import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import showToast from 'utils/toast';
import './PaymentCardFormPayU.css';
import { get, post } from 'utils/http';
import { URLS } from 'configuration';
import { Flex } from '@rebass/grid';
import { setAllPaymentCards } from 'views/Auth/actions/authActions';
import Spinner from 'styledComponents/elements/LoadingSpinner';
import FormCheckbox from 'common/components/Form/FormCheckbox';
import { ButtonPrimary } from 'styledComponents/elements/Button';

const PaymentCardFormPayU = ({
  isCVVForm = false,
  paymentIntentURL = '',
  cvvHandleCallback = () => {},
  onSuccessAddCard = () => {},
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const formType = isCVVForm ? 'cvv' : 'card';
  const [submitting, setSubmitting] = useState(false);
  const [isPayUFormRendered, setIsPayUFormRendered] = useState(false);
  const [cardsAreLoading, setCardsAreLoading] = useState(false);
  const [successSaveCard, setSuccessSaveCard] = useState(false);
  const [areRulesAccepted, setAreRulesAccepted] = useState(false);
  const [waitingCounter, setWaitingCounter] = useState(1);

  const dispatch = useDispatch();
  const {
    posId,
    rulesLink,
  } = useSelector(
    ({
      app: {
        config: {
          modules: { PayU: { posId } = {}, Stripe: { rulesLink } = {} } = {},
        } = {},
      } = {},
    }) => ({ rulesLink, posId })
  );

  const optionsForms = {
    style: {
      basic: {
        fontSize: '18px',
      },
    },
    lang: language,
  };

  const renderError = errors => {
    let messages = [];
    errors.forEach(error => {
      messages.push(error.message);
    });

    showToast({
      message: messages.join(', '),
      type: 'error',
    });
  };

  const handleSuccess = async response => {
    setSubmitting(true);

    const mask = response?.mask ?? '';
    const payload = {
      mask: mask.toString().substr(mask.length - 4),
      index: response.token,
      provider: 'PAYU_CARD',
    };

    try {
      await post(`${URLS.ALL_PAYMENT_CARDS}`, payload);
      setCardsAreLoading(true);
      const { data: allPaymentCards } = await get(`${URLS.ALL_PAYMENT_CARDS}`);
      setCardsAreLoading(false);
      dispatch(setAllPaymentCards(allPaymentCards['hydra:member']));

      showToast({
        message: t(
          `$*components.paymentCardForm.payU.onSuccessAddMessage`,
          `$$Jeśli karta nie zostanie użyta do opłacenia zamówienia w systemie w ciągu 1 godziny - to zostanie ona usunięta.`
        ),
        type: 'success',
      });
      setSubmitting(false);
      setSuccessSaveCard(true);
      onSuccessAddCard();
    } catch (exception) {
      setSubmitting(false);
      showToast({
        message: t(
          `$*error.addingPaymentCardFailed`,
          `$$Wystąpił błąd podczas dodawania karty płatniczej`
        ),
        type: 'error',
      });
    }
  };

  const handleCVVVerification = async ({ responseElement, payuSdkForms }) => {
    try {
      responseElement.innerText = '';

      const refReqId = await payuSdkForms.extractRefReqId(paymentIntentURL);
      const result = await payuSdkForms.sendCvv(refReqId);

      result?.status === 'SUCCESS'
        ? cvvHandleCallback()
        : renderError(result?.error?.messages);
    } catch (e) {
      console.log(e);
    }
  };

  const handleCardTokenization = ({ responseElement, payuSdkForms }) => {
    responseElement.innerText = '';

    try {
      payuSdkForms.tokenize('MULTI').then(result => {
        result?.status === 'SUCCESS'
          ? handleSuccess(result?.body)
          : renderError(result?.error?.messages);
      });
    } catch (e) {
      console.log(e); // błędy techniczne
    }
  };

  useEffect(() => {
    if (isPayUFormRendered) {
      return;
    }

    if (!posId || !window?.PayU) {
      // Sometimes when internet connection is slow, it takes some time to get PayU function avaliable, so thats why we force retry after 3 sec by setting waitingCounter
      return setTimeout(() => {
        setWaitingCounter(waitingCounter + 1);
      }, 3000);
    }

    const payuSdkForms = window.PayU(posId);
    const secureForms = payuSdkForms.secureForms();
    const card = secureForms.add(formType, optionsForms);

    card.render('#payu-card');

    const tokenizeButton = document.getElementById('tokenizeButton');
    const responseElement = document.getElementById('responseTokenize');

    tokenizeButton.addEventListener(
      'click',
      isCVVForm
        ? () => {
            handleCVVVerification({ responseElement, payuSdkForms });
          }
        : () => {
            handleCardTokenization({ responseElement, payuSdkForms });
          }
    );
    setIsPayUFormRendered(true);
  }, [waitingCounter]);

  if (cardsAreLoading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <Flex justifyContent="center" flexDirection="column" alignItems="center">
        <section className="payu-card-section">
          <div className="card-container">
            <div className="payu-card-form" id="payu-card"></div>
          </div>
          <div id="responseTokenize"></div>
        </section>
        <FormCheckbox
          componentStyle="rounded"
          linkLabel={t(
            '$*cardPayment.term.linkLabel',
            '$$ regulamin płatności cyklicznych'
          )}
          link={rulesLink}
          input={{
            checked: areRulesAccepted,
            onChange: () => {
              setAreRulesAccepted(!areRulesAccepted);
            },
          }}
        >
          {t('$*common.accept', '$$Akceptuję')}
        </FormCheckbox>
        <ButtonPrimary
          id="tokenizeButton"
          uppercased
          sizeMiddle
          weightBold
          withSpinner
          disabled={submitting || !areRulesAccepted}
          fullWidthOnMobile
          style={{ marginTop: '15px' }}
        >
          {!successSaveCard
            ? t('$*common.submit', '$$Zatwierdź')
            : t('$*common.saved', '$$Zapisano')}
        </ButtonPrimary>
      </Flex>
    </>
  );
};

export default PaymentCardFormPayU;
