import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Box, Flex } from '@rebass/grid';

import styled from 'styled-components';
import { media } from 'styledComponents/helpers';

import StyledCalendar from 'styledComponents/elements/SmallCalendarWithoutCheckmarksWithoutPlus';

import {
  StyledCheckbox,
  StyledCheckboxOptions,
} from 'styledComponents/elements/Form/FormCheckbox';

import { Paragraph } from 'styledComponents/elements/Typography';

import {
  setAllDaysToChange,
  setDaysToChange,
  unsetAllDaysToChange,
} from 'views/MenuPlanning/actions/menuPlanningActions';
import { compareAsc, format } from 'date-fns';
import DayPicker from 'react-day-picker';
import MomentLocalUtils from 'react-day-picker/moment';
import { useTranslation } from 'react-i18next';
import { mapLanguageToDatePickerLocale } from 'common/components/Calendar/services/calendarService';
import { BASE_URL, URLS } from '../../../configuration';

const StyledParagraph = styled(Paragraph)`
  ${media.tabletDown`
      padding-bottom: 20px;
  `}
`;

const translateDefaultValues = t => [
  { label: t('$*common.no', 'Nie'), value: false },
  { label: t('$*common.yes', 'Tak'), value: true },
];

const MultipleDaysChangeSection = ({
  dietDays,
  dietDaysToChange,
  initialDietDaysToChange,
  setDaysToChange,
  setAllDaysToChange,
  selectedDietObject,
  selectedDay,
  isChangingDiet,
  ConfigClientPanel,
  allowChangeMultipleDays,
  calendar,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [applyChangesForAllDays, setApplyChangesForAllDays] = useState(false);

  const availableDietDays = dietDays
    .filter(e => (isChangingDiet ? e.canChangeDiet : e.canChangeAddress))
    .sort((a, b) => compareAsc(a, b));

  const formattedAvailableDietDays = availableDietDays.map(date =>
    format(date.date, 'YYYY-MM-DD')
  );

  useEffect(() => {
    dietDaysToChange.length === 1 && setApplyChangesForAllDays(false);
  }, [dietDaysToChange.length]);

  const changeAllDaysChanges = value => {
    setApplyChangesForAllDays(value);

    value === true
      ? setAllDaysToChange(formattedAvailableDietDays)
      : setDaysToChange(initialDietDaysToChange);
  };

  const changeDays = day => {
    const dayBlock = availableDietDays.find(
      availableDay =>
        format(availableDay.date, 'DDMMYYYY') === format(day, 'DDMMYYYY')
    ).dayBlock;

    const formattedDayBlock = dayBlock.map(day =>
      format(day.date, 'YYYY-MM-DD')
    );

    const formattedDay = format(day, 'YYYY-MM-DD');
    // a user can't unselect the day what he clicked on the calendar
    if (
      initialDietDaysToChange.some(dayToChange =>
        formattedDayBlock.includes(dayToChange)
      )
    )
      return;

    // when a user selected a 'delivery for all days' flag and deselect a day - set a 'delivery for all days' flag to false.
    if (availableDietDays.length === dietDaysToChange.length) {
      setApplyChangesForAllDays(false);
    }

    // when a user select the last available day for delivery, select a 'delivery for all days' flag.
    if (
      dietDaysToChange.length === availableDietDays.length - 1 &&
      !dietDaysToChange.includes(formattedDay)
    ) {
      setApplyChangesForAllDays(true);
    }

    const days = dietDaysToChange.some(dayToChange =>
      formattedDayBlock.includes(dayToChange)
    )
      ? dietDaysToChange.filter(
          selectedDay => !formattedDayBlock.includes(selectedDay)
        )
      : [...dietDaysToChange, ...formattedDayBlock];

    setDaysToChange(days);
  };

  const isSubscriptionIntent =
    calendar?.days?.[selectedDay]?.['@type'] === 'SubscriptionIntent';

  const isSelectedDietSubscription = !!selectedDietObject?.subscription;

  if (
    availableDietDays.length === 0 ||
    isSubscriptionIntent ||
    isSelectedDietSubscription ||
    !allowChangeMultipleDays
  )
    return null;

  const hasAddIcon =
    ConfigClientPanel.calendarIconSubscriptionAddDelivery !== null;

  const { showCalendarIcons } = ConfigClientPanel;

  const plusIcon =
    hasAddIcon &&
    showCalendarIcons &&
    `${BASE_URL}${URLS.UPLOADED_MEDIA}${
      ConfigClientPanel['@resources'][
        ConfigClientPanel.calendarIconSubscriptionAddDelivery
      ]?.contentUrl
    }`;

  return (
    <Fragment>
      <StyledParagraph isBold textAlign="center" size="18px">
        {t('$*multipleDaysChangeSection.title', 'Wybierz dni do zastosowania:')}
      </StyledParagraph>
      <Flex justifyContent="center">
        <Paragraph
          textAlign="center"
          isSmall
          isBold
          customPadding="0 8px 0 15px"
          thin
        >
          {t(
            '$*multipleDaysChangeSection.allInDietLabel',
            'wszystkie w diecie'
          )}
        </Paragraph>
        <StyledCheckboxOptions noMargin>
          {translateDefaultValues(t).map(({ value, label }, index) => (
            <StyledCheckbox
              key={index}
              active={applyChangesForAllDays === value}
              onClick={() => changeAllDaysChanges(value)}
            >
              {label}
            </StyledCheckbox>
          ))}
        </StyledCheckboxOptions>
      </Flex>
      <Flex justifyContent="center" alignItems="center" flexWrap="wrap" mb={10}>
        <Flex>
          <Box width="100%" mt={25}>
            <StyledCalendar plusIcon={plusIcon}>
              <DayPicker
                selectedDays={dietDaysToChange.map(day => new Date(day))}
                disabledDays={day =>
                  !formattedAvailableDietDays.includes(
                    format(day, 'YYYY-MM-DD')
                  )
                }
                onDayClick={day => {
                  changeDays(day);
                }}
                fromMonth={new Date()}
                month={new Date(selectedDay)}
                // months={MONTH_NAMES}
                // weekdaysLong={WEEKDAY_NAMES_LONG}
                // weekdaysShort={WEEKDAY_NAMES_SHORT}
                firstDayOfWeek={1}
                locale={mapLanguageToDatePickerLocale(language)}
                localeUtils={MomentLocalUtils}
              />
            </StyledCalendar>
          </Box>
        </Flex>
      </Flex>
    </Fragment>
  );
};

export default connect(
  ({
    app: {
      brand: { mainColor, allowChangeMultipleDays },
      config: {
        modules: { ConfigClientPanel },
      },
    },
    menuPlanning: {
      dietDays,
      dietDaysToChange,
      initialDietDaysToChange,
      selectedDay,
      calendar,
    },
    userDiet: {
      calendar: { days },
      selectedDietObject,
    },
  }) => ({
    allowChangeMultipleDays,
    ConfigClientPanel,
    mainColor,
    selectedDietObject,
    dietDays,
    dietDaysToChange,
    initialDietDaysToChange,
    selectedDay,
    calendar,
    days,
  }),
  { setDaysToChange, setAllDaysToChange, unsetAllDaysToChange }
)(MultipleDaysChangeSection);
