import { combineReducers } from 'redux';

import app from './views/App/reducers/appReducer';
import nativeAppConfig from './views/App/reducers/nativeAppReducer';
import appGlobal from './views/App/reducers/appGlobalReducer';
import auth from './views/Auth/reducers/authReducer';
import dietForm from './views/NewOrder/reducers/dietFormReducer';
import orderForm from './views/NewOrder/reducers/orderFormReducer';
import orders from './views/MyOrders/reducers/ordersReducer';
import orderFormTabs from './views/NewOrder/components/OrderFormTabs/reducers/orderFormTabsReducer';
import menuPlanning from './views/MenuPlanning/reducers/menuPlanningReducer';
import userDiet from './views/UserDiet/reducers/userDietReducer';
import accountSettings from './views/AccountSettings/reducers/accountSettingsReducer';
import calendar from './common/components/Calendar/reducers/calendarReducer';
import userAddresses from './common/components/UserAddresses/reducers/userAddressesReducer';
import moneyBox from './common/components/MoneyBox/reducers/moneyBoxReducer';
import basket from 'common/components/Basket/duck/reducer';

const appReducer = combineReducers({
  accountSettings,
  app,
  appGlobal,
  nativeAppConfig,
  auth,
  basket,
  calendar,
  dietForm,
  menuPlanning,
  moneyBox,
  orderForm,
  orderFormTabs,
  orders,
  userAddresses,
  userDiet,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
