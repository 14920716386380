import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';

import { customTheme, customStyles } from 'styledComponents/vendor/ReactSelect';
import { fromPxToRem } from 'styledComponents/helpers';

import { mainTheme } from 'themes/main';
import { useTranslation } from 'react-i18next';

const StandaloneSelectBox = ({
  input,
  options,
  selected,
  onChange,
  mainColor,
  noOptionsMessage,
  ...rest
}) => {
  const { t } = useTranslation();
  const noOptionsMessageComponent = () =>
    noOptionsMessage || t('$*standaloneSelectBox.noResults', '$$Brak wyników');

  return (
    <Select
      {...input}
      {...rest}
      options={options || []}
      onChange={({ value }) => onChange(value)}
      value={selected && options.find(({ value }) => value === selected.value)}
      isSearchable={false}
      styles={{
        ...customStyles,
        control: styles => ({
          ...styles,
          backgroundColor: mainColor,
        }),
        dropdownIndicator: styles => ({
          ...styles,
          color: mainTheme.colorWhite,

          '&:hover': {
            color: mainTheme.colorWhite,
          },
        }),
        singleValue: styles => ({
          ...styles,
          color: mainTheme.colorWhite,
          fontWeight: mainTheme.fontWeight900,
          fontSize: fromPxToRem('16px'),
        }),
        placeholder: styles => ({
          ...styles,
          color: mainTheme.colorWhite,
          fontWeight: mainTheme.fontWeight900,
        }),
      }}
      noOptionsMessage={noOptionsMessageComponent}
      theme={customTheme}
    />
  );
};

export default connect(
  ({
    app: {
      brand: { mainColor },
    },
  }) => ({ mainColor }),
  null
)(StandaloneSelectBox);
