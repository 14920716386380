import React from 'react';
import MobileMenu from 'styledComponents/elements/Menu/MobileMenu';

const MobileMenuItem = ({
  label,
  icon,
  isBold,
  handleMenuClosing,
  children,
  onClickAdditionalAction = () => {},
}) => {
  return (
    <MobileMenu.Item>
      {children || (
        <React.Fragment>
          <div
            onClick={() => {
              handleMenuClosing(false);
              onClickAdditionalAction();
            }}
          >
            <MobileMenu.Label isBold={isBold}>{label}</MobileMenu.Label>
          </div>
          {icon && <i className={`fas fa-${icon}`}></i>}
        </React.Fragment>
      )}
    </MobileMenu.Item>
  );
};

export default MobileMenuItem;
