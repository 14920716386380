import styled from 'styled-components';
import { fromPxToRem, media } from 'styledComponents/helpers';

const Hover = styled.div`
  ${media.desktopDown`
    display: none;
  `};
  padding: 20px 25px;
  border-radius: ${props => props.theme.baseBorderRadius};
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.colorWhite};
  margin-bottom: 25px;
  text-align: center;
  display: ${({ mobile }) => (mobile ? 'none' : 'block')};
`;

Hover.Description = styled.span`
  display: block;
  font-size: ${fromPxToRem('13px')};
  font-weight: ${props => props.theme.fontWeight500};
  line-height: 1rem;
`;

Hover.Image = styled.img`
  width: 100%;
  max-width: 260px;
`;

export default Hover;
