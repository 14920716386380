import styled from 'styled-components';

import { fromPxToRem, media } from 'styledComponents/helpers';

const StyledSingleOrderEntry = styled.div`
  padding: 15px;
  border-top: 1px solid ${props => props.theme.colorIceBlue2};
`;

StyledSingleOrderEntry.Date = styled.p`
  font-size: ${fromPxToRem('11px')};
`;

StyledSingleOrderEntry.Name = styled.p`
  font-size: ${fromPxToRem('13px')};
  margin: 10px 0;

  ${media.tabletDown`
    margin-top: 0;
  `};
`;

export default StyledSingleOrderEntry;
