import { setComponentStyles } from 'utils/componentHelpers';

import FormRadio from 'common/components/Form/FormRadio';
import FormRadioBox from 'common/components/Form/FormRadioBox';

import FORM_FIELD_NAMES from '../formFieldNames';

export const DELIVERY_FIELDS = {
  [FORM_FIELD_NAMES.DELIVERY_TYPE]: {
    name: FORM_FIELD_NAMES.DELIVERY_TYPE,
    type: 'radio',
    component: FormRadio,
    componentStyle: 'secondary',
    isCentered: true,
    hasAutoHeight: true,
    width: '50%',
  },
  [FORM_FIELD_NAMES.DELIVERY_ADDRESS]: {
    name: FORM_FIELD_NAMES.DELIVERY_ADDRESS,
    type: 'radio',
    component: setComponentStyles(
      FormRadioBox,
      {
        width: '30%',
        margin: '10px',
        minHeight: '130px',
      },
      {
        width: '100%',
        margin: '10px 0',
      }
    ),
    mx: 10,
  },
  [FORM_FIELD_NAMES.DELIVERY_PICKUP_POINT]: {
    name: FORM_FIELD_NAMES.DELIVERY_PICKUP_POINT,
  },
};
