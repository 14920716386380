import React from 'react';
import { Box, Flex } from '@rebass/grid';
import styled from 'styled-components';

import { fromPxToRem } from 'styledComponents/helpers';

const ErrorMessage = styled.label`
  color: ${props => props.theme.colorRed};
  font-size: ${fromPxToRem('14px')};
  white-space: pre-wrap;
  margin-bottom: 20px;
`;

const FormSubmitError = ({ errorText, justifyContent = 'flex-end', ...rest }) =>
  errorText ? (
    <Flex justifyContent={justifyContent} {...rest}>
      <Box>
        <ErrorMessage>{errorText}</ErrorMessage>
      </Box>
    </Flex>
  ) : null;

export default FormSubmitError;
