import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';

import { initForm } from 'views/NewOrder/actions/dietFormActions';
import { setTabIndex } from 'views/NewOrder/components/OrderFormTabs/actions/orderFormTabsActions';
import { clearSelectedDays } from 'common/components/Calendar/actions/calendarActions';

import { ButtonLink, ButtonPrimary } from 'styledComponents/elements/Button';
import { DisplayOnDesktop, fromPxToRem } from 'styledComponents/helpers';
import { media } from 'styledComponents/helpers';
import { useTranslation } from 'react-i18next';
import Tooltip from 'common/components/Tooltip';
import { Fragment } from 'react';

const StyledFlex = styled(Flex)`
  ${media.mobileDown`
    justify-content: center;
    flex-direction: column;
  `};
`;

const RightButtonsWrapper = styled.div`
  display: flex;
  > button {
    margin-left: 3rem;

    ${media.mobileDown`
      margin-left: 0;
    `}

    & ~ button {
      ${media.tabletDown`
        margin-top: 20px;
      `};
    }
  }
`;

const FormNavButtons = ({
  onPrevClick,
  onNextClick,
  premiumModalOpen,
  premiumModalId,
  isPrevDisabled,
  isNextDisabled,
  isNextButtonVisible,
  isLoading,
  prevButtonText,
  nextButtonText,
  prevButtonType = 'button',
  nextButtonType = 'button',
  hasFullWidthButtons,
  initForm,
  isOnLastStep,
  setTabIndex,
  clearSelectedDays,
  testMode,
  paymentMode,
  nextButtonHintText = null,
}) => {
  const { t } = useTranslation();
  const toolTipId = 'hint';

  prevButtonText =
    prevButtonText || t('$*formNavButtons.prevButtonText', '$$Powrót');
  nextButtonText =
    nextButtonText || t('$*formNavButtons.nextButtonText', '$$Dalej');

  // subscription && logged => Subskrybuj

  return (
    <StyledFlex
      alignItems="center"
      justifyContent="space-between"
      flexWrap="wrap"
    >
      <Box width={hasFullWidthButtons ? '100%' : 'auto'}>
        <ButtonLink
          type={prevButtonType}
          uppercased
          sizeMiddle
          weightBold
          disabled={isPrevDisabled}
          onClick={() => onPrevClick()}
          hasFullWidth={hasFullWidthButtons}
        >
          {prevButtonText}
        </ButtonLink>
      </Box>
      {isNextButtonVisible && (
        <RightButtonsWrapper>
          {isOnLastStep && !testMode && paymentMode === '2' && (
            <DisplayOnDesktop>
              <ButtonLink
                uppercased
                weightBold
                type="button"
                sizeMiddle
                margin="0 25px 0 0"
                onClick={() => {
                  initForm();
                  setTabIndex(0);
                  clearSelectedDays();
                }}
              >
                {t(
                  '$*orderFormSummary.addNextOrder',
                  '$$Dodaj kolejne zamówienie'
                )}
              </ButtonLink>
            </DisplayOnDesktop>
          )}
          <span
            data-for={toolTipId}
            data-tip={toolTipId}
            data-tip-disable={false}
          >
            <ButtonPrimary
              type={nextButtonType}
              uppercased
              sizeMiddle
              weightBold
              withSpinner
              isLoading={isLoading}
              disabled={isNextDisabled}
              onClick={() => onNextClick(premiumModalOpen, premiumModalId)}
              hasFullWidth={hasFullWidthButtons}
            >
              {nextButtonText}
              {nextButtonHintText && (
                <i
                  className="fas fa-info-circle"
                  style={{
                    marginLeft: '4px',
                    padding: '4px',
                  }}
                />
              )}
            </ButtonPrimary>

            {nextButtonHintText && (
              <Tooltip
                id={toolTipId}
                maxWidth="350px"
                fontSize={fromPxToRem('14px')}
              >
                {nextButtonHintText}
              </Tooltip>
            )}
          </span>
        </RightButtonsWrapper>
      )}
    </StyledFlex>
  );
};

export default connect(
  ({ orderForm: { testMode }, dietForm: { paymentMode } }) => ({
    testMode,
    paymentMode,
  }),
  {
    initForm,
    setTabIndex,
    clearSelectedDays,
  }
)(FormNavButtons);
