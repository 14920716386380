import styled, { css } from 'styled-components';

import { mainTheme } from 'themes/main';

export function fromPxToRem(number) {
  number = parseInt(number, 10);

  const baseSize = parseInt(mainTheme.baseFontSize, 10);

  return `${(number / baseSize).toFixed(2)}rem`;
}

export const media = Object.keys(mainTheme.deviceBreakpoints).reduce(
  (acc, label) => {
    acc[`${label}Down`] = (...args) => css`
      @media (max-width: ${mainTheme.deviceBreakpoints[label] / 16}em) {
        ${css(...args)}
      }
    `;

    acc[`${label}Up`] = (...args) => css`
      @media (min-width: ${mainTheme.deviceBreakpoints[label] / 16}em) {
        ${css(...args)}
      }
    `;

    return acc;
  },
  {}
);

export const DisplayOnMobile = styled.div`
  display: none;

  ${media.tabletDown`
    display: ${props => (props.flex ? 'flex' : 'block')};
  `};
`;

export const DisplayOnDesktop = styled.div`
  display: ${props => (props.flex ? 'flex' : 'block')};

  ${media.tabletDown`
    display: none;
  `};
`;
