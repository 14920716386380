import {
  SET_TABS_COUNT,
  SET_TAB_INDEX,
  SET_NEXT_TAB_DISABILITY,
  CLEAR_TABS,
  SET_CURRENT_TAB_NAME,
} from './const';

export const setTabsCount = tabsCount => ({
  type: SET_TABS_COUNT,
  tabsCount,
});

export const setTabIndex = tabIndex => {
  return {
    type: SET_TAB_INDEX,
    tabIndex,
  };
};

export const setNextTabDisability = isNextDisabled => ({
  type: SET_NEXT_TAB_DISABILITY,
  isNextDisabled,
});

export const clearTabs = () => ({
  type: CLEAR_TABS,
});

export const setCurrentTabName = name => ({
  type: SET_CURRENT_TAB_NAME,
  name,
});
