export const FETCH_USER_ADDRESSES_REQUEST =
  'userAddresses/FETCH_USER_ADDRESSES_REQUEST';
export const FETCH_USER_ADDRESSES_SUCCESS =
  'userAddresses/FETCH_USER_ADDRESSES_SUCCESS';
export const FETCH_USER_ADDRESSES_FAILURE =
  'userAddresses/FETCH_USER_ADDRESSES_FAILURE';
export const SET_FIRST_USER_ADDRESS = 'userAddresses/SET_FIRST_USER_ADDRESS';
export const USER_ADDRESSES_LOADING_START =
  'userAddresses/USER_ADDRESSES_LOADING_START';
export const USER_ADDRESSES_LOADING_STOP =
  'userAddresses/USER_ADDRESSES_LOADING_STOP';
