import React, { useEffect, useState } from 'react';
import Modal from 'common/components/Modal';
import { useTranslation } from 'react-i18next';
import CookPreparingIcon from 'assets/icons/CookPreparingIcon';
import useInterval from 'hooks/useInterval';
import { useSelector, useDispatch } from 'react-redux';
import { URLS } from 'configuration';
import { get } from 'utils/http';
import { buildUrl } from 'utils/route';
import { setIsUnmountedPreparingModal } from 'views/MenuPlanning/actions/menuPlanningActions';

const INTERVAL_DELAY = 5000;
const TIME_TO_REFRESH_IN_SECONDS = 180;

const PreparingChangesModal = ({ id, isOpened, toggleModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [orderStatus, setOrderStatus] = useState({});
  const [time, setTime] = useState(0);

  const tmpOrderStatus = useSelector(
    state => state.menuPlanning.tmpChangeData.order
  );
  const orderId = tmpOrderStatus?.['@id'].replace('/orders/', '');

  useInterval(
    () => {
      get(buildUrl(URLS.CHECK_ORDER_STATUS, { orderId })).then(({ data }) => {
        setOrderStatus(data);
      });
      setTime(time + INTERVAL_DELAY / 1000);
    },
    orderId &&
      (orderStatus.orderItemsProcessFinished === true ||
        time >= TIME_TO_REFRESH_IN_SECONDS)
      ? null
      : time === 0
      ? 1000
      : INTERVAL_DELAY
  );

  useEffect(() => {
    dispatch(setIsUnmountedPreparingModal(false));

    return () => dispatch(setIsUnmountedPreparingModal(true));
  }, []);

  useEffect(() => {
    if (orderStatus.orderItemsProcessFinished) {
      dispatch(setIsUnmountedPreparingModal(true));
    }
  }, [orderStatus.orderItemsProcessFinished]);

  return (
    <Modal
      id={id}
      title={t(
        '$*menuPlanning.preparingChanges.title',
        '$$Trwa przy-GOTOWANIE'
      )}
      isOpened={isOpened}
      toggleModal={() => {
        toggleModal();
        dispatch(setIsUnmountedPreparingModal(true));
      }}
      contentPadding="0 30px"
      width="455px"
      widthDesktop="455px"
      withBackButton
    >
      <div style={{ textAlign: 'center' }}>
        <div>
          <CookPreparingIcon />
        </div>
        {t(
          '$*confirmationPreparing.changesInOrderDescription',
          '$$Trwa przy-GOTOWANIE zmian w zamówieniu. Za chwilę będą one widoczne.'
        )}
      </div>
    </Modal>
  );
};

export default PreparingChangesModal;
