import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  BaseWrapper,
  BaseDayNumber,
  BaseCaption,
  BaseIconWrapper,
} from 'styledComponents/elements/BigCalendarDayCell';
import { DisplayOnDesktop, media } from 'styledComponents/helpers';
import AddIcon from '../BigCalendar/icons/AddIcon';

const Wrapper = styled(BaseWrapper)`
  background-color: ${({ theme }) => theme.colorWhite};
  color: ${props => props.theme.baseFontColor};
`;

const StyledDayNumber = styled(BaseDayNumber)`
  font-weight: ${props => props.theme.fontWeight900};
`;

const IconWrapper = styled(BaseIconWrapper)`
  top: 15px;
  right: -8px;
  ${media.tabletDown`
    width: 20px;
    height: 20px;
    top: 15px;
    right: -6px;
  `}
`;

const SubscriptionDayHolded = ({ children }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <IconWrapper>
        <AddIcon />
      </IconWrapper>
      <StyledDayNumber>{children}</StyledDayNumber>
      <DisplayOnDesktop>
        <BaseCaption>
          {t('$*calendarDayCell.holded', '$$Wstrzymany')}
        </BaseCaption>
      </DisplayOnDesktop>
    </Wrapper>
  );
};

export default SubscriptionDayHolded;
