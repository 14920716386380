import styled from 'styled-components';
import { fromPxToRem } from 'styledComponents/helpers';

const MobileMenu = styled.div`
  @media (max-width: ${({ mediaMobileCss }) => mediaMobileCss}) {
    display: block;
    padding: 15px 20px;
    width: 100%;
    height: 100vh;
    background: ${props => props.theme.colorWhite};
    position: fixed;
    top: 80px;
    transform: translateX(${props => (props.showMobileMenu ? '0' : '-200vw')});
    z-index: 99;
    transition: 0.5s transform ease-in-out;
    overflow: hidden;
  }

  display: none;
`;

MobileMenu.Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 40px;
  padding: 15px 0;
  border-bottom: 1px solid ${props => props.theme.baseFontColor};
  cursor: pointer;
`;

MobileMenu.Label = styled.p`
  font-size: ${fromPxToRem('20px')};
  font-weight: ${props =>
    props.isBold ? props.theme.fontWeight900 : props.theme.fontWeight600};
  margin-right: 10px;
`;

MobileMenu.Hamburger = styled.div`
  @media (max-width: ${({ mediaMobileCss }) => mediaMobileCss}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 0;
    height: 80px;
    margin-left: 20px;
    z-index: 1;
    cursor: pointer;

    span {
      display: block;
      background-color: ${props => props.theme.baseFontColor};
      width: 30px;
      height: 3px;
      margin: 2px;
      transform: rotate(0) translate(0, 0);
    }

    span:nth-of-type(1) {
      transform: rotate(${props => (props.showMobileMenu ? '405deg' : '0')})
        translate(${props => (props.showMobileMenu ? '5px, 5px' : '0')});
      transition: 0.4s all ease-out;
    }

    span:nth-of-type(2) {
      opacity: ${props => (props.showMobileMenu ? '0' : '1')};
      transform: translateX(${props => (props.showMobileMenu ? '100px' : '0')});
      transition: 0.4s all ease-out;
    }

    span:nth-of-type(3) {
      transform: rotate(${props => (props.showMobileMenu ? '-405deg' : '0')})
        translate(${props => (props.showMobileMenu ? '5px, -5px' : '0')});
      transition: 0.4s all ease-out;
    }
  }

  display: none;
`;

export default MobileMenu;
