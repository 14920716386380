import React from 'react';
import styled from 'styled-components';

const StyledContent = styled.main`
  width: ${props =>
    props.hasNarrowContainer
      ? props.theme.narrowContainerWidth
      : props.theme.containerWidth};
  margin: 30px auto;
`;

const ResponsiveStyledContent = styled.main`
  width: ${props =>
    props.hasNarrowContainer
      ? props.theme.narrowContainerWidth
      : props.theme.containerWidth};
  max-width: ${props =>
    props.hasNarrowContainer
      ? props.theme.narrowContainerWidth
      : props.theme.containerWidth};
  padding: 0 15px;
  margin: 30px auto 0 auto;

  @media (max-width: calc(${props => props.theme.containerWidth} + 30px)) {
    width: unset;
  }
`;

const Content = ({ children, hasNarrowContainer, responsive }) => {
  const ContainerComponent = responsive
    ? ResponsiveStyledContent
    : StyledContent;

  return (
    <ContainerComponent hasNarrowContainer={hasNarrowContainer}>
      {children}
    </ContainerComponent>
  );
};

export default Content;
