import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const REGISTER_FORM = '/auth/register';
export const LOGIN_FORM = '/auth/login';

export const useAuthTabs = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return useMemo(
    () => [
      {
        id: REGISTER_FORM,
        label: t('$*authTabs.register', '$$Załóż nowe konto'),
      },
      { id: LOGIN_FORM, label: t('$*authTabs.logIN', '$$Zaloguj się') },
    ],
    [language]
  );
};
