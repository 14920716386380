import React from 'react';
import { ButtonLink } from 'styledComponents/elements/Button';
import { useTranslation } from 'react-i18next';

export const SubmitButton = ({
  submitting,
  pristine,
  invalid,
  caption,
  disabledWhenPristine,
  children,
}) => {
  const { t } = useTranslation();
  if (process.env.NODE_ENV === 'development') {
    if (children && caption) {
      try {
        throw new Error(
          'You set caption and children on SubmitButton - caption will be ignored'
        );
      } catch (ex) {
        console.warn(ex);
      }
    }
  }
  return (
    <ButtonLink
      type="submit"
      uppercased
      sizeMiddle
      weightBold
      disabled={
        submitting ||
        (disabledWhenPristine && pristine) ||
        (pristine && invalid)
      }
      fullWidthOnMobile
    >
      {children || caption}
    </ButtonLink>
  );
};
