const notifications = t => ({
  DATA_UPDATED_SUCCESSFULLY: t(
    '$*notification.dataUpdatedSucessfully',
    '$$Dane zostały pomyślnie zaktualizowane'
  ),
  RATE_TIME_OUT: t('$*notification.rateTimeOut', '$$Czas na ocenę minął'),
  PASSWORD_CHANGED_SUCCESSFULLY: t(
    '$*notification.passwordChangedSuccessfully',
    '$$Hasło zostało pomyślnie zmienione'
  ),
  ADDRESS_CREATED_SUCCESSFULLY: t(
    '$*notification.addressCreatedSuccessfully',
    '$$Pomyślnie dodano adres dostawy'
  ),
  ADDRESS_UPDATE_SUCCESSFULLY: t(
    '$*notification.addressUpdateSuccessfully',
    '$$Pomyślnie zaktualizowano adres'
  ),
  ADDRESS_DELETE_SUCCESSFULLY: t(
    '$*notification.addressesDeleteSuccessfully',
    '$$Pomyślnie usunięto adres'
  ),
  MENU_RATED_SUCCESSFULLY: t(
    '$*notification.menuRatedSuccessfully',
    '$$Pomyślnie oceniono menu, dziękujemy za opinię'
  ),
  DISCOUNT_CODE_REMOVED: t(
    '$*notification.discountCodeRemoved',
    '$$Wybrany kod rabatowy nie działa na zamówione rodzaje diet'
  ),
  MENU_RATED_ERROR: t(
    '$*notification.menuRatedError',
    '$$Nie udało się ocenić menu.'
  ),
  ACCOUNT_SUCCESSFULLY_CREATED: brandName =>
    t('$*notification.accountSuccessfullyCreated', {
      defaultValue: `$$Konto zostało pomyślnie utworzone. Witamy w {{brandName}}!`,
      replace: {
        brandName,
      },
    }),
});

export default notifications;
