import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual, connect } from 'react-redux';
import { Form } from 'react-final-form';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Flex } from '@rebass/grid';

import Modal from 'common/components/Modal';
import { ButtonPrimary } from 'styledComponents/elements/Button';

import { addBagToBasket } from 'common/components/Basket/duck/actions';
import { Addons, AddonTile } from 'views/NewOrder/components/addon';
import { sortByPosition } from 'utils/componentHelpers';
import { useTranslation } from 'react-i18next';
import {
  putDefaultSubscriptionConfig,
  changeSubscriptionIntent,
} from '../../actions/menuPlanningActions';

const AddonsChangeModal = ({
  id,
  isOpened,
  toggleModal,
  defaultSubscriptionDietSettings,
  onBeforeSave,
  isIntent,
  onConfirmRequired,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const isFiLanguage = language === 'fi';

  const [selectedAddons, setSelectedAddons] = useState([]);
  const dispatch = useDispatch();
  const {
    bag: currentBag,
    defaultSubscriptionDietDetails,
    selectedDay,
  } = useSelector(
    ({
      menuPlanning: { bag, defaultSubscriptionDietDetails, selectedDay },
    }) => ({
      bag,
      selectedDay,
      defaultSubscriptionDietDetails,
    })
  );

  const { basketBag, multinational, addons } = useSelector(
    ({
      basket,
      app: {
        config: { multinational },
      },
      menuPlanning: { bag, defaultSubscriptionDietDetails },
    }) => ({
      basketBag: basket.bags.find(({ bag }) => bag === currentBag['@id']),
      multinational,
      addons: bag?.addons || defaultSubscriptionDietDetails?.addons,
    }),
    shallowEqual
  );

  const { currencySymbol } = multinational;

  useEffect(() => {
    if (isOpened && !defaultSubscriptionDietSettings) {
      setSelectedAddons(basketBag?.addons || []);
    }
  }, [isOpened]);

  useEffect(() => {
    if (isOpened) {
      if (isIntent) {
        const formattedAddons = addons.map(addon => ({
          addon: addon.addon['@id'],
          ['@type']: 'Addon',
          defaultUsage: 'FOR_BAG',
          name: addon.addon.name,
          position: addon.addon.position,
          quantity: addon.quantity,
        }));
        setSelectedAddons(formattedAddons);
      }
    }
  }, [isOpened]);
  const onSubmit = async () => {
    const payload = {
      addons: selectedAddons,
      overriddenAddons: true,
      dates: [selectedDay],
    };
    if (onBeforeSave && isIntent) {
      onBeforeSave(payload);
    }

    if (defaultSubscriptionDietSettings) {
      dispatch(putDefaultSubscriptionConfig(payload))
        .then(toggleModal)
        .catch(error =>
          onConfirmRequired({
            error,
            payload,
          })
        );
    } else {
      dispatch(
        addBagToBasket({
          bag: currentBag['@id'],
          date: currentBag.date,
          addons: selectedAddons,
        })
      );
      toggleModal();
    }
  };

  const basketHasBagWithAddons = basketBag?.addons?.length > 0;
  const getAddonFromBasketBag = addonId =>
    basketBag?.addons?.find(({ addon }) => addon === addonId) || null;

  const getAddonFromSubscriptionSettings = addonId =>
    defaultSubscriptionDietDetails?.addons?.find(
      ({ addon }) => addon['@id'] === addonId
    ) || null;

  const buttonTitle = (() => {
    if (defaultSubscriptionDietSettings || isIntent)
      return t('$*common.saveChangesCaption', 'Zapisz zmiany');
    if (basketHasBagWithAddons)
      return t('$*addonsChangeModal.uppdateBasket', 'Zaktualizuj koszyk');

    return t('$*addonsChangeModal.addToBasket', 'Dodaj do koszyka');
  })();

  const title = defaultSubscriptionDietSettings
    ? t('$*addonsChangeModal.allDays.title', 'Zamów dodatki na każdą dostawę')
    : t('$*addonsChangeModal.singleDay.title', 'Zamów dodatki na ten dzień');

  const subTitle =
    defaultSubscriptionDietSettings &&
    t(
      '$*addonsChangeModal.subTitle',
      'Zamówienia dodatków na poszczególne dni możesz dokonać w widoku szczegółów dnia.'
    );

  return (
    <Modal
      id={id}
      title={title}
      isOpened={isOpened}
      toggleModal={() => toggleModal()}
      withBackButton
      paddingMobile="0 0 90px 0"
      fullscreen
      contentPaddingMobile={'15px'}
      widthDesktop="70%"
    >
      {/* {defaultSubscriptionDietSettings && ( // UNCOMMENT WHEN BUYING ADDONS IS DONE - CK-1193
        <h4 style={{ textAlign: 'center', marginBottom: '2rem' }}>
          {subTitle}
        </h4>
      )} */}
      {isFiLanguage && (
        <h4 style={{ textAlign: 'center', marginBottom: '2rem' }}>
          {t('$*addonsChangeModal.subTitle2')}
        </h4>
      )}

      {isOpened === id && (
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Addons
                  excludedPlace="EXTEND_BAG"
                  renderAddons={({ categoryName, categoryAddons }) =>
                    sortByPosition(categoryAddons).map(
                      ({ id, excludedPlaces, ...restCategoryAddon }) => {
                        const initState = (() => {
                          if (isIntent) {
                            const foundAddon =
                              addons.find(
                                ({ addon }) =>
                                  addon['@id'] === restCategoryAddon['@id']
                              ) || null;
                            return foundAddon;
                          }
                          if (defaultSubscriptionDietSettings) {
                            return getAddonFromSubscriptionSettings(
                              restCategoryAddon['@id']
                            );
                          }
                          return getAddonFromBasketBag(
                            restCategoryAddon['@id']
                          );
                        })();

                        return (
                          <AddonTile
                            key={id}
                            {...restCategoryAddon}
                            categoryName={categoryName}
                            setAddons={setSelectedAddons}
                            defaultUsage="FOR_BAG"
                            initState={initState}
                            currencySymbol={currencySymbol}
                          />
                        );
                      }
                    )
                  }
                />

                <Flex justifyContent="center" mt={40}>
                  <ButtonPrimary
                    type="submit"
                    uppercased
                    sizeMiddle
                    weightBold
                    width="250px"
                  >
                    {buttonTitle}
                  </ButtonPrimary>
                </Flex>
              </form>
            );
          }}
        />
      )}
    </Modal>
  );
};

export default compose(
  connect(state => state, {
    putDefaultSubscriptionConfig,
    changeSubscriptionIntent,
  }),
  withRouter
)(AddonsChangeModal);
