import styled from 'styled-components';

import { fromPxToRem, media } from 'styledComponents/helpers';
import SlideIn from '../animations/SlideIn';

const Calendar = styled.div`
  margin-bottom: 2rem;

  ${media.tabletUp`
    margin-top: 32px;
    min-height: 660px;
  `}

  .DayPicker {
    width: 100%;
    padding: 30px 10px 30px;
    background-color: ${props => props.theme.colorWhite};
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
    border-radius: ${props => props.theme.baseBorderRadius};

    ${media.tabletDown`
      padding: 20px 10px 10px;
    `};

    ${media.tabletUp`
      font-size: ${fromPxToRem('20px')};
      overflow: hidden;
    `}
  }

  .DayPicker-Week {
    animation: ${SlideIn} 0.3s;
  }

  .DayPicker-Day {
    ${media.tabletUp`
      height: 82px;
      border-radius: 10px;
    `}
    max-width: 90px;

    animation: ${SlideIn} 0.3s;
    padding: 0;
    margin: 0;
    border-radius: ${props => props.theme.baseBorderRadius};
    //border: 1px solid ${props => props.theme.colorIceBlue};

    &:focus {
      outline: none;
    }

    ${media.mobileDown`
      .calendar-icon {
        transform: scale(0.6) translate(6px, -12px);
      }
    `};

    & > div {
      border-radius: ${props => props.theme.baseBorderRadius};
      height: 100%;
      line-height: 1.3;
      ${media.tabletDown`
        padding: 16px 2px;
        min-width: 36px;
      `};

      ${media.mobileDown`
        padding: 12px 2px 4px;
        min-width: 36px;
      `};
    }
  }

  .DayPicker:not(.DayPicker--interactionDisabled) {
    .DayPicker-Day {
      &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside) {
        &:hover {
          border-color: ${props => props.theme.baseFontColor};
          background-color: ${props => props.theme.colorWhite};
        }
      }
    }
  }

  .DayPicker-wrapper {
    padding-bottom: 0;
    &:focus {
      outline: none;
    }
  }

  .DayPicker-Months {
    ${media.mobileDown`
      ${props =>
        props.dietIsSubscription &&
        `
        margin-bottom: 3rem;
      `}
    `};
  }

  .DayPicker-Month {
    width: 100%;
    margin: 0 20px;
    border-collapse: separate;
    border-spacing: 24px 24px;
    min-height: 600px;

    ${media.largeDown`
      border-spacing: 4px 20px;
    `}

    ${media.tabletDown`
      min-height: auto;
      margin: 0;
    `};

    ${media.mobileDown`
      border-spacing: 6px 20px;
      min-height: auto;
    `};
  }

  .DayPicker-Caption {
    margin-bottom: 0;
    margin-left: 0;
    padding: 0;
    text-align: center;
    width: 200px;
    text-transform: uppercase;
    color: ${props => props.theme.baseFontColor};
    ${media.tabletUp`
      font-size: ${fromPxToRem('24px')};
      width: 400px;
      margin-left: 5%;
      ${props =>
        !props.dietIsSubscription &&
        `
           width: 100%;
           margin-left: 0;
        `}
    `}
    ${media.mobileDown`
      width: 100%;
    `}
  }

  .DayPicker-NavBar {
    position: relative;
  }

  .DayPicker-NavButton {
    ${media.tabletUp`
      font-size: ${fromPxToRem('24px')};

      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0
    `}

    top: 6px;
    background: none;
    font-family: 'Font Awesome 5 Free';
    font-weight: ${props => props.theme.fontWeight900};
    color: ${props => props.theme.baseFontColor};
    -webkit-font-smoothing: antialiased;

    &:focus {
      outline: none;
    }
  }

  .DayPicker-NavButton--prev {
    left: ${props =>
      props.dietIsSubscription ? '5%' : 'calc((100% - 340px) / 2)'};

    ${media.tabletDown`
      left: 2%;
    `};

    &:before {
      content: '\f053';
    }
  }

  .DayPicker-NavButton--next {
    ${props =>
      props.dietIsSubscription
        ? 'left: calc(5% + 400px)'
        : 'right: calc((100% - 340px) / 2)'};

    ${media.tabletDown`
      left: 200px;
    `};

    ${media.mobileDown`
      left: 95%;
    `};

    &:before {
      content: '\f054';
    }
  }

  .DayPicker-Weekday {
    ${media.tabletUp`
      min-width: 90px;
      max-width: 110px;
      width: 110px;
    `}

    ${media.mobileDown`
      padding: 5px 7px;
    `};

    padding: 0;
    text-transform: uppercase;
    font-weight: ${props => props.theme.fontWeight900};
    font-size: ${fromPxToRem('12px')};
  }

  .DayPicker-Day--outside {
    border: 0;
  }

  .DayPicker-Day--disabled:not(.DayPicker-Day--outside) {
    background-color: ${props => props.theme.colorIceBlue};
    color: ${props => props.theme.baseFontColor};
  }

  .DayPicker-Day--today {
    font-weight: ${props => props.theme.fontWeightNormal};
  }

  .DayPicker-Day--today:not(.DayPicker-Day--disabled) {
    border-width: 2px;
    color: ${props => props.theme.baseFontColor};
    border-color: ${props => props.theme.baseFontColor};
    cursor: pointer;
  }

  .DayPicker-Day--selected {
    &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside),
    &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
      position: relative;
      background-color: ${props => props.theme.baseFontColor};
      border-color: ${props => props.theme.baseFontColor};
      border-color: red;
      color: ${props => props.theme.colorWhite};

      &:before {
        position: absolute;
        right: -3px;
        bottom: -3px;
        color: ${props => props.theme.colorWhite};
        line-height: 1;
        font-family: 'Font Awesome 5 Free';
        font-weight: ${props => props.theme.fontWeightNormal};
        content: '\f058';
        -webkit-font-smoothing: antialiased;
      }
    }
  }
`;

export default Calendar;
