import styled from 'styled-components';
import { Flex } from '@rebass/grid';

import { media } from 'styledComponents/helpers';

const DaysSwitcherDesktop = styled(Flex)`
  ${media.tabletDown`
    display: none;
  `};
`;

export default DaysSwitcherDesktop;
