import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Flex } from '@rebass/grid';

import {
  setNextTabDisability,
  setTabIndex,
} from 'views/NewOrder/components/OrderFormTabs/actions/orderFormTabsActions';
import FormSection from 'common/components/Form/FormSection';
import { ButtonPrimary } from 'styledComponents/elements/Button';

import withBasket from '../components/withBasket';
import getAddonsSection from '../const/formConfig/addonSections';
import { useTranslation } from 'react-i18next';
import { useCurrentTabNames } from '../components/OrderFormTabs/useCurrentTabNames';

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

const OrderFormAddon = ({
  isLoading,
  setNextTabDisability,
  setTabIndex,
  tabIndex,
  isNextDisabled,
  ...props
}) => {
  useCurrentTabNames('OrderFormAddon');

  useEffect(() => {
    setNextTabDisability(false);
  }, []);

  const handleClick = () => {
    setTabIndex(tabIndex + 1);
    scrollToTop();
  };

  const { t } = useTranslation();

  return getAddonsSection(t).map((section, index) => (
    <FormSection
      key={index}
      title={
        <div style={{ width: '100%' }}>
          <Flex justifyContent="space-between" alignItems="center">
            <span>{section.title}</span>
            {section.showSkip && (
              <ButtonPrimary
                type="button"
                uppercased
                sizeMiddle
                weightBold
                withSpinner
                isLoading={isLoading}
                disabled={isNextDisabled}
                onClick={handleClick}
              >
                {t('$*orderFormAddons.skipCaption', '$$pomiń')}
              </ButtonPrimary>
            )}
          </Flex>
        </div>
      }
    >
      <section.component
        {...section.getComponentProps(props, section.requiredProps)}
      />
    </FormSection>
  ));
};

const mapStateToProps = ({
  orderForm: { isLoading },
  orderFormTabs: { tabIndex, isNextDisabled },
}) => ({
  isLoading,
  tabIndex,
  isNextDisabled,
});

const mapDispatchToProps = {
  setNextTabDisability,
  setTabIndex,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withBasket
);

export default enhance(OrderFormAddon);
