import styled, { css } from 'styled-components';
import { Box } from '@rebass/grid';

import { media } from 'styledComponents/helpers';

export const ContentWrapper = styled(Box)`
  position: ${props => props.position};
  padding: ${props => props.padding};
  padding-top: ${props => props.paddingTop};
  border-radius: ${props => props.theme.baseBorderRadius};
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.colorWhite};
  margin-top: ${props => props.marginTop || '32px'};
  margin-bottom: ${props => props.marginBottom || '64px'};
  min-height: 300px;

  ${props =>
    props.noPaddingMobile &&
    css`
      ${media.tabletDown`
      padding: 0;
    `};
    `}

  ${media.tabletDown`
    padding: ${props => props.theme.mobileContentWrapperPadding};
    margin-top: ${props => props.marginTop || '10px'};
    margin-bottom: ${props => props.marginBottom || '24px'};
  `};
`;
