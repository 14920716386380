import { useSelector } from 'react-redux';
import { differenceInMilliseconds, isBefore, isSameDay } from 'date-fns';
import { useEffect, useState } from 'react';

const selector = ({ app: { brand }, menuPlanning: { bag } }) => ({
  brand,
  bag,
});

const useIsEditable = (deadlineBrandKey, editingAllowedKey) => {
  const { brand, bag } = useSelector(selector);
  const [hasTimePassed, setHasTimePassed] = useState(
    isBefore(bag[deadlineBrandKey], new Date())
  );
  const hasIntentEditingTimePassed = bag?.['@id']
    ? bag?.['@id'].includes('subscription-intents') &&
      isBefore(bag?.pauseDeadline, new Date())
    : false;

  const bagDeadlineIsToday = isSameDay(bag[deadlineBrandKey], new Date());

  useEffect(() => {
    if (bagDeadlineIsToday) {
      const millisecondsLeftToEdit = differenceInMilliseconds(
        bag[deadlineBrandKey],
        new Date()
      );

      if (millisecondsLeftToEdit < 1) {
        setHasTimePassed(true);
      }

      const timeoutFunction = setTimeout(() => {
        setHasTimePassed(true);
      }, millisecondsLeftToEdit);

      return () => {
        clearTimeout(timeoutFunction);
      };
    }
  }, [bagDeadlineIsToday]);

  return {
    hasTimePassed,
    isEditingAllowed: !hasIntentEditingTimePassed && brand[editingAllowedKey],
  };
};

export default useIsEditable;
