import styled from 'styled-components';
import { darken } from 'polished';

const addSuffix = (number, daySingular, dayPlural) =>
  number + (number > 1 ? ` ${dayPlural}` : ` ${daySingular}`);

export const SliderWrapper = styled.div`
  width: 100%;
  user-select: none;
  padding: 0 20px;
`;

export const DaysRangeWrapper = styled.div`
  margin-top: 22px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
`;

export const RangeBoundries = styled.span`
  opacity: ${props => (props.fade !== false ? props.fade : 1)};
  color: ${props => props.theme.baseFontColor};
`;
export const SliderPointer = styled.div`
  position: relative;
  width: 16px;
  height: 23px;
  background: ${props => darken(0.2, props.theme.baseFontColor)};
  left: ${props => props.left};
  transform: translate(-5px, 0);
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: -23px;
    left: 0;
    height: 13px;
    width: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid ${props => darken(0.2, props.theme.baseFontColor)};
  }

  &:after {
    content: '${props =>
      addSuffix(props.daysNumber, props.daySingular, props.dayPlural)}';
    position: absolute;
    top: -36px;
    width: 70px;
    left: -25px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: ${props => darken(0.2, props.theme.baseFontColor)};
    text-transform: uppercase;
  }
`;
