const brandDays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const getDaysSettings = data => {
  brandDays.forEach(day => {
    data[day] = data[day].shouldBeDeliveredInBlock;
  });

  return data;
};
