import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { compose, lifecycle } from 'recompose';
import { useSelector } from 'react-redux';

import { setNextTabDisability } from 'views/NewOrder/components/OrderFormTabs/actions/orderFormTabsActions';
import { useTranslation } from 'react-i18next';
import FORM_FIELD_NAMES from '../../const/formFieldNames';
import { get } from 'utils/http';
import { URLS } from 'configuration';
import { ButtonPrimary } from 'styledComponents/elements/Button';
import PayPoFormContent from './PayPoFormContent';

const payPoFormFields = [
  FORM_FIELD_NAMES.ADDRESS_POST_CODE,
  FORM_FIELD_NAMES.ADDRESS_CITY,
  FORM_FIELD_NAMES.ADDRESS_STREET,
  FORM_FIELD_NAMES.ADDRESS_BUILD_NUMBER,
  FORM_FIELD_NAMES.ADDRESS_PLACE_NUMBER,
];

const SummaryPayPoInfo = ({ handlePayPoSubmit, paymentIsLoading }) => {
  const { t } = useTranslation();

  const token = useSelector(({ auth: { token } }) => token);
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setIninitalValues] = useState(
    payPoFormFields.reduce((acc, field) => {
      return {
        ...acc,
        [field]: '',
      };
    }, {})
  );

  useEffect(() => {
    if (token !== null) {
      setIsLoading(true);
      get(URLS.PAYPO_ADDRESS_METADATA)
        .then(({ data }) => {
          const filteredFields = Object.entries(data).reduce(
            (acc, [key, value]) => {
              const isInArray = payPoFormFields.includes(key);

              return {
                ...acc,
                ...(isInArray ? { [key]: value } : {}),
              };
            },
            {}
          );

          setIninitalValues(filteredFields);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [token]);

  return (
    <Form
      onSubmit={handlePayPoSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, invalid }) => {
        return (
          <form onSubmit={handleSubmit}>
            <PayPoFormContent isLoadingPreviousPaymentData={isLoading} />
            <ButtonPrimary
              type="submit"
              uppercased
              sizeMiddle
              weightBold
              withSpinner
              isLoading={paymentIsLoading}
              disabled={paymentIsLoading || invalid}
              hasFullWidth={true}
              style={{ marginTop: '25px' }}
            >
              {t(
                '$*changePaymentTypeModal.payForOrderTitle',
                'Opłać zamówienie'
              )}
            </ButtonPrimary>
          </form>
        );
      }}
    />
  );
};

export default compose(
  connect(
    ({ orderForm }) => ({
      orderForm,
    }),
    {
      setNextTabDisability,
    }
  ),
  lifecycle({
    componentDidMount() {
      const {
        setNextTabDisability,
        orderForm: { paymentMethodId, paymentMetadataIsValid },
      } = this.props;

      const validate = [paymentMethodId === 'PAYPO' && !paymentMetadataIsValid];

      setNextTabDisability(validate.includes(true));
    },
    componentDidUpdate() {
      const {
        setNextTabDisability,
        orderForm: { paymentMethodId, paymentMetadataIsValid },
      } = this.props;

      const validate = [paymentMethodId === 'PAYPO' && !paymentMetadataIsValid];

      setNextTabDisability(validate.includes(true));
    },
  })
)(SummaryPayPoInfo);
