import { TODAY, WEEKDAYS_INDEXES } from 'common/shared/weekdays';
import {
  addDays,
  eachDay,
  getISODay,
  isSaturday,
  isSunday,
  subDays,
} from 'date-fns';

import { getWeekFromDateArray } from 'common/services/dateHelpers';
import { uniq } from 'ramda';

export function parseDatesToTimestamps(dates) {
  return dates.map(date => date.setHours(0, 0, 0, 0));
}

export function parseTimestampsToDates(timestamps) {
  return timestamps.map(timestamp => new Date(timestamp));
}

// Get date range with excluded weekend days
//
// Params:
// days - input date range
// includeSaturdays - should exclude only sundays, not whole weekend
// descending - should start checking excluded days in descending order (from starting day of the range to the past))
// excludedDays - custom excluded array of days

// const isSunday = day => getDay(day) === 0;

function getDisabledDaysFromConfig(daysConfig) {
  return Object.values(daysConfig).reduce((result, value, index) => {
    if (value === 0) {
      let dayOfWeekIndex = Object.entries(WEEKDAYS_INDEXES).find(([k, v]) => {
        return v === Object.keys(daysConfig)[index];
      })[0];

      dayOfWeekIndex = parseInt(dayOfWeekIndex, 10);

      if (dayOfWeekIndex === 7) {
        dayOfWeekIndex = 0;
      }

      result.push(dayOfWeekIndex);
    }

    return result;
  }, []);
}

const blocksFromConfig = arr => {
  let b = [],
    prev;

  arr.sort();
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] !== prev) {
      b.push(1);
    } else {
      b[b.length - 1]++;
    }
    prev = arr[i];
  }

  return uniq(b);
};

const currentlyAvailableBlocks = (blocks, currentDaysAmount, depth = 0) => {
  if (currentDaysAmount === 0) {
    return [];
  }
  const blocksSum = blocks.reduce((a, b) => a + b, 0);
  const result = [];

  if (currentDaysAmount > 13) {
    return blocks;
  }

  if (currentDaysAmount % blocksSum === 0) {
    return blocks;
  }

  const currentDaysModuloSumPlusSum =
    (currentDaysAmount % blocksSum) + blocksSum;
  if (currentDaysAmount > blocksSum) {
    blocks.forEach((block, index) => {
      if (
        currentDaysModuloSumPlusSum % block === 0 ||
        currentDaysAmount % block === 0
      ) {
        result.push(block);

        if (depth < 5) {
          blocks.forEach(testowany_blok => {
            if (testowany_blok !== block) {
              result.push(
                ...currentlyAvailableBlocks(
                  blocks,
                  currentDaysAmount - block,
                  ++depth
                )
              );
            }
          });
        }
      }
    });
  } else {
    blocks.forEach((block, index) => {
      if (currentDaysAmount % block === 0) {
        result.push(block);
      }
    });
  }
  blocks.forEach((block, index) => {
    blocks.forEach(blockToTest => {
      if (blockToTest !== block) {
        let lastTestedLength;
        let multiplier = 1;
        do {
          const lastTestedLength = currentDaysAmount - multiplier * blockToTest;

          if (lastTestedLength > 0 && lastTestedLength % block === 0) {
            result.push(block);
            result.push(blockToTest);
          }
          multiplier++;
        } while (lastTestedLength > 0);
      }
    });
  });

  return uniq(result);
};

export const getSelectedDaysByRange = ({
  day,
  duration,
  includeSaturdays,
  includeSundays,
  excludedDays = [],
  daysConfig,
}) => {
  const valuesFromConfig = Object.values(daysConfig).map(value => value);
  const blocks = blocksFromConfig(valuesFromConfig);

  const createDayBlock = day => {
    const clickedIsoDay = getISODay(day);

    const thisWeek = getWeekFromDateArray(day);

    return thisWeek.filter(date => {
      const isoCurrentDay = getISODay(date);
      const clickedWeekdayName = WEEKDAYS_INDEXES[clickedIsoDay];
      const clickedDayConfigValue = daysConfig[clickedWeekdayName];
      const currentWeekdayName = WEEKDAYS_INDEXES[isoCurrentDay];

      return daysConfig[currentWeekdayName] === clickedDayConfigValue;
    });
  };

  let selectedDays = [];
  let currentDay = day;
  const excludedTimestamps = parseDatesToTimestamps(excludedDays);

  while (selectedDays.length < duration) {
    const daysLeft = duration - selectedDays.length;
    const currentBlock = createDayBlock(currentDay);
    const availableBlocks = currentlyAvailableBlocks(blocks, daysLeft);
    const currentDayTimestamp = new Date(currentDay).setHours(0, 0, 0);

    const isDisabledInConfig = day => {
      const isoCurrentDay = getISODay(day);
      return daysConfig[WEEKDAYS_INDEXES[isoCurrentDay]] === 0;
    };

    const dayIsExcluded =
      excludedTimestamps.includes(currentDayTimestamp) ||
      isDisabledInConfig(currentDay) ||
      (isSaturday(currentDay) && !includeSaturdays) ||
      (isSunday(currentDay) && !includeSundays);
    const daysConfigArray = Object.values(daysConfig);
    const firstDayOfDeliveryIndex = daysConfigArray.findIndex(
      element => element !== 0
    );
    if (availableBlocks.includes(currentBlock.length) && !dayIsExcluded) {
      selectedDays = [...selectedDays, ...currentBlock];
      currentDay = addDays(
        currentBlock[currentBlock.length - 1],
        firstDayOfDeliveryIndex === 6 ? firstDayOfDeliveryIndex + 1 : 1
      );
    } else {
      currentDay = addDays(currentDay, 1);
    }
  }

  return selectedDays;
};

export const getDisabledDays = ({ includeToday, customDays, daysConfig }) => {
  return [
    { before: TODAY },
    [includeToday ? TODAY : null],
    { daysOfWeek: getDisabledDaysFromConfig(daysConfig) },
    { ...customDays },
  ];
};

// Get date range with offset, i.e.
// input: 8.07, 9.07, 10.07
// output if direction === "left": 7.07, 8.07, 9.07
// output if direction === "right": 9.07, 10.07, 11.07
export const getShiftedDateRange = ({ initialRange, direction }) => {
  const startDate = getShiftedStartDay({ initialRange, direction });
  const endDate = getShiftedEndDay({ initialRange, direction });

  return eachDay(startDate, endDate);
};

function getShiftedStartDay({ initialRange, direction }) {
  if (direction === 'left') {
    return subDays(initialRange[0], 1);
  } else if (direction === 'right') {
    return initialRange[1];
  } else {
    return initialRange[0];
  }
}

function getShiftedEndDay({ initialRange, direction }) {
  if (direction === 'left') {
    return initialRange[initialRange.length - 2];
  } else if (direction === 'right') {
    return addDays(initialRange[initialRange.length - 1], 1);
  } else {
    return initialRange[initialRange.length - 1];
  }
}
