import React, { Fragment } from 'react';
import { Flex } from '@rebass/grid';
import { connect } from 'react-redux';

import HeadingSection from 'layouts/components/HeadingSection';

import MoneyBoxContent from 'views/MoneyBox/containers/MoneyBoxContent';

import { ButtonPrimary } from 'styledComponents/elements/Button';
import { Paragraph } from 'styledComponents/elements/Typography';
import { useTranslation } from 'react-i18next';

const MoneyBox = brand => {
  const { t } = useTranslation();
  const additionlInfoLabel = t('$*moneyBox.additionalInfoLabel', {
    defaultValue:
      '$$Chcesz wiedzieć więcej o {{- name}} i jak działa skarbonka?',
    replace: {
      name:
        brand['moneyBoxConfigurationNames'].pluralNameLocative || 'punktach',
    },
  });

  return (
    <Fragment>
      <HeadingSection title={t('$*moneyBox.title', '$$Skarbonka')} />
      <MoneyBoxContent />
      <Flex justifyContent="center" flexDirection="column" alignItems="center">
        <Paragraph textAlign="center" marginBottom="25px">
          {additionlInfoLabel}
        </Paragraph>
        <ButtonPrimary
          type="button"
          uppercased
          sizeMiddle
          weightBold
          onClick={() => {}}
          fullWidthOnMobile
          href={brand['moneyBoxReadMoreLink']}
          target="_blank"
        >
          {t('$*moneyBox.readMore', '@@Czytaj więcej')}
        </ButtonPrimary>
      </Flex>
    </Fragment>
  );
};

export default connect(({ app: { brand } }) => brand, null)(MoneyBox);
