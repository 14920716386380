import React from 'react';

import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import { MONDAY } from 'common/shared/weekdays';

import { getCalendarDayStatus } from './services/calendarService';

import SingleDay from '../CalendarDayCell/SingleDay';
import { useTranslation } from 'react-i18next';
import MomentLocalUtils from 'react-day-picker/moment';
import { mapLanguageToDatePickerLocale } from '../Calendar/services/calendarService';
import { isSameDay } from 'date-fns';

const Calendar = ({
  handleDayChange,
  handleMonthChange,
  days,
  disabledWeekdays,
  dietsMissing,
  selectedDay,
  exludedDays,
  isLoading,
}) => {
  const calendarRef = React.useRef(null);
  const initialDay = new Date(selectedDay);
  const {
    i18n: { language },
  } = useTranslation();

  //@TODO firstVisibleDay

  return (
      <DayPicker
        ref={calendarRef}
        isLoading={isLoading}
        month={initialDay}
        initialMonth={initialDay}
        disabledDays={disabledWeekdays}
        onDayClick={handleDayChange}
        onMonthChange={handleMonthChange}
        firstDayOfWeek={MONDAY}
        locale={mapLanguageToDatePickerLocale(language)}
        localeUtils={MomentLocalUtils}
        renderDay={day => {
          const dayStatus = getCalendarDayStatus({
            dietsMissing,
            currentDay: day,
            calendarDays: days,
            exludedDays,
          });

          return (
            <SingleDay
              day={day}
              status={dayStatus}
              disabledDays={disabledWeekdays}
            />
          );
        }}
      />
  );
};

export default React.memo(Calendar, (prevProps, props) => {
  const isDayTheSame = prevProps.selectedDay === props.selectedDay;
  const excludedDaysAreTheSame = props.exludedDays.every(day =>
    prevProps.exludedDays.some(prevPropDay => isSameDay(day, prevPropDay))
  );

  const disabledWeekdaysAreTheSame = props.disabledWeekdays.every(day =>
    prevProps.disabledWeekdays.some(prevPropDay => isSameDay(day, prevPropDay))
  );

  const areDaysTheSame = Object.values(props.days).every(
    ({ newState, date, id }) => {
      return Object.values(prevProps.days).some(
        prevPropDay =>
          prevPropDay.newState === newState &&
          date === prevPropDay.date &&
          id === prevPropDay.id
      );
    }
  );

  const isSelectedDietTheSame = prevProps.selectedDiet === props.selectedDiet;

  return (
    isDayTheSame &&
    excludedDaysAreTheSame &&
    disabledWeekdaysAreTheSame &&
    areDaysTheSame &&
    isSelectedDietTheSame
  );
});
