import {
  FETCH_USER_ADDRESSES_REQUEST,
  FETCH_USER_ADDRESSES_SUCCESS,
  FETCH_USER_ADDRESSES_FAILURE,
  SET_FIRST_USER_ADDRESS,
  USER_ADDRESSES_LOADING_START,
  USER_ADDRESSES_LOADING_STOP,
} from '../actions/const';

const INITIAL_STATE = {
  list: [],
  isLoading: true,
  firstUserAddress: null,
};

const userAddressesReducer = (
  state = INITIAL_STATE,
  { type, list, firstUserAddress }
) => {
  switch (type) {
    case FETCH_USER_ADDRESSES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_USER_ADDRESSES_SUCCESS:
      return {
        ...state,
        list,
        isLoading: false,
      };

    case FETCH_USER_ADDRESSES_FAILURE:
      return {
        ...state,
        list,
        isLoading: false,
      };

    case USER_ADDRESSES_LOADING_START:
      return {
        ...state,
        isLoading: true,
      };

    case USER_ADDRESSES_LOADING_STOP:
      return {
        ...state,
        isLoading: false,
      };

    case SET_FIRST_USER_ADDRESS:
      return {
        ...state,
        firstUserAddress: firstUserAddress,
      };

    default:
      return state;
  }
};

export default userAddressesReducer;
