import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { ElementsConsumer, Elements } from '@stripe/react-stripe-js';

import { get } from 'utils/http';
import { URLS } from 'configuration';
import { setCreditCards } from 'views/Auth/actions/authActions';

const withStripeConsumer = Component => props => {
  const dispatch = useDispatch();
  const [stripe, setStripe] = useState(null);
  const [stripeSecret, setStripeSecret] = useState(null);
  const { token, allPaymentCards, isFetchedAllPaymentCards } = useSelector(
    ({ auth }) => auth
  );
  const needStripePromise = props.needStripePromise ?? false;

  const isStripeCardPayments = useSelector(
    ({ app: { config } }) => config.modules.Stripe.cardPayments
  );

  const hasStripeCards = allPaymentCards.some(
    ({ provider }) => provider === 'STRIPE_CARD'
  );

  const fetchCards = async ({ createIntent }) => {
    const { data } = await get(`${URLS.CARDS}?createIntent=${createIntent}`);
    dispatch(setCreditCards(data));

    if (createIntent) {
      setStripeSecret(data.secret);
      setStripe(loadStripe(data.key));
    }
  };

  useEffect(() => {
    if (
      (needStripePromise || !hasStripeCards) &&
      isFetchedAllPaymentCards &&
      isStripeCardPayments &&
      token !== null
    ) {
      fetchCards({ createIntent: true });
    }
  }, [hasStripeCards, isFetchedAllPaymentCards, isStripeCardPayments, token]);

  if (!isStripeCardPayments) {
    return <Component {...props} />;
  }

  return (
    <Elements stripe={stripe}>
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <Component
            {...props}
            stripe={stripe}
            elements={elements}
            stripeSecret={stripeSecret}
          />
        )}
      </ElementsConsumer>
    </Elements>
  );
};

export default withStripeConsumer;
