import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { Flex } from '@rebass/grid';
import FormCheckbox from 'common/components/Form/FormCheckbox';
import { post } from 'utils/http';
import { URLS } from 'configuration';
import { Paragraph } from 'styledComponents/elements/Typography';
import { updateForm } from '../../actions/dietFormActions';
import { transformOrderCost } from 'views/NewOrder/services/orderFormRepresenter';
import {
  validateDiscountCode,
  getOrderCost,
  setPaymentMethod,
} from '../../actions/orderFormActions';
import { useTranslation } from 'react-i18next';

const SummaryPremiumSwitch = ({
  dietForm,
  formId,
  getOrderCost,
  updateForm,
  orderForm,
  list,
}) => {
  const { t } = useTranslation();
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      flexDirection={['row', 'row', 'row']}
    >
      {dietForm.forms[formId].optionChangeMenu ? (
        <Paragraph
          customPadding={'0 15px 0 0'}
          noMarginMobile
          colorPremiumGold
          isBold
        >
          {t('$*summaryPremoiumSwitch.menuSelectionLabel', 'WYBÓR MENU')}
        </Paragraph>
      ) : (
        <Paragraph customPadding={'0 15px 0 0'} noMarginMobile>
          {t('$*summaryPremoiumSwitch.menuSelectionLabel', 'WYBÓR MENU')}
        </Paragraph>
      )}
      <FormCheckbox
        componentStyle="switch"
        input={{
          checked: dietForm.forms[formId].optionChangeMenu,
          onChange: async ev => {
            let currentFormToUpdate = dietForm.forms[formId];
            currentFormToUpdate.optionChangeMenu = !currentFormToUpdate.optionChangeMenu;

            const orderCosts = await post(
              URLS.ORDER_COST,
              transformOrderCost(Object.values(dietForm.forms), orderForm, list)
            );

            if (
              orderCosts &&
              orderCosts.data &&
              currentFormToUpdate.paymentType === 'PAYPO' &&
              (orderCosts.data.priceLeftToPay < 10 ||
                orderCosts.data.priceLeftToPay > 1000)
            ) {
              currentFormToUpdate.paymentType = 'TPAY';
            }

            const { name, ...restValues } = currentFormToUpdate;
            updateForm(formId, restValues);
            getOrderCost();
          },
        }}
      />
    </Flex>
  );
};

export default compose(
  connect(
    ({ orderForm, dietForm, userAddresses: { list } }) => ({
      list,
      dietForm,
      orderForm,
    }),
    { validateDiscountCode, getOrderCost, updateForm, setPaymentMethod }
  ),
  lifecycle({
    componentDidUpdate(prevProps) {
      getOrderCost();
    },
  })
)(SummaryPremiumSwitch);
