import { get } from 'lodash';

import { INPUT_IDS } from '../const/userDataFormInputs';

export const getUserDataFormInitialValues = userData => {
  const values = Object.values(INPUT_IDS).reduce(
    (acc, inputName) => ({ ...acc, [inputName]: get(userData, inputName) }),
    {}
  );

  return values;
};

export const prepareUserDataRequest = (formData, changedFields) => {
  const data = changedFields.reduce((acc, fieldName) => {
    if (fieldName.includes('.')) {
      const splitKey = fieldName.split('.');
      const secondLast = splitKey[splitKey.length - 2];
      const field = { [secondLast]: get(formData, secondLast) || null };

      return (acc = { ...acc, ...field });
    }

    return (acc = { ...acc, [fieldName]: get(formData, fieldName) || null });
  }, {});

  return data;
};
