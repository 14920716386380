export const FETCH_BRAND_REQUEST = 'APP/FETCH_BRAND_REQUEST';
export const FETCH_CONFIG_REQUEST = 'APP/FETCH_CONFIG_REQUEST';
export const FETCH_BRAND_SUCCESS = 'APP/FETCH_BRAND_SUCCESS';
export const FETCH_CONFIG_SUCCESS = 'APP/FETCH_CONFIG_SUCCESS';
export const TOGGLE_MOBILE_MENU = 'TOGGLE_MOBILE_MENU';
export const SET_ALL_PAYMENT_CARDS = 'SET_ALL_PAYMENT_CARDS';

export const SET_NATIVE_APP_CONFIG = 'NATIVE_APP/SET_CONFIG';
export const SET_COUNTER_PWA_MODAL = 'APP_GLOBAL/SET_COUNTER_PWA_MODAL';
export const INCREMENT_PWA_MODAL = 'APP_GLOBAL/INCREMENT_PWA_MODAL';
