import styled from 'styled-components';

const Category = styled.div`
  padding-bottom: 35px;
`;

Category.Name = styled.div`
  font-size: 1.25rem;
  font-weight: 500;
  border-bottom: 1px solid rgb(120, 129, 140, 0.4);
  margin-bottom: 20px;
  padding-bottom: 5px;
`;

Category.GroupWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  margin: -15px;
  padding: 15px 0 0;
`;

Category.NoAddons = styled.span`
  background: #f3f3f3;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  display: inline-block;
`;

export { Category };
