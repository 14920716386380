import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  BaseWrapper,
  BaseDayNumber,
  BaseCaption,
  BaseIconWrapper,
} from 'styledComponents/elements/BigCalendarDayCell';
import { DisplayOnDesktop, media } from 'styledComponents/helpers';
import InProgressIcon from '../BigCalendar/icons/InProgressIcon';

const Wrapper = styled(BaseWrapper)`
  background-color: ${props => props.theme.baseFontColor};
  color: ${props => props.theme.colorWhite};
`;

const IconWrapper = styled(BaseIconWrapper)`
  top: 15px;
  right: -8px;
  ${media.tabletDown`
    width: 20px;
    height: 20px;
    top: 14px;
    right: -6px;
  `}
`;

const Caption = styled(BaseCaption)`
  color: ${props => props.theme.colorWhite};
`;

const StyledDayNumber = styled(BaseDayNumber)`
  color: ${props => props.theme.colorWhite};
  font-weight: ${props => props.theme.fontWeight900};
`;

const NotDeliveredBlockedDayCellFuture = ({ children, isIteratedDayToday }) => {
  const { t } = useTranslation();
  return (
    <Wrapper isIteratedDayToday={isIteratedDayToday}>
      <StyledDayNumber>{children}</StyledDayNumber>
      <IconWrapper>
        <InProgressIcon />
      </IconWrapper>
      <DisplayOnDesktop>
        <Caption>{t('$*calendarDayCell.details', '$$Szczegóły')}</Caption>
      </DisplayOnDesktop>
    </Wrapper>
  );
};

export default NotDeliveredBlockedDayCellFuture;
