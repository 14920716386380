import React from 'react';

import StyledThumbRating from 'styledComponents/elements/MenuPlanning/ThumbRating';

const THUMB_RATING_OPTIONS = {
  THUMBS_UP: 5,
  THUMBS_DOWN: 1,
};

const DEFAULT_VALUES = [
  { icon: 'thumbs-up', value: THUMB_RATING_OPTIONS.THUMBS_UP },
  { icon: 'thumbs-down', value: THUMB_RATING_OPTIONS.THUMBS_DOWN },
];

const ThumbRating = ({ input, name, disabled }) => (
  <div>
    <input type="hidden" name={name} {...input} value={input.value} />
    {DEFAULT_VALUES.map(({ icon, value }, index) => (
      <StyledThumbRating
        key={index}
        onClick={() => input.onChange(value)}
        className={`fas fa-${icon}`}
        isActive={input.value === value}
        disabled={disabled}
      ></StyledThumbRating>
    ))}
  </div>
);

export default ThumbRating;
