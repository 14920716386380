import React from 'react';
import { Flex, Box } from '@rebass/grid';
import { isSameDay, isBefore } from 'date-fns';

import DaysSwitcher from 'styledComponents/elements/MenuPlanning/DaysSwitcherDesktop';

import SwitcherDayCellDesktop from './SwitcherDayCellDesktop';
import DaysSwitcherButton from './DaysSwitcherButton';
import { DAY_STATUSES } from '../../../common/components/BigCalendar/const/dayStatuses';

const DaysSwitcherDesktop = ({
  selectedDay,
  handleDayChange,
  visibleDays,
  setVisibleDays,
  dietCalendarDays,
}) => (
  <DaysSwitcher justifyContent="space-between" alignItems="center">
    <Box>
      <DaysSwitcherButton
        direction="left"
        onClick={() => {
          setVisibleDays({ direction: 'left' });
        }}
      />
    </Box>
    <Box>
      <Flex justifyContent="center" mt={20} flexWrap="wrap">
        {visibleDays.map(
          ({
            day,
            dayStatus,
            isNowBeforeIteratedDay,
            isDisabled,
            isConfigurable,
            isDayToday,
          }) => {
            return (
              <Box key={day.getDate()}>
                <SwitcherDayCellDesktop
                  date={day}
                  isDayToday={isDayToday}
                  selected={isSameDay(day, selectedDay)}
                  disabled={isDisabled}
                  //faded={dayStatus === DAY_STATUSES.SUBSCRIPTION_DISABLED} //delete
                  faded={dayStatus === DAY_STATUSES.SUBSCRIPTION_SUSPENDED}
                  configurable={isConfigurable}
                  dayStatus={dayStatus}
                  handleDayChange={() => handleDayChange(day)}
                  isNowBeforeIteratedDay={isNowBeforeIteratedDay}
                />
              </Box>
            );
          }
        )}
      </Flex>
    </Box>
    <Box>
      <DaysSwitcherButton
        direction="right"
        onClick={() => {
          setVisibleDays({ direction: 'right' });
        }}
      />
    </Box>
  </DaysSwitcher>
);

export default DaysSwitcherDesktop;
