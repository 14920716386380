import styled from 'styled-components';

import { fromPxToRem } from 'styledComponents/helpers';

const StyledSingleMoneyBoxEntry = styled.div`
  padding: 15px;
  border-top: 1px solid ${props => props.theme.colorIceBlue2};
`;

StyledSingleMoneyBoxEntry.EventDate = styled.p`
  font-size: ${fromPxToRem('11px')};
`;

StyledSingleMoneyBoxEntry.EventName = styled.p`
  font-size: ${fromPxToRem('13px')};
  margin-top: 10px;
`;

export default StyledSingleMoneyBoxEntry;
