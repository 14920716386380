const formMutators = {
  forceFocus: ([fieldName], fieldState) => {
    fieldState.fields[fieldName].focus();
  },
  forceBlur: ([fieldName], fieldState) => {
    fieldState.fields[fieldName].blur();
  },
  clear: ([fieldName], fieldState) => {
    fieldState.fields[fieldName].change(undefined);
  },
  setFieldValue: (args, state, { changeValue }) => {
    const [name, data] = args;
    changeValue(state, name, () => data);
  },
};

export default formMutators;
