import React from 'react';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import FormRadioGroup from 'common/components/Form/FormRadioGroup';
import FORM_FIELD_NAMES from '../../const/formFieldNames';
import { sortByPosition } from 'utils/componentHelpers';
import FormRadio from 'common/components/Form/FormRadio';

const DietVariant = ({ variants, diets, forms, currentFormId }) => {
  const currentDietId = forms[currentFormId].diet;
  const currentDiet =
    diets.find(diet => diet.id === parseInt(currentDietId)) || diets[0];

  return (
    <FormRadioGroup key={`frg=${currentFormId}`}>
      {sortByPosition(currentDiet.variants, true).map(variant => {
        const currentVariant = variants.find(
          variantWithDesc => variantWithDesc.id === variant.id
        );

        return (
          <Field
            key={variant.id}
            value={variant.id.toString()}
            label={variant.name}
            image={
              variant.image
                ? `/uploaded-media/${variant.image.contentUrl}`
                : null
            }
            description={currentVariant.description}
            name={FORM_FIELD_NAMES.VARIANT}
            type="radio"
            component={FormRadio}
            componentStyle="variantSecondary"
            // {...CONFIGURATION_FIELDS[FORM_FIELD_NAMES.VARIANT]}
          />
        );
      })}
    </FormRadioGroup>
  );
};
export default connect(
  ({ dietForm: { forms, currentFormId } }) => ({ forms, currentFormId }),
  null
)(DietVariant);
