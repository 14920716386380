import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { fromPxToRem } from 'styledComponents/helpers';

import StyledSingleDetailSection from 'styledComponents/elements/MenuPlanning/SingleDetailSection';
import HorizontalLine from 'styledComponents/elements/HorizontalLine';
import Spacer from 'styledComponents/elements/Spacer';
import {
  dateISOStringToPolishTransformed,
  dateISOStringToTime,
} from 'common/services/dateHelpers';
import { Flex } from '@rebass/grid';
import Modal from 'common/components/Modal';

import { DisplayOnDesktop, DisplayOnMobile } from 'styledComponents/helpers';
//import Tooltip from 'common/components/Tooltip';

import Tooltip from './Tooltip';
import { withTranslation } from 'react-i18next';
import { isAfter } from 'date-fns';
import { compose } from 'redux';
import { connect } from 'react-redux';

const ButtonDeadline = styled(Flex)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colorIceBlue2};
`;

ButtonDeadline.InfoText = styled.p`
  margin: 0;
  padding: 0;
  color: ${props => props.theme.baseFontColor};
  font-size: ${fromPxToRem('11px')};
`;

const DietDetailsSection = ({
  deadlineBrandKey,
  isEditable,
  title,
  showTooltip,
  children,
}) => {
  return (
    <div>
      <StyledSingleDetailSection.Title>
        <Flex justifyContent={'space-between'} alignContent={'center'}>
          {title}
          {showTooltip && (
            <Tooltip
              deadlineBrandKey={deadlineBrandKey}
              isEditable={isEditable}
            />
          )}
        </Flex>
      </StyledSingleDetailSection.Title>
      <HorizontalLine />
      {children}
    </div>
  );
};

export default compose(
  connect(({ app: { brand }, menuPlanning: { bag } }) => ({
    brand,
    bag,
  })),
  withTranslation()
)(DietDetailsSection);
