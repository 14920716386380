import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Flex } from '@rebass/grid';
import { media } from 'styledComponents/helpers';

import Modal from 'common/components/Modal';
import ConfirmButtonWrapper from 'common/components/Modal/ConfirmButtonWrapper';

import { ButtonLink } from 'styledComponents/elements/Button';
import { Paragraph } from 'styledComponents/elements/Typography';
import { useTranslation } from 'react-i18next';
import PriceBoxCollapse from './PriceBoxCollapse';

const ListElement = styled.li`
  display: flex;
  justify-content: space-between;
  ${media.mobileDown`
    flex-direction: column;
    & > div {
      margin-left: auto;
      margin-bottom: 1rem;
      }
  `}
`;

const OldPrice = styled.span`
  color: red;
  text-decoration: line-through;
  margin-left: auto;
  margin-right: 0.5rem;
`;

const NewPriceConfirmModal = ({
  id,
  isOpened,
  toggleModal,
  newPriceConfirmData,
  defaultSubscriptionDietSettings,
  onBeforeSave,
  newParams,
  currencySymbol,
}) => {
  const { t } = useTranslation();

  if (!newPriceConfirmData) return null;

  const priceSuffix = defaultSubscriptionDietSettings
    ? `/ ${t('$*common.day')}`
    : '';

  return (
    <Modal
      id={id}
      title={t('$*menuPlanning.pointsReturnModal.title', '$$Uwaga!')}
      isOpened={isOpened}
      toggleModal={() => toggleModal()}
      contentPadding="0 20px"
      padding="20px 16px 26px"
      widthDesktop="500px"
      style={{ width: '100%' }}
    >
      <Paragraph textAlign="center">
        {t(
          '$*common.changesWillChangePrice',
          '$$Wybrane zmiany wiążą się ze zmianą ceny!'
        )}
      </Paragraph>

      <div style={{ display: 'flex', margin: '-10px' }}>
        <div style={{ flex: '1 1 50%', padding: '10px' }}>
          <PriceBoxCollapse
            title={t(
              '$*newPriceConfirmModal.priceBeforeModification',
              '$$Cena przed zmianami'
            )}
            {...newPriceConfirmData.price}
          />
        </div>

        <div style={{ flex: '1 1 50%', padding: '10px' }}>
          <PriceBoxCollapse
            title={t(
              '$*newPriceConfirmModal.priceAfterModification',
              '$$Cena po zmianach'
            )}
            {...newPriceConfirmData.newPrice}
          />
        </div>
      </div>

      <Flex justifyContent="space-between" alignItems="center" mt="25px">
        <ButtonLink
          type="button"
          onClick={() => toggleModal()}
          uppercased
          sizeMiddle
          style={{ marginTop: 0 }}
        >
          {t('$*common.cancel', 'Anuluj')}
        </ButtonLink>
        <ConfirmButtonWrapper
          type="submit"
          onClick={() =>
            onBeforeSave({
              ...newParams,
              costAcceptKey: newPriceConfirmData.key,
            })
          }
          buttonText={t('$*common.continue', '$$Kontynuuj')}
          withSpinner
        />
      </Flex>
    </Modal>
  );
};

export default connect(
  ({
    app: {
      config: {
        multinational: { currencySymbol },
      },
    },
  }) => ({ currencySymbol }),
  null
)(NewPriceConfirmModal);
