import React, { useEffect } from 'react';
import DietHoldOnCalendarModalSection from './DietHoldOnCalendarModalSection';
import { connect } from 'react-redux';
import Modal from '../../../common/components/Modal';
import AddressChangeModal from '../../MenuPlanning/components/Modal/AddressChangeModal';
import MenuPlanningContentFormContainer from '../../MenuPlanning/containers/MenuPlanningContentFormContainer';
import DietChangeModal from '../../MenuPlanning/components/Modal/DietChangeModal';
import AddonsChangeModal from '../../MenuPlanning/components/Modal/AddonsChangeModal';
import {
  putDefaultSubscriptionConfig,
  clearDefaultSubscriptionConfig,
  getDefaultSubscriptionConfig,
} from 'views/MenuPlanning/actions/menuPlanningActions';
import NewPriceConfirmModal from '../../MenuPlanning/components/Modal/NewPriceConfirmModal/NewPriceConfirmModal';
import MENU_PLANNING_MODALS from '../../MenuPlanning/const/modal';
import ConfirmButtonWrapper from '../../../common/components/Modal/ConfirmButtonWrapper';

const DefaultSubscriptionDietSettings = ({
  onBeforeBagSave,
  defaultSubscriptionDietDetails,
  putDefaultSubscriptionConfig,
  clearDefaultSubscriptionConfig,
  diets,
  variants,
  variant,
  allowChangeDiet,
  allowChangeCalorific,
  allowChangeVariant,
  clearDietsInWhichMealExists,
  getDefaultSubscriptionConfig,
  dietsInWhichMealExists,
  modalOpened,
  userDiets,
  selectedDiet,
  isLoading,
  handleModalOpen,
  t,
  newPriceConfirmData: newPriceConfirmDataForPickedDays,
  newParams: newParamsForPickedDays,
}) => {
  const [currentView, setCurrentView] = React.useState('');
  const [newParams, setNewParams] = React.useState({});
  const [newPriceConfirmData, setNewPriceConfirmData] = React.useState({});

  useEffect(() => clearDefaultSubscriptionConfig, []);

  const onManageDeliveriesClick = () => {
    if (userDiets && currentView === '') {
      const selectedDietSubscriptionId = userDiets?.find(
        diet => diet.id === selectedDiet
      )?.subscription?.id;

      getDefaultSubscriptionConfig(selectedDietSubscriptionId);
      setCurrentView('default');
    }
  };

  const rootModalTitle = t(
    '$*userDiet.subscription.deliveriesChanges',
    'Zmiana terminów dostaw'
  );

  switch (currentView) {
    case 'default':
      if (modalOpened === MENU_PLANNING_MODALS.PRICE_CONFIRM_MODAL) {
        // special case for selected days change
        return (
          <NewPriceConfirmModal
            id={modalOpened}
            isOpened={modalOpened}
            toggleModal={() => handleModalOpen(null)}
            newParams={newParamsForPickedDays}
            newPriceConfirmData={newPriceConfirmDataForPickedDays}
            onBeforeSave={onBeforeBagSave}
          />
        );
      }

      return (
        <Modal
          id={currentView}
          title={rootModalTitle}
          isOpened={currentView}
          toggleModal={() => setCurrentView('')}
          withBackButton
          widthDesktop="1240px"
          widthMobile="calc(100vw - 20px)"
          fullscreen
          contentPaddingMobile="0"
          paddingMobile="0"
          style={{ paddingBottom: 0, paddingLeft: 0, marginLeft: 0 }}
        >
          <DietHoldOnCalendarModalSection setCurrentView={setCurrentView} />
        </Modal>
      );

    case 'address':
      return (
        <AddressChangeModal
          id={currentView}
          isOpened={currentView}
          bag={defaultSubscriptionDietDetails}
          toggleModal={() => setCurrentView('default')}
          onBeforeSave={e => {
            setCurrentView('default');
            return onBeforeBagSave(e);
          }}
          defaultSubscriptionDietSettings
          onConfirmRequired={({ error, payload }) => {
            setNewPriceConfirmData(error.response.data);
            setNewParams(payload);
            setCurrentView('newPriceConfirm');
          }}
        />
      );

    case 'diet':
      return (
        <DietChangeModal
          id={currentView}
          isOpened={currentView}
          dietsInWhichMealExists={dietsInWhichMealExists}
          clearDietsInWhichMealExists={clearDietsInWhichMealExists}
          toggleModal={() => setCurrentView('default')}
          onBeforeSave={e => {
            setCurrentView('default');
            onBeforeBagSave(e);
          }}
          currentDietId={defaultSubscriptionDietDetails.diet.id}
          diets={diets}
          allowChangeDiet={allowChangeDiet}
          allowChangeCalorific={allowChangeCalorific}
          allowChangeVariant={allowChangeVariant}
          currentCalorificId={defaultSubscriptionDietDetails.calorific?.id}
          variants={variants}
          currentVariantId={defaultSubscriptionDietDetails.variant.id}
          defaultSubscriptionDietSettings
          onConfirmRequired={({ error, payload }) => {
            setNewPriceConfirmData(error.response.data);
            setNewParams(payload);
            setCurrentView('newPriceConfirm');
          }}
          defaultSubscriptionDietDetails={defaultSubscriptionDietDetails}
          calorifics={
            variants.length
              ? variants.find(
                  element =>
                    element.id === defaultSubscriptionDietDetails.variant.id
                )?.calories
              : []
          }
        />
      );

    case 'addons':
      return (
        <AddonsChangeModal
          id={currentView}
          isOpened={currentView}
          toggleModal={() => setCurrentView('default')}
          defaultSubscriptionDietSettings
          onConfirmRequired={({ error, payload }) => {
            setNewPriceConfirmData(error.response.data);
            setNewParams(payload);
            setCurrentView('newPriceConfirm');
          }}
        />
      );

    case 'newPriceConfirm':
      return (
        <NewPriceConfirmModal
          id={currentView}
          isOpened={currentView}
          toggleModal={() => setCurrentView('default')}
          newParams={newParams}
          newPriceConfirmData={newPriceConfirmData}
          defaultSubscriptionDietSettings
          onBeforeSave={async e => {
            await putDefaultSubscriptionConfig(e);
            setCurrentView('default');
          }}
        />
      );

    default:
      return (
        <ConfirmButtonWrapper
          defaultSubscriptionDietSettings
          type="submit"
          padding="12px"
          onClick={onManageDeliveriesClick}
          marginLeft={'auto'}
          marginTop="12px"
          buttonText={t(
            '$*userDiet.subscription.manageDeliveries',
            '$$Kontynuuj'
          )}
        />
      );
  }
};

export default MenuPlanningContentFormContainer(
  connect(null, {
    putDefaultSubscriptionConfig,
    getDefaultSubscriptionConfig,
    clearDefaultSubscriptionConfig,
  })(DefaultSubscriptionDietSettings)
);
