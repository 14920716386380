import React from 'react';
import styled from 'styled-components';
import { media } from 'styledComponents/helpers';
import edit from 'assets/svg/edit.svg';
import { Subtitle, Paragraph } from 'styledComponents/elements/Typography';

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
`;

const Wrapper = styled.div`
  width: clamp(180px, 40%, 85%);
  margin: 0 1rem;
  overflow-wrap: break-word;
  ${media.mobileDown`
     max-width: 85%;
     width: 85%;
  `}

  > ul > li ~ li {
    margin-top: 0.5rem;
  }
`;

const Details = ({ title, children, onClick }) => {
  return (
    <Wrapper>
      <Heading onClick={onClick && onClick}>
        <Subtitle>{title}</Subtitle>
        {onClick && <img width={20} height={20} src={edit} alt="edit" />}
      </Heading>
      <ul>{children}</ul>
    </Wrapper>
  );
};

export default Details;
