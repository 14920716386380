import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { Box, Flex } from '@rebass/grid';

import Modal from 'common/components/Modal';
import ConfirmButtonWrapper from 'common/components/Modal/ConfirmButtonWrapper';

import { ButtonLink } from 'styledComponents/elements/Button';
import { Paragraph } from 'styledComponents/elements/Typography';
import { useTranslation } from 'react-i18next';

const PointsReturnModal = ({
  id,
  onSave,
  isOpened,
  toggleModal,
  returnPoints,
  additionalPrice,
  pluralNameGenitive,
  pluralNameDenominator,
}) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = form => {
    setIsSubmitting(true);
    form.payExtra
      ? onSave().then(() => {
          setIsSubmitting(false);
          return toggleModal();
        })
      : toggleModal();
  };

  return (
    <Modal
      id={id}
      title={t('$*menuPlanning.pointsReturnModal.title', '$$Uwaga!')}
      isOpened={isOpened}
      toggleModal={() => toggleModal()}
      contentPadding="0 30px"
      width="355px"
    >
      <Paragraph textAlign="center">
        {t('$*pointsReturnModal.label', {
          defaultValue: '{{pointPlural}} zostaną zwrócone na Twoje konto',
          replace: {
            pointPlural: pluralNameDenominator
              ? pluralNameDenominator[0].toUpperCase() +
                pluralNameDenominator.substring(1)
              : t('$*common.pointPlural', 'Punkty'),
          },
        })}
      </Paragraph>
      <Form
        onSubmit={onSubmit}
        initialValues={{ payExtra: true }}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Flex justifyContent="center">
              <Box mt={28}>
                <Paragraph textAlign="center" noPadding>
                  {t('$*pointsReturnModal.amountMessage', {
                    defaultValue:
                      'Ilość zwróconych {{points}}: {{returnPoints}}',
                    replace: {
                      points:
                        pluralNameGenitive ||
                        t('$*common.pointsLiteralGerundium', '$$punktów'),
                      returnPoints,
                    },
                  })}
                </Paragraph>
              </Box>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" mt="25px">
              <ButtonLink
                type="button"
                onClick={() => toggleModal()}
                uppercased
                sizeMiddle
              >
                {t('$*common.cancel', 'Anuluj')}
              </ButtonLink>
              <ConfirmButtonWrapper
                type="submit"
                buttonText={t('$*common.continue', '$$Kontynuuj')}
                withSpinner
                isLoading={isSubmitting}
                disabled={isSubmitting}
              />
            </Flex>
          </form>
        )}
      />
    </Modal>
  );
};

export default connect(
  ({
    app: {
      brand: {
        moneyBoxConfigurationNames: {
          pluralNameGenitive,
          pluralNameDenominator,
        },
      },
    },
  }) => ({ pluralNameGenitive, pluralNameDenominator }),
  null
)(PointsReturnModal);
