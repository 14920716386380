import React from 'react';
import { Flex } from '@rebass/grid';

import StyledSingleMoneyBoxEntry from 'styledComponents/elements/MoneyBox/SingleMoneyBoxEntry';

const EVENT_DATE = 0;
const EVENT_NAME = 1;
const POINTS = 2;

const SingleMoneyBoxEntry = ({ row }) => (
  <StyledSingleMoneyBoxEntry>
    <Flex justifyContent="space-between">
      <StyledSingleMoneyBoxEntry.EventDate>
        {row[EVENT_DATE]}
      </StyledSingleMoneyBoxEntry.EventDate>
      {row[POINTS]}
    </Flex>
    <Flex>
      <StyledSingleMoneyBoxEntry.EventName>
        {row[EVENT_NAME]}
      </StyledSingleMoneyBoxEntry.EventName>
    </Flex>
  </StyledSingleMoneyBoxEntry>
);

export default SingleMoneyBoxEntry;
