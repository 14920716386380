import { get } from 'utils/http';

import { BASE_URL, URLS } from 'configuration';
import {
  FETCH_BRAND_REQUEST,
  FETCH_BRAND_SUCCESS,
  TOGGLE_MOBILE_MENU,
  FETCH_CONFIG_SUCCESS,
  FETCH_CONFIG_REQUEST,
} from './const';

import { getDaysSettings } from '../services/appDataHelper';
import { FETCH_ORDER_CONFIG_SUCCESS } from 'views/NewOrder/actions/const';
import { transformOrderConfig } from 'views/NewOrder/services/orderFormRepresenter';

export function getBrand() {
  return dispatch => {
    dispatch({ type: FETCH_BRAND_REQUEST });

    return get(`${BASE_URL}${URLS.BRANDS}`).then(({ data }) => {
      dispatch({ type: FETCH_BRAND_SUCCESS, brand: getDaysSettings(data) });
    });
  };
}

export const getConfig = () => dispatch => {
  dispatch({ type: FETCH_CONFIG_REQUEST });

  return get(`${BASE_URL}${URLS.CONFIG}`).then(({ data }) => {
    dispatch({ type: FETCH_CONFIG_SUCCESS, config: data });
    return dispatch({
      type: FETCH_ORDER_CONFIG_SUCCESS,
      orderConfig: transformOrderConfig(data),
    });
  });
};

export const toogleMobileMenu = isMobileMenuOpened => {
  return dispatch => {
    dispatch({
      type: TOGGLE_MOBILE_MENU,
      isMobileMenuOpened,
    });
  };
};
