import React from 'react';
import styled from 'styled-components';
import { media } from 'styledComponents/helpers';

import NewOrderBasket from './NewOrderBasket';

const Row = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media screen and (min-width: 1025px) {
    flex-direction: row;
  }
`;

const LeftBox = styled.div`
  width: 100%;

  @media screen and (min-width: 1025px) {
    width: 75%;
  }
`;

const RightBox = styled.div`
  width: 100%;

  @media screen and (min-width: 1025px) {
    width: 30%;
    margin-left: 60px;
  }
`;

const HidingWrapper = styled.div`
  display: block;
  position: sticky;
  top: 55px;
  ${media.tabletDown`
    display: "none";
  `}
`;

const withBasket = WrappedComponent => {
  return props => (
    <Row>
      <LeftBox>
        <NewOrderBasket mobile />
        <WrappedComponent {...props} />
      </LeftBox>

      <RightBox>
        <HidingWrapper>
          <NewOrderBasket />
        </HidingWrapper>
      </RightBox>
    </Row>
  );
};

export default withBasket;
