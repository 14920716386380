import React from 'react';
import { useTranslation } from 'react-i18next';

import URLS from 'routes/routeUrls';

import Confirmation from 'styledComponents/elements/NewOrder/Confirmation';

const ConfirmationFailed = () => {
  const { t } = useTranslation();

  return (
    <Confirmation>
      <Confirmation.Title>
        {t(
          '$*newOrder.confirmationFailed.title',
          '$$Płatność została odrzucona'
        )}
      </Confirmation.Title>
      <Confirmation.Subtitle
        dangerouslySetInnerHTML={{
          __html: t('$*newOrder.confirmationFailed.subtitle', {
            defaultValue:
              '$$Spróbuj dokonać zapłaty jeszcze raz lub wybierz inną metodę płatności w Historii zamówień. <br /> W razie dalszych problemów zapraszamy do kontaktu z BOK.',
            interpolation: { escapeValue: false },
          }),
        }}
      />

      <Confirmation.Link to={URLS.MY_ORDERS}>
        {t('$*newOrder.confirmationFailed.link', '$$Historia zamówień')}
      </Confirmation.Link>
    </Confirmation>
  );
};

export default ConfirmationFailed;
