import { useCallback, useEffect, useState } from 'react';

const useNativeBridge = () => {
  const [handlers, setHandlers] = useState([]);

  useEffect(() => {
    const handler = (message) => {
      console.log("handler", message, handlers);
      let finalData;
      try {
        finalData = JSON.parse(message.data);
      } catch(e) {
        finalData = message.data;
      }

      handlers.forEach((handler) => {
        handler({...message, data: finalData});
      });
    };
    document.addEventListener("message", handler);
    
    return () => {
      document.removeEventListener("message", handler);
    }
  },[handlers]);

  const addNativeEventListener = useCallback((onMessageHandler) => { 
      if (typeof onMessageHandler === 'function' ) {
        const newHandlers = [...handlers, onMessageHandler];
        setHandlers(newHandlers);
      }
  }, [handlers, setHandlers]);

  const postMessageToNative = useCallback((message)=> {
    if (window.ReactNativeWebView) {
      const messageString = typeof message === 'string' || message instanceof String ? message : JSON.stringify(message);
      window.ReactNativeWebView.postMessage(messageString);
    }
  },[]);

  return {
    postMessageToNative,
    addNativeEventListener
  }
};

export default useNativeBridge;
