import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const WrapperCollapsed = styled.div`
  padding: 12px 16px;

  ${({ isOpenMenu }) => !isOpenMenu && `height: '100%'`}
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -16px;
  margin-left: -16px;
`;

const Col = styled.div`
  flex: 1 0 0%;
  width: 100%;
  max-width: 100%;
  padding-right: 16px;
  padding-left: 16px;

  ${({ auto }) => auto && `flex: 0 0 auto; width: auto;`}
`;

const InnerWrapper = styled.div`
  position: relative;
  padding: 0 16px 16px 16px;
  flex: 1 1 auto;
  overflow: auto;

  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0 !important;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ScrollDown = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 1) 100%
  );

  font-size: 12px;
  padding: 40px 2px 2px 2px;
  transition: ease 0.3s;
  transform: translateY(100%)
    ${({ showScrollDown }) => showScrollDown && `transform: translateY(0%)`};
`;

const DishWrapper = styled.div`
  width: 100%;
  position: relative;
  border-radius: 8px;
  border: 2px;
  cursor: pointer;
  min-height: 350px;
  box-shadow: 0 2px 16px 0 rgb(0 0 0 / 10%);
`;

const TagsWrapper = styled.div`
  padding-top: 12px;
  display: flex;
  flex-wrap: wrap;
  margin: -2px;
`;

const Tag = styled.div`
  ${({ theme }) => `border: 1px solid ${theme.colorLightBlueGrey}`};
  font-size: 12px;
  padding: 2px 8px;
  margin: 2px;
`;

const DishInfoWrapper = styled.div`
  ${({ showImage }) =>
    showImage &&
    `
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0 `};
  ${({ isOpenMenu }) =>
    isOpenMenu
      ? 'height: 350px; border-radius: 8px;'
      : 'height: 105px; border-radius: 0 0 8px 8px;'};

  overflow: hidden;
  background: white;
  transition: ease 0.3s;
`;

export const Styled = {
  Wrapper,
  WrapperCollapsed,
  Row,
  Col,
  InnerWrapper,
  ScrollDown,
  DishWrapper,
  TagsWrapper,
  Tag,
  DishInfoWrapper,
};
