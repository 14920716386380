import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import HeadingSection from 'layouts/components/HeadingSection';

import PasswordResetForm from 'views/AccountSettings/components/Form/PasswordResetForm';
import AgreementsForm from 'views/AccountSettings/components/Form/AgreementsForm';
import UserDataForm from 'views/AccountSettings/components/Form/UserDataForm';
import UserPaymentCardForm from 'views/AccountSettings/components/Form/UserPaymentCardForm';
import DeleteAccountSection from 'views/AccountSettings/components/DeleteAccountSection/DeleteAccountSection';
import UserAddressesSection from 'views/AccountSettings/containers/UserAddressesSection';
import ShareDiscountCode from 'views/AccountSettings/components/ShareDiscountCode';
import { useTranslation } from 'react-i18next';
import { config } from '@fortawesome/fontawesome-svg-core';

const AccountSettings = () => {
  const { t } = useTranslation();
  const isStripeSubscriptionEnabled = useSelector(
    ({ app: { config } }) => config.modules.Stripe.cardPayments
  );
  const isBlueMediaSubscriptionEnabled = useSelector(
    ({ app: { config } }) => config.modules.BlueMedia.cardPayments
  );
  const isPayUSubscriptionEnabled = useSelector(
    ({ app: { config } }) => config.modules.PayU.cardPayments
  );

  const clientCanRemoveAccount = useSelector(
    ({ app: { config } }) =>
      config.modules.ConfigClientPanel.clientCanRemoveAccount
  );

  const showRefererCode = useSelector(
    ({
      app: {
        brand: { showRefererCode },
      },
    }) => showRefererCode
  );

  return (
    <Fragment>
      <HeadingSection
        title={t('$*accountSettings.headingTitle', '$$Ustawienia konta')}
      />
      <UserDataForm />
      <PasswordResetForm />
      {showRefererCode && <ShareDiscountCode />}
      <AgreementsForm />
      {(isStripeSubscriptionEnabled ||
        isBlueMediaSubscriptionEnabled ||
        isPayUSubscriptionEnabled) && <UserPaymentCardForm />}
      {clientCanRemoveAccount && <DeleteAccountSection />}
      <UserAddressesSection />
    </Fragment>
  );
};

export default AccountSettings;
