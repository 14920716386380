import { omit, path, prop, propOr, isNil } from 'ramda';

import { dateObjectToPolishMonthYearWithWeekday } from 'common/services/dateHelpers';

import { INPUT_IDS } from '../const/ratingForm';

import i18n from 'i18next';

export const getDeliveryDateParam = bag => {
  let isEveningDelivery = false;

  // check if deliveryType is evening
  if (bag?.address?.zone?.deliveryType) {
    bag.address.zone.deliveryType.systemValue === 'EVENING'
      ? (isEveningDelivery = true)
      : (isEveningDelivery = false);
  }

  const date = dateObjectToPolishMonthYearWithWeekday(
    new Date(prop('deliveredIn', bag)),
    isEveningDelivery
  );

  const hourFrom = path(['address', 'deliveryHourFromString'], bag);
  const hourTo = path(['address', 'deliveryHourToString'], bag);

  return `${date}\n${
    hourFrom && hourTo
      ? `${i18n.t('$*common.between')} ${hourFrom} ${i18n.t(
          '$*common.and'
        )} ${hourTo}`
      : ''
  }`;
};

export const getDeliveryAddressParam = bag => {
  const address = prop('address', bag);
  const pickUpPoint = prop('pickUpPoint', bag);

  if (address) {
    const city = prop('city', address);
    const street = prop('street', address);
    const postCode = prop('postCode', address);
    const placeNumber = prop('placeNumber', address);
    const buildNumber = prop('buildNumber', address);

    return `${street} ${buildNumber}${
      !isNil(placeNumber) ? `/${placeNumber}` : ''
    }\n${postCode} ${city}`;
  } else if (pickUpPoint) {
    return prop('value', pickUpPoint);
  }

  return '';
};

export const getDietDetailsParam = (bag, t) => {
  const dietType = path(['diet', 'name'], bag);
  const calorific = path(['calorific', 'name'], bag);
  const variant = path(['variant', 'name'], bag);
  const dietLength = path(['clientDiet', 'dietLength'], bag);

  const translatedDay =
    dietLength > 1 ? t('$*dietDuration.dayPlural') : t('$*common.day');

  return `${dietType}\n${calorific}, ${variant}, ${dietLength} ${translatedDay}`;
};

export const getMealsForRating = bag => {
  const dishes = propOr([], 'items', bag);

  return dishes.map(dish => {
    const dishName = path(['dish', 'nameForClient'], dish);
    const mealType = path(['mealType', 'name'], dish);
    const dishItemId = prop('id', dish);
    const mealTypeId = path(['mealType', 'id'], dish);
    const dishId = path(['dish', 'id'], dish);
    const iri = path(['dish', '@id'], dish);
    return {
      dishItemId,
      mealType,
      mealTypeId,
      dishId,
      dishName,
      iri,
    };
  });
};

export const isMenuRated = bag => {
  return !!Object.values(getMenuRating(bag)).find(rate => !!rate);
};

export const getMealRatingsFromBagItems = items =>
  items.reduce(
    (mealItems, item) => ({
      ...mealItems,
      [prop('id', item)]: prop('rate', item),
    }),
    {}
  );

export const formatMealRatings = mealRatings =>
  Object.keys(mealRatings).reduce(
    (ratings, current) => ({
      ...ratings,
      [`${INPUT_IDS.SINGLE_MEAL_RATING_PREFIX}${current}`]: path(
        [current, 'rate'],
        mealRatings
      ),
      [`${INPUT_IDS.SINGLE_MEAL_RATING_COMMENT_PREFIX}${current}`]: path(
        [current, 'comment'],
        mealRatings
      ),
    }),
    {}
  );

export const getMenuRating = bag => {
  const rate = prop('rate', bag);
  const deliveryRate = prop('deliveryRate', bag);
  const items = propOr([], 'items', bag);

  const mealRatings = getMealRatingsFromBagItems(items);
  const formattedMealRatings = formatMealRatings(mealRatings);

  return {
    [INPUT_IDS.OVERALL_RATING_COMMENT]: prop('comment', rate),
    [INPUT_IDS.DELIVERY_RATING]: prop('rate', deliveryRate),
    [INPUT_IDS.DELIVERY_RATING_COMMENT]: prop('comment', deliveryRate),
    ...formattedMealRatings,
  };
};

export const transformBag = bag => {
  const unnecessaryKeys = ['@context', '@type'];

  return omit(unnecessaryKeys, bag);
};

export const calculateOverallRating = ratedItems => {
  const ratings = Object.values(ratedItems).filter(value => !isNaN(+value));
  const sumOfRatings = ratings.reduce((acc, cur) => acc + cur, 0);

  return Math.floor(sumOfRatings / ratings.length);
};

// Retrieves dishItemIds based on declared inputIds, e.g.
// singleMealRate-420
// "singleMealRate" - input description,
// "-" - separator,
// "420" - dish ID,
export const getDishItemIdFromInputId = inputId => inputId.split('-').pop();

export const transformBagChangeParams = (
  newParams,
  { date, optionChangeMenu, address, pickUpPoint, diet, calorific },
  { addresses, pickupPoints, diets }
) => {
  let addressToSet;
  let pickUpPointToSet;

  const newAddress = addresses.find(
    ({ ['@id']: id }) => newParams.address === id
  );
  const newPickupPoint = pickupPoints.find(
    ({ ['@id']: id }) => newParams.pickUpPoint === id
  );

  if (newAddress) {
    addressToSet = prop('@id', newAddress);
    pickUpPointToSet = null;
  }

  if (newPickupPoint) {
    addressToSet = null;
    pickUpPointToSet = prop('@id', newPickupPoint);
  }

  if (!newAddress && !newPickupPoint) {
    addressToSet = prop('@id', address);
    pickUpPointToSet = prop('@id', pickUpPoint);
  }

  return {
    optionChangeMenu,
    diet: prop('@id', diet),
    calorific: prop('@id', calorific),
    ...newParams,
    address: addressToSet,
    pickUpPoint: pickUpPointToSet,
  };
};
