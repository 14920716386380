import React, { cloneElement, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import store from 'store';
import isEmpty from 'lodash/isEmpty';

import { ContentWrapper } from 'styledComponents/blocks/ContentWrapper';

import {
  createAddress,
  updateAddress,
  saveUserAddressToLS,
} from 'common/components/UserAddresses/actions/userAddressesActions';
import createDecorator from 'final-form-calculate';
import { INPUT_IDS } from '../../const/userAddressesFormInputs';
import formMutators from 'views/NewOrder/services/formMutators';
import { trimAddressFields } from 'utils/common';
import { selectModuleConfigClientPanel } from 'selectors/appSelectors';
import { useSelector } from 'react-redux';

const formDecorators = createDecorator({
  field: INPUT_IDS.POSTAL_CODE,
  updates: {
    [INPUT_IDS.DELIVERY_HOURS]: (value, form, prevValues) => {
      const deliveryHours = store.getState().orderForm.deliveryHours;
      if (prevValues.address) {
        if (deliveryHours.length) {
          const defaultDeliveryHours = deliveryHours.find(
            deliveryHour => deliveryHour?.value?.default
          );

          if (!isEmpty(defaultDeliveryHours)) {
            return defaultDeliveryHours.value;
          } else {
            return deliveryHours[0].value;
          }
        }
      }
      return form.address.selectedDeliveryHour;
    },
  },
});

const UserAddressesFormWrapper = ({
  initialValues,
  onAfterSubmit = () => {},
  children,
  saveLocally,
  saveUserAddressToLS,
  doNotReload,
}) => {
  const [formValues, setFormValues] = useState(null);
  const { useAddressesWithLessFields } = useSelector(
    selectModuleConfigClientPanel
  );

  if (useAddressesWithLessFields && initialValues.street) {
    const [addressLine1 = '', addressLine2 = ''] = initialValues?.street?.split(
      '; '
    ) || [initialValues.addressLine1 || '', initialValues.addressLine2 || ''];

    initialValues.addressLine1 = addressLine1;
    initialValues.addressLine2 = addressLine2;
  }

  const onSubmit = useCallback(
    async (
      form,
      saveLocally,
      onAfterSubmit,
      saveUserAddressToLS,
      doNotReload
    ) => {
      if (saveLocally) {
        saveUserAddressToLS(form.address);
        onAfterSubmit(form.address?.id || 0);
      } else if (form.address['@id']) {
        return updateAddress(form, onAfterSubmit, doNotReload);
      } else {
        return createAddress(form, onAfterSubmit, doNotReload);
      }
    },
    []
  );

  return (
    <Form
      onSubmit={form => {
        if (typeof form.address === 'object') {
          form.address = trimAddressFields(form.address);
        }
        setFormValues(form.address);
        return onSubmit(
          form,
          saveLocally,
          onAfterSubmit,
          saveUserAddressToLS,
          doNotReload
        );
      }}
      mutators={formMutators}
      decorators={[formDecorators]}
      initialValues={{ address: formValues ? formValues : initialValues }}
      render={({ handleSubmit, ...rest }) => {
        return (
          <form onSubmit={handleSubmit}>
            <ContentWrapper padding="32px">
              {cloneElement(children, {
                initialValues,
                setFormValues,
                ...rest,
              })}
            </ContentWrapper>
          </form>
        );
      }}
    />
  );
};

export default connect(null, { saveUserAddressToLS })(UserAddressesFormWrapper);
