import isEmpty from 'lodash/isEmpty';

const SalesManagoLoader = ({ clientId = '', endpoint = 'salesmanago.pl' }) => {
  const salesManagoScript =
    document.querySelector('#salesManagoScript') || null;

  if (salesManagoScript === null && !isEmpty(clientId)) {
    const script = document.createElement('script');
    script.id = 'salesManagoScript';
    script.innerHTML = `
    var _smid = "${clientId}";
    (function(w, r, a, sm, s ) {
      w['SalesmanagoObject'] = r;
      w[r] = w[r] || function () {( w[r].q = w[r].q || [] ).push(arguments)};
      sm = document.createElement('script');
      sm.type = 'text/javascript';
      sm.async = true;
      sm.src = a;
      s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(sm, s);
    })(window, 'sm', ('https:' == document.location.protocol ? 'https://' : 'http://') + '${endpoint}/static/sm.js');`;

    document.body.appendChild(script);
  }

  return null;
};

export default SalesManagoLoader;
