import React from 'react';
import { connect } from 'react-redux';
import { Flex, Box } from '@rebass/grid';
import StyledCalendar from 'styledComponents/elements/SmallCalendar';
import { format, compareAsc } from 'date-fns';
import { isBefore } from 'date-fns';
import DayPicker from 'react-day-picker';
import MomentLocalUtils from 'react-day-picker/moment';
import showToast from 'utils/toast';

import { mainTheme } from 'themes/main';
import { useTranslation } from 'react-i18next';
import { mapLanguageToDatePickerLocale } from 'common/components/Calendar/services/calendarService';
import { BASE_URL, URLS } from '../../../configuration';

const DeliveryCalendar = ({
  input,
  mainColor,
  selectedDay,
  dietDays,
  initialDietDaysToChange,
  values,
  ConfigClientPanel,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dietDaysToChange = input.value;

  const availableDietDays = [...dietDays].sort((a, b) => compareAsc(a, b));

  const formattedAvailableDietDays = availableDietDays.map(date =>
    format(date.date, 'YYYY-MM-DD')
  );

  const changeDays = day => {
    const dayBlock = availableDietDays.find(
      availableDay =>
        format(availableDay.date, 'DDMMYYYY') === format(day, 'DDMMYYYY')
    ).dayBlock;

    const formattedDayBlock = dayBlock.map(day =>
      format(day.date, 'YYYY-MM-DD')
    );

    // a user can't unselect the day what he clicked on the calendar
    if (
      initialDietDaysToChange.some(dayToChange =>
        formattedDayBlock.includes(dayToChange)
      )
    )
      return;

    let days = [];

    if (
      dietDaysToChange.some(dayToChange =>
        formattedDayBlock.includes(dayToChange)
      )
    ) {
      if (values.applyForDates.length - values.dates.length <= 0) return;
      days = dietDaysToChange.filter(
        selectedDay => !formattedDayBlock.includes(selectedDay)
      );
    } else {
      days = [...dietDaysToChange, ...formattedDayBlock];
    }

    input.onChange(days);
  };

  if (availableDietDays.length === 0) return null;

  const dietDaysStyle = `.DayPicker-Day--dietDays:not(.DayPicker-Day--outside) {
      background-color: ${mainColor}!important;
      color: white!important;
    }`;

  const dietDaysToChangeStyle = `.DayPicker-Day--dietDaysToChange:not(.DayPicker-Day--outside) {
      background-color: ${mainColor}!important;
      color: ${mainTheme.colorWhite}!important;
      border: 0!important;
    }`;

  const hasAddIcon =
    ConfigClientPanel.calendarIconSubscriptionAddDelivery !== null;

  const { showCalendarIcons } = ConfigClientPanel;

  const plusIcon =
    hasAddIcon &&
    showCalendarIcons &&
    `${BASE_URL}${URLS.UPLOADED_MEDIA}${ConfigClientPanel['@resources'][
      ConfigClientPanel.calendarIconSubscriptionAddDelivery
    ]?.contentUrl
    }`;

  return (
    <Flex>
      <Box width="100%">
        <style>
          {dietDaysStyle}
          {dietDaysToChangeStyle}
        </style>
        <StyledCalendar>
          <DayPicker
            {...input}
            modifiers={{
              dietDays: formattedAvailableDietDays.map(day => new Date(day)),
            }}
            selectedDays={dietDaysToChange.map(day => new Date(day))}
            disabledDays={day => {
              const dateIsAvailable = !formattedAvailableDietDays.includes(
                format(day, 'YYYY-MM-DD')
              );

              return dateIsAvailable;
            }}
            onDayClick={day => {
              if (isBefore(day, new Date())) {
                return showToast({
                  message: t(
                    '$*menuPlanning.deliveryCalendar.dayClickError',
                    '$$Ten dzień już minął i nie może zostać przeniesiony'
                  ),
                  type: 'error',
                });
              }
              changeDays(day);
            }}
            fromMonth={new Date()}
            month={new Date(selectedDay)}
            firstDayOfWeek={1}
            locale={mapLanguageToDatePickerLocale(language)}
            localeUtils={MomentLocalUtils}
          />
        </StyledCalendar>
      </Box>
    </Flex>
  );
};

export default connect(
  ({
    menuPlanning: {
      dietDays,
      selectedDiet,
      dietDaysToChange,
      initialDietDaysToChange,
      selectedDay,
    },
    app: {
      brand: {
        mainColor,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
      },
      config: {
        modules: { ConfigClientPanel },
      },
    },
    orderForm: {
      orderConfig: { disabledDays },
    },
  }) => {
    return {
      ConfigClientPanel,
      mainColor,
      selectedDiet,
      dietDays,
      dietDaysToChange,
      initialDietDaysToChange,
      selectedDay,
      disabledDaysToOrder: disabledDays,
      daysConfig: {
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
      },
    };
  }
)(DeliveryCalendar);
