import React from 'react';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { Flex } from '@rebass/grid';

import Modal from 'common/components/Modal';
import FormFieldBox from 'common/components/Form/FormFieldBox';
import NOTIFICATIONS from 'common/shared/notifications';

import { ButtonLinkRed, ButtonLink } from 'styledComponents/elements/Button';
import { Paragraph } from 'styledComponents/elements/Typography';
import {
  INPUT_IDS,
  useBasicInformationFormInputs,
} from 'views/AccountSettings/const/userDataFormInputs';
import { updateUserData } from 'views/AccountSettings/actions/accountSettingsActions';

import showToast from 'utils/toast';
import { useTranslation } from 'react-i18next';

const MissingPhoneModal = ({ isOpened, toggleModal, updateUserData }) => {
  const { t } = useTranslation();
  const onSubmit = (form, { getState }) => {
    const { dirtyFields } = getState();
    const changedFields = Object.keys(dirtyFields);

    return updateUserData(form, changedFields).then(() => {
      toggleModal();
      showToast({
        message: NOTIFICATIONS(t).DATA_UPDATED_SUCCESSFULLY,
        type: 'success',
      });
    });
  };

  const BASIC_INFORMATION_FORM_INPUTS = useBasicInformationFormInputs();

  const { ...input } = BASIC_INFORMATION_FORM_INPUTS.find(
    input => input.id === INPUT_IDS.PHONE
  );

  return (
    <Modal
      id={isOpened}
      title={t('$*common.caution')}
      padding="25px 0"
      widthMobile="300px"
      isOpened={isOpened}
      widthDesktop="400px"
      contentPadding="0 30px"
      toggleModal={toggleModal}
    >
      <Paragraph textAlign="center">
        {t(
          '$***missingPhoneNumberModal.info',
          `$$Nie mamy jeszcze Twojego numeru telefonu, nie jest on niezbędny, ale na pewno bardzo ułatwi kontakt przy dostarczeniu Twojego zamówienia.`
        )}
      </Paragraph>
      <Form
        onSubmit={onSubmit}
        initialValues={{ phone: null }}
        render={({ handleSubmit, submitting, pristine, valid }) => (
          <form onSubmit={handleSubmit}>
            <FormFieldBox width="100%">
              <Field isBig {...input} />
            </FormFieldBox>
            <Flex justifyContent="space-between">
              <ButtonLinkRed
                type="button"
                onClick={toggleModal}
                uppercased
                sizeMiddle
                weightBold
                autoHeight
                fullWidthOnMobile
              >
                {t('$*common.skip', '$$Pomiń')}
              </ButtonLinkRed>
              <ButtonLink
                type="submit"
                uppercased
                sizeMiddle
                weightBold
                autoHeight
                disabled={submitting || pristine || !valid}
                fullWidthOnMobile
              >
                {t('$*common.save', '$$Zapisz')}
              </ButtonLink>
            </Flex>
          </form>
        )}
      />
    </Modal>
  );
};

export default connect(null, { updateUserData })(MissingPhoneModal);
