export const getHeaders = t => [
  {
    minWidth: '150px',
    label: t('$*moneyBoxContent.tableHeader.eventDate', '$$Data zdarzenia'),
  },
  {
    minWidth: '300px',
    label: t('$*moneyBoxContent.tableHeader.eventName', '$$Zdarzenie'),
  },
  {
    minWidth: '120px',
    label: t('$*moneyBoxContent.tableHeader.valueChange', '$$'),
  },
];
