import React from 'react';

import { Subtitle } from 'styledComponents/elements/Typography';
import HorizontalLine from 'styledComponents/elements/HorizontalLine';

import { dateObjectToPolishMonthYearWithWeekday } from 'common/services/dateHelpers';
import Modal from 'common/components/Modal';

import RateMenuForm from '../RateMenuForm';
import { useTranslation } from 'react-i18next';

const RateMenuModal = ({ isOpened, toggleModal, id, date, bag }) => {
  const { t } = useTranslation();

  return (
    <Modal
      id={id}
      title={t('$*menuPlanning.rateMenuModal.title', '$$Oceń menu')}
      isOpened={isOpened}
      toggleModal={() => toggleModal()}
      contentPadding="0"
      withBackButton
      fullscreen
      widthDesktop="400px"
    >
      <Subtitle noPadding textAlign="center">
        {dateObjectToPolishMonthYearWithWeekday(date)}
      </Subtitle>
      <HorizontalLine marginTop="16px" marginBottom="16px" />
      <RateMenuForm bag={bag} toggleModal={toggleModal} />
    </Modal>
  );
};

export default RateMenuModal;
