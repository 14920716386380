import React from 'react';
import { connect } from 'react-redux';
import { Box, Flex } from '@rebass/grid';
import { Field, Form } from 'react-final-form';

import HorizontalLine from 'styledComponents/elements/HorizontalLine';
import FormTextInput from 'common/components/Form/FormTextInput';
import ConfirmButtonWrapper from 'common/components/Modal/ConfirmButtonWrapper';

import {
  getMenuRating,
  getMealsForRating,
} from '../services/menuPlanningRepresenter';

import DeliveryRating from './DeliveryRating';
import SingleMealRating from './SingleMealRating';

import { DELIVERY_RATING_OPTIONS, INPUT_IDS } from '../const/ratingForm';

import { rateMenu } from '../actions/menuPlanningActions';
import { useTranslation } from 'react-i18next';

const RateMenuForm = ({ bag, meals, horizontalPadding = 26, rateMenu }) => {
  const { t } = useTranslation();
  const mealsForRating = getMealsForRating(bag);
  const mealRatings = getMenuRating(bag);

  const onSubmit = (ratedItems, { getState }) => {
    // getState - internal final form state
    const { dirtyFields } = getState();
    const changedFields = Object.keys(dirtyFields);

    rateMenu({ ratedItems, changedFields, bag });
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={
        mealRatings.deliveryRate ? mealRatings : { deliveryRate: 5 }
      }
      render={({ handleSubmit, submitting, invalid, values }) => {
        const isAllDishRaiting = mealsForRating.every(meal => {
          const ratingId = `${INPUT_IDS.SINGLE_MEAL_RATING_PREFIX}${meal.dishItemId}`;

          return ![undefined, null].includes(values[ratingId]);
        });

        return (
          <form onSubmit={handleSubmit}>
            <Flex justifyContent="center">
              <Box px={horizontalPadding} py={0} width="100%">
                {mealsForRating.map(meal => {
                  const commentId = `${INPUT_IDS.SINGLE_MEAL_RATING_COMMENT_PREFIX}${meal.dishItemId}`;
                  const ratingId = `${INPUT_IDS.SINGLE_MEAL_RATING_PREFIX}${meal.dishItemId}`;

                  const mealTypeFromOptions = meals.find(
                    mealFromOptions => +mealFromOptions.id === meal.mealTypeId
                  );
                  const dishFromOptions =
                    mealTypeFromOptions &&
                    mealTypeFromOptions.dishes.dishes.find(
                      dishFromOptions => dishFromOptions.id === meal.dishId
                    );

                  return (
                    <SingleMealRating
                      dishFromOptions={dishFromOptions}
                      meal={meal}
                      key={meal.dishItemId}
                      commentId={commentId}
                      ratingId={ratingId}
                      disabledComment={[undefined, null].includes(
                        values[ratingId]
                      )}
                    />
                  );
                })}
              </Box>
            </Flex>
            <Box px={horizontalPadding} py={0} width="100%">
              <Field
                name={INPUT_IDS.OVERALL_RATING_COMMENT}
                type="text"
                component={FormTextInput}
                label={`${t(
                  '$*menuPlanning.dietDetails.additionalComment_plural',
                  '$$Dodatkowe komentarze'
                )}:`}
                minHeight="auto"
                disabled={!isAllDishRaiting}
              />
            </Box>
            <Box px={horizontalPadding} py={0} width="100%">
              <DeliveryRating />
            </Box>
            {values[INPUT_IDS.DELIVERY_RATING] ===
              DELIVERY_RATING_OPTIONS.BAD && (
              <Box px={horizontalPadding} py={0} width="100%">
                <Field
                  name={INPUT_IDS.DELIVERY_RATING_COMMENT}
                  type="text"
                  component={FormTextInput}
                  label={`${t(
                    '$*menuPlanning.dietDetails.additionalComment',
                    '$$Dodatkowy komentarz'
                  )}:`}
                  minHeight="auto"
                />
              </Box>
            )}
            <HorizontalLine />
            <ConfirmButtonWrapper
              buttonText={t(
                '$*menuPlanning.rateMenuForm.sendRate',
                '$$Wyślij ocenę'
              )}
              isLoading={submitting}
              disabled={submitting || invalid}
              marginTop={10}
              marginLeft={25}
              marginRight={25}
            />
          </form>
        );
      }}
    />
  );
};

export default connect(({ menuPlanning: { meals } }) => ({ meals }), {
  rateMenu,
})(RateMenuForm);
