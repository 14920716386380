import React from 'react';
import ReactTooltip from 'react-tooltip';

import TooltipStyles from 'styledComponents/elements/Tooltip';

const Tooltip = ({ children, id, ...rest }) => (
  <TooltipStyles {...rest}>
    <ReactTooltip id={id} aria-haspopup="true" effect="solid" clickable>
      {children}
    </ReactTooltip>
  </TooltipStyles>
);

export default Tooltip;
