import {
  FETCH_BRAND_REQUEST,
  FETCH_BRAND_SUCCESS,
  FETCH_CONFIG_SUCCESS,
  TOGGLE_MOBILE_MENU,
} from '../actions/const';

const INITIAL_STATE = {
  brand: {},
  config: {},
  isLoading: true,
  isMobileMenuOpened: false,
};

const appReducer = (
  state = INITIAL_STATE,
  { type, brand, isMobileMenuOpened, config, payload }
) => {
  switch (type) {
    case FETCH_BRAND_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_BRAND_SUCCESS:
      return {
        ...state,
        brand,
        isLoading: false,
      };
    case TOGGLE_MOBILE_MENU:
      return {
        ...state,
        isMobileMenuOpened,
      };
    case FETCH_CONFIG_SUCCESS:
      return {
        ...state,
        config: calculateMissingModules(config),
        isLoading: false,
      };

    default:
      return state;
  }
};

export default appReducer;

// TEMP: calculate missing config until CK-434 arrive
const calculateMissingModules = config => {
  config.modules = config.modules || {};
  if (!config.modules.GoogleAnalytics) {
    config.modules.GoogleAnalytics = {
      enabled: config.allowGoogleAnalytics,
      code: config.googleAnalytics,
      useEcommerce: false,
      useEnhancedEcommerce: false,
    };
  }

  if (!config.modules.GoogleTagManager) {
    config.modules.GoogleTagManager = {
      enabled: config.allowGoogleTagManager,
      code: config.googleTagManager,
      useEcommerce: true,
      useEnhancedEcommerce: false,
    };
  }

  if (!config.modules.FacebookPixel) {
    config.modules.FacebookPixel = {
      enabled: config.allowFacebookPixel,
      id: config.facebookPixel,
    };
  }

  return config;
};
