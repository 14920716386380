import React, { Fragment } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Flex, Box } from '@rebass/grid';
import { Field, Form } from 'react-final-form';

import routeURLS from 'routes/routeUrls';
import { URLS } from 'configuration';

import { ButtonPrimary } from 'styledComponents/elements/Button';
import { Heading } from 'styledComponents/elements/Typography';

import { put } from 'utils/http';
import showToast from 'utils/toast';

import { getFormSubmitErrors } from 'common/services/formHelpers';
import { translateErrorMessages } from 'common/shared/errorMessages';
import NOTIFICATIONS from 'common/shared/notifications';
import FormSubmitError from 'common/components/Form/FormSubmitError';

import { INPUT_IDS } from 'views/Auth/const/resetPasswordInputs';
import { useTranslation } from 'react-i18next';
import FormTextInput from 'common/components/Form/FormTextInput';
import { useFormValidation } from 'common/services/formValidation';

const PasswordRemindBox = styled.div`
  max-width: 500px;
  margin: 0 auto;
  border-radius: 6px;
  border: 1px solid ${props => props.theme.baseFontColor};
  padding: 20px;
`;

const SetNewPassword = ({ history, ...props }) => {
  const { t } = useTranslation();
  const ERROR_MESSAGES = translateErrorMessages(t);
  const validators = useFormValidation();
  const onSubmit = form => {
    const submitValidationRules = [
      {
        condition: form.plainPassword !== form.repeatPlainPassword,
        errorMessage: ERROR_MESSAGES.SAME_PASSWORD,
      },
      {
        condition: form.plainPassword.length < 8,
        errorMessage: ERROR_MESSAGES.PASSWORD_TOO_SHORT,
      },
    ];

    const submitErrors = getFormSubmitErrors(form, submitValidationRules);
    if (submitErrors) {
      return submitErrors;
    } else {
      const token = props.match.params.token;
      form.token = token;
      put(URLS.RESET_PASSWORD, form)
        .then(() => {
          history.push(routeURLS.AUTH_LOGIN);
          showToast({
            message: NOTIFICATIONS(t).PASSWORD_CHANGED_SUCCESSFULLY,
            type: 'success',
          });
        })
        .catch(({ response }) =>
          showToast({
            message: response.data['hydra:description'],
            type: 'error',
          })
        );
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitError }) => {
        return (
          <Fragment>
            <form onSubmit={handleSubmit}>
              <PasswordRemindBox>
                <Flex flexDirection="column" alignItems="center">
                  <Heading>
                    {t('$*setNewPassword.heading', 'NOWE HASŁO')}
                  </Heading>
                  <Box width={[1, 1, 3 / 4]}>
                    <Field
                      isBig
                      id={INPUT_IDS.PASSWORD}
                      name={INPUT_IDS.PASSWORD}
                      type="password"
                      component={FormTextInput}
                      label={t('$*registerForm.inputs.password', 'Hasło')}
                      isRequired={true}
                      validate={validators.required}
                      width="100%"
                    />

                    <Field
                      isBig
                      id={INPUT_IDS.REPEAT_PASSWORD}
                      name={INPUT_IDS.REPEAT_PASSWORD}
                      type="password"
                      component={FormTextInput}
                      label={t(
                        '$*registerForm.inputs.repeatPassword',
                        'Powtórz hasło'
                      )}
                      isRequired={true}
                      validate={validators.required}
                      width="100%"
                    />
                  </Box>
                  <Box width={[1, 1, 'auto']}>
                    <ButtonPrimary
                      type="submit"
                      uppercased
                      sizeMiddle
                      weightBold
                      fullWidthOnMobile
                    >
                      {t('$*common.save', '$$Zapisz')}
                    </ButtonPrimary>
                  </Box>
                  <FormSubmitError errorText={submitError} mt="8px" />
                </Flex>
              </PasswordRemindBox>
            </form>
          </Fragment>
        );
      }}
    />
  );
};

export default withRouter(SetNewPassword);
