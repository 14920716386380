import { get, post, put, remove } from 'utils/http';
import { URLS } from 'configuration';
import NOTIFICATIONS from 'common/shared/notifications';

import showToast from 'utils/toast';
import { buildUrl } from 'utils/route';
import { transformUserAddressData } from '../services/userAddressesRepresenter';

import {
  FETCH_USER_ADDRESSES_REQUEST,
  FETCH_USER_ADDRESSES_SUCCESS,
  FETCH_USER_ADDRESSES_FAILURE,
  SET_FIRST_USER_ADDRESS,
  USER_ADDRESSES_LOADING_START,
  USER_ADDRESSES_LOADING_STOP,
} from './const';
import store from 'store';

export const getUserAddresses = token => {
  return dispatch => {
    dispatch({ type: FETCH_USER_ADDRESSES_REQUEST });

    if (token) {
      return getUserAddressesFromApi()
        .then(addresses => {
          const parsedAddresses = addresses.map(({ floor, ...rest }) => ({
            floor: parseInt(floor) || null,
            ...rest,
          }));

          dispatch({
            type: FETCH_USER_ADDRESSES_SUCCESS,
            list: parsedAddresses,
          });

          return parsedAddresses;
        })
        .catch(() =>
          dispatch({
            type: FETCH_USER_ADDRESSES_FAILURE,
          })
        );
    } else {
      let addresses = getUserAddressesFromLS();
      dispatch({
        type: FETCH_USER_ADDRESSES_SUCCESS,
        list: addresses,
      });

      // return Promise.resolve(addresses);

      return Promise.resolve({ list: getUserAddressesFromLS() });
    }
  };
};

export const createAddress = (form, onAfterSubmit, doNotReload) => {
  if (form) {
    const state = store.getState();
    const {
      useAddressesWithLessFields,
    } = state.app.config.modules.ConfigClientPanel;
    let address = { ...form.address };

    store.dispatch({ type: USER_ADDRESSES_LOADING_START });

    if (address.selectedDeliveryHour) {
      address.deliveryHourFrom = address.selectedDeliveryHour.hourFrom;
      address.deliveryHourTo = address.selectedDeliveryHour.hourTo;
    }

    if (useAddressesWithLessFields) {
      const addressLine1 = address.addressLine1;
      const addressLine2 = address.addressLine2;

      address.buildNumber = '0';
      address.street = [addressLine1 ?? '', addressLine2]
        .filter(Boolean)
        .join('; ');

      delete address.addressLine1;
      delete address.addressLine2;
    }

    post(URLS.CREATE_ADDRESS, address)
      .then(({ data }) => {
        if (!doNotReload) {
          window.location.reload();
        }
        store.dispatch({ type: USER_ADDRESSES_LOADING_STOP });
        showToast({
          message: NOTIFICATIONS(window.t).ADDRESS_CREATED_SUCCESSFULLY,
          type: 'success',
        });

        return data;
      })
      .then(data => {
        onAfterSubmit(data.id.toString());
      })
      .catch(({ response }) => {
        //@TODO: Dodac walidacje pod kazdym inputem, a nie w ten sposob.
        store.dispatch({ type: USER_ADDRESSES_LOADING_STOP });

        if (response?.data) {
          showToast({
            message: response.data.violations
              .map(error => `${error.propertyPath} - ${error.message}`)
              .join('\n'),
            type: 'error',
          });
        }
      });
  }
};

export const updateAddress = (form, onAfterSubmit, doNotReload) => {
  store.dispatch({ type: USER_ADDRESSES_LOADING_START });
  const state = store.getState();
  const {
    useAddressesWithLessFields,
  } = state.app.config.modules.ConfigClientPanel;
  let address = { ...form.address };

  if (address.selectedDeliveryHour) {
    address.deliveryHourFrom = address.selectedDeliveryHour.hourFrom;
    address.deliveryHourTo = address.selectedDeliveryHour.hourTo;
  }

  if (useAddressesWithLessFields) {
    const addressLine1 = address.addressLine1;
    const addressLine2 = address.addressLine2;

    address.buildNumber = '0';
    address.street = [addressLine1 ?? '', addressLine2]
      .filter(Boolean)
      .join('; ');

    delete address.addressLine1;
    delete address.addressLine2;
  }

  put(URLS.UPDATE_ADDRESS + address['@id'], address)
    .then(data => {
      if (!doNotReload) {
        window.location.reload();
      }
      store.dispatch({ type: USER_ADDRESSES_LOADING_STOP });
      showToast({
        message: NOTIFICATIONS(window.t).ADDRESS_UPDATE_SUCCESSFULLY,
        type: 'success',
      });
      return data.id;
    })
    .then(() => {
      onAfterSubmit(address.id.toString());
    })
    .catch(({ response }) => {
      store.dispatch({ type: USER_ADDRESSES_LOADING_STOP });
      showToast({
        message: response.data.violations
          ? response.data.violations
              .map(error => `${error.propertyPath} - ${error.message}`)
              .join('\n')
          : response.data['hydra:description'],
        type: 'error',
      });
    });
};

export const deleteAddress = (id, token) => dispatch => {
  const hasAddressesInLS = getUserAddressesFromLS().length;
  store.dispatch({ type: USER_ADDRESSES_LOADING_START });
  if (hasAddressesInLS && !token) {
    return deleteAddressFromLS(id, dispatch);
  } else {
    remove(buildUrl(URLS.DELETE_ADDRESS, { id })).then(() => {
      store.dispatch({ type: USER_ADDRESSES_LOADING_STOP });
      dispatch(getUserAddresses(token));
      showToast({
        message: NOTIFICATIONS(window.t).ADDRESS_DELETE_SUCCESSFULLY,
        type: 'success',
      });
    });
  }
};

export const saveUserAddressToLS = address => {
  if (address.selectedDeliveryHour) {
    address.deliveryHourFrom = address.selectedDeliveryHour.hourFrom;
    address.deliveryHourTo = address.selectedDeliveryHour.hourTo;
  } else {
    address.deliveryHourFrom = null;
    address.deliveryHourTo = null;
  }

  return () => {
    let existingAddresses = window.localStorage.getItem('addresses');
    existingAddresses = existingAddresses ? JSON.parse(existingAddresses) : {};
    existingAddresses[address.id] = address;

    if (address.isDefault) {
      Object.keys(existingAddresses).forEach(addressIndex => {
        if (existingAddresses[addressIndex].id !== address.id) {
          existingAddresses[addressIndex].isDefault = false;
        }
      });
    }

    window.localStorage.setItem('addresses', JSON.stringify(existingAddresses));
  };
};

export const clearUserAddressesFromLS = () => {
  return () => window.localStorage.removeItem('addresses');
};

function getUserAddressesFromApi() {
  return get(URLS.DELIVERY_ADDRESSES, null, true).then(({ data }) =>
    transformUserAddressData(data)
  );
}

export function getUserAddressesFromLS() {
  const addressesFromLS = window.localStorage.getItem('addresses');

  return addressesFromLS ? Object.values(JSON.parse(addressesFromLS)) : [];
}

function deleteAddressFromLS(id, dispatch) {
  let existingAddresses = window.localStorage.getItem('addresses');
  existingAddresses = existingAddresses ? JSON.parse(existingAddresses) : {};

  delete existingAddresses[id];

  window.localStorage.setItem('addresses', JSON.stringify(existingAddresses));

  dispatch(getUserAddresses());
}

export function setFirstUserAddress(firstUserAddress) {
  return {
    type: SET_FIRST_USER_ADDRESS,
    firstUserAddress: firstUserAddress,
  };
}
