import React from 'react';
import URLS from 'routes/routeUrls';
import { Box, Flex } from '@rebass/grid';
import styled from 'styled-components';
import { media } from 'styledComponents/helpers';
import { ButtonLink } from 'styledComponents/elements/Button';
import { getLimitDates } from '../../../services/calendarService';
import { dateObjectToYearMonthDay } from 'common/services/dateHelpers';
import CalendarIcon from '../../../icons/CalendarIcon';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { getUserDietCalendar } from 'views/UserDiet/actions/userDietActions';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

const BackToCalendar = styled(Box)`
  ${media.tabletDown`
    ${props => !props.subscription && 'display: none'};
  `};
`;

BackToCalendar.Link = styled(ButtonLink)`
  margin: 0;

  ${media.tabletDown`
    margin-top: 0;
  `};
`;

const BackToCalendarLink = ({
  getUserDietCalendar,
  selectedDiet,
  visibleCalendarDays,
  subscription,
  t,
  history,
}) => {
  const fetchUserDietCalendar = () => {
    const [monthStart, monthEnd] = getLimitDates(visibleCalendarDays);

    getUserDietCalendar({
      dietId: selectedDiet,
      dateFrom: dateObjectToYearMonthDay(monthStart),
      dateTo: dateObjectToYearMonthDay(monthEnd),
    });
  };

  return (
    <BackToCalendar subscription={!!subscription}>
      <BackToCalendar.Link
        type="button"
        uppercased
        weightBold
        onClick={() => {
          fetchUserDietCalendar();
          history.push(URLS.USER_DIET);
        }}
        fullWidthOnMobile
      >
        <Flex alignItems="center">
          <CalendarIcon />
          <Box ml={10}>
            {t('$*menuPlanning.backToCalendarCaption', '$$Wróć do kalendarza')}
          </Box>
        </Flex>
      </BackToCalendar.Link>
    </BackToCalendar>
  );
};

export default compose(
  connect(
    ({
      userDiet: {
        list: userDiets,
        selectedDiet,
        visibleCalendarDays,
        selectedDietObject: { subscription },
      },
    }) => ({
      selectedDiet,
      subscription,
      visibleCalendarDays,
    }),
    { getUserDietCalendar }
  ),
  withTranslation(),
  withRouter
)(BackToCalendarLink);

export { BackToCalendarLink };
