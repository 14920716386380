import { mainTheme } from 'themes/main';

export const customTheme = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    neutral50: mainTheme.baseFontColor,
    neutral80: mainTheme.baseFontColor,
  },
});

export const customStyles = {
  control: (styles, state) => ({
    ...styles,
    height: '54px',
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.1)',
    border: '1px solid transparent',
    borderRadius: state.menuIsOpen ? '8px 8px 0 0' : mainTheme.baseBorderRadius,
    cursor: 'pointer',

    '&:hover': {
      borderColor: 'transparent',
    },
  }),
  valueContainer: styles => ({
    ...styles,
    paddingLeft: '20px',
    paddingRight: '20px',
  }),
  indicatorsContainer: (styles, state) => ({
    ...styles,
    paddingRight: '5px',

    svg: {
      width: '25px',
      height: '25px',
    },
  }),
  indicatorSeparator: () => ({}),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: state.hasValue ? mainTheme.baseFontColor : 'inherit',

    '&:hover': {
      color: state.hasValue ? mainTheme.baseFontColor : 'inherit',
    },
  }),
  menu: styles => ({
    ...styles,
    marginTop: 0,
    boxShadow: '0 13px 10px 0 rgba(0, 0, 0, 0.1)',
    borderRadius: '0 0 8px 8px',
  }),
  menuList: styles => ({
    ...styles,
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: '0 0 8px 8px',
  }),
  option: (styles, state) => ({
    ...styles,
    cursor: 'pointer',
    color: state.isFocused ? mainTheme.baseFontColor : 'inherit',
    backgroundColor: 'transparent',
    paddingLeft: '25px',
    paddingRight: '25px',
  }),
  singleValue: styles => ({
    ...styles,
    color: mainTheme.baseFontColor,
  }),
};
