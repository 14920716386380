import { getComponentProps } from 'utils/componentHelpers';

import { AddonsWrapper } from '../../components/addon';

const getAddonsSection = t => [
  {
    title: t('$*orderFormAddons.chooseAddonsTitile', '$$Wybierz dodatki'),
    component: AddonsWrapper,
    showSkip: true,
    requiredProps: [],
    getComponentProps,
  },
];

export default getAddonsSection;
