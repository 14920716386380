import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import { Flex } from '@rebass/grid';

import FORM_FIELD_NAMES from '../../const/formFieldNames';
import { CONFIGURATION_FIELDS } from '../../const/formConfig/configurationFields';
import { useTranslation } from 'react-i18next';

const AdditionalUpgrades = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Flex flexWrap="wrap">
        <Field {...CONFIGURATION_FIELDS[FORM_FIELD_NAMES.WITH_ECOBOX]}>
          {t('$*orderFormAdditionalUpgrades.ecoPack', '$$Opakowanie Eko')}
        </Field>
      </Flex>
    </Fragment>
  );
};

export default AdditionalUpgrades;
