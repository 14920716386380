import React from 'react';
import { ToggleButton } from '@material-ui/lab';
import styled from 'styled-components';
import { partition } from 'lodash';
import { useTranslation } from 'react-i18next';
import { HiddenCheckbox } from 'styledComponents/elements/Form/FormCheckbox';

const StyledToggleButton = styled(ToggleButton)`
  &&.MuiToggleButton-root {
    background-color: ${props =>
      props.selected ? props.theme.baseFontColor : props.theme.colorWhite};
    border-radius: '6px';
    color: ${props =>
      props.selected ? props.theme.colorWhite : props.theme.colorLigthBlueGray};
    &:hover {
      background-color: ${props =>
        props.selected ? props.theme.baseFontColor : props.theme.colorWhite};
    }
  }
`;

export const ToggleRounded = ({ input, switchLabels }) => {
  const { t } = useTranslation();
  const [[yes], [no]] =
    switchLabels &&
    switchLabels.length &&
    partition(switchLabels, x => x.value);

  return (
    <StyledToggleButton
      value="check"
      selected={input.checked}
      onChange={() => {
        input.onChange(!input.checked);
      }}
      size="medium"
      aria-label={t('holdOnDietSwitch.active', 'hold-on diet')}
      aria-pressed={input.checked}
    >
      {input.checked ? yes.label : no.label}
      <HiddenCheckbox type="checkbox" {...input} checked={input.checked} />
    </StyledToggleButton>
  );
};
