import ReactPixel from 'react-facebook-pixel';

export const initFacebookPixel = pixelId => {
  const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
  };

  ReactPixel.init(pixelId, null, options);
};

export const trackFP = (title, data) => {
  ReactPixel.track(title, data);
};

export const confirmOrderFP = (orderId, orderPrice, currency = 'PLN') => {
  ReactPixel.track('Purchase', {
    value: orderPrice,
    currency,
  });
};
