import OverflowContent from '../OverflowContent';

const DishName = ({ name, styles = {} }) => {
  return (
    <OverflowContent
      title={name}
      lineClamp={3}
      style={{
        fontSize: '13px',
        fontWeight: 600,
        maxHeight: '80px',
        ...styles,
      }}
    >
      {name}
    </OverflowContent>
  );
};

export default DishName;
