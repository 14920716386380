import React from 'react';
import { Box, Flex } from '@rebass/grid';

import { Heading } from 'styledComponents/elements/Typography';

const HeadingSection = ({ title, children }) => (
  <Flex
    justifyContent={['center', 'center', 'center', 'space-between']}
    alignItems="center"
    flexWrap="wrap"
    flex-direction={['column, column, column, column, row']}
  >
    <Box width={[1, 1, 1, 1 / 5]} />
    <Box width={[1, 1, 1, 1 / 3]}>
      <Heading noPaddingDesktop textAlign="center">
        {title}
      </Heading>
    </Box>
    <Box width={[1, 1, 1 / 2, 1 / 5]}>{children}</Box>
  </Flex>
);

export default HeadingSection;
