import React from 'react';
import { DietIcon } from './DietIcon';
import StyledSingleDetailSection from '../../../../../styledComponents/elements/MenuPlanning/SingleDetailSection';
import { DietDetailsSection } from './index';
import { isAfter } from 'date-fns';
import showToast from '../../../../../utils/toast';
import MENU_PLANNING_MODALS from '../../../const/modal';
import FormCheckbox from '../../../../../common/components/Form/FormCheckbox';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import useIsEditable from '../hooks/useIsEditable';

const DEADLINE_BRAND_KEY = 'changeDietDeadLine';
const EDITING_ALLOW_KEY = 'allowChangeDiet';

const Packaging = ({ action, bag, t, ecoContainer }) => {
  const ecoContainersAddedToBag = bag.useEcoContainers;
  const { hasTimePassed } = useIsEditable(
    DEADLINE_BRAND_KEY,
    EDITING_ALLOW_KEY
  );

  const onInputChange = () => {
    if (hasTimePassed) {
      return showToast({
        message: t('$*menuPlanning.changetimeUp', '$$Czas na zmiany minął'),
        type: 'error',
      });
    }
    if (!ecoContainersAddedToBag) {
      action();
    }
  };

  if (!ecoContainer) return null;
  return (
    <DietDetailsSection
      title={t('$*menuPlanning.packagingTitle')}
      deadlineBrandKey={DEADLINE_BRAND_KEY}
      showTooltip
      isEditable={!hasTimePassed}
    >
      <div style={{ marginBottom: '12px', display: 'flex' }}>
        <FormCheckbox
          componentStyle="switch_extended"
          disabled={ecoContainersAddedToBag || hasTimePassed}
          label={t('$*ecoboxActivationModal.title', '$$Eko opakowania')}
          input={{
            checked: bag.useEcoContainers,
            onChange: onInputChange,
          }}
        />
      </div>
    </DietDetailsSection>
  );
};

export default compose(
  withTranslation(),
  connect(({ menuPlanning: { bag }, app: { brand: { ecoContainer } } }) => ({
    bag,
    ecoContainer,
  }))
)(Packaging);
