export const DAY_STATUSES = {
  RATE_MENU: 'RATE_MENU',
  RATED_MENU: 'RATED_MENU',
  DETAILS_SHOW_PREV: 'DETAILS_SHOW_PREV',
  TODAY: 'TODAY',
  DETAILS_SHOW_NEXT: 'DETAILS_SHOW_NEXT',
  CONFIGURABLE: 'CONFIGURABLE',
  SUBSCRIPTION_CONFIGURABLE: 'SUBSCRIPTION_CONFIGURABLE',
  SUBSCRIPTION_ENABLED_CANT_EDIT: 'SUBSCRIPTION_ENABLED_CANT_EDIT',
  SUBSCRIPTION_HOLDED: 'SUBSCRIPTION_HOLDED',
  ADD_DIET: 'ADD_DIET',
  NO_DIET: 'NO_DIET',
  // overwritten statuses
  SUBSCRIPTION_DISABLED: 'SUBSCRIPTION_DISABLED',
  SUBSCRIPTION_DISABLED_PROGRESS: 'SUBSCRIPTION_DISABLED_PROGRESS',
  SUBSCRIPTION_ENABLED: 'SUBSCRIPTION_ENABLED',
  // new statuses
  DISABLED: 'DISABLED',
  DISABLED_WITH_BAG: 'DISABLED_WITH_BAG',
  ERROR_ON_RENEW: 'ERROR_ON_RENEW',
  DELIVERED_RATED_BLOCKED: 'DELIVERED_RATED_BLOCKED',
  DELIVERED_RATED_CAN_RATE: 'DELIVERED_RATED_CAN_RATE',
  DELIVERED_RATED_PARTIALLY_BLOCKED: 'DELIVERED_RATED_PARTIALLY_BLOCKED',
  DELIVERED_RATED_PARTIALLY_CAN_RATE: 'DELIVERED_RATED_PARTIALLY_CAN_RATE',
  DELIVERED_NOT_RATED_BLOCKED: 'DELIVERED_NOT_RATED_BLOCKED',
  DELIVERED_NOT_RATED_CAN_RATE: 'DELIVERED_NOT_RATED_CAN_RATE',
  NOT_DELIVERED_BLOCKED: 'NOT_DELIVERED_BLOCKED',
  NOT_DELIVERED_WITH_CONFIGURABLE_ALL: 'NOT_DELIVERED_WITH_CONFIGURABLE_ALL',
  NOT_DELIVERED_WITH_CONFIGURABLE_WITHOUT_MENU:
    'NOT_DELIVERED_WITH_CONFIGURABLE_WITHOUT_MENU',
  EMPTY: 'EMPTY',
  NOT_DIET_CANT_PLACE_ORDER: 'NOT_DIET_CANT_PLACE_ORDER',
  NOT_DIET_CAN_PLACE_ORDER: 'NOT_DIET_CAN_PLACE_ORDER',
  SUBSCRIPTION_SUSPENDED: 'SUBSCRIPTION_SUSPENDED',
};

export const DISABLED_DAY_STATUSES = [DAY_STATUSES.NO_DIET];
