import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const DELIVERY_TAKEOUT_OPTION_ID = 1;
const DELIVERY_PICKUP_OPTION_ID = 2;

export { DELIVERY_TAKEOUT_OPTION_ID };

export const useDeliveryOptions = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  return useMemo(
    () => [
      {
        id: DELIVERY_TAKEOUT_OPTION_ID,
        type: 'takeout',
        label: t('$*deliveryOptions.toTheDoorLabel', '$$Dostawa pod drzwi'),
        i18nKey: '$*deliveryOptions.toTheDoor',
        ariaLabel: '$$Pod drzwi',
      },
      {
        id: DELIVERY_PICKUP_OPTION_ID,
        type: 'personal',
        label: t('$*deliveryOptions.pickupPointLabel', '$$Odbiór osobisty'),
        i18nKey: '$*deliveryOptions.pickupPoint',
        description: t(
          '$*deliveryOptions.pickupPointDescription',
          '$$Skorzystaj z kolejnego rabatu!'
        ),

        ariaLabel: '$$Odbiór osobisty',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [language, t]
  );
};
