import { isNotEmptyArray } from 'utils/isDefined';

import {
  INIT_FORM,
  UPDATE_FORM,
  UPDATE_FORM_ADDONS,
  SET_ADDRESS_FORM_ID,
  SET_CURRENT_FORM,
  REMOVE_FORM,
  CLEAR_FORM_FIELDS,
  CLEAR_ALL_FORMS,
  SET_DEFAULTS_FROM_URL,
  CHANGE_PAYMENT_MODE,
  SET_NAV_UPDATES,
} from '../actions/const';

import FORM_FIELD_NAMES from '../const/formFieldNames';

import { getNewAddressId } from '../services/formService';

import { sortByPosition } from 'utils/componentHelpers';
import { getDefaultDietLength } from '../services/formDecorators';

const INITIAL_STATE = {
  forms: {},
  currentFormId: undefined,
  defaultsFromUrl: null,
  paymentMode: '2',
  useNavUpdates: true,
};

const dietFormReducer = (
  state = INITIAL_STATE,
  {
    type,
    formId,
    formState,
    fieldNames,
    deliveryAddressFormId,
    config,
    addons,
    payload,
    paymentModeId,
    useNavUpdates,
  }
) => {
  switch (type) {
    case INIT_FORM:
      const { forms } = state;

      return {
        ...state,
        currentFormId: Object.keys(forms).length,
        forms: {
          ...forms,
          [Object.keys(forms).length]: getDefaultValues(
            state,
            config,
            Object.keys(forms).length
          ),
        },
      };

    case UPDATE_FORM:
      if (!state.forms[formId]) {
        return state;
      }

      return {
        ...state,
        forms: {
          ...state.forms,
          [formId]: {
            ...state.forms[formId],
            ...formState,
            addons: [...state.forms[formId].addons],
          },
        },
      };

    case UPDATE_FORM_ADDONS: {
      return {
        ...state,
        forms: {
          ...state.forms,
          [formId]: {
            ...state.forms[formId],
            addons: [...addons],
          },
        },
      };
    }

    case CHANGE_PAYMENT_MODE: {
      return {
        ...state,
        paymentMode: paymentModeId,
      };
    }

    case SET_ADDRESS_FORM_ID:
      //@TODO Do it better way, works for now
      const newAddressForm = state.forms[formId];
      newAddressForm.address = deliveryAddressFormId;

      return {
        ...state,
        forms: {
          ...state.forms,
          [formId]: newAddressForm,
        },
      };

    case CLEAR_FORM_FIELDS:
      const newFormState = fieldNames.reduce((form, fieldName) => {
        form[fieldName] = null;
        return form;
      }, state.forms[formId]);

      return {
        ...state,
        forms: {
          ...state.forms,
          [formId]: {
            ...newFormState,
            addons: [...state.forms[formId].addons],
          },
        },
      };

    case SET_CURRENT_FORM:
      return {
        ...state,
        currentFormId: formId,
      };

    case REMOVE_FORM:
      const { [formId]: _, ...newForms } = state.forms;

      return {
        ...state,
        forms: remapFormsKeys(newForms),
        currentFormId:
          state.currentFormId !== 0
            ? state.currentFormId - 1
            : state.currentFormId,
      };

    case CLEAR_ALL_FORMS:
      return {
        ...state,
        forms: {},
        currentFormId: undefined,
      };

    case SET_DEFAULTS_FROM_URL:
      return {
        ...state,
        defaultsFromUrl: payload,
      };

    case SET_NAV_UPDATES:
      return {
        ...state,
        useNavUpdates,
      };

    default:
      return state;
  }
};

function getDefaultValues(
  state,
  {
    app: {
      brand: { deliveryToDoorEnabled },
      config: {
        modules: {
          ConfigClientPanel: {
            defaultPaymentMode,
            oneTimePaymentDefaultGateway,
            subscriptionPaymentDefaultGateway,
          },
        },
      },
    },
    orderForm: { orderConfig },
    userAddresses: { list },
  },
  formId
) {
  const defaultsFromUrl = state.defaultsFromUrl;
  const { forms, currentFormId } = state;
  const currentDietForm = forms[currentFormId];

  const _package =
    isNotEmptyArray(orderConfig.packages) &&
    !!orderConfig.packages.find(pack => pack.diets.length > 0)
      ? defaultsFromUrl[FORM_FIELD_NAMES.PACKAGE] ||
        orderConfig.packages[0].id.toString()
      : null;

  let _diet;

  if (_package !== null) {
    const currentPackage = orderConfig.packages.find(
      item => item.id === parseInt(_package)
    );

    _diet = isNotEmptyArray(orderConfig.diets)
      ? defaultsFromUrl[FORM_FIELD_NAMES.DIET_TYPE] ||
        currentPackage.diets[0].id.toString()
      : null;
  } else {
    _diet = isNotEmptyArray(orderConfig.diets)
      ? defaultsFromUrl[FORM_FIELD_NAMES.DIET_TYPE] ||
        orderConfig.diets[0].id.toString()
      : null;
  }

  const _variantEntity = isNotEmptyArray(orderConfig.variants)
    ? orderConfig.variants.find(
        v => v.id === +defaultsFromUrl[FORM_FIELD_NAMES.VARIANT]
      ) || sortByPosition(orderConfig.diets[0].variants, true)[0]
    : null;

  const _calorifics = getDefaultCalorific(defaultsFromUrl, _variantEntity);

  const isOneDiet = Object.keys(state.forms).length < 1;

  const defaultGateway =
    defaultPaymentMode === '1' && isOneDiet
      ? subscriptionPaymentDefaultGateway
      : oneTimePaymentDefaultGateway;

  const values = {
    [FORM_FIELD_NAMES.DURATION]:
      defaultsFromUrl[FORM_FIELD_NAMES.DURATION] || // move value from url into method body
      getDefaultDuration({ defaultsFromUrl, orderConfig, currentDietForm }),
    [FORM_FIELD_NAMES.DELIVERY_TYPE]: deliveryToDoorEnabled ? '1' : '2',
    [FORM_FIELD_NAMES.SUBSCRIPTION_MOCKUP]: '1',
    [FORM_FIELD_NAMES.PACKAGE]: _package,
    [FORM_FIELD_NAMES.DIET_TYPE]: _diet,
    [FORM_FIELD_NAMES.VARIANT]: _variantEntity
      ? _variantEntity.id.toString()
      : null,
    [FORM_FIELD_NAMES.CALORIFICS]: _calorifics,
    [FORM_FIELD_NAMES.DELIVERY_DAYS]: [],
    [FORM_FIELD_NAMES.DIET_NAME]: `${window.t('$*common.diet.defaultName')} ${
      formId + 1
    }`,
    [FORM_FIELD_NAMES.PAYMENT_METHOD]: defaultGateway,
    [FORM_FIELD_NAMES.PAYMENT_MODE]: isOneDiet ? defaultPaymentMode : '2',
    [FORM_FIELD_NAMES.DELIVERY_ADDRESS]: getDefaultAddress(list),
    [FORM_FIELD_NAMES.TEST_MODE]: getDefaultTestMode({
      defaultsFromUrl,
      testDaysEnabled: orderConfig.testDaysEnabled,
    }),
    [FORM_FIELD_NAMES.IS_PREMIUM]: false,
    [FORM_FIELD_NAMES.ADDONS]: [],
  };

  return values;
}

const getDefaultTestMode = ({ defaultsFromUrl, testDaysEnabled }) => {
  return defaultsFromUrl[FORM_FIELD_NAMES.TEST_MODE] && testDaysEnabled
    ? true
    : false;
};

const getDefaultDuration = ({
  defaultsFromUrl,
  orderConfig,
  currentDietForm,
}) => {
  const testMode = getDefaultTestMode({
    defaultsFromUrl,
    testDaysEnabled: orderConfig.testDaysEnabled,
  });
  return getDefaultDietLength({
    orderConfig,
    form: currentDietForm,
    isTestMode: testMode,
  });
};

const getDefaultAddress = addresses => {
  const defaultAddressId = addresses.findIndex(el => el.isDefault === true);

  return defaultAddressId !== -1
    ? addresses[defaultAddressId].id.toString()
    : { isDefault: true, id: getNewAddressId(addresses) };
};

const remapFormsKeys = forms => {
  return Object.keys(forms).reduce((result, key, index) => {
    result[index] = forms[key];
    return result;
  }, {});
};

const getDefaultCalorific = (defaultsFromUrl, variantEntity) => {
  const _defCalorificId = defaultsFromUrl[FORM_FIELD_NAMES.CALORIFICS];
  const _defCalorific =
    _defCalorificId &&
    variantEntity.calories.find(c => c.id === +_defCalorificId);

  const object = _defCalorific || variantEntity.calories[0];
  return object.id.toString();
};

export default dietFormReducer;
