import React, { Component, Children, useEffect, useState } from 'react';
import onClickOutside from 'react-onclickoutside';
import { Flex, Box } from '@rebass/grid';
import { isNil } from 'ramda';

import { StyledDropdownSelectButton } from 'styledComponents/elements/Form/DropdownSelectButton';
import {
  ButtonPrimaryOutlined,
  ButtonSecondary,
} from 'styledComponents/elements/Button';

function DropdownSelectButton(props) {
  const [chosenOption, setChosenOption] = useState(null);
  const [isOpened, setIsOpened] = useState(false);

  DropdownSelectButton.handleClickOutside = () => setIsOpened(false);

  const toggleDropdown = value => setIsOpened(isNil(value) ? !isOpened : value);
  useEffect(() => {
    setChosenOption(props.defaultOption?.label);
  }, [props.defaultOption.label]);

  const onChange = (value, label) => {
    const { onChange } = props;
    if (value.value !== undefined) {
      onChange(value);
      toggleDropdown(false);
      setChosenOption(label);
    }
  };

  const { label, children } = props;

  return (
    <StyledDropdownSelectButton>
      <StyledDropdownSelectButton.Button
        type="button"
        width="auto"
        sizeMiddle
        as={chosenOption ? ButtonPrimaryOutlined : ButtonSecondary}
        onClick={() => toggleDropdown(!isOpened)}
      >
        {chosenOption || label}
        <i
          className={`fas ${isOpened ? 'fa-chevron-up' : 'fa-chevron-down'}`}
        />
      </StyledDropdownSelectButton.Button>

      {isOpened && (
        <StyledDropdownSelectButton.Dropdown>
          {Children.map(children, child => (
            <Flex>
              <Box
                onClick={ev => {
                  onChange(ev.target, child.props.label);
                }}
              >
                {child}
              </Box>
            </Flex>
          ))}
        </StyledDropdownSelectButton.Dropdown>
      )}
    </StyledDropdownSelectButton>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => DropdownSelectButton.handleClickOutside,
};

export default onClickOutside(DropdownSelectButton, clickOutsideConfig);
