// retrieves IRI based on provided ID and entity type
// example:
// { id: 1, resource: 'variants' }:
// /variants/1
export const getIRI = ({ id, resource }) => (id ? `/${resource}/${id}` : null);

export const getIDfromIRI = ({ iri }) => {
  const lastSlashIndex = iri.lastIndexOf('/');

  return iri.slice(lastSlashIndex + 1, iri.length);
};
