import React, { useState } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Field, Form } from 'react-final-form';
import { Flex, Box } from '@rebass/grid';
import { FORM_ERROR } from 'final-form';
import ReactPixel from 'react-facebook-pixel';

import URLS from 'routes/routeUrls';

import { ButtonPrimary, ButtonLink } from 'styledComponents/elements/Button';

import { login } from 'views/Auth/actions/authActions';
import { isAuthRoute } from 'views/Auth/services/authHelpers';
import { getUserData } from 'views/AccountSettings/actions/accountSettingsActions';

import { INITIAL_VALUES, INPUT_IDS } from 'views/Auth/const/loginFormInputs';

import FormFieldBox from 'common/components/Form/FormFieldBox';
import FormSubmitError from 'common/components/Form/FormSubmitError';
import FormCheckbox from '../../../../common/components/Form/FormCheckbox';
import { useTranslation } from 'react-i18next';
import FormTextInput from 'common/components/Form/FormTextInput';
import {
  useFormValidation,
  getValidators,
} from 'common/services/formValidation';

const FormContainer = styled.div`
  max-width: 300px;
  margin: 32px auto;
`;

const LoginForm = ({
  login,
  history,
  onAfterSign = () => {},
  getUserData,
  location,
  disableTracking,
}) => {
  const [rememberMe, setRememberMe] = useState(false);
  const isLogin = history.location.pathname === '/auth/login';
  const { t } = useTranslation();
  const { required, email } = useFormValidation();

  const routeRedirectedFrom = location.state?.from?.pathname;

  const onSubmit = ({ email, password }) => {
    return login(
      {
        username: email.toLowerCase(),
        password,
      },
      t
    )
      .then(() => {
        if (!disableTracking) {
          const trackName = isLogin ? 'Logowanie' : 'Dane klienta - Logowanie';
          ReactPixel.trackCustom(trackName);
        }

        rememberMe && localStorage.setItem('keepMeLoggedIn', 'true');
        if (routeRedirectedFrom) {
          return history.push(routeRedirectedFrom);
        }

        if (isAuthRoute()) {
          return history.push(URLS.USER_DIET);
        }

        getUserData();
        onAfterSign();
      })
      .catch(error => {
        return { [FORM_ERROR]: error };
      });
  };

  const remindPassword = e => {
    e.preventDefault();

    history.push(URLS.REMIND_PASSWORD);
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={INITIAL_VALUES}
      render={({
        handleSubmit,
        submitting,
        pristine,
        invalid,
        submitError,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormContainer>
            <Flex flexWrap="wrap" justifyContent="center">
              <FormFieldBox key={INPUT_IDS.EMAIL} width={'100%'}>
                <Field
                  isBig
                  id={INPUT_IDS.EMAIL}
                  name={INPUT_IDS.EMAIL}
                  type="email"
                  component={FormTextInput}
                  autoComplete="username"
                  label={t('$*loginForm.email', 'E-mail')}
                  isRequired={true}
                  validate={getValidators([required, email])}
                  width="100%"
                />
              </FormFieldBox>

              <FormFieldBox key={INPUT_IDS.PASSWORD} width={'100%'}>
                <Field
                  isBig
                  id={INPUT_IDS.PASSWORD}
                  name={INPUT_IDS.PASSWORD}
                  type="password"
                  component={FormTextInput}
                  autoComplete="current-password"
                  label={t('$*loginForm.password', 'Hasło')}
                  isRequired={true}
                  validate={required}
                  width="100%"
                />
              </FormFieldBox>
            </Flex>
            <FormSubmitError errorText={submitError} justifyContent="center" />
            <Flex>
              <Box ml={15}>
                <FormCheckbox
                  componentStyle="rounded"
                  input={{
                    checked: rememberMe,
                    onChange: () => setRememberMe(!rememberMe),
                  }}
                >
                  {t('$*loginForm.keepMeLoggedIn', '$$Nie wylogowuj mnie')}
                </FormCheckbox>
              </Box>
            </Flex>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              align
              width={1}
              mt={24}
            >
              <Box width={[1, 1, 'auto']}>
                <ButtonPrimary
                  type="submit"
                  uppercased
                  sizeMiddle
                  weightBold
                  withSpinner
                  isLoading={submitting}
                  disabled={submitting || (pristine && invalid)}
                  fullWidthOnMobile
                >
                  {t('$*loginForm.logIn', '$$Zaloguj się')}
                </ButtonPrimary>
              </Box>
              <Box width={[1, 1, 'auto']} textAlign={'center'}>
                <ButtonLink onClick={remindPassword}>
                  {t('$*loginForm.recoverPassword', '$$Przypomnij hasło')}
                </ButtonLink>
              </Box>
            </Flex>
          </FormContainer>
        </form>
      )}
    />
  );
};

export default compose(
  connect(({ nativeAppConfig: { disableTracking } }) => ({ disableTracking }), {
    login,
    getUserData,
  }),
  withRouter
)(LoginForm);
