import styled from 'styled-components';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Collapse } from '@material-ui/core';

const OldPrice = styled.span`
  color: red;
  text-decoration: line-through;
  margin-left: auto;
  margin-right: 0.5rem;
`;

const PricePartWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin: -2px -4px;
`;
const PricePartItem = styled.div`
  padding: 2px 4px;
`;

const PriceBoxCollapse = ({
  title = '',
  afterDiscount,
  beforeDiscount,
  priceParts,
  discountParts,
}) => {
  const { t } = useTranslation();
  const currencySymbol = useSelector(
    ({ app }) => app.config.multinational.currencySymbol
  );

  const [isExpaneded, setIsExpanded] = useState(false);

  const pricePartNames = {
    ADDON: t('$*orderFormTabs.addons', '$$Dodatki'),
    DIET: t('$*common.diet', '$$Dieta'),
    ZONE: t('$*basket.labelDelivery', '$$Dostawa'),
    CHANGE_MENU: t('$*orderFormTabs.premium', '$$Zmiana menu'),
    ECO_CONTAINER: t(
      '$*myOrders.orderDetailsModal.ecoContainers',
      '$$Eko opakowania'
    ),
    PICK_UP_POINT: t(
      '$*myOrders.orderDetailsModal.deliveryPoint',
      '$$Punkt odbioru'
    ),
  };

  const discountPartNames = {
    DISCOUNT_CODE: t('$*orderSummaryFields.discountCode', '$$Kod rabatowy'),
  };

  return (
    <Fragment>
      <div
        onClick={() => setIsExpanded(!isExpaneded)}
        style={{ cursor: 'pointer' }}
      >
        <div
          style={{
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'space-between',
            margin: '0 -4px',
          }}
        >
          <div style={{ padding: '0 4px' }}>{title}</div>
          <div style={{ padding: '0 4px' }}>
            {isExpaneded ? (
              <i className="fas fa-chevron-up" />
            ) : (
              <i className="fas fa-chevron-down" />
            )}
          </div>
        </div>

        <div>
          {beforeDiscount > afterDiscount && (
            <OldPrice>
              {beforeDiscount} {currencySymbol}
            </OldPrice>
          )}
          <span>
            {afterDiscount} {currencySymbol}
          </span>
        </div>
      </div>
      <Collapse in={isExpaneded} timeout="auto">
        {Object.entries(priceParts).map(([key, value]) => (
          <PricePartWrapper key={key}>
            <PricePartItem>{pricePartNames[key]}</PricePartItem>
            <PricePartItem>
              {value} {currencySymbol}
            </PricePartItem>
          </PricePartWrapper>
        ))}

        {Object.entries(discountParts).map(([key, value]) => (
          <PricePartWrapper key={key}>
            <PricePartItem>{discountPartNames[key]}</PricePartItem>
            <PricePartItem>
              -{value} {currencySymbol}
            </PricePartItem>
          </PricePartWrapper>
        ))}
      </Collapse>
    </Fragment>
  );
};

export default PriceBoxCollapse;
