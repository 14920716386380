import React, { useEffect, useRef, useState } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import StyledFormTextInput from 'styledComponents/elements/Form/FormTextInput';
import { shallowEqual, useSelector } from 'react-redux';
import { useReducer } from 'react';

import ru from 'react-phone-number-input/locale/ru';
import fi from 'react-phone-number-input/locale/fi';
import en from 'react-phone-number-input/locale/en';
import pl from 'react-phone-number-input/locale/pl';
import it from 'react-phone-number-input/locale/it';
import de from 'react-phone-number-input/locale/de';
import ua from 'react-phone-number-input/locale/ua';
import es from 'react-phone-number-input/locale/es';
import { useTranslation } from 'react-i18next';

const _labels = {
  ru,
  fi,
  en,
  pl,
  it,
  de,
  ua,
  es,
};

const getLabels = language => {
  const _lang = language.toLowerCase();
  if (Object.keys(_labels).includes(_lang)) {
    return _labels[_lang];
  } else {
    return _labels['en'];
  }
};

export const ReactPhoneNumberAdapter = ({
  input,
  meta,
  width,
  margin,
  label,
  ...rest
}) => {
  const reinitializeDispatched = useRef(0);
  const { error, touched } = meta;
  const {
    i18n: { language },
  } = useTranslation();

  const { multinational } = useSelector(
    ({
      app: {
        config: { multinational },
      },
    }) => ({
      multinational,
    }),
    shallowEqual
  );

  const initReducer = arg => {
    const initialComputedValue =
      arg && arg.countryCode
        ? { number: arg.number, countryCode: arg.countryCode }
        : { number: '', countryCode: multinational.defaultRegion.code };
    return initialComputedValue;
  };

  const [reducer, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'NUMBER':
          return { ...state, number: action.payload };
        case 'COUNTRY':
          return { ...state, countryCode: action.payload };
        case 'REINITIALIZE':
          return initReducer(action.payload);
        default:
          return state;
      }
    },
    input.value,
    initReducer
  );

  useEffect(() => {
    if (
      !shallowEqual(input.value, reducer) &&
      !shallowEqual(initReducer(input.value), reducer)
    ) {
      dispatch({ type: 'REINITIALIZE', payload: input.value });
      reinitializeDispatched.current++;
    }
  }, [input.value.number, input.value.countryCode]);

  const onCountryChange = countryCode => {
    if (countryCode !== reducer.countryCode) {
      dispatch({ type: 'COUNTRY', payload: countryCode });
    }
  };

  const onNumberChange = number => {
    if (number !== reducer.number) {
      dispatch({ type: 'NUMBER', payload: number });
    }
  };

  const { onChange } = input;

  useEffect(() => {
    if (reinitializeDispatched.current <= 0) {
      onChange(reducer);
    }
    if (reinitializeDispatched.current > 0) {
      reinitializeDispatched.current--;
    }
  }, [onChange, reducer]);

  return (
    multinational && (
      <StyledFormTextInput
        error={error && touched}
        width={width}
        margin={margin}
        withSubmitButton={false}
      >
        {label && (
          <StyledFormTextInput.Label
            isBig={rest.isBig}
            isInline={rest.isInline}
          >
            {label} {rest.isRequired && '*'}
          </StyledFormTextInput.Label>
        )}
        <StyledFormTextInput.InputContainer>
          <PhoneInput
            countryCallingCodeEditable={false}
            international
            withCountryCallingCode
            initialValueFormat
            error={error && touched}
            labels={getLabels(language)}
            {...input}
            value={reducer.number}
            onChange={onNumberChange}
            country={reducer.countryCode}
            defaultCountry={multinational.defaultRegion.code}
            onCountryChange={onCountryChange}
            {...rest}
            inputComponent={StyledFormTextInput.Text}
          ></PhoneInput>
          {touched && error && (
            <StyledFormTextInput.Error isBig={rest.isBig}>
              {error}
            </StyledFormTextInput.Error>
          )}
        </StyledFormTextInput.InputContainer>
      </StyledFormTextInput>
    )
  );
};
