import React, { Fragment } from 'react';
import { Paragraph } from 'styledComponents/elements/Typography';

const BankTransferPaymentConfirmation = ({ payload, t }) => {
  return payload?.number ? (
    <Fragment>
      <Paragraph customPadding="5px 0" isBold={true}>
        {t('$*common.accounNumber', 'Numer rachunku')}: {payload?.number || ''}
      </Paragraph>

      <Paragraph customPadding="5px 0">
        {t('$*common.additionalInformation', 'Dodatkowe informacje')}:{' '}
        {payload?.note || ''}
      </Paragraph>
    </Fragment>
  ) : null;
};
export default BankTransferPaymentConfirmation;
