import React, { Fragment, useRef, useEffect } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { connect, shallowEqual } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { isEmpty, omit, pick } from 'ramda';

import DietPriceCalculations from '../components/DietPriceCalculations';
import OrderFormTabs from '../components/OrderFormTabs';

import {
  initForm,
  updateForm,
  clearAllForms,
  setDefaultsFromUrl,
} from '../actions/dietFormActions';
import { changePaymentMode } from 'views/NewOrder/actions/orderFormActions';
import { resetOrder } from '../actions/orderFormActions';
import { getUserAddresses } from 'common/components/UserAddresses/actions/userAddressesActions';
import { clearTabs } from '../components/OrderFormTabs/actions/orderFormTabsActions';
import FIELDS_TO_BE_WATCHED_FOR_CALCULATIONS from '../const/formConfig/fieldNamesForCalculations';
import formDecorators from '../services/formDecorators';
import formMutators from '../services/formMutators';
import { parse, stringify } from 'query-string';
import { useHistory, withRouter } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import { trimAddressFields } from 'utils/common';
import { cloneDeep } from 'lodash';
import {
  setDuration,
  setSelectedDays,
} from 'common/components/Calendar/actions/calendarActions';

const formInQsProps = ['package', 'diet', 'variant', 'calorific', 'testMode'];

const formStringifiedNumbers = ['package', 'diet', 'variant', 'calorific'];

export const parseFormValuesFromSearch = search => {
  const all = parse(search, {
    parseBooleans: true,
    parseNumbers: true,
  });

  const usefull = pick(formInQsProps, all);
  const typeSpecific = formStringifiedNumbers.reduce((memo, key) => {
    if (memo[key]) {
      memo[key] = memo[key].toString();
    }
    return memo;
  }, usefull);
  return typeSpecific;
};

const NewOrderForm = ({
  forms,
  currentFormId,
  isLoading,
  updateForm,
  ConfigClientPanel: { isSubscriptionPaymentMode, isOneTimePayPaymentMode },
  location,
  changePaymentMode,
  useNavUpdates,
}) => {
  const history = useHistory();
  const updateQSrequestRef = useRef(null);

  const hasOnlySubscriptionPayment =
    isSubscriptionPaymentMode && !isOneTimePayPaymentMode;

  const currentForm = forms[currentFormId];

  useEffect(() => {
    if (hasOnlySubscriptionPayment) {
      changePaymentMode('1');
    }
  }, [hasOnlySubscriptionPayment]);

  if (isLoading || currentFormId === undefined) {
    return null;
  }

  const nextQsConfig = pick(
    formInQsProps,
    parseFormValuesFromSearch(location.search)
  );

  const paymentModeId = hasOnlySubscriptionPayment ? '1' : '2';

  const initialValues = {
    paymentMode: paymentModeId,
    saturdayInclude: true,
    sundayInclude: true,
    weekendInclude: true,
    ...currentForm,
    ...(useNavUpdates ? nextQsConfig : {}),
  };

  return (
    <Form
      key={currentFormId}
      onSubmit={() => {}}
      decorators={[formDecorators]}
      mutators={formMutators}
      initialValues={initialValues}
      render={({ values, ...rest }) => (
        <Fragment>
          <OrderFormTabs values={values} {...rest} />

          {!isEmpty(values) && (
            <DietPriceCalculations
              watchFields={FIELDS_TO_BE_WATCHED_FOR_CALCULATIONS}
              form={rest.form}
            />
          )}

          <FormSpy
            onChange={async ({ values }) => {
              let addressClone = cloneDeep(values.address);

              if (typeof values.address === 'object') {
                addressClone = trimAddressFields(addressClone);
              }

              if (updateQSrequestRef.current !== null) {
                cancelAnimationFrame(updateQSrequestRef.current);
                updateQSrequestRef.current = null;
              }
              await new Promise(resolve => {
                requestAnimationFrame(() => {
                  const { name, ...restValues } = values;
                  updateForm(currentFormId, {
                    ...restValues,
                    address: addressClone,
                  });
                  resolve('redux updated');
                });
              });

              await new Promise(resolve => {
                let fromForm = pick(formInQsProps, values);
                let qs = parseFormValuesFromSearch(window.location.search);
                let fromQs = pick(formInQsProps, qs);

                if (!shallowEqual(fromForm, fromQs)) {
                  if (updateQSrequestRef.current !== null) {
                    cancelAnimationFrame(updateQSrequestRef.current);
                    updateQSrequestRef.current = null;
                  }

                  updateQSrequestRef.current = requestAnimationFrame(() => {
                    const newSearch = {
                      ...omit(formInQsProps, qs),
                      ...fromForm,
                    };
                    history.replace({
                      ...history.location,
                      search: stringify(newSearch),
                    });
                    resolve('QS updated');
                  });
                }
              });
            }}
          />
        </Fragment>
      )}
    />
  );
};

export default compose(
  withRouter,
  connect(
    ({
      app: {
        config: {
          modules: { ConfigClientPanel },
        },
      },
      nativeAppConfig: { disableTracking },
      auth: { token },
      dietForm: { forms, currentFormId, useNavUpdates },
      orderForm: { isLoading, orderConfig, defaultsFromUrl },
    }) => ({
      token,
      forms,
      currentFormId,
      isLoading,
      orderConfig,
      defaultsFromUrl,
      ConfigClientPanel,
      useNavUpdates,
      disableTracking,
    }),
    {
      initForm,
      updateForm,
      clearAllForms,
      clearTabs,
      getUserAddresses,
      resetOrder,
      setDefaultsFromUrl,
      changePaymentMode,
      setDuration,
      setSelectedDays,
    }
  ),
  lifecycle({
    componentWillUnmount() {
      const { clearTabs, clearAllForms, resetOrder } = this.props;

      clearTabs();
      clearAllForms();
      resetOrder();
    },
    componentDidMount() {
      const {
        token,
        initForm,
        getUserAddresses,
        setDefaultsFromUrl,
        defaultsFromUrl,
        disableTracking,
      } = this.props;

      getUserAddresses(token)
        .then(() => {
          if (defaultsFromUrl === null) {
            return setDefaultsFromUrl(
              parseFormValuesFromSearch(window.location.search)
            );
          }
        })
        .then(initForm)
        .then(() => {
          !disableTracking && ReactPixel.track('ViewContent');
        });
    },
    shouldComponentUpdate(nextProps) {
      const { currentFormId, location, forms, useNavUpdates } = this.props;
      const { currentFormId: nextFormId, location: nextLocation } = nextProps;

      const currentQsConfig = pick(
        formInQsProps,
        parseFormValuesFromSearch(location.search)
      );
      const nextQsConfig = pick(
        formInQsProps,
        parseFormValuesFromSearch(nextLocation.search)
      );

      const configChanged = !shallowEqual(currentQsConfig, nextQsConfig);
      const currentForm = currentFormId >= 0 ? forms[currentFormId] || {} : {};
      const formConfig = pick(formInQsProps, currentForm);
      const configAndFormDiffers = !shallowEqual(formConfig, nextQsConfig);

      return (
        currentFormId !== nextFormId ||
        (useNavUpdates && configChanged && configAndFormDiffers)
      );
    },
  })
)(NewOrderForm);
