import React, { Fragment } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Flex, Box } from '@rebass/grid';
import { Field, Form } from 'react-final-form';

import routeURLS from 'routes/routeUrls';
import { URLS } from 'configuration';

import { post } from 'utils/http';
import showToast from 'utils/toast';

import { ButtonPrimary } from 'styledComponents/elements/Button';
import { Heading, Paragraph } from 'styledComponents/elements/Typography';
import FormTextInput from 'common/components/Form/FormTextInput';
import {
  getValidators,
  useFormValidation,
} from 'common/services/formValidation';
import { INPUT_ID } from '../const/passwordRemindInput';
import { useTranslation } from 'react-i18next';

const PasswordRemindBox = styled.div`
  max-width: 500px;
  margin: 0 auto;
  border-radius: 6px;
  border: 1px solid ${props => props.theme.baseFontColor};
  padding: 20px;
`;

const CreatePassword = ({ history, pluralNameGenitive, ...props }) => {
  const { t } = useTranslation();
  const validators = useFormValidation();

  const onSubmit = form => {
    post(URLS.SEND_PASSWORD_RESET_EMAIL, form)
      .then(() => history.push(routeURLS.RESET_PASSWORD_EMAIL_SENT))
      .catch(({ response }) =>
        showToast({
          message: response.data['hydra:description'],
          type: 'error',
        })
      );
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => {
        return (
          <Fragment>
            <form onSubmit={handleSubmit}>
              <PasswordRemindBox>
                <Flex flexDirection="column" alignItems="center">
                  <Heading>
                    {t('$*createPassword.heading', 'WYGENERUJ HASŁO')}
                  </Heading>
                  <Box width={1}>
                    <Paragraph textAlign="center" noPadding>
                      {t(
                        '$*createPassword.label',
                        'Stworzyliśmy dla Ciebie panel, w którym możesz zobaczyć i zarządzać swoim zamówieniem.'
                      )}
                    </Paragraph>
                    <Paragraph textAlign="center">
                      {t('$*createPassword.pointsInfoMessage', {
                        defaultValue: `Podaj swój adres email, a następnie postępuj zgodnie z
                        otrzymanymi instrukcjami. Zacznij swoją przygodę z naszą
                        aplikacją i zgarnij 1000 {{points}} na start!`,
                        replace: {
                          points:
                            pluralNameGenitive ||
                            t('$*common.pointsLiteralGerundium', '$$punktów'),
                        },
                      })}
                    </Paragraph>
                  </Box>
                  <Box width={[1, 1, 3 / 4]}>
                    <Field
                      isBig
                      id={INPUT_ID.EMAIL}
                      name={INPUT_ID.EMAIL}
                      type="email"
                      component={FormTextInput}
                      label="E-mail"
                      isRequired={true}
                      validate={getValidators([
                        validators.required,
                        validators.email,
                      ])}
                      width="100%"
                    />
                  </Box>
                  <Box width={[1, 1, 'auto']} mb={'25px'}>
                    <ButtonPrimary
                      type="submit"
                      uppercased
                      sizeMiddle
                      weightBold
                      fullWidthOnMobile
                    >
                      {t('$*common.send', 'Wyślij')}
                    </ButtonPrimary>
                  </Box>
                </Flex>
              </PasswordRemindBox>
            </form>
          </Fragment>
        );
      }}
    />
  );
};

export default compose(
  connect(
    ({
      app: {
        brand: { moneyBoxConfigurationNames: pluralNameGenitive },
      },
    }) => ({ pluralNameGenitive }),
    null
  ),
  withRouter
)(CreatePassword);
