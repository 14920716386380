import { parse, stringify } from 'query-string';
import history from 'history.js';

const currentSearch = () => parse(history.location.search);

export const getFromQueryString = type => currentSearch()[type] || null;

/**
 * @deprecated that method is possibly source of evil
 */
export const addToQueryString = (type, value) => {
  const url = {
    ...currentSearch(),
    [type]: value,
  };

  const stringifiedUrl = stringify(url);

  history.push({
    pathname: history.location.pathname,
    search: `?${stringifiedUrl}`,
  });
};
