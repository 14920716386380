export const FETCH_USER_DATA_REQUEST =
  'accountSettings/FETCH_USER_DATA_REQUEST';
export const FETCH_USER_DATA_SUCCESS =
  'accountSettings/FETCH_USER_DATA_SUCCESS';
export const FETCH_USER_MONEYBOX_SUCCESS =
  'accountSettings/FETCH_USER_MONEYBOX_SUCCESS';
export const FETCH_USER_DATA_FAILURE =
  'accountSettings/FETCH_USER_DATA_FAILURE';

export const UPDATE_USER_DATA_REQUEST =
  'accountSettings/UPDATE_USER_DATA_REQUEST';
export const UPDATE_USER_DATA_SUCCESS =
  'accountSettings/UPDATE_USER_DATA_SUCCESS';
export const UPDATE_USER_DATA_FAILURE =
  'accountSettings/UPDATE_USER_DATA_FAILURE';

export const CLEAR_USER_DATA = 'accountSettings/CLEAR_USER_DATA';

export const CHANGE_PASSWORD_REQUEST =
  'accountSettings/CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS =
  'accountSettings/CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE =
  'accountSettings/CHANGE_PASSWORD_FAILURE';
