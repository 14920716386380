import { get, put } from 'utils/http';

import { URLS } from 'configuration';

import {
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  FETCH_USER_DATA_FAILURE,
  FETCH_USER_DATA_REQUEST,
  FETCH_USER_DATA_SUCCESS,
  UPDATE_USER_DATA_FAILURE,
  UPDATE_USER_DATA_REQUEST,
  UPDATE_USER_DATA_SUCCESS,
  FETCH_USER_MONEYBOX_SUCCESS,
} from './const';
import { prepareUserDataRequest } from '../services/userDataFormRepresenter';

import { getValidationViolations } from 'common/services/formHelpers';
import HTTP_CODES from 'common/shared/httpCodes';

import { refreshLogin } from 'views/Auth/actions/authActions';

export const getUserData = () => dispatch => {
  dispatch({
    type: FETCH_USER_DATA_REQUEST,
  });

  return get(URLS.USER_DATA)
    .then(({ data }) =>
      dispatch({
        type: FETCH_USER_DATA_SUCCESS,
        userData: data,
      })
    )
    .catch(error => {
      dispatch({
        type: FETCH_USER_DATA_FAILURE,
        error,
      });

      throw new Error(error);
    });
};

export const getUserMoneyboxBalance = () => dispatch => {
  dispatch({
    type: FETCH_USER_DATA_REQUEST,
  });

  return get(URLS.USER_DATA, { properties: ['moneyBox'] })
    .then(({ data }) => {
      return dispatch({
        type: FETCH_USER_MONEYBOX_SUCCESS,
        moneyBox: data.moneyBox,
      });
    })
    .catch(error =>
      dispatch({
        type: FETCH_USER_DATA_FAILURE,
        error,
      })
    );
};

export const updateUserData = (formData, changedFields) => dispatch => {
  dispatch({
    type: UPDATE_USER_DATA_REQUEST,
  });

  const requestData = prepareUserDataRequest(formData, changedFields);

  return put(URLS.USER_DATA, requestData)
    .then(({ data }) =>
      dispatch({
        type: UPDATE_USER_DATA_SUCCESS,
        userData: data,
      })
    )
    .then(() => dispatch(refreshLogin()))
    .catch(error => {
      const errorCode = error.response.status;
      const errorText = getValidationViolations(error.response.data.violations);

      dispatch({
        type: UPDATE_USER_DATA_FAILURE,
        error,
      });

      throw errorCode === HTTP_CODES.BAD_REQUEST ? errorText : error;
    });
};

export const changePassword = passwords => dispatch => {
  dispatch({
    type: CHANGE_PASSWORD_REQUEST,
  });

  return put(URLS.CHANGE_PASSWORD, passwords)
    .then(({ data }) =>
      dispatch({
        type: CHANGE_PASSWORD_SUCCESS,
        userData: data,
      })
    )
    .then(() => dispatch(refreshLogin()))
    .catch(error => {
      const errorCode = error.response.status;
      const errorText = getValidationViolations(error.response.data.violations);

      dispatch({
        type: CHANGE_PASSWORD_FAILURE,
        error,
      });

      throw errorCode === HTTP_CODES.BAD_REQUEST ? errorText : error;
    });
};
