import React from 'react';

const CustomShareIcon = ({ src, size, onClick, alt }) => {
  return (
    <div onClick={onClick}>
      <img
        style={{
          height: `${size}px`,
          pointerEvents: 'none',
          userSelect: 'none',
        }}
        src={src}
        alt={alt}
      />
    </div>
  );
};

export default CustomShareIcon;
