import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Flex, Box } from '@rebass/grid';
import { Form, Field, FormSpy } from 'react-final-form';
import { compose, lifecycle } from 'recompose';

import {
  setInvoiceData,
  setInvoiceDataType,
} from 'views/NewOrder/actions/orderFormActions';
import { getUserData } from 'views/AccountSettings/actions/accountSettingsActions';
import { setNextTabDisability } from 'views/NewOrder/components/OrderFormTabs/actions/orderFormTabsActions';
import { useTranslation } from 'react-i18next';
import FormTextInput from 'common/components/Form/FormTextInput';
import {
  getValidators,
  useFormValidation,
} from 'common/services/formValidation';
import FORM_FIELD_NAMES from '../../const/formFieldNames';

const getInitialValues = (invoiceDataType, userData, orderForm) => {
  if (invoiceDataType === 'Receipt') {
    return {
      invoiceVatNumber: '',
    };
  }

  return {
    invoiceVatNumber: orderForm.invoiceVatNumber
      ? orderForm.invoiceVatNumber
      : userData.invoiceVatNumber,
  };
};

const SummaryInvoiceInfo = ({ orderForm, setInvoiceData, userData }) => {
  const { t } = useTranslation();
  const validators = useFormValidation();

  return (
    orderForm.invoiceDataType && (
      <Form
        onSubmit={() => {}}
        initialValues={getInitialValues(
          orderForm.invoiceDataType,
          userData,
          orderForm
        )}
        render={() => {
          return (
            <Fragment>
              <FormSpy
                subscription={{
                  valid: true,
                  dirty: true,
                  values: true,
                  initialValues: true,
                }}
                onChange={({ values, valid }) => {
                  setInvoiceData(values, valid);
                }}
              />
              <Flex
                justifyContent="space-between"
                mt={['25px', '25px', '0']}
                flexDirection={['column', 'column', 'row']}
              >
                <Box width={[1, 1, 1 / 4]} ml={['0', '0', 'auto']}>
                  <Field
                    name={FORM_FIELD_NAMES.INVOICE_VAT_NUMBER}
                    type="text"
                    component={FormTextInput}
                    isBig={true}
                    label={t(
                      '$*orderSummaryFields.invoiceVatNumber',
                      '$$NIP: '
                    )}
                    isRequired={true}
                    validate={getValidators([
                      validators.required,
                      validators.nipValidator,
                    ])}
                    parse={validators.parseNumber}
                    maxLength={10}
                    width="100%"
                  />
                </Box>
              </Flex>
            </Fragment>
          );
        }}
      />
    )
  );
};

export default compose(
  connect(
    ({ orderForm, accountSettings: { userData } }) => ({
      orderForm,
      userData,
    }),
    {
      setInvoiceData,
      getUserData,
      setInvoiceDataType,
      setNextTabDisability,
    }
  ),
  lifecycle({
    componentDidMount() {
      const {
        user,
        setNextTabDisability,
        getUserData,
        userData,
        orderForm: { isInvoiceIncluded, invoiceDataType, isInvoiceDataValid },
      } = this.props;
      if (user) {
        getUserData();
      }

      setNextTabDisability(isInvoiceIncluded && isInvoiceDataValid);
    },
    componentDidUpdate() {
      const {
        setNextTabDisability,
        orderForm: { isInvoiceIncluded, invoiceDataType, isInvoiceDataValid },
      } = this.props;
      setNextTabDisability(isInvoiceIncluded && !isInvoiceDataValid);
    },
  })
)(SummaryInvoiceInfo);
