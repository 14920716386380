import { FORM_ERROR } from 'final-form';

export const getFormInput = (id, inputs) =>
  inputs.find(input => input.id === id);

export const getFormInputs = (inputIds, inputs) =>
  inputs.filter(input => inputIds.includes(input.id));

export const getFormSubmitErrors = (form, submitValidationRules) => {
  const submitErrors = submitValidationRules.reduce(
    (errors, rule) =>
      rule.condition ? [...errors, rule.errorMessage] : errors,
    []
  );

  const parsedErrorText = submitErrors.map(error => `${error}\n`);

  return (
    submitErrors.length > 0 && {
      [FORM_ERROR]: parsedErrorText,
    }
  );
};

export const getValidationViolations = violations =>
  violations.map(violation => `${violation.message}\n`);
