import styled from 'styled-components';

import {
  BaseWrapper,
  BaseDayNumber,
} from 'styledComponents/elements/BigCalendarDayCell';
import { fromPxToRem } from 'styledComponents/helpers';

const SwitcherDayCellMobile = styled(BaseWrapper)`
  border-radius: 4px;
  transform: ${props => (props.isSelectedDay ? 'scale(1.25)' : 0)};
  border: ${props =>
    props.isAddDayPossible ? `1px solid ${props.theme.baseFontColor}` : `none`};
  background-color: ${props =>
    props.disabled || props.faded || props.isAddDayPossible
      ? props.theme.colorWhite //props.theme.colorIceBlue
      : props.hasBaseFontBackground
      ? props.theme.baseFontColor
      : props.theme.colorIceBlue};
  color: ${props =>
    props.disabled || props.isAddDayPossible
      ? props.theme.bodyColor
      : props.hasBaseFontBackground
      ? props.theme.colorIceBlue
      : props.theme.baseFontColor};

  ${props =>
    props.isPastDayWithoutDiet &&
    `
      background: ${props.theme.colorIceBlue};
      color: ${props.theme.bodyColor};
    `}
  font-weight: 900;
  text-align: center;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  cursor: pointer;
  transition: 0.3s background;
  height: 30px !important;
  width: 34px !important;

  ${props =>
    props.isDayToday &&
    `
    &:after {
      content: '•';
      font-size: 12px;
      display: inline-block;
      width: 100%;
      bottom: -6px;
      position: absolute;
    }
  `}
`;

SwitcherDayCellMobile.DayNumber = styled(BaseDayNumber)`
  line-height: 1;
  margin-bottom: 5px;
  font-weight: 400;
  font-size: ${fromPxToRem('12px')};
`;

export default SwitcherDayCellMobile;
