import React from 'react';

import StyledMenuEditingPeriodInfo from 'styledComponents/elements/MenuPlanning/MenuEditingPeriodInfo';

import {
  dateISOStringToPolishTransformed,
  dateISOStringToTime,
} from 'common/services/dateHelpers';

import Tooltip from 'common/components/Tooltip';
import { useTranslation } from 'react-i18next';

const dueTimeTooltip = `due-time-tooltip`;

const MenuEditingPeriodInfo = ({ dueDate, changeIsExpired }) => {
  const { t } = useTranslation();
  return (
    <StyledMenuEditingPeriodInfo>
      <StyledMenuEditingPeriodInfo.IconWrapper
        data-for={dueTimeTooltip}
        data-tip={dueTimeTooltip}
      >
        <i className="fas fa-exclamation-circle" />
      </StyledMenuEditingPeriodInfo.IconWrapper>
      <Tooltip id={dueTimeTooltip} hide={false} maxWidth={'250px'}>
        <StyledMenuEditingPeriodInfo.InfoText>
          {changeIsExpired
            ? t('$*menuEditingPeriodInfo.msgA', {
                replace: {
                  date: dateISOStringToPolishTransformed(dueDate),
                  time: dateISOStringToTime(dueDate),
                },
              })
            : t('$*menuEditingPeriodInfo.msgB', {
                defaultValue:
                  'Zmian możesz dokonać do {{date}}, do godziny {{time}}',
                replace: {
                  date: dateISOStringToPolishTransformed(dueDate),
                  time: dateISOStringToTime(dueDate),
                },
              })}
        </StyledMenuEditingPeriodInfo.InfoText>
      </Tooltip>
    </StyledMenuEditingPeriodInfo>
  );
};

export default MenuEditingPeriodInfo;
