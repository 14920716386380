import React, { Fragment, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { media } from 'styledComponents/helpers';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Flex } from '@rebass/grid';
import { Link, withRouter } from 'react-router-dom';

import URLS from 'routes/routeUrls';

import { toogleMobileMenu } from 'views/App/actions/appActions';
import { logout } from 'views/Auth/actions/authActions';

import StyledHeader from 'styledComponents/blocks/Header';
import { Paragraph } from 'styledComponents/elements/Typography';

import Logo from 'common/components/Logo';
import Navbar from 'common/components/Navbar';
import MoneyBoxBalance from 'common/components/Navbar/MoneyBoxBalance';
import withMoneyBoxHistory from 'common/components/MoneyBox';

import NavbarButton from 'common/components/Navbar/NavbarButton';
import NavbarHamburger from 'common/components/Navbar/NavbarHamburger';
import MobileMenu from 'common/components/Navbar/MobileMenu';
import MobileMenuItem from 'common/components/Navbar/MobileMenuItem';
import { clearUserAddressesFromLS } from 'common/components/UserAddresses/actions/userAddressesActions';

import { useTranslation } from 'react-i18next';
import { useHeaderItems } from './const';
import { LanguageSelector } from './LanguageSelector';

const StyledParagraph = styled(Paragraph)`
  padding: 0;
  top: 28px;
  right: 311px;
  font-size: 11px;
  position: absolute;

  @media (max-width: ${({ mediaMobileCss }) => mediaMobileCss}) {
    top: 50%;
    left: -15px;
    right: initial;
    transform: translate(-100%, -50%);
    font-size: 12px;
    min-width: fit-content;
  }

  ${media.mobileDown`
    right: 155px;
    min-width: fit-content;
  `}

  @media (max-width: 500px) {
    left: 45px;
    right: initial;
    transform: translate(-50%, 15px);
  }
`;

const Header = ({
  history,
  token,
  logout,
  isMobileMenuOpened,
  toogleMobileMenu,
  clearUserAddressesFromLS,
  userData,
  email,
  ConfigClientPanel,
  clientAllowedToChangeLanguage,
}) => {
  const [logoWidth, setLogoWidth] = useState(0);
  const [mediaMobileCss, setMediaMobileCss] = useState(1240);

  const wrapperRef = useRef(null);
  const rightNavRef = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (window.outerWidth > 1240) {
      const computedStyle = getComputedStyle(wrapperRef.current);
      const paddingWrapper =
        parseFloat(computedStyle.paddingLeft) +
        parseFloat(computedStyle.paddingRight);

      const LogoNavBar =
        paddingWrapper + logoWidth + 575 + rightNavRef.current.offsetWidth;
      const breakpointWidth = LogoNavBar > 1240 ? LogoNavBar : 1240;

      setMediaMobileCss(breakpointWidth);
    }
  }, [logoWidth]);

  const authButtonAction = async () => {
    if (token) {
      await logout();
    }

    clearUserAddressesFromLS();
    history.push(URLS.AUTH_LOGIN);
  };

  const handleOnLoadLogo = ({ target }) => {
    const computedStyle = getComputedStyle(target);
    const marginImage =
      parseFloat(computedStyle.marginLeft) +
      parseFloat(computedStyle.marginRight);
    const LogoWidth = target.offsetWidth + marginImage;

    setLogoWidth(LogoWidth);
  };

  const authButtonText = token
    ? t('$*header.logOut', '$$Wyloguj się')
    : t('$*header.logIn', '$$Zaloguj się');
  const authButtonIcon = token ? 'power-off' : 'sign-in-alt';

  const headerItems = useHeaderItems();

  return (
    <Fragment>
      <StyledHeader>
        {email && (
          <StyledHeader.AdminInfo>
            {t(
              '$*header.userWithAdminClaims',
              '$$UWAGA! ZALOGOWANO JAKO ADMINISTRATOR, NA KONCIE UŻYTKOWNIKA.'
            )}
          </StyledHeader.AdminInfo>
        )}
        <StyledHeader.Wrapper ref={wrapperRef}>
          <Logo onLoadLogo={handleOnLoadLogo} />
          <Navbar
            items={headerItems}
            token={token}
            mediaMobileCss={`${mediaMobileCss}px`}
          />
          <Flex
            ml="auto"
            alignItems="center"
            style={{ position: 'relative' }}
            ref={rightNavRef}
          >
            {token && userData.id && ConfigClientPanel?.allowClientSeeOwnId && (
              <StyledParagraph
                isBold
                mediaMobileCss={`${mediaMobileCss}px`}
              >{`ID: ${userData.id}`}</StyledParagraph>
            )}

            {token && ConfigClientPanel?.allowClientSeeMoneyBox && (
              <MoneyBoxBalance moneyBoxPoints={userData.moneyBox} />
            )}
            {token && (
              <NavbarButton icon="cog" mediaMobileCss={`${mediaMobileCss}px`}>
                <Link to={URLS.ACCOUNT_SETTINGS}>
                  {t('$*header.accountSettings', '$$Ustawienia konta')}
                </Link>
              </NavbarButton>
            )}
            {clientAllowedToChangeLanguage && (
              <LanguageSelector maxMobileWidth={mediaMobileCss} hideOnMobile />
            )}
            <NavbarButton
              icon={authButtonIcon}
              onClick={authButtonAction}
              mediaMobileCss={`${mediaMobileCss}px`}
            >
              {authButtonText}
            </NavbarButton>

            <NavbarHamburger
              isMobileMenuOpened={isMobileMenuOpened}
              handleNavigationChange={() =>
                toogleMobileMenu(!isMobileMenuOpened)
              }
              mediaMobileCss={`${mediaMobileCss}px`}
            />
          </Flex>
        </StyledHeader.Wrapper>
      </StyledHeader>
      <MobileMenu
        showMobileMenu={isMobileMenuOpened}
        mediaMobileCss={`${mediaMobileCss}px`}
      >
        {clientAllowedToChangeLanguage && (
          <MobileMenuItem key="lnSelect">
            <LanguageSelector maxMobileWidth={mediaMobileCss} fullWidth />
          </MobileMenuItem>
        )}
        {headerItems.map((item, index) =>
          item.checkForToken ? (
            token && (
              <MobileMenuItem
                key={index}
                label={item.label}
                onClickAdditionalAction={() => history.push(item.link)}
                isBold={item.isBold}
                handleMenuClosing={toogleMobileMenu}
              />
            )
          ) : (
            <MobileMenuItem
              key={index}
              label={item.label}
              onClickAdditionalAction={() => history.push(item.link)}
              isBold={item.isBold}
              handleMenuClosing={toogleMobileMenu}
            />
          )
        )}
        {token && (
          <MobileMenuItem
            label={t('$*header.accountSettings', '$$Ustawienia konta')}
            onClickAdditionalAction={() => history.push(URLS.ACCOUNT_SETTINGS)}
            handleMenuClosing={toogleMobileMenu}
            icon="cog"
          />
        )}
        <MobileMenuItem
          label={authButtonText}
          onClickAdditionalAction={authButtonAction}
          handleMenuClosing={toogleMobileMenu}
          icon={authButtonIcon}
        />
      </MobileMenu>
    </Fragment>
  );
};

export default compose(
  connect(
    ({
      auth: { token, email },
      app: {
        isMobileMenuOpened,
        config: {
          multinational: { clientAllowedToChangeLanguage },
          modules: { ConfigClientPanel },
        },
      },
    }) => ({
      email,
      token,
      ConfigClientPanel,
      isMobileMenuOpened,
      clientAllowedToChangeLanguage,
    }),
    { logout, toogleMobileMenu, clearUserAddressesFromLS }
  ),
  withRouter,
  withMoneyBoxHistory
)(Header);
