import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, useForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { Box, Flex } from '@rebass/grid';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';

import DurationSlider from 'common/components/Form/DurationSlider';
import FormSelectBox from 'common/components/Form/FormSelectBox';
import FormRadioGroup from 'common/components/Form/FormRadioGroup';
import FormRadio from 'common/components/Form/FormRadio';
import { clearSelectedDays } from 'common/components/Calendar/actions/calendarActions';

import FORM_FIELD_NAMES from 'views/NewOrder/const/formFieldNames';
import {
  changePaymentMode,
  setPaymentMethod,
} from 'views/NewOrder/actions/orderFormActions';
import { CONFIGURATION_FIELDS } from 'views/NewOrder/const/formConfig/configurationFields';
import { setNextTabDisability } from 'views/NewOrder/components/OrderFormTabs/actions/orderFormTabsActions';

const CheckList = styled.div`
  ${({ theme }) => `border-top: 1px solid ${theme.baseFontColor}`};
  padding-top: 15px;
`;

CheckList.Item = styled.div`
  position: relative;
  margin-left: 25px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    position: absolute;
    content: '✓';
    margin-left: -25px;
    font-size: 18px;
    ${({ theme }) => `color: ${theme.baseFontColor}`};
  }
`;

const CustomLabel = styled.div`
  text-align: center;
`;

const PaymentMode = ({
  PaymentModesCopy: { single, subscription } = {},
  changePaymentMode,
  clearSelectedDays,
  ConfigClientPanel: {
    isSubscriptionPaymentMode,
    isOneTimePayPaymentMode,
    oneTimePaymentDefaultGateway,
    subscriptionPaymentDefaultGateway,
  },
  days,
  dietCount,
  hasAnyPayedOrder,
  orderConfig,
  paymentMode,
  setNextTabDisability,
  setPaymentMethod,
  testDays,
  testDaysEnabled,
}) => {
  const formInstance = useForm(); // forms[currentFormId];
  const currentForm = formInstance.getState().values;
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const createOptionsFromRange = t => {
    const { daysMin, daysMax, testDaysMin, testDaysMax } = orderConfig;

    const range = (start, end) => {
      return Array(end - start + 1)
        .fill()
        .map((_, idx) => {
          const number = start + idx;

          const result = {
            value: number,
            label: `${number} ${
              number === 1
                ? `${t('$*common.day', '$$dzień')} ${
                    currentForm.testMode
                      ? t('$*dietDuration.testSingular', '$$próbny')
                      : ''
                  }`
                : `${t('$*dietDuration.dayPlural', '$$dni')} ${
                    currentForm.testMode
                      ? t('$*dietDuration.testPlural', '$$próbne')
                      : ''
                  }`
            }`,
          };

          return result;
        });
    };

    return currentForm.testMode
      ? range(testDaysMin, testDaysMax)
      : range(daysMin, daysMax);
  };

  const oneTimeChecklistItems = !isEmpty(single?.[language])
    ? single?.[language]
    : t('$*paymentMode.singlePayment.checklistItems', {
        returnObjects: true,
      });
  const subscriptionChecklistItems = !isEmpty(subscription?.[language])
    ? subscription?.[language]
    : t('$*paymentMode.subscriptionPayment.checklistItems', {
        returnObjects: true,
      });

  const orderTypes = [
    ...(isOneTimePayPaymentMode
      ? [
          {
            id: 2,
            name: t('$*paymentMode.singlePayment', '$$Płatność jednorazowa'),
            description: (
              <CheckList>
                {oneTimeChecklistItems.map((item, index) => (
                  <CheckList.Item key={index}>{item}</CheckList.Item>
                ))}
              </CheckList>
            ),
          },
        ]
      : []),
    ...(isSubscriptionPaymentMode
      ? [
          {
            id: 1,
            name: t('$*paymentMode.subscriptionPayment', '$$Subskrypcja'),
            description: (
              <CheckList>
                {subscriptionChecklistItems.map((item, index) => (
                  <CheckList.Item key={index}>{item}</CheckList.Item>
                ))}
              </CheckList>
            ),
            disabled: dietCount > 1,
          },
        ]
      : []),
  ];

  return (
    <Fragment>
      {orderTypes?.length > 1 && (
        <FormRadioGroup style={{ margin: '0 -10px 25px' }}>
          {orderTypes.map(type => {
            return (
              <Fragment key={type.id}>
                <Field
                  name={FORM_FIELD_NAMES.PAYMENT_MODE}
                  value={type.id.toString()}
                  customLabel={<CustomLabel>{type.name}</CustomLabel>}
                  description={type.description}
                  type="radio"
                  component={FormRadio}
                  componentStyle="primary2Columns"
                  disabled={type.disabled}
                />
                <OnChange name={FORM_FIELD_NAMES.PAYMENT_MODE}>
                  {paymentModeId => {
                    clearSelectedDays();
                    setNextTabDisability(true);

                    const defaultGateway =
                      paymentModeId === '1'
                        ? subscriptionPaymentDefaultGateway
                        : oneTimePaymentDefaultGateway;

                    setPaymentMethod(defaultGateway);

                    if (paymentModeId === '1') {
                      formInstance.mutators.setFieldValue(
                        FORM_FIELD_NAMES.PAYMENT_METHOD,
                        defaultGateway
                      );
                    }

                    changePaymentMode(paymentModeId);
                  }}
                </OnChange>
              </Fragment>
            );
          })}
        </FormRadioGroup>
      )}

      {paymentMode === '2' && (
        <Flex
          alignItems={['flex-start', 'flex-start', 'flex-start']}
          flexDirection={['column', 'column', 'column']}
        >
          {testDaysEnabled && !hasAnyPayedOrder && (
            <Box m={['15px 0', '15px 0', '15px 0']}>
              <Field {...CONFIGURATION_FIELDS[FORM_FIELD_NAMES.TEST_MODE]}>
                {t('$*dietDuration.testOrderLabel', '$$Zamówienie próbne')}
              </Field>
            </Box>
          )}
          <Fragment>
            {orderConfig[
              currentForm.testMode ? 'testDaysSelectorType' : 'daysSelectorType'
            ] === 'PREDEFINED_OPTIONS' && (
              <Box width={[1, 0.5, 5 / 12]}>
                <Field
                  options={currentForm.testMode ? testDays : days}
                  name={FORM_FIELD_NAMES.DURATION}
                  type="select"
                  component={FormSelectBox}
                  placeholder={t(
                    '$*newOrder.paymentMode.inputDuration.placeholder',
                    '$$Czas trwania'
                  )}
                />
                <OnChange name={FORM_FIELD_NAMES.DURATION}>
                  {() => {
                    requestAnimationFrame(() => {
                      clearSelectedDays();
                      setNextTabDisability(true);
                    });
                  }}
                </OnChange>
              </Box>
            )}
            {orderConfig[
              currentForm.testMode ? 'testDaysSelectorType' : 'daysSelectorType'
            ] === 'RANGE' && (
              <Box width={[1, 1, 1]}>
                <Field
                  name={FORM_FIELD_NAMES.DURATION}
                  component={DurationSlider}
                  placeholder={t(
                    '$*newOrder.paymentMode.inputDuration.placeholder',
                    '$$Czas trwania'
                  )}
                  testMode={currentForm.testMode}
                />
                <OnChange name={FORM_FIELD_NAMES.DURATION}>
                  {() =>
                    requestAnimationFrame(() => {
                      clearSelectedDays();
                      setNextTabDisability(true);
                    })
                  }
                </OnChange>
              </Box>
            )}
            {orderConfig[
              currentForm.testMode ? 'testDaysSelectorType' : 'daysSelectorType'
            ] === 'RANGE_INPUT' && (
              <Box width={[1, 0.5, 5 / 12]}>
                <Field
                  options={createOptionsFromRange(t)}
                  name={FORM_FIELD_NAMES.DURATION}
                  component={FormSelectBox}
                  placeholder={t(
                    '$*newOrder.paymentMode.inputDuration.placeholder',
                    '$$Czas trwania'
                  )}
                />
                <OnChange name={FORM_FIELD_NAMES.DURATION}>
                  {() =>
                    requestAnimationFrame(() => {
                      clearSelectedDays();
                      setNextTabDisability(true);
                    })
                  }
                </OnChange>
              </Box>
            )}
          </Fragment>
        </Flex>
      )}
    </Fragment>
  );
};

export default connect(
  ({
    orderForm: { orderConfig },
    accountSettings: {
      userData: { hasAnyPayedOrder },
    },
    calendar: { duration },
    dietForm: { forms, currentFormId, paymentMode },
    app: {
      config: {
        modules: { ConfigClientPanel, PaymentModesCopy },
      },
    },
    orderForm,
  }) => ({
    ConfigClientPanel,
    PaymentModesCopy,
    currentFormId,
    dietCount: Object.keys(forms).length,
    duration,
    hasAnyPayedOrder,
    orderConfig,
    orderForm,
    paymentMode,
  }),
  {
    changePaymentMode,
    clearSelectedDays,
    setNextTabDisability,
    setPaymentMethod,
  }
)(PaymentMode);
