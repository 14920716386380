import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import MainLayout from '../layouts/main';
import { refreshLogin } from 'views/Auth/actions/authActions';

import URLS from 'routes/routeUrls';
import { useLocalUrl } from './index';

const PrivateRoute = connect(({ auth: { token } }) => ({ token }), {
  refreshLogin,
})(({ component: Component, layout: Layout, token, refreshLogin, ...rest }) => {
  useEffect(() => {
    refreshLogin();
  }, []);
  const { toLocalUrl } = useLocalUrl();
  const pathname = toLocalUrl(URLS.AUTH_LOGIN);

  return (
    <Route
      {...rest}
      render={props =>
        token ? (
          <Layout component={Component} {...rest} {...props} />
        ) : (
          <Redirect
            to={{
              pathname,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
});

const PublicRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={props => <Layout component={Component} {...rest} {...props} />}
  />
);

const RouteWrapper = ({
  component: Content,
  layout: Layout = MainLayout,
  requiresAuth,
  ...rest
}) => {
  const RouteType = requiresAuth ? PrivateRoute : PublicRoute;

  return <RouteType component={Content} layout={Layout} {...rest} />;
};

export default RouteWrapper;
