import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import equal from 'deep-equal';

import {
  getBag,
  getIntentBag,
  getMeals,
  converSubscriptionMenuIntents,
  setSelectedDay,
  setSelectedDiet,
  setVisibleCalendarDays,
  getUserDietCalendar,
  clearMeals,
  setDaysToChange,
  setInitialDaysToChange,
  getDietDaysToChange,
  resetLoading,
  addLoading,
} from '../actions/menuPlanningActions';
import { setSelectedDiet as setSelectedUserDiet } from '../../UserDiet/actions/userDietActions';
import { getUserDietsList } from '../../UserDiet/actions/userDietActions';

import { getLimitDates } from '../services/calendarService';

import { dateObjectToYearMonthDay } from 'common/services/dateHelpers';

import { getBagIdForCurrentDay } from 'views/UserDiet/services/userDietRepresenter';
import { mainTheme } from '../../../themes/main';
import { withLocalUrl } from 'routes/withLocalUrl';

const withMenuPlanning = WrappedComponent =>
  class extends Component {
    isMobile = window.innerWidth < mainTheme.deviceBreakpoints.desktopDown;

    componentDidMount = async () => {
      const {
        setSelectedDay,
        getUserDietsList,
        selectedDietFromCalendar,
        selectedDayFromCalendar,
        getDietDaysToChange,
        setDaysToChange,
        dietDays,
        setInitialDaysToChange,
        clearMeals,
        selectedDay,
        calendar,
        addLoading,
      } = this.props;
      const { dietId, day, bagId } = this.props.match.params;
      addLoading(1);
      await getUserDietsList(dietId || selectedDietFromCalendar);

      setSelectedDiet(dietId || selectedDietFromCalendar);
      setSelectedDay(day || selectedDayFromCalendar);
      await this.fetchUserDietCalendar();
      const bagToFetchFromCalendarId = calendar?.days?.[selectedDay]?.id;

      if (bagId) {
        this.fetchBagWithMeals(bagId);
      } else {
        if (bagToFetchFromCalendarId) {
          this.fetchBagWithMeals(bagToFetchFromCalendarId);
        } else {
          clearMeals();
        }
      }
      dietId && getDietDaysToChange(dietId);

      if (dietDays.length > 0 && day) {
        const bagDay = dietDays.find(
          bag => dateObjectToYearMonthDay(bag.date) === day
        );
        const bagDayBlock = bagDay ? bagDay.dayBlock : [];
        const formattedDayBlock = bagDayBlock.map(day =>
          dateObjectToYearMonthDay(day.date)
        );
        setDaysToChange(formattedDayBlock);
        setInitialDaysToChange(formattedDayBlock);
      }
      addLoading(-1);
    };

    componentDidUpdate = async prevProps => {
      const {
        visibleCalendarDays,
        selectedDay,
        setSelectedDiet,
        selectedDietFromCalendar,
        setDaysToChange,
        setInitialDaysToChange,
        dietDays,
      } = this.props;
      const { dietId, bagId, day } = this.props.match.params;

      if (
        !prevProps.isUnmountedPreparingModal &&
        this.props.isUnmountedPreparingModal
      ) {
        this.fetchBagWithMeals(bagId);
      }

      // addLoading(+1);
      // MOBILE DELIVERY CALENDAR VIEW (no query params available)
      // Set parameters for diet calendar mobile view only if there are already available parameters from desktop
      if (
        !equal(selectedDietFromCalendar, prevProps.selectedDietFromCalendar)
      ) {
        Promise.all([setSelectedDiet(selectedDietFromCalendar)])
          .then(() => this.fetchUserDietCalendar())
          .then(() => {
            const bagId = this.getBagId(day);

            if (bagId) {
              this.fetchBagWithMeals(bagId);
            } else {
              this.props.clearMeals();
            }
          });
      }

      if (dietDays.length > 0 && day) {
        const bagDay = dietDays.find(
          bag => dateObjectToYearMonthDay(bag.date) === day
        );
        const bagDayBlock = bagDay ? bagDay.dayBlock : [];
        const formattedDayBlock = bagDayBlock.map(day =>
          dateObjectToYearMonthDay(day.date)
        );
        setDaysToChange(formattedDayBlock);
        setInitialDaysToChange(formattedDayBlock);
      }

      // If user change day in days switcher, all days will be refetched
      if (!equal(visibleCalendarDays, prevProps.visibleCalendarDays)) {
        this.fetchUserDietCalendar();
      }

      // MAIN MENU PLANNING VIEW (query params available)
      if (dietId) {
        // If user change day in days switcher, bag and meal list for selected day are being fetched
        if (!equal(selectedDay, prevProps.selectedDay)) {
          this.fetchUserDietCalendar().then(() =>
            this.fetchBagWithMeals(bagId)
          );
        }
      }
      // addLoading(-1);
    };

    fetchMeals = bagId => {
      const { getMeals } = this.props;

      return getMeals({
        id: bagId,
      });
    };

    getBagId = day => {
      const {
        calendar: { days },
        selectedDayFromCalendar,
      } = this.props;
      const selectedDay = day ? day : selectedDayFromCalendar;

      return getBagIdForCurrentDay({
        currentDay: selectedDay,
        calendarDays: days,
      });
    };

    fetchBagWithMeals = bagId => {
      const {
        getBag,
        clearMeals,
        history,
        selectedDiet,
        selectedDay,
        toLocalUrl,
        getIntentBag,
        calendar,
        dietIsSubscription,
      } = this.props;

      if (bagId) {
        this.isMobile &&
          !dietIsSubscription &&
          history.push(
            toLocalUrl(
              `/my-diet/menu-planning/${selectedDay}/${selectedDiet}/${bagId}`
            )
          );

        const isSubscriptionIntent =
          calendar?.days?.[selectedDay]?.['@type'] === 'SubscriptionIntent';

        return isSubscriptionIntent
          ? getIntentBag({ dietId: selectedDiet, date: selectedDay }).then(
              () => {
                // this.props.resetLoading();
                this.props.converSubscriptionMenuIntents();
              }
            )
          : getBag({ id: bagId }).then(() => this.fetchMeals(bagId));
      } else {
        this.isMobile &&
          !dietIsSubscription &&
          history.push(
            toLocalUrl(`/my-diet/menu-planning/${selectedDay}/${selectedDiet}/`)
          );

        return clearMeals();
      }
    };

    fetchUserDietCalendar = async () => {
      const {
        getUserDietCalendar,
        visibleCalendarDays,
        selectedDiet,
      } = this.props;

      const [rangeStart, rangeEnd] = getLimitDates(visibleCalendarDays);

      return await getUserDietCalendar({
        dietId: selectedDiet,
        dateFrom: dateObjectToYearMonthDay(rangeStart),
        dateTo: dateObjectToYearMonthDay(rangeEnd),
      });
    };

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

export default compose(
  withRouter,
  withLocalUrl,
  connect(
    ({
      menuPlanning: {
        bag,
        variant,
        meals,
        selectedDiet,
        selectedDay,
        visibleCalendarDays,
        calendar,
        dietDays,
        isUnmountedPreparingModal,
      },
      userDiet: {
        selectedDiet: selectedDietFromCalendar,
        selectedDietObject,
        selectedDay: selectedDayFromCalendar,
      },
    }) => ({
      calendar,
      selectedDiet,
      selectedDay,
      visibleCalendarDays,
      bag,
      variant,
      meals,
      dietDays,
      selectedDietFromCalendar,
      selectedDayFromCalendar,
      dietIsSubscription: selectedDietObject?.subscription !== null,
      isUnmountedPreparingModal,
    }),
    {
      getUserDietCalendar,
      setVisibleCalendarDays,
      setSelectedDiet,
      setSelectedDay,
      getBag,
      getIntentBag,
      getMeals,
      converSubscriptionMenuIntents,
      clearMeals,
      getUserDietsList,
      setDaysToChange,
      setInitialDaysToChange,
      getDietDaysToChange,
      setSelectedUserDiet,
      resetLoading,
      addLoading,
    }
  ),
  withMenuPlanning
);
