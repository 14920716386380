import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

import Confirmation from 'styledComponents/elements/NewOrder/Confirmation';

import { useTranslation } from 'react-i18next';
import { checkOrderStatus } from '../actions/orderFormActions';
import { useParams } from 'react-router-dom';
import useInterval from 'hooks/useInterval';
import { useHistory } from 'react-router-dom';
import URLS from 'routes/routeUrls';
import CookPreparingIcon from 'assets/icons/CookPreparingIcon';

const INTERVAL_DELAY = 5000;
const TIME_TO_REFRESH_IN_SECONDS = 180;

const ConfirmationPreparing = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const orderStatus = useSelector(state => state.orderForm?.orderStatus);
  const { orderId } = useParams();

  const [time, setTime] = useState(0);

  useInterval(
    () => {
      dispatch(checkOrderStatus(orderId));
      setTime(time + INTERVAL_DELAY / 1000);
    },
    orderStatus?.orderItemsProcessFinished === true &&
      time >= TIME_TO_REFRESH_IN_SECONDS
      ? null
      : INTERVAL_DELAY
  );

  useEffect(() => {
    if (orderStatus?.orderItemsProcessFinished) {
      history.push(URLS.NEW_ORDER_CONFIRMATION);
    }
  }, [orderStatus?.orderItemsProcessFinished]);

  return (
    <Confirmation>
      <Confirmation.Title>
        {t('$*confirmationSuccess.title', 'Dziękujemy!')}
      </Confirmation.Title>
      <Confirmation.Subtitle>
        {t(
          '$*confirmationSuccess.label',
          'Potwierdzamy, że otrzymaliśmy Twoje zamówienie.'
        )}
      </Confirmation.Subtitle>

      <div>
        <CookPreparingIcon />
      </div>

      <Confirmation.Description>
        {t(
          '$*confirmationPreparing.description',
          '$$Trwa przy-GOTOWANIE Twojego zamówienia w panelu klienta. Za chwilę będzie ono widoczne.'
        )}
      </Confirmation.Description>
    </Confirmation>
  );
};

export default compose(withRouter)(ConfirmationPreparing);
