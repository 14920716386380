import { get, post } from 'utils/http';

import { URLS } from 'configuration';
import { buildUrl } from 'utils/route';
import NOTIFICATIONS from 'common/shared/notifications';

import {
  transformDeliveryHours,
  transformOrderForm,
  getErrorsMessage,
} from '../services/orderFormRepresenter';
import showToast from 'utils/toast';

import { getMyLeadTrackingParams } from 'common/services/myLeadHelpers';

import {
  FETCH_PAYMENT_METHODS,
  SET_CALORIFICS,
  SET_DIET_TYPE_DESC,
  SET_DELIVERY_PICKUP_POINTS,
  SET_DELIVERY_HOURS,
  SET_CALCULATIONS,
  SET_DISCOUNT,
  SET_PAYMENT_METHOD,
  SET_USE_MONEY_BOX,
  SET_ORDER_STATUS,
  SET_INVOICE_INCLUDED,
  SET_INVOICE_DATA_TYPE,
  SET_INVOICE_DATA,
  RESET_ORDER,
  SET_TEST_MODE,
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_FAILURE,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_RESPONSE,
  USER_PROCEED,
  SET_DISCOUNT_FOR_SPECIFIED_DIETS,
  CLEAR_DISCOUNT,
  SET_PACKAGE_DESC,
  CHANGE_PAYMENT_MODE,
  SET_PAYMENT_METADATA,
} from './const';
import store from '../../../store';

export const getPaymentMethods = () => {
  return dispatch =>
    get(URLS.PAYMENT_METHODS, null, true).then(({ data }) =>
      dispatch({
        type: FETCH_PAYMENT_METHODS,
        paymentMethods: data,
      })
    );
};

export const getOrderPaymentMethods = orderId => {
  return dispatch =>
    get(`/frontend/secure/orders/${orderId}/payment-types`, null, true).then(
      ({ data }) =>
        dispatch({
          type: FETCH_PAYMENT_METHODS,
          paymentMethods: data,
        })
    );
};

export const getDeliveryPickupPoints = () => {
  return dispatch => {
    return get(URLS.DELIVERY_PICKUP_POINTS, null, true).then(({ data }) => {
      return dispatch({
        type: SET_DELIVERY_PICKUP_POINTS,
        pickupPoints: data,
      });
    });
  };
};

export const getDeliveryHours = postCode => {
  return dispatch => {
    return get(URLS.DELIVERY_HOURS, { postCode })
      .then(({ data: { from, hours } }) => {
        return dispatch({
          type: SET_DELIVERY_HOURS,
          deliveryHours: transformDeliveryHours(hours),
        });
      })
      .catch(() => {
        dispatch({
          type: SET_DELIVERY_HOURS,
          deliveryHours: [],
        });

        return Promise.reject();
      });
  };
};

export const clearDeliveryHours = () => dispatch =>
  dispatch({
    type: SET_DELIVERY_HOURS,
    deliveryHours: [],
  });

export const setPaymentMetadata = ({ data, valid }) => ({
  type: SET_PAYMENT_METADATA,
  payload: {
    paymentMetadata: data,
    isValid: valid,
  },
});

export const getOrderCost = () => {
  return (dispatch, getState) => {
    const {
      orderForm,
      dietForm: { forms },
      userAddresses: { list },
    } = getState();

    const dietForms = Object.values(forms);
    let modifiedList = JSON.parse(JSON.stringify(list));

    if (orderForm.discountForSpecifiedDiets) {
      const noDietsAvailableForSpecifiedDiscount = !dietForms.some(dietForm => {
        return orderForm.discountForSpecifiedDiets.diets.includes(
          `/diets/${dietForm.diet}`
        );
      });

      if (noDietsAvailableForSpecifiedDiscount) {
        dispatch({
          type: CLEAR_DISCOUNT,
        });
        showToast({
          message: NOTIFICATIONS(window.t).DISCOUNT_CODE_REMOVED,
          type: 'info',
        });
      }
    }

    try {
      const data = transformOrderForm(
        Object.values(forms),
        orderForm,
        modifiedList
      );

      const noSendRequest =
        data.diets.find(({ calorific }) => {
          return calorific === undefined;
        }) || false;

      if (noSendRequest) {
        return null;
      }

      return post(URLS.ORDER_COST, data).then(({ data }) =>
        dispatch({
          type: SET_CALCULATIONS,
          calculations: data,
        })
      );
    } catch (ex) {
      console.log(ex);
      return Promise.reject();
    }
  };
};

export const validateDiscountCode = code => {
  return dispatch => {
    return get(URLS.VALIDATE_DISCOUNT_CODE, {
      code: code.replace(/  +/g, ' ').trim(),
    })
      .then(({ data }) => {
        const { dietForm } = store.getState();
        const dietForms = Object.values(dietForm.forms);

        if (data.diets && data.diets.length) {
          dispatch({
            type: SET_DISCOUNT_FOR_SPECIFIED_DIETS,
            discountForSpecifiedDiets: data,
          });

          const promoCodeValidForSpecifiedDiets = dietForms.some(dietForm => {
            return data.diets.includes(`/diets/${dietForm.diet}`);
          });

          return promoCodeValidForSpecifiedDiets
            ? dispatch({
                type: SET_DISCOUNT,
                discount: data,
                discountErrorMessage: '',
              })
            : dispatch({
                type: SET_DISCOUNT,
                discount: {},
                discountErrorMessage: window.t(
                  '$*orderFormActions.validateDiscountCode.errorMessage',
                  '$$Kod nie działa na zamówione rodzaje diet'
                ),
              });
        } else {
          dispatch({
            type: SET_DISCOUNT_FOR_SPECIFIED_DIETS,
            discountForSpecifiedDiets: null,
          });
          dispatch({
            type: SET_DISCOUNT,
            discount: data,
            discountErrorMessage: '',
          });
        }
      })
      .catch(({ response }) => {
        return dispatch({
          type: SET_DISCOUNT,
          discount: {},
          discountErrorMessage: response
            ? getErrorsMessage(response.data.violations)
            : '',
        });
      });
  };
};

export const clearDiscountCode = () => ({
  type: CLEAR_DISCOUNT,
});

export const changePaymentMode = paymentModeId => ({
  type: CHANGE_PAYMENT_MODE,
  paymentModeId,
});

export const setCalorifics = variantId => ({
  type: SET_CALORIFICS,
  variantId,
});

export const setDietTypeDesc = dietTypeId => ({
  type: SET_DIET_TYPE_DESC,
  dietTypeId,
});

export const setPackageDesc = packageId => ({
  type: SET_PACKAGE_DESC,
  packageId,
});

export const createOrder = () => {
  return async (dispatch, getState) => {
    const {
      app: {
        brand: { saturday, sunday },
        config: {
          modules: { ConfigClientPanel, MyLead },
        },
      },
      orderForm,
      dietForm: { forms },
      userAddresses: { list },
    } = getState();

    let modifiedList = JSON.parse(JSON.stringify(list));

    if (ConfigClientPanel.useAddressesWithLessFields) {
      modifiedList = list.map(address => {
        const {
          addressLine1 = null,
          addressLine2 = null,
          street,
          ...restAddress
        } = address;

        const hasAddresLineKeys =
          [addressLine1, addressLine2].filter(Boolean).length > 0;

        return {
          ...restAddress,
          buildNumber: '0',
          street: hasAddresLineKeys
            ? [addressLine1 ?? '', addressLine2].filter(Boolean).join('; ')
            : street,
        };
      });
    }

    dispatch({ type: CREATE_ORDER_REQUEST });

    let payload = transformOrderForm(
      Object.values(forms),
      orderForm,
      modifiedList
    );

    const { clickId, userId } = getMyLeadTrackingParams();

    if (MyLead?.enabled && !!clickId && !!userId) {
      payload = {
        ...payload,
        tracking: { mylead: { userId, clickId } },
      };
    }

    console.log('Payload: ', payload);

    try {
      const response = await post(URLS.CREATE_ORDER, payload);

      dispatch({
        type: CREATE_ORDER_RESPONSE,
        order: response.data,
      });
      dispatch({ type: CREATE_ORDER_SUCCESS });

      return response;
    } catch (error) {
      dispatch({ type: CREATE_ORDER_FAILURE });

      throw error;
    }
  };
};

export const setPaymentMethod = paymentMethodId => dispatch => {
  dispatch({
    type: SET_PAYMENT_METHOD,
    paymentMethodId,
  });
};

export const setUseMoneyBox = useMoneyBox => ({
  type: SET_USE_MONEY_BOX,
  useMoneyBox,
});

export const setTestMode = testMode => {
  return {
    type: SET_TEST_MODE,
    testMode,
  };
};

export const setInvoiceIncluded = isInvoiceIncluded => ({
  type: SET_INVOICE_INCLUDED,
  isInvoiceIncluded,
});

export const setInvoiceDataType = (invoiceDataType, userData) => ({
  type: SET_INVOICE_DATA_TYPE,
  invoiceDataType,
  userData,
});

export const setInvoiceData = (invoiceData, isInvoiceDataValid) => ({
  type: SET_INVOICE_DATA,
  invoiceData,
  isInvoiceDataValid,
});

export const checkOrderStatus = orderId => dispatch =>
  get(buildUrl(URLS.CHECK_ORDER_STATUS, { orderId })).then(({ data }) =>
    dispatch({
      type: SET_ORDER_STATUS,
      orderStatus: data,
    })
  );

export const resetOrder = () => ({
  type: RESET_ORDER,
});

export const userProceed = () => ({
  type: USER_PROCEED,
});
