import React, { Fragment } from 'react';
import { Field, useForm } from 'react-final-form';
import { connect } from 'react-redux';

import FormRadioGroup from 'common/components/Form/FormRadioGroup';
import styled from 'styled-components';
import { sortByPosition } from 'utils/componentHelpers';
import FORM_FIELD_NAMES from '../../const/formFieldNames';
import { CONFIGURATION_FIELDS } from '../../const/formConfig/configurationFields';
import { DisplayOnDesktop } from '../../../../styledComponents/helpers';
import FormRadioDietCard from 'common/components/Form/FormRadioDietCard';

export const DescriptionWrapper = styled.div`
  padding-top: 30px;

  i {
    font-style: italic;
  }
  b {
    font-weight: bold;
  }
  h1 {
    font-size: 22px;
  }
  h2 {
    font-size: 20px;
  }
  ol,
  ul {
    list-style: initial;
  }
  li {
    list-style-position: inside;
  }
  ol {
    list-style: decimal;
  }
`;

const DietType = ({
  packages = [],
  diets = [],
  forms,
  dietTypeDesc,
  currentFormId,
}) => {
  // const finalForm = useForm();
  // const forms = {
  //   [currentFormId]: finalForm.getState().values,
  // };
  if (
    packages &&
    packages.length > 0 &&
    packages.find(pack => +pack.id === +forms[currentFormId].package) &&
    packages.find(pack => pack.diets.length > 0)
  ) {
    const currentPackageId = forms[currentFormId].package;
    const currentPackage = packages.find(
      pack => pack.id === parseInt(currentPackageId)
    );
    const { diets, disabledDiets = [] } = currentPackage;

    return (
      <Fragment>
        <FormRadioGroup
          key={`dietTypeRadion-${currentFormId}-${currentPackageId}`}
        >
          {sortByPosition(diets, true).map(diet => (
            <Field
              key={diet.id}
              value={diet.id.toString()}
              label={diet.name}
              icon={diet.image}
              image={diet.icon}
              description={dietTypeDesc}
              name={FORM_FIELD_NAMES.DIET_TYPE}
              type="radio"
              component={FormRadioDietCard}
              componentStyle="primary"

              // {...CONFIGURATION_FIELDS[FORM_FIELD_NAMES.DIET_TYPE]}
            />
          ))}
          {sortByPosition(disabledDiets, true).map(diet => (
            <Field
              key={diet.id}
              value={diet.id.toString()}
              label={diet.name}
              icon={diet.image}
              image={diet.icon}
              disabled
              isDisabledDiet={true}
              description={dietTypeDesc}
              name={FORM_FIELD_NAMES.DIET_TYPE}
              type="radio"
              component={FormRadioDietCard}
              componentStyle="primary"

              // {...CONFIGURATION_FIELDS[FORM_FIELD_NAMES.DIET_TYPE]}
            />
          ))}
        </FormRadioGroup>

        <DisplayOnDesktop>
          <DescriptionWrapper
            dangerouslySetInnerHTML={{ __html: dietTypeDesc }}
          />
        </DisplayOnDesktop>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <FormRadioGroup>
        {sortByPosition(diets, true).map(diet => {
          return (
            <Field
              key={diet.id}
              value={diet.id.toString()}
              label={diet.name}
              icon={diet.image}
              image={diet.icon}
              description={dietTypeDesc}
              name={FORM_FIELD_NAMES.DIET_TYPE}
              type="radio"
              component={FormRadioDietCard}
              componentStyle="primary"
              // {...CONFIGURATION_FIELDS[FORM_FIELD_NAMES.DIET_TYPE]}
            />
          );
        })}
      </FormRadioGroup>

      <DisplayOnDesktop>
        <DescriptionWrapper
          dangerouslySetInnerHTML={{ __html: dietTypeDesc }}
        />
      </DisplayOnDesktop>
    </Fragment>
  );
};

export default connect(
  ({ dietForm: { forms, currentFormId } }) => ({
    forms,
    currentFormId,
  }),
  null
)(DietType);
