import React from 'react';
import { useTranslation } from 'react-i18next';

import StyledTable from 'styledComponents/elements/Table';

// headers - array of objects
// [
//   { label: "Data zdarzenia", minWidth: "150px" },
//   { label: "Zdarzenie", },
// ]
// props:
// label - string with label
// optional props:
// minWidth - set max-width to column
//
// rows - array of arrays
// [
//   ["19.06.2019", "Zgoda na newsletter"]  //single row, may contain strings, react components, etc.
//   ["20.06.2019", "Nowe zamówienie nr #23215213515 za na kwotę 1 712,00 zł"]
// ]
const Table = ({
  headers = [],
  rows = [],
  isLoading = false,
  noRowsText,
  loadingRowsText,
}) => {
  const { t } = useTranslation();
  noRowsText = noRowsText || t('$*table.noRowsText', '$$Stan skarbonki:');
  loadingRowsText =
    loadingRowsText || t('$*table.loadingRowsText', '$$Ładowanie danych...');

  return (
    <StyledTable>
      <table>
        <thead>
          <tr>
            {headers.map(({ label, ...headerProps }) => (
              <StyledTable.Heading key={label} {...headerProps}>
                {label}
              </StyledTable.Heading>
            ))}
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <tr>
              <StyledTable.Cell colSpan={headers.length} textAlign="center">
                {loadingRowsText}
              </StyledTable.Cell>
            </tr>
          )}
          {!isLoading && rows.length === 0 && (
            <tr>
              <StyledTable.Cell colSpan={headers.length} textAlign="center">
                {noRowsText}
              </StyledTable.Cell>
            </tr>
          )}
          {!isLoading &&
            rows.length > 0 &&
            rows.map((row, index) => (
              <tr key={index}>
                {row.map((cell, index) => (
                  <StyledTable.Cell key={index}>{cell}</StyledTable.Cell>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </StyledTable>
  );
};

export default Table;
