import React from 'react';
import styled from 'styled-components';
import { media } from 'styledComponents/helpers';
import { connect } from 'react-redux';

const Text = styled.p`
  text-align: center;

  ${media.tabletDown`
    margin-top: 25px;
  `};
`;

const Copyright = ({ name }) => <Text>&copy; {name}</Text>;

export default connect(({ app: { brand: { name } } }) => ({
  name,
}))(Copyright);
