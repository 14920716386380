import React, { Children } from 'react';
import { BASE_URL } from 'configuration';

import {
  StyledFormRadioLabel,
  StyledFormRadioDescription,
  StyledFormRadioPrimary,
  StyledFormRadioPrimaryMobile,
  StyledFormRadioPrimary2Columns,
  StyledFormRadioSecondary,
  StyledFormRadioVariantSecondary,
  StyledFormRadioSecondaryMobile,
  StyledMenuPlanningFormRadio,
} from 'styledComponents/elements/Form/FormRadio';
import showToast from '../../../utils/toast';
import { withTranslation } from 'react-i18next';

const getStyledComponent = style => {
  switch (style) {
    case 'primary':
      return StyledFormRadioPrimary;
    case 'primary-mobile':
      return StyledFormRadioPrimaryMobile;
    case 'primary2Columns':
      return StyledFormRadioPrimary2Columns;
    case 'secondary':
    case 'noContainer':
      return StyledFormRadioSecondary;
    case 'variantSecondary':
      return StyledFormRadioVariantSecondary;
    case 'secondary-mobile':
      return StyledFormRadioSecondaryMobile;
    case 'menu-planning':
      return StyledMenuPlanningFormRadio;
    default:
      return 'label';
  }
};

const FormRadio = ({
  input,
  componentStyle,
  isCentered,
  isSubscriptionSuspended,
  hasAutoHeight,
  hasAutoWidth,
  hasBottomMargin,
  disabled,
  label = '',
  customLabel,
  description = '',
  icon,
  image,
  width,
  maxWidth,
  manualValidation,
  isFaded,
  tooltipTitle = null,
  t,
}) => {
  const StyledElement = getStyledComponent(componentStyle);
  const componentStylesWithCheckmark = [
    'noContainer',
    'secondary',
    'secondary-mobile',
  ];
  const hasCheckmark = componentStylesWithCheckmark.includes(componentStyle);

  return (
    <StyledElement
      isCentered={isCentered}
      isFaded={isFaded}
      hasAutoHeight={hasAutoHeight}
      hasAutoWidth={hasAutoWidth}
      hasBottomMargin={hasBottomMargin}
      noContainer={componentStyle === 'noContainer'}
      disabled={disabled}
      checked={input.checked && !isSubscriptionSuspended}
      mx={10}
      as="label"
      width={width}
      maxWidth={maxWidth}
    >
      <input
        type="radio"
        {...input}
        onClick={() => {
          if (isSubscriptionSuspended) {
            showToast({
              message: t(
                '$*menuPlanning.toast.renewSubFirst',
                'Najpierw należy wznowić dzień subskrypcji'
              ),
              type: 'error',
            });
          }
        }}
        onChange={props => {
          if (manualValidation) {
            if (manualValidation(props)) {
              return input.onChange(props);
            } else {
              return false;
            }
          } else {
            return input.onChange(props);
          }
        }}
      />
      {image && (
        <img
          style={{ borderRadius: '3px 3px 0 0' }}
          width="100%"
          src={`${BASE_URL}${image}`}
          alt=""
        />
      )}
      {label && (
        <StyledFormRadioLabel checked={input.checked}>
          {hasCheckmark && (
            <i
              className={`${
                input.checked ? 'fas fa-check-circle' : 'far fa-circle'
              }`}
            />
          )}
          {label}
        </StyledFormRadioLabel>
      )}

      {customLabel && (
        <StyledFormRadioLabel checked={input.checked}>
          {hasCheckmark && (
            <i
              className={`${
                input.checked ? 'fas fa-check-circle' : 'far fa-circle'
              }`}
            />
          )}

          {Children.only(customLabel)}
        </StyledFormRadioLabel>
      )}

      {description && (
        <StyledFormRadioDescription>{description}</StyledFormRadioDescription>
      )}
    </StyledElement>
  );
};

export default withTranslation()(FormRadio);
