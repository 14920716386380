import React from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { remove } from 'utils/http';
import { URLS } from 'configuration';
import { deleteCreditCard } from 'views/Auth/actions/authActions';

import Card from './PaymentCard.styled';

const PaymentCard = ({
  id,
  brand,
  last4,
  name,
  expireAt,
  provider,
  validatedByProvider,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const removeCard = async id => {
    try {
      await remove(`${URLS.CARDS}/${provider}/${id}`);
      dispatch(deleteCreditCard(id));
    } catch (error) {}
  };

  return (
    <Card>
      <Card.Front>
        <Card.Remove onClick={() => removeCard(id)}>
          <i class="fas fa-trash"></i>
        </Card.Remove>
        <Card.Brand>{brand}</Card.Brand>
        <Card.Number>**** **** **** {last4}</Card.Number>
        <Card.InfoWrapper>
          <Card.Left>
            {name && (
              <>
                <Card.Label>
                  {t('$*paymentCard.cardName', '$$Nazwa karty')}
                </Card.Label>
                <Card.Info>{name}</Card.Info>
              </>
            )}
          </Card.Left>
          <Card.Right>
            {/* Don't show expire at value on card added with PayU before first charge  */}
            {!(provider === 'PAYU_CARD' && !validatedByProvider) && (
              <>
                <Card.Label>
                  {t('$*paymentCard.expiredAt', '$$Data wygaśnięcia')}
                </Card.Label>
                <Card.Info>{format(new Date(expireAt), 'MM/YY')}</Card.Info>
              </>
            )}
          </Card.Right>
        </Card.InfoWrapper>
      </Card.Front>
    </Card>
  );
};

export default PaymentCard;
