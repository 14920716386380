import React from 'react';

import {
  HiddenCheckbox,
  StyledCheckboxRounded,
} from 'styledComponents/elements/Form/FormCheckbox';

const CheckboxRounded = ({ input }) => {
  return (
    <StyledCheckboxRounded active={input.checked}>
      <i
        className={`${input.checked ? 'fas fa-check-circle' : 'far fa-circle'}`}
      />
      <HiddenCheckbox type="checkbox" {...input} checked={input.checked} />
    </StyledCheckboxRounded>
  );
};

export default CheckboxRounded;
