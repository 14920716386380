import styled, { keyframes } from 'styled-components';
import basketIcon from 'assets/images/basket.png';
import { fromPxToRem } from 'styledComponents/helpers';
import { Button } from 'styledComponents/elements/Button';

const shake = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`;

const Basket = styled.div`
  ${({ theme: { baseFontColor } }) => `
    background: url(${basketIcon}) no-repeat center center ${baseFontColor};
  `};
  background-size: 20px;
  cursor: pointer;
  height: 40px;
  position: fixed;
  right: 0;
  top: 130px;
  width: 40px;

  animation-fill-mode: forwards;

  @media screen and (min-width: 1350px) {
    background-size: 30px;
    border-radius: 50%;
    height: 50px;
    right: calc(((100vw - 1110px) / 2) - 100px);
    width: 50px;
  }

  &.is-animating {
    animation: ${shake} 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
`;

const BasketQuantity = styled.div`
  ${({ theme: { colorRustyRed, colorWhite } }) => `
    background: ${colorRustyRed};
    color: ${colorWhite};
  `};
  align-items: center;
  border: none;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  justify-content: center;
  position: absolute;
  transform: translateY(75%);
  width: 100%;

  @media screen and (max-width: 1350px) {
    left: 0;
    bottom: 0;
  }

  @media screen and (min-width: 1350px) {
    ${({ theme: { colorWhite } }) => `
      border: 2px solid ${colorWhite};
    `};
    border-radius: 50%;
    height: 20px;
    right: 0;
    top: 0;
    transform: translate(25%, -25%);
    width: 20px;
  }
`;

const BagWrapper = styled.div`
  margin-bottom: 25px;
`;

BagWrapper.Header = styled.div`
  display: flex;
  position: relative;
  justify-content: center;

  span {
    background-color: #fff;
    font-size: 0.81rem;
    font-weight: 900;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    padding: 0 12px;
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 0;
    content: '';
    height: 1px;
    width: 100%;
    background-color: #19a0f7;
  }
`;

BagWrapper.Items = styled.div`
  @media (max-width: 650px) {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
  }
`;

const StyledButton = styled(Button)`
  font-size: ${fromPxToRem('12px')};
  margin: 20px auto 0;
  display: table;
  color: ${props =>
    props.disabled
      ? props.theme.colorLightBlueGrey
      : props.theme.baseFontColor};
`;

const BasketTotalPrice = styled.div`
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 650px) {
    justify-content: end;
    margin-right: 45px;
  }
`;

const BasketUsePointsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  @media (min-width: 650px) {
    justify-content: end;
    margin-right: 45px;
  }
`;

const PriceRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: 0 -3px 0 6px;
  align-items: baseline;
`;

const OldPrice = styled.span`
  flex: 0 0 auto;
  padding: 0 3px;
  text-decoration: line-through;
  color: ${props => props.theme.colorRed};
  font-size: ${fromPxToRem('14px')};
`;

const NewPrice = styled.div`
  display: flex;
  padding: 0 3px;
  overflow-wrap: anywhere;
`;

export const Styled = {
  Basket,
  BasketQuantity,
  BagWrapper,
  BasketTotalPrice,
  Button: StyledButton,
  BasketUsePointsWrapper,
  PriceRow,
  OldPrice,
  NewPrice,
};
