import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { Flex, Box } from '@rebass/grid';
import styled from 'styled-components';
import { isEmpty } from 'ramda';
import { Field } from 'react-final-form';

import { fromPxToRem } from 'styledComponents/helpers';
import { BadgeBlue } from 'styledComponents/elements/Badge';
import { DisplayOnMobile } from 'styledComponents/helpers';

import FORM_FIELD_NAMES from '../../const/formFieldNames';

import {
  validateDiscountCode,
  clearDiscountCode,
  getOrderCost,
} from '../../actions/orderFormActions';

import { SUMMARY_ROW_LEFT_WIDTH, SUMMARY_ROW_RIGHT_WIDTH } from './const';
import { useTranslation } from 'react-i18next';
import FormTextInput from 'common/components/Form/FormTextInput';
import { initForm } from '../../actions/dietFormActions';
import { setTabIndex } from '../OrderFormTabs/actions/orderFormTabsActions';
import { clearSelectedDays } from '../../../../common/components/Calendar/actions/calendarActions';
import { ButtonLink } from '../../../../styledComponents/elements/Button';

const StyledDiscountLabel = styled.span`
  margin-right: 10px;
  margin-left: ${props => (props.ml ? props.ml : 0)};
  font-size: ${fromPxToRem('13px')};
`;

const SummaryDiscountCode = ({
  discount,
  validateDiscountCode,
  discountErrorMessage,
  discountForSpecifiedDiets,
  forms,
  diets,
  paymentMode,
  clearDiscountCode,
  clearField,
  multinational,
  tabName,
  testMode,
  initForm,
  setTabIndex,
  clearSelectedDays,
}) => {
  let discountCodeAdditionalMessage;
  const { t } = useTranslation();
  const { currencySymbol } = multinational;

  const isOnLastStep = tabName === 'OrderFormSummary';
  const isOneTimePayment = paymentMode === '2';

  const isAddNextOrderVisibble = isOnLastStep && !testMode && isOneTimePayment;

  if (discountForSpecifiedDiets && discountForSpecifiedDiets.diets.length) {
    const dietsWithDiscount = [];
    const dietForms = Object.values(forms);
    dietForms.forEach(dietForm => {
      const dietFormIndex = `/diets/${dietForm.diet}`;
      if (discountForSpecifiedDiets.diets.includes(dietFormIndex)) {
        dietsWithDiscount.push(dietFormIndex);
      }
    });

    const dietsToShow = diets.filter(diet => {
      return dietsWithDiscount.includes(diet['@id']);
    });
    discountCodeAdditionalMessage = `${t(
      '$*summaryDiscountCode.applicableDietsLabel',
      '$$dotyczy wybranych diet'
    )}: ${dietsToShow.map(diet => diet.name).join(', ')}`;
  }

  const hasDiscount = !isEmpty(discount);
  const onSubmitAction = code => {
    if (hasDiscount) {
      clearField(FORM_FIELD_NAMES.DISCOUNT_CODE);
      return clearDiscountCode();
    }

    return validateDiscountCode(code);
  };

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      flexDirection={['column', 'column', 'row']}
    >
      <Box width={[1, 1, SUMMARY_ROW_LEFT_WIDTH]}>
        <Field
          submitErrorMessage={discountErrorMessage}
          onSubmit={onSubmitAction}
          parse={v => v.toUpperCase()}
          submitButtonText={
            hasDiscount
              ? t('$*summaryDiscountCode.remove', '$$Usuń')
              : t('$*summaryDiscountCode.load', '$$Wczytaj')
          }
          name={FORM_FIELD_NAMES.DISCOUNT_CODE}
          type="text"
          label={t('$*orderSummaryFields.discountCode', '$$Kod rabatowy')}
          component={FormTextInput}
          isBig={true}
          isInline={true}
          withSubmitButton={true}
          margin="0 0 20px"
        />
      </Box>
      {isAddNextOrderVisibble && (
        <DisplayOnMobile>
          <ButtonLink
            uppercased
            weightBold
            type="button"
            sizeMiddle
            onClick={() => {
              initForm();
              setTabIndex(0);
              clearSelectedDays();
            }}
          >
            {t('$*orderFormSummary.addNextOrder', '$$Dodaj kolejne zamówienie')}
          </ButtonLink>
        </DisplayOnMobile>
      )}

      {hasDiscount && (
        <Box width={[1, 1, SUMMARY_ROW_RIGHT_WIDTH]} mt={[15, 15, 0]}>
          <Flex alignItems="center" justifyContent={'space-between'}>
            <Box>
              {t('$*summaryDiscountCode.discountAmount', '$$Udzielony rabat')}:
            </Box>
            {discount.isPercentage ? (
              <BadgeBlue>-{discount.discount}%</BadgeBlue>
            ) : (
              <BadgeBlue>
                -{discount.discount} {currencySymbol}
              </BadgeBlue>
            )}
            {discountCodeAdditionalMessage && (
              <StyledDiscountLabel ml={'10px'}>
                {discountCodeAdditionalMessage}
              </StyledDiscountLabel>
            )}
          </Flex>
        </Box>
      )}
    </Flex>
  );
};

export default compose(
  connect(
    ({
      app: {
        config: { multinational },
      },
      orderForm: {
        discount,
        discountErrorMessage,
        discountForSpecifiedDiets,
        orderConfig: { diets },
        testMode,
      },
      orderFormTabs: { tabName },
      dietForm: { forms, paymentMode },
    }) => ({
      tabName,
      testMode,
      discount,
      discountErrorMessage,
      discountForSpecifiedDiets,
      forms,
      paymentMode,
      diets,
      multinational,
    }),
    {
      validateDiscountCode,
      clearDiscountCode,
      getOrderCost,
      initForm,
      setTabIndex,
      clearSelectedDays,
    }
  ),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { discount, getOrderCost } = this.props;

      if (discount.code !== prevProps.discount.code) {
        getOrderCost();
      }
    },
  })
)(SummaryDiscountCode);
