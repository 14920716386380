import { format } from 'date-fns';
import { is, prop } from 'ramda';
import React from 'react';
import moment from 'moment';
import i18n from 'i18next';
import { sortByPosition } from 'utils/componentHelpers';

import { DELIVERY_TAKEOUT_OPTION_ID } from '../const/formConfig/deliveryOptions.js';

import FORM_FIELD_NAMES from '../const/formFieldNames';

import {
  getPackageParam,
  getDietParam,
  getDietCalorificsParam,
  getPickupPointParam,
} from './formService.js';

export const getErrorsMessage = errors => {
  return errors?.map(error => error.message).join('\n') || '';
};

export const transformOrderConfig = config => {
  //Diety są jeszcze później sortowane, ale był jeden przypadek gdzie zaznaczony
  // element nie był 1
  const packagesWithSortedDiets = sortByPosition(
    [...config.packages],
    true
  ).map(pckg => ({
    ...pckg,
    diets: sortByPosition(pckg?.diets, true),
  }));

  return {
    ...config,
    additionalPrices: config.additionalPrices,
    diets: config.diets.filter(diet => diet.variants.length),
    packages: packagesWithSortedDiets,
    variants: config.variants.map(variant => {
      return {
        ...variant,
        description: (
          <div>
            <p>{`${i18n.t(
              '$*orderFormConfigurationSections.variant.consistsOf'
            )} ${variant.mealTypes.map(mt => mt.name).join(', ')}`}</p>
            <br />
            <p>{variant.description}</p>
          </div>
        ),
      };
    }),
    days: config.days.map(day => {
      let phrasal = i18n.t('$*dietDuration.dayPlural');
      if (day === 1) {
        phrasal = i18n.t('$*common.day');
      }
      return {
        label: `${day} ${phrasal}`,
        value: day,
      };
    }),
    testDays: config.testDays.map(testDay => {
      let phrasal = `${i18n.t('$*dietDuration.dayPlural')} (${i18n.t(
        '$*dietDuration.testPlural'
      )})`;
      if (testDay === 1) {
        phrasal = `${i18n.t('$*common.day')} (${i18n.t(
          '$*dietDuration.testSingular'
        )})`;
      }
      return {
        label: `${testDay} ${phrasal}`,
        value: testDay,
      };
    }),
    disabledDays: config.disabledDays.map(disabledDay => {
      const momentDate = new moment(disabledDay);
      return new Date(momentDate);
    }),
  };
};

export const transformDeliveryHours = hours => {
  return hours.map(hour => {
    return {
      value: hour,
      label: [
        ...(hour?.hourFrom
          ? [`${i18n.t('$*common.from')} ${format(hour.hourFrom, 'HH:mm')}`]
          : []),
        ...(hour?.hourTo
          ? [`${i18n.t('$*common.to')} ${format(hour.hourTo, 'HH:mm')}`]
          : []),
      ].join(' '),
    };
  });
};

export const transformOrderCost = (
  forms,
  {
    orderConfig: { diets, packages, variants },
    pickupPoints,
    discount,
    useMoneyBox,
    testMode,
  },
  addresses
) => {
  return transformOrderData({
    forms,
    diets,
    packages,
    variants,
    pickupPoints,
    discount,
    addresses,
    useMoneyBox,
    testMode,
    getFullAddress: addresses.length > 0,
  });
};

export const transformOrderForm = (
  forms,
  {
    orderConfig: { diets, packages, variants },
    pickupPoints,
    discount,
    paymentMethodId,
    useMoneyBox,
    isInvoiceIncluded,
    invoiceDataType,
    invoiceCompanyName,
    invoiceVatNumber,
    invoiceAddressPostCode,
    invoiceAddressCity,
    invoiceAddressStreet,
    invoiceAddressBuildNumber,
    invoiceAddressPlaceNumber,
    invoiceComments,
    testMode,
    paymentMetadata = null,
  },
  addresses
) => {
  return transformOrderData({
    forms,
    diets,
    packages,
    variants,
    pickupPoints,
    discount,
    addresses,
    paymentMethodId,
    getFullAddress: true,
    useMoneyBox,
    isInvoiceIncluded,
    invoiceDataType,
    invoiceCompanyName,
    invoiceVatNumber,
    invoiceAddressPostCode,
    invoiceAddressCity,
    invoiceAddressStreet,
    invoiceAddressBuildNumber,
    invoiceAddressPlaceNumber,
    invoiceComments,
    testMode,
    paymentMetadata,
  });
};

function transformOrderData({
  forms,
  packages,
  diets,
  variants,
  pickupPoints,
  discount,
  addresses,
  paymentMethodId,
  useMoneyBox,
  isInvoiceIncluded,
  invoiceDataType,
  invoiceCompanyName,
  invoiceVatNumber,
  invoiceAddressPostCode,
  invoiceAddressCity,
  invoiceAddressStreet,
  invoiceAddressBuildNumber,
  invoiceAddressPlaceNumber,
  invoiceComments,
  testMode,
  paymentMetadata = null,
}) {
  const invoiceFields = isInvoiceIncluded
    ? {
        [FORM_FIELD_NAMES.IS_INVOICE_INCLUDED]: isInvoiceIncluded,
        [FORM_FIELD_NAMES.INVOICE_TYPE]: invoiceDataType,
        [FORM_FIELD_NAMES.INVOICE_VAT_NUMBER]: invoiceVatNumber,
        [FORM_FIELD_NAMES.INVOICE_COMPANY_NAME]: invoiceCompanyName,
        [FORM_FIELD_NAMES.INVOICE_ADDRESS_POST_CODE]: invoiceAddressPostCode,
        [FORM_FIELD_NAMES.INVOICE_ADDRESS_CITY]: invoiceAddressCity,
        [FORM_FIELD_NAMES.INVOICE_ADDRESS_STREET]: invoiceAddressStreet,
        [FORM_FIELD_NAMES.INVOICE_ADDRESS_BUILD_NUMBER]: invoiceAddressBuildNumber,
        [FORM_FIELD_NAMES.INVOICE_ADDRESS_PLACE_NUMBER]: invoiceAddressPlaceNumber,
        [FORM_FIELD_NAMES.INVOICE_COMMENTS]: invoiceComments,
      }
    : {};

  return {
    [FORM_FIELD_NAMES.USE_MONEY_BOX]: useMoneyBox,
    [FORM_FIELD_NAMES.TEST_MODE]: testMode,

    ...invoiceFields,

    [FORM_FIELD_NAMES.DISCOUNT_CODE]: prop('@id', discount),
    [FORM_FIELD_NAMES.PAYMENT_METHOD]: paymentMethodId,
    ...(paymentMetadata !== null && paymentMethodId === 'PAYPO'
      ? { metadata: paymentMetadata }
      : {}),
    diets: forms.map(form => {
      let dietId = form[FORM_FIELD_NAMES.DIET_TYPE];
      let variantId = form[FORM_FIELD_NAMES.VARIANT];
      let diet = diets.find(d => d.id.toString() === dietId);
      const firstSelectedDay = form[FORM_FIELD_NAMES.DELIVERY_DAYS][0] || null;

      if (!diet.variants.find(x => x.id.toString() === variantId)) {
        throw new Error(
          `Diet ${dietId} dont supply selected variant ${variantId}`
        );
      }

      return {
        [FORM_FIELD_NAMES.ADDONS]: form[
          FORM_FIELD_NAMES.ADDONS
        ]?.map(({ price, name, ...restAddon }) => ({ ...restAddon })),
        [FORM_FIELD_NAMES.DIET_NAME]: form[FORM_FIELD_NAMES.DIET_NAME],
        [FORM_FIELD_NAMES.IS_PREMIUM]: form[FORM_FIELD_NAMES.IS_PREMIUM],
        [FORM_FIELD_NAMES.WITH_ECOBOX]: form[FORM_FIELD_NAMES.WITH_ECOBOX],
        [FORM_FIELD_NAMES.PACKAGE]: getPackageParam(
          packages,
          form[FORM_FIELD_NAMES.PACKAGE],
          '@id'
        ),
        [FORM_FIELD_NAMES.DIET_TYPE]: getDietParam(
          diets,
          form[FORM_FIELD_NAMES.DIET_TYPE],
          '@id'
        ),
        [FORM_FIELD_NAMES.CALORIFICS]: getDietCalorificsParam(
          variants,
          form[FORM_FIELD_NAMES.VARIANT],
          form[FORM_FIELD_NAMES.CALORIFICS],
          '@id'
        ),
        ...(form[FORM_FIELD_NAMES.PAYMENT_MODE] === '2'
          ? {
              [FORM_FIELD_NAMES.DURATION]: +form[FORM_FIELD_NAMES.DURATION],
            }
          : {
              [FORM_FIELD_NAMES.WITH_SATURDAYS]:
                form[FORM_FIELD_NAMES.WITH_SATURDAYS],
              [FORM_FIELD_NAMES.WITH_SUNDAYS]:
                form[FORM_FIELD_NAMES.WITH_SUNDAYS],
            }),
        //Omit timezones when creating an order
        [FORM_FIELD_NAMES.IS_SUBSCRIPTION_PAYMENT]:
          form[FORM_FIELD_NAMES.PAYMENT_MODE] === '1',
        ...(form[FORM_FIELD_NAMES.PAYMENT_MODE] === '2'
          ? {
              [FORM_FIELD_NAMES.DELIVERY_DAYS]: form[
                FORM_FIELD_NAMES.DELIVERY_DAYS
              ].map(day => format(new Date(day), 'DD-MM-YYYY')),
            }
          : {
              [FORM_FIELD_NAMES.FIRST_DELIVERY_DAY]: firstSelectedDay
                ? format(firstSelectedDay, 'DD-MM-YYYY')
                : null,
            }),

        ...getDeliveryAddress(form, addresses, pickupPoints),
      };
    }),
  };
}

function getDeliveryAddress(form, addresses, pickupPoints) {
  if (+form[FORM_FIELD_NAMES.DELIVERY_TYPE] === DELIVERY_TAKEOUT_OPTION_ID) {
    return getAddress(form, addresses);
  } else {
    return getPickupPoint(form, pickupPoints);
  }
}

function getAddress(form, addresses) {
  let address;

  if (is(String, form[FORM_FIELD_NAMES.DELIVERY_ADDRESS])) {
    address = addresses.find(
      ({ id }) => id === +form[FORM_FIELD_NAMES.DELIVERY_ADDRESS]
    );
  } else {
    address = undefined;
  }

  return { [FORM_FIELD_NAMES.DELIVERY_ADDRESS]: address };
}

function getPickupPoint(form, pickupPoints) {
  return {
    [FORM_FIELD_NAMES.DELIVERY_PICKUP_POINT]: getPickupPointParam(
      pickupPoints,
      form[FORM_FIELD_NAMES.DELIVERY_PICKUP_POINT],
      '@id'
    ),
  };
}
