import { omit, pathOr, prop } from 'ramda';

import { dateObjectToYearMonthDay } from 'common/services/dateHelpers';

export const getDietsListOptions = (userDiets = []) => {
  return userDiets.map(diet => ({
    label: `${prop('name', diet)} #${prop('id', diet)}`,
    value: prop('id', diet),
  }));
};

export const getInitialDietOption = (selectedDiet, userDiets = []) => {
  const selected = userDiets.find(diet => diet.id === selectedDiet);

  return (
    selected && {
      label: prop('name', selected),
      value: prop('id', selected),
    }
  );
};

export const getBagIdForCurrentDay = ({ currentDay, calendarDays }) => {
  const dayString = dateObjectToYearMonthDay(currentDay);

  return pathOr(null, [dayString, 'id'], calendarDays);
};

export const transformDietsList = (diets = []) => {
  const unnecessaryKeys = ['@id', '@type'];

  return diets.map(diet => omit(unnecessaryKeys, diet));
};

export const transformUserDietCalendar = (calendarData = []) => {
  const unnecessaryKeys = ['@id', '@context', '@type'];

  return omit(unnecessaryKeys, calendarData);
};

export const isDietAlreadySelected = state =>
  pathOr(null, ['userDiet', 'selectedDiet'], state);
