import React from 'react';
import Countdown from 'react-countdown-now';
import { media } from 'styledComponents/helpers';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const TimePart = styled.span`
  border-radius: 8px;
  border: 1px solid ${props => props.theme.mainFontColor};
  margin: 5px;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: bold;

  ${media.tabletDown`
    font-size: 12px;
  `};
`;

const CountDown = ({ date, endMessage, onEndAction }) => {
  const { t } = useTranslation();

  return (
    <Countdown
      date={date ? date : Date.now() + 10000}
      renderer={({ days, hours, minutes, seconds }) =>
        days === 0 && hours === 0 && minutes === 0 && seconds === 0 ? (
          <>
            <div>
              {endMessage
                ? endMessage
                : t('$*countDown.timeUp', '$$Czas minął')}
            </div>
            {onEndAction()}
          </>
        ) : (
          <div style={{ flexShrink: 0 }}>
            {days > 0 && (
              <TimePart>
                {days} {t('$*countDown.daysShort', '$$d')}
              </TimePart>
            )}
            {hours > 0 && (
              <TimePart>
                {hours} {t('$*countDown.hoursShort', '$$g')}
              </TimePart>
            )}
            {minutes > 0 && (
              <TimePart>
                {minutes} {t('$*countDown.minutesShort', '$$min')}
              </TimePart>
            )}
            <TimePart>{seconds}s</TimePart>
            {t('$*countDown.toEnd', '$$do końca')}
          </div>
        )
      }
    />
  );
};

export default CountDown;
