import { keyframes } from 'styled-components';

export default keyframes`
 
   0% {
      transform: translateY(-5rem);
      opacity: 0;
  }
  100% {
      transform: translateY(0rem);
      opacity: 1;
  }
  0% {
      transform: translateY(5rem);
      opacity: 0;
  }
`;
