import styled from 'styled-components';

import { fromPxToRem, media } from 'styledComponents/helpers';

export const StyledCheckboxWrapper = styled.label`
  display: flex;
  flex-direction: ${props =>
    (props.descriptionAlignment === 'left' && 'row-reverse') || 'row'};
  align-content: center;
  align-items: center;
`;

export const StyledCheckboxDescription = styled.span`
  margin-right: ${props => props.descriptionAlignment === 'left' && '15px'};
  margin-left: ${props => props.descriptionAlignment !== 'left' && '15px'};
  cursor: pointer;
  user-select: none;
`;

export const StyledCheckboxOptions = styled.label`
  display: inline-block;
  height: ${props => (props.extended ? '31px' : '23px')};
  flex-shrink: 0;
  min-width: 72px;
  border-radius: 6px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);
  background-color: ${props => props.theme.colorWhite};
  font-size: 0;

  input[type='checkbox'] {
    position: fixed;
    opacity: 0;
  }

  ${media.tabletDown`
    width: max-content;
  `};
`;

export const StyledCheckbox = styled.span`
  display: inline-block;
  vertical-align: top;
  height: ${props => (props.active ? '31px' : '100%')};
  margin-top: ${props => (props.active ? '-4px' : '0')};
  padding: 0 9px;
  border-radius: 6px;
  background-color: ${props =>
    props.active ? props.theme.baseFontColor : null};
  line-height: ${props => (props.active ? '31px' : '23px')};
  color: ${props =>
    props.active ? props.theme.colorWhite : props.theme.colorLightBlueGrey};
  font-size: ${fromPxToRem('9px')};
  font-weight: ${props => props.theme.fontWeight900};
  text-transform: uppercase;
`;

export const StyledCheckboxExtended = styled.span`
  display: inline-block;
  vertical-align: top;
  height: ${props => (props.active ? '31px' : '100%')};
  padding: 0 9px;
  border-radius: 6px;
  background-color: ${props =>
    props.active ? props.theme.baseFontColor : null};
  line-height: ${props => (props.active ? '31px' : '23px')};
  color: ${props =>
    props.active ? props.theme.colorWhite : props.theme.colorLightBlueGrey};
  font-size: ${fromPxToRem('9px')};
  font-weight: ${props => props.theme.fontWeight900};
  text-transform: uppercase;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  ${props => !props.active && 'margin-top: 4px'};

  [class*='fa-'] {
    color: ${props => props.active && props.theme.baseFontColor};
    font-size: 14px;
    margin-top: ${props => (props.active ? '8px' : '4px')};
    display: ${props => (props.isCentered ? 'block' : 'inherit')};
    margin-bottom: ${props => (props.isCentered ? '5px' : '0px')};
  }
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  border: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 16px;
  height: 16px;
  margin: 0;
  top: 0;
  left: 0;

  &:focus {
    outline: none;
    border: 1px solid ${props => props.theme.baseFontColor};
    border-radius: 50%;
  }
`;

export const StyledCheckboxRounded = styled.span`
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  transition: all 150ms;
  margin-top: 3px;

  [class*='fa-'] {
    color: ${props => props.active && props.theme.baseFontColor};
    padding-right: 15px;
    display: ${props => (props.isCentered ? 'block' : 'inherit')};
    margin-bottom: ${props => (props.isCentered ? '5px' : '0px')};
  }

  [class^='far'] {
    color: ${props => props.theme.colorLightBlueGrey};
  }

  ${media.tabletDown`
    align-items: center;
  `};
`;
