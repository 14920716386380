import React from 'react';
import Modal from '../Modal';
import { ButtonPrimary } from 'styledComponents/elements/Button';
import { withTranslation } from 'react-i18next';

const reloadApp = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .getRegistration()
      .then(() => {
        console.log('serviceWorker');
        window.location.reload();
      })
      .catch(err => console.log('Could not get registration: ', err));
  }
};

const Index = ({ isOpened, t }) => {
  if (!isOpened) return null;
  return (
    <Modal disableClose hideHeader fullscreen padding="0" widthDesktop="575px">
      <div style={{ textAlign: 'center' }}>
        <div
          dangerouslySetInnerHTML={{
            __html: t(
              '$*common.serviceWorkerUpdate.p1',
              '<span style="font-weight: 900">Zaktualizowaliśmy panel klienta, ale w Twojej przeglądarce pozostała poprzednia wersja!</span> Ach te ciasteczka… (mniam, mniam).  <br/><br/><span style="font-weight: 900">Prosimy o odświeżenie strony lub kliknięcie poniższego przycisku.</span>'
            ),
          }}
        />

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1rem',
            marginBottom: '1rem',
          }}
        >
          <ButtonPrimary weightBold onClick={reloadApp} sizeMiddle>
            {t('$*common.serviceWorkerUpdate.button', 'Odśwież')}
          </ButtonPrimary>
        </div>

        <p
          style={{ fontSize: '14px' }}
          dangerouslySetInnerHTML={{
            __html: t(
              '$*common.serviceWorkerUpdate.p2',
              'W przypadku dalszego występowania komunikatu (dot. przeglądarki Safari) usuń proszę przechowywane pliki cookie, na przykład postępując zgodnie z instrukcją:<br/><br/> Dla Safari na iOS:'
            ),
          }}
        />

        <ul
          style={{
            fontSize: '14px',
            margin: '0 auto 16px',
            display: 'table',
            textAlign: 'left',
          }}
          dangerouslySetInnerHTML={{
            __html: t(
              '$*common.serviceWorkerUpdate.p3',
              '<ol>1. Stuknij kolejno opcje ‘Ustawienia’</ol><ol>2. Następnie ‘Safari’</ol><ol>3. Wybierz ‘Wymaż historię i dane witryn’</ol>'
            ),
          }}
        />

        <div
          style={{ fontSize: '14px' }}
          dangerouslySetInnerHTML={{
            __html: t(
              '$*common.serviceWorkerUpdate.p4',
              'Dla Safari na Mac’u:<br/><a href="https://support.apple.com/pl-pl/guide/safari/sfri11471/mac" target="_blank">Kliknij tutaj</a> i wykonaj kroki z nagłówka i czwartego podpunktu.'
            ),
          }}
        />
      </div>
    </Modal>
  );
};

export default withTranslation()(Index);
