import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  StyledCheckbox,
  StyledCheckboxOptions,
} from 'styledComponents/elements/Form/FormCheckbox';

const CheckboxSwitch = ({ input, switchLabels = [] }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const DEFAULT_VALUES = useMemo(
    () => [
      { label: t('$*checkboxSwitch.no', '$$Nie'), value: false },
      { label: t('$*checkboxSwitch.yes', '$$Tak'), value: true },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [language]
  );

  const values = switchLabels.length > 0 ? switchLabels : DEFAULT_VALUES;

  return (
    <StyledCheckboxOptions>
      {values.map(({ value, label }, index) => (
        <StyledCheckbox key={index} active={input.checked === value}>
          {label}
        </StyledCheckbox>
      ))}
      <input type="checkbox" {...input} />
    </StyledCheckboxOptions>
  );
};

export default CheckboxSwitch;
