import React from 'react';
import { connect } from 'react-redux';
import { BASE_URL, URLS } from 'configuration';

const UnknownIcon = ({ ConfigClientPanel }) => {
  const hasMenuSoonIcon = ConfigClientPanel.calendarIconMenuSoon !== null;
  const { showCalendarIcons } = ConfigClientPanel;

  const src =
    hasMenuSoonIcon &&
    `${BASE_URL}${URLS.UPLOADED_MEDIA}${
      ConfigClientPanel['@resources'][ConfigClientPanel.calendarIconMenuSoon]
        ?.contentUrl
    }`;

  if (!hasMenuSoonIcon || !showCalendarIcons) return null;
  return <img className="calendar-icon" width="100%" src={src} />;
};

export default connect(
  ({
    app: {
      config: {
        modules: { ConfigClientPanel },
      },
    },
  }) => ({ ConfigClientPanel }),
  null
)(UnknownIcon);
