import styled from 'styled-components';

import worldMapImage from 'assets/images/worldMap.png';

const Card = styled.div``;

Card.Front = styled.div`
  display: inline-block;
  width: 320px;
  min-height: 190px;
  padding: 18px;
  border-radius: 8px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
  ${({ theme }) => `background-image: url('${worldMapImage}'),
    linear-gradient(to right bottom, ${theme.baseFontColor}, ${theme.baseFontColor})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;

  @media (max-width: 390px) {
    width: 100%;
  }
`;

Card.Remove = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
  height: 25px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 7px;
  ${({ theme }) => `color: ${theme.colorWhite}; background: ${theme.colorRed}`};
  cursor: pointer;

  i {
    font-size: 12px;
  }
`;

Card.Brand = styled.div`
  ${({ theme }) => `color: ${theme.colorWhite}`};
  text-transform: uppercase;
  font-weight: 600;
`;

Card.Number = styled.div`
  word-spacing: 4px;
  font-size: 20px;
  letter-spacing: 2px;
  ${({ theme }) => `color: ${theme.colorWhite}`};
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: 600;
`;

Card.InfoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 390px) {
    margin: -5px;
  }
`;

Card.Left = styled.div`
  flex: 0 0 50%;
  @media (max-width: 390px) {
    flex: 0 0 100%;
    padding: 5px;
    text-align: center;
  }
`;

Card.Right = styled.div`
  flex: 0 0 50%;
  text-align: right;
  @media (max-width: 390px) {
    flex: 0 0 100%;
    padding: 5px;
    text-align: center;
  }
`;

Card.Label = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.9);
  letter-spacing: 1px;
  font-weight: 700;
`;

Card.Info = styled.div`
  margin-bottom: 0;
  margin-top: 5px;
  line-height: 18px;
  ${({ theme }) => `color: ${theme.colorWhite}`};
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 600;
  word-break: break-all;
`;

export default Card;
