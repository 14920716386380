import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Label, MealWrapper, ShowMoreButton } from './StyledComponents';
import { Flex } from '@rebass/grid';
import { DisplayOnDesktop, DisplayOnMobile } from 'styledComponents/helpers';

const MealCell = ({
  iri,
  label,
  textToShow,
  isLoading,
  ingredients,
  showDishIngredients,
  showMealIngredients,
}) => {
  const { t } = useTranslation();
  const [shownText, setShownText] = useState(
    textToShow.length > 70 ? textToShow.slice(0, 70) : textToShow
  );
  const [shownIngredients, setShownIngredients] = useState(
    ingredients?.length > 70 ? ingredients.slice(0, 70) : ingredients
  );

  const isCoiled =
    textToShow.length > shownText.length && textToShow.length > 70;
  const isUncoiled =
    shownText.length === textToShow.length && textToShow.length > 70;

  const areIngredientsCoiled =
    ingredients?.length > shownIngredients?.length && ingredients?.length > 70;
  const areIngredientsUncoiled =
    ingredients?.length === shownIngredients?.length &&
    ingredients?.length > 70;

  return (
    <MealWrapper isLoading={isLoading}>
      <Flex>
        <Label>{label}</Label>
        <DisplayOnDesktop>
          {showDishIngredients && ingredients && (
            <div style={{ margin: '0 0 0 5px' }}>
              <i
                className="fas fa-info-circle"
                style={{ cursor: 'pointer' }}
                onClick={() => showMealIngredients(ingredients)}
              />
            </div>
          )}
        </DisplayOnDesktop>
      </Flex>
      <h3>
        {shownText}
        {isCoiled && '...'}
      </h3>
      {isCoiled && (
        <ShowMoreButton onClick={() => setShownText(textToShow)}>
          Rozwiń
        </ShowMoreButton>
      )}
      {isUncoiled && (
        <ShowMoreButton onClick={() => setShownText(textToShow.slice(0, 70))}>
          Zwiń
        </ShowMoreButton>
      )}
      <DisplayOnMobile>
        {showDishIngredients && ingredients && (
          <>
            <h3 style={{ marginTop: '10px' }}>{`${t(
              '$*components.dishTile.dishComposition',
              '$$Skład'
            )}: ${shownIngredients}${areIngredientsCoiled && '...'}`}</h3>
            {areIngredientsCoiled && (
              <ShowMoreButton onClick={() => setShownIngredients(ingredients)}>
                Rozwiń
              </ShowMoreButton>
            )}
            {areIngredientsUncoiled && (
              <ShowMoreButton
                onClick={() => setShownIngredients(ingredients.slice(0, 70))}
              >
                Zwiń
              </ShowMoreButton>
            )}
          </>
        )}
      </DisplayOnMobile>
    </MealWrapper>
  );
};

export default MealCell;
