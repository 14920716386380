export const mainTheme = {
  // sizes
  baseFontSize: '16px',
  containerWidth: '1375px',
  narrowContainerWidth: '1110px',
  mobileContentWrapperPadding: '20px',
  baseBorderRadius: '8px',

  // font weights
  fontWeight300: '300',
  fontWeight500: '500',
  fontWeight900: '900',
  fontWeightNormal: 'normal',

  // colors
  baseFontColor: '#78818c',
  bodyColor: '#78818c',
  bodyBgColor: '#fafbfc',
  colorWhite: '#fff',
  colorBlack: '#000',
  colorGrey: '#e7eaec',
  colorFontSecondary: '#78818c',
  colorLightBlueGrey: '#ccd2da',
  colorGreen: '#0dbd65',
  colorIceBlue: '#f1f4f5',
  colorIceBlue2: '#eceff1',
  colorBlue: '#5fcdec',
  colorStarYellow: '#DECF00',
  colorStarGrey: '#D7D9DD',
  colorPremiumGold: '#e79918',
  colorDarkGrey: '#2a2a2a',
  colorRed: '#ff002a',
  colorRustyRed: '#ab1717',
  colorFacebookBlue: '#3b5998',
  colorYellow: '#f0f3a3',

  deviceBreakpoints: {
    large: 1240, //for mobile menu purpose
    desktop: 992,
    tablet: 768,
    mobile: 576,
  },

  // rebass breakpoints (this library requires exact name "breakpoints" for handling breakpoint changes in Box components)
  breakpoints: ['576px', '768px', '992px', '1240'],
};
