import React from 'react';
import { Flex } from '@rebass/grid';

import StyledSingleOrderEntry from 'styledComponents/elements/MyOrders/SingleOrderEntry';

const ORDER_DATE = 0;
const ORDER_NAME = 1;
const AMOUNT = 2;
const USED_POINTS = 3;
const STATUS = 4;
const ACTION = 5;

const SingleOrderEntry = ({ row }) => (
  <StyledSingleOrderEntry>
    <Flex justifyContent="space-between">
      <StyledSingleOrderEntry.Date>
        {row[ORDER_DATE]}
      </StyledSingleOrderEntry.Date>
      {row[AMOUNT]}
    </Flex>
    <Flex justifyContent="center">
      <StyledSingleOrderEntry.Name>
        {row[ORDER_NAME]}
      </StyledSingleOrderEntry.Name>
    </Flex>
    <Flex justifyContent="center">{row[USED_POINTS]}</Flex>
    <Flex mt={10} justifyContent="center">
      {row[STATUS]}
    </Flex>
    <Flex mt={10} justifyContent="center">
      {row[ACTION]}
    </Flex>
  </StyledSingleOrderEntry>
);

export default SingleOrderEntry;
