import React from 'react';
import { connect } from 'react-redux';

const SubscriptionAddIcon = ({ mainColor }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 67 67"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="matrix(1,0,0,1,-615.408,-1073.99)">
        <g transform="matrix(0.636131,-0.636131,0.636131,0.636131,-416.484,1457.75)">
          <path
            fill={mainColor}
            d="M1133.72,535.711C1135.17,534.266 1137.53,534.253 1138.99,535.712C1140.44,537.17 1140.44,539.519 1138.99,540.978C1138.99,540.978 1117.53,562.435 1117.53,562.435L1138.54,583.45C1140,584.908 1140,587.257 1138.54,588.716C1137.09,590.175 1134.74,590.174 1133.28,588.716C1133.28,588.716 1112.26,567.701 1112.26,567.701L1091.69,588.273C1090.23,589.732 1087.88,589.732 1086.42,588.274C1084.97,586.815 1084.97,584.466 1086.42,583.008C1086.42,583.008 1107.03,562.402 1107.03,562.402L1085.95,541.452C1084.49,539.993 1084.49,537.644 1085.95,536.186C1087.41,534.727 1089.76,534.727 1091.22,536.186C1091.22,536.186 1112.3,557.265 1112.3,557.265L1133.72,535.711Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default connect(({ app: { brand: { mainColor } } }) => ({ mainColor }))(
  SubscriptionAddIcon
);
