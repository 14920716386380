import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ReactPixel from 'react-facebook-pixel';

import { login } from '../actions/authActions';
import { getUserData } from 'views/AccountSettings/actions/accountSettingsActions';
import URLS from 'routes/routeUrls';

const AppleAuth = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const disableTracking = useSelector(
    state => state.nativeAppConfig.disableTracking
  );
  const history = useHistory();
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const token = urlSearchParams.get('token');
  const refreshToken = urlSearchParams.get('refreshToken');

  useEffect(() => {
    if (token && refreshToken) {
      dispatch(
        login(
          {
            isAppleAuth: true,
            token,
            refreshToken,
          },
          t
        )
      );

      if (!disableTracking) {
        const trackName = 'Logowanie';
        ReactPixel.trackCustom(trackName);
      }
      localStorage.setItem('keepMeLoggedIn', 'true');

      const routeRedirectedFrom = location.state?.from?.pathname;

      if (routeRedirectedFrom) {
        return history.push(routeRedirectedFrom);
      }

      if (
        window.location.href.includes(URLS.AUTH) ||
        window.location.href.includes(URLS.AUTH_APPLE)
      ) {
        return history.push(URLS.ACCOUNT_SETTINGS);
      }

      getUserData();
    }
  }, [token, refreshToken, disableTracking]);

  return null;
};

export default AppleAuth;
