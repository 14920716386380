import styled from 'styled-components';
import { fromPxToRem } from 'styledComponents/helpers';

const Basket = styled.div`
  position: relative;
  z-index: 10;
  padding: 15px 20px;
  border-radius: ${props => props.theme.baseBorderRadius};
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.colorWhite};
  margin-bottom: 25px;

  display: ${({ mobile }) => (mobile ? 'block' : 'none')};
  @media screen and (min-width: 1025px) {
    display: ${({ mobile }) => (mobile ? 'none' : 'block')};
  }
`;

Basket.Mobile = styled(Basket)`
  position: absolute;
  width: 100%;
  transform: translateY(-190px);
  opacity: 0;
  display: block;
  @media screen and (min-width: 1025px) {
    display: none;
  }
`;

Basket.Container = styled.div`
  @media screen and (min-width: 1025px) {
    position: sticky;
    top: 100px;
  }
`;

Basket.MyWrapper = styled.div`
  position: sticky;
  width: 100%;
  top: 165px;
  z-index: 2;

  .is-revealed {
    opacity: 1;
    transform: translateY(-85px);
    transition: all 0.4s ease-in-out;
    padding: 15px 25px;
    border-radius: 0 0 8px 8px;
  }
`;

Basket.Title = styled.h3`
  margin-bottom: 20px;
  font-size: ${fromPxToRem('18px')};
  display: none;
  @media screen and (min-width: 1025px) {
    display: ${({ mobile }) => (mobile ? 'none' : 'block')};
  }
`;

Basket.Item = styled.div`
  padding-bottom: 10px;

  &:last-child {
    border-bottom: 1px solid ${props => props.theme.colorIceBlue2};
  }
`;

Basket.ItemEntryPriceRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: 0 -4px;
  max-width: 110px;
  text-align: right;
`;

Basket.ItemEntryOldPrice = styled.span`
  flex: 0 0 auto;
  padding: 0 4px;
  text-decoration: line-through;
  color: ${props => props.theme.colorRed};
`;

Basket.ItemEntryNewPrice = styled.div`
  display: flex;
  padding: 0 4px;
  overflow-wrap: anywhere;
  font-size: ${fromPxToRem('14px')};
`;

Basket.AddonsWrapper = styled.div`
  max-width: 100%;
`;

Basket.SectionsWrapper = styled.div`
  padding: 3px 0;
`;

Basket.ItemWrapper = styled.div`
  padding-bottom: 7px;
  margin-bottom: 7px;

  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  ${({ isGroupHeader, theme, priceKey }) =>
    isGroupHeader &&
    priceKey === 'totalPrice' &&
    `border-bottom: 2px dashed ${theme.baseFontColor}; margin-bottom: 10px`}
`;

Basket.ItemEntry = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${fromPxToRem('14px')};
  font-weight: 700;

  ${({ isGroupHeader, theme }) =>
    isGroupHeader && `color: ${theme.baseFontColor}; padding: 3px 0`}
`;

Basket.AddonsItemEntry = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${fromPxToRem('14px')};

  > span {
    overflow-wrap: anywhere;
    flex-basis: 75%;
  }
`;

Basket.SectionsItemEntry = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${fromPxToRem('14px')};
  font-weight: 600;
`;

Basket.TotalPrice = styled(Basket.ItemEntry)`
  background: ${({ theme }) => theme.baseFontColor};
  color: ${({ theme }) => theme.colorWhite};
  margin: 0 -20px -15px;
  padding: 5px 20px;
  border-radius: 0 0 5px 5px;
  font-weight: 700;
`;

Basket.Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

Basket.CartWrapper = styled.div`
  position: sticky;
  height: 0;
  top: 100px;
  width: 100%;
`;

Basket.InfoDiscount = styled.div`
  white-space: nowrap;
`;

Basket.InfoLabel = styled.span`
  display: block;
  font-size: ${fromPxToRem('13px')};
  font-weight: ${props => props.theme.fontWeight500};
  line-height: 1rem;
`;

Basket.DiscountLabel = styled.span`
  display: block;
  font-size: ${fromPxToRem('13px')};
  font-weight: ${props => props.theme.fontWeight900};
  line-height: 1rem;
  margin: 10px 0 5px;
`;

Basket.SingleDiscount = styled.span`
  display: block;
  font-size: ${fromPxToRem('13px')};
  font-weight: ${props => props.theme.fontWeight500};
`;

export default Basket;
