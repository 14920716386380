import React, { useState } from 'react';
import { connect } from 'react-redux';
import Slider from 'rc-slider';
import { OnChange } from 'react-final-form-listeners';

import { clearSelectedDays } from 'common/components/Calendar/actions/calendarActions';
import FORM_FIELD_NAMES from 'views/NewOrder/const/formFieldNames';

import {
  SliderWrapper,
  DaysRangeWrapper,
  RangeBoundries,
  SliderPointer,
} from 'styledComponents/elements/Form/DurationSlider';

import { getFromQueryString } from 'common/shared/querystring';

import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import { useTranslation } from 'react-i18next';

const FADE_RANGE = 0.15;
const FADE_OFFSET = 0.1;

const calculateOpacity = number => (number > 0 ? number * 10 : 0);

const DurationSlider = ({
  input,
  clearSelectedDays,
  mainColor,
  daysMin,
  daysMax,
  testMode,
  testDaysMin,
  testDaysMax,
}) => {
  const { t } = useTranslation();
  // const [sliderPosition, setSliderPosition] = useState(0.5);

  // const defaultValue = +getFromQueryString(FORM_FIELD_NAMES.DURATION);

  const min = testMode ? testDaysMin : daysMin;
  const max = testMode ? testDaysMax : daysMax;
  const sliderPosition = (input.value - min) / (max - min);

  return (
    <SliderWrapper>
      <DaysRangeWrapper>
        <RangeBoundries fade={calculateOpacity(sliderPosition - FADE_OFFSET)}>
          {testMode ? testDaysMin : daysMin}{' '}
          {testMode
            ? testDaysMin === 1
              ? t('$*common.day', '$$dzień')
              : t('$*common.day_plural', '$$dni')
            : daysMin === 1
            ? t('$*common.day', '$$dzień')
            : t('$*common.day_plural', '$$dni')}
        </RangeBoundries>
        <RangeBoundries
          fade={
            sliderPosition > 1 - FADE_RANGE
              ? calculateOpacity(1 - FADE_OFFSET - sliderPosition)
              : false
          }
        >
          {testMode ? testDaysMax : daysMax} {t('$*common.day_plural', '$$dni')}
        </RangeBoundries>
      </DaysRangeWrapper>
      <Slider
        min={min}
        max={max}
        value={+input.value}
        onChange={p => {
          input.onChange(p);
        }}
        handle={props => {
          return (
            <SliderPointer
              daySingular={t('$*common.day', '$$dzień')}
              dayPlural={t('$*common.day_plural', '$$dni')}
              daysNumber={props.value}
              left={
                ((props.value - props.min) / (props.max - props.min)) * 100 +
                '%'
              }
            />
          );
        }}
        trackStyle={{
          backgroundColor: mainColor,
          height: 18,
          borderRadius: 0,
          cursor: 'pointer',
        }} // @todo spiac kolor
        railStyle={{
          backgroundColor: 'white',
          border: `1px solid ${mainColor}`,
          height: 18,
          borderRadius: 0,
          cursor: 'pointer',
        }}
      />
      <OnChange name={FORM_FIELD_NAMES.DURATION}>
        {() => requestAnimationFrame(() => clearSelectedDays())}
      </OnChange>
    </SliderWrapper>
  );
};

export default connect(
  ({
    app: {
      brand: { mainColor },
    },
    orderForm: {
      orderConfig: {
        disabledDays,
        calendarText,
        daysMin,
        daysMax,
        testDaysMin,
        testDaysMax,
      },
      calculations: { dietCosts },
    },
    calendar: { selectedDays, duration },
    dietForm: { forms, currentFormId },
  }) => ({
    disabledDays,
    selectedDays,
    duration,
    calendarText,
    forms,
    dietCosts,
    currentFormId,
    mainColor,
    daysMin,
    daysMax,
    testDaysMin,
    testDaysMax,
  }),
  { clearSelectedDays }
)(DurationSlider);
