import React from 'react';
import { isSameDay } from 'date-fns';

import {
  hasDisabledDayStatus,
  getDayStatusByCalendarDays,
} from 'common/components/BigCalendar/services/calendarService';

import { dateObjectToPolishMonthYear } from 'common/services/dateHelpers';

import SwitcherDayCellMobile from './SwitcherDayCellMobile';
import StyledDaysSwitcherMobile from 'styledComponents/elements/MenuPlanning/DaysSwitcherMobile';

import moment from 'moment';
import { DAY_STATUSES } from '../../../common/components/BigCalendar/const/dayStatuses';

const DaysSwitcherMobile = ({
  selectedDay,
  handleDayChange,
  visibleDays,
  setVisibleDays,
  dietCalendarDays,
}) => {
  return (
    <StyledDaysSwitcherMobile
      justifyContent="center"
      mt={20}
      flexDirection="column"
    >
      <StyledDaysSwitcherMobile.MonthRow>
        <StyledDaysSwitcherMobile.DayNavButton
          left={'true'}
          onClick={() => {
            setVisibleDays({ direction: 'left' });
          }}
        />
        <StyledDaysSwitcherMobile.MonthText>
          {dateObjectToPolishMonthYear(new Date(selectedDay))}
        </StyledDaysSwitcherMobile.MonthText>
        <StyledDaysSwitcherMobile.DayNavButton
          right={'true'}
          onClick={() => {
            setVisibleDays({ direction: 'right' });
          }}
        />
      </StyledDaysSwitcherMobile.MonthRow>
      <StyledDaysSwitcherMobile.DayCellsRow>
        {visibleDays.map(
          ({
            day,
            dayStatus,
            isNowBeforeIteratedDay,
            isDisabled,
            isConfigurable,
            isDayToday,
          }) => {
            return (
              <SwitcherDayCellMobile
                key={`day-cell-mobile-${day.getDate()}`}
                date={day}
                isSimple={!isSameDay(day, selectedDay)}
                disabled={isDisabled}
                dayStatus={dayStatus}
                configurable={isConfigurable}
                isSelectedDay={isSameDay(day, selectedDay)}
                handleDayChange={() => handleDayChange(day)}
                isNowBeforeIteratedDay={isNowBeforeIteratedDay}
                isDayToday={isDayToday}
              />
            );
          }
        )}
      </StyledDaysSwitcherMobile.DayCellsRow>
      <StyledDaysSwitcherMobile.WeekdaysRow>
        {visibleDays.map(({ day }) => (
          <div key={`day-cell-mobile-weekdays-${day.getDate()}`}>
            {moment.weekdaysShort()[day.getDay()]}
          </div>
        ))}
      </StyledDaysSwitcherMobile.WeekdaysRow>
    </StyledDaysSwitcherMobile>
  );
};

export default DaysSwitcherMobile;
