import React from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { getFromQueryString } from 'common/shared/querystring';
import { switchUser } from '../actions/authActions';
import URLS from 'routes/routeUrls';

const LoginAsUser = ({ history, switchUser }) => {
  const userData = {
    token: getFromQueryString('token'),
    refreshToken: getFromQueryString('refreshToken'),
    email: getFromQueryString('email'),
  };

  switchUser(userData);

  return <Redirect to={URLS.HOME} />;
};

export default connect(null, { switchUser })(withRouter(LoginAsUser));
