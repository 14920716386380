import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { sortByPosition } from 'utils/componentHelpers';
import { updateFormAddons } from 'views/NewOrder/actions/dietFormActions';
import { Addons, AddonTile } from './';

const AddonsWrapper = ({
  forms,
  currentFormId,
  updateFormAddons,
  multinational,
}) => {
  const { t } = useTranslation();
  const [dietAddons, setDietAddons] = useState([]);
  const currentAddons = forms[currentFormId].addons;

  useEffect(() => {
    setDietAddons(currentAddons);
  }, []);

  useEffect(() => {
    updateFormAddons(currentFormId, dietAddons);
  }, [dietAddons]);

  const currentAddon = id =>
    currentAddons.find(({ addon }) => addon === id) || {};

  return (
    <Addons
      excludedPlace="NEW_ORDER"
      renderAddons={({ categoryAddons }) =>
        sortByPosition(categoryAddons).map(
          ({ id, excludedPlaces, ...restCategoryAddon }) => {
            return (
              <AddonTile
                key={id}
                {...restCategoryAddon}
                currencySymbol={multinational.currencySymbol}
                setAddons={setDietAddons}
                initState={currentAddon(restCategoryAddon['@id'])}
                usageItems={[
                  {
                    label: t(
                      '$*orderFormAddons.firstDeliveryForDietUsageItem',
                      '$$pierwsza dostawa'
                    ),
                    value: 'FOR_DIET',
                  },
                  {
                    label: t(
                      '$*orderFormAddons.everyDeliveryForBagUsageItem',
                      '$$każda dostawa'
                    ),
                    value: 'FOR_BAG',
                  },
                ]}
              />
            );
          }
        )
      }
    />
  );
};

const mapStateToProps = ({
  app: {
    config: { multinational, addons },
  },
  dietForm: { forms, currentFormId },
}) => ({
  addons,
  forms,
  currentFormId,
  multinational,
});

const mapDispatchToProps = { updateFormAddons };

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(AddonsWrapper);
