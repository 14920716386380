import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
`;

const ImageWrapper = styled.div`
  flex: 0 0 50px;
  height: 50px;
  ${({ theme }) => `border: 1px solid ${theme.colorIceBlue2}`};
  border-radius: 5px;
`;

const Image = styled.div`
  ${({ image }) => `background: url(${image}) no-repeat center center`};
  display: block;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  background-size: cover;
`;

const Name = styled.span`
  margin-left: 15px;
  flex: 1 1 auto;
  word-wrap: anywhere;
`;

export { Wrapper, ImageWrapper, Image, Name };
