export const SET_SELECTED_DIET = 'userDiet/SET_SELECTED_DIET';
export const SET_SELECTED_DAY = 'userDiet/SET_SELECTED_DAY';
export const SET_VISIBLE_CALENDAR_DAYS = 'userDiet/SET_VISIBLE_CALENDAR_DAYS';

export const CLEAR_USER_DIET = 'userDiet/CLEAR_USER_DIET';

export const FETCH_USER_DIETS_LIST_REQUEST =
  'userDiet/FETCH_USER_DIETS_LIST_REQUEST';
export const FETCH_USER_DIETS_LIST_SUCCESS =
  'userDiet/FETCH_USER_DIETS_LIST_SUCCESS';
export const FETCH_USER_DIETS_LIST_FAILURE =
  'userDiet/FETCH_USER_DIETS_LIST_FAILURE';

export const FETCH_USER_DIET_CALENDAR_REQUEST =
  'userDiet/FETCH_USER_DIET_CALENDAR_REQUEST';
export const FETCH_USER_DIET_CALENDAR_SUCCESS =
  'userDiet/FETCH_USER_DIET_CALENDAR_SUCCESS';
export const FETCH_USER_DIET_CALENDAR_FAILURE =
  'userDiet/FETCH_USER_DIET_CALENDAR_FAILURE';

export const SET_CURRENTLY_MAPPING_DAYS = 'userDiet/SET_CURRENTLY_MAPPING_DAYS';
export const CLEAR_CURRENTLY_MAPPING_DAYS =
  'userDiet/CLEAR_CURRENTLY_MAPPING_DAYS';
