import React, { Component, Fragment } from 'react';
import { Flex } from '@rebass/grid';

import HeadingSection from 'layouts/components/HeadingSection';
import { ButtonPrimary } from 'styledComponents/elements/Button';

import withUserAddresses from 'common/components/UserAddresses';

import UserAddressesForm from 'views/AccountSettings/components/Form/UserAddressesForm';
import UserAddressesFormWrapper from 'views/AccountSettings/components/Form/UserAddressesFormWrapper';
import AddressDeleteButton from '../components/AddressDeleteButton';
import { withTranslation } from 'react-i18next';

class UserAddressesSection extends Component {
  state = {
    newAddressFormOpened: false,
  };

  handleNewAddressFormOpening = isOpened => {
    this.setState({ newAddressFormOpened: isOpened });
  };

  onAddressDelete = id => {
    const { deleteAddress, token } = this.props;

    deleteAddress(id, token);
  };

  canDeleteAddress = ({ isDefault, containsActiveDiets }) => {
    const { addresses } = this.props;

    return !isDefault && !containsActiveDiets && addresses.length > 1;
  };

  sortDefaultFirst = addressesArray => {
    let sorted = [];
    addressesArray.forEach(address => {
      if (address.isDefault) {
        sorted.unshift(address);
      } else {
        sorted.push(address);
      }
    });

    return sorted;
  };

  render() {
    const { newAddressFormOpened } = this.state;
    const { addresses, t } = this.props;
    return (
      <Fragment>
        <HeadingSection
          title={t('$*userAddressesSection.myAddressesHeader', '$$Moje adresy')}
        />
        {this.sortDefaultFirst(addresses).map(address => (
          <UserAddressesFormWrapper key={address.id} initialValues={address}>
            <UserAddressesForm
              deleteButton={
                <AddressDeleteButton
                  label={t('$*userAddressesSection.removeCaption', '$$Usuń')}
                  isVisible={this.canDeleteAddress(address)}
                  action={() => this.onAddressDelete(address.id)}
                />
              }
            />
          </UserAddressesFormWrapper>
        ))}
        {newAddressFormOpened && (
          <UserAddressesFormWrapper
            key={'new-address'}
            initialValues={{ isDefault: false }}
          >
            <UserAddressesForm
              isBlank={newAddressFormOpened}
              deleteButton={
                <AddressDeleteButton
                  label={t('$*userAddressesSection.cancelCaption', '$$Anuluj')}
                  action={this.handleNewAddressFormOpening.bind(this, false)}
                />
              }
            />
          </UserAddressesFormWrapper>
        )}
        <Flex justifyContent="center">
          <ButtonPrimary
            type="button"
            uppercased
            sizeMiddle
            weightBold
            disabled={newAddressFormOpened}
            onClick={() => this.handleNewAddressFormOpening(true)}
            fullWidthOnMobile
          >
            {t('$*userAddressesSection.addNewAddressCaption', '$$Dodaj adres')}
          </ButtonPrimary>
        </Flex>
      </Fragment>
    );
  }
}

export default withTranslation()(withUserAddresses(UserAddressesSection));
