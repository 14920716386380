import React from 'react';

import StyledSwitcherDayCellMobile from 'styledComponents/elements/MenuPlanning/SwitcherDayCellMobile';

import { DAY_STATUSES } from 'common/components/BigCalendar/const/dayStatuses';
import SwitcherIcon from './SwitcherIcon';

const SwitcherDayCellMobile = ({
  date,
  isSimple,
  disabled,
  configurable,
  handleDayChange,
  dayStatus,
  isSelectedDay,
  isNowBeforeIteratedDay,
  isDayToday,
}) => {
  const isPastDayWithoutDiet =
    dayStatus === DAY_STATUSES.NOT_DIET_CANT_PLACE_ORDER;
  const isAddDayPossible =
    dayStatus === DAY_STATUSES.NOT_DIET_CAN_PLACE_ORDER ||
    dayStatus === DAY_STATUSES.SUBSCRIPTION_SUSPENDED;

  const hasBaseFontBackground =
    dayStatus !== DAY_STATUSES.ADD_DIET &&
    dayStatus !== DAY_STATUSES.NOT_DIET_CANT_PLACE_ORDER;

  return (
    <StyledSwitcherDayCellMobile
      isSimple={isSimple}
      onClick={handleDayChange}
      disabled={disabled}
      configurable={configurable}
      isSelectedDay={isSelectedDay}
      isAddDayPossible={isAddDayPossible}
      hasBaseFontBackground={hasBaseFontBackground}
      isDayToday={isDayToday}
      isPastDayWithoutDiet={isPastDayWithoutDiet}
    >
      {/*{(dayStatus === DAY_STATUSES.TODAY ||*/}
      {/*  dayStatus === DAY_STATUSES.ADD_DIET ||*/}
      {/*  dayStatus === DAY_STATUSES.DETAILS_SHOW_PREV ||*/}
      {/*  dayStatus === DAY_STATUSES.RATE_MENU ||*/}
      {/*  dayStatus === DAY_STATUSES.RATED_MENU) && (*/}
      {/*  <IconWrapper>*/}
      {/*    <i className={getIconClassName()} />*/}
      {/*  </IconWrapper>*/}
      {/*)}*/}
      <SwitcherIcon
        dayStatus={dayStatus}
        isNowBeforeIteratedDay={isNowBeforeIteratedDay}
      />
      <StyledSwitcherDayCellMobile.DayNumber>
        {date.getDate()}
      </StyledSwitcherDayCellMobile.DayNumber>
    </StyledSwitcherDayCellMobile>
  );
};

export default SwitcherDayCellMobile;
