import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Flex } from '@rebass/grid';
import { compose } from 'recompose';
import { format, isBefore, isToday } from 'date-fns';
import { withTranslation } from 'react-i18next';

import { DisplayOnMobile, DisplayOnDesktop } from 'styledComponents/helpers';

import HeadingSection from 'layouts/components/HeadingSection';
import DaysSwitcherDesktop from '../components/DaysSwitcherDesktop';
import DaysSwitcherMobile from '../components/DaysSwitcherMobile';

import {
  dateISOStringToPolishTransformed,
  dateObjectToYearMonthDay,
} from 'common/services/dateHelpers';

import { buildUrl } from 'utils/route';
import URLS from 'routes/routeUrls';

import { togglePremium } from 'views/MenuPlanning/actions/menuPlanningActions';

import { getBagIdForCurrentDay } from 'views/UserDiet/services/userDietRepresenter';
import {
  setSelectedDay,
  setVisibleCalendarDays,
} from '../actions/menuPlanningActions';

import {
  getLastDaysBeforeAndAfter,
  getVisibleDays,
  getCurrentCenteredDay,
  getPermanentlyDisabledDays,
} from '../services/calendarService';
import { getDayStatusByCalendarDays } from '../../../common/components/BigCalendar/services/calendarService';
import { DAY_STATUSES } from '../../../common/components/BigCalendar/const/dayStatuses';
import DefaultSubscriptionDietSettings from '../../UserDiet/containers/DefaultSubscriptionDietSettings';

class MenuPlanningHeadingSection extends Component {
  state = {
    modalOpened: null,
    visibleDays: getLastDaysBeforeAndAfter(this.props.day),
    calendarOffset: -1,
    formattedDisabledDays: [],
    permanentlyDisabledDays: [],
  };

  handleModalOpen = modalId => {
    //open modal if you have standard option only
    !this.props.bag.optionChangeMenu && this.setState({ modalOpened: modalId });
  };

  handleDayOffsets = ({ direction }) => {
    const { setVisibleCalendarDays } = this.props;
    const { calendarOffset } = this.state;

    const hideDisabledDays = this.props.hideDisabledDaysOnClientCalendar;

    const formattedDisabledDays = hideDisabledDays
      ? this.props.disabledDays.map(day => format(new Date(day), 'YYYY-MM-DD'))
      : [];

    const permanentlyDisabledDays = hideDisabledDays
      ? getPermanentlyDisabledDays(this.props.weekDays)
      : [];

    const {
      calendarOffset: newCalendarOffset,
      centeredVisibleDay,
    } = getCurrentCenteredDay({
      calendarOffset,
      direction,
      formattedDisabledDays,
      selectedDay: this.props.day,
      permanentlyDisabledDays,
    });

    const visibleDays = getVisibleDays({
      selectedDay: centeredVisibleDay,
      numberOfDaysOnEachSide: 3,
      disabledDays: formattedDisabledDays,
      permanentlyDisabledDays,
    });

    //only for rendering purpose
    setVisibleCalendarDays(visibleDays);

    this.setState({ visibleDays, calendarOffset: newCalendarOffset });
  };

  handleDayChange = day => {
    const {
      history,
      selectedDiet,
      setSelectedDay,
      setVisibleCalendarDays,
      calendar: { days },
    } = this.props;

    const bagId = getBagIdForCurrentDay({
      currentDay: day,
      calendarDays: days,
    });

    const visibleDays = getVisibleDays({
      selectedDay: day,
      numberOfDaysOnEachSide: 3,
      disabledDays: this.state.formattedDisabledDays,
      permanentlyDisabledDays: this.state.permanentlyDisabledDays,
    });

    this.setState({ visibleDays });

    setVisibleCalendarDays(visibleDays);
    setSelectedDay(dateObjectToYearMonthDay(day));

    history.push(
      buildUrl(URLS.MENU_PLANNING, {
        day: dateObjectToYearMonthDay(day),
        bagId,
        dietId: selectedDiet,
      })
    );
  };

  componentDidMount() {
    this.handleDayOffsets({});

    const hideDisabledDays = this.props.hideDisabledDaysOnClientCalendar;

    const formattedDisabledDays = hideDisabledDays
      ? this.props.disabledDays.map(day => format(new Date(day), 'YYYY-MM-DD'))
      : [];

    const permanentlyDisabledDays = hideDisabledDays
      ? getPermanentlyDisabledDays(this.props.weekDays)
      : [];

    const selectedDay = this.props.day;

    const visibleDays = getVisibleDays({
      selectedDay,
      numberOfDaysOnEachSide: 3,
      disabledDays: formattedDisabledDays,
      permanentlyDisabledDays,
    });

    setVisibleCalendarDays(visibleDays);

    this.setState({
      visibleDays,
      formattedDisabledDays,
      permanentlyDisabledDays,
    });
  }

  render() {
    const {
      selectedDay,
      calendar: { days },
    } = this.props;

    const fixedVisibleDays = this.state.visibleDays.map(day => {
      const dayStatus = getDayStatusByCalendarDays({
        currentDay: day,
        calendarDays: days,
      });

      const isNowBeforeIteratedDay = isBefore(new Date(), day) || isToday(day);

      const isDayToday = isToday(day);

      const isDisabled =
        dayStatus === DAY_STATUSES.DISABLED ||
        dayStatus === DAY_STATUSES.DISABLED_WITH_BAG ||
        dayStatus === DAY_STATUSES.ERROR_ON_RENEW;

      const isConfigurable =
        dayStatus ===
          DAY_STATUSES.NOT_DELIVERED_WITH_CONFIGURABLE_WITHOUT_MENU ||
        dayStatus === DAY_STATUSES.NOT_DELIVERED_WITH_CONFIGURABLE_ALL ||
        dayStatus === DAY_STATUSES.NOT_DELIVERED_BLOCKED;

      return {
        day,
        isNowBeforeIteratedDay,
        isDayToday,
        isDisabled,
        isConfigurable,
        dayStatus,
      };
    });

    return (
      <Fragment>
        <Flex
          flexDirection={['column-reverse', 'column-reverse', 'column']}
          justifyContent={'center'}
        >
          <HeadingSection
            title={dateISOStringToPolishTransformed(selectedDay)}
          />
          <Fragment>
            <DisplayOnMobile>
              <DaysSwitcherMobile
                selectedDay={selectedDay}
                visibleDays={fixedVisibleDays}
                dietCalendarDays={days}
                handleDayChange={day => this.handleDayChange(day, false)}
                setVisibleDays={this.handleDayOffsets}
              />
            </DisplayOnMobile>
            <DisplayOnDesktop>
              <DaysSwitcherDesktop
                selectedDay={selectedDay}
                visibleDays={fixedVisibleDays}
                dietCalendarDays={days}
                handleDayChange={day => this.handleDayChange(day, true)}
                setVisibleDays={this.handleDayOffsets}
                selectedLanguage={this.props.i18n.language}
              />
            </DisplayOnDesktop>
          </Fragment>
        </Flex>
      </Fragment>
    );
  }
}

export default compose(
  connect(
    ({
      menuPlanning: {
        selectedDay,
        isPremium,
        visibleCalendarDays,
        selectedDiet,
        calendar,
        bag,
      },
      app: {
        config: {
          disabledDays,
          modules: {
            ConfigClientPanel: { hideDisabledDaysOnClientCalendar },
          },
        },
        brand: {
          monday,
          tuesday,
          wednesday,
          thursday,
          friday,
          sunday,
          saturday,
        },
      },
    }) => ({
      isPremium,
      visibleCalendarDays,
      selectedDiet,
      calendar,
      selectedDay,
      disabledDays,
      hideDisabledDaysOnClientCalendar,
      bag,
      weekDays: {
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        sunday,
        saturday,
      },
    }),
    {
      togglePremium,
      setSelectedDay,
      setVisibleCalendarDays,
    }
  ),
  withRouter,
  withTranslation()
)(MenuPlanningHeadingSection);
