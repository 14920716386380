import React from 'react';
import StyledSingleDetailSection from '../../../../../styledComponents/elements/MenuPlanning/SingleDetailSection';
import { DietDetailsSection, DietIcon } from './index';

import {
  getDeliveryAddressParam,
  getDietDetailsParam,
} from '../../../services/menuPlanningRepresenter';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { isAfter } from 'date-fns';
import useIsEditable from '../hooks/useIsEditable';

const DEADLINE_BRAND_KEY = 'changeDietDeadLine';
const EDITING_ALLOW_KEY = 'allowChangeDiet';

const UserDiet = ({ bag, action, t }) => {
  const dietDetails = getDietDetailsParam(bag, t);
  const { hasTimePassed, isEditingAllowed } = useIsEditable(
    DEADLINE_BRAND_KEY,
    EDITING_ALLOW_KEY
  );

  return (
    <DietDetailsSection
      title={t('$*menuPlaning.details.yourDietTitle')}
      deadlineBrandKey={DEADLINE_BRAND_KEY}
      showTooltip={isEditingAllowed}
      isEditable={!hasTimePassed}
    >
      <div
        style={{
          marginBottom: '12px',
          display: 'flex',
          alignItems: 'center',
          fontSize: '15px',
        }}
      >
        <DietIcon bag={bag} />
        <div style={{ marginLeft: '5px' }}>{dietDetails}</div>
      </div>
      {!hasTimePassed && isEditingAllowed && (
        <StyledSingleDetailSection.Button
          type="button"
          uppercased
          sizeMiddle
          weightBold
          onClick={action}
        >
          {t('$*menuPlaning.details.modifyDietCaption')}
        </StyledSingleDetailSection.Button>
      )}
    </DietDetailsSection>
  );
};

export default compose(
  withTranslation(),
  connect(({ menuPlanning: { bag } }) => ({
    bag,
  }))
)(UserDiet);
