import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  BaseWrapper,
  BaseDayNumber,
  BaseCaption,
} from 'styledComponents/elements/BigCalendarDayCell';
import { DisplayOnDesktop } from 'styledComponents/helpers';

const Wrapper = styled(BaseWrapper)``;

const Caption = styled(BaseCaption)`
  color: ${props => props.theme.baseFontColor};
`;

const StyledDayNumber = styled(BaseDayNumber)`
  color: ${props => props.theme.baseFontColor};
  font-weight: ${props => props.theme.fontWeight900};
`;

const SubscriptionDayDisabled = ({ children }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <StyledDayNumber>{children}</StyledDayNumber>
      <DisplayOnDesktop>
        <Caption className="DayPicker-Caption">
          {t('$*calendarDayCell.add', '$$Dodaj')}
        </Caption>
      </DisplayOnDesktop>
    </Wrapper>
  );
};

export default SubscriptionDayDisabled;
