import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  REFRESH_USER_TOKENS,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_FAILURE,
  REFRESH_LOGIN_SUCCESS,
  REFRESH_LOGIN_FAILURE,
  REFRESH_LOGIN_REQUEST,
  SWITCH_USER,
  SET_CREDIT_CARDS,
  DELETE_CREDIT_CARD,
  SET_ALL_PAYMENT_CARDS,
} from '../actions/const';

const INITIAL_STATE = {
  user: {
    phone: null,
  },
  token: localStorage.getItem('t') || null,
  email: null,
  refreshToken: localStorage.getItem('rt') || null,
  isLoading: false,
  lastAction: null,
  stripe: {
    cards: [],
    key: null,
    secret: null,
  },
  allPaymentCards: [],
  isFetchedAllPaymentCards: false,
};

const authReducer = (
  state = INITIAL_STATE,
  {
    type,
    user,
    token,
    refreshToken,
    email,
    lastAction,
    stripe,
    paymentCards,
    id,
  }
) => {
  switch (type) {
    case LOGIN_REQUEST:
    case LOGOUT_REQUEST:
    case REGISTER_REQUEST:
    case REFRESH_LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case REFRESH_USER_TOKENS:
      return {
        ...state,
        token,
        refreshToken,
      };
    case LOGIN_SUCCESS:
    case REFRESH_LOGIN_SUCCESS:
      localStorage.setItem('t', token);
      localStorage.setItem('rt', refreshToken);

      return {
        ...state,
        user,
        token,
        refreshToken,
        lastAction,
        isLoading: false,
      };

    case SWITCH_USER:
      localStorage.setItem('t', token);
      localStorage.setItem('rt', refreshToken);

      return {
        ...state,
        token,
        refreshToken,
        email,
        lastAction,
        isLoading: false,
      };

    case REFRESH_LOGIN_FAILURE:
    case REGISTER_FAILURE:
    case LOGOUT_FAILURE:
    case LOGIN_FAILURE:
    case LOGOUT_SUCCESS:
      localStorage.removeItem('t');
      localStorage.removeItem('rt');

      return {
        ...INITIAL_STATE,
        token: null,
        refreshToken: null,
        isLoading: false,
        email: null,
      };

    case REGISTER_SUCCESS:
      return INITIAL_STATE;
    case SET_CREDIT_CARDS:
      return {
        ...state,
        stripe,
      };
    case SET_ALL_PAYMENT_CARDS:
      return {
        ...state,
        allPaymentCards: paymentCards,
        isFetchedAllPaymentCards: true,
      };
    case DELETE_CREDIT_CARD:
      return {
        ...state,
        stripe: {
          ...state.stripe,
          cards: [...state.stripe.cards.filter(card => card.id !== id)],
        },
        allPaymentCards: [
          ...state.allPaymentCards.filter(card => card.index !== id),
        ],
      };

    default:
      return state;
  }
};

export default authReducer;
