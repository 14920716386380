import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  BaseWrapper,
  BaseCaption,
  BaseDayNumber,
  BaseIconWrapper,
} from 'styledComponents/elements/BigCalendarDayCell';
import { fromPxToRem, media, DisplayOnDesktop } from 'styledComponents/helpers';
import PartiallyRatedIcon from 'common/components/BigCalendar/icons/PartiallyRatedIcon';
import WarningIcon from '../BigCalendar/icons/WarningIcon';

const Wrapper = styled(BaseWrapper)`
  background-color: ${props => props.theme.colorWhite};
  border: none;
  outline: none;
`;

const IconWrapper = styled(BaseIconWrapper)`
  top: 15px;
  right: -8px;
  ${media.tabletDown`
    width: 20px;
    height: 20px;
    top: 14px;
    right: -6px;
  `}
`;

const Caption = styled(BaseCaption)`
  color: ${props => props.theme.bodyColor};
`;

const StyledDayNumber = styled(BaseDayNumber)`
  color: ${props => props.theme.bodyColor};
  margin: auto;
`;

const DisabledDayWithBag = ({ children, isIteratedDayToday }) => {
  const { t } = useTranslation();
  return (
    <Wrapper isIteratedDayToday={isIteratedDayToday}>
      <IconWrapper>
        <WarningIcon />
      </IconWrapper>
      <StyledDayNumber>{children}</StyledDayNumber>
      <DisplayOnDesktop>
        <Caption>
          {t('$*menuPlanning.pointsReturnModal.title', 'Uwaga!')}
        </Caption>
      </DisplayOnDesktop>
    </Wrapper>
  );
};

export default DisabledDayWithBag;
