import styled from 'styled-components';
import { media, fromPxToRem } from 'styledComponents/helpers';
import { Link } from 'react-router-dom';

const Confirmation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

Confirmation.Title = styled.h1`
  margin-top: 90px;
  margin-bottom: 15px;
  font-size: ${fromPxToRem('34px')};

  ${media.mobileDown`
    margin-top: 15px;
    font-size: ${fromPxToRem('30px')};
  `};
`;

Confirmation.Subtitle = styled.h2`
  margin-bottom: 60px;
  font-size: ${fromPxToRem('24px')};
  text-align: center;

  ${media.mobileDown`
    font-size: ${fromPxToRem('20px')};
  `};
`;

Confirmation.Description = styled.p`
  font-size: ${fromPxToRem('20px')};
  text-align: center;

  ${media.mobileDown`
    font-size: ${fromPxToRem('16px')};
  `};
`;

Confirmation.Info = styled.div`
  margin-bottom: 50px;
  text-align: center;
`;

Confirmation.Link = styled(Link)`
  margin-bottom: 35px;
  background-color: ${props => props.theme.baseFontColor};
  border-radius: 10px;
  padding: 15px 40px;
  text-align: center;
  font-size: ${fromPxToRem('20px')};
  font-weight: ${props => props.theme.fontWeight900};
  color: ${props => props.theme.colorWhite};
  text-transform: uppercase;

  ${media.mobileDown`
    width: 100%;
  `};
`;

export default Confirmation;
