import axios from 'axios';

import { BASE_URL, URLS } from 'configuration';
import showToast from './toast';

import HTTP_CODES from '../common/shared/httpCodes';

import Cookies from 'cookies-js';

import ROUTE_URLS from '../routes/routeUrls';

import store from 'store.js';

import { getI18n } from 'react-i18next';

const NESTED_OBJECT_KEY_NAME = 'hydra:member';

const transformJsonResponse = data => {
  if (data) {
    data = JSON.parse(data);
  }

  return data;
};

const transformNestedResponse = data => {
  if (data) {
    data = data[NESTED_OBJECT_KEY_NAME];
  }

  return data;
};

axios.defaults.withCredentials = true;

export const http = axios.create({
  baseURL: BASE_URL,
});

http.interceptors.request.use(
  request => {
    const { token, email: impersonateEmail } = store.getState().auth;
    request.headers.common['X-CP-V'] =
      process.env.REACT_APP_APP_VERSION || 'not-set';
    if (token) {
      request.headers.common['Authorization'] = `Bearer ${token}`;
    }

    if (impersonateEmail) {
      request.headers.common['x-switch-user'] = impersonateEmail;
    }

    request.headers['X-LOCALE'] = getI18n().language;

    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

if (process.env.NODE_ENV !== 'production') {
  http.defaults.headers.common['X-DEV-FRONTEND-KEY'] =
    process.env.REACT_APP_DEV_FRONTEND_KEY;
  http.defaults.headers.common['X-DEV-FRONTEND-ORIGIN'] =
    process.env.REACT_APP_DEV_FRONTEND_ORIGIN;
}

export const get = (url, params = {}, isNestedResponse) =>
  http({
    url,
    params,
    method: 'get',
    transformResponse: [
      data => transformJsonResponse(data),
      data => (isNestedResponse ? transformNestedResponse(data) : data),
    ],
  });

export const post = (url, data) => http({ url, data, method: 'post' });

export const put = (url, data) => http({ url, data, method: 'put' });

export const patch = (url, data) => http({ url, data, method: 'patch' });

export const remove = url => http({ url, method: 'delete' });
