import React, { Fragment } from 'react';
import { Flex } from '@rebass/grid';

import { Heading } from 'styledComponents/elements/Typography';
import Spacer from 'styledComponents/elements/Spacer';

import RateMenuForm from './RateMenuForm';
import CountDown from '../../../common/components/CountDown';
import { NotificationWrapper } from '../../../styledComponents/elements/MenuPlanning/SingleDetailSection';
import { useTranslation } from 'react-i18next';
import { isAfter } from 'date-fns';
import { DAY_STATUSES } from '../../../common/components/BigCalendar/const/dayStatuses';

const MenuRatingSection = ({ bag, meals }) => {
  const { t } = useTranslation();

  const isToday = bag.state === DAY_STATUSES.TODAY;
  const timePassed = isAfter(new Date(), new Date(bag.rateDeadline));

  return (
    <Fragment>
      <Flex
        justifyContent={'space-between'}
        alignItems={'center'}
        flexDirection={['column', 'row']}
      >
        <Heading noPadding textAlignMobile="center">
          {t('$*calendarDayCell.pleaseRate', '$$Oceń menu')}
        </Heading>
        <CountDown
          date={bag.rateDeadline}
          endMessage={t(
            '$*menuPlanning.menuDetailsSection.rateTimeUp',
            '$$Czas na ocenę minął'
          )}
          onEndAction={() => {
            if (!(isToday && timePassed)) {
              window.location.reload();
            }
          }}
        />
      </Flex>
      {bag.notifications.map(el => (
        <NotificationWrapper dangerouslySetInnerHTML={{ __html: el.content }} />
      ))}
      <Spacer height="32px" />
      <RateMenuForm bag={bag} meals={meals} horizontalPadding={0} />
    </Fragment>
  );
};

export default MenuRatingSection;
