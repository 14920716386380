import React from 'react';
import { Box } from '@rebass/grid';

const inputGap = '8px';

const FormFieldBox = ({ children, width, ...rest }) => (
  <Box
    width={[1, 1, `calc(${width} - calc(2 * ${inputGap}))`]}
    m={[0, 0, inputGap]}
    {...rest}
  >
    {children}
  </Box>
);

export default FormFieldBox;
