import React from 'react';

import {
  StyledCheckboxWrapper,
  StyledCheckboxDescription,
} from 'styledComponents/elements/Form/FormCheckbox';

import CheckboxSwitch from './FormCheckbox/CheckboxSwitch';
import CheckboxRounded from './FormCheckbox/CheckboxRounded';
import SwitchExtended from './FormCheckbox/SwitchExtended';
import { ToggleRounded } from './FormCheckbox/ToggleRounded';

const getCheckboxComponent = style => {
  switch (style) {
    case 'togglebutton':
      return ToggleRounded;
    case 'rounded':
      return CheckboxRounded;
    case 'switch_extended':
      return SwitchExtended;
    case 'switch':
    default:
      return CheckboxSwitch;
  }
};

const FormCheckbox = ({
  input,
  children,
  componentStyle,
  descriptionAlignment = 'right',
  linkLabel,
  label,
  link,
  disabled,
  switchLabels,
}) => {
  const CheckboxComponent = getCheckboxComponent(componentStyle);

  return (
    <StyledCheckboxWrapper descriptionAlignment={descriptionAlignment}>
      <CheckboxComponent
        label={componentStyle === 'switch_extended' && label}
        input={input}
        disabled={disabled}
        switchLabels={switchLabels}
      />
      <StyledCheckboxDescription
        descriptionAlignment={descriptionAlignment}
        componentStyle={componentStyle}
      >
        {children}
        {link && linkLabel && (
          <a target="_blank" href={link} rel="noopener noreferrer">
            {linkLabel}
          </a>
        )}
      </StyledCheckboxDescription>
    </StyledCheckboxWrapper>
  );
};

export default FormCheckbox;
