import { SET_COUNTER_PWA_MODAL, INCREMENT_PWA_MODAL } from '../actions/const';

const INITIAL_STATE = {
  pwaModalCounter: 0,
};

const appGlobalReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_COUNTER_PWA_MODAL: {
      return { ...state, pwaModalCounter: payload };
    }
    case INCREMENT_PWA_MODAL: {
      return { ...state, pwaModalCounter: state.pwaModalCounter + 1 };
    }

    default:
      return state;
  }
};

export default appGlobalReducer;
