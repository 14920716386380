import { BASE_URL } from 'configuration';
import React from 'react';

export const DietIcon = ({ bag }) => {
  return bag?.diet?.icon ? (
    <img
      src={BASE_URL + bag.diet.icon}
      alt="DIET_ICON"
      width="40px"
      height="40px"
    />
  ) : null;
};
