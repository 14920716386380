import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fromPxToRem, media } from 'styledComponents/helpers';
import ROUTE_URLS from 'routes/routeUrls';
import { useHistory, Link } from 'react-router-dom';

const Title = styled.h1`
  margin-top: 90px;
  margin-bottom: 15px;
  font-size: ${fromPxToRem('34px')};
  text-align: center;

  ${media.mobileDown`
    margin-top: 15px;
    font-size: ${fromPxToRem('30px')};
  `};
`;

const Description = styled.p`
  font-size: ${fromPxToRem('20px')};
  text-align: center;

  ${media.mobileDown`
    font-size: ${fromPxToRem('16px')};
  `};
`;

const StyledLink = styled(Link)`
  margin: 35px auto 0;
  background-color: ${props => props.theme.baseFontColor};
  border-radius: 10px;
  padding: 15px 40px;
  text-align: center;
  font-weight: ${props => props.theme.fontWeight900};
  color: ${props => props.theme.colorWhite};
  text-transform: uppercase;
  display: table;

  ${media.mobileDown`
    width: 100%;
  `};
`;

const AppleError = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Title>
        {t('$*authAppleErrorPage.title', '$$Błąd Autoryzacji Apple')}
      </Title>
      <Description
        dangerouslySetInnerHTML={{
          __html: t(
            '$*authAppleErrorPage.description',
            '$$Podczas autoryzacji wystąpił niespodziewany błąd.<br /> Spróbuj ponownie później.'
          ),
        }}
      />

      <StyledLink to={ROUTE_URLS.AUTH_LOGIN}>
        {t('$*authAppleErrorPage.goToLoginPage', '$$Powrót do logowania')}
      </StyledLink>
    </div>
  );
};

export default AppleError;
