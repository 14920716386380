import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { addDays } from 'date-fns';
import DayPicker from 'react-day-picker';
import MomentLocalUtils from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';
import { compose } from 'recompose';

import {
  toggleDay,
  setDaysRange,
  incrementCalendarClicks,
  unselectSaturdays,
  unselectSundays,
  clearSelectedDays,
  setSelectedDays,
} from './actions/calendarActions';

import { setNextTabDisability } from 'views/NewOrder/components/OrderFormTabs/actions/orderFormTabsActions';

import {
  getDayIndex,
  getInitialMonth,
  mapLanguageToDatePickerLocale,
} from './services/calendarService';
import { getDisabledDays } from 'common/services/calendarService';
import { TODAY } from 'common/shared/weekdays';
import { OnChange } from 'react-final-form-listeners';

import { withTranslation } from 'react-i18next';

class Calendar extends Component {
  constructor(props) {
    super(props);

    this.onDaysChange = this.onDaysChange.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.el = React.createRef();
  }

  componentDidMount() {
    const { value, setSelectedDays } = this.props;

    this.onDaysChange(value);
    setSelectedDays(value);
  }

  disableNextTab = () => {
    const {
      paymentMode,
      duration,
      selectedDays,
      setNextTabDisability,
    } = this.props;

    const paymentModeDuration = paymentMode === '1' ? 1 : duration;
    setNextTabDisability(selectedDays.length !== paymentModeDuration);
  };

  componentDidUpdate(prevProps) {
    const {
      includeSaturdays,
      includeSundays,
      unselectSaturdays,
      unselectSundays,
    } = this.props;

    if (prevProps.includeSaturdays === true && includeSaturdays === false) {
      unselectSaturdays().then(() => {
        this.disableNextTab();
      });
    }

    if (prevProps.includeSundays === true && includeSundays === false) {
      unselectSundays().then(() => {
        this.disableNextTab();
      });
    }
  }

  onClear() {
    const { clearSelectedDays } = this.props;

    clearSelectedDays().then(() => this.onDaysChange());
  }

  onDaysChange(value) {
    const { selectedDays, onChange } = this.props;
    onChange(value || selectedDays);
  }

  selectDaysRange(day) {
    const {
      setDaysRange,
      excludedDays = [],
      incrementCalendarClicks,
    } = this.props;

    incrementCalendarClicks();
    setDaysRange(day, excludedDays).then(() => this.onDaysChange());
  }

  cantSelectDay(day, isDisabled) {
    const { selectedDays, duration } = this.props;
    const selectedIndex = getDayIndex(selectedDays, day);

    // check if is disabled
    // OR count of selected days is equal/higher from duration number
    // AND clicked day is not already selected
    return isDisabled || (selectedDays.length >= duration && selectedIndex < 0);
  }

  handleDayClick(day, modifiers) {
    const { toggleDay, calendarClicksCounter, excludedDays } = this.props;

    if (this.cantSelectDay(day, modifiers.disabled)) {
      return;
    }

    if (calendarClicksCounter === 0) {
      return this.selectDaysRange(day);
    }

    toggleDay(day, modifiers, excludedDays).then(() => {
      this.onDaysChange();
      this.disableNextTab();
    });
  }

  render() {
    const {
      selectedDays,
      daysConfig,
      excludedDays = [],
      disabledWeekDays,
      excludedDaysFunction,
      i18n: { language },
      name,
    } = this.props;

    return (
      <Fragment>
        <DayPicker
          ref={el => (this.el = el)}
          selectedDays={selectedDays}
          disabledDays={[
            day => excludedDaysFunction(day),
            ...getDisabledDays({
              daysConfig,
              customDays: { before: addDays(TODAY, 1) },
            }),
            ...excludedDays,
          ]}
          onDayClick={this.handleDayClick}
          month={getInitialMonth(excludedDays, disabledWeekDays)}
          firstDayOfWeek={1}
          locale={mapLanguageToDatePickerLocale(language)}
          localeUtils={MomentLocalUtils}
        />
        <OnChange name={name}>
          {() => {
            const { forms, currentFormId, paymentMode } = this.props;
            const currentForm = forms[currentFormId];
            const dietLength = paymentMode === '1' ? 1 : currentForm.dietLength;

            this.props.setNextTabDisability(
              currentForm.deliveryDays.length !== dietLength
            );
          }}
        </OnChange>
      </Fragment>
    );
  }
}

export default compose(
  withTranslation('', { withRef: true }),
  connect(
    ({
      calendar: {
        selectedDays,
        duration,
        calendarClicksCounter,
        includeSaturdays,
        includeSundays,
      },
      dietForm: { forms, currentFormId, paymentMode },
      orderFormTabs: { isNextDisabled },
      app: {
        brand: {
          monday,
          tuesday,
          wednesday,
          thursday,
          friday,
          saturday,
          sunday,
        },
      },
    }) => {
      return {
        selectedDays,
        duration,
        calendarClicksCounter,
        includeSaturdays,
        includeSundays,
        isNextDisabled,
        forms,
        currentFormId,
        daysConfig: {
          monday,
          tuesday,
          wednesday,
          thursday,
          friday,
          saturday: includeSaturdays ? saturday : 0,
          sunday: includeSundays ? sunday : 0,
        },
        disabledWeekDays: {
          1: monday,
          2: tuesday,
          3: wednesday,
          4: thursday,
          5: friday,
          6: saturday,
          7: sunday,
        },
        paymentMode,
      };
    },
    {
      toggleDay,
      setDaysRange,
      incrementCalendarClicks,
      unselectSaturdays,
      unselectSundays,
      clearSelectedDays,
      setSelectedDays,
      setNextTabDisability,
    },
    null,
    { forwardRef: true }
  )
)(Calendar);
