import { MONTH_NAMES_TRANSFORMED } from 'common/shared/months';
import { startOfISOWeek, endOfISOWeek, addDays, subDays } from 'date-fns';
import moment from 'moment';

// 2019-06-15T10:00:00.000Z => 15 czerwca 2019
export const dateISOStringToPolishTransformed = date => {
  const dateObj = new Date(date);

  const language = moment.locale();
  return `${dateObj.getDate()} ${
    language === 'pl'
      ? MONTH_NAMES_TRANSFORMED[dateObj.getMonth()]
      : moment.months()[dateObj.getMonth()]
  } ${dateObj.getFullYear()}`;
};

// 2019-06-15T10:00:00.000Z => 10:00
export const dateISOStringToTime = date => {
  const dateObj = new Date(date);

  return `${dateObj.getHours()}:${
    (dateObj.getMinutes() < 10 ? '0' : '') + dateObj.getMinutes()
  }`;
};

// 2019-06-15T10:00:00.000Z => Sobota | Sb
export const dateISOStringToWeekday = (date, short = false) => {
  const dateObj = new Date(date);
  const weekdays = short ? moment.weekdaysShort() : moment.weekdays();

  return `${weekdays[dateObj.getDay()]}`;
};

// Wed Jun 12 2019 10:02:10 GMT+0200 (Central European Summer Time) => Czerwiec 2019
export const dateObjectToPolishMonthYear = date => {
  return `${moment.months()[date.getMonth()]} ${date.getFullYear()}`;
};

// Wed Jun 12 2019 10:02:10 GMT+0200 (Central European Summer Time) => Środa, 12 czerwca 2019
export const dateObjectToPolishMonthYearWithWeekday = (
  date,
  modifier = false
) => {
  let dateObj = new Date(date);

  if (modifier) {
    dateObj = subDays(dateObj, 1);
  }

  return `${
    moment.weekdays()[dateObj.getDay()]
  }, ${dateISOStringToPolishTransformed(dateObj)}`;
};

// Wed Jun 12 2019 10:02:10 GMT+0200 (Central European Summer Time) => 12.06.2019
export const dateObjectToDateTime = date => {
  const dateObj = new Date(date);
  const month = dateObj.getMonth() + 1;

  return `${dateObj.getDate()}.${
    month < 10 ? `0${month}` : month
  }.${dateObj.getFullYear()}`;
};

// Wed Jun 12 2019 10:02:10 GMT+0200 (Central European Summer Time) => 2019-06-12
export const dateObjectToYearMonthDay = date => {
  const dateObj = new Date(date);
  const month = dateObj.getMonth() + 1;
  const day = dateObj.getDate();

  return `${dateObj.getFullYear()}-${month < 10 ? `0${month}` : month}-${
    day < 10 ? `0${day}` : day
  }`;
};

export const getWeekFromDateArray = date => {
  let startOfWeekDate = startOfISOWeek(date);
  const endOfWeekDate = endOfISOWeek(date);

  let weekFromDayArray = [];

  while (startOfWeekDate <= endOfWeekDate) {
    weekFromDayArray.push(startOfWeekDate);
    startOfWeekDate = addDays(startOfWeekDate, 1);
  }

  return weekFromDayArray;
};
