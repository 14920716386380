import React from 'react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

import StyledSwitcherDayCellDesktop from 'styledComponents/elements/MenuPlanning/SwitcherDayCellDesktop';
import SwitcherIcon from './SwitcherIcon';

import { DAY_STATUSES } from 'common/components/BigCalendar/const/dayStatuses';

const getStatusTitle = dayStatus => {
  switch (dayStatus) {
    case DAY_STATUSES.DELIVERED_NOT_RATED_CAN_RATE:
    case DAY_STATUSES.DELIVERED_RATED_PARTIALLY_CAN_RATE:
      return '$*calendarDayCell.pleaseRate';
    case DAY_STATUSES.DELIVERED_RATED_BLOCKED:
    case DAY_STATUSES.DELIVERED_RATED_PARTIALLY_BLOCKED:
    case DAY_STATUSES.DELIVERED_RATED_CAN_RATE:
      return '$*calendarDayCell.rated';
    case DAY_STATUSES.DELIVERED_NOT_RATED_BLOCKED:
      return '$*calendarDayCell.delivered';
    case DAY_STATUSES.NOT_DELIVERED_WITH_CONFIGURABLE_ALL:
      return '$*calendarDayCell.editMenu';
    case DAY_STATUSES.NOT_DELIVERED_WITH_CONFIGURABLE_WITHOUT_MENU:
      return '$*calendarDayCell.menuSoon';
    case DAY_STATUSES.NOT_DIET_CAN_PLACE_ORDER:
      return '$*calendarDayCell.add';
    case DAY_STATUSES.DISABLED_WITH_BAG:
    case DAY_STATUSES.ERROR_ON_RENEW:
      return '$*common.caution';
    case DAY_STATUSES.SUBSCRIPTION_SUSPENDED:
      return '$*calendarDayCell.holded';
    case DAY_STATUSES.NOT_DELIVERED_BLOCKED: {
      return '$*calendarDayCell.details';
    }
  }
};

const SwitcherDayCellDesktop = ({
  date,
  selected,
  disabled,
  faded,
  configurable,
  handleDayChange,
  dayStatus,
  isNowBeforeIteratedDay,
  isDayToday,
  t,
}) => {
  const showIcon = [
    DAY_STATUSES.DELIVERED_NOT_RATED_CAN_RATE,
    DAY_STATUSES.DELIVERED_RATED_PARTIALLY_CAN_RATE,
    DAY_STATUSES.DELIVERED_RATED_CAN_RATE,
    DAY_STATUSES.NOT_DELIVERED_WITH_CONFIGURABLE_ALL,
    DAY_STATUSES.NOT_DELIVERED_WITH_CONFIGURABLE_WITHOUT_MENU,
    DAY_STATUSES.DELIVERED_RATED_BLOCKED,
    DAY_STATUSES.DELIVERED_RATED_PARTIALLY_BLOCKED,
    DAY_STATUSES.DELIVERED_NOT_RATED_BLOCKED,
  ].includes(dayStatus);

  const canAddDay = dayStatus === DAY_STATUSES.NOT_DIET_CAN_PLACE_ORDER;

  const dayCellTitle = getStatusTitle(dayStatus, isNowBeforeIteratedDay);
  return (
    <StyledSwitcherDayCellDesktop
      onClick={handleDayChange}
      disabled={disabled}
      canAddDay={canAddDay}
      faded={faded}
      selected={selected}
      configurable={configurable || showIcon}
      isDayToday={isDayToday}
    >
      <SwitcherIcon
        dayStatus={dayStatus}
        isNowBeforeIteratedDay={isNowBeforeIteratedDay}
      />

      <StyledSwitcherDayCellDesktop.DayNumber>
        {date.getDate()}
      </StyledSwitcherDayCellDesktop.DayNumber>
      <StyledSwitcherDayCellDesktop.WeekdayText>
        {moment.weekdays()[date.getDay()]}
      </StyledSwitcherDayCellDesktop.WeekdayText>
      <StyledSwitcherDayCellDesktop.MonthText>
        {t(dayCellTitle)}
      </StyledSwitcherDayCellDesktop.MonthText>
    </StyledSwitcherDayCellDesktop>
  );
};

export default withTranslation()(SwitcherDayCellDesktop);
