import { getDayCellComponent } from '../BigCalendar/services/calendarService';
import { isToday, format } from 'date-fns';
import { connect } from 'react-redux';

const SingleDay = ({ day, status, disabledDays, isSubscription, days }) => {
  const legacyStatus = days?.[format(day, 'YYYY-MM-DD')]?.newState; // not changed by wstrzymaj/wznów
  return getDayCellComponent({ day, status, disabledDays })({
    children: day.getDate(),
    isIteratedDayToday: isToday(day),
    legacyStatus,
  });
};
export default connect(
  ({
    userDiet: {
      selectedDietObject: { isSubscription },
      calendar: { days },
    },
  }) => ({ isSubscription, days })
)(SingleDay);
