import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import Spacer from 'styledComponents/elements/Spacer';

import StyledSingleMealRating from 'styledComponents/elements/MenuPlanning/SingleMealRating';
import HorizontalLine from 'styledComponents/elements/HorizontalLine';

import FormTextInput from 'common/components/Form/FormTextInput';

import StarRating from 'views/MenuPlanning/components/StarRating';
import { uniq } from 'ramda';
import { Box, Flex } from '@rebass/grid';
import Tooltip from 'common/components/Tooltip';
import { Styled } from './SingleMealChooseSection/SingleMealChooseSection.styled';
import { useTranslation } from 'react-i18next';

const SingleMealRating = ({
  meal: { mealType },
  meal,
  disabled,
  ratingId,
  commentId,
  hiddenRating,
  hiddenComment,
  disabledComment,
  dishFromOptions,
  showInformation,
}) => {
  const { t } = useTranslation();

  const showDishDetails = ({
    id,
    calories,
    fat,
    name,
    tags,
    protein,
    ingredients,
    carbohydrates,
    rateAVG = null,
    servingSuggestion = {},
    glycemicIndex,
    image,
  }) => {
    const hasImage = showInformation?.dishImage ?? false;
    const names = ingredients.map(({ name }) => name);
    const allergens = ingredients.reduce(
      (acc, { allergens }) => [...acc, ...allergens],
      []
    );

    const formattedRating = rateAVG !== null ? rateAVG.toFixed(2) : '-';

    return (
      <Styled.DishBoxWrapper key={id}>
        <StyledSingleMealRating.DishName>
          {name}
        </StyledSingleMealRating.DishName>
        <Spacer height={'8px'} />

        <Styled.DishBox hasImage={hasImage}>
          {hasImage && <Styled.DishImage imageUrl={image?.contentUrl} />}
          <Styled.DishContent>
            <Box fontSize={'12px'}>
              {showInformation.tags && tags.length > 0 && (
                <Box>{tags.map(tag => `#${tag.value}`).join(' ')}</Box>
              )}
              {showInformation.macroIngredients && (
                <Box>
                  {`${t('$*ingredientsShort.protein', 'B')}: ${
                    protein ? protein.toFixed(2) : 0
                  } g | ${t('$*ingredientsShort.carbohydrates', 'W')}: ${
                    carbohydrates ? carbohydrates.toFixed(2) : 0
                  } g | ${t('$*ingredientsShort.fats', 'T')}: ${
                    fat ? fat.toFixed(2) : 0
                  } g`}
                </Box>
              )}
              {showInformation.dishCalorific && (
                <Box>
                  {t('$*singleMealChooseSection.calorific', 'Kaloryczność')}:{' '}
                  {calories?.toFixed(0) || '-'}{' '}
                  {t('$*singleMealChooseSection.calorificShort', '$$kcal')}
                </Box>
              )}
              {showInformation?.glycemicIndex?.show && (
                <Flex alignItems="center">
                  {t(
                    '$*singleMealChooseSection.glycemicIndex',
                    'Indeks glikemiczny'
                  )}
                  : {glycemicIndex}
                  {showInformation?.glycemicIndex?.tooltip?.length > 0 && (
                    <i
                      className="fas fa-exclamation-circle"
                      data-for="glycemicIndexTooltip"
                      data-tip="glycemicIndexTooltip"
                      style={{
                        cursor: 'pointer',
                        marginLeft: '10px',
                        paddingRight: '0',
                      }}
                    />
                  )}
                </Flex>
              )}
              {showInformation.avgDishRating && (
                <Box>
                  {t('$*singleMealChooseSection.rating', 'Ocena')}:{' '}
                  {formattedRating}
                </Box>
              )}
              {showInformation.servingProposal && (
                <Box>
                  {t(
                    '$*singleMealChooseSection.servingProposal',
                    'Propozycja podania'
                  )}
                  : {servingSuggestion?.value || '-'}
                </Box>
              )}
              {showInformation.allergens && allergens.length > 0 && (
                <Box>
                  {t('$*singleMealChooseSection.alergens', 'Alergeny')}:{' '}
                  {uniq(allergens)
                    .map(allergen => allergen.value)
                    .join(', ')}
                </Box>
              )}
              {showInformation.dishComposition && names.length > 0 && (
                <Box>
                  {t('$*singleMealChooseSection.composition', 'Skład dania')}:{' '}
                  {uniq(names).join(', ')}
                </Box>
              )}
            </Box>

            {showInformation?.glycemicIndex?.tooltip?.length > 0 && (
              <Tooltip id="glycemicIndexTooltip" hide={false} maxWidth="350px">
                <div
                  dangerouslySetInnerHTML={{
                    __html: showInformation?.glycemicIndex?.tooltip,
                  }}
                />
              </Tooltip>
            )}
          </Styled.DishContent>
        </Styled.DishBox>
      </Styled.DishBoxWrapper>
    );
  };

  return (
    <StyledSingleMealRating>
      <Flex justifyContent="space-between">
        <StyledSingleMealRating.MealType>
          {mealType}
        </StyledSingleMealRating.MealType>
        {!hiddenRating && (
          <Field name={ratingId} component={StarRating} disabled={disabled} />
        )}
      </Flex>
      {dishFromOptions && (
        <StyledSingleMealRating.Details>
          {showDishDetails(dishFromOptions)}
        </StyledSingleMealRating.Details>
      )}
      {!hiddenComment && (
        <div>
          <Field
            name={commentId}
            type="text"
            component={FormTextInput}
            placeholder={
              disabled
                ? ''
                : t(
                    '$*menuPlanning.singleMealRating.optionalComment',
                    '$$Komentarz (opcjonalnie)'
                  )
            }
            disabled={disabled || disabledComment}
          />
        </div>
      )}
      <HorizontalLine
        marginLeft="-30px"
        marginRight="-30px"
        marginTop={hiddenComment && hiddenRating && '20px'}
      />
    </StyledSingleMealRating>
  );
};

export default connect(
  ({
    app: {
      brand: { showInformation },
    },
  }) => ({
    showInformation,
  }),
  null
)(SingleMealRating);
