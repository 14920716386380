import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Flex, Box } from '@rebass/grid';
import { isAfter } from 'date-fns';

import { DAY_STATUSES } from 'common/components/BigCalendar/const/dayStatuses';

import MenuRatingSection from './MenuRatingSection';
import MealConfigurationSection from './MealConfigurationSection';
import MenuDetailsSection from './MenuDetailsSection';

const MenuPlanningRightColumn = ({
  isPremium,
  bag,
  meals,
  handleMenuPlanningContentFormModal,
  onBeforeBagSave,
  setDietsInWhichMealExists,
  isSubscriptionSuspended,
  getButtonAction,
  calendar,
  selectedDay,
}) => {
  const [isEditable, setIsEditable] = useState(
    calendar.days[selectedDay]?.newState !==
      DAY_STATUSES.SUBSCRIPTION_SUSPENDED &&
      isAfter(bag.changeDietDeadLine, new Date()) //@todo changeInFuture
  );

  useEffect(() => {
    const interval = setInterval(() => {
      calendar.days[selectedDay]?.newState !==
        DAY_STATUSES.SUBSCRIPTION_SUSPENDED &&
        isAfter(bag.changeDietDeadLine, new Date());
      setIsEditable(isEditable);
    }, 30000); // 30s

    return () => clearInterval(interval);
  }, []);

  return (
    <Flex>
      <Box pt={[0, 0]} px={[0, 40]} pb={[20, 60]} width={1}>
        {[
          DAY_STATUSES.RATE_MENU, //delete
          DAY_STATUSES.RATED_MENU, //delete
          DAY_STATUSES.TODAY, //delete
          DAY_STATUSES.DELIVERED_NOT_RATED_CAN_RATE,
          DAY_STATUSES.DELIVERED_RATED_PARTIALLY_CAN_RATE,
          DAY_STATUSES.DELIVERED_RATED_CAN_RATE,
        ].includes(bag.state) && <MenuRatingSection bag={bag} meals={meals} />}
        {[
          DAY_STATUSES.CONFIGURABLE, //delete
          DAY_STATUSES.DETAILS_SHOW_NEXT, //delete ??
          DAY_STATUSES.SUBSCRIPTION_CONFIGURABLE, //delete
          DAY_STATUSES.NOT_DELIVERED_WITH_CONFIGURABLE_ALL,
          DAY_STATUSES.NOT_DELIVERED_WITH_CONFIGURABLE_WITHOUT_MENU,
          DAY_STATUSES.SUBSCRIPTION_DISABLED,
        ].includes(bag.state) && (
          <MealConfigurationSection
            getButtonAction={getButtonAction}
            meals={meals}
            isPremium={isPremium}
            isEditable={isEditable}
            bag={bag}
            isSubscriptionSuspended={isSubscriptionSuspended}
            handleMenuPlanningContentFormModal={
              handleMenuPlanningContentFormModal
            }
            setDietsInWhichMealExists={setDietsInWhichMealExists}
            onBeforeBagSave={onBeforeBagSave}
          />
        )}
        {[
          DAY_STATUSES.DETAILS_SHOW_PREV, //delete
          DAY_STATUSES.DELIVERED_RATED_BLOCKED,
          DAY_STATUSES.DELIVERED_RATED_PARTIALLY_BLOCKED,
          DAY_STATUSES.DELIVERED_RATED_CAN_RATE,
          DAY_STATUSES.DELIVERED_RATED_PARTIALLY_CAN_RATE,
          DAY_STATUSES.DELIVERED_NOT_RATED_BLOCKED,
          DAY_STATUSES.DELIVERED_NOT_RATED_CAN_RATE,
        ].includes(bag.state) && <MenuDetailsSection bag={bag} />}
      </Box>
    </Flex>
  );
};

export default connect(
  ({ menuPlanning: { meals, calendar, selectedDay } }) => ({
    meals,
    calendar,
    selectedDay,
  })
)(MenuPlanningRightColumn);
