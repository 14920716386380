import React, { useState, useEffect } from 'react';
import showToast from 'utils/toast';
import NOTIFICATIONS from 'common/shared/notifications';

import StyledStarRating from 'styledComponents/elements/MenuPlanning/StarRating';
import { useTranslation } from 'react-i18next';

const starRange = [...Array(6).keys()].slice(1, 6);

const StarRating = ({ input, name, disabled }) => {
  const [hoverRating, setHoverRating] = useState(input.value);
  const { t } = useTranslation();

  useEffect(() => {
    setHoverRating(input.value);
  }, [input.value]);

  return (
    <StyledStarRating disabled={disabled}>
      <input type="hidden" name={name} {...input} value={input.value} />

      {starRange.map(star => {
        const filledStar =
          !input.value || star > input.value || star <= input.value
            ? star <= hoverRating
            : star <= input.value;

        return (
          <i
            className={`fas fa-star ${filledStar ? 'fill' : 'empty'}`}
            key={star}
            onClick={() =>
              disabled
                ? showToast({
                    message: NOTIFICATIONS(t).RATE_TIME_OUT,
                    type: 'error',
                  })
                : input.onChange(star)
            }
            onMouseOver={() => !disabled && setHoverRating(star)}
            onMouseLeave={() => !disabled && setHoverRating(input.value)}
          />
        );
      })}
    </StyledStarRating>
  );
};

export default StarRating;
