import React from 'react';
import styled from 'styled-components';

import {
  BaseWrapper,
  BaseDayNumber,
} from 'styledComponents/elements/BigCalendarDayCell';

const Wrapper = styled(BaseWrapper)`
  background: transparent;
  border: none;
`;

const DayNumber = styled(BaseDayNumber)`
  margin-bottom: auto;
`;

const BasicDayCell = ({ children, isIteratedDayToday }) => {
  return (
    <Wrapper isIteratedDayToday={isIteratedDayToday}>
      <DayNumber>{children}</DayNumber>
    </Wrapper>
  );
};

export default BasicDayCell;
