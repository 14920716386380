const INPUT_IDS = {
  STREET: 'street',
  HOUSE_NUMBER: 'buildNumber',
  HOUSE_UNIT_NUMBER: 'placeNumber',
  POSTAL_CODE: 'postCode',
  CITY: 'city',
};

export const getNullableValue = value => value || '';

export const buildMapQuery = values => {
  if (values) {
    return encodeURIComponent(
      `${getNullableValue(values[INPUT_IDS.STREET])}
      ${getNullableValue(values[INPUT_IDS.HOUSE_NUMBER])}/ 
      ${
        values.postCode && values.postCode.length === 6
          ? getNullableValue(values[INPUT_IDS.POSTAL_CODE])
          : ''
      } 
      ${getNullableValue(values[INPUT_IDS.CITY])}`
    );
  } else {
    return '';
  }
};
