import React from 'react';
import { Switch, Redirect, useLocation } from 'react-router-dom';

import URLS from 'routes/routeUrls';
import routes, { useLocalUrl } from 'routes';
import RouteWrapper from 'routes/routeWrapper';
import PageFromCreator from 'common/components/PageFromCreator';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const AppRouter = ({ showMenuPage, pages }) => {
  const routesFromCreator = pages;
  const {
    i18n: {
      options: { resources },
    },
  } = useTranslation();
  const location = useLocation();
  const { toLocalUrl } = useLocalUrl();

  const availableLanguages = Object.keys(resources);

  return (
    <Switch>
      <Redirect
        exact
        from={toLocalUrl(URLS.HOME)}
        to={toLocalUrl(URLS.NEW_ORDER_FORM)}
      />
      <Redirect exact from={'/menu'} to={toLocalUrl('/menu/1/1')} />
      {routes
        .filter(route =>
          showMenuPage ? true : route.hideOn !== 'showMenuPage'
        )
        .map(({ path, exact = true, ...rest }) => {
          const _path = `/:lang/${path.substring(path.search(/[^/]/))}`;

          return (
            <RouteWrapper key={_path} path={_path} exact={exact} {...rest} />
          );
        })}
      {routesFromCreator &&
        routesFromCreator.map(({ slug, exact = true, id, ...rest }) => {
          const Page = () => <PageFromCreator id={id} />;

          const pageSlug = `/:lang/pages/${slug}`;

          return (
            <RouteWrapper
              key={pageSlug}
              component={Page}
              path={pageSlug}
              exact={exact}
              responsive
              hasNarrowContainer
              {...rest}
            />
          );
        })}
      <Redirect
        from={`/(${availableLanguages.join('|')})`}
        to={toLocalUrl(URLS.HOME)}
      />
      <Redirect
        to={toLocalUrl(
          `${location.pathname}${location.search}${location.hash}`
        )}
      />
    </Switch>
  );
};

export default connect(
  ({
    orderForm: {
      orderConfig: { pages },
    },
  }) => ({ pages }),
  null
)(AppRouter);
