import styled from 'styled-components';
import { Tab, TabList, TabPanel } from 'react-tabs';

import { fromPxToRem, media } from 'styledComponents/helpers';

export const StyledTabList = styled(TabList)`
  display: flex;
  justify-content: center;
  ${props =>
    !props.$useImageAsBackground &&
    `border-bottom: 1px solid ${props.theme.colorIceBlue2};`};
  ${props => props.$useImageAsBackground && `background: white;`};

  ${media.tabletDown`
    display: none;
  `};
`;

export const StyledTab = styled(Tab)`
  position: relative;
  top: 2px;
  display: ${props => (props.hidden ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;
  padding: 0 15px;
  border-bottom: 3px solid transparent;
  text-transform: uppercase;
  cursor: pointer;

  &.react-tabs__tab--selected {
    color: ${props => props.theme.baseFontColor};
    border-bottom: 3px solid ${props => props.theme.baseFontColor};
  }

  &.react-tabs__tab--disabled:not(.react-tabs__tab--selected) {
    color: ${props => props.theme.colorStarGrey};
    cursor: default;
  }
`;

export const StyledSingleTab = styled.div`
  display: none;

  ${media.tabletDown`
    display: flex;
    align-items: center;
    color: ${props => props.theme.baseFontColor};
    text-transform: uppercase;
    position: relative;

    &:after{
      background: linear-gradient(to right, ${props =>
        props.theme.baseFontColor} ${props => props.progress}, ${props =>
    props.theme.colorGrey} ${props => props.progress});
      position: absolute;
      margin-left: ${props => (props.currentStep / props.tabsLength) * 100};
      content: '';
      height: 3px;
      right: 0;
      left: 0;
      bottom: 0;
    }
  `};
`;

export const SingleTabNumber = styled.span`
  font-size: ${fromPxToRem('30px')};
`;

export const SingleTabLabel = styled.span`
  margin-left: 10px;
  font-size: ${fromPxToRem('15px')};
`;

export const StyledTabPanel = styled(TabPanel)`
  display: none;
  margin-top: 50px;

  &.react-tabs__tab-panel--selected {
    display: block;
  }

  ${media.tabletDown`
    margin-top: 32px;
  `};
`;

export const StyledTabCounter = styled.span`
  font-size: ${fromPxToRem('30px')};
  padding-right: 18px;
`;

export const StyledTabLabel = styled.span`
  font-size: ${fromPxToRem('13px')};
  line-height: ${fromPxToRem('16px')};
`;
