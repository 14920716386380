import produce from 'immer';

import {
  ADD_BAG,
  CHANGE_QUANTITY_ADDON,
  REMOVE_ADDON_FROM_BAG,
  REMOVE_BAG,
} from './const';

const INITIAL_STATE = {
  bags: [],
};

const basketReducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case ADD_BAG: {
        const { bag } = payload;
        const currentBagIndex = draft.bags.findIndex(
          itemBag => itemBag.bag === bag.bag
        );

        if (currentBagIndex !== -1) {
          draft.bags[currentBagIndex] = bag;

          if (bag.addons.length === 0) {
            draft.bags.splice(currentBagIndex, 1);
          }
        } else {
          draft.bags.push(bag);
        }

        break;
      }

      case CHANGE_QUANTITY_ADDON: {
        const { bagIriId, addonIriId, quantity } = payload;
        const currentAddons = draft.bags.find(({ bag }) => bag === bagIriId)
          .addons;
        const currentAddon = currentAddons.find(
          ({ addon }) => addon === addonIriId
        );

        currentAddon.quantity = quantity;

        break;
      }

      case REMOVE_ADDON_FROM_BAG: {
        const { bagIriId, addonIriId } = payload;
        const currentAddons = draft.bags.find(({ bag }) => bag === bagIriId)
          .addons;
        const currentAddonIndex = currentAddons.findIndex(
          ({ addon }) => addon === addonIriId
        );

        currentAddons.splice(currentAddonIndex, 1);

        break;
      }

      case REMOVE_BAG: {
        const { bagIriId } = payload;

        const currentBagIndex = draft.bags.findIndex(
          ({ bag }) => bag === bagIriId
        );

        draft.bags.splice(currentBagIndex, 1);

        break;
      }

      default:
        return draft;
    }
  });

export default basketReducer;
