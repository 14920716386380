import styled from 'styled-components';
import { Flex } from '@rebass/grid';

import { fromPxToRem } from 'styledComponents/helpers';

const MenuEditingPeriodInfo = styled(Flex)`
  display: flex;
  align-items: center;

  margin-left: 5px;
  //border-bottom: 1px solid ${props => props.theme.colorIceBlue2};
`;

MenuEditingPeriodInfo.IconWrapper = styled.div`
  min-width: 20px;
  height: 20px;
  color: ${props => props.theme.baseFontColor};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${fromPxToRem('20px')};
`;

MenuEditingPeriodInfo.InfoText = styled.p`
  margin: 0;
  padding: 0;
  //color: ${props => props.theme.baseFontColor};
  font-size: ${fromPxToRem('11px')};
`;

export default MenuEditingPeriodInfo;
