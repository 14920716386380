import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Flex, Box } from '@rebass/grid';
import styled from 'styled-components';

import { fromPxToRem } from 'styledComponents/helpers';

import { setUseMoneyBox, getOrderCost } from '../../actions/orderFormActions';

import { SUMMARY_ROW_LEFT_WIDTH, SUMMARY_ROW_RIGHT_WIDTH } from './const';

import { getUserData } from 'views/AccountSettings/actions/accountSettingsActions';
import { useTranslation } from 'react-i18next';

const StyledMoneyBoxLabel = styled.span`
  font-size: ${fromPxToRem('13px')};
`;

const StyledMoneyBoxPoints = styled.span`
  font-size: ${fromPxToRem('14px')};
  font-weight: ${props => props.theme.fontWeight900};
`;

const SummaryMoneyBox = ({
  token,
  shortName,
  pointsToCashRate,
  pluralNameGenitive,
  pluralNameDenominator,
  afterPaymentWillReturnPoints,
  multinational,
}) => {
  const { t } = useTranslation();
  const { currencySymbol } = multinational;
  return (
    <Flex alignItems="center" justifyContent="space-between" flexWrap={'wrap'}>
      {token ? (
        <Fragment>
          <Box width={[1, 1, SUMMARY_ROW_LEFT_WIDTH]}>
            <Flex alignItems="center">
              <Box pr={30}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('$*summaryMoneyBoxReturn.caption', {
                      defaultValue: `$$W poprzednich zamówieniach, na ten sam adres i daty, posiadasz
                  już inne dostawy.
                  {{- br}}
                  $$Wiąże się to z większym rabatem wspólnej dostawy, który
                  otrzymasz do skarbonki!`,
                      replace: {
                        br: '<br />',
                      },
                    }),
                  }}
                />
              </Box>
            </Flex>
          </Box>

          <Box width={[1, 1, SUMMARY_ROW_RIGHT_WIDTH]}>
            <Flex
              mt={[25, 25, 0]}
              alignItems="center"
              justifyContent={['space-between']}
            >
              <Box>
                <StyledMoneyBoxLabel>
                  {t('$*summaryMoneyBoxReturn.label', {
                    defaultValue: `$$Zwrot {{- points}}`,
                    replace: {
                      points:
                        pluralNameGenitive ||
                        t('$*common.pointsLiteralGerundium', '$$punktów'),
                    },
                  })}
                  :
                </StyledMoneyBoxLabel>
              </Box>
              <Box>
                <StyledMoneyBoxPoints>
                  {afterPaymentWillReturnPoints}{' '}
                  {shortName || t('$*common.pointAbbreviation', '$$pkt')}/{' '}
                  {(afterPaymentWillReturnPoints * pointsToCashRate).toFixed(2)}{' '}
                  {currencySymbol}
                </StyledMoneyBoxPoints>
              </Box>
            </Flex>
          </Box>
        </Fragment>
      ) : (
        <span>
          {t('$*summaryMoneyBoxReturn.logInToUseDiscountPoints', {
            defaultValue: `Zaloguj się, aby dodać {{- points}} ze skarbonki`,
            replace: {
              points:
                pluralNameDenominator ||
                t('$*common.pointsLiteral', 'Punkty'),
            },
          })}
        </span>
      )}
    </Flex>
  );
};

export default connect(
  ({
    auth: { token },
    app: {
      brand: {
        moneyBoxConfigurationNames: {
          shortName,
          pluralNameDenominator,
          pluralNameGenitive,
        },
      },
      config: { multinational },
    },
    accountSettings: {
      userData: { moneyBox },
    },
    orderForm: {
      orderConfig: { pointsToCashRate },
      useMoneyBox,
    },
  }) => ({
    token,
    moneyBox,
    shortName,
    useMoneyBox,
    pointsToCashRate,
    pluralNameGenitive,
    pluralNameDenominator,
    multinational,
  }),
  { setUseMoneyBox, getOrderCost, getUserData }
)(SummaryMoneyBox);
