import React from 'react';
import { connect } from 'react-redux';
import { BASE_URL, URLS } from 'configuration';
import order from 'assets/svg/calendar/order.svg';

const AddIcon = ({ ConfigClientPanel }) => {
  const hasAddIcon =
    ConfigClientPanel.calendarIconSubscriptionAddDelivery !== null;

  const { showCalendarIcons } = ConfigClientPanel;

  const src =
    hasAddIcon &&
    `${BASE_URL}${URLS.UPLOADED_MEDIA}${
      ConfigClientPanel['@resources'][
        ConfigClientPanel.calendarIconSubscriptionAddDelivery
      ]?.contentUrl
    }`;

  if (!hasAddIcon || !showCalendarIcons) return null;
  return <img className="calendar-icon" width="100%" src={src} />;
};

export default connect(
  ({
    app: {
      config: {
        modules: { ConfigClientPanel },
      },
    },
  }) => ({ ConfigClientPanel }),
  null
)(AddIcon);
