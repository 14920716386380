import styled from 'styled-components';

export default styled.div`
  height: ${props => props.height || '1px'};
  background-color: ${props => props.color || props.theme.colorIceBlue2};
  margin-bottom: ${props => props.marginBottom || '12px'};
  margin-top: ${props => props.marginTop || 0};
  margin-left: ${props => props.marginLeft || 0};
  margin-right: ${props => props.marginRight || 0};
`;
