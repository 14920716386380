import React from 'react';
import { connect } from 'react-redux';
import { Flex, Box } from '@rebass/grid';
import styled from 'styled-components';

import { Field } from 'react-final-form';

import { fromPxToRem, media } from 'styledComponents/helpers';
import StyledCalendar from 'styledComponents/elements/Calendar';
import { Paragraph } from 'styledComponents/elements/Typography';
import { ButtonLink } from 'styledComponents/elements/Button';

import Calendar from 'common/components/Calendar';

import FORM_FIELD_NAMES from '../const/formFieldNames';
import { CONFIGURATION_FIELDS } from 'views/NewOrder/const/formConfig/configurationFields';
import { uniq } from 'ramda';
import { format, getISODay } from 'date-fns';
import { WEEKDAYS_INDEXES } from 'common/shared/weekdays';
import { getWeekFromDateArray } from 'common/services/dateHelpers';
import { useTranslation } from 'react-i18next';

const StyledDaysCounter = styled.span`
  ${media.tabletDown`
    text-align: center;
  `};

  display: inline-block;
  margin-top: 20px;
  margin-right: 25px;
  font-size: ${fromPxToRem('28px')};
  font-weight: ${props => props.theme.fontWeight900};
`;

const StyledDaysCounterContainer = styled.div`
  ${media.tabletDown`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `};
`;

const StyledDaysCounterLabel = styled.span`
  display: block;
  text-transform: uppercase;
  font-size: ${fromPxToRem('13px')};
`;

const blocksFromConfig = arr => {
  let b = [],
    prev;

  arr.sort();
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] !== prev) {
      b.push(1);
    } else {
      b[b.length - 1]++;
    }
    prev = arr[i];
  }

  return uniq(b);
};

const currentlyAvailableBlocks = (blocks, currentDaysAmount, depth = 0) => {
  if (currentDaysAmount === 0) {
    return [];
  }
  const blocksSum = blocks.reduce((a, b) => a + b, 0);
  const result = [];

  if (currentDaysAmount > 13) {
    return blocks;
  }

  if (currentDaysAmount % blocksSum === 0) {
    return blocks;
  }

  const currentDaysModuloSumPlusSum =
    (currentDaysAmount % blocksSum) + blocksSum;
  if (currentDaysAmount > blocksSum) {
    blocks.forEach((block, index) => {
      if (
        currentDaysModuloSumPlusSum % block === 0 ||
        currentDaysAmount % block === 0
      ) {
        result.push(block);

        if (depth < 5) {
          blocks.forEach(testowany_blok => {
            if (testowany_blok !== block) {
              result.push(
                ...currentlyAvailableBlocks(
                  blocks,
                  currentDaysAmount - block,
                  ++depth
                )
              );
            }
          });
        }
      }
    });
  } else {
    blocks.forEach((block, index) => {
      if (currentDaysAmount % block === 0) {
        result.push(block);
      }
    });
  }
  blocks.forEach((block, index) => {
    blocks.forEach(blockToTest => {
      if (blockToTest !== block) {
        let lastTestedLength;
        let multiplier = 1;
        do {
          const lastTestedLength = currentDaysAmount - multiplier * blockToTest;

          if (lastTestedLength > 0 && lastTestedLength % block === 0) {
            result.push(block);
            result.push(blockToTest);
          }
          multiplier++;
        } while (lastTestedLength > 0);
      }
    });
  });

  return uniq(result);
};

const OrderFormCalendar = ({
  input,
  form,
  disabledDaysForOrder,
  selectedDays,
  duration,
  saturday,
  sunday,
  calendarText,
  daysConfig,
  paymentMode,
}) => {
  const calendarRef = React.createRef();

  const onChange = value => {
    // form.mutators.setFieldValue(FORM_FIELD_NAMES.DELIVERY_DAYS, value);
    try {
      form.mutators.forceFocus(FORM_FIELD_NAMES.DELIVERY_DAYS, value);
      input.onChange(value);
      form.mutators.forceBlur(FORM_FIELD_NAMES.DELIVERY_DAYS);
    } catch {}
  };

  const { t } = useTranslation();

  const valuesFromConfig = Object.values(daysConfig).map(value => value);
  const blocks = blocksFromConfig(valuesFromConfig);

  const createDayBlock = day => {
    const clickedIsoDay = getISODay(day);

    const thisWeek = getWeekFromDateArray(day);

    return thisWeek.filter(date => {
      const isoCurrentDay = getISODay(date);
      const clickedWeekdayName = WEEKDAYS_INDEXES[clickedIsoDay];
      const clickedDayConfigValue = daysConfig[clickedWeekdayName];
      const currentWeekdayName = WEEKDAYS_INDEXES[isoCurrentDay];

      return daysConfig[currentWeekdayName] === clickedDayConfigValue;
    });
  };

  const formattedSelectedDays = selectedDays.map(day =>
    format(day, 'YYYY-MM-DD')
  );

  return (
    <Flex flexDirection={['column', 'column', 'row']}>
      <Box width={[1, 1, 8 / 12]}>
        <ButtonLink
          type="button"
          isRightAligned
          disabled={selectedDays.length === 0}
          onClick={() => calendarRef.current.onClear()}
        >
          {t('$*orderFormCalendar.clearSelectionCaption', '$$Wyczyść wybór')}
        </ButtonLink>

        <StyledCalendar>
          <Calendar
            ref={calendarRef}
            value={input.value}
            name={input.name}
            excludedDays={disabledDaysForOrder.map(
              day => new Date(day.replace(/ /g, 'T'))
            )}
            excludedDaysFunction={day => {
              const dayBlock = createDayBlock(day);
              const isDaySelected = formattedSelectedDays.includes(
                format(day, 'YYYY-MM-DD')
              );

              if (paymentMode === '1') {
                return null;
              }

              return (
                !currentlyAvailableBlocks(
                  blocks,
                  duration - selectedDays.length
                ).includes(dayBlock.length) && !isDaySelected
              );
            }}
            onChange={onChange}
          />
        </StyledCalendar>
      </Box>

      <Box width={[1, 1, 4 / 12]} ml={[0, 0, 40]} mt={[0, 0, 0]}>
        {paymentMode === '2' && (
          <Paragraph
            customPadding={'20px 0'}
            isSmall
            dangerouslySetInnerHTML={{
              __html: calendarText,
            }}
          />
        )}

        {saturday === sunday && saturday !== 0 && sunday !== 0 ? (
          <Box mt={24} mb={24}>
            <Field {...CONFIGURATION_FIELDS[FORM_FIELD_NAMES.WITH_WEEKENDS]}>
              {t(
                '$*orderFormCalendar.includeWeekends',
                '$$Uwzględnij weekendy'
              )}
            </Field>
          </Box>
        ) : (
          <div>
            {saturday !== 0 && (
              <Box mt={24} mb={24}>
                <Field
                  {...CONFIGURATION_FIELDS[FORM_FIELD_NAMES.WITH_SATURDAYS]}
                >
                  {t(
                    '$*orderFormCalendar.includeSaturdays',
                    '$$Uwzględnij soboty'
                  )}
                </Field>
              </Box>
            )}
            {sunday !== 0 && (
              <Box mb={24}>
                <Field {...CONFIGURATION_FIELDS[FORM_FIELD_NAMES.WITH_SUNDAYS]}>
                  {t(
                    '$*orderFormCalendar.includeSundays',
                    '$$Uwzględnij niedziele'
                  )}
                </Field>
              </Box>
            )}
          </div>
        )}

        {paymentMode === '2' && (
          <StyledDaysCounterContainer>
            <StyledDaysCounter>
              {duration - selectedDays.length}
              <StyledDaysCounterLabel>
                {t('$*orderFormCalendar.daysLeft', '$$Pozostało')}
              </StyledDaysCounterLabel>
            </StyledDaysCounter>

            <StyledDaysCounter>
              {selectedDays.length}
              <StyledDaysCounterLabel>
                {t('$*orderFormCalendar.selectedDays', '$$Wybrano')}
              </StyledDaysCounterLabel>
            </StyledDaysCounter>
          </StyledDaysCounterContainer>
        )}
      </Box>
    </Flex>
  );
};

export default connect(
  ({
    app: {
      brand: { monday, tuesday, wednesday, thursday, friday, sunday, saturday },
    },
    orderForm: {
      orderConfig: { disabledDaysForOrder, calendarText },
    },
    dietForm: { paymentMode },
    calendar: { selectedDays, duration },
  }) => ({
    disabledDaysForOrder,
    selectedDays,
    duration,
    calendarText,
    sunday,
    saturday,
    daysConfig: {
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
    },
    paymentMode,
  }),
  null
)(OrderFormCalendar);
