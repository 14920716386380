import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import {
  getUserAddresses,
  deleteAddress,
} from './actions/userAddressesActions';

export default compose(
  connect(
    ({ auth: { token }, userAddresses: { list, isLoading } }) => ({
      token,
      addresses: list,
    }),
    { getUserAddresses, deleteAddress }
  ),
  lifecycle({
    componentDidMount() {
      const { token, getUserAddresses } = this.props;

      getUserAddresses(token);
    },
  }),
  Component => props => (props.isLoading ? null : <Component {...props} />)
);
