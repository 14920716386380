import React, { useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { Field, Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import { addDays } from 'date-fns';
import { Box, Flex } from '@rebass/grid';
import { ButtonLink } from 'styledComponents/elements/Button';

import Modal from 'common/components/Modal';
import ConfirmButtonWrapper from 'common/components/Modal/ConfirmButtonWrapper';
import { getDisabledDays } from 'common/services/calendarService';
import { TODAY } from 'common/shared/weekdays';

import DeliveryCalendar from '../DeliveryCalendar';
import ChangeToDatesCalendar from '../ChangeToDatesCalendar';
import {
  setAllDaysToChange,
  setDaysToChange,
} from 'views/MenuPlanning/actions/menuPlanningActions';
import { DisplayOnMobile } from 'styledComponents/helpers';
import Spacer from 'styledComponents/elements/Spacer';
import { Styled } from './DeliveryDateChangeModal.styled';
import { useTranslation } from 'react-i18next';

const DeliveryDateChangeModal = ({
  isOpened,
  toggleModal,
  id,
  onBeforeSave,
  daysConfig,
  initialDietDaysToChange,
  allowChangeMultipleDays,
  disabledDays,
  isLoading,
  modules,
}) => {
  const { t } = useTranslation();
  const onSubmit = form => {
    const data = {
      ...form,
      changedAttributes: ['date'],
    };
    onBeforeSave(data);
  };

  const oneDayAfterToday = addDays(new Date(TODAY), 1);
  const disabledDaysCombined = getDisabledDays({
    customDays: {
      before: oneDayAfterToday,
    },
    daysConfig,
  });

  const scrollToRef = (ref, destination) => {
    ref.current.modalRef.current.parentElement.scrollTo({
      left: 0,
      top: destination.current.offsetTop,
      behavior: 'smooth',
    });
  };

  const scrollRef = useRef(null);
  const scrollDestination = useRef(null);
  const executeScroll = scrollDestination =>
    scrollToRef(scrollRef, scrollDestination);

  return (
    <Modal
      ref={scrollRef}
      id={id}
      title={t('$*deliveryDateChangeModal.title', '$$Zmiana terminu dostawy')}
      isOpened={isOpened}
      toggleModal={() => {
        toggleModal();
      }}
      withBackButton
      contentPadding={'0px'}
      contentPaddingMobile={'0px'}
      paddingMobile={'0 0 90px 0'}
      widthDesktop={allowChangeMultipleDays ? '95%' : '500px'}
      fullscreen
    >
      {isOpened === id && (
        <Form
          onSubmit={onSubmit}
          initialValues={{
            applyForDates: initialDietDaysToChange,
            dates: [],
          }}
          render={({ values, handleSubmit, submitting, pristine }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Spacer height={'30px'} />
                <Flex
                  flexDirection={['column', 'column', 'column', 'row']}
                  justifyContent={'center'}
                >
                  {allowChangeMultipleDays && (
                    <Box textAlign="center">
                      {t(
                        '$*deliveryDateChangeModal.chooseSorceDays',
                        '$$Wybierz dni, które chcesz przenieść'
                      )}
                      <Field
                        name="applyForDates"
                        component={DeliveryCalendar}
                        customDisabledDays={disabledDaysCombined}
                        customMonthStart={oneDayAfterToday}
                        values={values}
                        isOpened={isOpened}
                      />
                    </Box>
                  )}
                  <DisplayOnMobile>
                    {allowChangeMultipleDays && (
                      <ButtonLink
                        uppercased
                        sizeMiddle
                        weightBold
                        customMargin={'15px 0 20px'}
                        onClick={ev => {
                          ev.preventDefault();
                          executeScroll(scrollDestination);
                        }}
                        fullWidthOnMobile
                      >
                        <div style={{ marginBottom: '10px' }}>
                          {t(
                            '$*deliveryDateChangeModal.nextCaption',
                            '$$Przejdź dalej'
                          )}
                        </div>
                        <i className="fas fa-arrow-down" />
                      </ButtonLink>
                    )}
                  </DisplayOnMobile>
                  <Box textAlign="center">
                    <div ref={scrollDestination} />
                    {t(
                      '$*deliveryDateChangeModal.chooseNewDays',
                      '$$Wybierz nowe terminy dostawy'
                    )}
                    <Field
                      name="dates"
                      component={ChangeToDatesCalendar}
                      customDisabledDays={disabledDaysCombined}
                      customMonthStart={oneDayAfterToday}
                      values={values}
                      isOpened={isOpened}
                    />
                    <div style={{ fontWeight: 900 }}>
                      {t('$*deliveryDateChangeModal.leftToMove', {
                        defaultValue: '$$Pozostało do wyboru: {{value}}',
                        replace: {
                          value:
                            values.applyForDates.length - values.dates.length,
                        },
                      })}
                    </div>
                  </Box>
                </Flex>
                <Box mt={50}>
                  <Styled.AddonText>
                    {t(
                      '$*deliveryDateChangeModal.informativeLabel',
                      '$$Wszystkie dodatki zaplanowane na dany dzień zostaną przeniesione.'
                    )}
                  </Styled.AddonText>
                  <ConfirmButtonWrapper
                    buttonText={t(
                      '$*deliveryDateChangeModal.confirmButtoCaption',
                      '$$Zmień termin'
                    )}
                    isLoading={isLoading}
                    disabled={submitting || pristine}
                  />
                </Box>
              </form>
            );
          }}
        />
      )}
    </Modal>
  );
};

export default compose(
  connect(
    ({
      menuPlanning: { initialDietDaysToChange },
      orderForm: {
        orderConfig: { allowChangeMultipleDays, disabledDays },
      },
      app: {
        brand: {
          monday,
          tuesday,
          wednesday,
          thursday,
          friday,
          saturday,
          sunday,
        },
        config: { modules },
      },
    }) => ({
      initialDietDaysToChange,
      allowChangeMultipleDays,
      disabledDays,
      daysConfig: {
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
      },
      modules,
    }),
    {
      setAllDaysToChange,
      setDaysToChange,
    }
  ),
  withRouter
)(DeliveryDateChangeModal);
