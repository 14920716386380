import React, { Fragment } from 'react';

import StyledPremiumBadge from 'styledComponents/elements/MenuPlanning/PremiumBadge';
import { DisplayOnDesktop } from 'styledComponents/helpers';

import Tooltip from 'common/components/Tooltip';

import MODALS from '../const/modal';
import { useTranslation } from 'react-i18next';

const premiumBadgeTooltipId = `premium-badge-tooltip`;

const PremiumBadge = ({
  isPremium,
  handleModalOpen,
  noDisplay,
  changeIsExpired,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <StyledPremiumBadge
        noDisplay={noDisplay}
        data-for={premiumBadgeTooltipId}
        data-tip={premiumBadgeTooltipId}
        isPremium={isPremium}
        changeIsExpired={changeIsExpired}
        onClick={() =>
          !changeIsExpired && handleModalOpen(MODALS.PREMIUM_ACTIVATION_MODAL)
        }
        {...rest}
      >
        {isPremium
          ? t('$*premiumBadge.chooseMenu', 'Wybór menu')
          : changeIsExpired
          ? t('$*premiumBadge.timeForChangeExceeded', 'Czas na zmiany minął')
          : t('$*premiumBadge.purchaseMenuChoose', 'Dokup wybór menu')}
      </StyledPremiumBadge>
      <DisplayOnDesktop>
        {!changeIsExpired && (
          <Tooltip id={premiumBadgeTooltipId} hide={isPremium} maxWidth="250px">
            {!isPremium &&
              t(
                '$*premiumBadge.informationalLabel',
                'WYBÓR MENU pozwoli Ci wybierać pomiędzy posiłkami ze wszystkich dostępnych rodzajów diet w tym dniu!'
              )}
          </Tooltip>
        )}
      </DisplayOnDesktop>
    </Fragment>
  );
};

export default PremiumBadge;
