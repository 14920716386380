import styled from 'styled-components';
import { Flex } from '@rebass/grid';
import React from 'react';

import { fromPxToRem, media } from 'styledComponents/helpers';

// Ensure touches occur rapidly
const delay = 100;
// Sequential touches must be in close vicinity
const minZoomTouchDelta = 10;

// Track state of the last touch
let lastTapAt = 0;
let lastClientX = 0;
let lastClientY = 0;

function preventDoubleTapZoom(event) {
  // Exit early if this involves more than one finger (e.g. pinch to zoom)
  if (event.touches.length > 1) {
    return;
  }

  const tapAt = new Date().getTime();
  const timeDiff = tapAt - lastTapAt;
  const { clientX, clientY } = event.touches[0];
  const xDiff = Math.abs(lastClientX - clientX);
  const yDiff = Math.abs(lastClientY - clientY);
  if (
    xDiff < minZoomTouchDelta &&
    yDiff < minZoomTouchDelta &&
    event.touches.length === 1 &&
    timeDiff < delay
  ) {
    event.preventDefault();
    // Trigger a fake click for the tap we just prevented
    event.target.click();
  }
  lastClientX = clientX;
  lastClientY = clientY;
  lastTapAt = tapAt;
}

const TapAsFastAsYouWantButton = ({ children, ...rest }) => (
  <button {...rest} onTouchStart={preventDoubleTapZoom}>
    {children}
  </button>
);

const cellSize = '28px';

const DaysSwitcherMobile = styled(Flex)`
  display: none;

  ${media.tabletDown`
    display: block;
  `};
`;

DaysSwitcherMobile.MonthRow = styled(Flex)`
  justify-content: space-between;
  margin-bottom: 10px;
`;

DaysSwitcherMobile.DayNavButton = styled(TapAsFastAsYouWantButton)`
  font-size: ${fromPxToRem('24px')};
  width: ${cellSize};
  height: ${cellSize};
  top: 0;
  background: none;
  font-family: 'Font Awesome 5 Free';
  font-weight: ${props => props.theme.fontWeight900};
  color: ${props => props.theme.baseFontColor};
  -webkit-font-smoothing: antialiased;
  border: none;
  cursor: pointer;
  transform: ${props => props.right && `rotate(180deg)`};

  &:before {
    content: '\f053';
  }
`;

DaysSwitcherMobile.MonthText = styled.h3`
  margin-top: 2px;
  font-size: ${fromPxToRem('18px')};
  font-weight: ${props => props.theme.fontWeight500};
  text-transform: uppercase;
  color: ${props => props.theme.baseFontColor};
`;

DaysSwitcherMobile.WeekdaysRow = styled(Flex)`
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: ${fromPxToRem('12px')};
  font-weight: ${props => props.theme.fontWeight900};
  color: ${props => props.theme.baseFontColor};
  text-transform: uppercase;

  > div {
    width: ${cellSize};
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

DaysSwitcherMobile.DayCellsRow = styled(Flex)`
  justify-content: space-between;
  margin-bottom: 10px;

  > div {
    width: ${cellSize};
    height: ${cellSize};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default DaysSwitcherMobile;
