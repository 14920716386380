import React, { Children } from 'react';
import styled from 'styled-components';
import { BASE_URL } from 'configuration';

import {
  StyledFormRadioLabel,
  StyledFormRadioPrimary,
  StyledFormRadioPrimaryMobile,
  StyledFormRadioSecondary,
  StyledFormRadioSecondaryMobile,
  StyledMenuPlanningFormRadio,
} from 'styledComponents/elements/Form/FormRadio';
import { DisplayOnMobile } from '../../../styledComponents/helpers';

const DietImage = styled.img`
  margin-top: 15px;
  border-radius: 3px 3px 0 0;
  ${props => props.disabled && 'filter: grayscale(50%)'};
`;

const getStyledComponent = style => {
  switch (style) {
    case 'primary':
      return StyledFormRadioPrimary;
    case 'primary-mobile':
      return StyledFormRadioPrimaryMobile;
    case 'secondary':
    case 'noContainer':
      return StyledFormRadioSecondary;
    case 'secondary-mobile':
      return StyledFormRadioSecondaryMobile;
    case 'menu-planning':
      return StyledMenuPlanningFormRadio;
    default:
      return 'label';
  }
};

export const DescriptionWrapper = styled.div`
  padding: 15px 0;
  line-height: normal;

  i {
    font-style: italic;
  }
  b {
    font-weight: bold;
  }
  h1 {
    font-size: 22px;
  }
  h2 {
    font-size: 20px;
  }
  ol,
  ul {
    list-style: initial;
  }
  li {
    list-style-position: inside;
  }
  ol {
    list-style: decimal;
  }
`;

const FormRadioDietCard = ({
  icon,
  image,
  width,
  input,
  isFaded,
  maxWidth,
  disabled,
  isCentered,
  label = '',
  customLabel,
  hasAutoWidth,
  hasAutoHeight,
  componentStyle,
  hasBottomMargin,
  description = '',
  manualValidation,
  isDisabledDiet,
}) => {
  const StyledElement = getStyledComponent(componentStyle);
  const componentStylesWithCheckmark = [
    'noContainer',
    'secondary',
    'secondary-mobile',
  ];
  const hasCheckmark = componentStylesWithCheckmark.includes(componentStyle);

  return (
    <StyledElement
      isCentered={isCentered}
      isFaded={isFaded}
      hasAutoHeight={hasAutoHeight}
      hasAutoWidth={hasAutoWidth}
      hasBottomMargin={hasBottomMargin}
      noContainer={componentStyle === 'noContainer'}
      disabled={disabled}
      checked={input.checked}
      mx={10}
      as="label"
      width={width}
      maxWidth={maxWidth}
    >
      <input
        type="radio"
        {...input}
        // onChange={props => {
        //   if (manualValidation) {
        //     if (manualValidation(props)) {
        //       return input.onChange(props);
        //     } else {
        //       return false;
        //     }
        //   } else {
        //     return input.onChange(props);
        //   }
        // }}
      />
      {image && (
        <DietImage
          disabled={disabled}
          width="100%"
          src={`${BASE_URL}${image}`}
        />
      )}
      {label && (
        <StyledFormRadioLabel checked={input.checked}>
          {hasCheckmark && (
            <i
              className={`${
                input.checked ? 'fas fa-check-circle' : 'far fa-circle'
              }`}
            />
          )}
          {image || icon || isDisabledDiet ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {icon && (
                <img
                  style={{ height: '40px', marginRight: '15px' }}
                  src={`${BASE_URL}${icon}`}
                  alt="icon"
                />
              )}
              <span>
                {label}{' '}
                {disabled && (
                  <i
                    style={{ display: 'inline' }}
                    className={'fas fa-check-circle'}
                  />
                )}
              </span>
            </div>
          ) : (
            label
          )}
        </StyledFormRadioLabel>
      )}

      {customLabel && (
        <StyledFormRadioLabel checked={input.checked}>
          {hasCheckmark && (
            <i
              className={`${
                input.checked ? 'fas fa-check-circle' : 'far fa-circle'
              }`}
            />
          )}

          {Children.only(customLabel)}
        </StyledFormRadioLabel>
      )}

      <DisplayOnMobile>
        {input.checked && description && (
          <DescriptionWrapper
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </DisplayOnMobile>
    </StyledElement>
  );
};

export default FormRadioDietCard;
