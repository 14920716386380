import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import {
  getMoneyBoxHistory,
  updateMoneyBoxHistory,
  getAllMoneyBoxHistory,
} from './actions/moneyBoxActions';
import {
  getUserData,
  getUserMoneyboxBalance,
} from 'views/AccountSettings/actions/accountSettingsActions';

export default compose(
  connect(
    ({
      auth: { token },
      accountSettings: { userData },
      moneyBox: {
        history: moneyBoxHistory,
        isLoading,
        allMoneyBoxHistoryLoaded,
        itemsPerPage,
        page,
      },
    }) => ({
      moneyBoxHistory,
      isLoading,
      token,
      userData,
      allMoneyBoxHistoryLoaded,
      itemsPerPage,
      page,
    }),
    {
      getMoneyBoxHistory,
      updateMoneyBoxHistory,
      getAllMoneyBoxHistory,
      getUserData,
      getUserMoneyboxBalance,
    }
  ),
  lifecycle({
    componentDidMount() {
      const { token, getUserMoneyboxBalance } = this.props;
      if (token) {
        Promise.all([getUserMoneyboxBalance()]);
      }
    },
  }),
  Component => props => <Component {...props} />
);
