import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Box, Flex } from '@rebass/grid';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { startOfMonth } from 'date-fns';

import StyledCalendar from 'styledComponents/elements/BigCalendar';

import { getBag } from 'views/MenuPlanning/actions/menuPlanningActions';
import {
  getUserDietCalendar,
  getUserDietsList,
  setSelectedDiet,
} from 'views/UserDiet/actions/userDietActions';

import Calendar from 'common/components/BigCalendar';
import { DAY_STATUSES } from 'common/components/BigCalendar/const/dayStatuses';
import { getCalendarDayStatus } from 'common/components/BigCalendar/services/calendarService';

import { getMonthRange } from 'views/MenuPlanning/services/calendarService';

import { dateObjectToYearMonthDay } from 'common/services/dateHelpers';

import URLS from 'routes/routeUrls';

import { buildUrl } from 'utils/route';

import {
  setSelectedDay,
  setVisibleCalendarDays,
} from '../actions/userDietActions';

import { getBagIdForCurrentDay } from '../services/userDietRepresenter';
import DefaultSubscriptionDietSettings from './DefaultSubscriptionDietSettings';

const DietDetailsCalendarSection = ({
  selectedDay,
  setSelectedDay,
  calendarDays,
  setVisibleCalendarDays,
  history,
  selectedDiet,
  isLoading,
  userDiets = [],
  disabledDays,
  dietIsSubscription,
}) => {
  const days = Object.entries(calendarDays).reduce((acc, [key, value]) => {
    const { state } = value;
    const newState =
      {
        SUBSCRIPTION_DISABLED: DAY_STATUSES.SUBSCRIPTION_HOLDED,
      }[state] || state;

    return {
      ...acc,
      [key]: {
        ...value,
        state: newState,
      },
    };
  }, {});

  const disabledWeekdaysArray = days
    ? Object.values(days)
        .map(day => {
          //return day.state === DAY_STATUSES.NO_DIET
          return day.newState === DAY_STATUSES.NOT_DIET_CANT_PLACE_ORDER ||
            day.newState === DAY_STATUSES.DISABLED ||
            day.newState === DAY_STATUSES.DISABLED_WITH_BAG ||
            day.newState === DAY_STATUSES.ERROR_ON_RENEW
            ? new Date(day.date)
            : false;
        })
        .filter(Boolean)
    : [];

  const dietsMissing = userDiets.length === 0 && !isLoading;

  const handleMonthChange = month => {
    setVisibleCalendarDays(getMonthRange(startOfMonth(month)));
  };

  const handleDayChange = day => {
    const bagId = getBagIdForCurrentDay({
      currentDay: day,
      calendarDays: days,
    });
    const dayStatus = getCalendarDayStatus({
      dietsMissing,
      currentDay: day,
      calendarDays: days,
    });
    const selectedDay = dateObjectToYearMonthDay(day);

    setSelectedDay(selectedDay);

    switch (dayStatus) {
      case DAY_STATUSES.SUBSCRIPTION_DISABLED: //delete
      case DAY_STATUSES.NO_DIET: //delete
      case DAY_STATUSES.EMPTY:
      case DAY_STATUSES.NOT_DIET_CANT_PLACE_ORDER:
      case DAY_STATUSES.DISABLED:
      case DAY_STATUSES.DISABLED_WITH_BAG:
      case DAY_STATUSES.ERROR_ON_RENEW:
        break;
      case DAY_STATUSES.ADD_DIET: //delete
      case DAY_STATUSES.NOT_DIET_CAN_PLACE_ORDER:
        history.push(URLS.NEW_ORDER_FORM);
        break;
      default:
        history.push(
          buildUrl(URLS.MENU_PLANNING, {
            day: dateObjectToYearMonthDay(day),
            bagId,
            dietId: selectedDiet,
          })
        );
        break;
    }
  };
  return (
    <Fragment>
      <Flex>
        <Box style={{ position: 'relative' }} width="100%">
          {dietIsSubscription && <DefaultSubscriptionDietSettings />}
          <StyledCalendar dietIsSubscription={dietIsSubscription}>
            <Calendar
              isLoading={isLoading}
              days={days}
              selectedDay={selectedDay}
              dietsMissing={dietsMissing}
              disabledWeekdays={disabledWeekdaysArray}
              handleDayChange={handleDayChange}
              handleMonthChange={handleMonthChange}
              exludedDays={disabledDays}
              selectedDiet={selectedDiet}
            />
          </StyledCalendar>
        </Box>
      </Flex>
    </Fragment>
  );
};

export default compose(
  connect(
    ({
      userDiet: {
        isLoading,
        calendar,
        selectedDiet,
        selectedDietObject,
        selectedDay,
        list: userDiets,
      },
      orderForm: {
        orderConfig: { disabledDays },
      },
      menuPlanning: { bag },
      app: {
        brand: {
          monday,
          tuesday,
          wednesday,
          thursday,
          friday,
          saturday,
          sunday,
        },
      },
    }) => ({
      selectedDiet,
      selectedDay,
      selectedDietObject,
      isLoading,
      calendarDays: calendar?.days || {},
      bag,
      userDiets,
      disabledDays,
      daysConfig: {
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
      },
      dietIsSubscription: selectedDietObject?.isSubscription ?? false,
    }),
    {
      getUserDietCalendar,
      getUserDietsList,
      getBag,
      setSelectedDiet,
      setSelectedDay,
      setVisibleCalendarDays,
    }
  ),
  withRouter
)(DietDetailsCalendarSection);
