import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import {
  getValidators,
  useFormValidation,
} from 'common/services/formValidation';
import FormCheckbox from 'common/components/Form/FormCheckbox';
import FormTextInput from 'common/components/Form/FormTextInput';
import FormSubmitError from 'common/components/Form/FormSubmitError';
import withStripeConsumer from 'common/components/HOC/withStripeConsumer';
import { ButtonPrimary } from 'styledComponents/elements/Button';
import StyledFormTextInput from 'styledComponents/elements/Form/FormTextInput';
import Spinner from 'styledComponents/elements/LoadingSpinner';

import StyleCardElement from './PaymentCardForm.styled';
import { get } from 'utils/http';
import { URLS } from 'configuration';
import {
  setAllPaymentCards,
  setCreditCards,
} from 'views/Auth/actions/authActions';

const cardOptions = {
  style: {
    base: {
      fontSize: '16px',
      color: '#424770',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

const AddCardForm = ({ stripeSecret }) => {
  const { t } = useTranslation();
  const [successSaveCard, setSuccessSaveCard] = useState(false);
  const [cardsAreLoading, setCardsAreLoading] = useState(false);
  const [error, setError] = useState(null);

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const validators = useFormValidation();
  const { rulesLink } = useSelector(({ app }) => app.config.modules.Stripe);

  const handleSubmit = async ({ cardName }) => {
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error } = await stripe.confirmCardSetup(stripeSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: cardName,
        },
      },
    });

    if (error) {
      setError(t(`$*stripe.error.${error.code}`, `$$${error.message}`));
      setSuccessSaveCard(false);
    } else {
      cardElement.update({ disabled: true });
      setError(null);
      setSuccessSaveCard(true);

      const { data } = await get(`${URLS.CARDS}?createIntent=false`);
      dispatch(setCreditCards(data));
      setCardsAreLoading(true);
      const { data: allPaymentCards } = await get(`${URLS.ALL_PAYMENT_CARDS}`);
      setCardsAreLoading(false);
      dispatch(setAllPaymentCards(allPaymentCards['hydra:member']));
    }
  };

  if (!stripe) {
    return t('$*stripe.isLoading', '$$Trwa ładowanie stripe...');
  }

  if (cardsAreLoading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Spinner />
      </div>
    );
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{ cardName: '' }}
      render={({ handleSubmit, submitting, pristine, invalid }) => (
        <form onSubmit={handleSubmit}>
          <Field
            isBig
            name="cardName"
            type="text"
            component={FormTextInput}
            label={t('$*cardPayment.cardName', '$$Nazwa karty')}
            isRequired={true}
            validate={getValidators([validators.required])}
            width="100%"
            disabled={successSaveCard}
          />

          <StyledFormTextInput>
            <StyledFormTextInput.Label isBig={true}>
              {t('$*cardPayment.cardData', '$$Dane karty')} *
            </StyledFormTextInput.Label>
            <StyledFormTextInput.InputContainer>
              <StyleCardElement options={cardOptions} />
            </StyledFormTextInput.InputContainer>
          </StyledFormTextInput>

          <Field
            name="subscriptionTerms"
            type="checkbox"
            component={FormCheckbox}
            componentStyle="rounded"
            linkLabel={t(
              '$*cardPayment.term.linkLabel',
              '$$ regulamin płatności cyklicznych'
            )}
            link={rulesLink}
            width="100%"
            isRequired={true}
            validate={getValidators([validators.required])}
          >
            {t('$*common.accept', '$$Akceptuję')}
          </Field>

          <FormSubmitError
            errorText={error}
            justifyContent="flex-start"
            style={{ marginTop: '20px' }}
          />

          <ButtonPrimary
            type="submit"
            uppercased
            sizeMiddle
            weightBold
            withSpinner
            disabled={
              !stripe || submitting || pristine || invalid || successSaveCard
            }
            fullWidthOnMobile
            style={{ marginTop: '40px' }}
          >
            {!successSaveCard
              ? t('$*common.submit', '$$Zatwierdź')
              : t('$*common.saved', '$$Zapisano')}
          </ButtonPrimary>
        </form>
      )}
    />
  );
};

export default withStripeConsumer(AddCardForm);
