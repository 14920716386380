import React from 'react';
import styled from 'styled-components';
import {
  StyledFormRadioLabel,
  StyledFormRadioPrimary,
} from 'styledComponents/elements/Form/FormRadio';
import { BASE_URL } from 'configuration';

const StyledRadio = styled(StyledFormRadioPrimary)`
  width: calc(20% - 20px);
  min-width: 200px;
`;

const StandalonePrimaryRadio = ({
  onClick,
  checked,
  image,
  icon,
  marginRight,
  disableHandCursor,
  CustomImageComponent,
  name = '',
}) => {
  return (
    <StyledRadio
      marginRight={marginRight}
      onClick={onClick}
      label={name}
      checked={checked}
      disableHandCursor={disableHandCursor}
    >
      {image && (
        <img
          style={{ marginTop: '15px', borderRadius: '3px 3px 0 0' }}
          width="100%"
          src={`${!image.startsWith('https://') ? BASE_URL : ''}${image}`}
          alt=""
        />
      )}
      {name && (
        <StyledFormRadioLabel>
          {image || icon ? (
            <div
              style={{
                display: 'flex',
                float: 'left',
                alignItems: 'center',
              }}
            >
              {icon && (
                <img
                  style={{ height: '40px', marginRight: '15px' }}
                  src={`${BASE_URL}${icon}`}
                  alt=""
                />
              )}
              <span>{name}</span>
            </div>
          ) : (
            name
          )}
        </StyledFormRadioLabel>
      )}
    </StyledRadio>
  );
};

export default StandalonePrimaryRadio;
