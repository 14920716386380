import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import FormRadioGroup from 'common/components/Form/FormRadioGroup';
import FORM_FIELD_NAMES from '../../const/formFieldNames';
import { CONFIGURATION_FIELDS } from '../../const/formConfig/configurationFields';

import { Paragraph } from 'styledComponents/elements/Typography';

import { sortByPosition } from 'utils/componentHelpers';
import { DisplayOnDesktop } from '../../../../styledComponents/helpers';

const DietPackage = ({ packages = [], packageDesc }) => {
  return packages && packages.length > 0 ? (
    <Fragment>
      <FormRadioGroup>
        {sortByPosition(
          packages.filter(pack => pack.diets.length > 0),
          true
        ).map(pack => (
          <Field
            key={pack.id}
            value={pack.id.toString()}
            label={pack.name}
            image={pack.image && `/uploaded-media/${pack.image.contentUrl}`}
            description={packageDesc}
            {...CONFIGURATION_FIELDS[FORM_FIELD_NAMES.PACKAGE]}
          />
        ))}
      </FormRadioGroup>

      <DisplayOnDesktop>
        <Paragraph customPadding={'30px 0 0'}>{packageDesc}</Paragraph>
      </DisplayOnDesktop>
    </Fragment>
  ) : null;
};
export default connect(
  ({ dietForm: { forms, currentFormId } }) => ({ forms, currentFormId }),
  null
)(DietPackage);
