import React from 'react';
import styled from 'styled-components';
import { media } from 'styledComponents/helpers';
import { connect } from 'react-redux';

import FooterNavigationItem from './FooterNavigationItem';
import FooterNavigationExternalItem from './FooterNavigationExternalItem';

import { isExternalUrl } from 'utils/url';
import { sortByPosition } from 'utils/common';

const StyledNavbar = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-left: ${props => (props.isAlignedRight ? 'auto' : null)};
  align-items: center;

  ${media.tabletDown`
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    margin-left: 0;
  `};
`;

const FooterNavigation = ({ isAlignedRight, pages }) => (
  <StyledNavbar isAlignedRight={isAlignedRight}>
    {sortByPosition(pages).map(({ id, inFooter, slug, label }) => {
      const isExternal = isExternalUrl(slug);
      const props = {
        key: id,
        label: label,
      };

      if (inFooter) {
        return isExternal ? (
          <FooterNavigationExternalItem path={slug} {...props} />
        ) : (
          <FooterNavigationItem path={`/pages/${slug}`} {...props} />
        );
      }

      return null;
    })}
  </StyledNavbar>
);

export default connect(({ app: { brand, config: { pages } } }) => ({
  brand,
  pages,
}))(FooterNavigation);
