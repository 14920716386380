import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import routeUrls from 'routes/routeUrls';
import { BASE_URL, URLS } from 'configuration';
import {
  Banner,
  TitleWrapper,
  DescriptionWrapper,
} from 'styledComponents/elements/Banner';

import Header from './components/Header';
import Content from './components/Content';
import Footer from './components/Footer';
import { DisplayOnDesktop, DisplayOnMobile } from '../styledComponents/helpers';
import { useLanguageManager } from './components/Header/LanguageSelector';

const StyledWrapper = styled.div`
  height: ${props => (props.isMobileMenuOpened ? '100vh' : 'initial')};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${props =>
    props.backgroundColor &&
    !props.useImageAsBackground &&
    `background: ${props.backgroundColor}`};
  ${props =>
    props.useImageAsBackground &&
    props.backgroundURL &&
    `
    background: url(${`${BASE_URL}${URLS.UPLOADED_MEDIA}${props.backgroundURL}`});
    background-attachment: fixed;
    background-size: cover;
  `}
`;

const MainLayout = ({
  component: Component,
  hasNarrowContainer,
  responsive,
  isMobileMenuOpened,
  useImageAsBackground,
  backgroundImage,
  backgroundColor,
  newOrderInfoEnabled,
  newOrderInfoTitle,
  newOrderInfoDescription,
  newOrderInfoBannerDesktop,
  newOrderInfoBannerMobile,
  tabIndex,
  userProceed,
}) => {
  const withNewOrderRoute =
    window.location.href.includes('new-order') &&
    !window.location.href.includes('preparing') &&
    !window.location.href.includes('payment-status') &&
    !window.location.href.includes('confirm') &&
    !window.location.href.includes('error') &&
    !window.location.href.includes('failed');

  const menuRoute = window.location.pathname === routeUrls.MENU;

  useLanguageManager();

  const getBanner = () => {
    if (
      withNewOrderRoute &&
      !userProceed &&
      newOrderInfoEnabled &&
      tabIndex === 0
    ) {
      return (
        <Banner
          backgroundURLDesktop={newOrderInfoBannerDesktop}
          backgroundURLMobile={newOrderInfoBannerMobile}
        >
          <DisplayOnDesktop>
            {newOrderInfoBannerDesktop && (
              <Banner.Image
                src={`${BASE_URL}${URLS.UPLOADED_MEDIA}${newOrderInfoBannerDesktop?.contentUrl}`}
              />
            )}
          </DisplayOnDesktop>
          <DisplayOnMobile>
            {newOrderInfoBannerMobile && (
              <Banner.Image
                src={`${BASE_URL}${URLS.UPLOADED_MEDIA}${newOrderInfoBannerMobile?.contentUrl}`}
              />
            )}
          </DisplayOnMobile>
          <Banner.TextContainer>
            <TitleWrapper
              dangerouslySetInnerHTML={{
                __html: newOrderInfoTitle,
              }}
            />
            <DescriptionWrapper
              dangerouslySetInnerHTML={{
                __html: newOrderInfoDescription,
              }}
            />
          </Banner.TextContainer>
        </Banner>
      );
    }
  };

  return (
    <StyledWrapper
      isMobileMenuOpened={isMobileMenuOpened}
      backgroundURL={backgroundImage}
      useImageAsBackground={useImageAsBackground}
      backgroundColor={backgroundColor}
    >
      <div>
        <Header />
        {getBanner()}
        <Content
          hasNarrowContainer={hasNarrowContainer}
          responsive={responsive}
        >
          <Component />
        </Content>
      </div>
      <Footer />
    </StyledWrapper>
  );
};

export default connect(
  ({
    app: {
      isMobileMenuOpened,
      brand: { useImageAsBackground },
    },
    orderForm: {
      userProceed,
      orderConfig: {
        backgroundImage,
        backgroundColor,
        newOrderInfoEnabled,
        newOrderInfoTitle,
        newOrderInfoDescription,
        newOrderInfoBannerDesktop,
        newOrderInfoBannerMobile,
      },
    },
    orderFormTabs: { tabIndex },
  }) => ({
    isMobileMenuOpened,
    useImageAsBackground,
    backgroundImage,
    backgroundColor,
    newOrderInfoEnabled,
    newOrderInfoTitle,
    newOrderInfoDescription,
    newOrderInfoBannerDesktop,
    newOrderInfoBannerMobile,
    tabIndex,
    userProceed,
  })
)(MainLayout);
