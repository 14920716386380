const INVOICE = 'INVOICE';
const RECEIPT = 'RECEIPT';
const DISABLED = 'DISABLED';

const INTERGRATION_TYPE = {
  INVOICE,
  RECEIPT,
  DISABLED,
};

export { INTERGRATION_TYPE };
