import styled from 'styled-components';

import { fromPxToRem } from 'styledComponents/helpers';

const Tooltip = styled.div`
  .__react_component_tooltip {
    max-width: ${props => props.maxWidth || 'unset'};
    min-height: 52px;
    opacity: ${props => props.opacity || '0.75'};
    border-radius: 11px;
    background-color: ${props =>
      props.backgroundColor || props.theme.colorBlack};
    font-size: ${props => props.fontSize || fromPxToRem('11px')};
    padding: ${props => props.padding || '10px 15px'};
    text-align: center;
    display: ${props => (props.hide ? 'none' : 'inline-block')};

    & > img {
      width: 195px;
      height: auto;
      margin: auto;
    }
  }
`;

Tooltip.Handle = styled.div`
  & > * {
    display: flex;
  }
`;

export default Tooltip;
