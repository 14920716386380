import styled from 'styled-components';

import { media } from 'styledComponents/helpers';
import { BASE_URL, URLS } from 'configuration';
import FormRadioGroup from 'common/components/Form/FormRadioGroup';

import imagePlaceholder from 'assets/images/image_placeholder.jpg';

const DishRadioGroup = styled(FormRadioGroup)`
  width: calc(100% + 20px);

  ${media.tabletDown`
    width: 100% !important
  `};
`;

const DishWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 -10px;
  width: 100%;

  ${media.tabletDown`
    margin: 0;
  `};
`;

const DishContent = styled.div`
  flex: 0 0 calc(100% - 210px);

  ${media.desktopDown`
    flex: 0 0 100%;
  `};
`;

const DishBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  ${({ hasImage }) =>
    !hasImage &&
    `
    ${DishContent} {
      flex: 0 0 100%;
    }
  `}
`;

const DishImage = styled.div`
  ${({ imageUrl }) =>
    imageUrl
      ? `background: url(${BASE_URL}${URLS.UPLOADED_MEDIA}${imageUrl}) no-repeat center center`
      : `background: url(${imagePlaceholder}) no-repeat center center`};
  background-size: cover;
  flex: 0 0 180px;
  height: 180px;
  margin-right: 30px;
  max-width: 100%;

  ${media.desktopDown`
    flex: 0 0 100%;
    margin: 0 0 15px 0;
  `};
`;

const DishBoxWrapper = styled.div`
  border: 2px solid ${({ theme }) => theme.colorIceBlue2};
  background-color: #fff;
  height: auto;
  padding: 10px 20px;
  width: auto;
  margin-bottom: 16px;
  margin-top: 10px;
  min-width: 250px;
  border-radius: 8px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);

  ${DishContent} {
    line-height: 1.2rem;
  }
`;

export const Styled = {
  DishRadioGroup,
  DishWrapper,
  DishBox,
  DishImage,
  DishContent,
  DishBoxWrapper,
};
