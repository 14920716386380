import { useEffect } from 'react';

const CustomScriptsLoader = ({ customScripts }) => {
  useEffect(() => {
    if (document.querySelector('#custom-script') === null) {
      let script = document.createElement('script');
      script.id = 'custom-script';
      script.innerHTML = customScripts;
      document.head.appendChild(script);
    }
  }, [customScripts]);

  return null;
};

export default CustomScriptsLoader;
