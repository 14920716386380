import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import history from './history';
import Cookies from 'cookies-js';

import * as Sentry from '@sentry/browser';

import { persistor } from './store';
import store from './storeSubscribe';

import App from 'views/App';
import './i18n';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';

if (process.env.NODE_ENV === 'production') {
  const SENTRY_DSN = process.env.REACT_APP_SENTRY_KEY;
  const SENTRY_DSN_WITH_PROTOCOL = /^https?:\/\//.test(SENTRY_DSN)
    ? SENTRY_DSN
    : `https://${SENTRY_DSN}`;

  Sentry.init({
    dsn: SENTRY_DSN_WITH_PROTOCOL,
    ignoreErrors: ['Request failed with status code 401'],
    release: process.env.REACT_APP_APP_VERSION || 'not-set',
  });
}

Cookies.set('appv', ''); // appv is our new API ver detector - full page load needs no detecing, hence clearing

const render = Component => {
  return ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <MuiThemeProvider theme={createMuiTheme()}>
            <Component />
          </MuiThemeProvider>
        </Router>
      </PersistGate>
    </Provider>,
    document.getElementById('root')
  );
};

render(App);

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./views/App', () => {
    const NextApp = require('./views/App').default;
    return render(NextApp);
  });
}

if (process.env.NODE_ENV === 'development') {
  (async () => {
    await import('./dev.js');
  })();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register(null, () => {
//   console.log('registered');
// });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
