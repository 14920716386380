import styled from 'styled-components';
import { media } from 'styledComponents/helpers';

const StyledLogo = styled.img`
  display: block;
  margin-right: 25px;
  max-height: ${props => (props.small ? '23px' : '70px')};
  ${media.tabletDown`
    max-width: 45vw;
    margin-right: 0;
  `};
`;

export default StyledLogo;
