import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getComponentProps } from 'utils/componentHelpers';

import {
  DietType,
  DietPackage,
  DietVariant,
  DietCalorifics,
  PaymentMode,
  DietDeliveryDays,
  AdditionalUpgrades,
} from 'views/NewOrder/components/configuration';

export const useConfigurationSections = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isSubscription = useSelector(
    ({ dietForm: { paymentMode } }) => paymentMode === '1'
  );
  const {
    isOneTimePayPaymentMode,
    isSubscriptionPaymentMode,
    enableVariantStep,
  } = useSelector(
    ({
      app: {
        config: {
          modules: { ConfigClientPanel },
        },
      },
    }) => ConfigClientPanel
  );

  return useMemo(() => {
    const hasOnlySubscriptionPayment =
      isSubscriptionPaymentMode && !isOneTimePayPaymentMode;
    const getPaymentModeTitle = () => {
      if (isSubscriptionPaymentMode && isOneTimePayPaymentMode) {
        return {
          title: t(
            '$*orderFormConfigurationSections.paymentMode',
            '$$Wybierz tryb zamówienia'
          ),
          key: '$*orderFormConfigurationSections.paymentMode',
        };
      }

      return {
        title: t(
          '$*orderFormConfigurationSections.dietLength',
          '$$Wybierz czas trwania i skorzystaj z największych rabatów!'
        ),
        key: '$*orderFormConfigurationSections.dietLength',
      };
    };
    const {
      title: paymentModeTitle,
      key: paymentModeI18nKey,
    } = getPaymentModeTitle();

    return [
      {
        code: 'package',
        title: t(
          '$*orderFormConfigurationSections.package',
          '$$Wybierz pakiet'
        ),
        i18nKey: '$*orderFormConfigurationSections.package',
        component: DietPackage,
        requiredProps: ['packages', 'packageDesc'],
        getComponentProps,
      },
      {
        title: t('$*orderFormConfigurationSections.kind', '$$Wybierz rodzaj'),
        i18nKey: '$*orderFormConfigurationSections.kind',
        titleLink: true,

        titleLinkPropName: 'menuButtonLink',
        titleLinkShow: 'showMenuButton',
        titleLinkText: t(
          '$*orderFormConfigurationSections.seeMenuLink',
          '$$Zobacz menu'
        ),
        customComponentJustifyContent: 'space-between',
        component: DietType,
        requiredProps: [
          'packages',
          'diets',
          'dietTypeDesc',
          'menuButtonLink',
          'showMenuButton',
        ],
        getComponentProps,
      },
      ...(enableVariantStep
        ? [
            {
              title: t(
                '$*orderFormConfigurationSections.variant',
                '$$Wybierz wariant'
              ),
              i18nKey: '$*orderFormConfigurationSections.variant',

              component: DietVariant,
              requiredProps: ['variants', 'diets'],
              getComponentProps,
            },
          ]
        : []),
      {
        title: t(
          '$*orderFormConfigurationSections.calorific',
          '$$Wybierz kaloryczność'
        ),
        i18nKey: '$*orderFormConfigurationSections.calorific',

        titleLink: true,
        titleLinkPropName: 'calculatorLink',
        titleLinkShow: 'showCalculatorLink',
        titleLinkText: t(
          '$*orderFormConfigurationSections.calorificCalculator',
          '$$Kalkulator kaloryczności'
        ),
        i18nKeyTitleLinkText:
          '$*orderFormConfigurationSections.calorificCalculatorLink',

        customComponentJustifyContent: 'space-between',
        component: DietCalorifics,
        requiredProps: ['calorifics'],
        getComponentProps,
      },
      ...(!hasOnlySubscriptionPayment
        ? [
            {
              title: paymentModeTitle,
              i18nKey: paymentModeI18nKey,
              component: PaymentMode,
              requiredProps: isSubscription
                ? ['days']
                : ['days', 'testDays', 'testDaysEnabled'],
              getComponentProps,
            },
          ]
        : []),
      {
        title: isSubscription
          ? t(
              '$*orderFormConfigurationSections.firstDeliveryDay',
              '$$Wybierz pierwszy dzień dostawy'
            )
          : t(
              '$*orderFormConfigurationSections.deliveryDays',
              '$$Wybierz dni dostawy'
            ),
        i18nKey: isSubscription
          ? '$*orderFormConfigurationSections.firstDeliveryDay'
          : '$*orderFormConfigurationSections.deliveryDays',

        component: DietDeliveryDays,
        requiredProps: ['form'],
        getComponentProps,
      },
      {
        title: t(
          '$*orderFormConfigurationSections.extraOptions',
          '$$Opcje dodatkowe'
        ),
        i18nKey: '$*orderFormConfigurationSections.extraOptions',

        component: AdditionalUpgrades,
        getComponentProps,
        requiredProps: ['form'],
      },
    ];
  }, [
    language,
    isSubscription,
    isOneTimePayPaymentMode,
    isSubscriptionPaymentMode,
    enableVariantStep,
  ]);
};
