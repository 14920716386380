import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormSpy } from 'react-final-form';
import { difference } from 'ramda';

import { getOrderCost } from '../actions/orderFormActions';
import FORM_FIELD_NAMES from '../const/formFieldNames';
import showToast from 'utils/toast';
import { updateForm } from 'views/NewOrder/actions/dietFormActions';
class DietPriceCalculations extends Component {
  state = {
    isLoadingGetOrderCost: false,
  };

  componentDidUpdate(prevProps) {
    const { forms, currentFormId } = this.props;

    const hasFieldChanged =
      JSON.stringify(prevProps.forms[currentFormId]) !==
      JSON.stringify(forms[currentFormId]);

    if (hasFieldChanged) {
      const currentForm = forms[currentFormId];
      const canFetch =
        currentForm[FORM_FIELD_NAMES.VARIANT] &&
        currentForm[FORM_FIELD_NAMES.CALORIFICS];

      this.fetchOrderCost(canFetch);
    }
  }

  fetchOrderDumbTimeout = () =>
    setTimeout(async () => {
      if (!this.state.isLoadingGetOrderCost) {
        this.fetchOrderCost(true);
      } else {
        this.fetchOrderDumbTimeout();
      }
    }, [200]);

  async fetchOrderCost(canFetch) {
    const { getOrderCost, tabName } = this.props;

    if (this.canCalculate() && canFetch) {
      try {
        if (!this.state.isLoadingGetOrderCost) {
          this.setState({ isLoadingGetOrderCost: true }, async () => {
            try {
              await getOrderCost().then(() => {
                this.setState({ isLoadingGetOrderCost: false });
              });
            } catch (e) {
              console.log(e);
              this.setState({ isLoadingGetOrderCost: false });
            }
          });
        } else {
          this.fetchOrderDumbTimeout();
        }
      } catch (err) {
        const postCodeErr = err?.response?.data?.violations?.find(
          ({ propertyPath }) => propertyPath === 'postCode'
        );

        if (postCodeErr) {
          tabName === 'OrderFormDelivery' &&
            showToast({
              message: postCodeErr.message,
              type: 'error',
            });

          this.props.form.mutators.setFieldValue(
            FORM_FIELD_NAMES.DELIVERY_ADDRESS,
            '0'
          );

          const { name, ...restValues } = this.props.forms[
            this.props.currentFormId
          ];

          updateForm(this.props.currentFormId, {
            ...restValues,
            [FORM_FIELD_NAMES.DELIVERY_ADDRESS]: null,
          });
        }
      }
    }
  }

  canCalculate() {
    const { active, forms, currentFormId, watchFields } = this.props;
    const fieldsToOmit = difference(
      Object.keys(forms[currentFormId]),
      watchFields
    );

    return !fieldsToOmit.includes(active);
  }

  render() {
    return null;
  }
}

export default connect(
  ({ dietForm: { forms, currentFormId }, orderFormTabs: { tabName } }) => ({
    forms,
    currentFormId,
    tabName,
  }),
  { getOrderCost }
)(props => (
  <FormSpy
    {...props}
    component={DietPriceCalculations}
    subscription={{ active: true }}
  />
));
