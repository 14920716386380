import styled, { createGlobalStyle, css } from 'styled-components';
import { rgba } from 'polished';

import { fromPxToRem, media } from 'styledComponents/helpers';

import FadeIn from '../animations/FadeIn';
import Slide from '../animations/Slide';

import { Heading } from './Typography';

const Modal = styled.div`
  display: ${props => (props.isOpened ? 'flex' : 'none')};
  justify-content: center;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 101;
  margin-top: ${props => (props.hideHeader ? '0' : '80px')};
  background-color: ${props => rgba(props.theme.colorDarkGrey, 0.75)};
  animation: ${FadeIn} 0.25s;

  ${props =>
    props.fullscreen &&
    css`
      ${media.tabletDown`
        background-color: ${props => props.theme.colorWhite};
      `};
    `};

  ${media.tabletDown`
      animation: ${Slide} 0.25s;
    `};
`;

Modal.Body = styled.div`
  display: inline-block;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  border-radius: ${props => props.theme.baseBorderRadius};
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
  padding: ${props => props.padding || '16px 0 32px 16px'};
  background-color: ${props => props.backgroundColor || props.theme.colorWhite};
  min-width: 280px;
  max-height: calc(100vh - 240px);
  position: absolute;
  margin: 5% auto;
  max-width: ${props => (props.widthDesktop ? props.widthDesktop : '355px')};
  //max-width: ${props => props.maxWidth || 'inherit'};

  ${media.tabletDown`
    width: ${props => props.widthMobile};
    padding: ${props => props.paddingMobile};
  `};

  ${props =>
    props.fullscreen &&
    css`
      box-shadow: none;
      //width: ${props => props.widthDesktop || '100%'};

      ${media.tabletDown`
        width: ${props => props.widthMobile};
        margin: 5% 15px;
        max-height: calc(100vh - 5% - 80px);
      `};
    `};
`;

Modal.Title = styled(Heading)`
  line-height: normal;
  font-size: ${props => props.customFontSize || fromPxToRem('24px')};

  ${media.tabletDown`
    font-size: ${fromPxToRem('20px')};
  `};

  ${props =>
    props.withBackButton &&
    css`
      margin: 0 30px;
    `};
`;

Modal.Content = styled.div`
  padding: ${props => (props.contentPadding ? props.contentPadding : '30px')};

  ${media.tabletDown`
    padding: ${props => props.contentPaddingMobile ?? '30px'}
  `};
`;

Modal.CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${fromPxToRem('20px')};
  width: 26px;
  height: 26px;
  color: ${props => props.theme.baseFontColor};
  cursor: pointer;
  padding: 20px;
`;

Modal.BackButton = styled(Modal.CloseButton)`
  left: 10px;
  right: initial;
  width: initial;
  height: initial;
  padding: 20px 0;
`;

Modal.ScrollBlocker = createGlobalStyle`
  body {
    overflow: ${props => (props.isOpened ? 'hidden' : 'inherit')};
  }
`;

export default Modal;
