import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { BASE_URL, URLS } from '../../configuration';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const Seo = ({ Seo, brand, t }) => {
  const imagesSource = Seo['@resources'];

  return (
    <Helmet>
      <title>
        {Seo.meta.title ||
          `${brand.name} - ${t('$*page.title', '$$Panel klienta')}`}
      </title>

      {/* Google / Search Engine Tags */}
      {Seo.meta.title && <meta itemprop="name" content={Seo.meta.title} />}
      {Seo.og.image && (
        <meta
          itemprop="image"
          content={`${BASE_URL}${URLS.UPLOADED_MEDIA}${
            imagesSource[Seo.og.image]?.contentUrl
          }`}
        />
      )}

      {!Seo.indexing && <meta name="robots" content="no-index" />}
      {Seo.meta.description && (
        <meta name="description" content={Seo.meta.description} />
      )}
      {Seo.meta.description && (
        <meta itemprop="description" content={Seo.meta.description} />
      )}

      {/* Facebook Meta Tags */}
      <meta
        property="og:url"
        content={`${window.location.protocol}//${window.location.hostname}/`}
      />
      <meta property="og:type" content="website" />
      {Seo.og.title && <meta property="og:title" content={Seo.og.title} />}
      {Seo.og.description && (
        <meta property="og:description" content={Seo.og.description} />
      )}
      {Seo.og.image && (
        <meta
          property="og:image"
          content={`${BASE_URL}${URLS.UPLOADED_MEDIA}${
            imagesSource[Seo.og.image]?.contentUrl
          }`}
        />
      )}

      {/* Twitter Meta Tags */}
      <meta
        property="twitter:url"
        content={`${window.location.protocol}//${window.location.hostname}/`}
      />
      <meta name="twitter:card" content="summary_large_image" />
      {Seo.og.title && <meta name="twitter:title" content={Seo.og.title} />}
      {Seo.og.description && (
        <meta name="twitter:description" content={Seo.og.description} />
      )}
      {Seo.og.image && (
        <meta
          name="twitter:image"
          content={`${BASE_URL}${URLS.UPLOADED_MEDIA}${
            imagesSource[Seo.og.image]?.contentUrl
          }`}
        />
      )}
    </Helmet>
  );
};

const enhance = compose(
  withTranslation(),
  connect(({ app: { brand, config: { modules: { Seo } } } }) => ({
    Seo,
    brand,
  }))
);

export default enhance(Seo);
