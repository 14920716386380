import React from 'react';
import { Field, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { Flex } from '@rebass/grid';
import { connect } from 'react-redux';
import { omit } from 'ramda';

import { ButtonLink } from 'styledComponents/elements/Button';
import { ContentWrapper } from 'styledComponents/blocks/ContentWrapper';
import { DisplayOnMobile, DisplayOnDesktop } from 'styledComponents/helpers';

import FormSection from 'common/components/Form/FormSection';
import FormFieldBox from 'common/components/Form/FormFieldBox';
import FormSubmitError from 'common/components/Form/FormSubmitError';
import NOTIFICATIONS from 'common/shared/notifications';

import showToast from 'utils/toast';

import {
  INITIAL_VALUES,
  INPUT_IDS,
  usePasswordResetFormInputs,
} from 'views/AccountSettings/const/passwordResetFormInputs';

import { changePassword } from 'views/AccountSettings/actions/accountSettingsActions';
import { SubmitButton } from './SubmitButton';
import { useTranslation } from 'react-i18next';

const PasswordResetForm = ({ changePassword }) => {
  const { t } = useTranslation();
  const onSubmit = form => {
    if (form[INPUT_IDS.NEW_PASSWORD] !== form[INPUT_IDS.REPEAT_NEW_PASSWORD]) {
      return {
        [FORM_ERROR]: t(
          '$*error.samePassword',
          '$$Podane hasła muszą być takie same'
        ),
      };
    }

    const passwords = omit([INPUT_IDS.REPEAT_NEW_PASSWORD], form);

    return changePassword(passwords)
      .then(() => {
        showToast({
          message: NOTIFICATIONS(t).PASSWORD_CHANGED_SUCCESSFULLY,
          type: 'success',
        });
      })
      .catch(error => {
        return { [FORM_ERROR]: error };
      });
  };

  const savePasswordCaption = t(
    '$*accountsSettings.resetPassword.changePasswordCaption',
    '$$Zmień hasło'
  );

  const PASSWORD_RESET_FORM_INPUTS = usePasswordResetFormInputs();

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={INITIAL_VALUES}
      render={({
        handleSubmit,
        submitting,
        pristine,
        invalid,
        submitError,
      }) => (
        <form onSubmit={handleSubmit}>
          <ContentWrapper padding="40px">
            <FormSection
              title={t(
                '$*accountSettings.changePassword.title',
                '$$Zmiana hasła'
              )}
              action={
                <DisplayOnDesktop>
                  <SubmitButton
                    submitting={submitting}
                    pristine={pristine}
                    invalid={invalid}
                    caption={savePasswordCaption}
                  />
                </DisplayOnDesktop>
              }
            >
              <Flex flexWrap="wrap" justifyContent="center">
                {PASSWORD_RESET_FORM_INPUTS.map(({ id, width, ...input }) => (
                  <FormFieldBox key={id} width={width}>
                    <Field isBig {...input} />
                  </FormFieldBox>
                ))}
              </Flex>
            </FormSection>
            <FormSubmitError
              errorText={submitError}
              justifyContent="flex-start"
            />
            <DisplayOnMobile>
              <SubmitButton
                pristine={pristine}
                submitting={submitting}
                invalid={invalid}
                caption={savePasswordCaption}
              />
            </DisplayOnMobile>
          </ContentWrapper>
        </form>
      )}
    />
  );
};

export default connect(null, { changePassword })(PasswordResetForm);
