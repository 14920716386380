const routeUrls = {
  HOME: '/',
  NEW_ORDER_FORM: '/new-order/create',
  NEW_ORDER_ERROR: '/new-order/error',
  NEW_ORDER_FAILED: '/new-order/failed',
  NEW_ORDER_CONFIRMATION: '/new-order/confirm',
  NEW_ORDER_PREPARING: '/new-order/:orderId/preparing',
  NEW_ORDER_PAYMENT_STATUS: '/new-order/:orderId/payment-status',

  AUTH: '/auth',
  AUTH_APPLE: '/auth/apple',
  AUTH_APPLE_CONFIRM: '/auth/apple/confirm',
  AUTH_APPLE_ERROR: '/auth/apple/error',
  AUTH_LOGIN: '/auth/login',
  AUTH_REGISTER: '/auth/register',
  MENU: '/menu/:diet/:variant',
  USER_DIET: '/my-diet/calendar',
  MENU_PLANNING: '/my-diet/menu-planning/:day/:dietId/:bagId?',
  MONEY_BOX: '/money-box',
  MY_ORDERS: '/my-orders',
  SHARE_PROGRAM: '/share-program',
  LOGIN_AS_USER: '/auth/switch',
  RESET_PASSWORD: '/auth/set-new-password/:token',
  REMIND_PASSWORD: '/auth/remind-password',
  CREATE_PASSWORD: '/create-password',
  ACCOUNT_SETTINGS: '/account-settings',
  RESET_PASSWORD_EMAIL_SENT: '/auth/email-sent',
  CARDS: '/frontend/secure/cards',
};

export default routeUrls;
