import styled, { css } from 'styled-components';

import { fromPxToRem, media } from 'styledComponents/helpers';

const Badge = styled.span`
  display: inline-block;
  vertical-align: middle;
  padding: ${props =>
    (props.isSmall && '1px 3px') || (props.isBig && '2px 10px') || '3px 7px'};
  border-radius: 4px;
  font-size: ${props =>
    (props.isSmall && fromPxToRem('10px')) ||
    (props.isBig && fromPxToRem('24px')) ||
    fromPxToRem('16px')};
  font-weight: ${props =>
    (props.isSmall && props.theme.fontWeight500) || props.theme.fontWeight900};

  ${props =>
    props.isBigDesktop &&
    css`
      padding: 2px 10px;
      font-size: ${fromPxToRem('24px')};

      ${media.tabletDown`
      padding: 3px 7px;
      font-size: ${fromPxToRem('16px')};
    `};
    `};

  ${props =>
    props.isSmallMobile &&
    css`
      ${media.tabletDown`
      padding: 1px 3px;
      font-size: ${fromPxToRem('10px')};
      font-weight: ${props => props.theme.fontWeight500};
    `};
    `};
`;

export const BadgeBlue = styled(Badge)`
  border: 1px solid ${props => props.theme.colorBlue};
  color: ${props => props.theme.colorBlue};
`;

export const BadgeGreen = styled(Badge)`
  border: 1px solid ${props => props.theme.baseFontColor};
  color: ${props => props.theme.baseFontColor};
`;

export const BadgeSecondary = styled.span`
  margin-top: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  border: ${props =>
    props.noBorder ? 'none' : `1px solid ${props.theme.colorIceBlue2}`};
  background-color: ${props => props.theme.colorWhite};
  font-weight: ${props => props.theme.fontWeight500};
  color: ${props => props.theme.baseFontColor};
  white-space: nowrap;
`;

export const BadgesGroup = styled.div`
  > ${BadgeSecondary} {
    margin-right: 12px;
  }
`;
