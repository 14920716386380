import { get, put } from 'utils/http';

import { URLS } from 'configuration';

import {
  FETCH_ORDERS,
  START_LOADING,
  STOP_LOADING,
  UPDATE_ORDERS,
} from './const';

export const fetchOrders = (
  pagination = false,
  itemsPerPage,
  page
) => dispatch => {
  dispatch({
    type: START_LOADING,
  });
  get(
    URLS.MY_ORDERS,
    { partial: true, pagination, itemsPerPage, page, 'order[id]': 'DESC' },
    true
  ).then(({ data }) => {
    dispatch({
      type: STOP_LOADING,
    });
    return dispatch({
      type: FETCH_ORDERS,
      orders: data,
      allOrdersFetched: data.length < itemsPerPage,
    });
  });
};

export const fetchAllOrders = (
  pagination = false,
  itemsPerPage,
  page
) => dispatch => {
  dispatch({
    type: START_LOADING,
  });

  get(
    URLS.MY_ORDERS,
    { partial: false, pagination, itemsPerPage, page, 'order[id]': 'DESC' },
    true
  ).then(({ data }) => {
    dispatch({
      type: STOP_LOADING,
    });
    return dispatch({
      type: FETCH_ORDERS,
      orders: data,
      allOrdersFetched: true,
    });
  });
};

export const updateOrders = (
  pagination = false,
  itemsPerPage,
  page
) => dispatch => {
  dispatch({
    type: START_LOADING,
  });
  get(
    URLS.MY_ORDERS,
    { partial: true, pagination, itemsPerPage, page, 'order[id]': 'DESC' },
    true
  ).then(({ data }) => {
    dispatch({
      type: STOP_LOADING,
    });
    return dispatch({
      type: UPDATE_ORDERS,
      orders: data,
      allOrdersFetched: data.length < itemsPerPage,
    });
  });
};

export const cancelOrder = id => dispatch =>
  put(`${URLS.ORDERS}/${id}/cancel`, {}, true).then(() =>
    dispatch(fetchOrders(true, 30, 1))
  );
