import React, { Fragment } from 'react';
import moment from 'moment';
import 'moment/locale/pl';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { Box } from '@rebass/grid';
import { format } from 'date-fns';

import Modal from 'common/components/Modal';
import Spacer from 'styledComponents/elements/Spacer';

import { fromPxToRem } from 'styledComponents/helpers';
import HorizontalLine from 'styledComponents/elements/HorizontalLine';
import { useTranslation } from 'react-i18next';

const modificationsTranslations = t => ({
  date: t('$*myOrders.orderDetailsModal.date', '$$Data'),
  address: t('$*myOrders.orderDetailsModal.address', '$$Adres'),
  nullText: t('$*myOrders.orderDetailsModal.none', '$$brak'),
  variant: t('$*myOrders.orderDetailsModal.variant', '$$Wariant'),
  diet: t('$*myOrders.orderDetailsModal.typeDiet', '$$Rodzaj diety'),
  calorific: t('$*myOrders.orderDetailsModal.calorific', '$$Kaloryczność'),
  pickUpPoint: t(
    '$*myOrders.orderDetailsModal.deliveryPoint',
    '$$Punkt odbioru'
  ),
  useEcoContainers: t(
    '$*myOrders.orderDetailsModal.ecoContainers',
    '$$Eko opakowania'
  ),
});

const OrderDetailLabel = styled.h5`
  color: ${props => props.theme.baseFontColor};
  font-size: ${fromPxToRem('16px')};
  line-height: 1.38rem;
  margin-bottom: 5px;
  font-weight: ${props => (props.bold ? 900 : 500)};
`;

const OrderDetailValue = styled.h6`
  color: ${props => props.theme.colorDarkGrey};
  font-size: ${fromPxToRem('12px')};
  line-height: 1.38rem;
`;

const OrderDetailsList = styled.div`
  ${HorizontalLine}:last-child {
    display: none;
  }
`;

const V2ItemTypes = [
  'OrderItemDayDish',
  'OrderItemDayAddon',
  'OrderItemDayDelivery',
  'OrderItemDayModification',
  'OrderItemDiet',
];

const OrderDetailsModal = ({
  id,
  data,
  orderId,
  isOpened,
  toggleModal,
  subscriptionAtDay,
}) => {
  const { t } = useTranslation();

  const isV2Order = !isEmpty(
    data.find(({ type }) => V2ItemTypes.includes(type))
  );
  const isSubscriptionRenew = data.some(order => order.type === 'BuyBag');
  const isNewSubscription = data.some(
    order => order.type === 'DietItem' && !!order?.diet?.subscription
  );

  const modalTitle = (
    <>
      <h4>{`${t('$*orderFormTabs.orderDetails', 'Szczegóły zamówienia')}`}</h4>
      <h4>{`#${orderId}`}</h4>
      <h5 style={{ fontSize: '1rem' }}>
        {(() => {
          if (isNewSubscription) {
            return `(${t(
              '$*myOrders.orderDetailsModal.newSubscription',
              'Nowa subskrypcja'
            )})`;
          }
          if (isSubscriptionRenew) {
            return `(${t(
              '$*myOrders.orderDetailsModal.renewSubscription',
              'Odnowienie subskrypcji'
            )})`;
          }
          return '';
        })()}
      </h5>
    </>
  );

  return (
    <Modal
      id={id}
      title={modalTitle}
      isOpened={isOpened}
      toggleModal={toggleModal}
      contentPadding="15px 30px 0"
    >
      {isV2Order ? (
        t('$*common.v2Element', '$Ten element pochodzi z panelu v2')
      ) : (
        <OrderDetailsList>
          {data.map(order => {
            if (order.type === 'DietItem') {
              const { addons = [] } = order.diet;
              const addonsDiet = addons.filter(
                ({ type }) => type === 'FOR_DIET'
              );
              const addonsBag = addons.filter(({ type }) => type === 'FOR_BAG');

              return (
                <Fragment key={order.id}>
                  <Box my={15}>
                    <OrderDetailLabel>
                      {t('$*common.diet', 'Dieta')} #{order.diet.id}
                    </OrderDetailLabel>
                    <OrderDetailValue>
                      {t('$*orderFormTabs.dietKind', 'Rodzaj diety')}:{' '}
                      {order.diet.diet.name}
                    </OrderDetailValue>
                    <OrderDetailValue>
                      {t('$*orderFormTabs.calorific', 'Kaloryczność')}:{' '}
                      {order.diet.calorific.name}
                    </OrderDetailValue>
                    <OrderDetailValue>
                      {t('$*orderFormTabs.variant', 'Wariant')}:{' '}
                      {order.diet.variant.name}
                    </OrderDetailValue>
                    <OrderDetailValue>
                      {t(
                        '$*orderFormTabs.daysPurchased',
                        'Liczba wykupionych dni'
                      )}
                      : {order.diet.dietLength}
                    </OrderDetailValue>

                    {addons.length > 0 && (
                      <Fragment>
                        <OrderDetailValue>
                          {t('$*orderFormTabs.addons', 'Dodatki')}:
                        </OrderDetailValue>

                        <OrderDetailValue>
                          {t(
                            '$*orderFormTabs.forDietOnly',
                            'Do tylko pierwszej dostawy'
                          )}
                          :
                          {addonsDiet.length > 0
                            ? addonsDiet.map(
                                ({ quantity, addon: { name } }) => (
                                  <div>{`${quantity} x ${name}`}</div>
                                )
                              )
                            : ' -'}
                        </OrderDetailValue>

                        <OrderDetailValue>
                          {t('$*orderFormTabs.forBag', 'Do każdej dostawy')}:
                          {addonsBag.length > 0
                            ? addonsBag.map(({ quantity, addon: { name } }) => (
                                <div>{`${quantity} x ${name}`}</div>
                              ))
                            : ' -'}
                        </OrderDetailValue>
                      </Fragment>
                    )}
                  </Box>
                  <HorizontalLine marginLeft="-30px" marginRight="-30px" />
                </Fragment>
              );
            } else if (order.type === 'BagModification') {
              const modifications = Object.keys(order.payload);

              return (
                <Fragment key={order.id}>
                  <Box my={15}>
                    {order.bag && (
                      <>
                        <OrderDetailLabel>{`${t('$*common.diet', 'Dieta')} #${
                          order.bag.clientDiet.id
                        }`}</OrderDetailLabel>
                        <OrderDetailValue>
                          {moment(order.bag.date).format('DD-MM-YYYY')}
                        </OrderDetailValue>
                      </>
                    )}
                    <Spacer height="16px" />
                    {modifications.map((modification, index) => {
                      const getDisplayValue = (field, payload) => {
                        if (payload === null) {
                          return t('$*common.diet', 'Dieta');
                        }
                        switch (field) {
                          case 'date':
                          case 'useEcoContainers':
                            return payload;
                          case 'address':
                            return `${payload.city} ${payload.street} ${payload.buildNumber} ${payload.postCode}`;
                          case 'diet':
                          case 'calorific':
                          case 'variant':
                            return payload.name;
                          case 'pickUpPoint':
                            return payload.value;

                          default:
                            return 'Error';
                        }
                      };

                      const displayValueFrom = getDisplayValue(
                        modification,
                        order.payload[modification].from
                      );
                      const displayValueTo = getDisplayValue(
                        modification,
                        order.payload[modification].to
                      );

                      if (
                        modification === 'buyAddons' &&
                        modification.length > 0
                      ) {
                        return (
                          <Fragment>
                            <OrderDetailLabel>
                              {t(
                                '$*orderFormTabs.extraAddons',
                                'Dokupienie dodatków'
                              )}
                              :
                            </OrderDetailLabel>
                            <OrderDetailValue>
                              {order.payload[modification].map(
                                ({ quantity, addon: { name } }) => (
                                  <div>{`${quantity} x ${name}`}</div>
                                )
                              )}
                            </OrderDetailValue>
                          </Fragment>
                        );
                      }

                      if (displayValueFrom !== displayValueTo) {
                        return (
                          <Fragment key={index}>
                            <OrderDetailLabel>
                              {modificationsTranslations(t)[modification]
                                ? modificationsTranslations(t)[modification]
                                : modification}
                            </OrderDetailLabel>
                            {typeof displayValueFrom !== 'boolean' &&
                            typeof displayValueTo !== 'boolean' ? (
                              <OrderDetailValue>
                                <p>
                                  {t('$*common.from')}: {displayValueFrom}
                                </p>
                                <p>
                                  {t('$*common.to')}: {displayValueTo}
                                </p>
                              </OrderDetailValue>
                            ) : (
                              <OrderDetailValue>
                                {displayValueTo
                                  ? t('$*common.added')
                                  : t('$*common.subtracted')}
                              </OrderDetailValue>
                            )}
                            <Spacer height={'16px'} />
                          </Fragment>
                        );
                      }

                      return null;
                    })}
                  </Box>
                  <HorizontalLine marginLeft="-30px" marginRight="-30px" />
                </Fragment>
              );
            } else if (order.type === 'BuyBag') {
              const { bags } = order.payload;
              const renderBags = bags.map((bag, index) => {
                const { addons = [] } = bag;
                const addonsDiet = addons.filter(
                  ({ type }) => type === 'FOR_DIET'
                );
                const addonsBag = addons.filter(
                  ({ type }) => type === 'FOR_BAG'
                );

                return (
                  <Fragment key={index}>
                    <OrderDetailValue>
                      {t('$*myOrders.orderDetailsModal.date')}:{' '}
                      {format(subscriptionAtDay, 'DD-MM-YYYY')}
                    </OrderDetailValue>
                    <OrderDetailValue>
                      {t('$*orderFormTabs.dietKind', 'Rodzaj diety')}:{' '}
                      {bag.diet.name}
                    </OrderDetailValue>
                    <OrderDetailValue>
                      {t('$*orderFormTabs.calorific', 'Kaloryczność')}:{' '}
                      {bag.calorific.name}
                    </OrderDetailValue>
                    <OrderDetailValue>
                      {t('$*orderFormTabs.variant', 'Wariant')}:{' '}
                      {bag.variant.name}
                    </OrderDetailValue>
                    {addons.length > 0 && (
                      <Fragment>
                        <OrderDetailValue>
                          {t('$*orderFormTabs.addons', 'Dodatki')}:
                        </OrderDetailValue>

                        <OrderDetailValue>
                          {t(
                            '$*orderFormTabs.forDietOnly',
                            'Do tylko pierwszej dostawy'
                          )}
                          :
                          {addonsDiet.length > 0
                            ? addonsDiet.map(
                                ({ quantity, addon: { name } }) => (
                                  <div>{`${quantity} x ${name}`}</div>
                                )
                              )
                            : ' -'}
                        </OrderDetailValue>

                        <OrderDetailValue>
                          {t('$*orderFormTabs.forBag', 'Do każdej dostawy')}:
                          {addonsBag.length > 0
                            ? addonsBag.map(({ quantity, addon: { name } }) => (
                                <div>{`${quantity} x ${name}`}</div>
                              ))
                            : ' -'}
                        </OrderDetailValue>
                      </Fragment>
                    )}
                  </Fragment>
                );
              });
              const clientDietID =
                order?.payload?.bags[0]?.clientDiet['@id']?.replace(
                  '/ecommerce-diets/',
                  ''
                ) || '';
              return (
                <Fragment key={order.id}>
                  <Box my={15}>
                    <OrderDetailLabel>
                      {t('$*common.diet', 'Dieta')} #{clientDietID}
                    </OrderDetailLabel>
                    {renderBags}

                    <HorizontalLine marginLeft="-30px" marginRight="-30px" />
                  </Box>
                </Fragment>
              );
            }

            return null;
          })}
        </OrderDetailsList>
      )}
    </Modal>
  );
};

export default OrderDetailsModal;
