import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Box } from '@rebass/grid';
import { Form } from 'react-final-form';
import { compose, lifecycle } from 'recompose';
import { useSelector } from 'react-redux';

import { setPaymentMetadata } from 'views/NewOrder/actions/orderFormActions';
import { setNextTabDisability } from 'views/NewOrder/components/OrderFormTabs/actions/orderFormTabsActions';
import { useTranslation } from 'react-i18next';
import FORM_FIELD_NAMES from '../../const/formFieldNames';
import { get } from 'utils/http';
import { URLS } from 'configuration';
import PayPoFormContent from './PayPoFormContent';

const payPoFormFields = [
  FORM_FIELD_NAMES.ADDRESS_POST_CODE,
  FORM_FIELD_NAMES.ADDRESS_CITY,
  FORM_FIELD_NAMES.ADDRESS_STREET,
  FORM_FIELD_NAMES.ADDRESS_BUILD_NUMBER,
  FORM_FIELD_NAMES.ADDRESS_PLACE_NUMBER,
];

const SummaryPayPoInfo = ({ setPaymentMetadata }) => {
  const { t } = useTranslation();

  const token = useSelector(({ auth: { token } }) => token);
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setIninitalValues] = useState(
    payPoFormFields.reduce((acc, field) => {
      return {
        ...acc,
        [field]: '',
      };
    }, {})
  );

  useEffect(() => {
    if (token !== null) {
      setIsLoading(true);
      get(URLS.PAYPO_ADDRESS_METADATA)
        .then(({ data }) => {
          const filteredFields = Object.entries(data).reduce(
            (acc, [key, value]) => {
              const isInArray = payPoFormFields.includes(key);

              return {
                ...acc,
                ...(isInArray ? { [key]: value } : {}),
              };
            },
            {}
          );

          setIninitalValues(filteredFields);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [token]);

  return (
    <Box width={[1, 1, 1 / 2]} ml="auto">
      <Form
        onSubmit={() => {}}
        initialValues={initialValues}
        render={() => {
          return (
            <PayPoFormContent
              childrenAboveForm={
                <div>
                  {t('$*summaryPayPoInfo.title', '$$Dane rozliczeniowe PayPo')}
                </div>
              }
              handleFormSpyChange={({ values, valid }) => {
                setPaymentMetadata({ data: values, valid });
              }}
              isLoadingPreviousPaymentData={isLoading}
            />
          );
        }}
      />
    </Box>
  );
};

export default compose(
  connect(
    ({ orderForm }) => ({
      orderForm,
    }),
    {
      setNextTabDisability,
      setPaymentMetadata,
    }
  ),
  lifecycle({
    componentDidMount() {
      const {
        setNextTabDisability,
        orderForm: {
          isInvoiceIncluded,
          isInvoiceDataValid,
          paymentMethodId,
          paymentMetadataIsValid,
        },
      } = this.props;

      const validate = [
        isInvoiceIncluded && !isInvoiceDataValid,
        paymentMethodId === 'PAYPO' ? !paymentMetadataIsValid : false,
      ];

      setNextTabDisability(validate.includes(true));
    },
    componentDidUpdate() {
      const {
        setNextTabDisability,
        orderForm: {
          isInvoiceIncluded,
          isInvoiceDataValid,
          paymentMethodId,
          paymentMetadataIsValid,
        },
      } = this.props;

      const validate = [
        isInvoiceIncluded && !isInvoiceDataValid,
        paymentMethodId === 'PAYPO' ? !paymentMetadataIsValid : false,
      ];

      setNextTabDisability(validate.includes(true));
    },
  })
)(SummaryPayPoInfo);
