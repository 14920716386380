import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Flex, Box } from '@rebass/grid';
import styled from 'styled-components';

import FormCheckbox from 'common/components/Form/FormCheckbox';
import { setInvoiceIncluded } from 'views/NewOrder/actions/orderFormActions';
import { setNextTabDisability } from 'views/NewOrder/components/OrderFormTabs/actions/orderFormTabsActions';

import { fromPxToRem } from 'styledComponents/helpers';
import { Paragraph } from 'styledComponents/elements/Typography';

import { useTranslation } from 'react-i18next';
import { PRICES_CONFIG } from 'views/NewOrder/components/summary/const';
import { INTERGRATION_TYPE } from '../../const/fakturownia';
import produce from 'immer';

const DiscountPrice = styled.span`
  color: ${({ theme }) => theme.colorRed};
  padding: 0 5px;
  text-decoration: line-through;
`;

const NormalPrice = styled.span`
  font-size: ${fromPxToRem('20px')};
  font-weight: ${({ theme }) => theme.fontWeight900};
  color: ${({ theme }) => theme.baseFontColor};
  padding: 0 5px;
`;

const SummaryTotalPrice = ({
  totalPrice,
  pointsForOrder,
  isInvoiceIncluded,
  setInvoiceIncluded,
  setNextTabDisability,
  moneyBoxConfigurationNames,
  multinational,
  paymentMode,
  forms,
  dietCosts,
  usedMoneyBoxPointsAsPrice,
  moduleFakturownia,
}) => {
  const { t } = useTranslation();
  const { currencySymbol } = multinational;
  const [dietCost] = dietCosts;

  const prices = PRICES_CONFIG({
    t,
    paymentMode,
    dietCost,
    addons: forms[0].addons,
    deliveryType: forms[0].deliveryType,
    dietLength: forms[0].dietLength,
  });

  const pricePerDay = prices.find(({ key }) => key === 'pricePerDay');
  const priceFirstDay = prices.find(({ key }) => key === 'priceFirstDay');
  const priceOtherLimitDays = prices.find(
    ({ key }) => key === 'priceOtherLimitDay'
  );
  const priceOtherDays = prices.find(({ key }) => key === 'priceOtherDays');
  const firstPayment = priceFirstDay || pricePerDay || null;
  const draftRestPayment = priceOtherDays || { ...firstPayment };
  const restPayment = produce(draftRestPayment, draftState => {
    if (
      'CODE' in dietCost.usedDiscounts &&
      dietCost.subscriptionDiscountLimit === null
    ) {
      draftState.afterDiscount += dietCost.usedDiscounts['CODE']?.discount ?? 0;
    }
  });

  const { integrationType, allowInvoicesForClient } = moduleFakturownia;
  const integrationTypeIsReceipt =
    integrationType === INTERGRATION_TYPE.RECEIPT;

  return (
    <Flex
      flexWrap="wrap"
      alignItems="center"
      justifyContent={['space-beetwen', 'space-between', 'flex-end']}
      style={{ margin: '-5px' }}
    >
      <Box width={[1, 1, 0.7]} style={{ padding: '5px' }}>
        {allowInvoicesForClient && (
          <Flex alignItems="center">
            <Box>
              {!integrationTypeIsReceipt &&
                t(
                  '$*summaryTotalPrice.caption.invoice',
                  '$$Wystaw fakturę na firmę'
                )}
              {integrationTypeIsReceipt &&
                t(
                  '$*summaryTotalPrice.caption.receipt',
                  '$$Chcę otrzymać fakturę'
                )}
            </Box>
            <Box ml={15}>
              <FormCheckbox
                componentStyle="switch"
                input={{
                  checked: isInvoiceIncluded,
                  onChange: () => {
                    setInvoiceIncluded(!isInvoiceIncluded);
                    setNextTabDisability(!isInvoiceIncluded);
                  },
                }}
              />
            </Box>
          </Flex>
        )}
        {!integrationTypeIsReceipt &&
          isInvoiceIncluded &&
          allowInvoicesForClient && (
            <Paragraph>
              {t(
                '$*summaryTotalPrice.invoiceIncludedNotice',
                '$$Fakturę pobierzesz z zakładki „Historia zamówień” '
              )}
            </Paragraph>
          )}
      </Box>
      <Box width={[1, 1, 0.3]} style={{ padding: '5px' }}>
        {paymentMode === '2' ? (
          <Flex justifyContent="space-between" alignItems="center">
            <Box>{t('$*summaryTotalPrice.toPay', '$$Do zapłaty')}:</Box>

            <Flex alignItems="center">
              {totalPrice.beforeUsePoint !== totalPrice.afterUsePoint && (
                <DiscountPrice>
                  {totalPrice.beforeUsePoint.toFixed(2)}
                </DiscountPrice>
              )}
              <NormalPrice>
                {totalPrice.afterUsePoint.toFixed(2)} {currencySymbol}
              </NormalPrice>
            </Flex>
          </Flex>
        ) : (
          <Fragment>
            <Flex justifyContent="space-between" alignItems="center">
              <Box>
                {t(
                  '$*summaryTotalPrice.firstPayment',
                  '$$Pierwsza płatność (teraz)'
                )}
                :
              </Box>

              <Flex alignItems="center">
                {firstPayment?.beforeDiscount > firstPayment?.afterDiscount && (
                  <DiscountPrice>
                    {firstPayment?.beforeDiscount?.toFixed(2)}
                  </DiscountPrice>
                )}
                <NormalPrice>
                  {(
                    firstPayment?.afterDiscount - usedMoneyBoxPointsAsPrice
                  )?.toFixed(2)}{' '}
                  {currencySymbol}
                </NormalPrice>
              </Flex>
            </Flex>

            {dietCost.subscriptionDiscountLimit > 0 && (
              <Flex justifyContent="space-between" alignItems="center">
                <Box>
                  {t('$*summaryTotalPrice.restSubsciptionLimitPayment', {
                    replace: { count: dietCost.subscriptionDiscountLimit },
                  })}
                  :
                </Box>

                {priceOtherLimitDays ? (
                  <Flex alignItems="center">
                    {priceOtherLimitDays?.beforeDiscount >
                      priceOtherLimitDays?.afterDiscount && (
                      <DiscountPrice>
                        {priceOtherLimitDays?.beforeDiscount?.toFixed(2)}
                      </DiscountPrice>
                    )}
                    <NormalPrice>
                      {(
                        priceOtherLimitDays?.afterDiscount -
                        usedMoneyBoxPointsAsPrice
                      )?.toFixed(2)}{' '}
                      {currencySymbol}
                    </NormalPrice>
                  </Flex>
                ) : (
                  <Flex alignItems="center">
                    {firstPayment?.beforeDiscount >
                      firstPayment?.afterDiscount && (
                      <DiscountPrice>
                        {firstPayment?.beforeDiscount?.toFixed(2)}
                      </DiscountPrice>
                    )}
                    <NormalPrice>
                      {(
                        firstPayment?.afterDiscount - usedMoneyBoxPointsAsPrice
                      )?.toFixed(2)}{' '}
                      {currencySymbol}
                    </NormalPrice>
                  </Flex>
                )}
              </Flex>
            )}

            <Flex justifyContent="space-between" alignItems="center">
              <Box>
                {t('$*summaryTotalPrice.restPayment', '$$Kolejne płatności')}:
              </Box>

              <Flex alignItems="center">
                {restPayment?.beforeDiscount < restPayment?.afterDiscount && (
                  <DiscountPrice>
                    {restPayment?.beforeDiscount?.toFixed(2)}
                  </DiscountPrice>
                )}
                <NormalPrice>
                  {dietCost.subscription?.afterDiscount.toFixed(2)}{' '}
                  {currencySymbol}
                </NormalPrice>
              </Flex>
            </Flex>
          </Fragment>
        )}

        {pointsForOrder > 0 && (
          <Flex justifyContent="space-between" alignItems="center">
            <Box>
              {t('$*summaryTotalPrice.savingSummary', {
                defaultValue: '$$Zyskasz {{points}}',
                replace: {
                  points:
                    moneyBoxConfigurationNames.pluralNameGenitive ||
                    t('$*common.pointsLiteral', 'Punkty'),
                },
              })}
              :
            </Box>

            <Box>
              <NormalPrice>
                {pointsForOrder}
                {`${
                  moneyBoxConfigurationNames.shortName ||
                  t('$*common.pointAbbreviation', '$$pkt')
                }`}
              </NormalPrice>
            </Box>
          </Flex>
        )}
      </Box>
    </Flex>
  );
};

export default connect(
  ({
    dietForm: { paymentMode, forms },
    orderForm: {
      isInvoiceIncluded,
      invoiceDataType,
      isInvoiceDataValid,
      calculations: { dietCosts, usedMoneyBoxPointsAsPrice },
    },
    app: {
      brand: { moneyBoxConfigurationNames, allowInvoicesForClient },
      config: {
        multinational,
        modules: { Fakturownia },
      },
    },
  }) => ({
    dietCosts,
    isInvoiceIncluded,
    invoiceDataType,
    isInvoiceDataValid,
    moneyBoxConfigurationNames,
    allowInvoicesForClient,
    multinational,
    paymentMode,
    usedMoneyBoxPointsAsPrice,
    forms,
    moduleFakturownia: Fakturownia || {},
  }),
  {
    setInvoiceIncluded,
    setNextTabDisability,
  }
)(SummaryTotalPrice);
