import { keyframes } from 'styled-components';

export default keyframes`
  0% {
    transform: translateX(-480px);
  }
  100% {
    transform: translateX(0);
  }
`;
