import styled from 'styled-components';
import { darken, lighten } from 'polished';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 550px) {
    flex: 0 0 calc(100% - 10px);
  }
  @media (min-width: 550px) and (max-width: 650px) {
    flex: 0 0 calc(50% - 10px);
  }
  @media (max-width: 650px) {
    border: 1px solid;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 5px;
    position: relative;
    flex-direction: column;
  }
  @media (min-width: 650px) {
    margin: 5px 0;
    justify-content: space-between;
  }
`;

export const LeftCol = styled.div`
  @media (min-width: 650px) {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
  }
`;

export const RightCol = styled.div`
  display: flex;

  @media (max-width: 650px) {
    flex-direction: column;
    margin-top: auto;
  }

  @media (min-width: 650px) {
    align-items: center;
    flex: 0 0 auto;
    margin: 0 -10px;
  }
`;

export const ImageWrapper = styled.div`
  flex: 0 0 50px;
  width: 50px;
  height: 50px;
  ${({ theme }) => `border: 1px solid ${theme.colorIceBlue2}`};
  border-radius: 5px;

  @media (max-width: 650px) {
    margin: 0 auto;
  }
`;

export const Image = styled.div`
  ${({ image }) => `background: url(${image}) no-repeat center center`};
  display: block;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  background-size: cover;
`;

export const ContentWrapper = styled.div`
  margin: 10px 15px;

  @media (min-width: 650px) {
    margin: 0 15px;
  }
`;

export const Name = styled.div`
  @media (max-width: 650px) {
    text-align: center;
  }
`;

export const InputNumber = styled.div`
  @media (max-width: 650px) {
    order: 2;
  }

  display: flex;
  align-items: center;
  margin: 0 10px;
`;

InputNumber.Button = styled.button`
  width: 25px;
  height: 25px;
  ${({ theme: { baseFontColor } }) => `
    border: 1px solid ${baseFontColor};
  `};
  border-radius: 5px;
  background: no-repeat;
  cursor: pointer;
  outline: none;
  transition: ease 0.3s;

  &:hover {
    ${({ theme: { baseFontColor, colorWhite } }) =>
      `
      background: ${baseFontColor};
      color: ${colorWhite};
    `}
    transition: ease 0.3s;
  }

  &:focus {
    ${({ theme: { baseFontColor } }) =>
      `outline-color: ${darken(0.2, baseFontColor)};`};
  }
`;

InputNumber.Text = styled.span`
  margin: 0 5px;
  min-width: 25px;
  text-align: center;
`;

export const Price = styled.div`
  @media (max-width: 650px) {
    order: 1;
  }
  margin: 0 10px;
  min-width: 60px;
  text-align: center;
`;

export const RemoveButtonWrapper = styled.div`
  margin: 0 10px;
  @media (max-width: 650px) {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
  }
`;

export const RemoveButton = styled.button`
  ${({ theme: { colorRustyRed, colorWhite } }) => `
    background: ${colorRustyRed};
    color: ${colorWhite};

    &:hover {
      background: ${lighten(0.1, colorRustyRed)};
      transition: ease 0.3s;
    }
  `}
  width: 25px;
  height: 25px;
  border-radius: 5px;
  cursor: pointer;
  transition: ease 0.3s;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  @media (max-width: 650px) {
    border-radius: 0 4px 0 5px;
  }
`;
