import React from 'react';
import { useSelector } from 'react-redux';

import imagePlaceholder from 'assets/images/image_placeholder.jpg';
import { Wrapper, ImageWrapper, Image, Name } from './AddonDetails.styled';
import { useTranslation } from 'react-i18next';

const AddonDetails = () => {
  const { t } = useTranslation();
  const bagIri = useSelector(({ menuPlanning }) => menuPlanning.bag['@id']);
  const addons = useSelector(({ menuPlanning }) => menuPlanning.bag.addons);
  const extendDiet = useSelector(
    ({
      app: {
        config: { modules },
      },
    }) => modules.Addons.extendDiet
  );
  const bagAddonsLegnth =
    useSelector(
      ({ basket }) =>
        basket.bags.find(({ bag }) => bag === bagIri)?.addons?.length
    ) || 0;

  return (
    <div style={{ maxWidth: '100%' }}>
      {addons.length === 0 && (
        <div>{t('$*addonDetails.noAddons', 'Brak zamówionych dodatków.')}</div>
      )}
      {addons.length === 0 && bagAddonsLegnth === 0 && extendDiet && (
        <div>
          {t(
            '$*addonDetails.youNotUseDiscount',
            'Jeszcze nie skorzystałeś z okazji?'
          )}
        </div>
      )}
      {addons.length > 0 &&
        addons.map(({ addon: { image, name }, quantity }) => {
          return (
            <Wrapper>
              <ImageWrapper>
                <Image image={image || imagePlaceholder} />
              </ImageWrapper>
              <Name>{`${quantity}\xa0x\xa0${name}`}</Name>
            </Wrapper>
          );
        })}
    </div>
  );
};

export default AddonDetails;
