import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Picture from './Picture';

const Logo = ({ logoLink, isSmall, onLoadLogo }) => {
  if (logoLink === window.origin) {
    return (
      <Link to={logoLink}>
        <Picture isSmall={isSmall} onLoadLogo={onLoadLogo} />
      </Link>
    );
  }
  return (
    <a href={logoLink}>
      <Picture isSmall={isSmall} onLoadLogo={onLoadLogo} />
    </a>
  );
};

export default connect(({ app: { brand: { logoLink } } }) => ({
  logoLink,
}))(Logo);
