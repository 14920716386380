import { createContext, useContext, useEffect, useState } from 'react';

import { useToggle } from 'hooks';
import { checkImageExists } from 'utils/common';

const DishTileContext = createContext([{}, () => {}]);

const DishTileProvider = ({ children }) => {
  const showImage = true;
  const [isOpenMenu, toggleOpenMenu] = useToggle(false);
  const [dish, setDish] = useState({});
  const [isExistImage, setIsExistImage] = useState(null);

  useEffect(() => {
    checkImageExists(dish.clientImage).then(setIsExistImage);
  }, [dish]);

  const value = {
    isExistImage,
    isOpenMenu,
    dish,
    setDish,
    showImage,
    toggleOpenMenu,
  };

  return (
    <DishTileContext.Provider value={value}>
      {children}
    </DishTileContext.Provider>
  );
};

const useDishTileContext = () => useContext(DishTileContext);

export { DishTileProvider, useDishTileContext };
