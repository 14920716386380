import React from 'react';
import { DisplayOnDesktop, DisplayOnMobile } from 'styledComponents/helpers';
import Modal from 'common/components/Modal';
import Tooltip from 'common/components/Tooltip';
import {
  dateISOStringToPolishTransformed,
  dateISOStringToTime,
} from 'common/services/dateHelpers';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { connect } from 'react-redux';

const TitleTooltip = ({ deadlineBrandKey, bag, isEditable, t }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const changeDeadLineDate = bag[deadlineBrandKey];

  const toolTipText = isEditable
    ? t(
        '$*menuPlanning.changesAvailableToTooltip',
        '$$Zmian możesz dokonać do:'
      )
    : t(
        '$*menuPlanning.timeForChangeExceededTooltip',
        '$$Czas na zmiany minął:'
      );

  return (
    <div>
      <DisplayOnDesktop>
        <i
          className="fas fa-exclamation-circle"
          data-for={deadlineBrandKey}
          data-tip={deadlineBrandKey}
          style={{ cursor: 'pointer' }}
        />
      </DisplayOnDesktop>
      <DisplayOnMobile>
        <i
          className="fas fa-exclamation-circle"
          style={{ cursor: 'pointer' }}
          onClick={() =>
            setIsModalOpen(!isModalOpen ? 'mobileDeadlineModal' : false)
          }
        />
      </DisplayOnMobile>
      <DisplayOnMobile>
        <Modal
          id={'mobileDeadlineModal'}
          title={''}
          isOpened={isModalOpen}
          toggleModal={() => {
            setIsModalOpen(!isModalOpen ? 'mobileDeadlineModal' : false);
          }}
          withBackButton
          widthDesktop="620px"
          widthMobile="300px"
          contentPadding="0 20px"
          contentPaddingMobile="0 5px"
        >
          <div style={{ textAlign: 'center' }}>
            {toolTipText}
            <br />
            {dateISOStringToPolishTransformed(changeDeadLineDate)}
            <br />
            {t('$*menuPlanning.toHourLabel', '$$do godziny')}
            {': '}
            {dateISOStringToTime(changeDeadLineDate)}
          </div>
        </Modal>
      </DisplayOnMobile>
      <DisplayOnDesktop>
        <Tooltip
          style={{ textTransform: 'none' }}
          id={deadlineBrandKey}
          hide={false}
          maxWidth="250px"
        >
          {toolTipText}
          <br />
          {dateISOStringToPolishTransformed(changeDeadLineDate)}
          <br />
          {t('$*menuPlanning.toHourLabel', '$$do godziny')}
          {': '}
          {dateISOStringToTime(changeDeadLineDate)}
        </Tooltip>
      </DisplayOnDesktop>
    </div>
  );
};

export default compose(
  connect(
    ({ menuPlanning: { calendar, selectedDay, bag }, app: { brand } }) => ({
      bag,
      brand,
    }),
    null
  ),
  withTranslation()
)(TitleTooltip);
