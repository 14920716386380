import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import StandaloneSelectBox from 'common/components/StandaloneSelectBox';
import { Box, Flex } from '@rebass/grid';
import { media } from 'styledComponents/helpers';
import { Heading } from 'styledComponents/elements/Typography';

import {
  getDietsListOptions,
  getInitialDietOption,
} from 'views/UserDiet/services/userDietRepresenter';

import { setSelectedDiet, getUserDietsList } from '../actions/userDietActions';
import { useTranslation } from 'react-i18next';
import Modal from 'common/components/Modal';
import { Field, Form } from 'react-final-form';
import { ButtonPrimary } from 'styledComponents/elements/Button';
import FormTextInput from 'common/components/Form/FormTextInput';
import {
  getValidators,
  useFormValidation,
} from 'common/services/formValidation';
import { put } from 'utils/http';
import { URLS } from 'configuration';
import { buildUrl } from 'utils/route';

const StyledFlex = styled(Flex)`
  ${media.tabletDown`
    flex-direction: column-reverse;
  `}
`;

const StyledPencil = styled.i`
  color: ${props => props.theme.baseFontColor};
  cursor: pointer;
`;

const UserDietHeadingSection = ({
  showTitle = true,
  userDiets,
  selectedDiet,
  setSelectedDiet,
  getUserDietsList,
  modules,
}) => {
  const { t } = useTranslation();
  const dietsMissing = userDiets.length === 0;
  const dietsMissingMessage = t(
    '$*userDiet.dietsMissingMessage',
    '$$Brak zamówień'
  );
  const dropdownPlaceholder = t(
    '$*userDiet.chooseDietPlaceholder',
    '$$Wybierz dietę...'
  );

  const boxWidth = {
    left: showTitle ? [1, 1, 1, 1 / 5] : [1, 1, 0.7, 1 / 2],
    center: showTitle ? [1, 1, 1, 1 / 3] : [],
    right: showTitle ? [1, 1, 1 / 2, 1 / 5] : [1, 1, 0.3, 1 / 5],
  };

  const [isOpen, setIsOpen] = useState(null);

  const handleToggleDietNameModal = () =>
    setIsOpen(isOpen === null ? 'dietNameModal' : null);

  const currentDiet = userDiets.find(({ id }) => id === selectedDiet) ?? {};
  const validators = useFormValidation();

  const handleSubmit = ({ dietId, ...fields }) => {
    put(
      buildUrl(URLS.EDIT_DIET, {
        dietId,
      }),
      fields
    ).then(() => {
      getUserDietsList();
      handleToggleDietNameModal();
    });
  };

  return (
    <>
      <StyledFlex
        justifyContent={['center', 'center', 'flex-end', 'flex-end']}
        alignItems="center"
      >
        {showTitle && (
          <Box width={boxWidth.center} order={[1, 1, 2]}>
            <Heading noPaddingDesktop textAlign="center">
              {t('$*userDiet.title', '$$Moje konto')}
            </Heading>
          </Box>
        )}

        <Box style={{ zIndex: '2' }} width={boxWidth.right} order={[2, 2, 3]}>
          <Flex alignItems="center">
            <div style={{ flex: '1 1 auto' }}>
              <StandaloneSelectBox
                name="selectedDiet"
                options={getDietsListOptions(userDiets)}
                isDisabled={dietsMissing}
                placeholder={
                  dietsMissing ? dietsMissingMessage : dropdownPlaceholder
                }
                selected={getInitialDietOption(selectedDiet, userDiets)}
                noOptionsMessage={dietsMissingMessage}
                onChange={diet => {
                  const dietObject = userDiets.find(
                    userDiet => userDiet.id === diet
                  );
                  setSelectedDiet(diet, dietObject);
                }}
              />
            </div>
            {modules?.ConfigClientPanel?.allowClientOwnDietName && (
              <div
                onClick={handleToggleDietNameModal}
                style={{ flex: '0 0 auto', marginLeft: '8px' }}
              >
                <StyledPencil className="fas fa-edit" />
              </div>
            )}
          </Flex>
        </Box>
      </StyledFlex>

      <Modal
        id="dietNameModal"
        title={t('$*userDiet.dietNameModal.title', {
          defaultValue: '$$Edycja nazwy diety #{{dietId}}',
          replace: {
            dietId: currentDiet.id,
          },
        })}
        isOpened={isOpen}
        toggleModal={handleToggleDietNameModal}
        withBackButton
        widthDesktop="620px"
        widthMobile="90%"
        style={{ paddingBottom: 0 }}
      >
        <Form
          onSubmit={handleSubmit}
          initialValues={{ dietId: currentDiet.id, name: currentDiet.name }}
          render={({ handleSubmit, submitting, pristine, invalid }) => (
            <form onSubmit={handleSubmit}>
              <Field
                isBig
                name="name"
                type="text"
                component={FormTextInput}
                label={t('$*userDiet.form.dietName.label', '$$Nazwa diety')}
                isRequired={true}
                validate={getValidators([validators.required])}
                width="100%"
                maxLength={60}
              />

              <ButtonPrimary
                type="submit"
                uppercased
                sizeMiddle
                weightBold
                withSpinner
                disabled={submitting || pristine || invalid}
                fullWidthOnMobile
                style={{ marginTop: '40px' }}
              >
                {t('$*common.saveChangesCaption')}
              </ButtonPrimary>
            </form>
          )}
        />
      </Modal>
    </>
  );
};

export default connect(
  ({
    app: {
      config: { modules },
    },
    userDiet: { list: userDiets, selectedDiet },
  }) => ({
    selectedDiet,
    userDiets,
    modules,
  }),
  { setSelectedDiet, getUserDietsList }
)(UserDietHeadingSection);
