import { useEffect } from 'react';

import { useDishTileContext } from './DishTile.context';
import DishTileDesktop from './DishTileDesktop';

const DishTile = dish => {
  const { setDish } = useDishTileContext();

  useEffect(() => {
    setDish(dish);
  }, [dish]);

  return <DishTileDesktop />;
};

export default DishTile;
