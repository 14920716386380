export const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

export const URLS = {
  // BRANDS
  BRANDS: '/frontend/brand',

  // AUTH
  LOGIN: '/login_check',
  AUTH_LOGIN: '/auth/login',
  APPLE_LOGIN: '/frontend/auth/apple',
  FACEBOOK_LOGIN: '/frontend/auth/facebook',
  GOOGLE_LOGIN: '/frontend/auth/google',
  LOGOUT: '/logout',
  REFRESH_LOGIN: '/refresh_token',
  REGISTER: '/frontend/registration/form',
  CARDS: '/frontend/secure/cards',
  ALL_PAYMENT_CARDS: '/frontend/secure/payment-cards',

  // ORDER FORM
  CONFIG: '/frontend/config',
  PAYMENT_METHODS: '/frontend/payment-types',
  DELIVERY_PICKUP_POINTS: '/frontend/pick-up-points',
  DELIVERY_ADDRESSES: '/frontend/secure/addresses',
  DELIVERY_HOURS: '/frontend/delivery-hours',
  ORDER_COST: '/frontend/order-cost',
  VALIDATE_DISCOUNT_CODE: '/frontend/valid-discount-code',
  CREATE_ORDER: '/frontend/secure/order-create',
  CHECK_ORDER_STATUS: '/frontend/secure/orders/:orderId',
  PAYPO_ADDRESS_METADATA: '/frontend/secure/paypo-last-used-address',

  // ORDERS
  ORDERS: '/frontend/secure/orders',
  MY_ORDERS: '/frontend/secure/my-orders',

  // ACCOUNT SETTINGS
  CREATE_ADDRESS: '/frontend/secure/addresses/create',
  UPDATE_ADDRESS: '/frontend/secure',
  DELETE_ADDRESS: '/frontend/secure/addresses/:id',
  USER_DATA: '/frontend/secure/my-data', //GET & PUT
  CHANGE_PASSWORD: '/frontend/secure/change-password',
  DELETE_ACCOUNT: '/frontend/secure/remove-account',

  // MONEYBOX
  MONEY_BOX_HISTORY: '/frontend/secure/my-money-box-histories',

  // USER DIET
  USER_DIETS_LIST: '/frontend/secure/my-diets',
  GET_CALENDAR: '/frontend/secure/calendar/:dietId/:dateFrom/:dateTo',
  EDIT_DIET: '/frontend/secure/ecommerce-diets/:dietId',

  // MENU PLANNING
  CHANGE_MENU_OPTIONS_OLD: '/frontend/secure/change-menu-options',
  GET_BAG: '/frontend/secure/bags/:id',
  GET_INTENT_BAG:
    '/frontend/secure/ecommerce-diets/:dietId/delivery-days/:date',
  PLAN_MENU: '/frontend/secure/bags/:id/plan-menu',
  RATE_MENU: '/frontend/secure/bags/:id/rate',
  CHANGE_BAG: '/frontend/secure/bags/:id/change',
  CHANGE_NEEDS_PAY: '/frontend/secure/bags/:id/change-need-pay',
  CHANGE_MENU_OPTIONS: '/frontend/secure/bags/:id/change-menu-options',
  DEFAULT_SUBSCRIPTION_CONFIG: 'frontend/secure/subscriptions/:id',
  ORDER_ADDONS: '/frontend/secure/order-addons',
  SUBSCRIPTION_CONFIG: '/frontend/secure/subscriptions/:subscriptionId',
  CHANGE_SUBSCRIPTION_INTENT:
    '/frontend/secure/ecommerce-diets/:dietId/delivery-days',

  // PASSWORD REMINDING
  SEND_PASSWORD_RESET_EMAIL: '/frontend/lost-password',
  RESET_PASSWORD: '/frontend/change-password',

  // MEDIA
  UPLOADED_MEDIA: '/uploaded-media/',
};
