import {
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAILURE,
  CREATE_ORDER_RESPONSE,
  FETCH_ORDER_CONFIG_REQUEST,
  FETCH_ORDER_CONFIG_SUCCESS,
  FETCH_PAYMENT_METHODS,
  SET_CALORIFICS,
  SET_DIET_TYPE_DESC,
  SET_DELIVERY_PICKUP_POINTS,
  SET_DELIVERY_HOURS,
  SET_CALCULATIONS,
  SET_DISCOUNT,
  CLEAR_DISCOUNT,
  SET_DISCOUNT_FOR_SPECIFIED_DIETS,
  SET_PAYMENT_METHOD,
  SET_ORDER_STATUS,
  SET_USE_MONEY_BOX,
  SET_INVOICE_INCLUDED,
  SET_INVOICE_DATA_TYPE,
  SET_INVOICE_DATA,
  RESET_ORDER,
  SET_TEST_MODE,
  USER_PROCEED,
  SET_PACKAGE_DESC,
  SET_PAYMENT_METADATA,
} from '../actions/const';

const INITIAL_STATE = {
  orderConfig: {
    additionalPrices: [],
    diets: [],
    variants: [],
    days: [],
  },
  testMode: false,
  paymentMethods: [],
  pickupPoints: [],
  deliveryHours: [],
  calorifics: [],
  discount: {},
  discountErrorMessage: '',
  calculations: {},
  packageDesc: null,
  dietTypeDesc: null,
  isLoading: true,
  isConfigLoaded: false,
  paymentMethodId: null,
  paymentMetadata: null,
  paymentMetadataIsValid: false,
  useMoneyBox: false,
  isInvoiceIncluded: false,
  invoiceDataType: 'DefaultCompany',
  isInvoiceDataValid: false,
  invoiceCompanyName: '',
  invoiceVatNumber: '',
  invoiceAddressPostCode: '',
  invoiceAddressCity: '',
  invoiceAddressStreet: '',
  invoiceAddressBuildNumber: '',
  invoiceAddressPlaceNumber: '',
  invoiceComments: '',
  orderStatus: {},
  userProceed: false,
  discountForSpecifiedDiets: null,
  order: {},
  defaultsFromUrl: null,
};

const orderFormReducer = (
  state = INITIAL_STATE,
  {
    type,
    orderConfig,
    paymentMethods,
    pickupPoints,
    deliveryHours,
    calculations,
    variantId,
    packageId,
    dietTypeId,
    discount,
    paymentMethodId,
    payload,
    useMoneyBox,
    isInvoiceIncluded,
    invoiceDataType,
    invoiceData,
    isInvoiceDataValid,
    orderStatus,
    discountErrorMessage,
    testMode,
    discountForSpecifiedDiets,
    order,
  }
) => {
  switch (type) {
    case CREATE_ORDER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case CREATE_ORDER_SUCCESS:
    case CREATE_ORDER_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case CREATE_ORDER_RESPONSE:
      return {
        ...state,
        order: {
          ...state.order,
          ...order,
        },
      };

    case FETCH_ORDER_CONFIG_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_ORDER_CONFIG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isConfigLoaded: true,
        orderConfig,
      };

    case FETCH_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods,
      };

    case SET_CALORIFICS:
      const currentVariant = state.orderConfig.variants.find(
        ({ id }) => id === variantId
      );

      return {
        ...state,
        calorifics: currentVariant ? currentVariant.calories : [],
      };

    case SET_DIET_TYPE_DESC:
      const currentDietType = state.orderConfig.diets.find(
        ({ id }) => id === parseInt(dietTypeId, 10)
      );

      return {
        ...state,
        dietTypeDesc: currentDietType ? currentDietType.description : null,
      };

    case SET_PACKAGE_DESC:
      const currentPackage = state.orderConfig.packages.find(
        ({ id }) => id === parseInt(packageId, 10)
      );

      return {
        ...state,
        packageDesc: currentPackage ? currentPackage.description : null,
      };

    case SET_DELIVERY_PICKUP_POINTS:
      return {
        ...state,
        pickupPoints,
      };

    case SET_TEST_MODE:
      return {
        ...state,
        testMode,
      };

    case SET_DELIVERY_HOURS:
      return {
        ...state,
        deliveryHours,
      };

    case SET_PAYMENT_METADATA:
      return {
        ...state,
        paymentMetadata: payload.paymentMetadata,
        paymentMetadataIsValid: payload.isValid,
      };

    case SET_CALCULATIONS:
      return {
        ...state,
        calculations,
      };

    case SET_DISCOUNT:
      return {
        ...state,
        discount,
        discountErrorMessage,
      };

    case CLEAR_DISCOUNT:
      return {
        ...state,
        discount: {},
        discountForSpecifiedDiets: null,
        discountErrorMessage: '',
      };

    case SET_DISCOUNT_FOR_SPECIFIED_DIETS:
      return {
        ...state,
        discountForSpecifiedDiets,
      };

    case SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethodId,
      };

    case SET_USE_MONEY_BOX:
      return {
        ...state,
        useMoneyBox,
      };

    case SET_INVOICE_INCLUDED:
      return {
        ...state,
        isInvoiceIncluded,
      };

    case SET_INVOICE_DATA_TYPE:
      return {
        ...state,
        invoiceDataType,
      };

    case SET_INVOICE_DATA:
      return {
        ...state,
        isInvoiceDataValid: isInvoiceDataValid,
        invoiceCompanyName: invoiceData.invoiceCompanyName,
        invoiceVatNumber: invoiceData.invoiceVatNumber,
        invoiceAddressPostCode: invoiceData.invoiceAddressPostCode,
        invoiceAddressCity: invoiceData.invoiceAddressCity,
        invoiceAddressStreet: invoiceData.invoiceAddressStreet,
        invoiceAddressBuildNumber: invoiceData.invoiceAddressBuildNumber,
        invoiceAddressPlaceNumber: invoiceData.invoiceAddressPlaceNumber,
        invoiceComments: invoiceData.invoiceComments,
      };

    case SET_ORDER_STATUS:
      return {
        ...state,
        orderStatus,
        paymentMethodId: orderStatus.paymentType,
      };

    case USER_PROCEED:
      return {
        ...state,
        userProceed: true,
      };

    case RESET_ORDER:
      return {
        ...state,
        discount: {},
      };

    default:
      return state;
  }
};

export default orderFormReducer;
