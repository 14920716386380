import React from 'react';
import { connect } from 'react-redux';

const PageDescription = ({ pageDescription }) => {
  return <div dangerouslySetInnerHTML={{ __html: pageDescription }} />;
};

export default connect(
  ({
    app: {
      config: {
        modules: {
          MenuPage: { pageDescription },
        },
      },
    },
  }) => ({
    pageDescription,
  })
)(PageDescription);
