import React, { Fragment } from 'react';

import StyledFormTextInput from 'styledComponents/elements/Form/FormTextInput';

const getStyledComponent = type => {
  switch (type) {
    case 'text':
    case 'email':
    case 'password':
      return StyledFormTextInput.Text;
    case 'textarea':
      return StyledFormTextInput.TextArea;
    default:
      return StyledFormTextInput.Text;
  }
};

const FormTextInput = ({
  input,
  type,
  value,
  onChange,
  onBlur, // this must be passed; other way field won't set as touched
  label,
  isBig,
  isInline,
  meta: { error, touched },
  width,
  margin,
  withSubmitButton,
  submitButtonText,
  onSubmit,
  submitErrorMessage,
  isRequired,
  ...rest
}) => {
  const TextInput = getStyledComponent(type);

  return (
    <StyledFormTextInput
      error={error && touched}
      width={width}
      margin={margin}
      withSubmitButton={withSubmitButton}
    >
      {label && (
        <StyledFormTextInput.Label isBig={isBig} isInline={isInline}>
          {label} {isRequired && '*'}
        </StyledFormTextInput.Label>
      )}
      <StyledFormTextInput.InputContainer>
        <TextInput
          isBig={isBig}
          withSubmitButton={withSubmitButton}
          isInline={isInline}
          type={type}
          error={error && touched}
          value={value}
          onChange={e => onChange(e.target.value, e)}
          {...input}
          {...rest}
        />
        {touched && error && (
          <StyledFormTextInput.Error isBig={isBig}>
            {error}
          </StyledFormTextInput.Error>
        )}
        {withSubmitButton && (
          <Fragment>
            <StyledFormTextInput.Button
              disabled={!input.value}
              sizeMiddle
              uppercased
              weightBold
              noMargin
              onClick={() => onSubmit(input.value)}
            >
              {submitButtonText}
            </StyledFormTextInput.Button>
            <StyledFormTextInput.ErrorMessage>
              {submitErrorMessage}
            </StyledFormTextInput.ErrorMessage>
          </Fragment>
        )}
      </StyledFormTextInput.InputContainer>
    </StyledFormTextInput>
  );
};

export default FormTextInput;
