import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';
import { BadgeSecondary } from 'styledComponents/elements/Badge';
import { withTranslation } from 'react-i18next';
import { media } from 'styledComponents/helpers';
import { Flex } from '@rebass/grid';
import {
  Subtitle,
  Paragraph,
  Heading,
} from 'styledComponents/elements/Typography';
import Detail from './components/Details';

const DetailsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  max-width: 100vw;
  ${media.mobileDown`
    flex-direction: column;
    align-items: center;
  `}
`;

const StyledHeading = styled(Heading)`
  font-size: 1.5rem;
  text-align: center;
`;

const BadgesContainer = styled.div`
  & > span {
    display: inline-block;
    margin-right: 4px;
  }
`;

const Options = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 16px;

  & > span {
    margin: auto;
  }

  & > div {
    margin: 8px;
  }
`;

const Index = ({
  Addons,
  address,
  pickUpPoint,
  addons,
  diet,
  variant,
  calorific,
  optionChangeMenu,
  useEcoContainers,
  setCurrentView,
  children,
  t,
  allowChangeAddress,
  premiumType,
  allowChangeDiet,
  ecoContainer,
}) => {
  if (!diet || !variant) return null;

  const showAddons = !(addons.length === 0 && !Addons.extendDiet);
  const showChooseMenu = premiumType === 'PREMIUM' || optionChangeMenu;
  const showEcoContainers = ecoContainer || useEcoContainers;

  return (
    <>
      <StyledHeading>
        {t('$*userDiet.subscription.detailsChanges')}
      </StyledHeading>
      <Paragraph style={{ paddingTop: '0.75rem' }} textAlign="center">
        {t('$*userDiet.subscription.detailsChanges.subtitle')}
      </Paragraph>
      <DetailsContainer>
        <Detail
          onClick={allowChangeAddress ? () => setCurrentView('address') : null}
          title={t('$*menuPlaning.details.addressTitle')}
        >
          <p>
            {pickUpPoint?.value ||
              `${address?.street} ${address?.buildNumber}${
                address?.placeNumber ? `/${address?.placeNumber}` : ''
              } ${address?.postCode} ${address?.city}`}
          </p>
        </Detail>
        <Detail
          onClick={allowChangeDiet ? () => setCurrentView('diet') : null}
          title={t('$*menuPlaning.details.yourDietTitle')}
        >
          <BadgesContainer>
            <BadgeSecondary>{diet.name}</BadgeSecondary>
            <BadgeSecondary>{variant.name}</BadgeSecondary>
            <BadgeSecondary>{calorific.name}</BadgeSecondary>
            <BadgeSecondary>
              {t('$*paymentMode.subscriptionPayment')}
            </BadgeSecondary>
            <Options>
              {showChooseMenu && (
                <>
                  <div>{t('$*premiumBadge.chooseMenu')}</div>
                  <span>
                    {optionChangeMenu
                      ? t('$*checkboxSwitch.yes', 'tak')
                      : t('$*checkboxSwitch.no', 'nie')}
                  </span>
                </>
              )}
              {showEcoContainers && (
                <>
                  <div>{t('$*ecoboxActivationModal.title')}</div>
                  <span>
                    {useEcoContainers
                      ? t('$*checkboxSwitch.yes', 'tak')
                      : t('$*checkboxSwitch.no', 'nie')}
                  </span>
                </>
              )}
            </Options>
          </BadgesContainer>
        </Detail>
        {showAddons && (
          <Detail
            onClick={Addons.extendDiet ? () => setCurrentView('addons') : null}
            title={t('$*menuPlaning.details.yourAddonsTitle')}
          >
            {addons.map(({ addon, quantity }, index) => (
              <li key={index}>
                <span>{quantity}x</span>
                &nbsp;
                <span>{addon.name}</span>
              </li>
            ))}
          </Detail>
        )}
        {children}
      </DetailsContainer>
    </>
  );
};

const enhance = compose(
  connect(
    ({
      menuPlanning: { defaultSubscriptionDietDetails },
      app: {
        brand: {
          allowChangeAddress,
          allowChangeDiet,
          premiumType,
          ecoContainer,
        },
        config: {
          modules: { Addons },
        },
      },
    }) => ({
      ...defaultSubscriptionDietDetails,
      allowChangeAddress,
      allowChangeDiet,
      premiumType,
      ecoContainer,
      Addons,
    }),
    null
  ),
  withTranslation()
);

export default enhance(Index);
