import React from 'react';
import styled from 'styled-components';
import { Box } from '@rebass/grid';

import { media } from 'styledComponents/helpers';

const GoogleMapWrapper = styled(Box)`
  min-height: 260px;
  border-radius: ${props => props.theme.baseBorderRadius};
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;

  iframe {
    height: 100%;
  }

  ${media.tabletDown`
    iframe {
      height: -webkit-fill-available;
    }
  `};

  ${media.tabletDown`
    display: ${props => (props.noMobile ? 'none' : null)};
  `};
`;

const GoogleMap = ({ src, noMobile }) => {
  return (
    <GoogleMapWrapper
      width={[1, 1, 1, 1 / 2]}
      mt={[0, 0, 36]}
      mb="32px"
      ml="10px"
      noMobile={noMobile}
    >
      <iframe
        title="Google Maps"
        src={`https://maps.google.com/maps?q=${src}&output=embed`}
        width="100%"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen
      />
    </GoogleMapWrapper>
  );
};

export default GoogleMap;
