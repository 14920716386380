import React, { useState } from 'react';
import styled from 'styled-components';
import TagManager from 'react-gtm-module';
import { withRouter } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { Flex, Box } from '@rebass/grid';
import { FORM_ERROR } from 'final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { omit } from 'ramda';
import ReactPixel from 'react-facebook-pixel';

import { ButtonPrimary } from 'styledComponents/elements/Button';

import showToast from 'utils/toast';
import URLS from 'routes/routeUrls';

import {
  INITIAL_VALUES,
  INPUT_IDS,
  INPUT_IDS as REGISTER_FORM_INPUT_IDS,
  useRegisterFormInputs,
} from 'views/Auth/const/registerFormInputs';
import { INPUT_IDS as AGREEMENTS_FORM_INPUT_IDS } from 'views/AccountSettings/const/agreementsFormInputs';

import { register, login } from 'views/Auth/actions/authActions';
import { isAuthRoute } from 'views/Auth/services/authHelpers';

import {
  getFormInputs,
  getFormSubmitErrors,
} from 'common/services/formHelpers';
import { translateErrorMessages } from 'common/shared/errorMessages';
import NOTIFICATIONS from 'common/shared/notifications';

import FormFieldBox from 'common/components/Form/FormFieldBox';
import FormSubmitError from 'common/components/Form/FormSubmitError';
import FormCheckbox from '../../../../common/components/Form/FormCheckbox';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';

const FormContainer = styled.div`
  max-width: 600px;
  margin: 32px auto;
`;

const RegisterForm = ({
  register,
  login,
  history,
  brand,
  sexOptions,
  howKnowOptions,
  onAfterSign = () => {},
  disableTracking,
}) => {
  const [rememberMe, setRememberMe] = useState(false);
  const isRegister = history.location.pathname === '/auth/register';
  const { t } = useTranslation();
  const ERROR_MESSAGES = translateErrorMessages(t);
  const REGISTER_FORM_INPUTS = useRegisterFormInputs({
    sexOptions,
    howKnowOptions,
  });

  const agreementsFormInputs = getFormInputs(
    [
      INPUT_IDS.MARKETING_DATA_PROCESSING_AGREEMENT,
      INPUT_IDS.TERMS_OF_SERVICE_AGREEMENT,
    ],
    REGISTER_FORM_INPUTS
  );

  const registerFormTextInputs = REGISTER_FORM_INPUTS.filter(
    input => !agreementsFormInputs.includes(input)
  );

  const onSubmit = formData => {
    const submitValidationRules = [
      {
        condition:
          formData[REGISTER_FORM_INPUT_IDS.PASSWORD] !==
          formData[REGISTER_FORM_INPUT_IDS.REPEAT_PASSWORD],
        errorMessage: ERROR_MESSAGES.SAME_PASSWORD,
      },
      {
        condition: !formData[
          AGREEMENTS_FORM_INPUT_IDS.TERMS_OF_SERVICE_AGREEMENT
        ],
        errorMessage: ERROR_MESSAGES.TERMS_OF_SERVICE_AGREEMENT,
      },
    ];

    const submitErrors = getFormSubmitErrors(formData, submitValidationRules);
    if (submitErrors) {
      return submitErrors;
    }

    const registrationData = omit(
      [REGISTER_FORM_INPUT_IDS.REPEAT_PASSWORD],
      formData
    );

    if (
      registrationData.knowAboutUs ===
      t('$*registerForm.inputs.refererOptionsOthers')
    ) {
      registrationData.knowAboutUs = registrationData.knowAboutUsText;
    }

    rememberMe && localStorage.setItem('keepMeLoggedIn', 'true');

    return register(registrationData)
      .then(() => {
        !disableTracking &&
          TagManager.dataLayer({
            dataLayer: {
              visitorType: 'high-value',
            },
            dataLayerName: 'signup',
          });

        showToast({
          message: NOTIFICATIONS(t).ACCOUNT_SUCCESSFULLY_CREATED(brand.name),
          type: 'success',
        });

        if (!disableTracking) {
          const trackName = isRegister
            ? 'Rejestracja'
            : 'Dane klienta - Rejestracja';
          ReactPixel.trackCustom(trackName);
          ReactPixel.track('CompleteRegistration');
        }

        // automatically login after successful account creation
        // and redirect to homepage or do not redirect if we're on the
        // order form route
        return login(
          {
            username: registrationData[
              REGISTER_FORM_INPUT_IDS.EMAIL
            ].toLowerCase(),
            password: registrationData[REGISTER_FORM_INPUT_IDS.PASSWORD],
          },
          t
        ).then(() => {
          onAfterSign();
        });
      })
      .then(() => {
        if (isAuthRoute()) {
          history.push(URLS.HOME);
        }
      })
      .catch(error => {
        return { [FORM_ERROR]: error };
      });
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={INITIAL_VALUES}
      render={({
        values,
        handleSubmit,
        submitting,
        pristine,
        invalid,
        submitError,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormContainer>
            <Flex flexWrap="wrap" justifyContent="center">
              {registerFormTextInputs.map(({ id, width, children, ...input }) =>
                id !== REGISTER_FORM_INPUT_IDS.REFERRER_TEXT ||
                values.knowAboutUs ===
                  t('$*registerForm.inputs.refererOptionsOthers') ? (
                  <FormFieldBox key={id} width={width}>
                    <Field isBig {...input} />
                  </FormFieldBox>
                ) : null
              )}
              {agreementsFormInputs.map(
                ({ id, label, width, link, linkLabel, ...input }) => (
                  <FormFieldBox key={id} width={width} mb="8px">
                    <Field
                      isBig
                      componentStyle="rounded"
                      linkLabel={linkLabel}
                      link={brand[link]}
                      {...input}
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: brand[label] }}
                      ></div>
                    </Field>
                  </FormFieldBox>
                )
              )}
              <FormFieldBox width={1} mb="8px">
                <Flex justifyContent={'flex-start'}>
                  <Box>
                    <FormCheckbox
                      componentStyle="rounded"
                      input={{
                        checked: rememberMe,
                        onChange: () => setRememberMe(!rememberMe),
                      }}
                    >
                      {t(
                        '$*registerForm.keepMeLoggedIn',
                        '$$Nie wylogowuj mnie'
                      )}
                    </FormCheckbox>
                  </Box>
                </Flex>
              </FormFieldBox>
              <FormSubmitError errorText={submitError} mt="8px" />
            </Flex>
            <Flex justifyContent="center" mt={24}>
              <Box width={[1, 1, 'auto']}>
                <ButtonPrimary
                  type="submit"
                  uppercased
                  sizeMiddle
                  weightBold
                  disabled={submitting || (pristine && invalid)}
                  fullWidthOnMobile
                >
                  {t('$*registerForm.signIn', '$$Załóż konto')}
                </ButtonPrimary>
              </Box>
            </Flex>
          </FormContainer>
        </form>
      )}
    />
  );
};

export default compose(
  connect(
    ({
      app: {
        brand,
        config: { sexOptions, howKnowOptions },
      },
      nativeAppConfig: { disableTracking },
    }) => ({ brand, sexOptions, howKnowOptions, disableTracking }),
    {
      register,
      login,
    }
  ),
  withRouter
)(RegisterForm);
