import styled from 'styled-components';

import { fromPxToRem, media } from 'styledComponents/helpers';

export default styled.div`
  display: ${props => (props.noDisplay ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.baseFontColor};
  cursor: pointer;

  font-size: ${props =>
    props.isPremium ? fromPxToRem('16px') : fromPxToRem('12px')};
  font-weight: ${props => props.theme.fontWeight900};
  text-transform: uppercase;
  height: ${props => (props.isPremium ? '26px' : '23px')};
  width: max-content;
  border-radius: 6px;
  border: 2px solid ${props => props.theme.baseFontColor};
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);
  padding: 0 10px;
  margin-left: ${props => (props.alignRight ? 'auto' : 0)};
  margin-right: ${props => (props.alignLeft ? 'auto' : 0)};
  margin: ${props => props.alignCenter && '0 auto'};
  transition: 0.3s all;

  &:hover {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.24);
  }

  ${media.mobileDown`
    margin: auto;
  `};
`;
