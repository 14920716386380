import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Flex, Box } from '@rebass/grid';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import URLS from 'routes/routeUrls';

import { DisplayOnMobile, DisplayOnDesktop } from 'styledComponents/helpers';
import { Heading } from 'styledComponents/elements/Typography';
import { ButtonPrimary } from 'styledComponents/elements/Button';
import { ContentWrapper } from 'styledComponents/blocks/ContentWrapper';

import UserDietHeadingSection from 'views/UserDiet/containers/UserDietHeadingSection';
import DietDetailsCalendarSection from 'views/UserDiet/containers/DietDetailsCalendarSection';
import MenuPlanningContentForm from 'views/MenuPlanning/containers/MenuPlanningContentForm';
import MenuPlanningHeadingSection from 'views/MenuPlanning/containers/MenuPlanningHeadingSection';
import withUserDiet from 'views/UserDiet/components/withUserDiet';
import withMenuPlanning from 'views/MenuPlanning/components/withMenuPlanning';
import { fetchOrders } from 'views/MyOrders/actions/ordersActions';
import MissingPhoneModal from 'views/UserDiet/components/MissingPhoneModal.component';

import TABS from '../const/tabs';
import NoDiets from '../components/NoDiets';
import { TODAY } from 'common/shared/weekdays';
import { fromPxToRem, media } from 'styledComponents/helpers';

const Label = styled(Link)`
  display: block;
  background: ${props => props.theme.baseFontColor};
  padding: 8px 20px;
  text-align: center;
  color: ${props => props.theme.colorWhite};
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: ${props => props.size || '1rem'};
  font-weight: bold;
  margin: 20px 0;
`;

const H1 = styled.h1`
  font-size: ${fromPxToRem('26px')};
  margin: 32px 0;

  ${media.tabletDown`
    font-size: ${fromPxToRem('20px')};
  `}
`;

const UserDiet = ({
  phone,
  hasWaitingForPaymentOrders,
  history,
  isLoading,
  userDiets,
  match: {
    params: { day },
  },
  dietIsSubscription,
}) => {
  const { t } = useTranslation();
  const [activeView, setActiveView] = useState(TABS.DIET_DETAILS);
  const [isMissingPhoneModalOpened, toggleMissingPhoneModal] = useState(!phone);

  return (
    <Fragment>
      <DisplayOnDesktop>
        {hasWaitingForPaymentOrders && (
          <Label to={URLS.MY_ORDERS}>
            {t(
              '$*userDiet.unpaidOrderCaption',
              '$$Masz zamówienia oczekujące na płatność. Szczegóły sprawdź w historii zamówień'
            )}
          </Label>
        )}
        <UserDietHeadingSection showTitle={false} />

        <H1>
          {t(
            '$*userDiet.dietDetails.title',
            '$$Wybierz datę, aby zobaczyć menu na dany dzień'
          )}
        </H1>
        {userDiets.length === 0 ? <NoDiets /> : <DietDetailsCalendarSection />}
      </DisplayOnDesktop>

      <DisplayOnMobile>
        {hasWaitingForPaymentOrders && (
          <Label size="12px" to={URLS.MY_ORDERS}>
            {t(
              '$*userDiet.unpaidOrderCaption',
              '$$Masz zamówienia oczekujące na płatność. Szczegóły sprawdź w historii zamówień'
            )}
          </Label>
        )}
        {dietIsSubscription && (
          <Fragment>
            <Flex flexDirection="column">
              <UserDietHeadingSection showTitle={false} />
            </Flex>

            <div>
              <H1>
                {t(
                  '$*userDiet.dietDetails.title',
                  '$$Wybierz datę, aby zobaczyć menu na dany dzień'
                )}
              </H1>
              {userDiets.length === 0 ? (
                <NoDiets />
              ) : (
                <DietDetailsCalendarSection />
              )}
            </div>
          </Fragment>
        )}
        {!dietIsSubscription && (
          <Fragment>
            <Flex flexDirection="column">
              <UserDietHeadingSection />
              {userDiets.length > 0 && (
                <MenuPlanningHeadingSection day={TODAY} />
              )}
            </Flex>

            {userDiets.length === 0 ? (
              <ContentWrapper>
                <Flex>
                  <Box>
                    <Heading textAlign="center">
                      {t(
                        '$*userDiet.noOrdersYet',
                        '$$Obecnie nie posiadasz żadnych zamówień.'
                      )}
                    </Heading>
                  </Box>
                </Flex>
                <Flex justifyContent="center">
                  <Box width={1}>
                    <ButtonPrimary
                      uppercased
                      fullWidthOnMobile
                      onClick={() => history.push(URLS.NEW_ORDER_FORM)}
                    >
                      {t('$*userDiet.addNewOrder', 'Dodaj zamówienie')}
                    </ButtonPrimary>
                  </Box>
                </Flex>
              </ContentWrapper>
            ) : isLoading ? null : (
              <MenuPlanningContentForm />
            )}
          </Fragment>
        )}
      </DisplayOnMobile>
      {isMissingPhoneModalOpened && (
        <MissingPhoneModal
          isOpened={isMissingPhoneModalOpened}
          toggleModal={() => toggleMissingPhoneModal(false)}
        />
      )}
    </Fragment>
  );
};

export default compose(
  connect(
    ({
      auth: {
        user: { phone, hasWaitingForPaymentOrders },
      },
      menuPlanning: { isLoading, defaultSubscriptionDietDetails },
      userDiet: { list: userDiets, selectedDietObject },
      orders: { orders },
    }) => ({
      phone,
      hasWaitingForPaymentOrders,
      userDiets,
      orders,
      isLoading,
      defaultSubscriptionDietDetails,
      dietIsSubscription: selectedDietObject?.isSubscription ?? false,
    }),
    { fetchOrders }
  ),
  withRouter,
  // This view uses user diet and menu planning HOCs due to different desktop and mobile layouts
  withUserDiet,
  withMenuPlanning
)(UserDiet);
