import { get } from 'utils/http';
import { URLS } from 'configuration';

import {
  FETCH_MONEY_BOX_HISTORY_REQUEST,
  FETCH_MONEY_BOX_HISTORY_SUCCESS,
  FETCH_MONEY_BOX_HISTORY_FAILURE,
  UPDATE_MONEY_BOX_HISTORY_SUCCESS,
} from 'common/components/MoneyBox/actions/const';

export const getMoneyBoxHistory = (itemsPerPage, page) => dispatch => {
  dispatch({
    type: FETCH_MONEY_BOX_HISTORY_REQUEST,
  });

  return get(
    URLS.MONEY_BOX_HISTORY,
    { partial: true, itemsPerPage, page, 'order[id]': 'DESC' },
    true
  )
    .then(({ data }) =>
      dispatch({
        type: FETCH_MONEY_BOX_HISTORY_SUCCESS,
        page: page,
        history: data,
        allMoneyBoxHistoryLoaded: data.length < itemsPerPage,
      })
    )
    .catch(error =>
      dispatch({
        type: FETCH_MONEY_BOX_HISTORY_FAILURE,
        error,
      })
    );
};

export const getAllMoneyBoxHistory = () => dispatch => {
  dispatch({
    type: FETCH_MONEY_BOX_HISTORY_REQUEST,
  });

  return get(
    URLS.MONEY_BOX_HISTORY,
    { partial: false, pagination: false, 'order[id]': 'DESC' },
    true
  )
    .then(({ data }) =>
      dispatch({
        type: FETCH_MONEY_BOX_HISTORY_SUCCESS,
        history: data,
        allMoneyBoxHistoryLoaded: true,
      })
    )
    .catch(error =>
      dispatch({
        type: FETCH_MONEY_BOX_HISTORY_FAILURE,
        error,
      })
    );
};

export const updateMoneyBoxHistory = (itemsPerPage, page) => dispatch => {
  dispatch({
    type: FETCH_MONEY_BOX_HISTORY_REQUEST,
  });

  return get(
    URLS.MONEY_BOX_HISTORY,
    { partial: true, itemsPerPage, page: page + 1, 'order[id]': 'DESC' },
    true
  )
    .then(({ data }) =>
      dispatch({
        type: UPDATE_MONEY_BOX_HISTORY_SUCCESS,
        history: data,
        page: page + 1,
        allMoneyBoxHistoryLoaded: data.length < itemsPerPage,
      })
    )
    .catch(error =>
      dispatch({
        type: FETCH_MONEY_BOX_HISTORY_FAILURE,
        error,
      })
    );
};
