import { DELIVERY_TAKEOUT_OPTION_ID } from '../const/formConfig/deliveryOptions';
import FORM_FIELD_NAMES from '../const/formFieldNames';
import { min, max } from 'date-fns';

import {
  getDietParam,
  getVarianParam,
  getDietCalorificsParam,
  getDietDuration,
  getDietDeliveryTypeParam,
  getDietDeliveryHourUpTo,
  getDietDeliveryAddress,
  getDietPickupPointAddressParam,
  getDietField,
  getUseEcoContainers,
  getPackageParam,
} from './formService.js';

export const getDietsSummary = (
  forms,
  packages,
  diets,
  variants,
  pickupPoints,
  addresses,
  testMode,
  DELIVERY_OPTIONS,
  t,
  useAddressesWithLessFields
) => {
  return Object.values(forms).map(form =>
    getDietSummary(
      form,
      packages,
      diets,
      variants,
      pickupPoints,
      addresses,
      testMode,
      DELIVERY_OPTIONS,
      t,
      useAddressesWithLessFields
    )
  );
};

const getDietSummary = (
  form,
  packages,
  diets,
  variants,
  pickupPoints,
  addresses,
  testMode,
  DELIVERY_OPTIONS,
  t,
  useAddressesWithLessFields
) => {
  const deliveryDays = form[FORM_FIELD_NAMES.DELIVERY_DAYS];
  const firstDeliveryDay = min(...deliveryDays);
  const lastDeliveryDay =
    form[FORM_FIELD_NAMES.PAYMENT_MODE] === '2' ? max(...deliveryDays) : null;

  return {
    package: getPackageParam(packages, form[FORM_FIELD_NAMES.PACKAGE], 'name'),
    dietType: getDietParam(diets, form[FORM_FIELD_NAMES.DIET_TYPE], 'name'),
    dietDuration: getDietDuration(form[FORM_FIELD_NAMES.DURATION], testMode, t),
    dietVariant: getVarianParam(
      variants,
      form[FORM_FIELD_NAMES.VARIANT],
      'name'
    ),
    dietIcon: getDietField(diets, form['diet'], 'icon'),
    dietImage: getDietField(diets, form['diet'], 'image'),
    useEcoContainers: getUseEcoContainers(
      form[FORM_FIELD_NAMES.WITH_ECOBOX],
      t
    ),
    dietCalorifics: getDietCalorificsParam(
      variants,
      form[FORM_FIELD_NAMES.VARIANT],
      form[FORM_FIELD_NAMES.CALORIFICS],
      'name'
    ),
    dietDeliveryType: form[FORM_FIELD_NAMES.DELIVERY_TYPE],
    dietDeliveryHourUpTo:
      +form[FORM_FIELD_NAMES.DELIVERY_TYPE] === DELIVERY_TAKEOUT_OPTION_ID
        ? getDietDeliveryHourUpTo(
            form[FORM_FIELD_NAMES.DELIVERY_ADDRESS],
            addresses,
            t
          )
        : null,

    dietDeliveryAddress:
      +form[FORM_FIELD_NAMES.DELIVERY_TYPE] === DELIVERY_TAKEOUT_OPTION_ID
        ? getDietDeliveryAddress(
            form[FORM_FIELD_NAMES.DELIVERY_ADDRESS],
            addresses,
            t,
            useAddressesWithLessFields
          )
        : getDietPickupPointAddressParam(
            form[FORM_FIELD_NAMES.DELIVERY_PICKUP_POINT],
            pickupPoints,
            'value'
          ),
    testMode,
    dietAddons: form?.addons || [],
    dietFirstDeliveryDay: firstDeliveryDay,
    dietLastDeliveryDay: lastDeliveryDay,
  };
};
