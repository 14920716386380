import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  BaseWrapper,
  BaseDayNumber,
  BaseCaption,
} from 'styledComponents/elements/BigCalendarDayCell';
import { DisplayOnDesktop } from 'styledComponents/helpers';

const Wrapper = styled(BaseWrapper)`
  background-color: ${props => props.theme.baseFontColor};
`;

const Caption = styled(BaseCaption)`
  color: ${props => props.theme.colorWhite};
`;

const StyledDayNumber = styled(BaseDayNumber)`
  color: ${props => props.theme.colorWhite};
  font-weight: ${props => props.theme.fontWeight900};
`;

const DetailsAfter3OrMoreDaysDayCell = ({ children }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <StyledDayNumber>{children}</StyledDayNumber>

      <DisplayOnDesktop>
        <Caption>{t('$*calendarDayCell.details', '$$Szczegóły')}</Caption>
      </DisplayOnDesktop>
    </Wrapper>
  );
};

export default DetailsAfter3OrMoreDaysDayCell;
