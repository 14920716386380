import React from 'react';
import partially_rated from 'assets/svg/calendar/partially_rated.svg';
import { connect } from 'react-redux';
import { BASE_URL, URLS } from 'configuration';

const RateIcon = ({ ConfigClientPanel }) => {
  const hasPartiallyRatedIcon =
    ConfigClientPanel.calendarIconRatedPartial !== null;

  const { showCalendarIcons } = ConfigClientPanel;
  const src =
    hasPartiallyRatedIcon &&
    `${BASE_URL}${URLS.UPLOADED_MEDIA}${
      ConfigClientPanel['@resources'][
        ConfigClientPanel.calendarIconRatedPartial
      ]?.contentUrl
    }`;

  if (!hasPartiallyRatedIcon || !showCalendarIcons) return null;
  return <img className="calendar-icon" width="100%" src={src} />;
};

export default connect(
  ({
    app: {
      config: {
        modules: { ConfigClientPanel },
      },
    },
  }) => ({ ConfigClientPanel }),
  null
)(RateIcon);
