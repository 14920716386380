import styled from 'styled-components';
import { lighten } from 'polished';

import { fromPxToRem, media } from 'styledComponents/helpers';

const Calendar = styled.div`
  .DayPicker {
    width: 100%;
    padding: 20px 20px 10px;
    border-radius: ${props => props.theme.baseBorderRadius};
  }

  .DayPicker:not(.DayPicker--interactionDisabled) {
    .DayPicker-Day {
      &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside) {
        &:hover {
          border-color: ${props => props.theme.baseFontColor};
          background-color: ${props => props.theme.colorWhite};
        }
      }
    }
  }

  .DayPicker-wrapper {
    padding-bottom: 0;
    &:focus {
      outline: none;
    }
  }

  .DayPicker-Month {
    //width: 100%;
    margin: 0;
    border-collapse: separate;
    border-spacing: 22px 24px;
  }

  .DayPicker-Caption {
    margin-bottom: 0;
    text-align: center;
    text-transform: uppercase;
    color: ${props => props.theme.baseFontColor};
  }

  .DayPicker-Day.DayPicker-Day--withdiet.DayPicker-Day--disabled:not(.DayPicker-Day--outside) {
    background: ${props => lighten(0.25, props.theme.baseFontColor)};
    color: ${props => props.theme.colorWhite};
  }

  .DayPicker-NavBar {
    position: relative;
  }

  .DayPicker-NavButton {
    top: 1px;
    background: none;
    font-family: 'Font Awesome 5 Free';
    font-weight: ${props => props.theme.fontWeight900};
    color: ${props => props.theme.baseFontColor};
    -webkit-font-smoothing: antialiased;

    &:focus {
      outline: none;
    }
  }

  .DayPicker-NavButton--prev {
    left: calc((100% - 240px) / 2);

    &:before {
      content: '\f053';
    }
  }

  .DayPicker-NavButton--next {
    right: calc((100% - 240px) / 2);

    &:before {
      content: '\f054';
    }
  }

  .DayPicker-Weekday {
    padding: 0;
    min-width: 36px;
    text-transform: uppercase;
    font-weight: ${props => props.theme.fontWeight900};
    font-size: ${fromPxToRem('12px')};
  }

  .DayPicker-Day {
    padding: 5px 10px;
    border-radius: ${props => props.theme.baseBorderRadius};
    border: 1px solid ${props => props.theme.colorIceBlue};

    &:focus {
      outline: none;
    }

    &:not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled) {
      position: relative;
    }
  }

  .DayPicker-Day--outside {
    border: 0;
    //background-color: ${props => props.theme.colorIceBlue};
    background-color: transparent;
    pointer-events: none;
    color: ${props => props.theme.baseFontColor};

    &:hover {
      border: 1px solid ${props => props.theme.baseFontColor};
      cursor: pointer;
    }
  }

  .DayPicker-Day--disabled:not(.DayPicker-Day--outside) {
    background-color: ${props => props.theme.colorIceBlue};
    color: ${props => props.theme.baseFontColor};
  }

  .DayPicker-Day--disabled {
    pointer-events: none;
  }

  .DayPicker-Day--today:not(.DayPicker-Day--disabled) {
    background-color: ${props => props.theme.colorWhite};
    border: 2px solid ${props => props.theme.baseFontColor};
    color: ${props => props.theme.baseFontColor};
    cursor: pointer;
  }

  .DayPicker-Day--selected {
    &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside),
    &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
      position: relative;
      background-color: ${props => props.theme.baseFontColor};
      border-color: ${props => props.theme.baseFontColor};
      color: ${props => props.theme.colorWhite};
  }

  ${media.mobileDown`
      .DayPicker {
        width: 100%;
        padding: 6px;
        border-radius: ${props => props.theme.baseBorderRadius};
      }

      .DayPicker-Month {
        max-width: 280px;
        border-spacing: 4px 16px;
      }

      .DayPicker-Weekday,
      .DayPicker-Day {
        padding: 0;
        width: 40px;
        height: 40px;
      }

      .DayPicker-NavButton--prev {
        left: 5%;
      }

      .DayPicker-NavButton--next {
        right: 5%;
      }
    `};
`;

export default Calendar;
