import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { sortByPosition } from 'utils/componentHelpers';
import { Category } from './Addons.styled';

const Addons = ({ addons, renderAddons, modules, excludedPlace }) => {
  const { t } = useTranslation();
  const filteredAddons = addons.map(
    ({ addons: categoryAddons, ...restAddon }) => {
      const addons = categoryAddons.filter(
        ({ excludedPlaces }) =>
          excludedPlaces.length === 0 || !excludedPlaces.includes(excludedPlace)
      );

      return {
        ...restAddon,
        addons,
      };
    }
  );

  const {
    Addons: { groupByCategory },
  } = modules;

  if (groupByCategory) {
    return (
      <div>
        {sortByPosition(filteredAddons).map(
          ({ name, addons: categoryAddons }, index) => {
            const hasAddons = categoryAddons.length > 0;

            return (
              <Category key={`category-${name}`}>
                <Category.Name>{`${index + 1}. ${name}`}</Category.Name>

                {hasAddons && (
                  <Category.GroupWrapper>
                    {renderAddons({ categoryName: name, categoryAddons })}
                  </Category.GroupWrapper>
                )}

                {!hasAddons && (
                  <Category.NoAddons>
                    {t(
                      '$*orderFormAddons.categoryNoAddons1',
                      '$$Dodatki w tej kategori pojawią się wkrótce!'
                    )}
                  </Category.NoAddons>
                )}
              </Category>
            );
          }
        )}

        {filteredAddons.length === 0 && (
          <Category.NoAddons>
            {t(
              '$*orderFormAddons.categoryNoAddons2',
              '$$Dodatki pojawią się wkrótce!'
            )}
          </Category.NoAddons>
        )}
      </div>
    );
  }

  return (
    <div>
      <Category.GroupWrapper>
        {sortByPosition(filteredAddons).map(
          ({ name, addons: categoryAddons }) => {
            const hasAddons = categoryAddons.length > 0;

            return (
              hasAddons && renderAddons({ categoryName: name, categoryAddons })
            );
          }
        )}
      </Category.GroupWrapper>

      {filteredAddons.length === 0 && (
        <Category.NoAddons>
          {t(
            '$*orderFormAddons.categoryNoAddons3',
            '$$Dodatki pojawią się wkrótce!'
          )}
        </Category.NoAddons>
      )}
    </div>
  );
};

const mapStateToProps = ({
  app: {
    config: { modules, addons },
  },
}) => ({
  addons,
  modules,
});

const enhance = compose(connect(mapStateToProps, null));

export default enhance(Addons);
