import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import RenewBadge from 'styledComponents/elements/MenuPlanning/RenewBadge';
import useIsEditable from './MenuPlanningLeftColumn/hooks/useIsEditable';
import PlusIcon from '../icons/PlusIcon';
import CloseIcon from '../icons/CloseIcon';
import Tooltip from './MenuPlanningLeftColumn/components/Tooltip';

const Wrapper = styled.div`
  display: flex;
  font-size: 0.75rem;
  justify-content: space-between;
  align-items: center;
  color: ${props => props.theme.baseFontColor};

  > div ~ div {
    margin-left: 1rem;
  }
`;

const DEADLINE_BRAND_KEY = 'pauseDeadline';

const RenewDietBadge = ({ t, onClickAction, suspend }) => {
  const { hasTimePassed } = useIsEditable(DEADLINE_BRAND_KEY);

  const title = suspend
    ? t('$*menuPlaning.details.resumeDayCaption')
    : t('$*menuPlaning.details.holdDayCaption');

  const Icon = suspend ? PlusIcon : CloseIcon;

  if (hasTimePassed) return null;

  return (
    <Wrapper>
      <RenewBadge marginLeft={'auto'} marginRight={0} onClick={onClickAction}>
        <Icon />
        <span style={{ marginLeft: '6px', whiteSpace: 'nowrap' }}>{title}</span>
      </RenewBadge>
      <Tooltip
        deadlineBrandKey={DEADLINE_BRAND_KEY}
        isEditable={!hasTimePassed}
      />
    </Wrapper>
  );
};

export default withTranslation()(RenewDietBadge);
