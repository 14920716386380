import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import store from 'store';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import FormTextInput from 'common/components/Form/FormTextInput';
import FormCheckbox from 'common/components/Form/FormCheckbox';
import DeliveryHoursSelect from '../components/DeliveryHoursSelect';

import {
  getValidators,
  useFormValidation,
} from 'common/services/formValidation';

import {
  getDeliveryHours,
  clearDeliveryHours,
} from 'views/NewOrder/actions/orderFormActions';
import { translateErrorMessages } from 'common/shared/errorMessages';

export const INPUT_IDS = {
  ADDRESS_ID: 'address.id',
  CUSTOM_ADDRESS_NAME: 'address.name',
  STREET: 'address.street',
  HOUSE_NUMBER: 'address.buildNumber',
  HOUSE_UNIT_NUMBER: 'address.placeNumber',
  POSTAL_CODE: 'address.postCode',
  CITY: 'address.city',
  FLOOR: 'address.floor',
  STAIRCASE: 'address.gate',
  INTERCOM_CODE: 'address.keyToIntercom',
  GATEWAY_CODE: 'address.keyToGate',
  DELIVERY_HOURS: 'address.selectedDeliveryHour',
  ADDITIONAL_INFO: 'address.comment',
  SET_AS_DEFAULT: 'address.isDefault',
  ADDRESS_LINE1: 'address.addressLine1',
  ADDRESS_LINE2: 'address.addressLine2',
};

export const useUserAddressesFormInputs = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [isValidating, setIsValidating] = useState(false);
  const validators = useFormValidation();
  const ERROR_MESSAGES = translateErrorMessages(t);

  const supportedRegions = useSelector(
    state => state.app.config.multinational.supportedRegions
  );
  const firstSupportedCountry = Object.values(supportedRegions)?.[0];
  const regexpTest = firstSupportedCountry?.postCodeRegexp
    ? new RegExp(`${firstSupportedCountry.postCodeRegexp}$`)
    : null;

  return {
    isValidating,
    USER_ADDRESSES_FORM_INPUTS: useMemo(
      () => [
        {
          id: INPUT_IDS.ADDRESS_ID,
          name: INPUT_IDS.ADDRESS_ID,
          type: 'hidden',
        },
        {
          id: INPUT_IDS.CUSTOM_ADDRESS_NAME,
          name: INPUT_IDS.CUSTOM_ADDRESS_NAME,
          type: 'text',
          component: FormTextInput,
          label: t(
            '$*userAddressesForm.yourAddressName',
            '$$Twoja nazwa adresu'
          ),
          disabled: isValidating,
          isRequired: true,
          validate: validators.required,
        },
        {
          id: INPUT_IDS.STREET,
          name: INPUT_IDS.STREET,
          type: 'text',
          component: FormTextInput,
          label: t('$*userAddressesForm.street', '$$Ulica'),
          maxLength: '255',
          disabled: isValidating,
          isRequired: true,
          validate: validators.required,
        },
        {
          id: INPUT_IDS.HOUSE_NUMBER,
          name: INPUT_IDS.HOUSE_NUMBER,
          type: 'text',
          component: FormTextInput,
          label: t('$*userAddressesForm.houseNumber', '$$Numer'),
          maxLength: '16',
          disabled: isValidating,
          isRequired: true,
          validate: validators.required,
        },
        {
          id: INPUT_IDS.HOUSE_UNIT_NUMBER,
          name: INPUT_IDS.HOUSE_UNIT_NUMBER,
          type: 'text',
          maxLength: '16',
          disabled: isValidating,
          component: FormTextInput,
          label: t('$*userAddressesForm.houseUnitNumber', '$$Lokal'),
        },
        {
          id: INPUT_IDS.POSTAL_CODE,
          name: INPUT_IDS.POSTAL_CODE,
          type: 'text',
          component: FormTextInput,
          label: t('$*userAddressesForm.postal', '$$Kod pocztowy'),
          isRequired: true,
          parse: firstSupportedCountry?.postCodeRegexp
            ? validators.parsePostalCode
            : v => v,
          onBlur: ({ target: { value } }) =>
            store.dispatch(getDeliveryHours(value)),
          validate: getValidators([
            validators.required,
            (value, values, fieldState) => {
              if (value && fieldState.active) {
                if (!regexpTest || (regexpTest && regexpTest.test(value))) {
                  setIsValidating(true);
                  return store
                    .dispatch(getDeliveryHours(value))
                    .then(() => {
                      const deliveryHours = store.getState().orderForm
                        .deliveryHours;
                      if (deliveryHours.length) {
                        const defaultDeliveryHours = deliveryHours.find(
                          deliveryHour => deliveryHour?.value?.default
                        );

                        if (!isEmpty(defaultDeliveryHours)) {
                          values.address.selectedDeliveryHour =
                            defaultDeliveryHours.value;
                          values.address.deliveryHourFrom =
                            defaultDeliveryHours.value.hourFrom;
                          values.address.deliveryHourTo =
                            defaultDeliveryHours.value.hourTo;
                        } else {
                          values.address.selectedDeliveryHour =
                            deliveryHours[0].value;
                          values.address.deliveryHourFrom =
                            deliveryHours[0].value.hourFrom;
                          values.address.deliveryHourTo =
                            deliveryHours[0].value.hourTo;
                        }
                      } else {
                        values.address.deliveryHourFrom = null;
                        values.address.deliveryHourTo = null;
                      }

                      setIsValidating(false);
                      return undefined;
                    })
                    .catch(res => {
                      setIsValidating(false);
                      return ERROR_MESSAGES.LOCATION_NOT_SUPPORTED;
                    });
                } else {
                  store.dispatch(clearDeliveryHours());
                  return ERROR_MESSAGES.WRONG_POSTAL_FORMAT;
                }
              } else if (fieldState.invalid) {
                if (fieldState.value.length === 6) {
                  return ERROR_MESSAGES.LOCATION_NOT_SUPPORTED;
                } else {
                  return ERROR_MESSAGES.WRONG_POSTAL_FORMAT;
                }
              }
            },
          ]),
        },
        {
          id: INPUT_IDS.CITY,
          name: INPUT_IDS.CITY,
          type: 'text',
          component: FormTextInput,
          label: t('$*userAddressesForm.city', '$$Miasto'),
          disabled: isValidating,
          maxLength: '255',
          isRequired: true,
          validate: validators.required,
        },
        {
          id: INPUT_IDS.FLOOR,
          name: INPUT_IDS.FLOOR,
          type: 'text',
          component: FormTextInput,
          maxLength: '10',
          disabled: isValidating,
          label: t('$*userAddressesForm.floor', '$$Piętro'),
          parse: v =>
            Number.isInteger(parseInt(v, 10)) ? parseInt(v, 10) : null,
        },
        {
          id: INPUT_IDS.STAIRCASE,
          name: INPUT_IDS.STAIRCASE,
          type: 'text',
          component: FormTextInput,
          maxLength: '64',
          disabled: isValidating,
          label: t('$*userAddressesForm.stage', '$$Klatka schodowa'),
        },
        {
          id: INPUT_IDS.INTERCOM_CODE,
          name: INPUT_IDS.INTERCOM_CODE,
          type: 'text',
          component: FormTextInput,
          maxLength: '64',
          disabled: isValidating,
          label: t('$*userAddressesForm.intercomCode', '$$Kod domofonu'),
        },
        {
          id: INPUT_IDS.DELIVERY_HOURS,
          name: INPUT_IDS.DELIVERY_HOURS,
          type: 'select',
          component: DeliveryHoursSelect,
          disabled: isValidating,
          label: t(
            '$*userAddressesForm.deliveryHours',
            '$$Orientacyjne godziny dostaw'
          ),
          placeholder: t('$*userAddressesForm.deliveryHours.select'),
          isRequired: true,
        },
        {
          id: INPUT_IDS.ADDITIONAL_INFO,
          name: INPUT_IDS.ADDITIONAL_INFO,
          type: 'textarea',
          component: FormTextInput,
          maxLength: '400',
          disabled: isValidating,
          label: t(
            '$*userAddressesForm.additionalInfo',
            '$$Dodatkowe uwagi do adresu (np. numer telefonu)'
          ),
        },
        {
          id: INPUT_IDS.SET_AS_DEFAULT,
          name: INPUT_IDS.SET_AS_DEFAULT,
          type: 'checkbox',
          component: FormCheckbox,
          disabled: isValidating,
          label: t(
            '$*userAddressesForm.setAsDefault',
            '$$Ustaw jako adres domyślny'
          ),
        },

        {
          id: INPUT_IDS.ADDRESS_LINE1,
          name: INPUT_IDS.ADDRESS_LINE1,
          type: 'text',
          component: FormTextInput,
          label: t('$*userAddressesForm.addressLine1', '$$Wiersz adresu 1'),
          maxLength: '255',
          isRequired: true,
          disabled: isValidating,
          validate: validators.required,
        },
        {
          id: INPUT_IDS.ADDRESS_LINE2,
          name: INPUT_IDS.ADDRESS_LINE2,
          type: 'text',
          component: FormTextInput,
          label: t('$*userAddressesForm.addressLine2', '$$Wiersz adresu 2'),
          maxLength: '255',
          disabled: isValidating,
          isRequired: false,
        },
      ],
      [language, isValidating]
    ),
  };
};
