import React, { useState, useEffect, useRef } from 'react';
import Modal from 'common/components/Modal';
import {
  startOfWeek as getStartOfWeek,
  addDays,
  format,
  differenceInDays,
  isSameDay,
} from 'date-fns';
import _ from 'lodash';
import {
  ArrowWrapper,
  HeadingWrapper,
  Label,
  MealWrapper,
  SliderWrapper,
  StyledIcon,
  WeekDay,
  WeekDayWrapper,
  MealCellWrapper,
  MealCellTitle,
} from './StyledComponents';
import Slider from 'react-slick';
import MealCell from './MealCell';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { withLocalUrl } from '../../routes/withLocalUrl';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { mainTheme } from '../../themes/main';
import { get } from '../../utils/http';
import moment from 'moment';

const getDaysToShow = actualWidth => {
  switch (true) {
    case actualWidth < mainTheme.deviceBreakpoints['mobile']:
      return 1;
    case actualWidth < mainTheme.deviceBreakpoints['tablet']:
      return 3;
    case actualWidth < mainTheme.deviceBreakpoints['desktop']:
      return 4;
    default:
      return 7;
  }
};

const startOfWeek = getStartOfWeek(new Date(), { weekStartsOn: 1 });
const dayOffset = differenceInDays(new Date(), startOfWeek) || 0;
const daysToShow = getDaysToShow(window.innerWidth);

const AllWeekComponent = props => {
  const sliderReference = useRef(null);
  const [sliderRef, setSliderRef] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(null);
  const [mealsByDays, setMealsByDays] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [
    isMealIngredientsModalOpened,
    setIsMealIngredientsModalOpened,
  ] = useState(null);
  const [mealIngredientsText, setMealIngredientsText] = useState(null);

  useEffect(() => {
    setSliderRef(sliderReference.current);
    const initSlideOffset = getSlideOffset(daysToShow, dayOffset);
    const isAtStart = currentSlide === initSlideOffset;
    const isAtEnd = currentSlide + daysToShow >= 14;

    setIsAtStart(isAtStart);
    setIsAtEnd(isAtEnd);
    setCurrentSlide(initSlideOffset);
    sliderReference.current?.slickGoTo(initSlideOffset);
  }, []);

  const {
    variants,
    mainColor,
    daysConfig,
    disabledDays,
    selectedVariant,
    t,
    i18n,
    selectedDiet,
    defaultDiet,
    defaultVariant,
    MenuPage: { mode, showDishIngredients },
  } = props;

  const getSlideOffset = (daysToShow, dayOffset) => {
    switch (daysToShow) {
      case 1:
        return dayOffset;
      case 3:
      case 4:
        return dayOffset - 1;
      default:
        return 0;
    }
  };

  useEffect(() => {
    const initSlideOffset = getSlideOffset(daysToShow, dayOffset);
    const fixedCurrentSlide =
      currentSlide === null ? initSlideOffset : currentSlide;
    const dateFrom = format(
      addDays(startOfWeek, fixedCurrentSlide),
      'YYYY-MM-DD'
    );

    const dateTo = format(
      addDays(startOfWeek, fixedCurrentSlide + daysToShow - 1),
      'YYYY-MM-DD'
    );

    const defaultQuery = {
      variant: selectedVariant,
      diet: selectedDiet,
      dateFrom,
      dateTo,
    };

    const brandSetQuery = {
      variant: defaultVariant,
      diet: defaultDiet,
      dateFrom,
      dateTo,
    };

    const loadDietFromBrand =
      mode === 'DEFAULT_DIET' &&
      defaultVariant !== null &&
      defaultDiet !== null;

    getMeals(loadDietFromBrand ? brandSetQuery : defaultQuery);
  }, [selectedVariant, selectedDiet]);

  const generateAllWeekdays = () => {
    const startOfWeek = getStartOfWeek(new Date(), { weekStartsOn: 1 });

    return _.range(14).map((el, index) => {
      //const currDay = moment(startOfWeek).add(index, 'days');
      const currDay = addDays(startOfWeek, index);
      //const dayName = moment(currDay).locale('en').format('dddd').toLowerCase();
      const dayName = moment(currDay, 'dddd').locale(i18n.language);
      const disabled =
        daysConfig[dayName] === 0 ||
        disabledDays.some(date => moment(date).isSame(currDay, 'day'));

      return {
        label: format(currDay, 'dd'),
        date: currDay,
        disabled,
      };
    });
  };

  const handleMealIngredientsModalToggle = ingredientsString => {
    if (!ingredientsString) {
      setMealIngredientsText(null);
      return setIsMealIngredientsModalOpened(null);
    }
    setMealIngredientsText(
      `${t(
        '$*components.dishTile.dishComposition',
        '$$Skład'
      )}: ${ingredientsString}`
    );
    setIsMealIngredientsModalOpened('allWeekComponentDishIngredients');
  };

  const setPosition = (currentSlide, maxSlides) => {
    const atBeggining = currentSlide === 0;
    const reachedEnd = currentSlide + daysToShow >= maxSlides;
    setIsAtStart(atBeggining);
    setIsAtEnd(reachedEnd);
  };

  const onBeforeChange = (currSlide, nextSlide) => {
    const fixedNextSlide = Math.min(nextSlide, 14 - daysToShow);

    const daysToAddForQuery =
      mode === 'WEEK_VIEW' ? getDaysToShow(window.innerWidth) - 1 : 14;

    const dateFrom = format(addDays(startOfWeek, nextSlide), 'YYYY-MM-DD');
    const dateTo = format(addDays(dateFrom, daysToAddForQuery), 'YYYY-MM-DD');

    const queryParams = {
      variant: selectedVariant,
      diet: selectedDiet,
      dateFrom,
      dateTo,
    };

    getMeals(queryParams);
    setCurrentSlide(fixedNextSlide);
  };

  const allWeekdays = generateAllWeekdays();

  const getMeals = ({ variant, diet, dateFrom, dateTo }) => {
    setIsLoading(true);
    setMealsByDays([]);
    get('frontend/menu', {
      'date[after]': dateFrom,
      'date[before]': dateTo,
      properties: ['date', 'variant', 'mealType', 'diet'],
      'properties[nameForClient]': 'dish',
      pagination: false,
      variant: variant,
      diet: diet,
    }).then(
      response => {
        const data = response.data['hydra:member'];
        const sortedMeals = data.reduce((prev, curr) => [...prev, curr], []);
        setMealsByDays(sortedMeals);
        setIsLoading(false);
      },
      () => setIsLoading(false)
    );
  };

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 800,
    slidesToShow: daysToShow,
    slidesToScroll: daysToShow,
  };

  const mappedVariants = variants.reduce(
    (prev, curr) => ({
      ...prev,
      [curr['@id']]: {
        label: curr.name,
        mealTypes: curr.mealTypes.reduce(
          (prev, curr) => ({ ...prev, [curr['@id']]: curr.name }),
          {}
        ),
      },
    }),
    {}
  );

  if (!mappedVariants[selectedVariant]) return null;

  const mealTypes = mappedVariants[selectedVariant].mealTypes;
  const sortedMealTypes = Object.entries(mealTypes).map(([key, value]) => ({
    label: value,
    mealType: key,
  }));

  const initialSlide = getSlideOffset(
    getDaysToShow(window.innerWidth),
    dayOffset
  );

  return (
    <>
      <ArrowWrapper>
        <StyledIcon
          mainColor={mainColor}
          hide={isAtStart}
          className="fas fa-chevron-circle-left"
          onClick={e => sliderRef && sliderRef.slickPrev(e)}
        />
        <StyledIcon
          hide={isAtEnd}
          className="fas fa-chevron-circle-right"
          onClick={e => sliderRef && sliderRef.slickNext(e)}
        />
      </ArrowWrapper>
      <SliderWrapper>
        <Slider
          beforeChange={onBeforeChange}
          afterChange={currentSlide => {
            const fixedCurrentSlide = Math.min(currentSlide, 14 - daysToShow);
            setPosition(fixedCurrentSlide, allWeekdays.length);
          }}
          initialSlide={initialSlide}
          ref={slider => {
            sliderReference.current = slider;
          }}
          {...sliderSettings}
          arrows={false}
        >
          {allWeekdays.map((weekday, index) => (
            <WeekDayWrapper
              key={index}
              className={'slider-element'}
              color={mainColor}
              firstWeekday={index === 0}
              lastWeekday={index === allWeekdays.length - 1}
              active={isSameDay(new Date(), weekday.date)}
            >
              <HeadingWrapper
                lastWeekday={index === allWeekdays.length - 1}
                firstWeekday={index === 0}
                color={mainColor}
                active={isSameDay(new Date(), weekday.date)}
              >
                <WeekDay>
                  {moment(weekday.date)
                    .locale(i18n.language)
                    .format('dd DD.MM')}
                </WeekDay>
              </HeadingWrapper>
              {sortedMealTypes.map(({ label, mealType }, index) => {
                const mealDescription = mealsByDays.find(el => {
                  return (
                    isSameDay(el.date, weekday.date) && el.mealType === mealType
                  );
                });

                const textToShow =
                  !!mealDescription && mealDescription.dish.nameForClient;

                const ingredients = mealDescription?.dish?.lowestDishSize?.ingredientsSortedByUsage
                  .map(({ name }) => name ?? '')
                  .filter(ingredient => ingredient !== '')
                  .join(', ');

                return (
                  <MealCellWrapper isVisible={isLoading} key={index}>
                    {mealDescription && !weekday.disabled ? (
                      <MealCell
                        iri={mealDescription?.['@id']}
                        label={label}
                        color={mainColor}
                        isLoading={isLoading}
                        textToShow={textToShow}
                        ingredients={ingredients}
                        mealDescription={mealDescription}
                        showDishIngredients={showDishIngredients}
                        showMealIngredients={handleMealIngredientsModalToggle}
                      />
                    ) : (
                      <MealWrapper
                        isLoading={isLoading}
                        active={isSameDay(new Date(), weekday.date)}
                      >
                        <Label>{label}</Label>
                        <MealCellTitle isVisible={isLoading}>
                          {weekday.disabled
                            ? t('$*menu.noDelivery', 'Brak dostaw')
                            : t('$*menu.availableSoon', 'Dostępne wkrótce')}
                        </MealCellTitle>
                      </MealWrapper>
                    )}
                  </MealCellWrapper>
                );
              })}
            </WeekDayWrapper>
          ))}
        </Slider>
      </SliderWrapper>
      <Modal
        id={'allWeekComponentDishIngredients'}
        title={''}
        isOpened={isMealIngredientsModalOpened}
        toggleModal={handleMealIngredientsModalToggle}
        withBackButton
        widthDesktop="520px"
        widthMobile="300px"
        contentPadding="0 40px"
        contentPaddingMobile="0 5px"
      >
        <div style={{ textAlign: 'center' }}>{mealIngredientsText}</div>
      </Modal>
    </>
  );
};

export default compose(
  withRouter,
  withLocalUrl,
  withTranslation(),
  connect(
    ({
      calendar: { includeSaturdays, includeSundays },
      app: {
        brand: {
          mainColor,
          menuBanner,
          monday,
          tuesday,
          wednesday,
          thursday,
          friday,
          saturday,
          sunday,
        },
        config: {
          modules: { MenuPage },
        },
      },
      orderForm: {
        orderConfig: { disabledDays },
      },
    }) => ({
      mainColor,
      menuBanner,
      disabledDays,
      MenuPage,
      daysConfig: {
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday: includeSaturdays ? saturday : 0,
        sunday: includeSundays ? sunday : 0,
      },
    }),
    null
  )
)(AllWeekComponent);
