import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { prop } from 'ramda';
import { withRouter } from 'react-router-dom';
import { tdconversion } from 'utils/td';
import { pushPurchasePollsterEvent } from 'utils/pollsterTrack';
import { confirmOrderFromOrderStatus } from 'utils/gtm';
import { addTransactionGA, enhancedEcommercePurchase } from 'utils/ga';
import { confirmOrderFP } from 'utils/facebookPixel';

import URLS from 'routes/routeUrls';

import Confirmation from 'styledComponents/elements/NewOrder/Confirmation';

import { getPaymentMethods } from '../actions/orderFormActions';

import {
  BankTransferPaymentConfirmation,
  CashPaymentConfirmation,
} from '../components/confirmation';
import { useTranslation } from 'react-i18next';
import { symbolToCurrency } from 'utils/currencies';

const getConfirmationDetailsView = (paymentMethodId, payload, t) => {
  switch (paymentMethodId) {
    case 'BANK_WIRE':
      return <BankTransferPaymentConfirmation payload={payload} t={t} />;
    case 'CASH':
      return <CashPaymentConfirmation payload={payload} />;
    default:
      return null;
  }
};

const ConfirmationSuccess = ({
  paymentMethods,
  paymentMethodId,
  order,
  orderStatus,
  currencySymbol,
  modules,
  disableTracking,
}) => {
  const { t } = useTranslation();
  const currentPaymentMethod = paymentMethods.find(
    ({ type }) => type === paymentMethodId
  );
  const dietDayToPushTo = localStorage.getItem('dietDayToPay');

  const currency = symbolToCurrency(currencySymbol);
  const {
    FacebookPixel,
    PollsterTrack,
    ClickOnMetrics,
    GoogleAnalytics,
    GoogleTagManager,
  } = modules;

  let orderData;

  if (orderStatus?.id !== undefined && order?.id !== undefined) {
    orderData = orderStatus.id > order.id ? orderStatus : order;
  } else {
    orderData = orderStatus.id !== undefined ? orderStatus : order;
  }

  const paymentType = orderData.paymentType;

  let revenue = '';

  if (paymentType === 'STRIPE_CARD') {
    revenue =
      orderData.priceLeftToPay === 0 && orderData.pricePayed !== 0
        ? orderData.pricePayed
        : orderData.priceLeftToPay;
  } else {
    revenue =
      paymentType === 'BANK_WIRE' || paymentType === 'CASH'
        ? orderData.priceLeftToPay
        : orderData.pricePayed;
  }

  useEffect(() => {
    if (revenue !== null && revenue >= 0 && !disableTracking) {
      if (
        GoogleTagManager?.enabled &&
        (GoogleTagManager?.useEcommerce ||
          GoogleTagManager?.useEnhancedEcommerce)
      ) {
        confirmOrderFromOrderStatus(orderData, revenue);
      }
      if (GoogleAnalytics?.enabled) {
        if (GoogleAnalytics.useEnhancedEcommerce)
          enhancedEcommercePurchase(orderData, revenue);
        if (GoogleAnalytics.useEcommerce) addTransactionGA(order.id, revenue); // ! needs adding addItem data
      }
      if (FacebookPixel?.enabled) {
        confirmOrderFP(orderData.id, revenue, currency);
      }
      if (PollsterTrack?.enabled) {
        pushPurchasePollsterEvent(orderData, revenue);
      }
    }

    return tdconversion(orderData.id, orderData.revenue);
  }, [
    order,
    orderStatus,
    paymentMethodId,
    currency,
    GoogleTagManager?.enabled,
    GoogleTagManager?.useEcommerce,
    GoogleAnalytics?.enabled,
    GoogleAnalytics?.useEnhancedEcommerce,
    FacebookPixel?.enabled,
    disableTracking,
  ]);

  return (
    <Confirmation>
      <Confirmation.Title>
        {t('$*confirmationSuccess.title', 'Dziękujemy!')}
      </Confirmation.Title>
      <Confirmation.Subtitle>
        {t(
          '$*confirmationSuccess.label',
          'Potwierdzamy, że otrzymaliśmy Twoje zamówienie.'
        )}
      </Confirmation.Subtitle>
      <Confirmation.Info>
        {getConfirmationDetailsView(
          paymentMethodId,
          prop('payload', currentPaymentMethod),
          t
        )}
      </Confirmation.Info>

      <Confirmation.Link
        to={dietDayToPushTo ? dietDayToPushTo : URLS.USER_DIET}
      >
        {dietDayToPushTo
          ? t('$*common.continue', 'Kontynuuj')
          : t('$*header.myAccount', 'Moje konto')}
      </Confirmation.Link>

      {ClickOnMetrics?.enabled && (
        <img
          src={`https://delivery.clickonometrics.pl/tracker=${ClickOnMetrics?.trackerId}/track/lead/track.gif`}
          alt=""
        />
      )}
    </Confirmation>
  );
};

export default compose(
  connect(
    ({
      app: {
        config: {
          multinational: { currencySymbol },
          modules,
        },
      },
      nativeAppConfig: { disableTracking },
      orderForm: { paymentMethods, paymentMethodId, order, orderStatus },
    }) => ({
      paymentMethods,
      paymentMethodId,
      order,
      orderStatus,
      currencySymbol,
      modules,
      disableTracking,
    }),
    { getPaymentMethods }
  ),
  withRouter
)(ConfirmationSuccess);
