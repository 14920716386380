import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Flex, Box } from '@rebass/grid';
import { prop, isEmpty } from 'ramda';

import URLS from 'routes/routeUrls';

import PAYMENT_STATUSES from '../const/paymentStatuses';

import Spinner from 'styledComponents/elements/LoadingSpinner';
import Confirmation from 'styledComponents/elements/NewOrder/Confirmation';

import { checkOrderStatus } from '../actions/orderFormActions';
import { withTranslation } from 'react-i18next';
import { translatePaymentStatuses } from 'views/MyOrders/containers/MyOrdersContent';
import { buildUrl } from 'utils/route';
import PaymentCardFormPayU from 'common/components/PaymentCard/PaymentCardFormPayU';

const INTERVAL_DELAY = 2000;
const MAX_WAIT_TIME = 0.5 * 60 * 1000; // 30 seconds

class PaymentStatus extends Component {
  state = {
    timerStartTime: 0,
    cvvVerification: false,
    isCvvVerificationDone: false,
  };

  componentWillMount() {
    const {
      match: {
        params: { orderId },
      },
      checkOrderStatus,
      history,
    } = this.props;

    let queryStatus = new URLSearchParams(this.props.location.search).get(
      'status'
    );

    if (queryStatus) {
      if (queryStatus === 'OK') {
        history.push(URLS.NEW_ORDER_CONFIRMATION);
      } else {
        history.push(URLS.NEW_ORDER_ERROR);
      }
    }

    checkOrderStatus(orderId);

    this.startInterval();
  }

  componentDidUpdate(prevProps) {
    const { history, orderStatus } = this.props;
    const status = orderStatus?.status ?? {};

    if (
      status?.systemValue === PAYMENT_STATUSES.WAITING &&
      orderStatus?.paymentIntentAction?.type === 'VERIFY' &&
      !this.state.isCvvVerificationDone
    ) {
      return !this.state.cvvVerification ? this.showCvvForm() : null;
    }

    if (
      orderStatus.orderItemsProcessFinished &&
      status?.systemValue === PAYMENT_STATUSES.PAID
    ) {
      history.push(
        buildUrl(URLS.NEW_ORDER_PREPARING, { orderId: orderStatus.id })
      );
    }

    if (['BANK_WIRE', 'CASH'].includes(orderStatus?.paymentType)) {
      history.push(URLS.NEW_ORDER_CONFIRMATION);
    }

    if (status?.systemValue !== prevProps.orderStatus?.status?.systemValue) {
      this.redirectDependingOnStatus();
    }

    if (this.state.timerStartTime >= MAX_WAIT_TIME) {
      history.push(URLS.NEW_ORDER_ERROR);
    }
  }

  componentWillUnmount() {
    this.clearInterval();
  }

  redirectDependingOnStatus() {
    const { history, orderStatus } = this.props;
    const status = orderStatus?.status ?? {};

    if (status.systemValue === PAYMENT_STATUSES.PAID) {
      return false
        ? history.push(URLS.NEW_ORDER_CONFIRMATION)
        : history.push(
            buildUrl(URLS.NEW_ORDER_PREPARING, { orderId: orderStatus.id })
          );
    }

    if (status.systemValue === PAYMENT_STATUSES.CANCELED) {
      return history.push(URLS.NEW_ORDER_FAILED);
    }

    return;
  }

  showCvvForm = () => {
    this.setState(
      {
        timerStartTime: 0,
        cvvVerification: true,
      },
      () => this.clearInterval()
    );
  };

  fetchOrderStatus = () => {
    const {
      match: {
        params: { orderId },
      },
      checkOrderStatus,
    } = this.props;

    this.setState(
      {
        timerStartTime: this.state.timerStartTime + INTERVAL_DELAY,
      },
      () => {
        if (this.shouldClearInterval()) {
          this.clearInterval();

          return;
        }

        checkOrderStatus(orderId);
      }
    );
  };

  clearInterval = () => {
    clearInterval(this.orderStatusInterval);
  };

  startInterval = () => {
    this.orderStatusInterval = setInterval(
      () => this.fetchOrderStatus(),
      INTERVAL_DELAY
    );
  };

  shouldClearInterval = () => {
    const { orderStatus } = this.props;
    const status = orderStatus?.status ?? {};

    return (
      this.state.timerStartTime >= MAX_WAIT_TIME ||
      (status.systemValue && status.systemValue !== PAYMENT_STATUSES.WAITING)
    );
  };

  render() {
    const { t, orderStatus } = this.props;
    const status = orderStatus?.status ?? {};

    if (this.state.cvvVerification) {
      return (
        <Flex justifyContent="center">
          <Box>
            <Confirmation>
              <Confirmation.Title>
                {t('$*paymentStatus.verifyCVV', '$$Potwierdź CVV:')}
              </Confirmation.Title>
              <PaymentCardFormPayU
                isCVVForm={true}
                paymentIntentURL={
                  orderStatus?.paymentIntentAction?.payload?.url ?? ''
                }
                cvvHandleCallback={() => {
                  this.setState(
                    {
                      cvvVerification: false,
                      isCvvVerificationDone: true,
                    },
                    () => {
                      this.startInterval();
                    }
                  );
                }}
              />
            </Confirmation>
          </Box>
        </Flex>
      );
    }

    return (
      !isEmpty(status) && (
        <Flex justifyContent="center">
          <Box>
            <Confirmation>
              <Confirmation.Title>
                {t('$*paymentStatus.title', 'Status płatności:')}
              </Confirmation.Title>
              <Confirmation.Subtitle>
                {translatePaymentStatuses(t)[prop('systemValue', status)] ||
                  prop('value', status)}
              </Confirmation.Subtitle>
              <Spinner />
            </Confirmation>
          </Box>
        </Flex>
      )
    );
  }
}

export default compose(
  withTranslation(),
  connect(
    ({
      app: {
        config: {
          multinational: {
            defaultRegion: { currencyCode },
          },
        },
      },
      orderForm: { orderStatus },
    }) => ({ orderStatus, currencyCode }),
    {
      checkOrderStatus,
    }
  ),
  withRouter
)(PaymentStatus);
