import store from 'store.js';
import { prop } from 'ramda';
import history from 'history.js';

export const getDietInfoFromStore = () => {
  const { dietForm } = store.getState();

  return Object.values(dietForm.forms);
};

const addEventToGTM = event => {
  if (!window || !window.dataLayer) return;
  window.dataLayer.push(event);
};

export const flushDataLayer = () => {
  if (
    window.dataLayer !== undefined &&
    window.google_tag_manager !== undefined
  ) {
    window.dataLayer.length = 0;
    const gtmContainerReg = /GTM-/i;

    for (const gtmKey of Object.keys(window.google_tag_manager)) {
      if (
        gtmContainerReg.test(gtmKey) &&
        window.google_tag_manager[gtmKey].dataLayer &&
        window.google_tag_manager[gtmKey].dataLayer.reset
      ) {
        window.google_tag_manager[gtmKey].dataLayer.reset();
      }
    }
  }
};

const getDietName = dietId =>
  store.getState().orderForm.orderConfig.diets.find(diet => diet.id === +dietId)
    .name;
const getDietVariant = variantId =>
  store
    .getState()
    .orderForm.orderConfig.variants.find(variant => variant.id === +variantId)
    .name;
const getDietCalorific = (variantId, calorificId) =>
  store
    .getState()
    .orderForm.orderConfig.variants.find(variant => variant.id === +variantId)
    .calories.find(calory => calory.id === calorificId).name;

export const createProductSchema = (orderInfo, index) => {
  const brandName = store.getState().app.brand.name;
  const dietName = getDietName(+orderInfo.diet);
  const dietVariant = getDietVariant(+orderInfo.variant);
  const dietCalorific = getDietCalorific(
    +orderInfo.variant,
    +orderInfo.calorific
  );
  const dietPrice =
    store.getState().orderForm.calculations.dietCosts[index].priceAfterDiscount;

  return {
    id: `${dietName} - ${dietVariant} - ${dietCalorific}`,
    name: dietName,
    brand: brandName,
    price: dietPrice,
    variant: dietVariant,
    category: dietCalorific,
    quantity: 1,
  };
};

export const createProductSchemaFromStatusItem = statusItem => {
  const orderType = statusItem.type;
  let dietName, dietVariant, dietCalorific, dietPrice;
  const brandName = store.getState().app.brand.name;

  switch (orderType) {
    case 'BagModification':
      // @todo
      return { id: `Bag/s Modification` };
    case 'BuyBag':
      // @todo
      return { id: `Subscription renewal` };
    case 'DietItem':
      dietName = statusItem.diet.diet.name;
      dietVariant = statusItem.diet.variant.name;
      dietCalorific = statusItem.diet.calorific.name;
      dietPrice = statusItem.priceAfterDiscount;
      break;
    default:
      return { id: `Unspecified order` };
  }

  // might need to use priceBeforeDiscount, needs info

  return {
    id: `${dietName} - ${dietVariant} - ${dietCalorific}`,
    name: dietName,
    brand: brandName,
    price: dietPrice,
    variant: dietVariant,
    category: dietCalorific,
    quantity: 1,
  };
};

export const createGTM = id => {
  const script = document.createElement('script');

  script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${id}');`;

  const script2 = document.createElement('noscript');

  script2.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${id}"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

  document.head.appendChild(script);
  document.body.appendChild(script2);
};

export const addToCart = (formId, currencyCode) => {
  const state = store.getState();
  const { enabled, useEcommerce, useEnhancedEcommerce } =
    state.app.config.modules.GoogleTagManager || {};
  const orderInfo = getDietInfoFromStore()[formId];
  const products = createProductSchema(orderInfo, formId);

  const addToCartEvent = {
    event: 'addToCart',
    ecommerce: {
      currencyCode: currencyCode,
      add: {
        products,
      },
    },
  };

  if (enabled && useEnhancedEcommerce) {
    addEventToGTM(addToCartEvent);
  }
};

export const beginCheckoutGTM = (orderId, priceLeftToPay, discountCode) => {
  const state = store.getState();
  const { enabled, useEnhancedEcommerce } =
    state.app.config.modules.GoogleTagManager || {};
  if (enabled && useEnhancedEcommerce) {
    const orderEvent = {
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: {
            step: 1,
            option: {
              id: orderId,
              revenue: priceLeftToPay,
              coupon: discountCode?.code || {},
            },
          },
          products: getDietInfoFromStore().map(createProductSchema),
        },
      },
    };

    addEventToGTM(orderEvent);
  }
};

export const confirmOrderFromOrderStatus = (orderStatus, revenue) => {
  const state = store.getState();
  const { enabled, useEcommerce, useEnhancedEcommerce } =
    state.app.config.modules.GoogleTagManager || {};
  const { id, discountCode, items } = orderStatus;

  const isBagModification = items.some(item => item.type === 'BagModification');

  // ! BuyBag - subscription new bag order
  // ! BagModification - bag attribs change - including buying addons
  // ! New bag from subscription - not accounted for for now since we don't track this in GA

  const orderEvent = {
    event: 'purchase',
    ecommerce: {
      purchase: {
        email: state?.auth?.user?.email || '',
        actionField: {
          id,
          affiliation: '0',
          revenue: revenue,
          coupon: discountCode?.code || {},
          tax: '0',
          shipping: '0',
        },
        paymentType: orderStatus?.paymentType ?? null,
        products: !isBagModification
          ? items.map(createProductSchemaFromStatusItem)
          : 'Bag/s Modification',
      },
    },
  };

  if (enabled && (useEcommerce || useEnhancedEcommerce)) {
    addEventToGTM(orderEvent);
  }
};

export const userLogin = userId => {
  const loginEvent = {
    event: 'login',
    userId: userId,
  };

  addEventToGTM(loginEvent);
};

export const pushAuthenticationEvent = userId => {
  const event = {
    userId,
    event: 'authentication',
  };

  addEventToGTM(event);
};

export const pushGTMCheckoutStepChangeEvent = (tabIndex, stepName) => {
  const checkoutStepChangeEvent = {
    event: 'stepChange',
    stepName: stepName,
    stepNumber: tabIndex + 1,
  };

  addEventToGTM(checkoutStepChangeEvent);
};

export const pushGTMPageViewEvent = linkToPage => {
  const state = store.getState();
  const { enabled } = state.app.config.modules.GoogleTagManager || {};

  let pageViewEvent = {
    event: 'pageView',
  };

  if (enabled) {
    if (linkToPage) pageViewEvent.link = linkToPage;
    addEventToGTM(pageViewEvent);
  }
};
