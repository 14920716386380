export const LOGIN_REQUEST = 'auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'auth/LOGIN_FAILURE';

export const REFRESH_LOGIN_REQUEST = 'auth/REFRESH_LOGIN_REQUEST';
export const REFRESH_LOGIN_SUCCESS = 'auth/REFRESH_LOGIN_SUCCESS';
export const REFRESH_LOGIN_FAILURE = 'auth/REFRESH_LOGIN_FAILURE';

export const REFRESH_USER_TOKENS = 'auth/REFRESH_USER_TOKENS';

export const REGISTER_REQUEST = 'auth/REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'auth/REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'auth/REGISTER_FAILURE';

export const LOGOUT_REQUEST = 'auth/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'auth/LOGOUT_FAILURE';

export const SWITCH_USER = 'auth/SWITCH_USER';
export const SET_CREDIT_CARDS = 'auth/SET_CREDIT_CARDS';
export const DELETE_CREDIT_CARD = 'auth/DELETE_CREDIT_CARD';

export const SET_ALL_PAYMENT_CARDS = 'auth/SET_ALL_PAYMENT_CARDS';
