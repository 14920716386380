import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PaymentCard from 'common/components/PaymentCard/PaymentCard';
import PaymentCardForm from 'common/components/PaymentCard/PaymentCardForm';
import PaymentCardFormPayU from 'common/components/PaymentCard/PaymentCardFormPayU';
import FormSection from 'common/components/Form/FormSection';

import { setNextTabDisability } from '../components/OrderFormTabs/actions/orderFormTabsActions';
import { useCurrentTabNames } from '../components/OrderFormTabs/useCurrentTabNames';
import withPaymentCardsConsumer from 'common/components/HOC/withPaymentCardsConsumer';

const OrderFormCardPayment = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    paymentMethodId,
    allPaymentCards,
    isFetchedAllPaymentCards,
  } = useSelector(
    ({
      auth: { allPaymentCards, isFetchedAllPaymentCards } = {},
      orderForm: { paymentMethodId } = {},
    }) => ({
      paymentMethodId,
      allPaymentCards,
      isFetchedAllPaymentCards,
    })
  );

  const userCards = allPaymentCards.filter(
    ({ provider }) => provider === paymentMethodId
  );

  const hasCards = (isFetchedAllPaymentCards && userCards.length > 0) || false;

  const [userCard] = userCards;
  useCurrentTabNames('OrderFormCardPayment');

  useEffect(() => {
    dispatch(setNextTabDisability(!hasCards));
  }, [hasCards]);

  return (
    <>
      <FormSection
        title={t('$*OrderFormCardPayment.title', '$$Dane karty kredytowej')}
        flexStyleProps={{ justifyContent: 'center' }}
      >
        {hasCards ? (
          <div style={{ display: 'table', margin: '0 auto' }}>
            <PaymentCard
              id={userCard.index}
              brand={userCard.issuer}
              last4={userCard.mask}
              name={userCard.name || ''}
              provider={userCard.provider}
              expireAt={
                new Date(userCard.validityYear, userCard.validityMonth - 1)
              }
              validatedByProvider={userCard?.validatedByProvider}
            />
          </div>
        ) : (
          <>
            {paymentMethodId === 'STRIPE_CARD' ? (
              <PaymentCardForm />
            ) : paymentMethodId === 'PAYU_CARD' ? (
              <PaymentCardFormPayU />
            ) : null}
          </>
        )}
      </FormSection>
    </>
  );
};

export default withPaymentCardsConsumer(OrderFormCardPayment);
