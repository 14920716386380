import React from 'react';
import delivered from 'assets/svg/calendar/delivered.svg';
import { connect } from 'react-redux';
import { BASE_URL, URLS } from '../../../../configuration';

const DeliveredIcon = ({ ConfigClientPanel }) => {
  const hasDeliveredIcon = ConfigClientPanel.calendarIconDelivered !== null;
  const { showCalendarIcons } = ConfigClientPanel;
  const src =
    hasDeliveredIcon &&
    `${BASE_URL}${URLS.UPLOADED_MEDIA}${
      ConfigClientPanel['@resources'][ConfigClientPanel.calendarIconDelivered]
        ?.contentUrl
    }`;

  if (!hasDeliveredIcon || !showCalendarIcons) return null;
  return <img className="calendar-icon" width="100%" src={src} />;
};

export default connect(
  ({
    app: {
      config: {
        modules: { ConfigClientPanel },
      },
    },
  }) => ({ ConfigClientPanel }),
  null
)(DeliveredIcon);
