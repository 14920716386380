import React from 'react';
import { connect } from 'react-redux';
import { BASE_URL, URLS } from '../../../../configuration';

const EditIcon = ({ ConfigClientPanel }) => {
  const hasEditIcon = ConfigClientPanel.calendarIconChangeMenu !== null;
  const { showCalendarIcons } = ConfigClientPanel;
  const src =
    hasEditIcon &&
    `${BASE_URL}${URLS.UPLOADED_MEDIA}${
      ConfigClientPanel['@resources'][ConfigClientPanel.calendarIconChangeMenu]
        ?.contentUrl
    }`;

  if (!hasEditIcon || !showCalendarIcons) return null;
  return <img className="calendar-icon" width="100%" src={src} />;
};

export default connect(
  ({
    app: {
      config: {
        modules: { ConfigClientPanel },
      },
    },
  }) => ({ ConfigClientPanel }),
  null
)(EditIcon);
