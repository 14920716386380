import React, { Component } from 'react';
import StyledBasket from './Basket';

class MobileBasket extends Component {
  render() {
    const {
      dietCosts,
      className,
      multinational: { currencySymbol },
      t,
    } = this.props;

    const priceBeforeDiscount = dietCosts.reduce(
      (prev, curr) => curr.priceBeforeDiscount + prev,
      0
    );

    const priceAfterDiscount = dietCosts.reduce(
      (prev, curr) => curr.priceAfterDiscount + prev,
      0
    );

    return (
      <StyledBasket.Mobile className={className}>
        <StyledBasket.ItemWrapper
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          <StyledBasket.ItemEntry isGroupHeader={true}>
            <span>{t('$*basket.labelTotalPrice', '$$Cena łączna')}</span>

            <StyledBasket.ItemEntryPriceRow>
              {priceBeforeDiscount !== priceAfterDiscount && (
                <StyledBasket.ItemEntryOldPrice>
                  {priceBeforeDiscount.toFixed(2)} {currencySymbol}
                </StyledBasket.ItemEntryOldPrice>
              )}

              <StyledBasket.ItemEntryNewPrice>
                {priceAfterDiscount.toFixed(2)} {currencySymbol}
              </StyledBasket.ItemEntryNewPrice>
            </StyledBasket.ItemEntryPriceRow>
          </StyledBasket.ItemEntry>
        </StyledBasket.ItemWrapper>
      </StyledBasket.Mobile>
    );
  }
}

export default MobileBasket;
