import { DateUtils } from 'react-day-picker';
import {
  addDays,
  getISODay,
  isSameISOWeek,
  isSaturday,
  isSunday,
} from 'date-fns';
import { difference } from 'ramda';

import { TODAY, WEEKDAYS_INDEXES } from 'common/shared/weekdays';
import { getSelectedDaysByRange } from 'common/services/calendarService';

function getDaysFromTheSameWeek(day, selectedDays) {
  return selectedDays.filter(selectedDay => isSameISOWeek(selectedDay, day));
}

function getMatchingDaysForChosenDay({ days, daysConfig, chosenDay }) {
  const chosenDayOfWeekNumber = getISODay(chosenDay);

  return days.filter(day => {
    const dayOfWeekNumber = getISODay(day);
    return (
      daysConfig[WEEKDAYS_INDEXES[chosenDayOfWeekNumber]] ===
      daysConfig[WEEKDAYS_INDEXES[dayOfWeekNumber]]
    );
  });
}

function getDaysToSelect({
  day,
  duration,
  daysConfig,
  selectedDays,
  excludedDays,
  includeSaturdays,
  includeSundays,
}) {
  const daysToSelect = getSelectedDaysByRange({
    day,
    duration,
    daysConfig,
    includeSaturdays,
    includeSundays,
  });
  const matchingDays = getMatchingDaysForChosenDay({
    days: daysToSelect,
    daysConfig,
    chosenDay: day,
  });
  const matchingDaysForTheSameWeek = getDaysFromTheSameWeek(day, matchingDays);
  const isMatchingDaysLeftToSelect =
    matchingDaysForTheSameWeek.length + selectedDays.length <= duration ||
    selectedDays.length === 0;

  const enabledDays = difference(matchingDaysForTheSameWeek, excludedDays);
  const areEnabledDaysEqualToMatchingDays =
    matchingDaysForTheSameWeek.length === enabledDays.length;

  return isMatchingDaysLeftToSelect && areEnabledDaysEqualToMatchingDays
    ? matchingDaysForTheSameWeek
    : [];
}

export const getSelectedDays = (
  selectedDays,
  day,
  { selected },
  daysConfig,
  excludedDays,
  duration,
  includeSaturdays,
  includeSundays
) => {
  if (selected) {
    const daysToUnselect = getMatchingDaysForChosenDay({
      days: getDaysFromTheSameWeek(day, selectedDays),
      daysConfig,
      chosenDay: day,
    });

    return difference(selectedDays, daysToUnselect);
  } else {
    const daysToSelect = getDaysToSelect({
      day,
      duration,
      daysConfig,
      selectedDays,
      excludedDays,
      includeSaturdays,
      includeSundays,
    });

    return [...selectedDays, ...daysToSelect];
  }
};

export function getDayIndex(selectedDays, day) {
  return selectedDays.findIndex(selectedDay =>
    DateUtils.isSameDay(selectedDay, day)
  );
}

export function getSelectedDaysWithoutSaturdays(selectedDays) {
  return selectedDays.filter(day => !isSaturday(day));
}

export function getSelectedDaysWithoutSundays(selectedDays) {
  return selectedDays.filter(day => !isSunday(day));
}

export const getDaysConfig = getState => {
  const {
    app: {
      brand: { monday, tuesday, wednesday, thursday, friday, saturday, sunday },
    },
  } = getState();

  return {
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday,
  };
};

export const getInitialMonth = (disabledDays, disabledWeekDays) => {
  let firstSelectableDay = TODAY;
  firstSelectableDay.setHours(0, 0, 0, 0);
  let isDaySelectable = false;

  while (!isDaySelectable) {
    isDaySelectable =
      disabledDays.filter(
        day => day.toString() === firstSelectableDay.toString()
      ).length === 0 && disabledWeekDays[firstSelectableDay.getDay()] !== 0;

    if (!isDaySelectable) {
      firstSelectableDay = addDays(firstSelectableDay, 1);
    }
  }

  return firstSelectableDay;
};

export const mapLanguageToDatePickerLocale = language =>
  language === 'ua' ? 'uk' : language;
