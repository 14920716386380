import React from 'react';

import {
  StyledCheckboxExtended,
  StyledCheckboxOptions,
} from 'styledComponents/elements/Form/FormCheckbox';

const CheckboxSwitch = ({ input, disabled, label }) => (
  <StyledCheckboxOptions extended>
    {!input.checked && (
      <StyledCheckboxExtended inactive>
        <i className="fas fa-check" />
      </StyledCheckboxExtended>
    )}
    <StyledCheckboxExtended disabled={disabled} active={input.checked}>
      {label}
    </StyledCheckboxExtended>
    {input.checked && (
      <StyledCheckboxExtended>
        <i style={{ color: 'black' }} className="fas fa-check" />
      </StyledCheckboxExtended>
    )}

    <input type="checkbox" {...input} />
  </StyledCheckboxOptions>
);

export default CheckboxSwitch;
