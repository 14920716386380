import React from 'react';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';

import Modal from 'common/components/Modal';
import FormFieldBox from 'common/components/Form/FormFieldBox';
import NOTIFICATIONS from 'common/shared/notifications';

import { ButtonLink } from 'styledComponents/elements/Button';
import { Paragraph } from 'styledComponents/elements/Typography';
import {
  INPUT_IDS,
  useBasicInformationFormInputs,
} from 'views/AccountSettings/const/userDataFormInputs';
import { updateUserData } from 'views/AccountSettings/actions/accountSettingsActions';

import showToast from 'utils/toast';
import { useTranslation } from 'react-i18next';

const MissingUserNameModal = ({ isOpened, toggleModal, updateUserData }) => {
  const { t } = useTranslation();
  const onSubmit = (form, { getState }) => {
    const { dirtyFields } = getState();
    const changedFields = Object.keys(dirtyFields);

    return updateUserData(form, changedFields).then(() => {
      toggleModal();
      showToast({
        message: NOTIFICATIONS(t).DATA_UPDATED_SUCCESSFULLY,
        type: 'success',
      });
    });
  };

  const BASIC_INFORMATION_FORM_INPUTS = useBasicInformationFormInputs();

  const { ...firstNameInput } = BASIC_INFORMATION_FORM_INPUTS.find(
    input => input.id === INPUT_IDS.FIRST_NAME
  );

  const { ...lastNameInput } = BASIC_INFORMATION_FORM_INPUTS.find(
    input => input.id === INPUT_IDS.LAST_NAME
  );

  return (
    <Modal
      id={isOpened}
      title={t('$*common.caution')}
      padding="25px 0"
      widthMobile="300px"
      isOpened={isOpened}
      widthDesktop="400px"
      contentPadding="0 30px"
      toggleModal={() => {}}
    >
      <Paragraph
        textAlign="center"
        dangerouslySetInnerHTML={{
          __html: t(
            '$*missingUserNameModal.info',
            `$$Zalogowałeś się korzystając z autoryzacji Apple.<br/> Proszę uzupełnij swoje dane!`
          ),
        }}
      />
      <Form
        onSubmit={onSubmit}
        initialValues={{ phone: null }}
        render={({ handleSubmit, submitting, pristine, valid }) => (
          <form onSubmit={handleSubmit}>
            <FormFieldBox width="100%">
              <Field isBig {...firstNameInput} />
            </FormFieldBox>
            <FormFieldBox width="100%">
              <Field isBig {...lastNameInput} />
            </FormFieldBox>

            <ButtonLink
              type="submit"
              uppercased
              sizeMiddle
              weightBold
              autoHeight
              disabled={submitting || pristine || !valid}
              fullWidthOnMobile
              style={{ display: 'table', marginLeft: 'auto' }}
            >
              {t('$*common.save', '$$Zapisz')}
            </ButtonLink>
          </form>
        )}
      />
    </Modal>
  );
};

export default connect(null, { updateUserData })(MissingUserNameModal);
