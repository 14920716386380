import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { get } from 'utils/http';
import { URLS } from 'configuration';
import { setAllPaymentCards } from 'views/Auth/actions/authActions';

const withPaymentCardsConsumer = Component => props => {
  const dispatch = useDispatch();
  const token = useSelector(({ auth: { token } }) => token);

  const fetchCards = async () => {
    const { data: allPaymentCards } = await get(`${URLS.ALL_PAYMENT_CARDS}`);
    dispatch(setAllPaymentCards(allPaymentCards['hydra:member']));
  };

  useEffect(() => {
    if (token !== null) fetchCards();
  }, [token]);

  return <Component {...props} />;
};

export default withPaymentCardsConsumer;
