import React from 'react';

import {
  StyledFormRadioLabel,
  StyledFormRadioHorizontalList,
  StyledFormRadioHorizontalListItem,
} from 'styledComponents/elements/Form/FormRadio';

const FormRadioHorizontalListItem = ({ input, label }) => (
  <StyledFormRadioHorizontalListItem>
    <input type="radio" {...input} />

    <StyledFormRadioLabel checked={input.checked} title={label}>
      <i
        className={`${input.checked ? 'fas fa-check-circle' : 'far fa-circle'}`}
      />
      {label}
    </StyledFormRadioLabel>
  </StyledFormRadioHorizontalListItem>
);

const FormRadioHorizontalList = ({ children }) => (
  <StyledFormRadioHorizontalList>
    {React.Children.map(children, child =>
      React.cloneElement(child, {
        type: 'radio',
        component: FormRadioHorizontalListItem,
      })
    )}
  </StyledFormRadioHorizontalList>
);

export default FormRadioHorizontalList;
