import React from 'react';
import styled, { css } from 'styled-components';
import { lighten } from 'polished';

import { fromPxToRem, media } from 'styledComponents/helpers';

import Spinner from './LoadingSpinner';

export const StyledButton = styled.button`
  float: ${props => (props.isRightAligned ? 'right' : 'none')};
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
  font-size: ${props =>
    (props.sizeMiddle && fromPxToRem('16px')) ||
    (props.sizeSmall && fromPxToRem('12px')) ||
    fromPxToRem('14px')};
  font-weight: ${props =>
    props.weightBold ? props.theme.fontWeight900 : 'initial'};
  text-transform: ${props => (props.uppercased ? 'uppercase' : 'none')};
  font-family: Arial;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: default;
  }

  svg .circle {
    stroke: #fff;
  }
`;

const Anchor = styled.a`
  width: auto;

  ${media.tabletDown`
    width: 100%;
  `};
`;

export const Button = ({
  children,
  href,
  target,
  isLoading,
  withSpinner,
  spinnerSize = '36px',
  ...props
}) => {
  const buttonComponent = (
    <StyledButton {...props}>
      {isLoading && withSpinner ? (
        <Spinner size={fromPxToRem(spinnerSize)} thickness="5" />
      ) : (
        children
      )}
    </StyledButton>
  );

  return href ? (
    <Anchor href={href} target={target}>
      {buttonComponent}
    </Anchor>
  ) : (
    buttonComponent
  );
};

export const ButtonLink = styled(Button)`
  color: ${props => props.theme.baseFontColor};
  width: ${props => props.hasFullWidth && '100%'};
  height: ${props => (props.autoHeight ? 'auto' : '54px')};
  margin: ${props => props.margin || 0};
  font-family: inherit;

  &:hover {
    text-decoration: underline;
  }

  &:disabled {
    color: ${props => props.theme.colorLightBlueGrey};
    text-decoration: none;
  }

  ${media.tabletDown`
    margin: ${props =>
      props.customMargin ? props.customMargin : '20px 0 0 0'};
  `};

  ${props =>
    props.fullWidthOnMobile &&
    css`
      ${media.tabletDown`
        width: 100%;
      `};
    `};

  ${props =>
    props.noMargin &&
    css`
      margin: 0;

      ${media.tabletDown`
        margin: 0;
      `};
    `};
`;

export const ButtonLinkGrey = styled(ButtonLink)`
  color: ${props => props.theme.baseFontColor};
`;

export const ButtonLinkRed = styled(ButtonLink)`
  color: ${props => props.theme.colorRustyRed};
`;

export const ButtonPrimary = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${props => props.padding || (props.sizeSmall ? '0 6px' : 'inherit')};
  background-color: ${props => props.theme.baseFontColor};
  border-radius: ${props => props.theme.baseBorderRadius};
  width: ${props =>
    (props.hasFullWidth && '100%') ||
    (props.sizeSmall && 'unset') ||
    props.width ||
    '165px'};
  height: ${props => (props.sizeSmall ? '30px' : '54px')};
  color: ${props => props.theme.colorWhite};
  margin: auto;

  &:hover {
    background-color: ${props => lighten(0.02, props.theme.baseFontColor)};
  }

  &:disabled {
    background-color: ${props => props.theme.colorLightBlueGrey};
  }

  ${props =>
    props.fullWidthOnMobile &&
    css`
      ${media.tabletDown`
        width: 100%;
      `};
    `};
`;

export const ButtonPrimaryOutlined = styled(Button)`
  font-family: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${props => props.padding || (props.sizeSmall ? '0 6px' : 'inherit')};
  background-color: transparent;
  border-radius: ${props => props.theme.baseBorderRadius};
  border: 2px solid;
  border-color: ${props => props.theme.baseFontColor};
  width: ${props =>
    (props.hasFullWidth && '100%') ||
    (props.sizeSmall && 'unset') ||
    props.width ||
    '165px'};
  height: ${props => (props.sizeSmall ? '30px' : '54px')};
  color: ${props => props.theme.baseFontColor};

  &:hover {
    background-color: ${props => props.theme.colorWhite};
  }

  &:disabled {
    background-color: ${props => props.theme.colorLightBlueGrey};
  }

  ${props =>
    props.fullWidthOnMobile &&
    css`
      ${media.tabletDown`
        width: 100%;
      `};
    `};
`;

export const ButtonSecondary = styled(ButtonPrimary)`
  background-color: ${props => props.theme.baseFontColor};
  font-family: inherit;

  &:hover {
    background-color: ${props => lighten(0.02, props.theme.baseFontColor)};
  }
`;

export const ButtonFacebook = styled(ButtonPrimary)`
  position: relative;
  box-sizing: border-box;
  min-height: 50px;
  height: unset;
  width: 290px;
  background-color: ${props => props.theme.colorFacebookBlue};
  padding: 10px 12px 10px 62px;
  justify-content: flex-start;

  span {
    display: block;
    text-align: left;
  }

  [class*='fa-'] {
    position: absolute;
    left: 18px;
    bottom: 13px;
    font-size: ${fromPxToRem('24px')};
  }

  &:hover {
    background-color: ${props => lighten(0.02, props.theme.colorFacebookBlue)};
  }
`;

export const ButtonApple = styled(ButtonPrimary)`
  position: relative;
  box-sizing: border-box;
  min-height: 50px;
  height: unset;
  width: 290px;
  background-color: ${({ theme }) => theme.colorBlack};
  padding: 10px 12px 10px 62px;
  justify-content: flex-start;

  span {
    display: block;
    text-align: left;
  }

  [class*='fa-'] {
    position: absolute;
    left: 16px;
    bottom: 16px;
    font-size: ${fromPxToRem('24px')};
  }

  &:hover {
    background-color: ${props => lighten(0.02, props.theme.colorBlack)};
  }
`;

export const ButtonGoogleWrapper = styled.div`
  span {
    padding-left: 12px;
    text-align: left;
  }
`;
