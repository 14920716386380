import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';

import { fromPxToRem, media } from 'styledComponents/helpers';
import { BadgesGroup } from 'styledComponents/elements/Badge';
import {
  ButtonLinkGrey,
  ButtonLinkRed,
} from 'styledComponents/elements/Button';
import { Heading } from 'styledComponents/elements/Typography';

export const StyledSummaryItem = styled.div`
  border-radius: ${props => props.theme.baseBorderRadius};
  background-color: ${props => props.theme.colorWhite};
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
  border: 2px solid
    ${props => (props.isActive ? props.theme.baseFontColor : 'transparent')};
`;

StyledSummaryItem.InfoWrapper = styled.div`
  margin-top: 25px;
`;

StyledSummaryItem.Info = styled.div`
  display: table-row;
`;

StyledSummaryItem.InfoLabel = styled.div`
  display: table-cell;
  padding-bottom: 10px;
  padding-right: 10px;
  font-weight: 700;
`;

StyledSummaryItem.InfoValue = styled.div`
  display: table-cell;
  padding-bottom: 10px;
  padding-left: 10px;
`;

StyledSummaryItem.Inner = styled.div`
  padding: 25px;
`;

StyledSummaryItem.Buttons = styled.div`
  > ${ButtonLinkGrey}, > ${ButtonLinkRed} {
    margin-right: 30px;
  }
`;

StyledSummaryItem.Address = styled.div`
  margin-top: 6px;
`;

StyledSummaryItem.AddressLabel = styled.span`
  font-weight: ${props => props.theme.fontWeight900};
`;

StyledSummaryItem.Prices = styled.div`
  height: 100%;
  border-left: 1px solid ${props => props.theme.colorIceBlue2};
  font-size: ${fromPxToRem('14px')};

  ${Flex} > ${Box}:last-child {
    font-weight: ${props => props.theme.fontWeight900};
  }

  ${media.tabletDown`
    border-left: none;
    border-top: 1px solid ${props => props.theme.colorIceBlue2};
  `};
`;

StyledSummaryItem.PricesTotal = styled.div`
  margin: 0 -25px;
  padding: 0 25px;
  border-top: 1px solid ${props => props.theme.colorIceBlue2};

  > ${Flex} > ${Box}:last-child {
    color: ${props => props.theme.baseFontColor};
  }
`;

StyledSummaryItem.IconWrapper = styled.div`
  height: 120px;
  position: relative;
  overflow: hidden;
  margin: 25px;
`;

StyledSummaryItem.Icon = styled.img`
  display: block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  height: 120px;
  ${media.tabletDown`
    left: 0;
    transform: translateX(0);
  `};
`;

StyledSummaryItem.Heading = styled(Heading)`
  display: inline;
  vertical-align: bottom;
  ${media.tabletDown`
    vertical-align: top;
  `}
`;

StyledSummaryItem.DietInfo = styled(BadgesGroup)`
  margin-top: 28px;
  display: flex;
  flex-wrap: wrap;
`;

StyledSummaryItem.Separator = styled.div`
  padding-top: 10px;
  border-top: 1px solid ${props => props.theme.colorIceBlue2};
  margin: 10px -25px 0;
`;

StyledSummaryItem.AddonsInfo = styled.div`
  display: flex;
  margin-top: 10px;
`;
StyledSummaryItem.AddonsFirstDay = styled.div`
  flex: 0 0 50%;
`;
StyledSummaryItem.AddonsFirstDayLabel = styled.div`
  font-weight: 600;
`;
StyledSummaryItem.AddonsAnotherDay = styled.div`
  flex: 0 0 50%;
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid ${props => props.theme.colorIceBlue2};
`;
StyledSummaryItem.AddonsAnotherDayLabel = styled.div`
  font-weight: 600;
`;
StyledSummaryItem.AddonsWrapper = styled.div`
  overflow: auto;
  max-height: 67px;
  font-size: ${fromPxToRem(14)};
`;

export const StyledSummaryRow = styled.div`
  padding: 25px 0;
  border-bottom: 1px solid ${props => props.theme.colorIceBlue2};
  font-weight: ${props => props.theme.fontWeight500};
`;

export const StyledTermsAgreementWrapper = styled.div`
  margin-left: auto;
  margin-top: 1rem;
  ${media.tabletUp`
    max-width: 30%;
  `}
`;
