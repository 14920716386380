import React, { Fragment, useState } from 'react';
import ReactDOM from 'react-dom';
import { connect, useSelector } from 'react-redux';
import { Field, useForm } from 'react-final-form';
import { Flex, Box } from '@rebass/grid';
import styled from 'styled-components';

import { media } from 'styledComponents/helpers';
import { SubtitleWithCrossLine } from 'styledComponents/elements/Typography';
import { ButtonLink, ButtonLinkRed } from 'styledComponents/elements/Button';
import { StyledFormRadioBox } from 'styledComponents/elements/Form/FormRadio';

import Tooltip from 'common/components/Tooltip';
import FormRadioGroup from 'common/components/Form/FormRadioGroup';
import Modal from 'common/components/Modal';

import UserAddressesForm from 'views/AccountSettings/components/Form/UserAddressesForm';
import UserAddressesFormWrapper from 'views/AccountSettings/components/Form/UserAddressesFormWrapper';
import { getOrderCost } from 'views/NewOrder/actions/orderFormActions';

import FORM_FIELD_NAMES from '../../const/formFieldNames';
import { DELIVERY_FIELDS } from '../../const/formConfig/deliveryFields.js';

import { getNewAddressId } from '../../services/formService';
import { DisplayOnDesktop } from '../../../../styledComponents/helpers';
import { clearDeliveryHours } from '../../actions/orderFormActions';
import { setDeliveryAddressFormId } from 'views/NewOrder/actions/dietFormActions';
import { getUserAddresses } from 'common/components/UserAddresses/actions/userAddressesActions';
import { useTranslation } from 'react-i18next';
import { selectModuleConfigClientPanel } from 'selectors/appSelectors';

const StyledAddressBoxWrapper = styled(FormRadioGroup)`
  width: 90%;
  margin: 0 auto;

  ${media.tabletDown`
    width: 100%;
  `};
`;

const StyledAddressBoxButton = styled(StyledFormRadioBox)`
  margin-top: 25px;
  width: 30%;

  ${media.tabletDown`
    width: 100%;
    margin: 25px 0 0;
  `};
`;

const ModalPortal = ({ children }) => {
  const el = document.getElementById('modalAddressUser');
  return el ? ReactDOM.createPortal(children, el) : null;
};

const UserAddressesList = ({
  token,
  list,
  forms,
  currentFormId,
  onAddressDelete,
  reloadAddresses,
  canAddNewAddress,
  getOrderCost,
  isLoading,
  clearDeliveryHours,
  setDeliveryAddressFormId,
  getUserAddresses,
  isNewOrder = true,
  setSelectedAddress,
}) => {
  const { t } = useTranslation();
  const [isModalOpened, toggleModal] = useState(false);
  const [editAddress, toggleEditMode] = useState(undefined);
  const { useAddressesWithLessFields } = useSelector(
    selectModuleConfigClientPanel
  );

  const formInstance = useForm();

  const onAfterSubmit = postAddressId => {
    toggleModal(false);
    toggleEditMode(undefined);
    reloadAddresses(token);

    if (postAddressId && isNewOrder) {
      setDeliveryAddressFormId(currentFormId, postAddressId.toString()).then(
        () => {
          getUserAddresses(token).then(() => {
            getOrderCost();
            formInstance.mutators.setFieldValue(
              FORM_FIELD_NAMES.DELIVERY_ADDRESS,
              postAddressId.toString()
            );
          });
        }
      );
    }

    if (!postAddressId) {
      getOrderCost();
    }
  };

  const onEdit = address => {
    toggleModal(true);
    toggleEditMode(address);
  };

  const canDeleteAddress = ({ id, isDefault, containsActiveDiets }) => {
    if (forms[currentFormId]) {
      return (
        forms[currentFormId].address !== id.toString() &&
        !containsActiveDiets &&
        !isDefault &&
        list.length > 1
      );
    }

    return !containsActiveDiets && !isDefault && list.length > 1;
  };

  const ModalContent = (
    <Modal
      id={true}
      title={
        editAddress ? '' : t('$*userAddressesList.header', '$$Dodaj nowy adres')
      }
      isOpened={isModalOpened}
      toggleModal={() => {
        toggleModal();
        toggleEditMode(undefined);
        clearDeliveryHours();
      }}
      widthDesktop="70%"
      fullscreen
      withBackButton
    >
      <UserAddressesFormWrapper
        onAfterSubmit={onAfterSubmit}
        saveLocally={!token}
        initialValues={
          editAddress
            ? editAddress
            : { isDefault: false, id: getNewAddressId(list) }
        }
        doNotReload
      >
        <UserAddressesForm isBlank={true} useContentWrapper={false} />
      </UserAddressesFormWrapper>
    </Modal>
  );

  return (
    <Fragment>
      <StyledAddressBoxWrapper alignItems="center" justifyContent="center">
        {list.map((item, id) => {
          const disabledEditTooltipId = `disabled-edit-tooltip-${item.id}`;
          const [addressLine1 = '', addressLine2 = ''] = item?.street?.split(
            '; '
          ) || [item.addressLine1 || '', item.addressLine2 || ''];

          return (
            <Field
              key={item.id}
              value={item.id.toString()}
              {...DELIVERY_FIELDS[FORM_FIELD_NAMES.DELIVERY_ADDRESS]}
            >
              <SubtitleWithCrossLine>
                <SubtitleWithCrossLine.Text>
                  {`${t(
                    '$*userAddressesList.headerAddressLiteral',
                    '$$Adres'
                  )} ${id + 1}`}
                </SubtitleWithCrossLine.Text>
              </SubtitleWithCrossLine>

              <div>{item.city}</div>
              {useAddressesWithLessFields ? (
                <Fragment>
                  <div>{addressLine1}</div>
                  <div>{addressLine2}</div>
                </Fragment>
              ) : (
                <div>{`${item.street} ${item.buildNumber} ${
                  item.placeNumber
                    ? `${t('$*common.appartmentAbbr', '$$lok.')} ${
                        item.placeNumber
                      }`
                    : ''
                }`}</div>
              )}

              <Flex justifyContent="space-between">
                <Box>
                  <ButtonLink
                    type="button"
                    uppercased
                    weightBold
                    fullWidthOnMobile
                    disabled={item.containsActiveDiets}
                    onClick={() => onEdit(item)}
                  >
                    <span
                      data-for={disabledEditTooltipId}
                      data-tip={disabledEditTooltipId}
                    >
                      {t('$*userAddressesList.edit', '$$Edytuj')}
                    </span>
                  </ButtonLink>
                </Box>

                <DisplayOnDesktop>
                  <Tooltip
                    id={disabledEditTooltipId}
                    hide={!item.containsActiveDiets}
                    maxWidth="200px"
                  >
                    {t(
                      '$*userAddressesList.noAdresWithDietEditTooltip',
                      '$$Nie możesz edytować adresu, na którym masz aktywną inną dietę.'
                    )}
                  </Tooltip>
                </DisplayOnDesktop>

                <Box>
                  {canDeleteAddress(item) && (
                    <ButtonLinkRed
                      type="button"
                      uppercased
                      weightBold
                      fullWidthOnMobile
                      onClick={() =>
                        isLoading ? null : onAddressDelete(item.id, token)
                      }
                    >
                      {t('$*userAddressesList.remove', '$$Usuń')}
                    </ButtonLinkRed>
                  )}
                </Box>
              </Flex>
            </Field>
          );
        })}

        {canAddNewAddress && (
          <StyledAddressBoxButton mx={10} isCentered={true}>
            <ButtonLink
              type="button"
              onClick={() => toggleModal(!isModalOpened)}
            >
              {t('$*userAddressesList.addNew', '$$+ Dodaj nowy adres')}
            </ButtonLink>
          </StyledAddressBoxButton>
        )}
      </StyledAddressBoxWrapper>

      {isModalOpened ? (
        isNewOrder ? (
          ModalContent
        ) : (
          <ModalPortal>{ModalContent}</ModalPortal>
        )
      ) : null}
    </Fragment>
  );
};

export default connect(
  ({
    auth: { token },
    dietForm: { forms, currentFormId },
    userAddresses: { isLoading },
  }) => ({
    token,
    forms,
    currentFormId,
    isLoading,
  }),
  {
    getOrderCost,
    clearDeliveryHours,
    setDeliveryAddressFormId,
    getUserAddresses,
  }
)(UserAddressesList);
