import React from 'react';
import { Field } from 'react-final-form';

import FORM_FIELD_NAMES from '../../const/formFieldNames';
import OrderFormCalendar from '../../components/OrderFormCalendar';

const DietDeliveryDays = ({ ...props }) => (
  <Field
    {...props}
    name={FORM_FIELD_NAMES.DELIVERY_DAYS}
    type="hidden"
    component={OrderFormCalendar}
  />
);

export default DietDeliveryDays;
