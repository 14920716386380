import React from 'react';
import { Box, Flex } from '@rebass/grid';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';

import FormTextInput from 'common/components/Form/FormTextInput';
import { useFormValidation } from 'common/services/formValidation';

import {
  getMenuRating,
  getMealsForRating,
} from '../services/menuPlanningRepresenter';

import DeliveryRating from './DeliveryRating';
import SingleMealRating from './SingleMealRating';

import { DELIVERY_RATING_OPTIONS, INPUT_IDS } from '../const/ratingForm';
import { useTranslation } from 'react-i18next';

const DietDetails = ({ bag, horizontalPadding = 26, meals }) => {
  const { t } = useTranslation();
  const mealsForRating = getMealsForRating(bag);
  const mealRatings = getMenuRating(bag);
  const { required } = useFormValidation();

  return (
    <Form
      initialValues={mealRatings}
      onSubmit={() => {}}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <Flex justifyContent="center">
            <Box px={horizontalPadding} py={0} width="100%">
              {mealsForRating.map(meal => {
                const commentId = `${INPUT_IDS.SINGLE_MEAL_RATING_COMMENT_PREFIX}${meal.dishItemId}`;
                const ratingId = `${INPUT_IDS.SINGLE_MEAL_RATING_PREFIX}${meal.dishItemId}`;

                const mealTypeFromOptions = meals.find(
                  mealFromOptions => +mealFromOptions.id === meal.mealTypeId
                );
                const dishFromOptions =
                  mealTypeFromOptions &&
                  mealTypeFromOptions.dishes.dishes.find(
                    dishFromOptions => dishFromOptions.id === meal.dishId
                  );

                return (
                  <SingleMealRating
                    meal={meal}
                    key={meal.dishId}
                    commentId={commentId}
                    ratingId={ratingId}
                    hiddenComment={!values[commentId]}
                    dishFromOptions={dishFromOptions}
                    disabled
                  />
                );
              })}
            </Box>
          </Flex>
          {values[INPUT_IDS.OVERALL_RATING_COMMENT] && (
            <Box px={horizontalPadding} py={0} width="100%">
              <Field
                name={INPUT_IDS.OVERALL_RATING_COMMENT}
                type="text"
                component={FormTextInput}
                label={`${t(
                  '$*menuPlanning.dietDetails.additionalComment_plural',
                  '$$Dodatkowy komentarze'
                )}:`}
                minHeight="auto"
                disabled
              />
            </Box>
          )}
          {values[INPUT_IDS.DELIVERY_RATING] && (
            <Box px={horizontalPadding} py={0} width="100%">
              <DeliveryRating disabled />
            </Box>
          )}
          {values[INPUT_IDS.DELIVERY_RATING] ===
            DELIVERY_RATING_OPTIONS.BAD && (
            <Box px={horizontalPadding} py={0} width="100%">
              <Field
                name={INPUT_IDS.DELIVERY_RATING_COMMENT}
                type="text"
                component={FormTextInput}
                label={`${t(
                  '$*menuPlanning.dietDetails.additionalComment',
                  '$$Dodatkowy komentarz'
                )}:`}
                minHeight="auto"
                validate={required}
                disabled
              />
            </Box>
          )}
        </form>
      )}
    />
  );
};

export default connect(
  ({ menuPlanning: { meals } }) => ({ meals }),
  null
)(DietDetails);
