import React from 'react';
import { DietDetailsSection } from './';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { getDeliveryDateParam } from '../../../services/menuPlanningRepresenter';
import StyledSingleDetailSection from 'styledComponents/elements/MenuPlanning/SingleDetailSection';
import { compose } from 'recompose';
import { isAfter } from 'date-fns';
import useIsEditable from '../hooks/useIsEditable';

const DEADLINE_BRAND_KEY = 'changeDeliveryDateDeadLine';
const EDITING_ALLOW_KEY = 'allowChangeDeliveryDate';

const getButtonName = (isSubscriptionIntent, isSelectedDayActive) => {
  if (!isSubscriptionIntent)
    return '$*menuPlaning.details.moveToOtherDayCaption';
  if (isSelectedDayActive) return '$*menuPlaning.details.holdDayCaption';
  return '$*menuPlaning.details.resumeDayCaption';
};

const DateOfDelivery = ({ bag, action, t, selectedDietObject }) => {
  const { hasTimePassed, isEditingAllowed } = useIsEditable(
    DEADLINE_BRAND_KEY,
    EDITING_ALLOW_KEY
  );

  const isSelectedDietSubscription = !!selectedDietObject?.subscription;

  const deliveryDate = getDeliveryDateParam(bag);

  return (
    <DietDetailsSection
      title={t('$*menuPlaning.details.dateTitle')}
      deadlineBrandKey={DEADLINE_BRAND_KEY}
      showTooltip={isEditingAllowed && !isSelectedDietSubscription}
      isEditable={!hasTimePassed}
    >
      <p style={{ marginBottom: '12px', fontSize: '15px' }}>{deliveryDate}</p>
      {!hasTimePassed && !isSelectedDietSubscription && isEditingAllowed && (
        <StyledSingleDetailSection.Button
          type="button"
          uppercased
          sizeMiddle
          weightBold
          onClick={action(2)}
        >
          {t('$*menuPlaning.details.moveToOtherDayCaption')}
        </StyledSingleDetailSection.Button>
      )}
    </DietDetailsSection>
  );
};

export default compose(
  withTranslation(),
  connect(
    ({
      menuPlanning: { bag, calendar, selectedDay },
      userDiet: { selectedDietObject },
    }) => ({
      bag,
      calendar,
      selectedDietObject,
      selectedDay,
    })
  )
)(DateOfDelivery);
