import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { Flex, Box } from '@rebass/grid';

import { StyledFormRadioLabel } from 'styledComponents/elements/Form/FormRadio';

import FormRadioHorizontalList from 'common/components/Form/FormRadioHorizontalList';

import { useFormValidation } from 'common/services/formValidation';
import { ButtonLink } from 'styledComponents/elements/Button';
import FORM_FIELD_NAMES from '../../const/formFieldNames';
import { DELIVERY_FIELDS } from '../../const/formConfig/deliveryFields.js';
import { useTranslation } from 'react-i18next';

const DeliveryPickupForm = ({ pickupPoints, pickUpPointInformationLink }) => {
  const { t } = useTranslation();
  const { required } = useFormValidation();
  return (
    <Flex justifyContent="center" flexDirection="column" alignItems="center">
      <Box width={[1, 1, 1]}>
        <StyledFormRadioLabel>
          {t(
            '$*deliveryPickupForm.header',
            '$$Wybierz z listy punkt odbioru *'
          )}
        </StyledFormRadioLabel>
        <FormRadioHorizontalList>
          {pickupPoints.map(pickupPoint => (
            <Field
              key={pickupPoint.id}
              value={pickupPoint.id.toString()}
              label={pickupPoint.value}
              validate={required}
              {...DELIVERY_FIELDS[FORM_FIELD_NAMES.DELIVERY_PICKUP_POINT]}
            />
          ))}
        </FormRadioHorizontalList>
      </Box>
      <Box mt={30}>
        {pickUpPointInformationLink && (
          <ButtonLink
            onClick={e => {
              window.location.href = pickUpPointInformationLink;
            }}
            sizeMiddle
            weightBold
            fullWidthOnMobile
          >
            {t(
              '$*deliveryPickupForm.readAboutPickupPoints',
              '$$Dowiedz się więcej o punktach odbioru'
            )}
          </ButtonLink>
        )}
      </Box>
    </Flex>
  );
};

export default connect(
  ({
    app: {
      brand: { pickUpPointInformationLink },
    },
    orderForm: { pickupPoints },
  }) => ({
    pickupPoints,
    pickUpPointInformationLink,
  })
)(DeliveryPickupForm);
