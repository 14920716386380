export function tdconversion(id, price) {
  if (!getCookie('tdvisit')) {
    /* Przypisz numer zamowienia */

    var numer_zamowienia = id;

    /* Przypisz wartosc zamowienia */

    var wartosc_zamowienia = price;

    /* Przypisz walut? */

    var waluta = 'PLN';

    var program = '299282';

    var event = '389045';

    var cookie = 30;

    if (!numer_zamowienia || numer_zamowienia === '') {
      numer_zamowienia = Math.round(new Date().getTime() * Math.random());
    }

    if (!wartosc_zamowienia || wartosc_zamowienia === '') {
      wartosc_zamowienia = '1';
    }

    var u = `https://tbs.tradedoubler.com/report?program=${program}&event=${event}&orderNumber=${numer_zamowienia}&orderValue=${wartosc_zamowienia}&currency=${waluta}&type=iframe`;

    var c = document.createElement('IFRAME');

    c.setAttribute('src', u);

    c.style.width = 1 + 'px';

    c.style.height = 1 + 'px';

    c.style.border = 'none';

    document.body.appendChild(c);

    setCookie('tdvisit', 'true', cookie, '/', window.location.hostname);
  }
}

function setCookie(name, value, expires, path, domain, secure) {
  var today = new Date();

  today.setTime(today.getTime());

  if (expires) {
    expires = expires * 1000 * 60 * 60 * 24;
  }

  var expires_date = new Date(today.getTime() + expires);

  document.cookie =
    name +
    '=' +
    escape(value) +
    (expires ? '; expires=' + expires_date.toGMTString() : '') +
    (path ? '; path=' + path : '') +
    (domain ? '; domain=' + domain : '') +
    (secure ? '; secure' : '');
}

function getCookie(name) {
  var dc = document.cookie;

  var prefix = name + '=';

  var begin = dc.indexOf('; ' + prefix);

  if (begin === -1) {
    begin = dc.indexOf(prefix);

    if (begin !== 0) return null;
  } else {
    begin += 2;
  }

  var end = document.cookie.indexOf(';', begin);

  if (end === -1) {
    end = dc.length;
  }

  return unescape(dc.substring(begin + prefix.length, end));
}
