import styled from 'styled-components';

import { fromPxToRem } from 'styledComponents/helpers';

const SingleMealRating = styled.div`
  text-align: left;
`;

SingleMealRating.MealType = styled.h5`
  font-weight: 900;
  font-size: ${fromPxToRem('16px')};
  line-height: 1.38rem;
`;

SingleMealRating.DishName = styled.h6`
  color: ${props => props.theme.baseFontColor};
  font-size: ${fromPxToRem('16px')};
  font-weight: 500;
  line-height: 1.38rem;
`;

SingleMealRating.DetailsBaseFont = styled.h6`
  color: ${props => props.theme.baseFontColor};
  font-size: ${fromPxToRem('12px')};
  line-height: 1.38rem;
`;

SingleMealRating.Details = styled.h6`
  font-size: ${fromPxToRem('12px')};
  line-height: 1.38rem;
`;

export default SingleMealRating;
