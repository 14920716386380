import URLS from './routeUrls';
import {
  NewOrderForm,
  ConfirmationSuccess,
  ConfirmationFailed,
  ConfirmationError,
  ConfirmationPreparing,
  PaymentStatus,
} from 'views/NewOrder';
import Menu from 'views/Menu';
import { UserDiet } from 'views/UserDiet';
import { MenuPlanning } from 'views/MenuPlanning';
import { MoneyBox } from 'views/MoneyBox';
import { MyOrders } from 'views/MyOrders';
import { AccountSettings, ShareProgram } from 'views/AccountSettings';
import {
  Auth,
  CreatePassword,
  PasswordRemind,
  SetNewPassword,
  ResetPasswordEmailSent,
  LoginAsUser,
  AppleAuth,
  AppleError,
} from 'views/Auth';
import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

// responsive - temporary flag that determines if we should use fluid container
// in main layout file, can be removed when all views will be adjusted to RWD

const routes = [
  {
    path: URLS.NEW_ORDER_FORM,
    component: NewOrderForm,
    responsive: true,
  },
  {
    path: URLS.NEW_ORDER_CONFIRMATION,
    component: ConfirmationSuccess,
    responsive: true,
  },
  {
    path: URLS.MENU,
    component: Menu,
    responsive: true,
    hasNarrowContainer: true,
    requiresAuth: false,
    hideOn: 'showMenuPage',
  },
  {
    path: URLS.NEW_ORDER_FAILED,
    component: ConfirmationFailed,
    responsive: true,
  },
  {
    path: URLS.NEW_ORDER_ERROR,
    component: ConfirmationError,
    responsive: true,
  },
  {
    path: URLS.NEW_ORDER_PAYMENT_STATUS,
    component: PaymentStatus,
    responsive: true,
  },
  {
    path: URLS.NEW_ORDER_PREPARING,
    component: ConfirmationPreparing,
    responsive: true,
  },
  {
    path: URLS.USER_DIET,
    component: UserDiet,
    responsive: true,
    hasNarrowContainer: true,
    requiresAuth: true,
  },
  {
    path: URLS.MENU_PLANNING,
    component: MenuPlanning,
    responsive: true,
    hasNarrowContainer: true,
    requiresAuth: true,
  },
  {
    path: URLS.MONEY_BOX,
    component: MoneyBox,
    responsive: true,
    hasNarrowContainer: true,
    requiresAuth: true,
  },
  {
    path: URLS.MY_ORDERS,
    component: MyOrders,
    responsive: true,
    hasNarrowContainer: true,
    requiresAuth: true,
  },
  {
    path: URLS.ACCOUNT_SETTINGS,
    component: AccountSettings,
    responsive: true,
    hasNarrowContainer: true,
    requiresAuth: true,
  },
  {
    path: URLS.SHARE_PROGRAM,
    component: ShareProgram,
    responsive: true,
    hasNarrowContainer: true,
    requiresAuth: true,
  },
  {
    path: URLS.AUTH,
    component: Auth,
    responsive: true,
    hasNarrowContainer: true,
  },
  {
    path: URLS.AUTH_LOGIN,
    component: Auth,
    responsive: true,
    hasNarrowContainer: true,
  },
  {
    path: URLS.AUTH_REGISTER,
    component: Auth,
    responsive: true,
    hasNarrowContainer: true,
  },
  {
    path: URLS.AUTH_APPLE,
    component: AppleAuth,
    responsive: true,
    hassNarrowContainer: true,
  },
  {
    path: URLS.AUTH_APPLE_ERROR,
    component: AppleError,
    responsive: true,
    hassNarrowContainer: true,
  },
  {
    path: URLS.REMIND_PASSWORD,
    component: PasswordRemind,
    responsive: true,
    hassNarrowContainer: true,
  },
  {
    path: URLS.RESET_PASSWORD,
    component: SetNewPassword,
    responsive: true,
    hassNarrowContainer: true,
  },
  {
    path: URLS.LOGIN_AS_USER,
    component: LoginAsUser,
    responsive: false,
    hassNarrowContainer: false,
  },
  {
    path: URLS.RESET_PASSWORD_EMAIL_SENT,
    component: ResetPasswordEmailSent,
    responsive: true,
    hassNarrowContainer: true,
  },
  {
    path: URLS.CREATE_PASSWORD,
    component: CreatePassword,
    responsive: true,
    hassNarrowContainer: true,
  },
];

export default routes;

export const useLocalUrl = () => {
  const {
    i18n: { language },
  } = useTranslation();

  return useMemo(
    () => ({
      toLocalUrl: path =>
        `/${language}${path.startsWith('/') ? '' : '/'}${path}`,
    }),
    [language]
  );
};
