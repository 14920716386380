import React, { useEffect, useState } from 'react';
import { get } from 'utils/http';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { media } from 'styledComponents/helpers';

export const PageWrapper = styled.div`
  ${media.tabletDown`
    width: 100%;
  `}

  i {
    font-style: italic;
  }
  b {
    font-weight: bold;
  }
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 24px;
  }
  ol,
  ul {
    list-style: initial;
  }
  li {
    list-style-position: inside;
  }
  ol {
    list-style: decimal;
  }
`;

const H1 = styled.h1`
  font-size: 30px;
  margin-bottom: 30px;
  line-height: normal;
`;

const PageFromCreator = ({ id, brand }) => {
  const [page, setPage] = useState({});
  useEffect(() => {
    const getPage = async () => {
      try {
        const { data } = await get(`/frontend/pages/${id}`);
        setPage(data);
      } catch (error) {}
    };

    getPage();
  }, []);

  if (!page) {
    return null;
  }

  return (
    <div>
      <Helmet title={`${brand.name} - ${page.title}`} />
      <H1>{page.title}</H1>
      <PageWrapper dangerouslySetInnerHTML={{ __html: page.content }} />
    </div>
  );
};

export default connect(
  ({ app: { brand } }) => ({ brand }),
  null
)(PageFromCreator);
