export const DELIVERY_RATING_OPTIONS = {
  GOOD: 5,
  BAD: 1,
};

export const INPUT_IDS = {
  OVERALL_RATING_COMMENT: 'overallRateComment',
  DELIVERY_RATING: 'deliveryRate',
  DELIVERY_RATING_COMMENT: 'deliveryRateComment',
  SINGLE_MEAL_RATING_PREFIX: 'singleMealRate-',
  SINGLE_MEAL_RATING_COMMENT_PREFIX: 'singleMealRateComment-',
};
