import React, { useEffect } from 'react';
import Menu from 'styledComponents/elements/Menu/MobileMenu';

const MobileMenu = ({ children, showMobileMenu, mediaMobileCss }) => {
  useEffect(() => {
    document.body.style.overflow = showMobileMenu ? 'hidden' : 'initial';
  }, [showMobileMenu]);

  return (
    <Menu showMobileMenu={showMobileMenu} mediaMobileCss={mediaMobileCss}>
      {children}
    </Menu>
  );
};

export default MobileMenu;
