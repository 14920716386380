import React, { Fragment } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { fromPxToRem } from 'styledComponents/helpers';

import { customTheme, customStyles } from 'styledComponents/vendor/ReactSelect';
import { StyledFormLabel } from 'styledComponents/elements/Form/FormLabel';

const Error = styled.p`
  color: ${props => props.theme.colorRed};
  font-size: ${fromPxToRem('14px')};
  white-space: pre-wrap;
  margin: 10px 0;
`;

const FormSelectBox = ({
  input,
  options = [],
  label,
  isRequired,
  meta: { error, touched },
  isBig,
  ...rest
}) => {
  return (
    <Fragment>
      {label && (
        <StyledFormLabel>
          {label} {isRequired && '*'}
        </StyledFormLabel>
      )}
      <Select
        {...input}
        {...rest}
        options={options}
        onChange={({ value }) => input.onChange(value)}
        value={options.find(({ value }) => value === input.value)}
        isSearchable={false}
        styles={customStyles}
        theme={customTheme}
      />
      {error && touched && <Error isBig={isBig}> {error} </Error>}
    </Fragment>
  );
};

export default FormSelectBox;
