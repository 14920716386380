import {
  FETCH_USER_DIETS_LIST_REQUEST,
  FETCH_USER_DIETS_LIST_FAILURE,
  FETCH_USER_DIETS_LIST_SUCCESS,
  FETCH_USER_DIET_CALENDAR_FAILURE,
  FETCH_USER_DIET_CALENDAR_SUCCESS,
  FETCH_USER_DIET_CALENDAR_REQUEST,
  SET_SELECTED_DIET,
  SET_VISIBLE_CALENDAR_DAYS,
  SET_SELECTED_DAY,
  CLEAR_USER_DIET,
  SET_CURRENTLY_MAPPING_DAYS,
} from '../actions/const';

import { getMonthRange } from 'views/MenuPlanning/services/calendarService';

import { TODAY } from 'common/shared/weekdays';
import { dateObjectToYearMonthDay } from 'common/services/dateHelpers';

const INITIAL_STATE = {
  visibleCalendarDays: getMonthRange(TODAY),
  selectedDiet: null,
  selectedDietObject: null,
  selectedDay: dateObjectToYearMonthDay(TODAY),

  list: [],
  calendar: [],
  currentlyMappingDays: [],

  isLoading: true,
  error: null,
};

const userDietReducer = (
  state = INITIAL_STATE,
  {
    type,
    list,
    calendar,
    error,
    selectedDiet,
    selectedDietObject,
    selectedDay,
    visibleCalendarDays,
    currentlyMappingDays,
  }
) => {
  switch (type) {
    case FETCH_USER_DIETS_LIST_REQUEST:
    case FETCH_USER_DIET_CALENDAR_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_USER_DIETS_LIST_SUCCESS:
      return {
        ...state,
        list,
        isLoading: false,
      };
    case FETCH_USER_DIET_CALENDAR_SUCCESS:
      return {
        ...state,
        calendar: {
          ...state.calendar,
          days: {
            ...state.calendar.days,
            ...calendar.days,
          },
        },
        isLoading: false,
      };
    case FETCH_USER_DIETS_LIST_FAILURE:
    case FETCH_USER_DIET_CALENDAR_FAILURE:
      return {
        ...state,
        isLoading: false,
        error,
      };
    case SET_SELECTED_DIET: {
      return {
        ...state,
        selectedDiet,
        selectedDietObject,
      };
    }
    case SET_SELECTED_DAY: {
      return {
        ...state,
        selectedDay,
      };
    }
    case SET_VISIBLE_CALENDAR_DAYS: {
      return {
        ...state,
        visibleCalendarDays,
      };
    }
    case SET_CURRENTLY_MAPPING_DAYS: {
      return {
        ...state,
        currentlyMappingDays,
      };
    }
    case CLEAR_USER_DIET: {
      return {
        ...INITIAL_STATE,
      };
    }
    default:
      return state;
  }
};

export default userDietReducer;
