import React from 'react';
import styled from 'styled-components';
import { BaseIconWrapper } from '../../../styledComponents/elements/BigCalendarDayCell';
import { media } from '../../../styledComponents/helpers';

import DeliveredIcon from 'common/components/BigCalendar/icons/DeliveredIcon';
import AddIcon from 'common/components/BigCalendar/icons/AddIcon';
import EditIcon from 'common/components/BigCalendar/icons/EditIcon';
import RateIcon from 'common/components/BigCalendar/icons/RateIcon';
import SubscriptionAddIcon from 'common/components/BigCalendar/icons/SubscriptionAddIcon';
import PartiallyRatedIcon from 'common/components/BigCalendar/icons/PartiallyRatedIcon';
import InProgressIcon from 'common/components/BigCalendar/icons/InProgressIcon';
import WarningIcon from 'common/components/BigCalendar/icons/WarningIcon';
import UnknownIcon from 'common/components/BigCalendar/icons/UnknownIcon';
import RatedIcon from 'common/components/BigCalendar/icons/RatedIcon';
import { DAY_STATUSES } from 'common/components/BigCalendar/const/dayStatuses';

const IconWrapper = styled(BaseIconWrapper)`
  top: 15px;
  right: -8px;
  ${media.tabletDown`
    width: 12px;
    height: 12px;
    top: 7px;
    right: -5px;
  `}
`;

const AddIconWrapper = styled(BaseIconWrapper)`
  width: 30px;
  height: 30px;
  padding: 5px;
  left: 50%;
  transform: translateX(-50%);
  top: -15px;
  ${media.tabletDown`
    width: 18px;
    height: 18px;
    padding: 3px;
    top: -9px;
  `}
`;

const SwitcherIcon = ({ dayStatus, isNowBeforeIteratedDay }) => {
  const Wrapper = IconWrapper;

  return (
    <Wrapper>
      {(() => {
        switch (dayStatus) {
          case DAY_STATUSES.DELIVERED_NOT_RATED_BLOCKED:
            return <DeliveredIcon />;
          case DAY_STATUSES.DELIVERED_RATED_CAN_RATE:
          case DAY_STATUSES.DELIVERED_RATED_BLOCKED:
            return <RatedIcon />;
          case DAY_STATUSES.NOT_DELIVERED_WITH_CONFIGURABLE_WITHOUT_MENU:
            return <UnknownIcon />;
          case DAY_STATUSES.ADD_DIET:
          case DAY_STATUSES.NOT_DIET_CAN_PLACE_ORDER:
          case DAY_STATUSES.SUBSCRIPTION_SUSPENDED:
            return <AddIcon />;
          case DAY_STATUSES.DELIVERED_NOT_RATED_CAN_RATE:
            return <RateIcon />;
          case DAY_STATUSES.DELIVERED_RATED_PARTIALLY_CAN_RATE:
          case DAY_STATUSES.DELIVERED_RATED_PARTIALLY_BLOCKED:
            return <PartiallyRatedIcon />;
          case DAY_STATUSES.NOT_DELIVERED_WITH_CONFIGURABLE_ALL:
            return <EditIcon />;
          case DAY_STATUSES.DISABLED_WITH_BAG:
          case DAY_STATUSES.ERROR_ON_RENEW:
            return <WarningIcon />;
          case DAY_STATUSES.NOT_DELIVERED_BLOCKED:
            return <InProgressIcon />;

          default:
            return null;
        }
      })()}
    </Wrapper>
  );
};

export default SwitcherIcon;
