import React, { Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Basket from 'styledComponents/elements/NewOrder/Basket';
import Tooltip from 'common/components/Tooltip';

const PriceItem = ({ priceItem, customLabel = null, currencySymbol }) => {
  // fix component + fix tooltip
  const toolTipId = uuidv4();

  return (
    <Basket.ItemWrapper
      priceKey={priceItem.key}
      {...(priceItem.itemEntryProps ?? {})}
    >
      <Basket.ItemEntry {...(priceItem.itemEntryProps ?? {})}>
        <span>{customLabel ? customLabel : priceItem.label}</span>

        <Basket.ItemEntryPriceRow>
          {priceItem.showBeforeDiscount && (
            <Basket.ItemEntryOldPrice>
              {priceItem?.beforeDiscount?.toFixed(2)}
            </Basket.ItemEntryOldPrice>
          )}

          <Basket.ItemEntryNewPrice>
            {priceItem?.tooltip?.isShow && (
              <>
                <i
                  className="fas fa-info-circle"
                  data-for={toolTipId}
                  data-tip={toolTipId}
                  style={{
                    marginLeft: '4px',
                    padding: '4px',
                  }}
                />
                <Tooltip id={toolTipId} hide={false} maxWidth="350px">
                  {priceItem.tooltip.text}
                </Tooltip>
              </>
            )}
            {priceItem?.afterDiscount?.toFixed(2)} {currencySymbol}
          </Basket.ItemEntryNewPrice>
        </Basket.ItemEntryPriceRow>
      </Basket.ItemEntry>
      {priceItem?.sections?.map((priceSection, index) => (
        <Basket.SectionsWrapper key={index}>
          {priceSection?.items ? (
            <Fragment>
              <Basket.SectionsItemEntry
                {...(priceSection.itemEntryProps ?? {})}
              >
                <span>
                  {customLabel ? customLabel : priceSection.label}
                  {priceSection?.tooltip?.isShow && (
                    <>
                      <i
                        className="fas fa-info-circle"
                        data-for={toolTipId}
                        data-tip={toolTipId}
                        style={{ marginLeft: '4px', padding: '4px' }}
                      />
                      <Tooltip id={toolTipId} hide={false} maxWidth="350px">
                        {priceSection.tooltip.text}
                      </Tooltip>
                    </>
                  )}
                </span>
                <Basket.ItemEntryPriceRow>
                  {priceSection?.showBeforeDiscount && (
                    <Basket.ItemEntryOldPrice>
                      {priceSection?.beforeDiscount?.toFixed(2)}
                    </Basket.ItemEntryOldPrice>
                  )}

                  <Basket.ItemEntryNewPrice>
                    {priceSection?.afterDiscount?.toFixed(2)} {currencySymbol}
                  </Basket.ItemEntryNewPrice>
                </Basket.ItemEntryPriceRow>
              </Basket.SectionsItemEntry>
              <Basket.AddonsWrapper>
                {priceSection.items.map((item, index) => (
                  <Basket.AddonsItemEntry key={index}>
                    <span>{`${item.quantity}x\xa0${item.name}`}</span>
                    <Basket.ItemEntryPriceRow>
                      <Basket.ItemEntryNewPrice>
                        {(item.quantity * item.price)?.toFixed(2)}
                        {'\xa0'}
                        {currencySymbol}
                      </Basket.ItemEntryNewPrice>
                    </Basket.ItemEntryPriceRow>
                  </Basket.AddonsItemEntry>
                ))}
              </Basket.AddonsWrapper>
            </Fragment>
          ) : (
            <Basket.SectionsItemEntry {...(priceSection.itemEntryProps ?? {})}>
              <span>
                {customLabel ? customLabel : priceSection.label}
                {priceSection?.tooltip?.isShow && (
                  <>
                    <i
                      className="fas fa-info-circle"
                      data-for={toolTipId}
                      data-tip={toolTipId}
                      style={{ marginLeft: '4px', padding: '4px' }}
                    />
                    <Tooltip id={toolTipId} hide={false} maxWidth="350px">
                      {priceSection.tooltip.text}
                    </Tooltip>
                  </>
                )}
              </span>

              <Basket.ItemEntryPriceRow>
                {priceSection?.showBeforeDiscount && (
                  <Basket.ItemEntryOldPrice>
                    {priceSection?.beforeDiscount?.toFixed(2)}
                  </Basket.ItemEntryOldPrice>
                )}

                <Basket.ItemEntryNewPrice>
                  {priceSection?.afterDiscount?.toFixed(2)} {currencySymbol}
                </Basket.ItemEntryNewPrice>
              </Basket.ItemEntryPriceRow>
            </Basket.SectionsItemEntry>
          )}
        </Basket.SectionsWrapper>
      ))}
    </Basket.ItemWrapper>
  );
};

export default PriceItem;
