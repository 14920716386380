import React, { Component, createRef, Fragment } from 'react';
import { Flex } from '@rebass/grid';
import StyledModal from 'styledComponents/elements/Modal';
import { DisplayOnMobile, DisplayOnDesktop } from 'styledComponents/helpers';

class Modal extends Component {
  constructor(props) {
    super(props);

    this.modalRef = createRef();
  }

  componentDidMount() {
    if (!this.props.disableClose) {
      document.addEventListener('mousedown', this.handleClickOutsideModal);
      document.addEventListener('keydown', this.handleEscapeFromModal);
    }
  }

  componentWillUnmount() {
    if (!this.props.disableClose) {
      document.removeEventListener('mousedown', this.handleClickOutsideModal);
      document.removeEventListener('keydown', this.handleEscapeFromModal);
    }
  }

  handleEscapeFromModal = e => {
    const { isOpened, id } = this.props;

    if (e.key === 'Escape' && isOpened === id) {
      this.props.toggleModal();
    }
  };

  handleClickOutsideModal = e => {
    const { isOpened, id } = this.props;
    const ref = this.modalRef.current;

    if (!ref.contains(e.target) && isOpened === id) {
      this.props.toggleModal();
    }
  };

  render() {
    const {
      id,
      title,
      padding,
      children,
      isOpened,
      fullscreen,
      widthMobile,
      toggleModal,
      widthDesktop,
      paddingMobile,
      contentPadding,
      withBackButton,
      contentPaddingMobile,
      hideHeader,
      ...rest
    } = this.props;

    return (
      <StyledModal
        hideHeader={hideHeader}
        isOpened={isOpened === id}
        fullscreen={fullscreen}
      >
        <StyledModal.Body
          ref={this.modalRef}
          fullscreen={fullscreen}
          padding={padding}
          paddingMobile={paddingMobile}
          widthDesktop={widthDesktop}
          widthMobile={widthMobile}
          {...rest}
        >
          <div style={{ overflowY: 'auto', height: '100%' }}>
            <Flex alignItems={'center'} justifyContent={'center'}>
              {withBackButton && (
                <Fragment>
                  <DisplayOnDesktop>
                    <StyledModal.CloseButton onClick={() => toggleModal()}>
                      <i className="fas fa-times" />
                    </StyledModal.CloseButton>
                  </DisplayOnDesktop>
                  <DisplayOnMobile>
                    <StyledModal.BackButton onClick={() => toggleModal()}>
                      <i className="fas fa-arrow-left" />
                    </StyledModal.BackButton>
                  </DisplayOnMobile>
                </Fragment>
              )}
              {title && (
                <StyledModal.Title
                  textAlign="center"
                  noPadding
                  paddingMobile={'0px'}
                  isSmall
                  withBackButton={withBackButton}
                >
                  {title}
                </StyledModal.Title>
              )}
            </Flex>

            <StyledModal.Content
              contentPadding={contentPadding}
              contentPaddingMobile={contentPaddingMobile}
            >
              {children}
            </StyledModal.Content>
          </div>
        </StyledModal.Body>
        <StyledModal.ScrollBlocker isOpened={isOpened} />
      </StyledModal>
    );
  }
}

export default Modal;
