import styled from 'styled-components';

import { fromPxToRem } from 'styledComponents/helpers';

const Table = styled.div`
  overflow: auto;
  width: 100%;

  table {
    width: 100%;
  }
`;

Table.Heading = styled.th`
  text-align: left;
  padding: 12px 10px 12px 0;
  font-size: ${fromPxToRem('12px')};
  font-weight: ${props => props.theme.fontWeight900};
  color: ${props => props.theme.baseFontColor};
  text-transform: uppercase;
  border-bottom: 3px solid ${props => props.theme.baseFontColor};
  min-width: ${props => props.minWidth};
`;

Table.Cell = styled.td`
  padding: 16px 10px 16px 0;
  font-size: ${fromPxToRem('16px')};
  font-weight: ${props => props.theme.fontWeightNormal};
  color: ${props => props.theme.baseFontColor};
  line-height: 1.38;
  border-bottom: 1px solid ${props => props.theme.colorIceBlue2};
  text-align: ${props => props.textAlign || 'left'};
`;

export default Table;
