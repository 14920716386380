import createDecorator from 'final-form-calculate';

import store from 'store';
import { isNotEmptyArray } from 'utils/isDefined';
import {
  clearSelectedDays,
  setDuration,
  setIncludeSaturdays,
  setIncludeSundays,
} from 'common/components/Calendar/actions/calendarActions';

import { setNextTabDisability } from '../components/OrderFormTabs/actions/orderFormTabsActions';
import {
  setCalorifics,
  setDietTypeDesc,
  setPackageDesc,
  setTestMode,
} from '../actions/orderFormActions';

import FORM_FIELD_NAMES from '../const/formFieldNames';
import // addToQueryString,
// getFromQueryString,
'common/shared/querystring';
import {
  getSelectedDaysWithoutSaturdays,
  getSelectedDaysWithoutSundays,
} from '../../../common/components/Calendar/services/calendarService';
import history from 'history.js';
import moment from 'moment';

export default createDecorator(
  {
    field: FORM_FIELD_NAMES.DURATION,
    updates: {
      [FORM_FIELD_NAMES.DURATION]: value => {
        store.dispatch(setDuration(+value));
        return +value;
      },
      [FORM_FIELD_NAMES.DELIVERY_DAYS]: () => {
        return [];
      },
    },
  },
  {
    field: FORM_FIELD_NAMES.TEST_MODE,
    updates: {
      [FORM_FIELD_NAMES.TEST_MODE]: value => {
        const {
          orderForm: {
            orderConfig: { testDaysEnabled },
          },
        } = store.getState();

        return value && testDaysEnabled;
      },
      [FORM_FIELD_NAMES.DURATION]: (isTestMode, form) => {
        const {
          orderForm: { orderConfig },
          dietForm: { useNavUpdates },
        } = store.getState();

        let suitableDietLength = getDefaultDietLength({
          orderConfig,
          form,
          isTestMode,
          useNavUpdates,
        });

        store.dispatch(setTestMode(isTestMode));
        useNavUpdates && store.dispatch(clearSelectedDays());
        store.dispatch(setDuration(suitableDietLength));

        return +suitableDietLength;
      },
    },
  },
  {
    field: FORM_FIELD_NAMES.WITH_WEEKENDS,
    updates: {
      [FORM_FIELD_NAMES.WITH_WEEKENDS]: (
        value,
        { paymentMode, deliveryDays }
      ) => {
        store.dispatch(setIncludeSaturdays(value));
        store.dispatch(setIncludeSundays(value));
        if (paymentMode === '1' && deliveryDays.length > 0) {
          const day = moment(deliveryDays[0]).day();
          const isWeekend = day === 6 || day === 0;

          isWeekend && store.dispatch(clearSelectedDays());
        }

        return value;
      },
      [FORM_FIELD_NAMES.DELIVERY_DAYS]: (value, form) => {
        const daysWithoutSaturdays = getSelectedDaysWithoutSaturdays(
          form.deliveryDays
        );
        return getSelectedDaysWithoutSundays(daysWithoutSaturdays);
      },
    },
  },
  {
    field: FORM_FIELD_NAMES.WITH_SATURDAYS,
    updates: {
      [FORM_FIELD_NAMES.WITH_SATURDAYS]: (
        value,
        { paymentMode, deliveryDays }
      ) => {
        store.dispatch(setIncludeSaturdays(value));
        if (paymentMode === '1' && deliveryDays.length > 0) {
          const day = moment(deliveryDays[0]).day();
          const isSaturday = day === 6;

          isSaturday && store.dispatch(clearSelectedDays());
        }

        return value;
      },
      [FORM_FIELD_NAMES.DELIVERY_DAYS]: (value, { deliveryDays }) => {
        return getSelectedDaysWithoutSaturdays(deliveryDays);
      },
    },
  },
  {
    field: FORM_FIELD_NAMES.WITH_SUNDAYS,
    updates: {
      [FORM_FIELD_NAMES.WITH_SUNDAYS]: (
        value,
        { paymentMode, deliveryDays }
      ) => {
        store.dispatch(setIncludeSundays(value));
        if (paymentMode === '1' && deliveryDays.length > 0) {
          const day = moment(deliveryDays[0]).day();
          const isSunday = day === 0;

          isSunday && store.dispatch(clearSelectedDays());
        }

        return value;
      },
      [FORM_FIELD_NAMES.DELIVERY_DAYS]: (value, form) => {
        return getSelectedDaysWithoutSundays(form.deliveryDays);
      },
    },
  },
  {
    field: FORM_FIELD_NAMES.PACKAGE,
    updates: {
      [FORM_FIELD_NAMES.PACKAGE]: value => {
        store.dispatch(setPackageDesc(+value));
        const {
          orderForm: {
            orderConfig: { packages },
          },
        } = store.getState();

        if (value && packages.find(pack => pack.id === +value)) {
          //addToQueryString(FORM_FIELD_NAMES.PACKAGE, value);
        }

        return value;
      },
      [FORM_FIELD_NAMES.DIET_TYPE]: (value, form) => {
        const {
          dietForm: { defaultsFromUrl },
          orderForm: {
            orderConfig: { diets, packages },
          },
        } = store.getState();

        if (
          (value !== form.package || !form.diet) &&
          (packages.length === 0 ||
            !packages.find(pack => pack.diets.length > 0) ||
            !packages.find(pack => pack.id === +value))
        ) {
          return (
            defaultsFromUrl[FORM_FIELD_NAMES.DIET_TYPE] ||
            diets[0].id.toString()
          );
        }

        const currentPackage = packages.find(p => p.id === +value);
        const formDietId = form[FORM_FIELD_NAMES.DIET_TYPE];
        const isInDiets = currentPackage?.diets.find(
          diet => diet.id === +formDietId
        );

        let result = isInDiets || currentPackage?.diets[0] || null;

        return result ? result.id.toString() : null;
      },
    },
  },
  {
    field: FORM_FIELD_NAMES.DIET_TYPE,
    updates: {
      [FORM_FIELD_NAMES.DIET_TYPE]: (value, form) => {
        store.dispatch(setDietTypeDesc(+value));
        const {
          orderForm: {
            orderConfig: { diets },
          },
        } = store.getState();

        if (value && diets.find(diet => diet.id === +value)) {
          //addToQueryString(FORM_FIELD_NAMES.DIET_TYPE, value);
        }

        return value;
      },
      [FORM_FIELD_NAMES.VARIANT]: (value, form) => {
        const {
          orderForm: {
            orderConfig: { diets },
          },
        } = store.getState();
        const currentDiet = diets.find(diet => diet.id === +value);
        if (!currentDiet) {
          return history.push('/new-order/create');
        }

        const currentVariant = form[FORM_FIELD_NAMES.VARIANT];
        const isInVariants = currentDiet.variants.find(
          variant => variant.id === +currentVariant
        );

        let result =
          currentDiet && isNotEmptyArray(currentDiet.variants) && isInVariants
            ? form[FORM_FIELD_NAMES.VARIANT]
            : currentDiet.variants[0].id.toString();

        return result;
      },
    },
  },
  {
    field: FORM_FIELD_NAMES.VARIANT,
    updates: {
      [FORM_FIELD_NAMES.CALORIFICS]: (value, form) => {
        store.dispatch(setCalorifics(+value)); // TODO: raczej nop instruction - set in fromSpy

        if (value) {
          //addToQueryString(FORM_FIELD_NAMES.VARIANT, value);
        }

        const {
          orderForm: {
            calorifics,
            orderConfig: { diets, variants },
          },
        } = store.getState();
        const diet = diets.find(
          diet => diet.id === +form[FORM_FIELD_NAMES.DIET_TYPE]
        );

        const currentVariant =
          diet.variants.find(v => v.id === +form[FORM_FIELD_NAMES.VARIANT]) ||
          variants.find(
            variant => variant.id === +form[FORM_FIELD_NAMES.VARIANT]
          );

        const queryStringCalorific = form[FORM_FIELD_NAMES.CALORIFICS];

        const isInVariant = currentVariant.calories.find(
          c => c.id === +queryStringCalorific
        );

        const isInCalorifics = calorifics.find(
          cal => cal.id === +queryStringCalorific
        );

        const result =
          isNotEmptyArray(calorifics) && (isInVariant || isInCalorifics)
            ? queryStringCalorific
            : currentVariant.calories.length > 0
            ? currentVariant.calories[0].id.toString()
            : calorifics.length > 0
            ? calorifics[0].id.toString()
            : null;

        return result;
      },
    },
  },
  {
    field: FORM_FIELD_NAMES.DELIVERY_DAYS,
    updates: value => {
      const hasDaysChosen = isNotEmptyArray(value);

      store.dispatch(setNextTabDisability(!hasDaysChosen));

      return {
        [FORM_FIELD_NAMES.DELIVERY_DAYS]: value,
      };
    },
  }
);

const alignDurationToRange = ({ current, min, max }) => {
  return current > max ? max : current < min ? min : current;
};

export const getDefaultDietLength = ({
  orderConfig: {
    testDaysSelectorType,
    testDaysMin,
    testDaysMax,
    testDaysDefault,
    testDays,
    daysSelectorType,
    daysMin,
    daysMax,
    daysDefault,
    days,
  },
  form,
  isTestMode,
  useNavUpdates,
}) => {
  const isRangedInput = isTestMode
    ? testDaysSelectorType === 'RANGE' || testDaysSelectorType === 'RANGE_INPUT'
    : daysSelectorType === 'RANGE' || daysSelectorType === 'RANGE_INPUT';

  const currentDuration = form ? form[FORM_FIELD_NAMES.DURATION] : 0;

  let suitableDietLength = isTestMode
    ? isRangedInput
      ? testDaysDefault ||
        alignDurationToRange({
          current: currentDuration,
          min: testDaysMin,
          max: testDaysMax,
        })
      : testDays
          .map(day => day.value)
          .find(value => value === testDaysDefault) || testDays[0].value
    : isRangedInput
    ? useNavUpdates
      ? daysDefault
      : alignDurationToRange({
          current: currentDuration,
          min: daysMin,
          max: daysMax,
        })
    : days.map(day => day.value).find(value => value === daysDefault) ||
      days[0].value;

  return suitableDietLength;
};
