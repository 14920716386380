import React from 'react';
import styled from 'styled-components';
import { MenuItem, Select } from '@material-ui/core';
import { fromPxToRem } from 'styledComponents/helpers';

export const StyledMuiSelect = styled(Select)`
  color: ${props => props.theme.baseFontColor};
  &&.MuiInput-underline {
    &:before {
      border-color: ${props => props.theme.baseFontColor};
    }
    &:hover,
    :hover:not(.Mui-disabled):before {
      border-color: ${props => props.theme.baseFontColor};
    }
  }
  &&.MuiInput-underline:after {
    border-color: ${props => props.theme.baseFontColor};
    &:hover {
      border-color: ${props => props.theme.baseFontColor};
    }
  }
  &&.MuiInputBase-root {
    color: ${props => props.theme.baseFontColor};
    font-size: ${fromPxToRem('16px')};
  }
  && .MuiSelect-icon {
    color: ${props => props.theme.baseFontColor};
  }
`;

export const StyleMenuItem = styled(MenuItem)`
  &&.MuiButtonBase-root {
    color: ${props => props.color || props.theme.baseFontColor};
  }
`;
