import { Box } from '@rebass/grid';
import styled, { css } from 'styled-components';

import { fromPxToRem, media } from 'styledComponents/helpers';
import { ButtonLink } from 'styledComponents/elements/Button';

const StyledFormTextInput = styled(Box)`
  position: relative;
  width: 100%;
  padding-bottom: ${props => props.error && '20px'};
  margin: ${props => props.margin || 0};

  ${props =>
    props.withSubmitButton &&
    css`
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    `};
`;

const InputStyles = css`
  font-family: inherit;
  font-size: ${fromPxToRem('12px')};
  font-weight: ${props => props.theme.fontWeight900};
  color: ${props => props.theme.baseFontColor};
  width: ${props => (props.isInline ? 'auto' : '100%')};
  height: 36px;
  box-sizing: border-box;
  appearance: none;
  background: ${props => props.theme.colorWhite};
  border: ${props =>
    props.error
      ? `2px solid ${props.theme.colorRed}`
      : `2px solid ${props.theme.colorWhite}`};
  border-radius: ${props => props.theme.baseBorderRadius};
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
  padding: 10px 14px;
  margin: ${props =>
    props.isInline ? '0' : props.error ? '10px 0' : '10px 0 20px'};

  &::placeholder {
    font-weight: ${props => props.theme.fontWeight500};
  }

  &:active,
  &:focus {
    outline: none;
    border: 2px solid
      ${props =>
        props.error ? props.theme.colorRed : props.theme.baseFontColor};
    font-weight: ${props => props.theme.fontWeight900};
  }

  &:disabled {
    cursor: auto;
    border: none;
    background: ${props => props.theme.colorIceBlue};
    color: ${props => props.theme.baseFontColor};
  }

  ${props =>
    props.isBig &&
    css`
      padding: '14px 22px';
      height: 54px;
      color: ${props => props.theme.baseFontColor};
      font-size: ${fromPxToRem('16px')};
    `};

  ${props =>
    props.withSubmitButton &&
    css`
      padding: 14px 110px 14px 22px;
    `};

  ${media.tabletDown`
    width: 100%;
  `};
`;

StyledFormTextInput.Text = styled.input`
  ${InputStyles};
  font-family: inherit;
`;

StyledFormTextInput.InputContainer = styled.div`
  position: relative;

  ${media.tabletDown`
    width: 100%;
  `};
`;

StyledFormTextInput.TextArea = styled.textarea`
  ${InputStyles};
  resize: none;
  min-height: ${props => props.minHeight || '75px'};
  max-height: ${props => props.maxHeight || '75px'};
`;

StyledFormTextInput.Label = styled.label`
  font-size: ${props =>
    props.isBig ? fromPxToRem('16px') : fromPxToRem('12px')};
  font-weight: ${props => props.theme.fontWeight500};
  margin-right: ${props => (props.isInline ? '20px' : '0')};
`;

StyledFormTextInput.Error = styled(StyledFormTextInput.Label)`
  color: ${props => props.theme.colorRed};
  font-size: ${fromPxToRem('14px')};
  white-space: pre-wrap;
  margin-bottom: 20px;
`;

StyledFormTextInput.Button = styled(ButtonLink)`
  position: absolute;
  top: 0;
  right: 20px;
`;

StyledFormTextInput.ErrorMessage = styled.p`
  color: ${props => props.theme.colorRed};
  font-size: ${fromPxToRem('13px')};
  margin-top: 5px;
  margin-left: 10px;
  position: absolute;
`;

export default StyledFormTextInput;
