import { is, prop, max, reduce } from 'ramda';
import { format } from 'date-fns';

export const getPackageParam = (packages, id, paramName) => {
  const pack = packages.find(pack => pack.id === +id);

  return prop(paramName, pack);
};

export const getDietParam = (diets, id, paramName) => {
  const diet = diets.find(diet => diet.id === +id);

  return prop(paramName, diet);
};

export const getVariant = (variants, variantId) => {
  return variants.find(({ id }) => id === +variantId);
};

export const getVarianParam = (variants, id, paramName) => {
  const variant = getVariant(variants, id);

  return prop(paramName, variant);
};

export const getDietField = (diets, id, key) =>
  diets.find(diet => diet.id === +id)[key];

export const getUseEcoContainers = (useEcoContainers, t) =>
  useEcoContainers && t('$*common.ecoPacks', '$$Opakowania eko');

export const getDietCalorificsParam = (
  variants,
  variantId,
  calorificsId,
  paramName
) => {
  const variant = getVariant(variants, variantId);

  const calorifics = variant.calories.find(({ id }) => id === +calorificsId);
  if (!calorifics) {
    throw new Error('No calorific found');
  }

  return prop(paramName, calorifics);
};

export const getDietDuration = (durationLength, testMode, t) => {
  const isOneDay = durationLength === 1;
  let dayPhrase = t('$*common.day_plural', '$$dni');
  let testPhrase = '';
  if (isOneDay) {
    dayPhrase = t('$*common.day', '$$dzień');
  }
  if (testMode) {
    if (isOneDay) {
      testPhrase = t('$*common.test_adj_masculo', '$$próbny');
    } else {
      testPhrase = t('$*common.text_adj_neutral', '$$próbne');
    }
  }
  return `${durationLength} ${dayPhrase} ${testPhrase}`;
};

export function getDietDeliveryTypeParam(
  deliveryTypeId,
  paramName,
  DELIVERY_OPTIONS
) {
  const deliveryType = DELIVERY_OPTIONS.find(
    ({ id }) => id === +deliveryTypeId
  );
  return prop(paramName, deliveryType);
}

export const getDietDeliveryHourUpTo = (deliveryAddressId, addresses, t) => {
  const address = findAddress(addresses, deliveryAddressId);
  return address
    ? (address?.deliveryHourFrom
        ? `${t('$*common.from', '$$od')} ${format(
            address?.deliveryHourFrom,
            'HH:mm'
          ).slice(0, 5)}`
        : '') +
        (address?.deliveryHourTo
          ? ` ${t('$*common.to', '$$do')} ${format(
              address?.deliveryHourTo,
              'HH:mm'
            ).slice(0, 5)}`
          : '')
    : null;
};

export const getDietDeliveryAddress = (
  deliveryAddress,
  addresses,
  t,
  useAddressesWithLessFields
) => {
  if (!is(Object, deliveryAddress)) {
    const {
      addressLine1,
      addressLine2,
      buildNumber = '',
      city = '',
      placeNumber,
      street = '',
    } = findAddress(addresses, deliveryAddress) || {};

    if (useAddressesWithLessFields) {
      if (addressLine1) {
        return `${city}, ${[addressLine1, addressLine2]
          .filter(Boolean)
          .join('; ')}`;
      }

      return `${city}, ${street}`;
    }

    return city && street && buildNumber
      ? `${city}, ${street} ${buildNumber} ` +
          (placeNumber
            ? `${t('$*common.appartmentAbbr', 'lok.')} ${placeNumber}`
            : '')
      : null;
  } else {
    const {
      addressLine1,
      addressLine2,
      buildNumber = '',
      city = '',
      placeNumber,
      street = '',
    } = deliveryAddress;

    if (useAddressesWithLessFields) {
      if (addressLine1) {
        return `${city}, ${[addressLine1, addressLine2]
          .filter(Boolean)
          .join('; ')}`;
      }

      return `${city}, ${street}`;
    }

    return (
      `${city}, ${street} ${buildNumber}` +
      (placeNumber
        ? ` ${t('$*common.appartmentAbbr', 'lok.')} ${placeNumber}`
        : '')
    );
  }
};

export const getDietPickupPointAddressParam = (
  pickupPointId,
  pickupPoints,
  paramName
) => {
  const pickupPoint = pickupPoints.find(({ id }) => id === +pickupPointId);

  return prop(paramName, pickupPoint);
};

export const getAddressParam = (addresses, addressId, paramName) => {
  const address = findAddress(addresses, addressId);

  return prop(paramName, address);
};

export const getPickupPointParam = (pickupPoints, pickupPointId, paramName) => {
  const pickupPoint = findPickupPoint(pickupPointId, pickupPoints);

  return prop(paramName, pickupPoint);
};

export const findAddress = (addresses, addressId) => {
  return addresses.find(({ id }) => id === +addressId);
};

export const findPickupPoint = (pickupPointId, pickupPoints) => {
  return pickupPoints.find(({ id }) => id === +pickupPointId);
};

export const getNewAddressId = addresses => {
  if (addresses.length === 0) {
    return 0;
  } else if (addresses.length === 1) {
    return addresses[0].id + 1;
  } else {
    return (
      parseInt(
        reduce(
          max,
          -Infinity,
          addresses.map(({ id }) => id)
        )
      ) + 1
    );
  }
};
