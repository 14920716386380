import React from 'react';
import styled from 'styled-components';
import { Flex } from '@rebass/grid';

import { fromPxToRem, media } from 'styledComponents/helpers';

const StyledFormSection = styled.section`
  margin-bottom: 65px;

  ${media.tabletDown`
    margin-bottom: 32px;
  `};
`;

const StyledFormSectionTitle = styled.h3`
  padding-bottom: 20px;
  color: ${props => props.theme.baseFontColor};
  font-size: ${fromPxToRem('24px')};

  ${media.tabletDown`
    font-size: ${fromPxToRem('20px')};
    padding-bottom: 10px;
  `};
`;

const FormSection = ({ title, children, action, flexStyleProps = {} }) => {
  const flexProps = {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    ...flexStyleProps,
  };

  return (
    <StyledFormSection>
      <StyledFormSectionTitle>
        <Flex {...flexProps}>
          {title}
          {action}
        </Flex>
      </StyledFormSectionTitle>

      <div>{children}</div>
    </StyledFormSection>
  );
};

export default FormSection;
