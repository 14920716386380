import React from 'react';
import { Flex } from '@rebass/grid';

const FormRadioGroup = ({ children, ...props }) => (
  <Flex
    flexWrap="wrap"
    flexDirection={[
      props.flexDirection ? props.flexDirection : 'column',
      'row',
    ]}
    {...props}
  >
    {children}
  </Flex>
);

export default FormRadioGroup;
