const OverflowContent = ({
  as: Component = 'div',
  title,
  children,
  lineClamp = 1,
  style: additionalStyle = {},
}) => {
  const isOneLine = lineClamp === 1;
  const style = {
    ...(isOneLine
      ? {
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          OTextOverflow: 'ellipsis',
          wordBreak: 'break-word',
        }
      : {
          display: ['-moz-box', '-ms-box', '-webkit-box', 'box'],
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          OTextOverflow: 'ellipsis',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: lineClamp,
          wordBreak: 'break-word',
        }),
    ...additionalStyle,
  };

  return (
    <Component style={style} title={title}>
      {children}
    </Component>
  );
};

export default OverflowContent;
