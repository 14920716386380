import styled from 'styled-components';
import { darken } from 'polished';

const CardTitle = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  line-height: normal;
  margin-bottom: 20px;
`;

const Card = styled.div`
  min-height: 330px;
  height: auto;
  background: white;
  border: 2px solid transparent;
  border-radius: 8px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin: 15px;
  width: calc(100% - 30px);
  -webkit-tap-highlight-color: transparent;

  @media (min-width: 690px) {
    width: calc((100% / 2) - 30px);
    min-width: 285px;
  }
  @media (min-width: 1370px) {
    width: calc((100% / 3) - 30px);
  }

  ${({ isChecked, theme: { baseFontColor } }) =>
    isChecked &&
    `
    border-color: ${baseFontColor};

    ${CardTitle} {
      color: ${baseFontColor}
    }
  `}

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      ${({ theme: { baseFontColor } }) => `border-color: ${baseFontColor};`}
    }
  }
`;

Card.Wrapper = styled.div`
  padding: 15px;
`;

Card.ContentWrapper = styled.div`
  transition: ease 0.3s;
  ${({ noImage }) => noImage && `transform: translateY(-240px);`}
`;

Card.Content = styled.div`
  max-height: 267px;
  overflow: hidden;
  margin-bottom: 15px;

  &:hover {
    ${Card.ContentWrapper} {
      transform: translateY(-240px);
      transition: ease 0.3s;
    }
  }
`;

Card.Image = styled.div`
  ${({ src }) => `background: url("${src}") no-repeat center center`};
  background-size: auto 225px;
  height: 225px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
`;

Card.Title = CardTitle;

Card.Description = styled.div`
  overflow: auto;
  max-height: 215px;
  line-height: normal;
`;

Card.Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;

Card.Switch = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5px -2px -2px;
  color: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  width: calc(100% + 4px);
  ${({ theme: { baseFontColor } }) => `
    background: ${baseFontColor};
    border: 2px solid ${baseFontColor};
  `}
`;

Card.SwitchItem = styled.div`
  border-radius: 6px;
  padding: 9px 8px;
  line-height: 100%;
  flex: 1 1 auto;
  text-align: center;

  ${({ isActive, theme: { baseFontColor } }) =>
    isActive &&
    `
    background: #fff;
    color: ${baseFontColor};
    font-weight: 700;
  `}
`;

const Check = styled.div`
  width: 25px;
  height: 25px;
  ${({ theme: { baseFontColor } }) => `
    border: 2px solid ${baseFontColor};
  `};
  border-radius: 5px;
`;

const InputNumber = styled.div`
  display: flex;
  align-items: center;
`;

InputNumber.Button = styled.button`
  width: 25px;
  height: 25px;
  ${({ theme: { baseFontColor } }) => `
    border: 1px solid ${baseFontColor};
  `};
  border-radius: 5px;
  background: no-repeat;
  cursor: pointer;
  outline: none;
  transition: ease 0.3s;

  &:hover {
    ${({ theme: { baseFontColor } }) => `background: ${baseFontColor}`};
    color: #fff;
    transition: ease 0.3s;
  }

  &:focus {
    ${({ theme: { baseFontColor } }) =>
      `outline-color: ${darken(0.2, baseFontColor)};`};
  }
`;

InputNumber.Text = styled.span`
  margin: 0 5px;
  min-width: 25px;
  text-align: center;
`;

export { Card, InputNumber, Check };
