import React from 'react';
import { connect } from 'react-redux';
import StyledSingleDetailSection from 'styledComponents/elements/MenuPlanning/SingleDetailSection';
import { DietDetailsSection } from './index';
import { AddonDetails } from '../../index';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import useIsEditable from '../hooks/useIsEditable';

const DEADLINE_BRAND_KEY = 'buyAddonsDeadline';
const EDITING_ALLOW_KEY = 'allowChangeDiet';

const Addons = ({ action, extendDiet, bag, calendar, selectedDay, t }) => {
  const { hasTimePassed, isEditingAllowed } = useIsEditable(
    DEADLINE_BRAND_KEY,
    EDITING_ALLOW_KEY
  );

  if (!extendDiet && bag.addons.length === 0) return null;

  const isSubscriptionIntent =
    calendar?.days?.[selectedDay]?.['@type'] === 'SubscriptionIntent';

  return (
    <DietDetailsSection
      title={t('$*menuPlaning.details.yourAddonsTitle')}
      deadlineBrandKey={DEADLINE_BRAND_KEY}
      showTooltip={isEditingAllowed}
      isEditable={!hasTimePassed}
    >
      <div style={{ marginBottom: '12px' }}>
        <AddonDetails />
      </div>
      {!hasTimePassed && (
        <StyledSingleDetailSection.Button
          type="button"
          uppercased
          sizeMiddle
          weightBold
          onClick={action}
        >
          {t('$*menuPlaning.details.checkOccasion')}
        </StyledSingleDetailSection.Button>
      )}
    </DietDetailsSection>
  );
};

export default compose(
  withTranslation(),
  connect(
    ({
      menuPlanning: { bag, calendar, selectedDay },
      app: {
        brand,
        config: {
          modules: {
            Addons: { extendDiet },
          },
        },
      },
    }) => ({
      bag,
      extendDiet,
      calendar,
      brand,
      selectedDay,
    })
  )
)(Addons);
