import React, { useState } from 'react';
import {
  Container,
  OptionsGroup,
  OptionsSliderWrapper,
  StandaloneWrapper,
  StyledIcon,
  SubHeading,
} from './StyledComponents';
import FormRadioGroup from '../../common/components/Form/FormRadioGroup';
import StandalonePrimaryRadio from '../../common/components/StandalonePrimaryRadio';
import Slider from 'react-slick';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

const VariantsPicker = ({
  availableVariants,
  selectedVariant,
  onVariantSelect,
  t,
  isChangeVariantManual,
  setIsChangeVariantManual,
}) => {
  const sliderRef = React.useRef(null);

  const selectedVariantIndex = availableVariants.findIndex(
    variant => variant['@id'] === selectedVariant
  );

  React.useEffect(() => {
    sliderRef?.current?.slickGoTo(selectedVariantIndex, true);
  }, [selectedVariant, availableVariants]);

  return (
    <>
      <SubHeading>{t('$*menu.chooseVariant', 'Wybierz wariant')}</SubHeading>
      <OptionsGroup>
        <FormRadioGroup>
          {availableVariants.map(variant => (
            <StandalonePrimaryRadio
              onClick={() => onVariantSelect(variant['@id'])}
              name={variant.name}
              key={variant.id}
              checked={selectedVariant === variant['@id']}
              marginRight={'10px'}
            />
          ))}
        </FormRadioGroup>
      </OptionsGroup>
      {availableVariants.length > 1 ? (
        <OptionsSliderWrapper>
          <StyledIcon
            className="fas fa-chevron-circle-left"
            onClick={() => {
              setIsChangeVariantManual(true);
              sliderRef.current?.slickPrev();
            }}
            hide={availableVariants.length === 1}
          />
          <Slider
            arrows={false}
            style={{ width: 'calc(100vw - 100px)' }}
            ref={sliderRef}
            initialSlide={selectedVariantIndex}
            afterChange={currentSlide => {
              if (isChangeVariantManual) {
                onVariantSelect(availableVariants[currentSlide]['@id']);
                setIsChangeVariantManual(false);
              }
            }}
          >
            {availableVariants.map(variant => {
              return (
                <StandaloneWrapper key={variant.id}>
                  <StandalonePrimaryRadio
                    onClick={() => {
                      onVariantSelect(variant['@id']);
                      setIsChangeVariantManual(true);
                    }}
                    name={variant.name}
                    checked={selectedVariant === variant['@id']}
                  />
                </StandaloneWrapper>
              );
            })}
          </Slider>
          <StyledIcon
            className="fas fa-chevron-circle-right"
            onClick={() => {
              setIsChangeVariantManual(true);
              sliderRef.current?.slickNext();
            }}
            hide={availableVariants.length === 1}
          />
        </OptionsSliderWrapper>
      ) : (
        <OptionsSliderWrapper>
          <StandalonePrimaryRadio
            onClick={() => {}}
            name={availableVariants[0].name}
            checked
          />
        </OptionsSliderWrapper>
      )}
    </>
  );
};

export default compose(
  connect(({ orderForm: { orderConfig: { variants, diets } } }) => ({
    variants,
    diets,
  })),
  withTranslation()
)(VariantsPicker);
