const checkDomain = url => {
  let urlWithProtocol = url;
  if (url.indexOf('//') === 0) {
    urlWithProtocol = window.location.protocol + url;
  }

  return urlWithProtocol
    .toLowerCase()
    .replace(/([a-z])?:\/\//, '$1')
    .split('/')[0];
};

export const isExternalUrl = url => {
  return (
    (url.indexOf(':') > -1 || url.indexOf('//') > -1) &&
    checkDomain(window.location.href) !== checkDomain(url)
  );
};
