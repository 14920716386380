import {
  FETCH_ORDERS,
  UPDATE_ORDERS,
  START_LOADING,
  STOP_LOADING,
} from '../actions/const';

const INITIAL_STATE = {
  orders: [],
  allOrdersFetched: false,
  isLoading: false,
};

const ordersReducer = (
  state = INITIAL_STATE,
  { type, orders, allOrdersFetched }
) => {
  switch (type) {
    case START_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case STOP_LOADING: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case FETCH_ORDERS: {
      return {
        ...state,
        orders,
        allOrdersFetched,
      };
    }
    case UPDATE_ORDERS: {
      return {
        ...state,
        orders: [...state.orders, ...orders],
        allOrdersFetched,
      };
    }
    default:
      return state;
  }
};

export default ordersReducer;
