import styled from 'styled-components';

import {
  BaseWrapper,
  BaseDayNumber,
} from 'styledComponents/elements/BigCalendarDayCell';
import { fromPxToRem } from 'styledComponents/helpers';

const SwitcherDayCellDesktop = styled(BaseWrapper)`
  width: 90px;
  position: relative;
  height: 72px;
  padding: 15px 0;
  transform: ${props => (props.selected ? 'scale(1.25)' : 0)};
  border-radius: ${props => props.theme.baseBorderRadius};
  border: ${props =>
    props.selected ? `2px solid ${props.theme.baseFontColor}` : 'none'};
  ${props =>
    props.canAddDay && `border: 1px solid ${props.theme.baseFontColor}`};
  background-color: ${props =>
    props.disabled || props.faded || props.canAddDay
      ? props.theme.colorWhite //props.theme.colorIceBlue
      : props.configurable
      ? props.theme.baseFontColor
      : props.theme.colorIceBlue};
  text-align: center;
  color: ${props =>
    props.disabled || props.faded
      ? props.theme.bodyColor
      : props.configurable
      ? props.theme.colorIceBlue
      : props.theme.baseFontColor};
  font-weight: 900;
  margin: 5px 15px 15px;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  cursor: pointer;

  &:hover {
    border: ${props =>
      (!props.disabled &&
        props.selected &&
        `2px solid ${props.theme.baseFontColor}`) ||
      (!props.disabled && `1px solid ${props.theme.baseFontColor}`) ||
      'inherit'};
  }

  ${props =>
    props.isDayToday &&
    `
    &:after {
      content: '•';
      display: inline-block;
      width: 100%;
      bottom: -9px;
      position: absolute;
    }
  `}
`;

SwitcherDayCellDesktop.DayNumber = styled(BaseDayNumber)`
  margin-bottom: auto;
  font-size: ${fromPxToRem('15px')};
`;

SwitcherDayCellDesktop.MonthText = styled.p`
  text-transform: uppercase;
  font-size: ${fromPxToRem('8px')};
  margin-top: auto;
`;

SwitcherDayCellDesktop.WeekdayText = styled.p`
  position: absolute;
  font-size: 1rem;
  margin-bottom: auto;
  color: ${props => props.theme.baseFontColor};
  top: 105%;
  width: 100%;
  font-weight: normal;
`;

SwitcherDayCellDesktop.Icon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  background: red;
`;

SwitcherDayCellDesktop.ActionTodoText = styled.p`
  font-size: ${fromPxToRem('9px')};
  margin-bottom: auto;
`;

export default SwitcherDayCellDesktop;
