import React from 'react';
import styled from 'styled-components';

import { Button } from 'styledComponents/elements/Button';
import { fromPxToRem } from 'styledComponents/helpers';

const NavbarButtonWrapper = styled(Button)`
  color: ${props => props.theme.baseFontColor};
  font-family: inherit;
  font-size: ${fromPxToRem('16px')};
  margin-left: 20px;
  cursor: pointer;
  min-width: 120px;

  [class*='fa-'] {
    margin-left: 10px;
  }

  @media (max-width: ${({ mediaMobileCss }) => mediaMobileCss}) {
    display: none;
  } ;
`;

const NavbarButton = ({ icon, children, ...rest }) => (
  <NavbarButtonWrapper {...rest}>
    {children}
    <i className={`fas fa-${icon}`} />
  </NavbarButtonWrapper>
);

export default NavbarButton;
