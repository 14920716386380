import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import URLS from 'routes/routeUrls';

export const headerItems = [];

export const useHeaderItems = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const showRefererCode = useSelector(
    ({ app: { config } }) => config.showRefererCode
  );

  return useMemo(() => {
    return [
      {
        link: URLS.NEW_ORDER_FORM,
        label: t('$*header.prepareTheOrder', '$$Zamów'),
        i18nKey: '$*header.prepareTheOrder',
      },
      {
        link: URLS.USER_DIET,
        checkForToken: true,
        label: t('$*header.myAccount', 'Moje konto'),
        i18nKey: '$*header.myAccount',
      },
      {
        link: URLS.MY_ORDERS,
        checkForToken: true,
        label: t('$*header.historyOfOrders', '$$Historia zamówień'),
        i18nKey: '$*header.historyOfOrders',
      },
      ...(showRefererCode
        ? [
            {
              link: URLS.SHARE_PROGRAM,
              checkForToken: true,
              label: t('$*header.referralCode', '$$Kod polecający'),
              i18nKey: '$*header.referralCode',

              isBold: true,
            },
          ]
        : []),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, t, showRefererCode]);
};
