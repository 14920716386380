import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { ButtonPrimary } from 'styledComponents/elements/Button';
import { ContentWrapper } from 'styledComponents/blocks/ContentWrapper';
import { fromPxToRem, media } from 'styledComponents/helpers';

import showToast from 'utils/toast';
import { remove } from 'utils/http';
import { mainTheme } from 'themes/main';

import { URLS } from 'configuration';
import { logout } from 'views/Auth/actions/authActions';

import DeleteAccountConfirmModal from './DeleteAccountConfirmModal';

const Title = styled.h3`
  padding-bottom: 20px;
  color: ${props => props.theme.baseFontColor};
  font-size: ${fromPxToRem('24px')};

  ${media.tabletDown`
    font-size: ${fromPxToRem('20px')};
    padding-bottom: 10px;
  `};
`;

const DeleteAccountSection = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);

  const paragraphs = [
    t(
      '$accountSettings.deleteAccountSection.paragraph1',
      '$$Czas na ocenę menu minął'
    ),
    t(
      '$accountSettings.deleteAccountSection.paragraph2',
      '$$Nie masz aktywnych zamówień'
    ),
    t(
      '$accountSettings.deleteAccountSection.paragraph3',
      '$$Nie masz aktywnych subskrypcji'
    ),
  ];

  const handleSubmit = async () => {
    try {
      await remove(URLS.DELETE_ACCOUNT);

      dispatch(logout());
      setTimeout(() => {
        showToast({
          message: t(
            '$accountSettings.deleteAccountSection.accountHasBeenDeleted',
            '$$Twoje konto zostało usunięte'
          ),
          type: 'success',
        });
      }, 1500);
    } catch ({ response }) {
      switch (response?.status) {
        case 400:
          (response?.data?.violations ?? []).map(({ message }) =>
            showToast({ message, type: 'error' })
          );
          setIsConfirmModalOpened(false);
          break;
        default:
          break;
      }
    }
  };

  return (
    <>
      <ContentWrapper padding="40px">
        <Title>
          {t('$accountSettings.deleteAccountSection.title', '$$Usuń konto')}
        </Title>
        <p>
          {t(
            '$accountSettings.deleteAccountSection.subtitle',
            '$$Pamiętaj, konto można usunąć jeśli: '
          )}
        </p>
        <ul style={{ marginLeft: '25px' }}>
          {paragraphs.map((paragraph, key) => (
            <li key={key} style={{ listStyleType: 'disc' }}>
              {paragraph}
            </li>
          ))}
        </ul>
        <div
          style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}
        >
          <ButtonPrimary
            color="error"
            onClick={() => setIsConfirmModalOpened(true)}
            style={{ backgroundColor: mainTheme.colorRustyRed, margin: '0' }}
          >
            {t('$accountSettings.deleteAccountSection.button', '$$Usuń konto')}
          </ButtonPrimary>
        </div>
      </ContentWrapper>
      <DeleteAccountConfirmModal
        isOpened={isConfirmModalOpened}
        toggleModal={setIsConfirmModalOpened}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default DeleteAccountSection;
