import React, { Component, createRef } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { format } from 'date-fns';
import { connect } from 'react-redux';

import { prop } from 'ramda';
import { buildUrl } from 'utils/route';

import { URLS } from 'configuration';
import routeUrls from 'routes/routeUrls';

import showToast from 'utils/toast';
import { BAG_TYPES } from 'views/MenuPlanning/const/deliveryAndDietDetails';

import {
  getDeliveryAndDietDetails,
  DELIVERY_AND_DIET_DETAILS,
} from 'views/MenuPlanning/const/deliveryAndDietDetails';
import MENU_PLANNING_MODALS from 'views/MenuPlanning/const/modal';

import {
  getDeliveryAddressParam,
  getDeliveryDateParam,
  getDietDetailsParam,
} from 'views/MenuPlanning/services/menuPlanningRepresenter';

import { dateObjectToYearMonthDay } from 'common/services/dateHelpers';

import {
  addLoading,
  checkBagNeedsPay,
  changeSubscriptionIntent,
  changeBag,
  getBag,
  getMeals,
  getIntentBag,
  setSelectedDay,
  setVisibleCalendarDays,
  clearMeals,
  toggleEcobox,
  toggleSubscriptionDelivery,
  converSubscriptionMenuIntents,
  addMenuIntentsToStore,
  updateSelectedDayDetails,
} from 'views/MenuPlanning/actions/menuPlanningActions';
import { getUserDietCalendar } from '../../UserDiet/actions/userDietActions';
import { getUserDietCalendar as getUserDietWeekCalendar } from '../../MenuPlanning/actions/menuPlanningActions';
import {
  getLastDaysBeforeAndAfter,
  getLimitDates,
} from '../services/calendarService';
import { AddonDetails } from '../components';

import { unsetAllDaysToChange } from 'views/MenuPlanning/actions/menuPlanningActions';
import { withTranslation } from 'react-i18next';
import { withLocalUrl } from 'routes/withLocalUrl';
import { put } from 'utils/http';
import NOTIFICATIONS from 'common/shared/notifications';
const MenuPlanningContentFormContainer = WrappedComponent =>
  class MenuPlanningContentFormWrapper extends Component {
    state = {
      modalOpened: null,
      additionalPrice: 0,
      returnPoints: 0,
      dietsInWhichMealExists: null,
      formParams: {},
      upgradePrice: 0,
      isSubscriptionIntent: false,
      newPriceConfirmData: null,
      newParams: null,
      changeNeedPayment: false,
    };

    componentDidMount() {
      const isSubscriptionIntent =
        this.props.calendar?.days?.[this.props.selectedDay]?.['@type'] ===
        BAG_TYPES.SUBSCRIPTION_INTENT;

      this.setState(prevState => ({ ...prevState, isSubscriptionIntent }));

      localStorage.removeItem('dietDayToPay');
    }

    componentDidUpdate(prevProps, prevState) {
      const { calendar, selectedDay } = this.props;
      const isSubscriptionIntent =
        calendar?.days?.[selectedDay]?.['@type'] ===
        BAG_TYPES.SUBSCRIPTION_INTENT;

      if (
        JSON.stringify(calendar) !== JSON.stringify(prevProps.calendar) ||
        selectedDay !== prevProps.selectedDay
      ) {
        this.setState(prevState => ({ ...prevState, isSubscriptionIntent }));
      }

      // run when preparing is ending. prevState was false and current is true
      if (
        !prevProps.isUnmountedPreparingModal &&
        this.props.isUnmountedPreparingModal
      ) {
        this.actionAfterSaveBagPreparing();
      }
    }

    handleModalOpen = modalId => {
      this.props.unsetAllDaysToChange();
      this.setState({ modalOpened: modalId });
    };

    handleModalOpenWithPriceRequest = async modalId => {
      this.props.unsetAllDaysToChange();

      if (!this.state.isSubscriptionIntent) {
        this.setState({ modalOpened: modalId });
        try {
          const { cost, needPayment } = await this.props.checkBagNeedsPay(
            { useEcoContainers: true, changedAttributes: ['useEcoContainers'] },
            this.props.bag
          );

          if (needPayment) {
            this.setState({
              upgradePrice: cost,
              changeNeedPaymrnt: needPayment,
            });
          }
        } catch (error) {
          console.log('error', error);
        }
      } else {
        this.onBeforeBagSave({
          useEcoContainers: true,
          changedAttributes: ['useEcoContainers'],
          dates: [this.props.selectedDay],
        });
      }
    };

    getButtonAction = detailId => {
      switch (detailId) {
        case DELIVERY_AND_DIET_DETAILS.CHANGE_ADDRESS:
          return () =>
            this.handleModalOpen(MENU_PLANNING_MODALS.ADDRESS_CHANGE_MODAL);
        case DELIVERY_AND_DIET_DETAILS.SKIP_DELIVERY_DAY:
          return () => {
            const subscriptionIntentState = this.props.calendar?.days?.[
              this.props.selectedDay
            ]?.newState;
            this.props.addLoading(1);
            put(
              buildUrl(URLS.SUBSCRIPTION_CONFIG, {
                subscriptionId: this.props.selectedDietObject.subscription.id,
              }),
              {
                ...(subscriptionIntentState === 'SUBSCRIPTION_SUSPENDED'
                  ? { enableDays: [this.props.selectedDay] }
                  : { disableDays: [this.props.selectedDay] }),
              }
            )
              .then(async resp => {
                await this.updateWeekCalendar();
                this.props.addLoading(-1);
                showToast({
                  message: NOTIFICATIONS(this.props.t)
                    .DATA_UPDATED_SUCCESSFULLY,
                  type: 'success',
                });
              })
              .catch(() => {
                this.props.addLoading(-1);
              });
          };
        case DELIVERY_AND_DIET_DETAILS.CHANGE_DELIVERY_DATE:
          return () =>
            this.handleModalOpen(
              MENU_PLANNING_MODALS.DELIVERY_DATE_CHANGE_MODAL
            );
        case DELIVERY_AND_DIET_DETAILS.CHANGE_DIET_DETAILS:
          return () => {
            this.handleModalOpen(MENU_PLANNING_MODALS.DIET_CHANGE_MODAL);
          };
        case DELIVERY_AND_DIET_DETAILS.CHANGE_ADDONS:
          return () => {
            this.handleModalOpen(MENU_PLANNING_MODALS.ADDONS_CHANGE_MODAL);
          };
        case DELIVERY_AND_DIET_DETAILS.RATE_MENU:
          return () => {
            const {
              match: {
                params: { day: dayFromUrl },
              },
              day,
              setSelectedDay,
            } = this.props;

            setSelectedDay(dayFromUrl || dateObjectToYearMonthDay(day));
            this.handleModalOpen(MENU_PLANNING_MODALS.RATE_MENU_MODAL);
          };
        default:
          break;
      }
    };

    getDescription = t => detailId => {
      const { bag } = this.props;

      const deliveryDate = getDeliveryDateParam(bag);
      const deliveryAddress = getDeliveryAddressParam(bag);
      const dietDetails = getDietDetailsParam(bag, t);
      const deliveryAndDietDetails = getDeliveryAndDietDetails({ t });
      const rateMenuInfo = deliveryAndDietDetails.find(
        () => detailId === DELIVERY_AND_DIET_DETAILS.RATE_MENU
      );

      switch (detailId) {
        case DELIVERY_AND_DIET_DETAILS.CHANGE_ADDRESS:
          return deliveryAddress;
        case DELIVERY_AND_DIET_DETAILS.SKIP_DELIVERY_DAY:
          return deliveryDate;
        case DELIVERY_AND_DIET_DETAILS.CHANGE_DELIVERY_DATE:
          return deliveryDate;
        case DELIVERY_AND_DIET_DETAILS.CHANGE_DIET_DETAILS:
          return dietDetails;
        case DELIVERY_AND_DIET_DETAILS.RATE_MENU:
          return rateMenuInfo.description;
        case DELIVERY_AND_DIET_DETAILS.CHANGE_ADDONS:
          return <AddonDetails />;
        default:
          break;
      }
    };

    saveNewPriceParams = ({ newPriceConfirmData, newParams }) =>
      this.setState({ newParams, newPriceConfirmData });

    setDietsInWhichMealExists = existInDiets =>
      this.setState({ dietsInWhichMealExists: existInDiets });
    clearDietsInWhichMealExists = () =>
      this.setState({ dietsInWhichMealExists: null });

    onBeforeBagSave = params => {
      const { bag, checkBagNeedsPay, changeSubscriptionIntent } = this.props;

      if (this.state.isSubscriptionIntent) {
        const {
          date,
          applyForDates,
          changedAttributes,
          pickUpPoint,
          address,
          ...restParams
        } = params;

        const dates =
          this.props.dietDaysToChange.length === 0
            ? [this.props.selectedDay]
            : this.props.dietDaysToChange;

        const newParams = {
          dates,
          ...restParams,
          ...(pickUpPoint ? { pickUpPoint: `${pickUpPoint}` } : {}),
          ...(address ? { address: `${address}` } : {}),
        };

        changeSubscriptionIntent(newParams)
          .then(resp => {
            this.handleModalOpen(null);
            this.setState({
              formParams: newParams,
            });
            // TODO: add reload view
            // getBag
            this.props.updateSelectedDayDetails(resp.data);
            this.props.converSubscriptionMenuIntents(); // for updating meals after buying menu change
            this.props.addLoading(1);
            this.props.addMenuIntentsToStore(resp.data);
            if (newParams.costAcceptKey) {
              showToast({
                message: NOTIFICATIONS(this.props.t).DATA_UPDATED_SUCCESSFULLY,
                type: 'success',
              });
            } else {
              showToast({
                message: window.t(
                  '$*notification.subscriptionUpdatedSucessfully',
                  {
                    price: `${resp.data.priceAfterDiscount} ${this.props.currencySymbol}`,
                  }
                ),
                type: 'success',
              });
            }
          })
          .catch(error => {
            const needPriceChangeNotification =
              error.response.data?.['@type'] === 'PriceChangeKeyRequired';
            if (needPriceChangeNotification) {
              this.handleModalOpen(MENU_PLANNING_MODALS.PRICE_CONFIRM_MODAL);
              const newPriceConfirmData = error.response.data;
              this.setState({
                newParams,
                newPriceConfirmData,
                newPriceRequestData: newParams,
              });
              return;
            }

            this.handleModalOpen(null);

            return (
              error.response.data.violations &&
              error.response.data.violations.forEach(violation => {
                showToast({
                  message: violation.message,
                  type: 'error',
                });
              })
            );
          });
      } else {
        checkBagNeedsPay(params, bag).then(
          ({ cost, needPayment, returnPoints }) => {
            this.handleModalOpen(null);
            this.setState({
              additionalPrice: cost,
              returnPoints: returnPoints,
              formParams: params,
              changeNeedPayment: needPayment,
            });

            if (needPayment) {
              this.handleModalOpen(MENU_PLANNING_MODALS.DISCOUNT_INFO_MODAL);
            } else if (!needPayment && returnPoints > 0) {
              this.handleModalOpen(MENU_PLANNING_MODALS.POINTS_RETURN_MODAL);
            } else {
              this.onBagSave(params);
            }
          },
          error => {
            return (
              error.response.data.violations &&
              error.response.data.violations.forEach(violation => {
                showToast({
                  message: violation.message,
                  type: 'error',
                });
              })
            );
          }
        );
      }
    };

    updateCalendar = () => {
      const {
        selectedDiet,
        visibleCalendarDays,
        getUserDietCalendar,
      } = this.props;

      const [rangeStart, rangeEnd] = getLimitDates(visibleCalendarDays);

      getUserDietCalendar({
        dietId: selectedDiet,
        dateFrom: dateObjectToYearMonthDay(rangeStart),
        dateTo: dateObjectToYearMonthDay(rangeEnd),
      });
    };

    updateWeekCalendar = () => {
      const {
        selectedDiet,
        visibleWeekCalendarDays,
        getUserDietWeekCalendar,
      } = this.props;

      const [rangeStart, rangeEnd] = getLimitDates(visibleWeekCalendarDays);

      return getUserDietWeekCalendar({
        dietId: selectedDiet,
        dateFrom: dateObjectToYearMonthDay(rangeStart),
        dateTo: dateObjectToYearMonthDay(rangeEnd),
      });
    };

    onBagSave = (additionalParams = {}) => {
      const { bag } = this.props;
      const { formParams } = this.state;
      return this.saveBag(formParams, bag, additionalParams);
    };

    saveBag = (formParams, bag, additionalParams) => {
      const { changeBag, toLocalUrl } = this.props;

      if (!this.state.isSubscriptionIntent) {
        return changeBag(formParams, bag, additionalParams).then(
          ({ order, dayDetails } = {}) => {
            const paymentLink = prop('paymentLink', order);
            const customLink =
              formParams.dates &&
              toLocalUrl(
                `/my-diet/menu-planning/${dateObjectToYearMonthDay(
                  formParams.dates[0]
                )}/${this.props.bag.clientDiet.id}/${this.props.bag.id}`
              );
            formParams.dates
              ? localStorage.setItem('dietDayToPay', customLink)
              : localStorage.setItem('dietDayToPay', window.location.pathname);

            if (paymentLink) {
              window.location.href = paymentLink;
            } else {
              if (this.state.changeNeedPayment) {
                this.handleModalOpen(MENU_PLANNING_MODALS.PREPARING_CHANGES);
              } else {
                this.actionAfterSaveBagPreparing();
              }
            }
          },
          error =>
            error.response.data.violations &&
            error.response.data.violations.forEach(violation => {
              showToast({
                message: violation.message,
                type: 'error',
              });
            })
        );
      }
    };

    actionAfterSaveBagPreparing = () => {
      const {
        history,
        tmpChangeData: { formParams, bag, dayDetails },
      } = this.props;

      this.updateCalendar();

      if (formParams.dates) {
        const day = new Date(formParams.dates[0]);
        const bagId = bag.id;
        const dietId = bag.clientDiet.id;
        const daysRange = getLastDaysBeforeAndAfter(day);
        setVisibleCalendarDays(daysRange);
        setSelectedDay(dateObjectToYearMonthDay(day));
        history.push(
          buildUrl(routeUrls.MENU_PLANNING, {
            day: dateObjectToYearMonthDay(day),
            bagId,
            dietId,
          })
        );
      }

      this.props.updateSelectedDayDetails(dayDetails);
      // if diet is being changed
      this.props.addLoading(1);
      this.props.getMeals({ id: bag.id });

      if (this.state.changeNeedPayment) {
        this.handleModalOpen(null);
      }
    };

    handleEcobox = () => {
      const { changeBag, changeSubscriptionIntent } = this.props;

      if (this.state.isSubscriptionIntent) {
        this.onBeforeBagSave({ useEcoContainers: true });
      } else {
        return changeBag(
          { useEcoContainers: true, changedAttributes: ['useEcoContainers'] },
          this.props.bag
        ).then(response => {
          if (response) {
            const { order } = response;

            localStorage.setItem('dietDayToPay', window.location.pathname);

            const paymentLink = prop('paymentLink', order);

            if (paymentLink) {
              window.location.href = paymentLink;
            } else {
              window.location.reload();
            }
          }
        });
      }
    };

    render() {
      return <WrappedComponent {...this} {...this.props} {...this.state} />;
    }
  };

export default compose(
  connect(
    ({
      app: {
        brand: {
          allChangesWithoutCosts,
          allowChangeCalorific,
          allowChangeDiet,
          allowChangeVariant,
          monday,
          tuesday,
          wednesday,
          thursday,
          friday,
          saturday,
          sunday,
        },
        config: {
          multinational: { currencySymbol },
        },
      },
      menuPlanning: {
        bag,
        calendar,
        isLoading,
        isPremium,
        meals,
        selectedDay,
        selectedDiet,
        variant,
        dietDaysToChange,
        defaultSubscriptionDietDetails,
        visibleCalendarDays: visibleWeekCalendarDays,
        tmpChangeData,
        isUnmountedPreparingModal,
      },
      orderForm: {
        orderConfig: { additionalPrices, diets, variants },
      },
      userDiet: {
        visibleCalendarDays,
        selectedDietObject,
        currentlyMappingDays,
        list: userDiets,
      },
    }) => ({
      additionalPrices,
      allChangesWithoutCosts,
      allowChangeCalorific,
      allowChangeDiet,
      allowChangeVariant,
      currentlyMappingDays,
      bag,
      calendar,
      currencySymbol,
      selectedDietObject,
      daysConfig: {
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
      },
      dietDaysToChange,
      diets,
      isLoading,
      isPremium,
      userDiets,
      meals,
      selectedDay,
      selectedDiet,
      variant,
      variants,
      visibleCalendarDays,
      visibleWeekCalendarDays,
      defaultSubscriptionDietDetails,
      tmpChangeData,
      isUnmountedPreparingModal,
    }),
    {
      addLoading,
      changeBag,
      getBag,
      getMeals,
      getIntentBag,
      changeSubscriptionIntent,
      checkBagNeedsPay,
      clearMeals,
      getUserDietCalendar,
      setSelectedDay,
      setVisibleCalendarDays,
      toggleEcobox,
      toggleSubscriptionDelivery,
      updateSelectedDayDetails,
      unsetAllDaysToChange,
      converSubscriptionMenuIntents,
      addMenuIntentsToStore,
      getUserDietWeekCalendar,
    }
  ),
  withRouter,
  withTranslation(),
  withLocalUrl,
  MenuPlanningContentFormContainer
);
