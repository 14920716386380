import React from 'react';
import {
  DisplayOnDesktop,
  DisplayOnMobile,
} from '../../styledComponents/helpers';
import { BASE_URL, URLS } from '../../configuration';
import { connect } from 'react-redux';
import { MenuBanner } from './StyledComponents';

const Banner = ({
  bannerInfoEnabled,
  bannerTitle,
  bannerDescription,
  bannerDesktop,
  bannerMobile,
  resources,
}) => {
  if (!bannerInfoEnabled) return null;
  return (
    <div>
      <MenuBanner>
        <MenuBanner.ImageWrapper>
          <DisplayOnDesktop>
            {bannerDesktop && (
              <MenuBanner.Image
                src={`${BASE_URL}${URLS.UPLOADED_MEDIA}${resources[bannerDesktop]?.contentUrl}`}
                alt=""
              />
            )}
          </DisplayOnDesktop>
          <DisplayOnMobile>
            {bannerMobile && (
              <MenuBanner.Image
                src={`${BASE_URL}${URLS.UPLOADED_MEDIA}${resources[bannerMobile]?.contentUrl}`}
                alt=""
              />
            )}
          </DisplayOnMobile>
        </MenuBanner.ImageWrapper>
        <MenuBanner.TextWrapper>
          <MenuBanner.Title dangerouslySetInnerHTML={{ __html: bannerTitle }} />
          <MenuBanner.Description
            dangerouslySetInnerHTML={{
              __html: bannerDescription,
            }}
          />
        </MenuBanner.TextWrapper>
      </MenuBanner>
    </div>
  );
};

export default connect(
  ({
    app: {
      config: {
        modules: {
          MenuPage: {
            bannerInfoEnabled,
            bannerTitle,
            bannerDescription,
            bannerDesktop,
            bannerMobile,
            '@resources': resources,
          },
        },
      },
    },
  }) => ({
    bannerInfoEnabled,
    bannerTitle,
    bannerDescription,
    bannerDesktop,
    bannerMobile,
    resources,
  })
)(Banner);
