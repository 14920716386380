import React, { Fragment } from 'react';

import HeadingSection from 'layouts/components/HeadingSection';

import MyOrdersContent from 'views/MyOrders/containers/MyOrdersContent';
import { useTranslation } from 'react-i18next';

const MyOrders = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <HeadingSection
        title={t('$*header.historyOfOrders', '$$Historia zamówień')}
      />
      <MyOrdersContent />
    </Fragment>
  );
};

export default MyOrders;
