import React from 'react';
import ReactGA from 'react-ga';
import store from 'store.js';
import history from 'history.js';
import { prop } from 'ramda';
import { getDietInfoFromStore, createProductSchema, createProductSchemaFromStatusItem } from './gtm';
import { symbolToCurrency } from './currencies';

export const initGoogleAnalytics = trackingId => {
  ReactGA.initialize(trackingId);
};

export const setUserGA = userId => {
  const GoogleAnalytics =
    store.getState().app.config.modules.GoogleAnalytics || {};
  if (GoogleAnalytics.enabled) {
    ReactGA.set({
      userId,
    });
  }
};

export const addToCartGA = currentFormId => {  // ! needs rework - https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce#add-remove-cart
  ReactGA.event({
    category: 'Dane dostawy',
    action: 'Potwierdzono adres dostawy',
  });
};

export const pushGAPageViewEvent = linkToPage => {
  const state = store.getState();
  const { enabled } = state.app.config.modules.GoogleAnalytics || {};
  if (enabled) {
    const link = linkToPage ? linkToPage : `${history.location.pathname}${history.location.search}`
    ReactGA.set({ page: link });
    ReactGA.pageview(link);
  }
};

export const addTransactionGA = (id, revenue) => {
  try {
    const state = store.getState();
    const { enabled, useEcommerce, useEnhancedEcommerce } =
      state.app.config.modules.GoogleAnalytics || {};

    const currency = state.app.config.multinational.defaultRegion.currencyCode;

    if (enabled && useEcommerce && !useEnhancedEcommerce) {
      ReactGA.plugin.require('ecommerce');
      ReactGA.plugin.execute('ecommerce', 'addTransaction', {
        id: id,
        revenue: revenue,
        currency,
      });
      ReactGA.plugin.execute('ecommerce', 'send', null, null);
    }
  } catch (exception) {
    console.warn(exception);
  }
};

export const enhancedEcommerceAddProduct = formId => {
  try {
    const state = store.getState();
    const { enabled, useEnhancedEcommerce } =
      state.app.config.modules.GoogleAnalytics || {};

    const orderInfo = getDietInfoFromStore()[formId];
    const product = createProductSchema(orderInfo, formId);

    if (enabled && useEnhancedEcommerce) {
      ReactGA.plugin.require('ec');
      ReactGA.plugin.execute('ec', 'addProduct', product);
      ReactGA.plugin.execute('ec', 'setAction', 'add');
      ReactGA.event({
        category: 'product',
        action: 'click',
        label: 'add to cart'
      });
    }
  } catch (exception) {
    console.warn(exception);
  }
};

export const enhancedEcommerceCheckout = (id, revenue, paymentMethod, discountCode) => {
  try {
    const state = store.getState();
    const { enabled, useEnhancedEcommerce } =
      state.app.config.modules.GoogleAnalytics || {};

    const currency = symbolToCurrency(
      state.app.config.multinational.currencySymbol
    );

    if (enabled && useEnhancedEcommerce) {
      ReactGA.plugin.require('ec');
      ReactGA.plugin.execute('ec', 'setAction', 'checkout', {
        step: 1,
        option: {
          transactionId: id,
          paymentMethod: paymentMethod,
          currency,
          revenue: revenue,
          coupon: discountCode?.code || {},
          products: getDietInfoFromStore().map(createProductSchema),
        },
      });
      pushGAPageViewEvent()
    }
  } catch (exception) {
    console.warn(exception);
  }
};

export const enhancedEcommercePurchase = (orderStatus, revenue) => {

  const { id, discountCode, items, paymentType } = orderStatus;

  const isBagModification = items.some(item => item.type === "BagModification")

  const products = !isBagModification ? items.map(createProductSchemaFromStatusItem) : 'Bag/s Modification';

  try {
    const state = store.getState();
    const { enabled, useEnhancedEcommerce } =
      state.app.config.modules.GoogleAnalytics || {};

    const currency = symbolToCurrency(
      state.app.config.multinational.currencySymbol
    );

    if (enabled && useEnhancedEcommerce) {
      ReactGA.plugin.require('ec');
      ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
        id: id,
        affiliation: '0',
        revenue: revenue,
        coupon: discountCode?.code || {},
        option: {
          paymentMethod: paymentType,
          currency,
          products: products,
        },
      });
      pushGAPageViewEvent()
    }
  } catch (exception) {
    console.warn(exception);
  }
};
