import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getComponentProps } from 'utils/componentHelpers';

import DeliveryOptions from '../../components/delivery/DeliveryOptions';

export const useDeliverySections = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  return useMemo(
    () => [
      {
        title: t(
          '$*orderFormDeliverySections.deliveryOptionsTitle',
          '$$Gdzie mamy dostarczyć Twoje zamówienie?'
        ),
        component: DeliveryOptions,
        requiredProps: ['values', 'valid'],
        getComponentProps,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [language]
  );
};
