import styled from 'styled-components';

import { fromPxToRem } from 'styledComponents/helpers';

export const StyledFormLabel = styled.label`
  display: inline-block;
  margin-bottom: 10px;
  font-size: ${fromPxToRem('16px')};
  font-weight: ${props => props.theme.fontWeight500};
`;
