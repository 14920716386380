import React, { Fragment } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Flex, Box } from '@rebass/grid';
import { Field, Form } from 'react-final-form';

import routeURLS from 'routes/routeUrls';
import { URLS } from 'configuration';

import { post } from 'utils/http';
import showToast from 'utils/toast';

import { ButtonPrimary, ButtonLink } from 'styledComponents/elements/Button';
import { Heading } from 'styledComponents/elements/Typography';

import { INPUT_ID } from 'views/Auth/const/passwordRemindInput';

import FormTextInput from 'common/components/Form/FormTextInput';

import {
  getValidators,
  useFormValidation,
} from 'common/services/formValidation';
import { useTranslation } from 'react-i18next';

const PasswordRemindBox = styled.div`
  max-width: 500px;
  margin: 0 auto;
  border-radius: 6px;
  border: 1px solid ${props => props.theme.baseFontColor};
  padding: 20px;
`;

const PasswordRemind = ({ history, ...props }) => {
  const { t } = useTranslation();
  const validators = useFormValidation();

  const onSubmit = form => {
    const formToSend = form;
    formToSend.email = formToSend.email.toLowerCase();
    post(URLS.SEND_PASSWORD_RESET_EMAIL, formToSend)
      .then(() => history.push(routeURLS.RESET_PASSWORD_EMAIL_SENT))
      .catch(({ response }) =>
        showToast({
          message: response.data['hydra:description'],
          type: 'error',
        })
      );
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => {
        return (
          <Fragment>
            <form onSubmit={handleSubmit}>
              <PasswordRemindBox>
                <Flex flexDirection="column" alignItems="center">
                  <Heading>
                    {t('$*passwordRemind.heading', 'ODZYSKAJ HASŁO')}
                  </Heading>
                  <Box width={[1, 1, 3 / 4]}>
                    <Field
                      isBig
                      id={INPUT_ID.EMAIL}
                      name={INPUT_ID.EMAIL}
                      type="email"
                      component={FormTextInput}
                      label={t('$*registerForm.inputs.email', 'E-mail')}
                      isRequired={true}
                      validate={getValidators([
                        validators.required,
                        validators.email,
                      ])}
                      width="100%"
                    />
                  </Box>
                  <Box width={[1, 1, 'auto']}>
                    <ButtonPrimary
                      type="submit"
                      uppercased
                      sizeMiddle
                      weightBold
                      fullWidthOnMobile
                    >
                      {t('$*common.send', 'Wyślij')}
                    </ButtonPrimary>
                  </Box>
                  <Box width={[1, 1, 'auto']}>
                    <ButtonLink
                      onClick={() => history.push(routeURLS.AUTH_LOGIN)}
                    >
                      {t(
                        '$*passwordRemind.backToLoginCaption',
                        'Powrót do logowania'
                      )}
                    </ButtonLink>
                  </Box>
                </Flex>
              </PasswordRemindBox>
            </form>
          </Fragment>
        );
      }}
    />
  );
};

export default withRouter(PasswordRemind);
