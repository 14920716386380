import React from 'react';
import { Field, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { pick } from 'ramda';
import { connect } from 'react-redux';
import { Flex } from '@rebass/grid';

import FormSection from 'common/components/Form/FormSection';
import FormFieldBox from 'common/components/Form/FormFieldBox';
import { getFormInputs } from 'common/services/formHelpers';
import NOTIFICATIONS from 'common/shared/notifications';

import { ContentWrapper } from 'styledComponents/blocks/ContentWrapper';
import { ButtonLink } from 'styledComponents/elements/Button';
import { DisplayOnMobile, DisplayOnDesktop } from 'styledComponents/helpers';

import {
  INITIAL_VALUES,
  INPUT_IDS,
  useAgreementsFormInputs,
} from 'views/AccountSettings/const/agreementsFormInputs';

import showToast from 'utils/toast';

import { updateUserData } from 'views/AccountSettings/actions/accountSettingsActions';
import { useTranslation } from 'react-i18next';
import { SubmitButton } from './SubmitButton';

const AgreementsForm = ({ updateUserData, brand, userData }) => {
  const { t } = useTranslation();
  const AGREEMENTS_FORM_INPUTS = useAgreementsFormInputs();
  const agreementsFormInputs = getFormInputs(
    [INPUT_IDS.MARKETING_DATA_PROCESSING_AGREEMENT],
    AGREEMENTS_FORM_INPUTS
  );

  const onSubmit = (form, { getState }) => {
    // getState - internal final form state
    const { dirtyFields } = getState();
    const changedFields = Object.keys(dirtyFields);

    return updateUserData(form, changedFields)
      .then(() => {
        showToast({
          message: NOTIFICATIONS(t).DATA_UPDATED_SUCCESSFULLY,
          type: 'success',
        });
      })
      .catch(error => {
        return { [FORM_ERROR]: error };
      });
  };

  const agreementsInitialValues = pick(
    [INPUT_IDS.MARKETING_DATA_PROCESSING_AGREEMENT],
    userData
  );

  const saveAgreementsCaption = t(
    '$*common.saveChangesCaption',
    'Zapisz zmiany'
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={agreementsInitialValues || INITIAL_VALUES}
      render={({ handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <ContentWrapper padding="40px">
            <FormSection
              title={t(
                '$*accountSettings.agreements.title',
                '$$Zgody marketingowe'
              )}
              action={
                <DisplayOnDesktop>
                  <SubmitButton
                    submitting={submitting}
                    pristine={pristine}
                    caption={saveAgreementsCaption}
                    disabledWhenPristine
                  />
                </DisplayOnDesktop>
              }
            >
              <Flex
                flexWrap="wrap"
                justifyContent="center"
                flexDirection="column"
              >
                {agreementsFormInputs.map(({ id, label, width, ...input }) => {
                  const inputUpdated = { ...input, link: brand[input.link] };

                  return (
                    <FormFieldBox key={id} width={width}>
                      <Field isBig componentStyle="rounded" {...inputUpdated}>
                        {brand[label]}
                      </Field>
                    </FormFieldBox>
                  );
                })}
              </Flex>
            </FormSection>
            <DisplayOnMobile>
              <SubmitButton
                submitting={submitting}
                pristine={pristine}
                disabledWhenPristine
                caption={saveAgreementsCaption}
              />
            </DisplayOnMobile>
          </ContentWrapper>
        </form>
      )}
    />
  );
};

export default connect(
  ({ app: { brand }, accountSettings: { userData } }) => ({
    brand,
    userData,
  }),
  {
    updateUserData,
  }
)(AgreementsForm);
