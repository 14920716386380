import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { Box, Flex } from '@rebass/grid';

import Modal from 'common/components/Modal';
import ConfirmButtonWrapper from 'common/components/Modal/ConfirmButtonWrapper';

import { ButtonLink } from 'styledComponents/elements/Button';
import { Paragraph } from 'styledComponents/elements/Typography';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import FormCheckbox from 'common/components/Form/FormCheckbox';
import styled from 'styled-components';
import { fromPxToRem } from 'styledComponents/helpers';

const AdditionalPaymentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PriceRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: 0 -3px;
  align-items: baseline;
`;

const OldPrice = styled.span`
  flex: 0 0 auto;
  padding: 0 3px;
  text-decoration: line-through;
  color: ${props => props.theme.colorRed};
  font-size: ${fromPxToRem('14px')};
`;

const NewPrice = styled.div`
  display: flex;
  padding: 0 3px;
  overflow-wrap: anywhere;
`;

const DiscountInfoModal = ({
  additionalPrice,
  onSave,
  isOpened,
  toggleModal,
  id,
}) => {
  const { t } = useTranslation();
  const currencySymbol = useSelector(
    ({
      app: {
        config: {
          multinational: { currencySymbol },
        },
      },
    }) => currencySymbol,
    shallowEqual
  );

  const {
    allowClientPayWithMoneboxEverywhere,
    moneyBox,
    pointsToCashRate,
  } = useSelector(
    ({
      accountSettings: { userData },
      app: {
        config: { modules, pointsToCashRate },
      },
    }) => ({
      moneyBox: userData.moneyBox,
      pointsToCashRate,
      allowClientPayWithMoneboxEverywhere:
        modules.ConfigClientPanel.allowClientPayWithMoneboxEverywhere,
    })
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [usePointsToPay, setUsePointsToPay] = useState(false);

  const onSubmit = form => {
    if (form.payExtra) {
      setIsSubmitting(true);
      onSave({ usePointsToPay }).then(() => {
        setIsSubmitting(false);
        // toggleModal();
      });
    } else {
      toggleModal();
    }
  };

  const pointsInCash = moneyBox * pointsToCashRate;
  const restAdditionalPrice = additionalPrice - pointsInCash;

  const canUsePaymentPoints =
    allowClientPayWithMoneboxEverywhere &&
    (restAdditionalPrice <= 0 || restAdditionalPrice > 0.5);

  return (
    <Modal
      id={id}
      title={t('$*discountInfoModal.title', 'Uwaga!')}
      isOpened={isOpened}
      toggleModal={() => toggleModal()}
      contentPadding="0 30px"
      width="355px"
    >
      <Paragraph textAlign="center">
        {t(
          '$*discountInfoModal.mainLabel',
          'Wybrana zmiana wiąże się z dopłatą.'
        )}
      </Paragraph>
      <Form
        onSubmit={onSubmit}
        initialValues={{ payExtra: true }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Flex justifyContent="center">
              <Box mt={28}>
                <AdditionalPaymentWrapper>
                  <span style={{ marginRight: '8px' }}>
                    {t('$*discountInfoModal.supplementLabel', ' Dopłata')}:{' '}
                  </span>
                  {usePointsToPay ? (
                    <PriceRow>
                      <OldPrice>
                        {additionalPrice.toFixed(2)} {currencySymbol}
                      </OldPrice>
                      <NewPrice>
                        {restAdditionalPrice > 0
                          ? restAdditionalPrice.toFixed(2)
                          : 0}{' '}
                        {currencySymbol}
                      </NewPrice>
                    </PriceRow>
                  ) : (
                    `${additionalPrice.toFixed(2)} ${currencySymbol}`
                  )}
                </AdditionalPaymentWrapper>

                {canUsePaymentPoints && moneyBox > 0 && (
                  <Paragraph>
                    <FormCheckbox
                      componentStyle="switch"
                      input={{
                        checked: usePointsToPay,
                        onChange: () => {
                          setUsePointsToPay(!usePointsToPay);
                        },
                      }}
                    >
                      {t(
                        '$*common.usePointsToPayOrder',
                        '$$Użyj punktów ze skarbonki'
                      )}
                    </FormCheckbox>
                  </Paragraph>
                )}
              </Box>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" mt="25px">
              <ButtonLink
                type="button"
                onClick={() => toggleModal()}
                uppercased
                sizeMiddle
              >
                {t('$*common.cancel', 'Anuluj')}
              </ButtonLink>
              <ConfirmButtonWrapper
                type="submit"
                buttonText={t('$*common.continue', 'Kontynuuj')}
                withSpinner
                isLoading={isSubmitting}
                disabled={isSubmitting}
              />
            </Flex>
          </form>
        )}
      />
    </Modal>
  );
};

export default DiscountInfoModal;
