import { isBefore, isSameDay } from 'date-fns';

import { DAY_STATUSES, DISABLED_DAY_STATUSES } from '../const/dayStatuses';

import RateMenuDayCell from 'common/components/CalendarDayCell/RateMenuDayCell';
import DeliveredCell from 'common/components/CalendarDayCell/DeliveredCell';
import RatedMenuDayCell from 'common/components/CalendarDayCell/RatedMenuDayCell';
import PartiallyRatedMenuDayCell from 'common/components/CalendarDayCell/PartiallyRatedMenuDayCell';
import AddDayDayCell from 'common/components/CalendarDayCell/AddDayDayCell';
import DetailsAfter3OrMoreDaysDayCell from 'common/components/CalendarDayCell/DetailsAfter3OrMoreDaysDayCell';
import SubscriptionDayDisabled from 'common/components/CalendarDayCell/SubscriptionDayDisabled';
import SubscriptionDayEnabled from 'common/components/CalendarDayCell/SubscriptionDayEnabled';
import SubscriptionDayHolded from 'common/components/CalendarDayCell/SubscriptionDayHolded';
import BasicDayCell from 'common/components/CalendarDayCell/BasicDayCell';
import DisabledDay from 'common/components/CalendarDayCell/DisabledDay';
import DisabledDayWithBag from 'common/components/CalendarDayCell/DisabledDayWithBag';
import EditMenuDayCell from 'common/components/CalendarDayCell/EditMenuDayCell';
import MenuSoonDayCell from 'common/components/CalendarDayCell/MenuSoonDayCell';
import NotDeliveredBlockedDayCellFuture from 'common/components/CalendarDayCell/NotDeliveredBlockedDayCellFuture';

import { pathOr, prop } from 'ramda';

import { dateObjectToYearMonthDay } from 'common/services/dateHelpers';

export const getLastDayOfRange = range => range[range.length - 1];

export const getDayCellComponent = ({ day, status, disabledDays }) => {
  if (disabledDays.includes(day.getDay())) {
    return BasicDayCell;
  }

  switch (status) {
    case DAY_STATUSES.DETAILS_SHOW_PREV: //delete
    case DAY_STATUSES.DELIVERED_NOT_RATED_BLOCKED:
      return DeliveredCell;
    case DAY_STATUSES.RATE_MENU: //delete
    case DAY_STATUSES.DELIVERED_NOT_RATED_CAN_RATE:
      return RateMenuDayCell;
    case DAY_STATUSES.DELIVERED_RATED_PARTIALLY_CAN_RATE:
      return PartiallyRatedMenuDayCell;
    case DAY_STATUSES.RATED_MENU: //delete
    case DAY_STATUSES.DELIVERED_RATED_BLOCKED:
    case DAY_STATUSES.DELIVERED_RATED_PARTIALLY_BLOCKED:
    case DAY_STATUSES.DELIVERED_RATED_CAN_RATE:
      return RatedMenuDayCell;
    case DAY_STATUSES.DETAILS_SHOW_NEXT: //delete
      return DetailsAfter3OrMoreDaysDayCell;
    case DAY_STATUSES.SUBSCRIPTION_DISABLED: // to zostaje, bo jest dodane po zmianie kalendarza na wstrzymaj/wznów
      return SubscriptionDayDisabled;
    case DAY_STATUSES.SUBSCRIPTION_ENABLED: // to zostaje, bo jest dodane po zmianie kalendarza na wstrzymaj/wznów
    case DAY_STATUSES.SUBSCRIPTION_DISABLED_PROGRESS: // to zostaje, bo jest dodane po zmianie kalendarza na wstrzymaj/wznów
      return SubscriptionDayEnabled;
    case DAY_STATUSES.SUBSCRIPTION_HOLDED: //delete
    case DAY_STATUSES.SUBSCRIPTION_SUSPENDED:
      return SubscriptionDayHolded;
    case DAY_STATUSES.ADD_DIET: //delete
    case DAY_STATUSES.NOT_DIET_CAN_PLACE_ORDER:
      return AddDayDayCell;
    case DAY_STATUSES.DISABLED:
      return DisabledDay;
    case DAY_STATUSES.DISABLED_WITH_BAG:
    case DAY_STATUSES.ERROR_ON_RENEW:
      return DisabledDayWithBag;
    case DAY_STATUSES.NOT_DELIVERED_WITH_CONFIGURABLE_ALL:
      return EditMenuDayCell;
    case DAY_STATUSES.NOT_DELIVERED_WITH_CONFIGURABLE_WITHOUT_MENU:
      return MenuSoonDayCell;
    case DAY_STATUSES.NOT_DELIVERED_BLOCKED: {
      return NotDeliveredBlockedDayCellFuture;
    }
    default:
      return BasicDayCell; //disabled day
  }
};

export const getDayStatusByCalendarDays = ({
  currentDay,
  calendarDays = [],
}) => {
  const dayObject = prop(dateObjectToYearMonthDay(currentDay), calendarDays);

  return prop('newState', dayObject);
};

export const hasDisabledDayStatus = dayStatus =>
  Object.values(DISABLED_DAY_STATUSES).find(status => status === dayStatus); //delete

export const getCalendarDayStatus = ({
  dietsMissing,
  currentDay,
  calendarDays,
  exludedDays,
}) => {
  const isExluded =
    exludedDays?.find(
      day => day.toDateString() === currentDay.toDateString()
    ) ?? false;

  return dietsMissing
    ? currentDay < new Date() || isExluded
      ? DAY_STATUSES.NO_DIET //@todo ogarnąć to dokładniej
      : DAY_STATUSES.ADD_DIET //@todo ogarnąć to dokładniej
    : getDayStatusByCalendarDays({
        currentDay,
        calendarDays,
      });
};
