import React from 'react';
import { format } from 'date-fns';
import Select from 'react-select';
import { SubHeading } from './StyledComponents';
import { withTranslation } from 'react-i18next';
import DishTile from 'common/components/DishTile/DishTile.container';
import Masonry from 'react-masonry-css';
import Spinner from 'styledComponents/elements/LoadingSpinner';

const removeMealDuplicates = meals => {
  const reducedMeals = meals.reduce((prev, meal) => {
    return {
      ...prev,
      [meal.dish.id]: meal,
    };
  }, {});
  return Object.values(reducedMeals);
};

const SingleDayComponent = ({ t, mealsByDays, isLoading }) => {
  const [selectedDay, setSelectedDay] = React.useState(null);

  const availableDays = mealsByDays
    .slice()
    .sort((a, b) => new Date(a.date) - new Date(b.date))
    .reduce((prev, curr) => {
      const key = format(curr.date, 'DD-MM-YYYY');
      return {
        ...prev,
        [key]: {
          date: key,
          meals: prev[key] ? [...prev[key].meals, curr] : [curr],
        },
      };
    }, {});

  const availableDaysForSelect = Object.keys(availableDays).map(date => ({
    label: date,
    value: date,
  }));

  React.useEffect(() => {
    if (availableDaysForSelect.length > 0 && !selectedDay) {
      let selectedDay = availableDaysForSelect[0];
      let reversedDate = selectedDay.value.split('-').reverse().join('-');

      let index = 1;
      while (
        new Date(reversedDate) < new Date() &&
        index < availableDaysForSelect.length
      ) {
        selectedDay = availableDaysForSelect[index];
        reversedDate = selectedDay.value.split('-').reverse().join('-');
        index++;
      }

      setSelectedDay(selectedDay);
    }
  }, [availableDaysForSelect]);

  const selectedDayToShow = availableDays[selectedDay?.value]?.meals ?? [];
  const mealsWithoutDuplicates = removeMealDuplicates(selectedDayToShow);

  return (
    <div>
      <SubHeading>{t('$*menu.chooseDay', 'Wybierz dzień')}</SubHeading>
      <Select
        onChange={setSelectedDay}
        options={availableDaysForSelect}
        value={selectedDay}
      />
      <div style={{ marginTop: '20px' }}>
        {isLoading ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Spinner size="30px" />
            <span style={{ marginLeft: '10px' }}>
              {t('$*common.isDataLoading')}
            </span>
          </div>
        ) : (
          <Masonry
            breakpointCols={{ default: 5, 1400: 4, 1000: 3, 780: 2, 500: 1 }}
            className="masonry-grid"
            columnClassName="masonry-grid-column"
          >
            {mealsWithoutDuplicates.map(({ dish }) => (
              <DishTile key={dish['@id']} {...dish} />
            ))}
          </Masonry>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(SingleDayComponent);
