export const sortBy = (collection, property) =>
  [...collection].sort((a, b) => a[property] - b[property]);

export const sortByPosition = collection => sortBy(collection, 'position');

export const sortByDate = (collection, property) =>
  [...collection].sort((a, b) => new Date(a[property]) - new Date(b[property]));

export const removeSpaceRepetition = input => {
  return input
    ?.split(' ')
    .filter(x => x.length > 0)
    .join(' ');
};
export const trimAddressFields = addressObj => {
  let keys = [
    'city',
    'street',
    'postCode',
    'buildNumber',
    'placeNumber',
    'gate',
  ];

  return Object.keys(addressObj)
    .filter(k => keys.includes(k))
    .reduce((memo, key) => {
      memo[key] = removeSpaceRepetition(memo[key]);
      return memo;
    }, addressObj);
};

export const slugify = string => {
  const a =
    'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b =
    'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

export const loadFontToDocumentHead = url => {
  var head = document.getElementsByTagName('head')[0];
  var link = document.createElement('link');
  link.type = 'text/css';
  link.rel = 'stylesheet';
  head.appendChild(link);
  link.href = url;
};

export function pickBy(object) {
  const obj = {};
  for (const key in object) {
    if (object[key] !== undefined) {
      obj[key] = object[key];
    }
  }
  return obj;
}

export const isFalsify = value => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0) ||
    (typeof value === 'number' && (value === 0 || isNaN(value)))
  );
};

export const getIntegerIfIntegerOrFixed = (number, decimalNumber = 2) =>
  Number.isInteger(number) ? number : number.toFixed(decimalNumber);

export const checkImageExists = imageUrl => {
  return new Promise((resolve, reject) => {
    if (imageUrl !== null) {
      const img = new Image();

      img.onload = () => resolve(true);
      img.onerror = () => reject(false);
      img.src = imageUrl;
    } else {
      reject(false);
    }
  });
};

export const clearAllSiteData = () => {
  localStorage.clear();
  sessionStorage.clear();

  // DELETE ALL COOKIES
  const cookies = document.cookie.split('; ');
  for (let cookie of cookies) {
    const index = cookie.indexOf('=');
    const name = ~index ? cookie.substr(0, index) : cookie;

    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
  }
};
