import { Fragment, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import DishImage from './DishImage';
import DishInfoWrapper from './DishInfoWrapper';
import DishName from './DishName';
import DishTags from './DishTags';
import { useDishTileContext } from './DishTile.context';
import DishWrapper from './DishWrapper';

import imagePlaceholder from 'assets/images/image_placeholder.jpg';
import { Styled } from './Dish.styled';
import DishSizeInfo from './DishSizeInfo';

import { BASE_URL, URLS } from 'configuration';

const DishTileDesktop = () => {
  const { t } = useTranslation();
  const innerWrapperRef = useRef(null);

  const { dishImagePlaceholderContentUrl } = useSelector(
    ({ app: { config: { modules: { ConfigClientPanel } = {} } } = {} }) => ({
      dishImagePlaceholderContentUrl:
        ConfigClientPanel?.['@resources']?.[
          ConfigClientPanel?.dishImagePlaceholder
        ]?.contentUrl,
    })
  );

  const [showScrollDown, setShowScrollDown] = useState(false);
  const {
    isExistImage,
    isOpenMenu,
    showImage,
    toggleOpenMenu,
    dish,
  } = useDishTileContext();

  useEffect(() => {
    if (innerWrapperRef?.current) {
      setShowScrollDown(
        innerWrapperRef.current.scrollHeight >
          innerWrapperRef.current.clientHeight
      );
    }
  }, [setShowScrollDown]);

  const { nameForClient, clientImage = '', tags = [] } = dish;
  const imageUrl =
    showImage && isExistImage
      ? clientImage
      : dishImagePlaceholderContentUrl
      ? `${BASE_URL}${URLS.UPLOADED_MEDIA}${dishImagePlaceholderContentUrl}`
      : imagePlaceholder;
  const {
    components,
    allergens,
  } = dish?.lowestDishSize?.ingredientsSortedByUsage?.reduce(
    (acc, ingredient) => {
      let name = null;
      let allergens = [];

      if (ingredient !== null) {
        name = ingredient?.name;
        allergens = ingredient?.allergens?.map(({ value }) => value);
      }

      const newAllergens = allergens.filter(
        allergen => !acc.allergens.find(accAllergen => accAllergen === allergen)
      );
      return {
        ...acc,
        components: [...acc.components, name].filter(Boolean),
        allergens: [...acc.allergens, ...newAllergens],
      };
    },
    { components: [], allergens: [] }
  ) || { components: [], allergens: [] };

  return (
    <DishWrapper onClick={toggleOpenMenu}>
      <DishImage showImage={showImage} imageUrl={imageUrl} />
      <DishInfoWrapper>
        <Styled.Wrapper>
          <Styled.WrapperCollapsed isOpenMenu={isOpenMenu}>
            <Styled.Row
              style={{
                ...(!isOpenMenu ? { height: '100%' } : {}),
              }}
            >
              <Styled.Col>
                {!isOpenMenu && (
                  <Styled.Row>
                    <Styled.Col>
                      <DishName name={nameForClient} />
                    </Styled.Col>
                    <Styled.Col auto>
                      <i className="fas fa-arrow-up" />
                    </Styled.Col>
                  </Styled.Row>
                )}

                {isOpenMenu && (
                  <Fragment>
                    <DishName name={nameForClient} />
                    {tags?.length > 0 && (
                      <Styled.TagsWrapper>
                        <DishTags tags={tags} />
                      </Styled.TagsWrapper>
                    )}
                  </Fragment>
                )}
              </Styled.Col>
              {isOpenMenu && (
                <Styled.Col auto>
                  <div tw="flex flex-col items-center">
                    <i className="fas fa-arrow-down" />
                  </div>
                </Styled.Col>
              )}
            </Styled.Row>
          </Styled.WrapperCollapsed>

          {isOpenMenu && (
            <>
              <Styled.InnerWrapper
                onClick={e => e.stopPropagation()}
                ref={innerWrapperRef}
                onScroll={e => {
                  const { scrollHeight, scrollTop, clientHeight } = e.target;
                  const scroll = scrollHeight - scrollTop - clientHeight;

                  if (scroll <= 20) {
                    setShowScrollDown(false);
                  } else {
                    setShowScrollDown(true);
                  }
                }}
              >
                <DishSizeInfo
                  allergens={allergens}
                  components={components}
                  calories={
                    dish?.lowestDishSize?.calories /
                    dish?.lowestDishSize?.size?.portionDivision
                  }
                />
              </Styled.InnerWrapper>

              <Styled.ScrollDown
                showScrollDown={showScrollDown}
                onClick={e => e.stopPropagation()}
              >
                {t('$*common.scrollDown')}
              </Styled.ScrollDown>
            </>
          )}
        </Styled.Wrapper>
      </DishInfoWrapper>
    </DishWrapper>
  );
};

export default DishTileDesktop;
