import {
  ADD_BAG,
  CHANGE_QUANTITY_ADDON,
  REMOVE_ADDON_FROM_BAG,
  REMOVE_BAG,
} from './const';

import { post } from 'utils/http';
import { URLS } from 'configuration';

export const getBag = iriId => getState => {
  const { bags } = getState().basket;
  const currentBag = bags.find(({ bag }) => bag === iriId);

  return currentBag;
};

export const addBagToBasket = bag => dispatch => {
  dispatch({
    type: ADD_BAG,
    payload: { bag },
  });
};

export const changeQuantityAddon = (
  bagIriId,
  addonIriId,
  quantity
) => dispatch => {
  dispatch({
    type: CHANGE_QUANTITY_ADDON,
    payload: { bagIriId, addonIriId, quantity },
  });
};

export const removeAddonFromBag = (bagIriId, addonIriId) => dispatch => {
  dispatch({
    type: REMOVE_ADDON_FROM_BAG,
    payload: { bagIriId, addonIriId },
  });
};

export const removeBag = bagIriId => dispatch => {
  dispatch({
    type: REMOVE_BAG,
    payload: { bagIriId },
  });
};

export const submitOrder = ({ usePointsToPay }) => async (
  dispatch,
  getState
) => {
  const {
    basket: { bags },
  } = getState();

  const items = bags.map(({ date, addons, ...restBag }) => {
    return {
      ...restBag,
      addons: addons.map(({ category, price, name, ...restAddon }) => ({
        ...restAddon,
      })),
    };
  });

  const { data } = await post(URLS.ORDER_ADDONS, { items, usePointsToPay });

  if (data?.paymentLink) {
    window.location.href = data?.paymentLink;
  } else {
    window.location.reload();
  }
};
