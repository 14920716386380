export const DELIVERY_AND_DIET_DETAILS = {
  RATE_MENU: 0,
  CHANGE_ADDRESS: 1,
  CHANGE_DELIVERY_DATE: 2,
  CHANGE_DIET_DETAILS: 3,
  CHANGE_ADDONS: 4,
  SKIP_DELIVERY_DAY: 5,
};

export const BAG_TYPES = {
  BAG: 'Bag',
  SUBSCRIPTION_INTENT: 'SubscriptionIntent',
};

export const getDeliveryAndDietDetails = ({ t, condition = {} }) => [
  {
    id: condition?.isSubscriptionIntent
      ? DELIVERY_AND_DIET_DETAILS.SKIP_DELIVERY_DAY
      : DELIVERY_AND_DIET_DETAILS.CHANGE_DELIVERY_DATE,
    title: t('$*menuPlaning.details.dateTitle', '$$Data dostawy'),
    actionButtonText: condition?.isSubscriptionIntent
      ? condition?.subscriptionIntentState === 'SUBSCRIPTION_DISABLED'
        ? t('$*menuPlaning.details.resumeDayCaption', '$$Wznów dzień dostawy')
        : t('$*menuPlaning.details.holdDayCaption', '$$Wstrzymaj dzień dostawy')
      : t(
          '$*menuPlaning.details.moveToOtherDayCaption',
          '$$Przenieś na inny dzień'
        ),
    isEditable: ['allowChangeDeliveryDate'],
    changeDeadLine: 'changeDeliveryDateDeadLine',
  },
  {
    id: DELIVERY_AND_DIET_DETAILS.CHANGE_ADDRESS,
    title: t('$*menuPlaning.details.addressTitle', '$$Adres dostawy'),
    actionButtonText: t(
      '$*menuPlaning.details.changeAddressCaption',
      '$$Dokonaj zmiany'
    ),
    isEditable: ['allowChangeAddress'],
    changeDeadLine: 'changeAddressDeadLine',
  },
  {
    id: DELIVERY_AND_DIET_DETAILS.CHANGE_DIET_DETAILS,
    title: t('$*menuPlaning.details.yourDietTitle', '$$Twoja dieta'),
    actionButtonText: t(
      '$*menuPlaning.details.modifyDietCaption',
      '$$Modyfikuj dietę'
    ),
    isEditable: [
      'allowChangeDiet',
      'allowChangeVariant',
      'allowChangeCalorific',
    ],
    changeDeadLine: 'changeDietDeadLine',
  },
  ...(!condition?.isSubscriptionIntent
    ? [
        {
          id: DELIVERY_AND_DIET_DETAILS.CHANGE_ADDONS,
          title: t('$*menuPlaning.details.yourAddonsTitle', '$$Twoje dodatki'),
          actionButtonText: t(
            '$*menuPlaning.details.checkOccasion',
            '$$Sprawdź okazję!'
          ),
          isEditable: ['allowChangeDiet'],
          changeDeadLine: 'buyAddonsDeadline',
        },
      ]
    : []),
];
