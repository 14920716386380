import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import FormSection from 'common/components/Form/FormSection';
import ReactPixel from 'react-facebook-pixel';

import withBasket from '../components/withBasket';
import { getDeliveryPickupPoints } from '../actions/orderFormActions';
import { useDeliverySections } from '../const/formConfig/deliverySections';
import { useCurrentTabNames } from '../components/OrderFormTabs/useCurrentTabNames';

const OrderFormDelivery = ({ ...props }) => {
  useCurrentTabNames('OrderFormDelivery');
  const DELIVERY_SECTIONS = useDeliverySections();
  return DELIVERY_SECTIONS.map((section, index) => (
    <FormSection key={index} title={section.title}>
      <section.component
        {...section.getComponentProps(props, section.requiredProps)}
      />
    </FormSection>
  ));
};

export default compose(
  connect(
    ({ nativeAppConfig: { disableTracking } }) => ({
      disableTracking,
    }),
    { getDeliveryPickupPoints }
  ),
  lifecycle({
    componentDidMount() {
      this.props.getDeliveryPickupPoints();
      !this.props.disableTracking && ReactPixel.trackCustom('Dane dostawy');
    },
  }),
  withBasket
)(OrderFormDelivery);
