import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledNavbarItem = styled.li`
  margin: 0 20px;
`;

const NavbarItem = ({ path, label, props }) => (
  <StyledNavbarItem>
    <Link to={path} {...props}>
      {label}
    </Link>
  </StyledNavbarItem>
);

export default NavbarItem;
