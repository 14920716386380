import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { BASE_URL } from 'configuration';

import PriceItem from './PriceItem';
import { SubtitleWithCrossLine } from 'styledComponents/elements/Typography';
import Basket from 'styledComponents/elements/NewOrder/Basket';
import MobileBasket from 'styledComponents/elements/NewOrder/MobileBasket';
import Hover from 'styledComponents/elements/NewOrder/Hover';
import { PRICES_CONFIG } from 'views/NewOrder/components/summary/const';

const NewOrderBasket = ({
  calculations,
  currentFormId,
  diets,
  variants,
  forms,
  mobile = false,
  hover,
  multinational,
  paymentMode,
}) => {
  const { t } = useTranslation();
  const currencySymbol = multinational.currencySymbol;

  useEffect(() => {
    const cartSummary = document.querySelector('.cart-summary-mobile');
    const cartMarker = document.querySelector('.cart-summary-marker');

    if (cartMarker) {
      const handler = entries => {
        if (!entries[0].isIntersecting) {
          cartSummary.classList.add('is-revealed');
        } else {
          cartSummary.classList.remove('is-revealed');
        }
      };

      const observer = new window.IntersectionObserver(handler, {
        threshold: 0.6,
      });
      observer.observe(cartMarker);
    }
  });

  const getCurrentDietName = index => {
    return diets.find(diet => diet.id === parseInt(forms[index].diet)).name;
  };

  const getCurrentVariantName = index => {
    return variants.find(
      variant => variant.id === parseInt(forms[index].variant)
    ).name;
  };

  const totalPrice = {
    beforeUsePoint: calculations.priceAfterDiscount || 0,
    afterUsePoint:
      calculations.priceAfterDiscount -
        calculations.usedMoneyBoxPointsAsPrice || 0,
  };

  const noOrderCostBasketInfo =
    paymentMode === '1'
      ? t(
          '$*basket.subscriptionPayment.noOrderCost',
          '$$Wybierz pierwszy dzień dostawy, aby wyliczyć koszty'
        )
      : t(
          '$*basket.oneTimePayment.noOrderCost',
          '$$Wybierz dni dostawy, aby wyliczyć koszty'
        );

  return (
    <Fragment>
      <Basket.Title mobile={mobile}>
        {t('$*basket.currentlyInBasketTitle', '$$Obecnie w koszyku')}
      </Basket.Title>
      <Basket mobile={mobile} className="cart-summary-marker">
        {calculations?.dietCosts
          ? calculations?.dietCosts?.map((dietCost, index) => (
              <Fragment key={index}>
                <Basket.Item>
                  <SubtitleWithCrossLine isActive={currentFormId === index}>
                    <SubtitleWithCrossLine.Text
                      isActive={currentFormId === index}
                    >
                      {getCurrentDietName(index)},{' '}
                      {getCurrentVariantName(index)}
                    </SubtitleWithCrossLine.Text>
                  </SubtitleWithCrossLine>

                  {PRICES_CONFIG({
                    t,
                    dietCost,
                    paymentMode,
                    addons: forms[index].addons || [],
                    deliveryType: forms[index].deliveryType,
                    dietLength: forms[index].dietLength,
                  }).map((priceItem, _index) => (
                    <PriceItem
                      key={_index}
                      priceItem={priceItem}
                      currencySymbol={currencySymbol}
                    />
                  ))}
                </Basket.Item>
              </Fragment>
            ))
          : noOrderCostBasketInfo}
        {/* <Basket.TotalPrice>
          <div>{t('$*basket.totalAmount', '$$Cena całkowita:')}</div>
          <Basket.ItemEntryPriceRow>
            {totalPrice.beforeUsePoint !== totalPrice.afterUsePoint && (
              <Basket.ItemEntryOldPrice>
                {totalPrice.beforeUsePoint.toFixed(2)}
              </Basket.ItemEntryOldPrice>
            )}

            <Basket.ItemEntryNewPrice>
              {totalPrice.afterUsePoint.toFixed(2)} {currencySymbol}
            </Basket.ItemEntryNewPrice>
          </Basket.ItemEntryPriceRow>
        </Basket.TotalPrice> */}
      </Basket>
      {hover.active && (
        <Hover mobile={mobile}>
          <SubtitleWithCrossLine isActive>
            <SubtitleWithCrossLine.Text isActive>
              {hover.title}
            </SubtitleWithCrossLine.Text>
          </SubtitleWithCrossLine>
          {hover.image && (
            <Hover.Image
              src={`${BASE_URL}/uploaded-media/${hover.image.contentUrl}`}
            />
          )}
          <Hover.Description
            dangerouslySetInnerHTML={{ __html: hover.description }}
          />
        </Hover>
      )}
      <Basket.MyWrapper mobile={mobile}>
        <MobileBasket
          className="cart-summary-mobile"
          dietCosts={calculations?.dietCosts || []}
          multinational={multinational}
          t={t}
        />
      </Basket.MyWrapper>
    </Fragment>
  );
};

export default connect(
  ({
    app: {
      brand: { hover },
      config: { multinational },
    },
    orderForm: {
      calculations,
      orderConfig: { diets, variants },
    },
    dietForm: { forms, currentFormId, paymentMode },
  }) => ({
    diets,
    forms,
    hover,
    variants,
    calculations,
    currentFormId,
    multinational,
    paymentMode,
  })
)(NewOrderBasket);
