import React from 'react';
import styled from 'styled-components';

import NavbarItem from './NavbarItem';

const StyledNavbar = styled.ul`
  display: flex;
  margin-left: ${props => (props.isAlignedRight ? 'auto' : null)};
  align-items: center;

  @media (max-width: ${({ mediaMobileCss }) => mediaMobileCss}) {
    display: none;
  }
`;

const Navbar = ({ items, isAlignedRight, token, mediaMobileCss }) => {
  return (
    <StyledNavbar
      isAlignedRight={isAlignedRight}
      mediaMobileCss={mediaMobileCss}
    >
      {items.map((item, index) => {
        return item.checkForToken ? (
          token && <NavbarItem key={index} path={item.link} {...item} />
        ) : item.onLogout ? (
          !token && <NavbarItem key={index} path={item.link} {...item} />
        ) : (
          <NavbarItem key={index} path={item.link} {...item} />
        );
      })}
    </StyledNavbar>
  );
};

export default Navbar;
