import React from 'react';
import { connect } from 'react-redux';

const CloseIcon = ({ mainColor }) => {
  return (
    <svg width="12" height="12" viewBox="0 0 311 311">
      <path
        d="M155.575,132.868L283.757,4.686C290.006,-1.562 300.136,-1.562 306.384,4.686C312.633,10.935 312.633,21.065 306.384,27.317L178.204,155.498L306.384,283.679C312.633,289.928 312.633,300.058 306.384,306.307C303.248,309.423 299.156,311.001 295.082,311.001C290.986,311.001 286.893,309.443 283.777,306.307L155.581,178.121L27.395,306.307C24.259,309.423 20.163,311.001 16.07,311.001C11.973,311.001 7.877,309.443 4.764,306.307C-1.484,300.058 -1.484,289.928 4.764,283.679L132.95,155.493L4.764,27.317C-1.484,21.065 -1.484,10.935 4.764,4.686C11.012,-1.562 21.143,-1.562 27.395,4.686L155.575,132.868Z"
        fill={mainColor}
        stroke={mainColor}
        strokeWidth="15"
      />
    </svg>
  );
};

export default connect(({ app: { brand: { mainColor } } }) => ({ mainColor }))(
  CloseIcon
);
