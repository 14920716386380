import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Flex, Box } from '@rebass/grid';
import { Form, Field, FormSpy } from 'react-final-form';
import { compose, lifecycle } from 'recompose';

import {
  setInvoiceDataType,
  setInvoiceData,
} from 'views/NewOrder/actions/orderFormActions';
import { getUserData } from 'views/AccountSettings/actions/accountSettingsActions';
import { setNextTabDisability } from 'views/NewOrder/components/OrderFormTabs/actions/orderFormTabsActions';
import { useTranslation } from 'react-i18next';
import FormSelectBox from 'common/components/Form/FormSelectBox';
import FormTextInput from 'common/components/Form/FormTextInput';
import {
  getValidators,
  useFormValidation,
} from 'common/services/formValidation';
import FORM_FIELD_NAMES from '../../const/formFieldNames';

const getInitialValues = (invoiceDataType, userData, orderForm) => {
  if (invoiceDataType === 'Other') {
    return {
      invoiceCompanyName: '',
      invoiceVatNumber: '',
      invoiceAddressPostCode: '',
      invoiceAddressCity: '',
      invoiceAddressStreet: '',
      invoiceAddressBuildNumber: '',
      invoiceAddressPlaceNumber: '',
      invoiceComments: '',
    };
  }

  return {
    invoiceCompanyName: orderForm.invoiceCompanyName
      ? orderForm.invoiceCompanyName
      : userData.invoiceCompany,
    invoiceVatNumber: orderForm.invoiceVatNumber
      ? orderForm.invoiceVatNumber
      : userData.invoiceVatNumber,
    invoiceAddressPostCode: orderForm.invoiceAddressPostCode
      ? orderForm.invoiceAddressPostCode
      : userData.invoiceAddressPostCode,
    invoiceAddressCity: orderForm.invoiceAddressCity
      ? orderForm.invoiceAddressCity
      : userData.invoiceAddressCity,
    invoiceAddressStreet: orderForm.invoiceAddressStreet
      ? orderForm.invoiceAddressStreet
      : userData.invoiceAddressStreet,
    invoiceAddressBuildNumber: orderForm.invoiceAddressBuildNumber
      ? orderForm.invoiceAddressBuildNumber
      : userData.invoiceAddressBuildNumber,
    invoiceAddressPlaceNumber: orderForm.invoiceAddressPlaceNumber
      ? orderForm.invoiceAddressPlaceNumber
      : userData.invoiceAddressPlaceNumber,
    invoiceComments: orderForm.invoiceComments
      ? orderForm.invoiceComments
      : userData.invoiceComments,
  };
};

const SummaryInvoiceInfo = ({
  orderForm,
  setInvoiceDataType,
  setInvoiceData,
  supportedRegions,
  userData,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [initialValues, setInitialValues] = useState({});
  const validators = useFormValidation();
  const firstSupportedCountry = Object.values(supportedRegions)?.[0];
  const regexpTest = firstSupportedCountry?.postCodeRegexp
    ? new RegExp(`${firstSupportedCountry.postCodeRegexp}$`)
    : null;

  const INVOICE_OPTIONS = useMemo(
    () => [
      {
        value: 'DefaultCompany',
        label: t(
          '$*orderSummaryFields.invoiceOptionsDefaultCompany',
          '$$Twoja firma'
        ),
      },
      {
        value: 'Other',
        label: t('$*orderSummaryFields.invoiceOptionsOtherCompany', '$$Inna'),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [language]
  );

  useEffect(() => {
    const currentInitialValues = getInitialValues(
      orderForm.invoiceDataType,
      userData,
      orderForm
    );

    setInitialValues(currentInitialValues);
  }, [orderForm.invoiceDataType]);

  return (
    <Flex
      alignItems="flex-start"
      justifyContent={['space-beetwen', 'space-between', 'flex-end']}
      flexDirection={['column', 'column', 'row']}
    >
      <Box width={[1, 1, 1 / 2]}>
        <Flex alignItems="center" width={1}>
          <Box width={[1, 1, 1 / 2]}>
            <Field
              text={orderForm.invoiceDataType}
              value={orderForm.invoiceDataType}
              input={{
                onChange: value => {
                  setInvoiceDataType(value, userData);
                  setInvoiceData(orderForm, true);
                },
              }}
              name={FORM_FIELD_NAMES.INVOICE_TYPE}
              type="select"
              component={FormSelectBox}
              label={t(
                '$*orderSummaryFields.invoiceType',
                '$$Dane do faktury:'
              )}
              placeholder={t(
                '$*orderSummaryFields.invoiceTypePlaceholder',
                '$$Twoja firma'
              )}
              options={INVOICE_OPTIONS}
            />
          </Box>
        </Flex>
      </Box>
      <Box width={[1, 1, 1 / 2]}>
        {orderForm.invoiceDataType && (
          <Form
            onSubmit={() => {}}
            initialValues={initialValues}
            render={form => {
              return (
                <Fragment>
                  <FormSpy
                    subscription={{
                      valid: true,
                      dirty: true,
                      values: true,
                      initialValues: true,
                    }}
                    onChange={({ values, valid }) => {
                      setInvoiceData(values, valid);
                    }}
                  />
                  <Flex
                    justifyContent="space-between"
                    mt={['25px', '25px', '0']}
                    flexDirection={['column', 'column', 'row']}
                  >
                    <Box width={[1, 1, 1 / 2]}>
                      <Field
                        name={FORM_FIELD_NAMES.INVOICE_COMPANY_NAME}
                        type="text"
                        component={FormTextInput}
                        isBig={true}
                        label={t(
                          '$*orderSummaryFields.invoiceCompanyName',
                          '$$Nazwa firmy: '
                        )}
                        isRequired={true}
                        validate={validators.required}
                        width="100%"
                      />
                    </Box>
                    <Box width={[1, 1, 1 / 2]} ml={['0', '0', '10px']}>
                      <Field
                        name={FORM_FIELD_NAMES.INVOICE_VAT_NUMBER}
                        type="text"
                        component={FormTextInput}
                        isBig={true}
                        label={t(
                          '$*orderSummaryFields.invoiceVatNumber',
                          '$$NIP: '
                        )}
                        isRequired={true}
                        validate={getValidators([
                          validators.required,
                          validators.nipValidator,
                        ])}
                        parse={validators.parseNumber}
                        maxLength={10}
                        width="100%"
                      />
                    </Box>
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    flexDirection={['column', 'column', 'row']}
                  >
                    <Box width={[1, 1, 1 / 2]}>
                      <Field
                        name={FORM_FIELD_NAMES.INVOICE_ADDRESS_CITY}
                        type="text"
                        component={FormTextInput}
                        isBig={true}
                        label={t(
                          '$*orderSummaryFields.invoiceAddressCity',
                          '$$Miasto: '
                        )}
                        isRequired={true}
                        validate={validators.required}
                        width="100%"
                      />
                    </Box>
                    <Box width={[1, 1, 1 / 2]} ml={['0', '0', '10px']}>
                      <Field
                        name={FORM_FIELD_NAMES.INVOICE_ADDRESS_STREET}
                        type="text"
                        component={FormTextInput}
                        isBig={true}
                        label={t(
                          '$*orderSummaryFields.invoiceAddressStreet',
                          '$$Ulica: '
                        )}
                        isRequired={true}
                        validate={validators.required}
                        width="100%"
                      />
                    </Box>
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    flexDirection={['column', 'column', 'row']}
                  >
                    <Box width={[1, 1, 1 / 3]}>
                      <Field
                        name={FORM_FIELD_NAMES.INVOICE_ADDRESS_BUILD_NUMBER}
                        type="text"
                        component={FormTextInput}
                        isBig={true}
                        label={t(
                          '$*orderSummaryFields.invoiceAddressBuildNumber',
                          '$$Nr budynku: '
                        )}
                        isRequired={true}
                        validate={validators.required}
                        width="100%"
                      />
                    </Box>
                    <Box width={[1, 1, 1 / 3]} ml={['0', '0', '10px']}>
                      <Field
                        name={FORM_FIELD_NAMES.INVOICE_ADDRESS_PLACE_NUMBER}
                        type="text"
                        component={FormTextInput}
                        isBig={true}
                        label={t(
                          '$*orderSummaryFields.invoiceAddressPlaceNumber',
                          '$$Nr lokalu: '
                        )}
                        isRequired={false}
                        width="100%"
                      />
                    </Box>
                    <Box width={[1, 1, 1 / 3]} ml={['0', '0', '10px']}>
                      <Field
                        name={FORM_FIELD_NAMES.INVOICE_ADDRESS_POST_CODE}
                        type="text"
                        component={FormTextInput}
                        isBig={true}
                        label={t(
                          '$*orderSummaryFields.invoiceAddressPostCode',
                          '$$Kod pocztowy: '
                        )}
                        isRequired={true}
                        validate={getValidators([
                          validators.required,
                          value => {
                            if (regexpTest && !regexpTest.test(value)) {
                              return t(
                                '$*error.invalidPostcode',
                                '$$Kod pocztowy jest nieprawidłowy'
                              );
                            } else {
                              return undefined;
                            }
                          },
                        ])}
                        parse={
                          firstSupportedCountry?.postCodeRegexp
                            ? validators.parsePostalCode
                            : v => v
                        }
                        width="100%"
                      />
                    </Box>
                  </Flex>
                </Fragment>
              );
            }}
          />
        )}
      </Box>
    </Flex>
  );
};

export default compose(
  connect(
    ({
      auth: { token },
      orderForm,
      accountSettings: { userData },
      app: {
        config: {
          multinational: { supportedRegions },
        },
      },
    }) => ({
      token,
      orderForm,
      userData,
      supportedRegions,
    }),
    {
      setInvoiceDataType,
      setInvoiceData,
      getUserData,
      setNextTabDisability,
    }
  ),
  lifecycle({
    componentDidMount() {
      const {
        token,
        setNextTabDisability,
        getUserData,
        orderForm: {
          isInvoiceIncluded,
          invoiceDataType,
          isInvoiceDataValid,
          paymentMethodId,
          paymentMetadataIsValid,
        },
      } = this.props;

      if (token) {
        getUserData();
      }

      const validate = [
        isInvoiceIncluded && !isInvoiceDataValid,
        paymentMethodId === 'PAYPO' ? !paymentMetadataIsValid : false,
      ];

      setNextTabDisability(validate.includes(true));
    },
    componentDidUpdate() {
      const {
        setNextTabDisability,
        orderForm: {
          isInvoiceIncluded,
          isInvoiceDataValid,
          paymentMethodId,
          paymentMetadataIsValid,
        },
      } = this.props;

      const validate = [
        isInvoiceIncluded && !isInvoiceDataValid,
        paymentMethodId === 'PAYPO' ? !paymentMetadataIsValid : false,
      ];

      setNextTabDisability(validate.includes(true));
    },
  })
)(SummaryInvoiceInfo);
