import React, { Fragment } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Flex, Box } from '@rebass/grid';

import URLS from 'routes/routeUrls';

import { ButtonPrimary } from 'styledComponents/elements/Button';
import { Heading } from 'styledComponents/elements/Typography';
import { useTranslation } from 'react-i18next';

const PasswordRemindBox = styled.div`
  max-width: 500px;
  margin: 0 auto;
  border-radius: 6px;
  border: 1px solid ${props => props.theme.baseFontColor};
  padding: 20px;
`;

const ResetPasswordEmailSent = ({ history, ...props }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <PasswordRemindBox>
        <Flex flexDirection="column" alignItems="center">
          <Heading textAlign="center">
            {t(
              '$**resetPasswordEmailSent',
              'Dalsze kroki zostały przesłane na Twój adres email!'
            )}
          </Heading>
          <Box width={[1, 1, 'auto']} mt={'40px'}>
            <ButtonPrimary
              onClick={() => history.push(URLS.AUTH_LOGIN)}
              uppercased
              sizeMiddle
              weightBold
              fullWidthOnMobile
            >
              {t('$**common.ok', 'OK')}
            </ButtonPrimary>
          </Box>
        </Flex>
      </PasswordRemindBox>
    </Fragment>
  );
};

export default withRouter(ResetPasswordEmailSent);
