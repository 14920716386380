import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import equal from 'deep-equal';

import { dateObjectToYearMonthDay } from 'common/services/dateHelpers';

import { getLimitDates } from 'views/MenuPlanning/services/calendarService';

import {
  getUserDietCalendar,
  getUserDietsList,
  setSelectedDiet,
} from '../actions/userDietActions';

const withUserDiet = WrappedComponent =>
  class extends Component {
    // On calendar load fetch user diets for dropdown list and set first one as selected, then fetch calendar for selected diet (current month)
    componentDidMount() {
      const { getUserDietsList } = this.props;

      getUserDietsList();
    }

    componentDidUpdate(prevProps) {
      const { visibleCalendarDays, selectedDiet } = this.props;

      // If user change diet in dropdown, all days will be refetched
      if (selectedDiet && !equal(selectedDiet, prevProps.selectedDiet)) {
        this.fetchUserDietCalendar();
        return;
      }

      // If user change month in big calendar, all days will be refetched
      if (!equal(visibleCalendarDays, prevProps.visibleCalendarDays)) {
        this.fetchUserDietCalendar();
      }
    }

    fetchUserDietCalendar = () => {
      const {
        getUserDietCalendar,
        visibleCalendarDays,
        selectedDiet,
      } = this.props;

      const [monthStart, monthEnd] = getLimitDates(visibleCalendarDays);

      getUserDietCalendar({
        dietId: selectedDiet,
        dateFrom: dateObjectToYearMonthDay(monthStart),
        dateTo: dateObjectToYearMonthDay(monthEnd),
      });
    };

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

export default compose(
  connect(
    ({
      userDiet: {
        list: userDiets,
        selectedDiet,
        selectedDietObject,
        visibleCalendarDays,
      },
    }) => ({
      selectedDiet,
      selectedDietObject,
      visibleCalendarDays,
      userDiets,
    }),
    { getUserDietCalendar, getUserDietsList, setSelectedDiet }
  ),
  withUserDiet
);
