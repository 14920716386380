import React, { useState } from 'react';
import styled from 'styled-components';
import { Flex } from '@rebass/grid';
import { Paragraph } from 'styledComponents/elements/Typography';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from 'common/components/Modal';
import FormCheckbox from 'common/components/Form/FormCheckbox';
import ConfirmButtonWrapper from 'common/components/Modal/ConfirmButtonWrapper';

const PremiumPrice = styled.span`
  opacity: ${props => (props.premiumPrice ? 1 : 0)};
  font-weight: ${props => props.theme.fontWeight900};
  transition: 0.5s all;
`;

const PremiumOldPrice = styled.span`
  padding: 0 5px;
  color: ${props => props.theme.colorRed};
  text-decoration: line-through;
`;

const PremiumActivationModal = ({
  id,
  isOpened,
  toggleModal,
  needPayment,
  premiumPrice,
  additionalPrices,
  isSubscriptionIntent,
  handlePremiumStatusChange,
}) => {
  const { t } = useTranslation();
  const [usePointsToPay, setUsePointsToPay] = useState(false);
  const {
    moneyBox,
    currencySymbol,
    pointsToCashRate,
    allowClientPayWithMoneboxEverywhere,
  } = useSelector(
    ({
      accountSettings: { userData },
      app: {
        config: {
          modules,
          pointsToCashRate,
          multinational: { currencySymbol },
        },
      },
    }) => ({
      moneyBox: userData.moneyBox,
      currencySymbol,
      pointsToCashRate,
      allowClientPayWithMoneboxEverywhere:
        modules.ConfigClientPanel.allowClientPayWithMoneboxEverywhere,
    })
  );

  const pointsInCash = moneyBox * pointsToCashRate;
  const restAdditionalPrice = premiumPrice - pointsInCash;

  const handlePremium = event => {
    event.preventDefault();
    const additionalParams = { usePointsToPay };
    handlePremiumStatusChange(additionalParams);
    toggleModal();
  };

  return (
    <Modal
      id={id}
      title={t(
        '$*premiumActivationModal.title',
        'Dodaj opcję wyboru posiłków do dnia diety'
      )}
      isOpened={isOpened}
      toggleModal={() => toggleModal()}
      withBackButton
      fullscreen
      widthDesktop="480px"
      padding="20px 30px 32px"
      paddingMobile="0"
    >
      <Paragraph textAlign="center">
        {t(
          '$*premiumActivationModal.mainLabel',
          'WYBÓR MENU pozwoli Ci wybierać ulubione posiłki spośród aż kilkunastu propozycji każdego dnia! Pamiętaj, że dokonując zmian, wartość odżywcza oraz rozkład makroskładników może różnić się od Twojego domyślnego wyboru'
        )}
      </Paragraph>
      <Flex justifyContent="center" alignItems="center" flexDirection="column">
        {usePointsToPay ? (
          <PremiumPrice premiumPrice={premiumPrice}>
            {t('$*basket.labelPricePerDay', '$$Cena za dzień')}:
            <PremiumOldPrice>
              {premiumPrice.toFixed(2)} {currencySymbol}
            </PremiumOldPrice>
            {restAdditionalPrice > 0 ? restAdditionalPrice.toFixed(2) : 0}{' '}
            {currencySymbol}
          </PremiumPrice>
        ) : (
          <PremiumPrice premiumPrice={premiumPrice}>
            {t('$*basket.labelPricePerDay', '$$Cena za dzień')}: {premiumPrice}{' '}
            {currencySymbol}
          </PremiumPrice>
        )}
      </Flex>
      {allowClientPayWithMoneboxEverywhere &&
        moneyBox > 0 &&
        !isSubscriptionIntent && (
          <Paragraph>
            <FormCheckbox
              componentStyle="switch"
              input={{
                checked: usePointsToPay,
                onChange: () => {
                  setUsePointsToPay(!usePointsToPay);
                },
              }}
            >
              {t('$*common.usePointsToPayOrder', '$$Użyj punktów ze skarbonki')}
            </FormCheckbox>
          </Paragraph>
        )}
      <ConfirmButtonWrapper
        buttonText={
          (usePointsToPay && restAdditionalPrice <= 0) ||
          (additionalPrices && additionalPrices.CHANGE_MENU <= 0) ||
          premiumPrice <= 0
            ? t('$*common.add', 'Dodaj')
            : t('$*common.toPayment', 'Do płatności')
        }
        marginTop={28}
        disabled={!premiumPrice && needPayment}
        onClick={handlePremium}
      />
    </Modal>
  );
};

export default PremiumActivationModal;
