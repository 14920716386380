import styled from 'styled-components';

import { media } from 'styledComponents/helpers';

export const Banner = styled.div`
  position: relative;
`;

Banner.Image = styled.img`
  max-width: 100%;
  width: 100%;
  object-fit: contain;
`;

Banner.TextContainer = styled.div`
  position: absolute;
  top: 0;
  //max-width: 60%;

  ${media.tabletDown`
    max-width: 100%;
  `};
`;

export const TitleWrapper = styled.div`
  text-transform: uppercase;
  font-size: 5vw;
  line-height: 5.5vw;
  font-weight: 900;
  color: black;

  ${media.tabletDown`
    font-size: 10vw;
    line-height: 10.5vw;
  `};
`;

export const DescriptionWrapper = styled.div`
  margin-top: 1vw;
  font-size: 2vw;
  line-height: 2.5vw;

  ${media.tabletDown`
    margin-top: 3vw;
    font-size: 7vw;
    line-height: 7.5vw;
    max-width: 100%;
  `};

  i {
    font-style: italic;
  }
  b {
    font-weight: bold;
  }
  ol,
  ul {
    list-style: initial;
  }
  li {
    list-style-position: inside;
  }
  ol {
    list-style: decimal;
  }
`;
