import styled, { css } from 'styled-components';

import { fromPxToRem, media } from 'styledComponents/helpers';

export const Paragraph = styled.p`
  padding: ${props =>
    (props.noPadding && '0') || props.customPadding || '25px 0'};
  font-size: ${props => (props.isSmall ? fromPxToRem('14px') : 'inherit')};
  ${props =>
    props.size &&
    css`
      font-size: ${props.size};
    `};
  font-weight: ${props =>
    props.isBold ? props.theme.fontWeight900 : 'inherit'};
  ${props =>
    props.thin &&
    css`
      font-weight: ${props.theme.fontWeight300};
    `};
  text-align: ${props => props.textAlign || 'inherit'};
  text-transform: ${props => props.textTransform || 'inherit'};
  line-height: 1.38rem;
  margin-bottom: ${props => props.marginBottom};
  border-bottom: ${props =>
    props.underline ? `1px solid ${props.theme.baseFontColor}` : 'none'};
  color: ${props => props.colorPremiumGold && props.theme.colorPremiumGold}
    ${media.tabletDown`
    text-align: ${props => props.textAlign || 'center'};
    margin-top: ${props => (props.noMarginMobile ? '0' : '30px')};
    padding: ${props => (props.customPadding ? props.customPadding : 0)};
  `};
`;

export const Legend = styled.legend`
  ${media.tabletDown`
    padding: 5px 0;
  `};

  padding: 25px 0;
  font-size: ${fromPxToRem('13px')};
`;

export const Heading = styled.h1`
  //padding: ${props => props.customPadding || '25px 0'};
  text-align: ${props => props.textAlign || 'left'};
  font-weight: ${props => props.theme.fontWeight300};
  font-size: ${fromPxToRem('32px')};
  color: ${props => props.baseFontColor && props.theme.baseFontColor};

  ${props =>
    props.noPadding &&
    css`
      padding: 0;
    `};

  ${props =>
    props.noPaddingDesktop &&
    css`
      padding: 0;

      ${media.tabletDown`
        padding: 25px 0;
      `};
    `};

  ${media.tabletDown`
    padding: ${props => props.paddingMobile || '0 0 16px'};
    font-size: ${props =>
      props.fontSizeMobile
        ? fromPxToRem(props.fontSizeMobile)
        : fromPxToRem('24px')};
    text-align: ${props => props.textAlignMobile};
  `};
`;

export const Subtitle = styled.h4`
  padding: ${props => (props.noPadding ? '0' : '25px 0')};
  text-align: ${props => props.textAlign || 'left'};
  font-weight: ${props => props.theme.fontWeight900};
  font-size: ${fromPxToRem('14px')};
  text-transform: uppercase;
`;

export const SubtitleWithCrossLine = styled.h5`
  text-align: center;
  position: relative;
  margin-bottom: 5px;

  &:before {
    position: absolute;
    top: 50%;
    left: 0;
    content: '';
    height: 1px;
    width: 100%;
    background-color: ${props =>
      props.isActive ? props.theme.baseFontColor : props.theme.colorIceBlue2};
  }
`;

SubtitleWithCrossLine.Text = styled.span`
  position: relative;
  padding: 0 12px;
  background-color: ${props => props.theme.colorWhite};
  font-size: ${fromPxToRem('13px')};
  font-weight: ${props =>
    props.isActive ? props.theme.fontWeight900 : props.theme.fontWeight500};
  text-transform: uppercase;
`;
