import React, { useState, useEffect } from 'react';
import produce from 'immer';

import { Card, Check, InputNumber } from './AddonTile.styled';
import { BASE_URL, URLS } from 'configuration';

const Tile = ({
  '@id': iriId,
  clientCost,
  defaultUsage,
  image,
  name,
  shortDescription,
  setAddons,
  initState,
  usageItems = [],
  categoryName = null,
  position,
  currencySymbol,
}) => {
  const hasImage = image !== null;
  const imageUrl = hasImage
    ? `${BASE_URL}${URLS.UPLOADED_MEDIA}${image?.contentUrl}`
    : '';
  const [isChecked, setIsChecked] = useState(!!initState?.addon);
  const [count, setCount] = useState(initState?.quantity || 1);
  const [usage, setUsage] = useState(initState?.type || defaultUsage);

  const toggleCheck = () => setIsChecked(!isChecked);

  useEffect(() => {
    if (!isChecked) {
      setCount(1);
    }
  }, [isChecked]);

  useEffect(() => {
    if (count <= 0) {
      setIsChecked(false);
    }
  }, [count]);

  useEffect(() => {
    setAddons(
      produce(draft => {
        const checkCurrent = ({ addon }) => addon === iriId;
        const currentAddon = draft.find(checkCurrent);
        const currentAddonIndex = draft.findIndex(checkCurrent);

        if (!isChecked) {
          currentAddonIndex !== -1 && draft.splice(currentAddonIndex, 1);
        } else {
          if (currentAddon) {
            currentAddon.quantity = count;
            currentAddon.type = usage;
          } else {
            draft.push({
              addon: iriId,
              name,
              quantity: count,
              type: usage,
              price: clientCost,
              position,
              ...(categoryName ? { category: categoryName } : {}),
            });
          }
        }

        return draft;
      })
    );
  }, [count, usage, isChecked]);

  return (
    <Card isChecked={isChecked}>
      <Card.Wrapper onClick={toggleCheck}>
        <Card.Content>
          <Card.ContentWrapper noImage={image === null}>
            <Card.Image src={imageUrl} />
            <Card.Title>{name}</Card.Title>
            <Card.Description
              dangerouslySetInnerHTML={{ __html: shortDescription }}
            />
          </Card.ContentWrapper>
        </Card.Content>
        <Card.Footer>
          <div>{`${clientCost.toFixed(2)} ${currencySymbol}`}</div>
          <div>
            {!isChecked ? (
              <Check />
            ) : (
              <InputNumber>
                <InputNumber.Button
                  type="button"
                  onClick={e => {
                    e.stopPropagation();
                    setCount(count - 1);
                  }}
                >
                  -
                </InputNumber.Button>
                <InputNumber.Text>{count}</InputNumber.Text>
                <InputNumber.Button
                  type="button"
                  onClick={e => {
                    e.stopPropagation();
                    setCount(count + 1);
                  }}
                >
                  +
                </InputNumber.Button>
              </InputNumber>
            )}
          </div>
        </Card.Footer>
      </Card.Wrapper>
      {isChecked && usageItems.length > 0 && (
        <Card.Switch>
          {usageItems.map(({ label, value }, index) => (
            <Card.SwitchItem
              key={index}
              isActive={usage === value}
              onClick={e => {
                e.stopPropagation();
                setUsage(value);
              }}
            >
              {label}
            </Card.SwitchItem>
          ))}
        </Card.Switch>
      )}
    </Card>
  );
};

export default Tile;
