import React, { Fragment } from 'react';
import { Flex } from '@rebass/grid';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { DisplayOnMobile, DisplayOnDesktop } from 'styledComponents/helpers';

import Basket from 'common/components/Basket/Basket';
import MenuPlanningHeadingSection from '../containers/MenuPlanningHeadingSection';
import MenuPlanningContentForm from '../containers/MenuPlanningContentForm';

import UserDietHeadingSection from 'views/UserDiet/containers/UserDietHeadingSection';

import withMenuPlanning from '../components/withMenuPlanning';

const MenuPlanning = React.memo(
  ({
    match: {
      params: { day },
    },
    history,
  }) => {
    const modulesAddons = useSelector(
      ({
        app: {
          config: { modules },
        },
      }) => modules.Addons
    );

    return (
      <Fragment>
        <DisplayOnDesktop>
          <Fragment>
            <MenuPlanningHeadingSection day={day} />
            <MenuPlanningContentForm day={day} />
          </Fragment>
        </DisplayOnDesktop>
        <DisplayOnMobile>
          <Fragment>
            <Flex flexDirection="column">
              <UserDietHeadingSection />
              <MenuPlanningHeadingSection day={day} />
            </Flex>
            <MenuPlanningContentForm day={day} isMobile={true} />
          </Fragment>
        </DisplayOnMobile>

        {modulesAddons.extendDiet && <Basket />}
      </Fragment>
    );
  },
  (e, b) => {
    return e.selectedDay === b.selectedDay;
  }
);

export default compose(withRouter, withMenuPlanning)(MenuPlanning);
