import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  BaseWrapper,
  BaseDayNumber,
  BaseCaption,
  BaseIconWrapper,
} from 'styledComponents/elements/BigCalendarDayCell';
import { DisplayOnDesktop, media } from 'styledComponents/helpers';
import { DAY_STATUSES } from '../BigCalendar/const/dayStatuses';
import DeliveredIcon from '../BigCalendar/icons/DeliveredIcon';

const Wrapper = styled(BaseWrapper)`
  background-color: ${props => props.theme.baseFontColor};
`;

const Caption = styled(BaseCaption)`
  color: ${props => props.theme.colorWhite};
`;

const StyledDayNumber = styled(BaseDayNumber)`
  color: ${props => props.theme.colorWhite};
  font-weight: ${props => props.theme.fontWeight900};
`;

const IconWrapper = styled(BaseIconWrapper)`
  top: 15px;
  right: -8px;
  ${media.tabletDown`
    width: 20px;
    height: 20px;
  `}
`;

const SubscriptionDayEnabled = ({ children, legacyStatus }) => {
  const { t } = useTranslation();

  const title = (function () {
    switch (legacyStatus) {
      case DAY_STATUSES.NOT_DELIVERED_WITH_CONFIGURABLE_ALL:
      case DAY_STATUSES.NOT_DELIVERED_WITH_CONFIGURABLE_WITHOUT_MENU:
      case DAY_STATUSES.SUBSCRIPTION_SUSPENDED:
        return '$*userDiet.dietHoldOn.ordered';
      case DAY_STATUSES.NOT_DELIVERED_BLOCKED:
        return '$*userDiet.dietHoldOn.inProgress';
      default:
        return '$*calendarDayCell.delivered';
    }
  })();
  return (
    <Wrapper>
      <IconWrapper>
        <DeliveredIcon />
      </IconWrapper>
      <StyledDayNumber>{children}</StyledDayNumber>

      <DisplayOnDesktop>
        <Caption className="DayPicker-Caption">{t(title)}</Caption>
      </DisplayOnDesktop>
    </Wrapper>
  );
};

export default SubscriptionDayEnabled;
