import React from 'react';
import { Box, Flex } from '@rebass/grid';
import styled from 'styled-components';
import { media } from 'styledComponents/helpers';

import { ButtonPrimary } from 'styledComponents/elements/Button';

const StyledFlex = styled(Flex)`
  position: absolute;
  z-index: 1;
  top: 2rem;
  left: calc(95%);
  transform: translate(calc(-100%), 10px);
  ${media.desktopDown`
    left: calc(100% - 16px);
  `}
  ${media.tabletDown`
    top: 0;
    transform: translate(calc(-85%), -2px) scale(0.7);
  `}
  ${media.mobileDown`
     left: 50%;
     transform: translate(calc(-48%), 2.5rem) scale(0.7);
     top: unset;
     bottom: 80px;
  `}
`;

const ConfirmButtonWrapper = ({
  disabled,
  buttonText,
  marginTop,
  defaultSubscriptionDietSettings,
  marginBottom,
  marginLeft,
  marginRight,
  padding,
  onClick,
  isLoading,
  type = 'submit',
}) => {
  const Wrapper = defaultSubscriptionDietSettings ? StyledFlex : Flex;
  return (
    <Wrapper justifyContent="center">
      <Box
        marginTop={marginTop}
        marginBottom={marginBottom}
        marginLeft={marginLeft}
        marginRight={marginRight}
        width={[1, 1, 'inherit']}
      >
        <ButtonPrimary
          type={type}
          disabled={disabled}
          padding={padding}
          uppercased
          sizeMiddle
          weightBold
          withSpinner
          isLoading={isLoading}
          onClick={onClick}
          width={defaultSubscriptionDietSettings && '250px'}
          mobileWidth="250px"
        >
          {buttonText}
        </ButtonPrimary>
      </Box>
    </Wrapper>
  );
};

export default ConfirmButtonWrapper;
