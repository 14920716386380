import React from 'react';
import { mainTheme } from '../../../themes/main';
import { BASE_URL, URLS } from '../../../configuration';
import StyledLogo from '../../../styledComponents/elements/Logo';
import { connect } from 'react-redux';

const Picture = ({ logo, isSmall, onLoadLogo }) => {
  return (
    <picture>
      <source
        media={`(max-width: ${mainTheme.deviceBreakpoints.mobile}px)`}
        srcSet={`${BASE_URL}${URLS.UPLOADED_MEDIA}${logo?.mobile?.contentUrl}`}
      />
      <source
        media={`(min-width: ${mainTheme.deviceBreakpoints.mobile + 1}px)`}
        srcSet={`${BASE_URL}${URLS.UPLOADED_MEDIA}${logo?.desktop?.contentUrl}`}
      />
      <StyledLogo
        src={`${BASE_URL}${URLS.UPLOADED_MEDIA}${logo?.desktop?.contentUrl}`}
        alt="Logo"
        small={isSmall}
        onLoad={onLoadLogo}
      />
    </picture>
  );
};

export default connect(({ app: { config: { logo } } }) => ({
  logo,
}))(Picture);
