import FormCheckbox from 'common/components/Form/FormCheckbox';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const INPUT_IDS = {
  MARKETING_DATA_PROCESSING_AGREEMENT: 'marketingTerms',
  TERMS_OF_SERVICE_AGREEMENT: 'serviceTerms',
};

export const INITIAL_VALUES = {
  [INPUT_IDS.MARKETING_DATA_PROCESSING_AGREEMENT]: false,
  [INPUT_IDS.TERMS_OF_SERVICE_AGREEMENT]: false,
};

export const useAgreementsFormInputs = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return useMemo(
    () => [
      {
        id: INPUT_IDS.TERMS_OF_SERVICE_AGREEMENT,
        name: INPUT_IDS.TERMS_OF_SERVICE_AGREEMENT,
        type: 'checkbox',
        component: FormCheckbox,
        label: 'termsContent',
        linkLabel: t(
          '$*accountSettings.agreements.regulationsConsent',
          ' $$(Regulamin serwisu)*'
        ),
        link: 'termsLink',
        width: '100%',
      },
      {
        id: INPUT_IDS.MARKETING_DATA_PROCESSING_AGREEMENT,
        name: INPUT_IDS.MARKETING_DATA_PROCESSING_AGREEMENT,
        type: 'checkbox',
        component: FormCheckbox,
        label: 'marketingTermsContent',
        linkLabel: t(
          '$*accountSettings.agreements.marketingProcessingConsents',
          ' $$(Szczegóły)'
        ),
        link: 'marketingTermsLink',
        width: '100%',
      },
    ],
    [language]
  );
};
