export const translateErrorMessages = t => ({
  REQUIRED: t('$*error.required', 'To pole jest wymagane'),
  INVALID_EMAIL: t(
    '$*error.invalidEmail',
    'Podany adres e-mail jest nieprawidłowy'
  ),
  SAME_PASSWORD: t('$*error.samePassword', 'Podane hasła muszą być takie same'),
  PASSWORD_TOO_SHORT: t(
    '$*error.passwordToShort',
    'Podane hasło jest za krótkie (min. 8 znaków)'
  ),
  MARKETING_AGREEMENTS_REQUIRED: t(
    '$*error.marketingAgreementsRequired',
    'Musisz zgodzić się na otrzymywanie informacji marketingowych'
  ),
  INVALID_CREDENTIALS: t(
    '$*error.invalidCredentials',
    'Niepoprawne dane logowania'
  ),
  SERVER_ERROR_OCCURRED: t(
    '$*error.serverErrorOccured',
    'Wystąpił błąd serwera, prosimy spróbować ponownie później'
  ),
  TERMS_OF_SERVICE_AGREEMENT: t(
    '$*error.termsOfServiceRequired',
    'Musisz zaakceptować warunki korzystania z serwisu'
  ),
  BAD_REQUEST: t('$*error.badRequest', 'Podano niepoprawne dane'),
  ACCOUNT_EXISTS: t('$*error.accountExists', 'Podany e-mail jest zajęty. '),
  EXACT_VALUE: value => t('$*error.exactValue', { value }),

  NIP_LENGTH: t('$*error.nipLength', 'NIP powinien składać się z 10 cyfr'),

  LOCATION_NOT_SUPPORTED: t(
    '$*error.locationNotSupported',
    'Ta lokalizacja nie jest obsługiwana'
  ),
  WRONG_POSTAL_FORMAT: t(
    '$*error.wrongPostalFormat',
    'Nieprawidłowy format kodu pocztowego'
  ),
  BRUTE_FORCE: (bruteForceMinute = 0) => {
    const plural =
      {
        1: 'minute_singular_accusative',
        5: 'minute_plural_genitive',
      }[bruteForceMinute] || 'minute_singular_genitive';

    return t('$*error.bruteForce.tooManyAttempts', {
      defaultValue:
        '$$Zbyt wiele nieudanych prób logowania, spróbuj ponownie za {{time}}.',
      replace: { time: `${bruteForceMinute} ${t(plural)}` },
    });
  },
  INVALID_PHONE_IN_REGION: t(
    '$*error.wrongPhoneInRegion',
    'Numer nieprawidłowy w wybranym regionie'
  ),
});
