import { translateErrorMessages } from 'common/shared/errorMessages';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';

// Use this if there is more than one validator needed
//
// validators - array of validator functions, i.e. [required, email]
// value - value to check passed automatically by Final Form
export const getValidators = validators => (value, values, formState) =>
  validators.reduce(
    (error, validator) => error || validator(value, values, formState),
    undefined
  );
const required = ERROR_MESSAGES => value => {
  return value || value === false ? undefined : ERROR_MESSAGES.REQUIRED;
};

export const useFormValidation = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return useMemo(() => {
    const ERROR_MESSAGES = translateErrorMessages(t);
    let rEmail =
      // eslint-disable-next-line
      /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

    return {
      exactValue: min => value => {
        return value.replace(/\s/g, '').length === min
          ? undefined
          : ERROR_MESSAGES.EXACT_VALUE(min);
      },

      required: required(ERROR_MESSAGES),

      email: value =>
        value && !rEmail.test(value) ? ERROR_MESSAGES.INVALID_EMAIL : undefined,

      requiredLocalizedPhone: value => {
        return required(ERROR_MESSAGES)(value.number);
      },

      smartPhoneInRegionValidator: value => {
        const parsedValue = parsePhoneNumber(value.number);
        const countryEquals =
          parsedValue?.country && parsedValue?.country === value.countryCode;

        return countryEquals && isValidPhoneNumber(value.number)
          ? undefined
          : ERROR_MESSAGES.INVALID_PHONE_IN_REGION;
      },

      equalPasswords: (value, fieldValueToMatch) =>
        value === fieldValueToMatch ? undefined : ERROR_MESSAGES.SAME_PASSWORD,

      parsePhoneNumber: value => {
        if (!value) return value;

        const onlyNums = value.replace(/[^\d]/g, '');

        if (onlyNums.length <= 3) {
          return `${onlyNums.slice(0, 3)}`;
        }

        if (onlyNums.length <= 6) {
          return `${onlyNums.slice(0, 3)} ${onlyNums.slice(3, 6)}`;
        }

        return `${onlyNums.slice(0, 3)} ${onlyNums.slice(
          3,
          6
        )} ${onlyNums.slice(6, 9)}`;
      },

      parsePostalCode: value => {
        if (!value) return value;
        return value.replace(/[^\d-]/g, '');
      },

      parseNumber: value => {
        const onlyNums = value.replace(/[^\d]/g, '');

        return onlyNums;
      },

      parseDietLength: value => {},
      nipValidator: value => {
        if (!value || value.length < 10) {
          return ERROR_MESSAGES.NIP_LENGTH;
        } else {
          return undefined;
        }
      },
    };
  }, [language]);
};
