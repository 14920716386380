import styled from 'styled-components';
import { media } from 'styledComponents/helpers';

export const MenuBanner = styled.div`
  position: relative;
  margin: -30px -15px;

  @media (min-width: 1080px) {
    margin: -30px calc(-1 * (100vw - 1110px) / 2 - 30px) 0;
  }
`;

MenuBanner.ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

MenuBanner.Image = styled.img`
  display: block;
  width: 100%;
`;

MenuBanner.TextWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 25px 22px 30px;

  @media (min-width: 1080px) {
    padding: 25px 40px 30px;
  }
`;

MenuBanner.Title = styled.div`
  color: black;
  font-size: 3vw;
  font-weight: 900;
  line-height: normal;
  max-width: 65%;
  text-transform: uppercase;
`;

MenuBanner.Description = styled.div`
  font-size: 2vw;
  line-height: 2.5vw;
  margin-top: 1vw;
`;

export const SelectsWrapper = styled.div`
  max-width: 500px;
  margin: 0 auto;
`;

export const PageDescriptionWrapper = styled.div`
  max-width: 720px;
  margin: 0 auto;
`;

export const Container = styled.div`
  margin-left: 0;
  margin-right: 0;

  @media (min-width: 1400px) {
    margin-left: -120px;
    margin-right: -120px;
  }
`;

export const MealWrapper = styled.div`
  font-size: 12px;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px 10px;
  margin: 0;
  ${media.mobileDown`
    margin: 20px auto;
  `};
  position: relative;
  max-width: 250px;
  min-height: 175px;

  h3 {
    text-align: left;
    font-size: 14px;
    line-height: 1.2;
    word-wrap: break-word;
    word-break: break-word;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
`;

export const WeekDayWrapper = styled.div`
  ${props =>
    props.active &&
    `
     font-weight: bold;
     background: ${props.theme.baseFontColor};
     color: white;
     border-radius: 8px;
  `};

  padding: 1px;
  outline: 0;
`;

export const StandaloneWrapper = styled.div`
  padding: 0 15px;
  width: 90vw;
  outline: 0;
`;

export const OptionsSliderWrapper = styled.div`
  display: none;
  position: relative;
  padding: 0 5px;
  width: 100%;
  overflow-x: hidden;
  ${media.tabletDown`
    display: flex;
    align-items: center;
  `}

  > i {
    margin-top: -5px;
  }
`;

export const OptionsGroup = styled.div`
  display: block;
  ${media.tabletDown`
    display: none;
  `}
`;

export const SliderWrapper = styled.div`
  padding: 20px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

export const StyledIcon = styled.i`
  opacity: ${props => (props.hide ? 0 : 1)};
  transition: opacity 0.3s ease;
  font-size: 29px;
  color: ${props => props.theme.baseFontColor};
  cursor: ${props => (props.hide ? 'auto' : 'pointer')};
  pointer-events: ${props => (props.hide ? 'none' : 'auto')};
  flex-grow: 0;
`;

export const WeekDay = styled.span`
  margin-bottom: -6px;
  text-transform: capitalize;
  white-space: nowrap;
`;

export const Label = styled.div`
  text-align: left;
  font-size: 14px;
  line-height: 1.2;
  word-wrap: break-word;
  word-break: break-word;
  font-weight: bold;
`;

export const ShowMoreButton = styled.span`
  text-align: center;
  cursor: pointer;
  margin: 5px;
  font-weight: bold;
`;

export const MenuTitle = styled.h1`
  padding-top: 20px;
  font-size: 50px;
  width: 100%;
  text-align: center;
  font-weight: 300;
`;

export const HeadingWrapper = styled.div`
  position: relative;
  ${props => props.firstWeekday && `border-radius: 8px 0 0 0`};
  ${props => props.lastWeekday && `border-radius: 0 8px 0 0`};
  margin: 0;
  padding: 5px 10px;
  //text-align: center;
`;

export const SubHeading = styled.h2`
  margin-top: 20px;
  font-size: 30px;
`;

export const ArrowWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  [class*='fa-'] {
    margin: 5px;
  }
`;

export const MealCellWrapper = styled.div`
  transition: 0.2s all;
  visibility: ${props => (props.isVisible ? '1' : 0)};
`;

export const MealCellTitle = styled.h3`
  transition: 0.2s all;
  visibility: ${props => (props.isVisible ? '1' : 0)};
`;
