import React from 'react';
import { Box } from '@rebass/grid';

import SingleOrderEntry from './SingleOrderEntry';
import { Paragraph } from 'styledComponents/elements/Typography';

const SingleOrderList = ({ rows, noRowsText }) => (
  <Box mt={10} mx={-20}>
    {rows.length > 0 ? (
      rows.map((row, index) => <SingleOrderEntry row={row} key={index} />)
    ) : (
      <Paragraph textAlign="center">{noRowsText}</Paragraph>
    )}
  </Box>
);

export default SingleOrderList;
