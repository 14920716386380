import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Box, Flex } from '@rebass/grid';
import { ButtonLink } from 'styledComponents/elements/Button';
import Spinner from 'styledComponents/elements/LoadingSpinner';
import { ContentWrapper } from 'styledComponents/blocks/ContentWrapper';
import { Heading } from 'styledComponents/elements/Typography';
import { BadgeGreen as StyledBadgeGreen } from 'styledComponents/elements/Badge';
import {
  media,
  fromPxToRem,
  DisplayOnMobile,
  DisplayOnDesktop,
} from 'styledComponents/helpers';

import Table from 'common/components/Table';
import withMoneyBoxHistory from 'common/components/MoneyBox';

import { getHeaders } from 'common/components/MoneyBox/const/moneyBox';
import SingleMoneyBoxList from 'views/MoneyBox/components/SingleMoneyBoxList';

import { dateObjectToDateTime } from 'common/services/dateHelpers';
import { useTranslation } from 'react-i18next';

const MoneyBoxText = styled.span`
  margin-right: 12px;

  ${media.tabletDown`
    font-size: ${fromPxToRem('16px')};
  `};
`;

const MoneyBoxContent = ({
  moneyBoxHistory,
  moneyBoxPoints,
  shortName,
  isLoading,
  userData,
  allMoneyBoxHistoryLoaded,
  updateMoneyBoxHistory,
  getAllMoneyBoxHistory,
  itemsPerPage,
  token,
  getMoneyBoxHistory,
  getUserData,
}) => {
  const { t } = useTranslation();
  const rows = moneyBoxHistory.map(({ createdAt, event, value }) => [
    dateObjectToDateTime(createdAt),
    event,
    <StyledBadgeGreen isSmallMobile>
      {value > 0 ? '+' : ''}
      {value} {shortName || t('$*common.pointAbbreviation', '$$pkt')}
    </StyledBadgeGreen>,
  ]);
  const HEADERS = getHeaders(t);

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (token) {
      Promise.all([
        window.location.href.includes('money-box') &&
          getMoneyBoxHistory(itemsPerPage, 1),
      ]);
    }
  }, []);

  const emptyMoneyBoxHistoryText = t(
    '$*moneyBoxContent.emptyMoneyBoxHistoryText',
    '$$Historia skarbonki jest pusta'
  );

  return (
    <ContentWrapper padding="40px" marginBottom="20px" paddingTop="20px">
      <Flex
        flexDirection={['column', 'column', 'row']}
        justifyContent={['center', 'center', 'space-between']}
        alignItems="center"
        flexWrap="wrap"
      >
        <Box>
          <Heading paddingMobile="0 0 15px 0" fontSizeMobile="18px">
            {t('$*moneyBoxContent.balanceHistory', '$$Historia skarbonki')}
          </Heading>
        </Box>
        <Box>
          <MoneyBoxText>
            {t('$*moneyBoxContent.balanceCaption', '$$Stan skarbonki:')}
          </MoneyBoxText>
          <StyledBadgeGreen isBigDesktop>
            {userData.moneyBox}{' '}
            {shortName || t('$*common.pointAbbreviation', '$$pkt')}
          </StyledBadgeGreen>
        </Box>
      </Flex>
      <DisplayOnMobile>
        <SingleMoneyBoxList rows={rows} noRowsText={emptyMoneyBoxHistoryText} />
      </DisplayOnMobile>
      <DisplayOnDesktop>
        <Table
          headers={HEADERS}
          rows={rows}
          noRowsText={emptyMoneyBoxHistoryText}
        />
      </DisplayOnDesktop>
      <Box textAlign={'center'}>
        {isLoading && <Spinner />}
        {!allMoneyBoxHistoryLoaded && !isLoading && (
          <Fragment>
            <Flex justifyContent={'space-around'}>
              <Box>
                <ButtonLink
                  type="button"
                  uppercased
                  weightBold
                  onClick={() => {
                    updateMoneyBoxHistory(itemsPerPage, page);
                    setPage(page + 1);
                  }}
                  fullWidthOnMobile
                >
                  {t('$*moneyBoxContent.loadMoreCaption', '$$Załaduj więcej')}
                </ButtonLink>
              </Box>
              <Box>
                <ButtonLink
                  type="button"
                  uppercased
                  weightBold
                  onClick={() => getAllMoneyBoxHistory()}
                  fullWidthOnMobile
                >
                  {t('$*moneyBoxContent.loadAllCaption', '$$Załaduj wszystko')}
                </ButtonLink>
              </Box>
            </Flex>
          </Fragment>
        )}
      </Box>
    </ContentWrapper>
  );
};

export default compose(
  connect(
    ({
      app: {
        brand: {
          moneyBoxConfigurationNames: { shortName },
        },
      },
    }) => ({ shortName }),
    null
  ),
  withMoneyBoxHistory
)(MoneyBoxContent);
