import FORM_FIELD_NAMES from '../formFieldNames';

const FIELDS_TO_BE_WATCHED_FOR_CALCULATIONS = [
  FORM_FIELD_NAMES.PACKAGE,
  FORM_FIELD_NAMES.DIET_TYPE,
  FORM_FIELD_NAMES.VARIANT,
  FORM_FIELD_NAMES.CALORIFICS,
  FORM_FIELD_NAMES.DURATION,
  FORM_FIELD_NAMES.PAYMENT_MODE,
  FORM_FIELD_NAMES.FIRST_DELIVERY_DAY,
  FORM_FIELD_NAMES.DELIVERY_DAYS,
  FORM_FIELD_NAMES.DELIVERY_TYPE,
  FORM_FIELD_NAMES.DELIVERY_ADDRESS,
  FORM_FIELD_NAMES.DELIVERY_PICKUP_POINT,
  FORM_FIELD_NAMES.TEST_MODE,
  FORM_FIELD_NAMES.WITH_ECOBOX,
  FORM_FIELD_NAMES.WITH_SATURDAYS,
  FORM_FIELD_NAMES.WITH_SUNDAYS,
  FORM_FIELD_NAMES.WITH_WEEKENDS,
];

export default FIELDS_TO_BE_WATCHED_FOR_CALCULATIONS;
