import React from 'react';
import styled from 'styled-components';

import Modal from 'common/components/Modal';

import ConfirmButtonWrapper from 'common/components/Modal/ConfirmButtonWrapper';

import { Paragraph } from 'styledComponents/elements/Typography';
import { useTranslation } from 'react-i18next';

const ButtonsWrapper = styled.div`
  margin: 30px 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CancelConfirmationModal = ({
  isOpened,
  toggleModal,
  id,
  cancelOrder,
  orderId,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      id={id}
      title={t('$*myOrders.cancelConfirmationModal.title', {
        defaultValue: '$$Czy na pewno chcesz usunąć zamówienie #{{orderId}}?',
        replace: {
          orderId,
        },
      })}
      isOpened={isOpened}
      toggleModal={toggleModal}
      contentPadding="10px"
    >
      <ButtonsWrapper>
        <Paragraph
          style={{ cursor: 'pointer' }}
          customPadding="0 20px"
          noMargin
          onClick={toggleModal}
        >
          {t('$*common.cancel', '$$Anuluj')}
        </Paragraph>
        <ConfirmButtonWrapper
          buttonText={t('$*common.confirm', '$$Potwierdź')}
          disabled={false}
          padding="8px 20px"
          marginLeft="10px"
          marginRight="10px"
          onClick={() => cancelOrder(orderId).then(() => toggleModal())}
        />
      </ButtonsWrapper>
    </Modal>
  );
};

export default CancelConfirmationModal;
