import React from 'react';
import styled from 'styled-components';
import { Flex } from '@rebass/grid';

import { fromPxToRem } from 'styledComponents/helpers';

const DietCardButton = styled.span`
  width: 100%;
  margin-top: 15px;
  padding: auto 20px;
  border: ${props =>
    props.isSelected ? `2px solid ${props.theme.baseFontColor}` : `none`};
  border-radius: 8px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
  font-size: ${fromPxToRem('16px')};
  font-weight: ${props => (props.isSelected ? 900 : 500)};

  cursor: pointer;
`;

const SingleDietCard = ({ diet, onChange, isSelected }) => (
  <DietCardButton
    type="button"
    onClick={() => onChange(diet)}
    isSelected={isSelected}
    color={diet.color}
  >
    <Flex
      justifyContent="space-evenly"
      alignItems="center"
      flexDirection="column"
      flexWrap="wrap"
      m={10}
    >
      {diet.name}
    </Flex>
  </DietCardButton>
);

export default SingleDietCard;
