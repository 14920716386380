import styled from 'styled-components';

const StarRating = styled.div`
  .fill {
    color: ${props => props.theme.colorStarYellow};
  }

  .empty {
    color: ${props => props.theme.colorStarGrey};
  }

  [class*='fa-'] {
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    padding-right: 4px;
  }
`;

export default StarRating;
