import React from 'react';
import { Flex, Box } from '@rebass/grid';

import SingleMoneyBoxEntry from './SingleMoneyBoxEntry';

import { Paragraph } from 'styledComponents/elements/Typography';

const SingleMoneyBoxList = ({ rows = [], noRowsText }) => (
  <Flex>
    <Box mt={10} width="100%">
      {rows.length > 0 ? (
        rows.map((row, index) => <SingleMoneyBoxEntry row={row} key={index} />)
      ) : (
        <Paragraph textAlign="center">{noRowsText}</Paragraph>
      )}
    </Box>
  </Flex>
);

export default SingleMoneyBoxList;
