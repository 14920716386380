import styled from 'styled-components';
import { Flex } from '@rebass/grid';

import { fromPxToRem, media } from 'styledComponents/helpers';

export const BaseCaption = styled(Flex)`
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 16px;
  font-size: ${fromPxToRem('9px')};
  font-weight: ${props => props.theme.fontWeight900};
  font-style: normal;
  text-align: center;
  text-transform: uppercase;
  width: max-content;
  margin: 0 auto 14px;
  padding: 0 6px;
`;

export const BaseIconWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 25px;
  height: 25px;
  right: 0;
  top: -15px;
  transform: translate(-50%, -50%);
`;

export const BaseDayNumber = styled.div`
  margin-top: auto;
`;

export const BaseWrapper = styled(Flex)`
  ${props => props.isIteratedDayToday && 'transform: scale(1.1);'}
  flex-direction: column;
  position: relative;
  font-size: ${fromPxToRem('20px')};
  border-color: ${props => props.theme.baseFontColor};
  background-color: ${props => props.theme.colorWhite};
  border: 1px solid;

  ${props =>
    props.isIteratedDayToday &&
    `
    &:after {
      content: '•';
      font-size: 24px;
      display: inline-block;
      width: 100%;
      bottom: 0;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  `}

  ${media.tabletDown`
    font-size: ${fromPxToRem('16px')};
    &:after {
      font-size: 22px;
      bottom: -12px;
    }
  `}
`;
