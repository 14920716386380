import styled from 'styled-components';
import { Box } from '@rebass/grid';

import { fromPxToRem, media } from 'styledComponents/helpers';

const StyledFormRadio = styled(Box)`
  background-color: ${props =>
    props.disabled && !props.checked
      ? props.theme.colorIceBlue2
      : props.theme.colorWhite};
  border: ${props =>
    props.checked
      ? `2px solid ${props.theme.baseFontColor}`
      : props.disabled
      ? `2px solid ${props.theme.colorIceBlue2}`
      : `2px solid ${props.theme.colorWhite}`};
  border-radius: ${props => props.theme.baseBorderRadius};
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: ${props => (props.isCentered ? 'center' : 'none')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};

  input[type='radio'] {
    position: fixed;
    opacity: 0;
  }

  [class*='fa-'] {
    padding-right: 15px;
    display: ${props => (props.isCentered ? 'block' : 'inherit')};
    margin-bottom: ${props => (props.isCentered ? '5px' : '0px')};

    ${media.tabletDown`
      padding-right: ${props => props.isCentered && '0'};
    `};
  }

  [class^='far'] {
    color: ${props => props.theme.colorLightBlueGrey};
  }
`;

export const StyledFormRadioLabel = styled.span`
  color: ${props => (props.checked ? props.theme.baseFontColor : null)};
  font-weight: ${props => props.theme.fontWeight500};
`;

export const StyledFormRadioDescription = styled.div`
  padding-top: 5px;
  font-size: ${fromPxToRem('14px')};
  line-height: 1.2rem;
  margin-bottom: 15px;
`;

export const StyledFormRadioPrimary = styled(StyledFormRadio)`
  ${media.tabletDown`
    width: 100%;
    margin: 10px auto;
  `};

  padding: 10px 20px;
  width: ${props => (props.hasAutoWidth ? 'auto' : 'calc(25% - 20px)')};
  margin-bottom: ${props => (props.hasBottomMargin ? '16px' : 0)};
  margin-top: 10px;
  min-width: 250px;
  margin-right: ${props => props.marginRight};
  cursor: ${props => (props.disableHandCursor ? 'default' : 'pointer')};
`;

export const StyledFormRadioPrimary2Columns = styled(StyledFormRadioPrimary)`
  width: calc(50% - 20px);
`;

export const StyledMenuPlanningFormRadio = styled(StyledFormRadio)`
  ${media.tabletDown`
    width: 100%;
    margin: 10px auto;
  `};
  border: ${props =>
    props.checked
      ? `2px solid ${props.theme.baseFontColor}`
      : props.isFaded
      ? `2px solid ${props.theme.colorIceBlue2}`
      : `2px solid ${props.theme.colorWhite}`};
  background-color: ${props =>
    props.isFaded ? props.theme.colorIceBlue2 : props.theme.colorWhite};
  height: auto;
  padding: 10px 20px;
  width: ${props => (props.hasAutoWidth ? 'auto' : 'calc(25% - 20px)')};
  margin-bottom: ${props => (props.hasBottomMargin ? '16px' : 0)};
  margin-top: 10px;
  min-width: 250px;

  transition: 0.25s all;
`;

export const StyledFormRadioSecondary = styled(StyledFormRadio)`
  min-height: ${props => props.noContainer || '100px'};
  height: ${props => props.hasAutoHeight && 'auto'};
  padding: 12px 16px;
  width: ${props => (props.noContainer ? 'max-content' : 'calc(50% - 20px)')};
  background: ${props => props.noContainer && 'none'};
  border: ${props => props.noContainer && 'none'};
  box-shadow: ${props => props.noContainer && 'none'};
  margin: 5px 10px;
  max-width: ${props => props.maxWidth};
  min-width: 250px;

  ${media.tabletDown`
    width: ${props =>
      props.noContainer
        ? 'max-content'
        : '100%'
        ? `calc(${props.width} - 20px)`
        : '100%'};
    margin: 10px auto;
    overflow-wrap: break-word;
  `};

  ${media.mobileDown`
    min-width: 200px;
    width: 100%;
  `}
`;

export const StyledFormRadioVariantSecondary = styled(StyledFormRadio)`
  min-height: 100px;
  height: auto;
  padding: 12px 16px;
  width: calc(33% - 20px);
  margin: 5px 10px;
  max-width: ${props => props.maxWidth};
  min-width: 250px;

  ${media.tabletDown`
    width: ${props => (props.width ? `calc(${props.width}% - 20px)` : '100%')};
    margin: 10px auto;
    overflow-wrap: break-word;
  `};
`;

export const StyledFormRadioPrimaryMobile = styled(StyledFormRadioPrimary)`
  display: flex;
  align-items: center;
  min-height: 54px;
  height: unset;
  line-height: 1.4;
  padding: 12px 20px;

  span {
    white-space: pre-line;
  }
`;

export const StyledFormRadioSecondaryMobile = styled(StyledFormRadioSecondary)`
  height: ${props => (props.hasAutoHeight ? 'auto' : '100px')};
  width: 100%;
  padding: 12px 16px;
  margin: 0 0 12px;

  [class*='fa-'] {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    padding: 0;
  }
`;

export const StyledFormRadioBox = styled(StyledFormRadio)`
  padding: 10px 15px;
`;

// STYLE: Horizontal and scrollable list of radio buttons
export const StyledFormRadioHorizontalList = styled.div`
  max-height: 300px;
  margin-top: 10px;
  padding: 15px;
  overflow-y: auto;
  border-radius: ${props => props.theme.baseBorderRadius};
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.colorWhite};
`;

export const StyledFormRadioHorizontalListItem = styled.label`
  display: block;
  overflow: hidden;
  padding: 15px 0;
  border-bottom: 1px solid ${props => props.theme.colorIceBlue2};
  text-overflow: ellipsis;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  input[type='radio'] {
    position: fixed;
    opacity: 0;
  }

  [class*='fa-'] {
    padding-right: 15px;
  }

  [class^='far'] {
    color: ${props => props.theme.colorLightBlueGrey};
  }
`;
