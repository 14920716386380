import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, useMediaQuery } from '@material-ui/core';

import { StyledMuiSelect, StyleMenuItem } from './StyledMuiSelect';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { pick } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { URLS } from 'configuration';
import { put } from 'utils/http';
import { getConfig } from 'views/App/actions/appActions';

import cz from '../../../assets/images/flags/CZ.png';
import es from '../../../assets/images/flags/ES.png';
import de from '../../../assets/images/flags/DE.png';
import en from '../../../assets/images/flags/GB.png';
import fi from '../../../assets/images/flags/FI.png';
import id from '../../../assets/images/flags/ID.png';
import it from '../../../assets/images/flags/IT.png';
import pl from '../../../assets/images/flags/PL.png';
import ru from '../../../assets/images/flags/RU.png';
import ua from '../../../assets/images/flags/UA.png';

export const useLanguageManager = () => {
  const { lang } = useParams();
  const history = useHistory();
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();

  const { defaultLanguage, clientAllowedToChangeLanguage } = useSelector(
    ({
      app: {
        config: {
          multinational: { defaultLanguage, clientAllowedToChangeLanguage },
        },
      },
    }) => ({
      defaultLanguage,
      clientAllowedToChangeLanguage,
    })
  );
  const isUserLogged = useSelector(({ auth: { token } }) => !!token);

  const changeLanguage = useCallback(
    newLang => {
      i18n
        .changeLanguage(newLang)
        .then(() => {
          moment.locale(newLang);
          document.documentElement.lang = newLang;
          isUserLogged && put(URLS.USER_DATA, { language: newLang });

          // localStorage.setItem('language', newLang);
          if (lang && lang !== newLang) {
            const pathParts = history.location.pathname.split('/');
            const langId = pathParts.indexOf(lang);
            if (langId >= 0) {
              pathParts[langId] = newLang;
            }
            history.push({
              ...pick(['hash', 'search'], history.location),
              pathname: pathParts.join('/'),
            });
          }
        })
        .catch(err => console.log('Error while changing language', err));
    },
    [lang]
  );
  const inferLanguageFromLocation = useCallback(() => {
    if (lang && lang !== i18n.language) {
      changeLanguage(lang);
    }
  }, [lang, changeLanguage, i18n.language]);

  useEffect(() => inferLanguageFromLocation(), [lang]);

  useEffect(() => {
    window.t = t;
    moment.locale(i18n.language);
  }, [i18n.language, i18n.t]);

  useEffect(() => {
    if (!clientAllowedToChangeLanguage) {
      changeLanguage(defaultLanguage);
    }
  }, [defaultLanguage, clientAllowedToChangeLanguage, changeLanguage]);

  return useMemo(
    () => ({
      changeLanguage,
      inferLanguageFromLocation,
    }),
    [changeLanguage, inferLanguageFromLocation]
  );
};

const countryflags = {
  cs: cz,
  de,
  en,
  es,
  fi,
  in: id,
  it,
  pl,
  ru,
  ua,
};

export const LanguageSelector = ({
  maxMobileWidth = 0,
  hideOnMobile,
  fullWidth = false,
}) => {
  const { i18n } = useTranslation();
  const theme = useTheme();
  const countryFlag = countryflags[i18n.language];

  const isMobile = useMediaQuery(theme =>
    theme.breakpoints.down(maxMobileWidth)
  );
  const { changeLanguage } = useLanguageManager();

  return (
    <Box
      ml={2}
      flexDirection="row"
      alignItems="center"
      display={isMobile && hideOnMobile ? 'none' : 'flex'}
      width={fullWidth ? '100%' : '77px'}
      justifyItems="flex-end"
    >
      <Box mr="10px">
        <img
          style={{ verticalAlign: 'middle' }}
          src={countryFlag}
          alt={i18n.language}
        />
      </Box>
      {isMobile && !hideOnMobile ? (
        <select
          style={{
            flex: '1 0 0',
            border: 'none',
            borderBottom: `solid 1px ${theme.baseFontColor}`,
            color: theme.baseFontColor,
            fontSize: '1rem',
            padding: '6px 0 7px',
          }}
          value={i18n.language}
          onChange={e => changeLanguage(e.target.value)}
        >
          {Object.keys(i18n.options.resources).map(lang => {
            return <option value={lang}>{lang}</option>;
          })}
        </select>
      ) : (
        <StyledMuiSelect
          label="Language"
          onChange={e => changeLanguage(e.target.value)}
          value={i18n.language}
        >
          {Object.keys(i18n.options.resources).map(lang => {
            return (
              <StyleMenuItem
                color={theme.baseFontColor}
                key={lang}
                value={lang}
              >
                {lang.toUpperCase()}
              </StyleMenuItem>
            );
          })}
        </StyledMuiSelect>
      )}
    </Box>
  );
};
