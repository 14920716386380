import styled from 'styled-components';
import { CardElement } from '@stripe/react-stripe-js';

const StyleCardElement = styled(CardElement)`
  font-weight: ${props => props.theme.fontWeight900};
  width: 100%;
  box-sizing: border-box;
  appearance: none;
  background: ${props => props.theme.colorWhite};
  border-radius: ${props => props.theme.baseBorderRadius};
  border: ${props => `2px solid ${props.theme.colorWhite}`};
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
  padding: 17px 14px;
  margin: 10px 0 20px;
  height: 54px;
  color: ${props => props.theme.baseFontColor};
  font-family: inherit;
`;

export default StyleCardElement;
