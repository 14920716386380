import FormRadio from 'common/components/Form/FormRadio';
import FormRadioDietCard from 'common/components/Form/FormRadioDietCard';
import FormSelectBox from 'common/components/Form/FormSelectBox';
import FormCheckbox from 'common/components/Form/FormCheckbox';
import DurationSlider from 'common/components/Form/DurationSlider';
import FORM_FIELD_NAMES from '../formFieldNames';

export const CONFIGURATION_FIELDS = {
  [FORM_FIELD_NAMES.PACKAGE]: {
    name: FORM_FIELD_NAMES.PACKAGE,
    type: 'radio',
    component: FormRadioDietCard,
    componentStyle: 'primary',
  },
  // [FORM_FIELD_NAMES.DIET_TYPE]: {
  //   name: FORM_FIELD_NAMES.DIET_TYPE,
  //   type: 'radio',
  //   component: FormRadioDietCard,
  //   componentStyle: 'primary',
  // },
  // [FORM_FIELD_NAMES.VARIANT]: {
  //   name: FORM_FIELD_NAMES.VARIANT,
  //   type: 'radio',
  //   component: FormRadio,
  //   componentStyle: 'variantSecondary',
  // },
  // [FORM_FIELD_NAMES.CALORIFICS]: {
  //   name: FORM_FIELD_NAMES.CALORIFICS,
  //   type: 'radio',
  //   component: FormRadio,
  //   componentStyle: 'primary',
  // },
  // [FORM_FIELD_NAMES.DURATION]: {
  //   name: FORM_FIELD_NAMES.DURATION,
  //   type: 'select',
  //   component: FormSelectBox,
  //   placeholder: 'Czas trwania',
  // },
  // [FORM_FIELD_NAMES.DURATION_SLIDER]: {
  //   name: FORM_FIELD_NAMES.DURATION,
  //   component: DurationSlider,
  //   placeholder: 'Czas trwania',
  // },
  // [FORM_FIELD_NAMES.DURATION_INPUT]: {
  //   name: FORM_FIELD_NAMES.DURATION,
  //   component: FormSelectBox,
  //   placeholder: 'Czas trwania',
  // },
  [FORM_FIELD_NAMES.WITH_SATURDAYS]: {
    name: FORM_FIELD_NAMES.WITH_SATURDAYS,
    type: 'checkbox',
    component: FormCheckbox,
  },
  [FORM_FIELD_NAMES.WITH_SUNDAYS]: {
    name: FORM_FIELD_NAMES.WITH_SUNDAYS,
    type: 'checkbox',
    component: FormCheckbox,
  },
  [FORM_FIELD_NAMES.WITH_WEEKENDS]: {
    name: FORM_FIELD_NAMES.WITH_WEEKENDS,
    type: 'checkbox',
    component: FormCheckbox,
  },
  [FORM_FIELD_NAMES.TEST_MODE]: {
    name: FORM_FIELD_NAMES.TEST_MODE,
    type: 'checkbox',
    component: FormCheckbox,
  },
  [FORM_FIELD_NAMES.WITH_ECOBOX]: {
    name: FORM_FIELD_NAMES.WITH_ECOBOX,
    type: 'checkbox',
    componentStyle: 'square',
    component: FormCheckbox,
    isBig: true,
  },
};
