import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import ROUTE_URLS from 'routes/routeUrls';
import { BASE_URL, URLS } from 'configuration';

import { media, fromPxToRem } from 'styledComponents/helpers';
import { useTranslation } from 'react-i18next';

const MoneyBoxBalanceWrapper = styled(NavLink)`
  display: flex;
  min-width: 210px;
  height: 36px;
  border-radius: ${props => props.theme.baseBorderRadius};
  border: 1px solid ${props => props.theme.colorLightBlueGrey};
  cursor: pointer;

  ${media.mobileDown`
    min-width: auto;
  `};
`;

const BalanceText = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 10px;
  font-size: ${fromPxToRem('14px')};
  border-right: 1px solid ${props => props.theme.colorLightBlueGrey};
  white-space: nowrap;

  ${media.mobileDown`
    display: none;
  `};
`;

const PointsText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.baseFontColor};
  font-size: ${fromPxToRem('16px')};
  line-height: 1.38rem;
  padding: 0 10px;
  font-weight: ${props => props.theme.fontWeight900};
  white-space: nowrap;
`;

const MoneyBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  width: 20px;
  height: 20px;

  ${({ isDefaultIcon, theme }) =>
    isDefaultIcon &&
    `
      padding: 3px;
      border-radius: 50%;
      border: 1px solid ${theme.colorLightBlueGrey};

      i {
        color: ${theme.baseFontColor};
      }
  `};
`;

MoneyBoxWrapper.Icon = styled.div`
  ${({ image }) =>
    `background-image: url(${BASE_URL}${URLS.UPLOADED_MEDIA}${image})`};
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
`;

const MoneyBoxBalance = ({
  moneyBoxIcon,
  moneyBoxPoints = '',
  shortName = '',
}) => {
  const { t } = useTranslation();
  return (
    <MoneyBoxBalanceWrapper to={ROUTE_URLS.MONEY_BOX}>
      <BalanceText>
        <MoneyBoxWrapper isDefaultIcon={!moneyBoxIcon?.contentUrl}>
          {moneyBoxIcon?.contentUrl ? (
            <MoneyBoxWrapper.Icon image={moneyBoxIcon.contentUrl} />
          ) : (
            <i className="fas fa-dollar-sign" />
          )}
        </MoneyBoxWrapper>
        {t('$*moneyBoxBalance.balanceCaption', '$$Stan skarbonki')}
      </BalanceText>
      <PointsText>{`${moneyBoxPoints} ${
        shortName || t('$*common.pointAbbreviation', '$$pkt')
      }`}</PointsText>
    </MoneyBoxBalanceWrapper>
  );
};

export default compose(
  connect(
    ({
      app: {
        brand: {
          moneyBoxConfigurationNames: { shortName },
        },
        config: { moneyBoxIcon },
      },
    }) => ({ shortName, moneyBoxIcon }),
    null
  ),
  withRouter
)(MoneyBoxBalance);
