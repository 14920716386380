import React, { useEffect } from 'react';
import { Field } from 'react-final-form';
import { Flex, Box } from '@rebass/grid';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import ReactPixel from 'react-facebook-pixel';

import { Paragraph, Heading } from 'styledComponents/elements/Typography';
import FormCheckbox from 'common/components/Form/FormCheckbox';

import { getOrderCost } from 'views/NewOrder/actions/orderFormActions';
import FORM_FIELD_NAMES from 'views/NewOrder/const/formFieldNames';

import styled from 'styled-components';
import { setNextTabDisability } from '../components/OrderFormTabs/actions/orderFormTabsActions';
import { OnChange } from 'react-final-form-listeners';
import withBasket from '../components/withBasket';
import { useTranslation } from 'react-i18next';
import { useCurrentTabNames } from '../components/OrderFormTabs/useCurrentTabNames';

const PremiumStepBox = styled.div`
  padding: 80px 20px 70px;
  background-color: ${props => props.theme.colorWhite};
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
`;

const PremiumStep = ({
  setNextTabDisability,
  getOrderCost,
  multinational: { currencySymbol },
  chooseMenuDescription,
  disableTracking,
  ...props
}) => {
  const { t } = useTranslation();
  useCurrentTabNames('PremiumStep');

  useEffect(() => {
    !disableTracking && ReactPixel.trackCustom('Premium - Wybór menu');
    setNextTabDisability(false);
  }, [disableTracking]);

  const {
    premiumPricePerDay,
    values: { optionChangeMenu },
  } = props;
  return (
    <PremiumStepBox>
      <Flex justifyContent="center" alignItems="center" flexDirection="column">
        <Box width={['100%', '100%', '60%']}>
          <Heading textAlign="center" baseFontColor>
            {t('$*premiumStep.title', 'WYBÓR MENU')}
          </Heading>
        </Box>
        <Box
          width={['100%', '100%', '90%']}
          textAlign={'center'}
          dangerouslySetInnerHTML={{
            __html: chooseMenuDescription
              .replace('{{price}}', premiumPricePerDay)
              .replace('{{currencySymbol}}', currencySymbol),
          }}
        />
        <Box mt={['15px', '15px', '25px']}>
          <Field
            name={FORM_FIELD_NAMES.IS_PREMIUM}
            type="checkbox"
            component={FormCheckbox}
            descriptionAlignment="left"
          >
            <Paragraph noPadding noMarginMobile colorPremiumGold>
              {t('$*premiumStep.chooseMenuCaption', 'WYBÓR MENU')}
            </Paragraph>
          </Field>
          <OnChange name={FORM_FIELD_NAMES.IS_PREMIUM}>
            {() => requestAnimationFrame(() => getOrderCost())}
          </OnChange>
        </Box>
        <span
          style={{
            marginTop: '15px',
            visibility: optionChangeMenu ? 'visible' : 'hidden',
          }}
        >
          {t(
            '$*premiumStep.changeNotes',
            'Pamiętaj, że dokonując zmian, wartość odżywcza oraz rozkład makroskładników może różnić się od Twojego domyślnego wyboru'
          )}
        </span>
      </Flex>
    </PremiumStepBox>
  );
};

export default compose(
  connect(
    ({
      app: {
        config: {
          multinational,
          modules: { ConfigClientPanel },
        },
      },
      nativeAppConfig: { disableTracking },
    }) => ({
      multinational,
      disableTracking,
      chooseMenuDescription:
        ConfigClientPanel?.chooseMenuDescription ||
        `<p style="padding: 0 0 25px">WYBÓR MENU pozwoli Ci wybierać pomiędzy posiłkami ze wszystkich dostępnych rodzajów diet! Pamiętaj, że dokonując zmian, wartość odżywcza oraz rozkład makroskładników może różnić się od Twojego domyślnego wyboru.</p><p>WYBÓR MENU to tylko {{price}} {{currencySymbol}} dziennie<p>`,
    }),
    { getOrderCost, setNextTabDisability }
  ),
  withBasket
)(PremiumStep);
