import React from 'react';
import { connect, useSelector } from 'react-redux';
import { compose, withProps } from 'recompose';
import { Flex, Box } from '@rebass/grid';
import moment from 'moment';

import { BadgeSecondary } from 'styledComponents/elements/Badge';
import { StyledSummaryItem } from 'styledComponents/elements/NewOrder/Summary';

import {
  setCurrentForm,
  removeForm,
  updateForm,
  setNavUpdates,
} from '../../actions/dietFormActions';
import { getOrderCost } from '../../actions/orderFormActions';
import { setTabIndex } from '../OrderFormTabs/actions/orderFormTabsActions';
import { BASE_URL } from 'configuration';

import { SUMMARY_ITEM_BUTTONS_CONFIG, PRICES_CONFIG } from './const';
import SummaryPremiumSwitch from './SummaryPremiumSwitch';
import {
  DisplayOnDesktop,
  DisplayOnMobile,
} from '../../../../styledComponents/helpers';
import { useTranslation } from 'react-i18next';
import PriceItem from '../PriceItem';
import {
  setDuration,
  setSelectedDays,
} from 'common/components/Calendar/actions/calendarActions';
import FormTextInput from 'common/components/Form/FormTextInput';
import FORM_FIELD_NAMES from 'views/NewOrder/const/formFieldNames';

const SummaryItem = ({
  dietSummary: {
    dietType,
    dietDeliveryAddress,
    dietVariant,
    dietCalorifics,
    dietDuration,
    dietDeliveryType,
    dietDeliveryHourUpTo,
    dietIcon,
    dietImage,
    useEcoContainers,
    testMode,
    dietAddons,
    dietFirstDeliveryDay,
    dietLastDeliveryDay,
  },
  costs,
  forms,
  formsCount,
  formId,
  onEdit,
  onRemove,
  premiumType,
  deliveryToDoorEnabled,
  pickUpPointEnabled,
  multinational,
  paymentMode,
  modules,
  updateForm,
}) => {
  const isSubscription = useSelector(
    ({ dietForm: { paymentMode } }) => paymentMode === '1'
  );
  const { t } = useTranslation();
  const { currencySymbol } = multinational;

  const dietInfos = [
    {
      label: t('$*orderFormSummary.addressLabel', '$$Adres'),
      value: dietDeliveryAddress,
      condition: dietDeliveryAddress,
    },
    {
      label: t('$*orderFormSummary.firstDeliveryLabel', '$$Pierwsza dostawa'),
      value: moment(new Date(dietFirstDeliveryDay)).format('DD MMMM YYYY'),
      condition: dietFirstDeliveryDay,
    },
    {
      label: t('$*orderFormSummary.lastDeliveryLabel', '$$Ostatnia dostawa'),
      value: moment(new Date(dietLastDeliveryDay)).format('DD MMMM YYYY'),
      condition: dietLastDeliveryDay,
    },
  ];

  return (
    <Flex mb={35}>
      <Box width={1}>
        <StyledSummaryItem isActive={true}>
          <Flex flexDirection={['column', 'column', 'row']}>
            {dietIcon && (
              <StyledSummaryItem.IconWrapper>
                <StyledSummaryItem.Icon src={`${BASE_URL}${dietIcon}`} />
              </StyledSummaryItem.IconWrapper>
            )}
            <Box width={[1, 1, dietIcon ? 6 / 10 : 7 / 10]}>
              <StyledSummaryItem.Inner>
                <Flex alignItems="center" justifyContent="space-between">
                  <Box>
                    {dietImage && (
                      <img
                        style={{
                          display: 'inline',
                          height: '37px',
                          marginRight: '10px',
                        }}
                        src={`${BASE_URL}${dietImage}`}
                        alt=""
                      />
                    )}
                    <StyledSummaryItem.Heading
                      isSmall
                      noPadding
                      paddingMobile={'0'}
                    >
                      {dietType}
                    </StyledSummaryItem.Heading>
                  </Box>
                  <Box>
                    <DisplayOnMobile>
                      <Flex>
                        {formsCount > 1 &&
                          Object.entries(SUMMARY_ITEM_BUTTONS_CONFIG(t)).map(
                            (
                              [
                                name,
                                { component: ButtonComponent, label, ...props },
                              ],
                              index
                            ) => {
                              return (
                                <Box ml={15} mt={15} mb={15} key={index}>
                                  <ButtonComponent
                                    key={name}
                                    customMargin={'0'}
                                    onClick={() =>
                                      name === 'edit' ? onEdit() : onRemove()
                                    }
                                    {...props}
                                  >
                                    {label}
                                  </ButtonComponent>
                                </Box>
                              );
                            }
                          )}
                      </Flex>
                    </DisplayOnMobile>
                    <DisplayOnDesktop>
                      <StyledSummaryItem.Buttons>
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                          flexWrap={'wrap'}
                        >
                          {premiumType === 'PREMIUM' && (
                            <SummaryPremiumSwitch formId={formId} />
                          )}
                          {formsCount > 1 &&
                            Object.entries(SUMMARY_ITEM_BUTTONS_CONFIG(t)).map(
                              (
                                [
                                  name,
                                  {
                                    component: ButtonComponent,
                                    label,
                                    ...props
                                  },
                                ],
                                index
                              ) => (
                                <Box mr={15} ml={15} key={index}>
                                  <ButtonComponent
                                    key={name}
                                    customMargin={'0'}
                                    onClick={() =>
                                      name === 'edit' ? onEdit() : onRemove()
                                    }
                                    {...props}
                                  >
                                    {label}
                                  </ButtonComponent>
                                </Box>
                              )
                            )}
                        </Flex>
                      </StyledSummaryItem.Buttons>
                    </DisplayOnDesktop>
                  </Box>
                </Flex>
                <DisplayOnMobile>
                  <Flex justifyContent={'space-between'}>
                    <Box mt={10} mb={10}>
                      {premiumType === 'PREMIUM' && (
                        <SummaryPremiumSwitch formId={formId} />
                      )}
                    </Box>
                  </Flex>
                </DisplayOnMobile>
                <StyledSummaryItem.DietInfo>
                  {testMode && (
                    <BadgeSecondary>
                      {t(
                        '$*orderFormSummary.testDietBadgeLabel',
                        'Zamówienie próbne'
                      )}
                    </BadgeSecondary>
                  )}
                  {[
                    dietVariant,
                    dietCalorifics,
                    isSubscription
                      ? t('$*paymentMode.subscriptionPayment', '$$Subskrypcja')
                      : dietDuration,
                    ...(deliveryToDoorEnabled && pickUpPointEnabled
                      ? [
                          dietDeliveryType === '1'
                            ? t(
                                '$*deliveryOptions.toTheDoorLabel',
                                '$$Dostawa pod drzwi'
                              )
                            : t(
                                '$*deliveryOptions.pickupPointLabel',
                                '$$Odbiór osobisty'
                              ),
                        ]
                      : []),
                    dietDeliveryHourUpTo,
                    useEcoContainers,
                  ].map(
                    (dietInfo, index) =>
                      dietInfo && (
                        <BadgeSecondary key={index}>{dietInfo}</BadgeSecondary>
                      )
                  )}
                </StyledSummaryItem.DietInfo>
                <StyledSummaryItem.InfoWrapper>
                  {dietInfos.map(({ label, value, condition }, index) => {
                    return condition ? (
                      <StyledSummaryItem.Info key={index}>
                        <StyledSummaryItem.InfoLabel>
                          {label}:
                        </StyledSummaryItem.InfoLabel>
                        <StyledSummaryItem.InfoValue>
                          {value}
                        </StyledSummaryItem.InfoValue>
                      </StyledSummaryItem.Info>
                    ) : null;
                  })}
                  {modules?.ConfigClientPanel?.allowClientOwnDietName && (
                    <StyledSummaryItem.Info>
                      <StyledSummaryItem.InfoLabel>
                        {t(
                          '$*orderFormSummary.setDietName',
                          '$$Nazwij swoją dietę'
                        )}
                        :
                      </StyledSummaryItem.InfoLabel>
                      <StyledSummaryItem.InfoValue>
                        <FormTextInput
                          value={forms[formId][FORM_FIELD_NAMES.DIET_NAME]}
                          type="text"
                          meta={{ error: {} }}
                          maxLength={60}
                          onChange={value => {
                            updateForm(formId, {
                              ...forms[formId],
                              [FORM_FIELD_NAMES.DIET_NAME]: value,
                            });
                          }}
                        />
                      </StyledSummaryItem.InfoValue>
                    </StyledSummaryItem.Info>
                  )}
                </StyledSummaryItem.InfoWrapper>
              </StyledSummaryItem.Inner>
            </Box>

            <Box width={[1, 1, 3 / 10]}>
              <StyledSummaryItem.Prices>
                <StyledSummaryItem.Inner>
                  {PRICES_CONFIG({
                    t,
                    dietCost: costs,
                    paymentMode,
                    addons: dietAddons || [],
                    deliveryType: dietDeliveryType,
                    dietLength: forms[formId].dietLength,
                  }).map((priceItem, _index) => (
                    <PriceItem
                      key={_index}
                      priceItem={priceItem}
                      currencySymbol={currencySymbol}
                    />
                  ))}
                </StyledSummaryItem.Inner>
              </StyledSummaryItem.Prices>
            </Box>
          </Flex>
        </StyledSummaryItem>
      </Box>
    </Flex>
  );
};

export default compose(
  connect(
    ({
      app: {
        brand: { premiumType, deliveryToDoorEnabled, pickUpPointEnabled },
        config: { modules, multinational },
      },
      dietForm: { forms, currentFormId, paymentMode },
      orderForm,
    }) => ({
      formsCount: Object.keys(forms).length,
      currentFormId,
      premiumType,
      deliveryToDoorEnabled,
      pickUpPointEnabled,
      orderForm,
      forms,
      modules,
      multinational,
      paymentMode,
    }),
    {
      setCurrentForm,
      updateForm,
      removeForm,
      setTabIndex,
      getOrderCost,
      setNavUpdates,
      setDuration,
      setSelectedDays,
    }
  ),
  withProps(
    ({
      forms,
      formId,
      setCurrentForm,
      removeForm,
      getOrderCost,
      setTabIndex,
      setNavUpdates,
      setSelectedDays,
    }) => ({
      onEdit: () => {
        const currentForm = forms[formId];
        setNavUpdates(false);
        setSelectedDays(currentForm.deliveryDays);
        setCurrentForm(formId);
        setTabIndex(0);
      },
      onRemove: () => {
        const firstForm = forms[0];
        removeForm(formId);
        setNavUpdates(false);
        setSelectedDays(firstForm.deliveryDays);
        setCurrentForm(0);
        getOrderCost();
      },
    })
  )
)(SummaryItem);
