import styled from 'styled-components';

const AddonText = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;

export const Styled = {
  AddonText,
};
