import styled from 'styled-components';
import { Flex } from '@rebass/grid';

import { fromPxToRem, media } from 'styledComponents/helpers';

const SingleMealChooseSection = styled.div`
  background: #fafafa;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

SingleMealChooseSection.Header = styled(Flex)`
  justify-content: space-between;
  align-items: center;
`;

SingleMealChooseSection.Title = styled.div`
  padding: 0;
  min-width: 140px;
  font-size: ${fromPxToRem('16px')};
  font-weight: ${props => props.theme.fontWeight900};
  line-height: 1.38;
  width: ${props => props.width || 'inherit'};

  ${media.tabletDown`
    text-align: ${props => props.textAlignMobile};
    width: ${props => props.widthMobile};
  `};
`;

SingleMealChooseSection.ContentWrapper = styled.div``;
SingleMealChooseSection.SelectedDish = styled.div``;
SingleMealChooseSection.SelectedDishKcal = styled.div``;
SingleMealChooseSection.DishWrapper = styled.div`
  margin-top: 10px;
`;
SingleMealChooseSection.CollapseButton = styled.div`
  padding: 10px;
  background: #f1f1f1;
  border-radius: 5px;
  margin-top: 10px;
  text-align: center;
`;

export default SingleMealChooseSection;
