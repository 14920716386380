import React, { Component } from 'react';
import { connect } from 'react-redux';
import { startOfWeek as getStartOfWeek, addDays, format } from 'date-fns';
import _ from 'lodash';
import { get } from 'utils/http';
import moment from 'moment';
import { Redirect, withRouter } from 'react-router-dom';
import { ButtonPrimary } from 'styledComponents/elements/Button';
import AllWeekComponent from './AllWeekComponent';
import SingleDayComponent from './SingleDayComponent';

import { mainTheme } from 'themes/main';
import { sortByPosition } from 'utils/componentHelpers';
import routeUrls from 'routes/routeUrls';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'moment/locale/pl';
import {
  MenuTitle,
  ButtonWrapper,
  SelectsWrapper,
  Container,
  PageDescriptionWrapper,
} from './StyledComponents';

import { compose } from 'recompose';
import { withLocalUrl } from 'routes/withLocalUrl';
import { withTranslation } from 'react-i18next';
import MenuBanner from './MenuBanner';
import DietsPicker from './DietsPicker';
import VariantsPicker from './VariantsPicker';
import PageDescription from './PageDescription';
import URLS from '../../routes/routeUrls';

const getDaysToShow = actualWidth => {
  switch (true) {
    case actualWidth < mainTheme.deviceBreakpoints['mobile']:
      return 1;
    case actualWidth < mainTheme.deviceBreakpoints['tablet']:
      return 3;
    case actualWidth < mainTheme.deviceBreakpoints['desktop']:
      return 4;
    default:
      return 7;
  }
};

class Menu extends Component {
  state = {
    daysToShow: getDaysToShow(window.innerWidth),
    selectedVariant: '',
    selectedDiet: '',
    reachedEnd: false,
    atBeggining: true,
    mealTypes: [],
    mealsByDays: [],
    sortedMealTypes: [],
    availableVariants: [],
    allWeekdays: [],
    currentSlide: 0,
    defaultQuery: {},
    dayOffset: -1,
    queryDiet: null,
    queryVariant: null,
    isLoading: false,
    isChangeVariantManual: false,
  };

  slider = null;
  variantSlider = null;
  dietSlider = null;

  startOfWeek = getStartOfWeek(new Date(), { weekStartsOn: 1 });
  dayOffset = moment().diff(this.startOfWeek, 'days') || 0;

  componentDidMount() {
    this.loadStateAndMeals();
  }

  componentWillUnmount() {
    this.resize = window.removeEventListener(
      'resize',
      _.throttle(this.resizeWindow, 1000)
    );
  }

  loadStateAndMeals = () => {
    const { mode, defaultDiet, defaultVariant } = this.props.MenuPage;
    const isWeekView = mode === 'WEEK_VIEW';
    const isDayView = mode === 'DAY_VIEW';
    const isDefaultDiet = mode === 'DEFAULT_DIET';
    const isAllDiets = mode === 'ALL_DIETS';

    if (isDayView || isWeekView) {
      const { selectedVariant, queryVariant } = this.setVariantFromUrl();
      const {
        selectedDiet,
        queryDiet,
        availableVariants,
      } = this.setDietFromUrl();

      const requestRangeDays = isWeekView ? 14 : 70;

      const query = {
        variant: selectedVariant,
        diet: selectedDiet,
        dateFrom: format(this.startOfWeek, 'YYYY-MM-DD'),
        dateTo: format(
          addDays(this.startOfWeek, requestRangeDays),
          'YYYY-MM-DD'
        ),
      };

      this.getMeals(query);
      this.setState({
        selectedVariant,
        queryVariant,
        selectedDiet,
        queryDiet,
        availableVariants,
        defaultQuery: query,
      });
    }

    if (isDefaultDiet) {
      const { selectedVariant, queryVariant } = this.setDefaultVariant(
        defaultVariant
      );
      const {
        selectedDiet,
        queryDiet,
        availableVariants,
      } = this.setDefaultDiet(defaultDiet);

      const query = {
        variant: selectedVariant,
        diet: selectedDiet,
        dateFrom: this.startOfWeek,
        dateTo: addDays(this.startOfWeek, 70),
      };

      this.getMeals(query);
      this.setState({
        selectedVariant,
        queryVariant,
        selectedDiet,
        queryDiet,
        availableVariants,
      });
    }

    if (isAllDiets) {
      this.getAllMeals();
    }

    this.resize = window.addEventListener(
      'resize',
      _.throttle(this.resizeWindow, 1000)
    );
  };

  setDefaultDiet = dietIri => {
    const { diets } = this.props;
    const dietOptions = diets.map(
      ({ '@id': value, name: label, variants, id }) => ({
        label,
        value,
        variants,
        id,
      })
    );

    const defaultDiet =
      dietOptions.find(diet => diet.value === dietIri) || dietOptions[0];

    const availableVariants = sortByPosition(defaultDiet.variants, true);

    const { value: selectedDiet, id: queryDiet } = defaultDiet;

    this.setState({ selectedDiet, queryDiet, availableVariants });
    return { selectedDiet, queryDiet, availableVariants };
  };

  setDefaultVariant = variantIri => {
    const { variants } = this.props;

    const variantOptions = variants.map(
      ({ '@id': value, name: label, id }) => ({ label, value, id })
    );

    const defaultVariant =
      variantOptions.find(variant => variant.value === variantIri) ||
      variantOptions[0];

    const { value: selectedVariant, id: queryVariant } = defaultVariant;
    this.setState({ selectedVariant, queryVariant });
    return { selectedVariant, queryVariant };
  };

  setDietFromUrl = () => {
    const { diets } = this.props;
    const dietOptions = diets.map(
      ({ '@id': value, name: label, variants, id }) => ({
        label,
        value,
        variants,
        id,
      })
    );
    const dietId = parseInt(this.props.match.params.diet);

    const dietFromQuery =
      dietOptions.find(diet => diet.id === dietId) || dietOptions[0];

    const availableVariants = sortByPosition(dietFromQuery.variants, true);

    const { value: selectedDiet, id: queryDiet } = dietFromQuery;
    //this.setState({ selectedDiet, queryDiet, availableVariants });
    return { selectedDiet, queryDiet, availableVariants };
  };

  setVariantFromUrl = () => {
    const { variants } = this.props;

    const variantOptions = variants.map(
      ({ '@id': value, name: label, id }) => ({ label, value, id })
    );

    const variantId = parseInt(this.props.match.params.variant);

    const variantFromQuery =
      variantOptions.find(variant => variant.id === variantId) ||
      variantOptions[0];

    const { value: selectedVariant, id: queryVariant } = variantFromQuery;
    //this.setState({ selectedVariant, queryVariant });
    return { selectedVariant, queryVariant };
  };

  resizeWindow = e => {
    this.setState({ daysToShow: getDaysToShow(window.innerWidth) });
  };

  getSlideOffset = (
    daysToShow = getDaysToShow(window.innerWidth),
    dayOffset = this.dayOffset
  ) => {
    switch (daysToShow) {
      case 1:
        return dayOffset;
      case 3:
      case 4:
        return dayOffset - 1;
      default:
        return 0;
    }
  };

  getMeals = ({ variant, diet, dateFrom, dateTo }) => {
    this.setState({ mealsByDays: [], isLoading: true });
    get('frontend/menu', {
      'date[after]': dateFrom,
      'date[before]': dateTo,
      draft: false,
      properties: ['date', 'variant', 'mealType', 'diet'],
      'properties[nameForClient]': 'dish',
      pagination: false,
      variant: variant,
      diet: diet,
    }).then(
      response => {
        const { disabledDays } = this.props;
        const data = response.data['hydra:member'];
        const formattedDisabledDays = disabledDays.map(date =>
          format(date, 'DDMMYY')
        );
        const sortedMeals = data
          .map(mealDay => ({
            ...mealDay,
            order: format(mealDay.date, 'DDMMYY'),
          }))
          .filter(mealDay => !formattedDisabledDays.includes(mealDay.order))
          .sort((a, b) => parseInt(a.order) - parseInt(b.order));

        this.setState({ mealsByDays: sortedMeals, isLoading: false });
      },
      () => this.setState({ isLoading: false })
    );
  };

  getAllMeals = () => {
    this.setState({ mealsByDays: [], isLoading: true });
    const startOfWeek = getStartOfWeek(new Date(), { weekStartsOn: 1 });
    const dateFrom = format(startOfWeek, 'YYYY-MM-DD');
    const dateTo = format(addDays(startOfWeek, 70), 'YYYY-MM-DD');

    get('frontend/menu', {
      'date[after]': dateFrom,
      'date[before]': dateTo,
      properties: ['date', 'variant', 'mealType', 'diet'],
      'properties[nameForClient]': 'dish',
      pagination: false,
    }).then(
      response => {
        const data = response.data['hydra:member'];

        this.setState({ mealsByDays: data, isLoading: false });
      },
      () => this.setState({ isLoading: false })
    );
  };

  onDietSelect = diet => {
    const { history, toLocalUrl, diets } = this.props;
    const selectedDiet = diets.find(el => el['@id'] === diet);
    const availableVariants = sortByPosition(
      diets.find(el => el['@id'] === diet).variants,
      true
    );

    const queryDiet = selectedDiet.id;
    const variant = availableVariants[0]['@id'];
    const selectedVariant = availableVariants[0];

    const query = { ...this.state.defaultQuery, variant, diet };

    const url = `/menu/${selectedDiet.id}/${selectedVariant.id}`;
    history.push(toLocalUrl(url));

    this.setState(
      {
        defaultQuery: query,
        selectedDiet: diet,
        availableVariants,
        queryDiet,
        selectedVariant: variant,
        isChangeVariantManual: false,
      },
      this.loadStateAndMeals
    );
  };

  onVariantSelect = variant => {
    const { queryDiet } = this.state;
    const { toLocalUrl, history } = this.props;
    const query = { ...this.state.defaultQuery, variant };

    const selectedVariant = this.props.variants.find(
      el => el['@id'] === variant
    );
    const url = `/menu/${queryDiet}/${selectedVariant.id}`;
    history.push(toLocalUrl(url));

    const queryVariant = selectedVariant.id;
    this.setState(
      {
        defaultQuery: query,
        queryVariant,
        selectedVariant: variant,
      },
      this.loadStateAndMeals
    );
  };

  setPosition = (currentSlide, maxSlides) => {
    const { daysToShow } = this.state;
    const atBeggining = currentSlide === 0;
    const reachedEnd = currentSlide + daysToShow >= maxSlides;
    this.setState({ atBeggining, reachedEnd });
  };

  goToOrder = () => {
    const {
      history,
      toLocalUrl,
      MenuPage: { mode },
    } = this.props;
    const { queryDiet, queryVariant } = this.state;

    const isAllDiets = mode === 'ALL_DIETS';

    const url = isAllDiets
      ? routeUrls.NEW_ORDER_FORM
      : `${routeUrls.NEW_ORDER_FORM}?diet=${queryDiet}&variant=${queryVariant}`;

    history.push(toLocalUrl(url));
  };

  setIsChangeVariantManual = isChangeVariantManual => {
    this.setState(prevState => ({ ...prevState, isChangeVariantManual }));
  };

  initialSlide = this.getSlideOffset();

  render() {
    const {
      variants,
      MenuPage: { mode },
    } = this.props;
    const {
      selectedDiet,
      selectedVariant,
      mealsByDays,
      availableVariants,
    } = this.state;
    if (variants.length === 0 && mealsByDays.length === 0) return null;

    const mappedVariants = variants.reduce(
      (prev, curr) => ({
        ...prev,
        [curr['@id']]: {
          label: curr.name,
          mealTypes: curr.mealTypes.reduce(
            (prev, curr) => ({ ...prev, [curr['@id']]: curr.name }),
            {}
          ),
        },
      }),
      {}
    );
    if (
      !mappedVariants[selectedVariant] &&
      (mode === 'WEEK_VIEW' || mode === 'DAY_VIEW')
    )
      return null;

    const { t } = this.props;

    return (
      <>
        <MenuBanner />

        <Container>
          <SelectsWrapper>
            <MenuTitle>{t('$*menu.title', 'Menu')}</MenuTitle>
          </SelectsWrapper>
          <PageDescriptionWrapper>
            <PageDescription />
          </PageDescriptionWrapper>
          {(mode === 'WEEK_VIEW' || mode === 'DAY_VIEW') && (
            <>
              <DietsPicker
                selectedDiet={selectedDiet}
                onDietSelect={this.onDietSelect}
              />
              <VariantsPicker
                availableVariants={availableVariants}
                selectedVariant={selectedVariant}
                onVariantSelect={this.onVariantSelect}
                isChangeVariantManual={this.state.isChangeVariantManual}
                setIsChangeVariantManual={this.setIsChangeVariantManual}
              />
            </>
          )}
          {mode === 'WEEK_VIEW' ? (
            <AllWeekComponent {...this.state} {...this.props} />
          ) : (
            <SingleDayComponent
              mealsByDays={mealsByDays}
              isLoading={this.state.isLoading}
            />
          )}

          <ButtonWrapper>
            <ButtonPrimary
              uppercased
              sizeMiddle
              weightBold
              onClick={this.goToOrder}
            >
              {t('$*menu.xxx', 'Zamów dietę')}
            </ButtonPrimary>
          </ButtonWrapper>
        </Container>
      </>
    );
  }
}

export default compose(
  withRouter,
  withLocalUrl,
  withTranslation(),
  connect(
    ({
      calendar: { includeSaturdays, includeSundays },
      app: {
        brand: {
          mainColor,
          menuBanner,
          monday,
          tuesday,
          wednesday,
          thursday,
          friday,
          saturday,
          sunday,
        },
        config: {
          modules: { MenuPage },
        },
      },
      orderForm: {
        orderConfig: { disabledDays },
      },
    }) => ({
      mainColor,
      menuBanner,
      disabledDays,
      MenuPage,
      daysConfig: {
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday: includeSaturdays ? saturday : 0,
        sunday: includeSundays ? sunday : 0,
      },
    }),
    null
  )
)(Menu);
