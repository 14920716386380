import styled, { css } from 'styled-components';

const ThumbRating = styled.i`
  &.fa-thumbs-up {
    color: ${props => props.theme.baseFontColor};
    border-color: ${props => props.isActive && props.theme.baseFontColor};

    &:hover {
      border-color: ${props => props.theme.baseFontColor};
    }
  }

  &.fa-thumbs-down {
    color: ${props => props.theme.colorRed};
    border-color: ${props => props.isActive && props.theme.colorRed};

    &:hover {
      border-color: ${props => props.theme.colorRed};
    }
  }

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;

      &:hover {
        border-color: transparent;
      }
    `};

  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 50%;
  padding: 6px;
  margin-left: 10px;
`;

export default ThumbRating;
