import React from 'react';
import { connect } from 'react-redux';
import Menu from './Menu';

const MenuWrapper = ({ variants, diets }) => {
  if (variants.length === 0 || diets.length === 0) return null;
  return <Menu variants={variants} diets={diets} />;
};

export default connect(
  ({
    orderForm: {
      orderConfig: { variants, diets },
    },
  }) => ({ variants, diets }),
  null
)(MenuWrapper);
