import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Field, FormSpy } from 'react-final-form';
import { Box, Flex } from '@rebass/grid';
import { prop } from 'ramda';
import equal from 'deep-equal';

import { ButtonLink } from 'styledComponents/elements/Button';
import { media } from 'styledComponents/helpers';

import GoogleMap from 'common/components/GoogleMap';

import {
  INPUT_IDS,
  useUserAddressesFormInputs,
} from 'views/AccountSettings/const/userAddressesFormInputs';
import { setFirstUserAddress } from 'common/components/UserAddresses/actions/userAddressesActions';
import { getDeliveryHours } from 'views/NewOrder/actions/orderFormActions';
import { buildMapQuery } from 'views/AccountSettings/services/mapHelpers';
import { transformDeliveryHours } from 'views/NewOrder/services/orderFormRepresenter';

import { getFormInput, getFormInputs } from 'common/services/formHelpers';
import { format } from 'date-fns';
import { useFormValidation } from 'common/services/formValidation';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectModuleConfigClientPanel } from 'selectors/appSelectors';

const DefaultAddressSpan = styled.span`
  font-weight: ${props => props.theme.fontWeight900};
  display: block;
  font-size: 24px;
  margin-bottom: 24px;
`;

const FormGrid = styled(Flex)`
  align-items: flex-start;

  > ${Box} {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  ${media.tabletDown`
  width: 100%;
    > ${Box} {
      width: 100%;

      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  `};
`;

const FormRow = styled(Flex)`
  width: 100%;

  > ${Box}:not(:last-child) {
    margin-right: 22px;
  }
`;

const UserAddressesForm = ({
  initialValues,
  deliveryAllowedFrom,
  deliveryHours,
  isBlank,
  hideSaveButton,
  submitting,
  pristine,
  invalid,
  values,
  token,
  useAutoSave,
  saveAddressToLS,
  deleteButton,
  setFirstUserAddress,
  userAddressesList,
  isLoading,
  getDeliveryHours,
}) => {
  const { t } = useTranslation();
  const { required } = useFormValidation();
  const { address } = initialValues;
  const { useAddressesWithLessFields } = useSelector(
    selectModuleConfigClientPanel
  );

  const {
    isValidating,
    USER_ADDRESSES_FORM_INPUTS,
  } = useUserAddressesFormInputs();

  const firstRowInputs = getFormInputs(
    [INPUT_IDS.CITY, INPUT_IDS.POSTAL_CODE],
    USER_ADDRESSES_FORM_INPUTS
  );

  const secondRowInputs = getFormInputs(
    [INPUT_IDS.STREET],
    USER_ADDRESSES_FORM_INPUTS
  );

  const miniFormSecondRowInputs = getFormInputs(
    [INPUT_IDS.ADDRESS_LINE1],
    USER_ADDRESSES_FORM_INPUTS
  );

  const miniFormThirdRowInputs = getFormInputs(
    [INPUT_IDS.ADDRESS_LINE2],
    USER_ADDRESSES_FORM_INPUTS
  );

  const thirdRowInputs = getFormInputs(
    [INPUT_IDS.HOUSE_UNIT_NUMBER, INPUT_IDS.HOUSE_NUMBER],
    USER_ADDRESSES_FORM_INPUTS
  );

  const fourthRowInputs = getFormInputs(
    [INPUT_IDS.STAIRCASE, INPUT_IDS.FLOOR],
    USER_ADDRESSES_FORM_INPUTS
  );
  const fifthRowInputs = getFormInputs(
    [INPUT_IDS.INTERCOM_CODE],
    USER_ADDRESSES_FORM_INPUTS
  );
  const deliveryHoursInput = getFormInput(
    INPUT_IDS.DELIVERY_HOURS,
    USER_ADDRESSES_FORM_INPUTS
  );
  const additionalInfoInput = getFormInput(
    INPUT_IDS.ADDITIONAL_INFO,
    USER_ADDRESSES_FORM_INPUTS
  );
  const setAsDefaultAddressInput = getFormInput(
    INPUT_IDS.SET_AS_DEFAULT,
    USER_ADDRESSES_FORM_INPUTS
  );

  useEffect(() => {
    if (address) {
      if (address.postCode && !address.deliveryHourOptions) {
        getDeliveryHours(address.postCode);
      }
    }
  }, [address, getDeliveryHours]);

  const showSetAsDefaultAddress =
    userAddressesList.length > 0 && !address.isDefault;
  const addressPostCodeDiffers =
    address && address.postCode !== values.address.postCode;
  const addressHasDeliveryHours =
    prop('deliveryHourOptions', address) && !addressPostCodeDiffers
      ? transformDeliveryHours(address.deliveryHourOptions).length > 0
      : values.address.postCode &&
        values.address.postCode.length === 6 &&
        deliveryHours.length > 0;
  const deliveryHoursOptions =
    prop('deliveryHourOptions', address) && !addressPostCodeDiffers
      ? transformDeliveryHours(address.deliveryHourOptions)
      : deliveryHours;

  const validateDeliveryHours = formValue => {
    if (!addressHasDeliveryHours) {
      return false;
    }
    const value = address?.selectedDeliveryHour
      ? address.selectedDeliveryHour
      : formValue;

    return required(value);
  };

  return (
    <Fragment>
      {useAutoSave && (
        <FormSpy
          subscription={{
            valid: true,
            dirty: true,
            values: true,
            initialValues: true,
          }}
          onChange={({ valid, dirty, values, initialValues }) => {
            if (
              !equal(values.address, initialValues.address) &&
              valid &&
              dirty
            ) {
              if (!token) {
                if (values.address.selectedDeliveryHour) {
                  values.address.deliveryHourFrom =
                    values.address.selectedDeliveryHour.hourFrom;
                  values.address.deliveryHourTo =
                    values.address.selectedDeliveryHour.hourTo;
                } else {
                  values.address.deliveryHourFrom = null;
                  values.address.deliveryHourTo = null;
                }
                saveAddressToLS(values.address);
              } else {
                setFirstUserAddress(values);
              }
            }
          }}
        />
      )}

      {!showSetAsDefaultAddress && (
        <DefaultAddressSpan>
          {t('$*userAddressesForm.defaultAddressLabel', '$$Adres domyślny')}
        </DefaultAddressSpan>
      )}

      {deleteButton && (
        <Flex justifyContent="flex-end">
          <Box>{deleteButton}</Box>
        </Flex>
      )}

      {/* NOTE: Commented out name field of user address, did not remove in case Grzegorz change his mind soon  */}
      <Flex flexDirection={['column', 'columns', 'row']}>
        <Box width={[1, 1, 1, 1 / 2]}>
          <FormGrid>
            {firstRowInputs.map(({ id, ...input }) => (
              <Box key={id} width={1 / 2}>
                <Field
                  disabled={address && address.containsActiveDiets}
                  isBig
                  autoComplete="none"
                  {...input}
                />
              </Box>
            ))}
          </FormGrid>

          {useAddressesWithLessFields && (
            <Fragment>
              <FormGrid>
                {miniFormSecondRowInputs.map(({ id, ...input }) => (
                  <Box key={id} width={1}>
                    <Field
                      disabled={address && address.containsActiveDiets}
                      isBig
                      autoComplete="none"
                      {...input}
                    />
                  </Box>
                ))}
              </FormGrid>
              <FormGrid>
                {miniFormThirdRowInputs.map(({ id, ...input }) => (
                  <Box key={id} width={1}>
                    <Field
                      disabled={address && address.containsActiveDiets}
                      isBig
                      autoComplete="none"
                      {...input}
                    />
                  </Box>
                ))}
              </FormGrid>
            </Fragment>
          )}

          {!useAddressesWithLessFields && (
            <Fragment>
              <FormGrid>
                {secondRowInputs.map(({ id, ...input }) => (
                  <Box key={id} width={1}>
                    <Field
                      disabled={address && address.containsActiveDiets}
                      isBig
                      autoComplete="none"
                      {...input}
                    />
                  </Box>
                ))}
              </FormGrid>
              <FormGrid>
                {thirdRowInputs.map(({ id, ...input }) => (
                  <Box key={id} width={1 / 2}>
                    <Field
                      disabled={
                        input.name === 'address.buildNumber' &&
                        address &&
                        address.containsActiveDiets
                      }
                      isBig
                      autoComplete="none"
                      {...input}
                    />
                  </Box>
                ))}
              </FormGrid>
              <FormGrid>
                {fourthRowInputs.map(({ id, ...input }) => (
                  <Box key={id} width={1 / 2}>
                    <Field isBig autoComplete="none" {...input} />
                  </Box>
                ))}
              </FormGrid>
              <FormGrid>
                {fifthRowInputs.map(({ id, ...input }) => (
                  <Box key={id} width={1}>
                    <Field isBig autoComplete="none" {...input} />
                  </Box>
                ))}
              </FormGrid>
            </Fragment>
          )}
          {addressHasDeliveryHours && (
            <FormRow>
              <Flex width={1} mb={20}>
                <Box width={1}>
                  <Field
                    isBig
                    autoComplete="none"
                    key={deliveryHoursInput.id}
                    address={address}
                    options={deliveryHoursOptions}
                    validate={props => validateDeliveryHours(props)}
                    isDisabled={
                      address?.containsActiveDiets
                        ? true
                        : prop('deliveryHourOptions', address)
                        ? !address.deliveryHourOptions.length &&
                          !deliveryHours.length
                        : !deliveryHours.length
                    }
                    {...deliveryHoursInput}
                    isRequired={addressHasDeliveryHours}
                  />
                </Box>
              </Flex>
            </FormRow>
          )}
          {!useAddressesWithLessFields && (
            <FormRow>
              <Field
                isBig
                autoComplete="none"
                key={additionalInfoInput.id}
                {...additionalInfoInput}
              />
            </FormRow>
          )}
        </Box>
        <GoogleMap src={buildMapQuery(values.address)} noMobile />
      </Flex>
      <Flex
        justifyContent={['center', 'center', 'space-between']}
        flexDirection={['column-reverse', 'column-reverse', 'row']}
        alignItems="center"
        flexWrap="wrap"
      >
        {!hideSaveButton && (
          <ButtonLink
            type="submit"
            uppercased
            sizeMiddle
            weightBold
            withSpinner
            isLoading={isLoading}
            disabled={
              submitting || pristine || invalid || isLoading || isValidating
            }
          >
            {isBlank
              ? t('$*userAddressesForm.saveCaption', '$$Zapisz')
              : t('$*userAddressesForm.updateCaption', '$$Aktualizuj zmiany')}
          </ButtonLink>
        )}
        {showSetAsDefaultAddress ? (
          <Field
            isBig
            isDisabled={userAddressesList.length === 1}
            key={setAsDefaultAddressInput.id}
            {...setAsDefaultAddressInput}
          >
            {setAsDefaultAddressInput.label}
          </Field>
        ) : null}
      </Flex>
    </Fragment>
  );
};

export default connect(
  ({
    orderForm: { deliveryHours, deliveryAllowedFrom },
    userAddresses: { list, isLoading },
  }) => ({
    deliveryHours,
    deliveryAllowedFrom,
    isLoading,
    userAddressesList: list,
  }),
  { setFirstUserAddress, getDeliveryHours }
)(UserAddressesForm);
