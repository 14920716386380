import styled from 'styled-components';

import { StyledFormRadioSecondary } from './FormRadio';
import { media } from 'styledComponents/helpers';

export const StyledDropdownSelectButton = styled.div`
  position: relative;
`;

StyledDropdownSelectButton.Button = styled.button`
  padding: 0 20px;

  [class*='fa-'] {
    margin-left: 20px;
  }

  ${media.tabletDown`
    width: 100%;
  `};
`;

StyledDropdownSelectButton.Dropdown = styled.div`
  position: absolute;
  top: 65px;
  right: 0;
  width: 300px;
  padding: 10px 0;
  background: ${props => props.theme.colorWhite};
  border-radius: ${props => props.theme.baseBorderRadius};
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
  z-index: 2;

  ${StyledFormRadioSecondary} {
    display: block;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  ${media.tabletDown`
    width: 100%;
  `};
`;
