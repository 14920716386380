import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Flex, Box } from '@rebass/grid';
import styled from 'styled-components';

import { fromPxToRem } from 'styledComponents/helpers';

import FormCheckbox from 'common/components/Form/FormCheckbox';

import { setUseMoneyBox, getOrderCost } from '../../actions/orderFormActions';

import { SUMMARY_ROW_LEFT_WIDTH, SUMMARY_ROW_RIGHT_WIDTH } from './const';

import { getUserData } from 'views/AccountSettings/actions/accountSettingsActions';
import { useTranslation } from 'react-i18next';

const StyledMoneyBoxLabel = styled.span`
  font-size: ${fromPxToRem('13px')};
`;

const StyledMoneyBoxPoints = styled.span`
  font-size: ${fromPxToRem('14px')};
  font-weight: ${props => props.theme.fontWeight900};
`;

const SummaryMoneyBox = ({
  token,
  points,
  moneyBox,
  shortName,
  getUserData,
  useMoneyBox,
  getOrderCost,
  setUseMoneyBox,
  pointsToCashRate,
  pluralNameDenominator,
  multinational,
}) => {
  const { t } = useTranslation();
  const { currencySymbol } = multinational;
  return (
    <Flex alignItems="center" justifyContent="space-between" flexWrap={'wrap'}>
      {token ? (
        <Fragment>
          <Box width={[1, 1, SUMMARY_ROW_LEFT_WIDTH]}>
            <Flex alignItems="center">
              <Box>
                {t('$*summaryMoneyBox.caption', {
                  defaultValue: `$$Uwzględnij {{points}} ze skarbonki`,
                  replace: {
                    points:
                      pluralNameDenominator ||
                      t('$*common.pointsLiteral', 'punkty'),
                  },
                })}
              </Box>

              <Box ml={15}>
                <FormCheckbox
                  componentStyle="switch"
                  input={{
                    checked: useMoneyBox,
                    onChange: () => {
                      token && getUserData();
                      setUseMoneyBox(!useMoneyBox);
                      getOrderCost();
                    },
                  }}
                />
              </Box>
            </Flex>
          </Box>

          <Box width={[1, 1, SUMMARY_ROW_RIGHT_WIDTH]}>
            <Flex
              mt={[25, 25, 0]}
              alignItems="center"
              justifyContent={['space-between']}
            >
              <Box>
                <StyledMoneyBoxLabel>
                  {t('$*summaryMoneyBox.label', '$$Status skarbonki')}:
                </StyledMoneyBoxLabel>
              </Box>
              <Box>
                <StyledMoneyBoxPoints>
                  {moneyBox}{' '}
                  {shortName || t('$*common.pointAbbreviation', '$$pkt')}/{' '}
                  {(moneyBox * pointsToCashRate).toFixed(2)} {currencySymbol}
                </StyledMoneyBoxPoints>
              </Box>
            </Flex>
          </Box>
        </Fragment>
      ) : (
        <span>
          {t('$*summaryMoneyBox.logInToAddPoints', {
            defaultValue: `Zaloguj się, aby dodać {{points}} ze skarbonki`,
            replace: {
              points:
                pluralNameDenominator ||
                t('$*common.pointsLiteral', 'Punkty'),
            },
          })}
        </span>
      )}
    </Flex>
  );
};

export default connect(
  ({
    auth: { token },
    app: {
      brand: {
        moneyBoxConfigurationNames: { shortName, pluralNameDenominator },
      },
      config: { multinational },
    },
    accountSettings: {
      userData: { moneyBox },
    },
    orderForm: {
      orderConfig: { pointsToCashRate },
      useMoneyBox,
    },
  }) => ({
    token,
    moneyBox,
    shortName,
    useMoneyBox,
    pointsToCashRate,
    pluralNameDenominator,
    multinational,
  }),
  { setUseMoneyBox, getOrderCost, getUserData }
)(SummaryMoneyBox);
