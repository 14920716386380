import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentTabName } from './actions/orderFormTabsActions';

export const useCurrentTabNames = name => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentTabName(name));
    return () => {
      dispatch(setCurrentTabName(null));
    };
  }, []);
};
