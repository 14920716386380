import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Flex, Box } from '@rebass/grid';
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
} from 'react-share';

import MessengerIcon from './Icons/MessengerIcon.png';

import { ContentWrapper } from 'styledComponents/blocks/ContentWrapper';
import { Paragraph } from 'styledComponents/elements/Typography';
import { fromPxToRem, media } from 'styledComponents/helpers';
import CustomShareIcon from './CustomShareIcon';
import { useTranslation } from 'react-i18next';
import { getUserData } from 'views/AccountSettings/actions/accountSettingsActions';

const StyledSectionTitle = styled.h3`
  padding-bottom: 20px;
  color: ${props => props.theme.baseFontColor};
  font-size: ${fromPxToRem('24px')};

  ${media.tabletDown`
    font-size: ${fromPxToRem('20px')};
    padding-bottom: 10px;
  `};
`;

const FacebookButtonWrapper = styled.div`
  position: absolute;
  top: 30px;
  right: 50px;
  cursor: pointer;

  ${media.tabletDown`
    position: static;
    text-align: center;
    width: 100%;
  `};
`;

const StyledDiscountCode = styled.span`
  color: ${props => props.theme.baseFontColor};
  font-weight: 900;
`;

const ShareDiscountCode = ({
  referenceCode,
  name,
  pluralNameDenominator,
  showFacebookCodeReference,
  showTwitterCodeReference,
  showLinkedinCodeReference,
  showWhatsappCodeReference,
  showMessengerCodeReference,
  referralProgramDescription,
  facebookCodeReferenceLink,
  twitterCodeReferenceLink,
  linkedinCodeReferenceLink,
  whatsappCodeReferenceLink,
  messengerCodeReferenceLink,
  facebookAppId,
  getUserData,
}) => {
  useEffect(() => {
    getUserData();
  }, []);

  const { t } = useTranslation();
  const handleMessengerReference = () => {
    const isMobile = window.mobilecheck();

    const refLinkDesktop = `https://www.facebook.com/dialog/send?app_id=${facebookAppId}&link=${messengerCodeReferenceLink}&redirect_uri=${messengerCodeReferenceLink}`;

    const refLinkMobile =
      'fb-messenger://share?link=' +
      encodeURIComponent(messengerCodeReferenceLink) +
      '&app_id=' +
      encodeURIComponent(facebookAppId);

    const refLink = isMobile ? refLinkMobile : refLinkDesktop;

    return window.open(refLink);
  };

  const publicUrl = window.location.origin;

  return (
    <ContentWrapper position="relative" padding="40px">
      <StyledSectionTitle>
        {t(
          '$*accountSettings.discountCodeSection.title',
          '$$Kod promocyjny dla przyjaciół'
        )}
      </StyledSectionTitle>
      <FacebookButtonWrapper>
        <Flex alignItems="center" flexWrap={'wrap'}>
          <Box mr="10px">
            {t(
              '$*accountSettings.discountCodeSection.shareLabel',
              '$$Udostępnij:'
            )}
          </Box>
          <Box mt="8px">
            <Flex>
              <Box>
                {showFacebookCodeReference && (
                  <FacebookShareButton url={facebookCodeReferenceLink}>
                    <FacebookIcon size={35} round={true} />
                  </FacebookShareButton>
                )}
              </Box>
              <Box ml="5px">
                {showTwitterCodeReference && (
                  <TwitterShareButton url={twitterCodeReferenceLink}>
                    <TwitterIcon size={35} round={true} />
                  </TwitterShareButton>
                )}
              </Box>
              <Box ml="5px">
                {showWhatsappCodeReference && (
                  <WhatsappShareButton url={whatsappCodeReferenceLink}>
                    <WhatsappIcon size={35} round={true} />
                  </WhatsappShareButton>
                )}
              </Box>
              <Box ml="5px">
                {showLinkedinCodeReference && (
                  <LinkedinShareButton url={linkedinCodeReferenceLink}>
                    <LinkedinIcon size={35} round={true} />
                  </LinkedinShareButton>
                )}
              </Box>
              <Box ml="5px">
                {true && (
                  <EmailShareButton
                    subject={t(
                      '$*accountSettings.discountCodeSection.emailTitle',
                      {
                        defaultValue: `$$ Kod rabatowy do {{name}}`,
                        replace: { name },
                      }
                    )}
                    body={t('$*discountCodeSection.emailBody', {
                      defaultValue: `$$ Hej! \n\n Podsyłam Ci kod rabatowy na dietę pudełkową z firmy {{name}}, z której korzystam. \n\n Użyj kodu {{referenceCode}} na stronie {{publicUrl}}. \n\n Masz możliwość wyboru dań i zbierania {{points}}, które możesz wymieniać na kolejne zniżki. \n\n Polecam!`,
                      replace: {
                        name,
                        referenceCode: referenceCode ? referenceCode.code : '',
                        points:
                          pluralNameDenominator ||
                          t('$*common.pointsLiteral', 'punkty'),
                        publicUrl,
                      },
                    })}
                    url={''}
                  >
                    <EmailIcon size={35} round={true} />
                  </EmailShareButton>
                )}
              </Box>
              <Box ml="5px" mr="5px">
                {showMessengerCodeReference && (
                  <CustomShareIcon
                    size={35}
                    src={MessengerIcon}
                    onClick={handleMessengerReference}
                    alt={'Facebook Messenger'}
                  />
                )}
              </Box>
            </Flex>
          </Box>
        </Flex>
      </FacebookButtonWrapper>
      <Paragraph>
        {t(
          '$*accountSettings.discountCodeSection.hereIsYourDiscountCode',
          '$$Oto twój kod promocyjny'
        )}
        : <StyledDiscountCode>{referenceCode?.code || ''}</StyledDiscountCode>
      </Paragraph>
      <Paragraph>
        {!!referralProgramDescription
          ? referralProgramDescription
          : t('$*accountSettings.discountCodeSection.description', {
              defaultValue: `Przekaż go swoim znajomym! Przy pierwszym zamówieniu dostaną od Ciebie wspaniały rabat, a Ty otrzymasz darmowe {{points}} do skarbonki! Kod należy wprowadzić w polu „Kod Rabatowy” podczas składania zamówienia.`,
              replace: {
                points:
                  pluralNameDenominator ||
                  t('$*common.pointsLiteral', 'punkty'),
              },
            })}
      </Paragraph>
    </ContentWrapper>
  );
};

const mapStateToProps = ({
  app: {
    brand: {
      name,
      moneyBoxConfigurationNames: { pluralNameDenominator, pluralNameGenitive },
      showFacebookCodeReference,
      showTwitterCodeReference,
      showLinkedinCodeReference,
      showWhatsappCodeReference,
      showMessengerCodeReference,
      facebookCodeReferenceLink,
      twitterCodeReferenceLink,
      linkedinCodeReferenceLink,
      whatsappCodeReferenceLink,
      messengerCodeReferenceLink,
    },
    config: {
      modules: {
        ConfigClientPanel: { referralProgramDescription },
        AuthServices,
      },
    },
  },
  accountSettings: {
    userData: { referenceCode },
  },
}) => ({
  name,
  facebookAppId: AuthServices.facebook.id,
  referenceCode,
  pluralNameGenitive,
  pluralNameDenominator,
  showFacebookCodeReference,
  showTwitterCodeReference,
  showLinkedinCodeReference,
  showWhatsappCodeReference,
  showMessengerCodeReference,
  facebookCodeReferenceLink,
  twitterCodeReferenceLink,
  linkedinCodeReferenceLink,
  whatsappCodeReferenceLink,
  messengerCodeReferenceLink,
  referralProgramDescription,
});

const mapDispatchToProps = { getUserData };

export default connect(mapStateToProps, mapDispatchToProps)(ShareDiscountCode);
