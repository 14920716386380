import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { Field } from 'react-final-form';
import styled from 'styled-components';

import FormRadioGroup from 'common/components/Form/FormRadioGroup';

import DeliveryTakeoutForm from './DeliveryTakeoutForm';
import DeliveryPickupForm from './DeliveryPickupForm';

import { setNextTabDisability } from '../OrderFormTabs/actions/orderFormTabsActions';
import { getUserAddressesFromLS } from 'common/components/UserAddresses/actions/userAddressesActions';
import { clearFormFields } from '../../actions/dietFormActions';

import FORM_FIELD_NAMES from '../../const/formFieldNames';
import DeliveryOptionLabel from './DeliveryOptionLabel';
import { useDeliveryOptions } from 'views/NewOrder/const/formConfig/deliveryOptions';
import FormRadio from 'common/components/Form/FormRadio';

const DeliveryFormViewWrapper = styled.div`
  margin-top: 50px;
`;

const getDeliveryFormView = type => {
  switch (type) {
    case '1':
      return DeliveryTakeoutForm;
    case '2':
      return DeliveryPickupForm;
    default:
      return null;
  }
};

const DeliveryOptions = ({
  values,
  currentFormId,
  deliveryToDoorEnabled,
  pickUpPointEnabled,
  clearFormFields,
}) => {
  const DeliveryFormView = getDeliveryFormView(values.deliveryType);
  const DELIVERY_OPTIONS = useDeliveryOptions();

  return (
    <Fragment>
      {deliveryToDoorEnabled && pickUpPointEnabled && (
        <FormRadioGroup flexDirection="row">
          {DELIVERY_OPTIONS.map(option => (
            <Fragment key={option.id}>
              <Field
                value={option.id.toString()}
                customLabel={<DeliveryOptionLabel option={option} />}
                name={FORM_FIELD_NAMES.DELIVERY_TYPE}
                type="radio"
                component={FormRadio}
                componentStyle="secondary"
                isCentered={true}
                hasAutoHeight={true}
                width="50%"
              />
            </Fragment>
          ))}
        </FormRadioGroup>
      )}

      <DeliveryFormViewWrapper>
        <DeliveryFormView values={values} canAddNewAddress />
      </DeliveryFormViewWrapper>
    </Fragment>
  );
};

export default compose(
  connect(
    ({
      dietForm: { currentFormId },
      app: {
        brand: { deliveryToDoorEnabled, pickUpPointEnabled },
      },
    }) => ({ currentFormId, deliveryToDoorEnabled, pickUpPointEnabled }),
    { clearFormFields, setNextTabDisability }
  ),
  lifecycle({
    async componentDidMount() {
      const { valid, values, setNextTabDisability } = this.props;

      const localStorageAddresses = getUserAddressesFromLS();
      const selectedAddress =
        localStorageAddresses.find(
          ({ id }) => id.toString() === values?.address
        ) || null;

      const invalid =
        values.deliveryType === '1'
          ? values?.address === '0' && selectedAddress === null
            ? true
            : !valid
          : (values?.pickUpPoint ?? null) === null;

      setNextTabDisability(invalid);
    },
    componentDidUpdate(prevProps) {
      const { valid, values, setNextTabDisability } = this.props;

      if (
        JSON.stringify(values) !== JSON.stringify(prevProps.values) ||
        prevProps.valid !== valid
      ) {
        const localStorageAddresses = getUserAddressesFromLS();
        const selectedAddress =
          localStorageAddresses.find(
            ({ id }) => id.toString() === values?.address
          ) || null;

        const invalid =
          values.deliveryType === '1'
            ? values?.address === '0' && selectedAddress === null
              ? true
              : !valid
            : (values?.pickUpPoint ?? null) === null;

        setNextTabDisability(invalid);
      }
    },
  })
)(DeliveryOptions);
