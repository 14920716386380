import { useTranslation } from 'react-i18next';

import isEmpty from 'lodash/isEmpty';
import { Flex, Box } from '@rebass/grid';
import { getIntegerIfIntegerOrFixed } from 'utils/common';

import OverflowContent from '../OverflowContent';

const DishSizeInfo = ({ allergens, components, calories = 0 }) => {
  const { t } = useTranslation();

  const componentsNames = !isEmpty(components) ? components.join(', ') : '-';
  const allergensNames = !isEmpty(allergens) ? allergens.join(', ') : '-';

  return (
    <Box fontSize={12}>
      <Box>
        <Flex mx={-1}>
          <Box px={1} width={1 / 3}>
            <OverflowContent title={t('$*components.dishTile.kcal', '$$Kcal')}>
              {`${t('$*components.dishTile.kcal', '$$Kcal')}:`}
            </OverflowContent>
          </Box>
          <Box px={1} width={2 / 3}>
            {isNaN(calories) ? 0 : getIntegerIfIntegerOrFixed(calories, 0)}
          </Box>
        </Flex>
      </Box>

      <Box pt={2}>
        <Flex mx={-1}>
          <Box px={1} width={1 / 3}>
            <OverflowContent
              title={t('$*components.dishTile.dishComposition', '$$Skład')}
              style={{ position: 'sticky', top: 0 }}
            >
              {`${t('$*components.dishTile.dishComposition', '$$Skład')}:`}
            </OverflowContent>
          </Box>
          <Box px={1} width={2 / 3}>
            <OverflowContent title={componentsNames} lineClamp={5}>
              {componentsNames}
            </OverflowContent>
          </Box>
        </Flex>
      </Box>

      <Box pt={2}>
        <Flex mx={-1}>
          <Box px={1} width={1 / 3}>
            <OverflowContent
              title={t('$*components.dishTile.allergens', '$$Alergeny')}
            >
              {`${t('$*components.dishTile.allergens', '$$Alergeny')}:`}
            </OverflowContent>
          </Box>
          <Box px={1} width={2 / 3}>
            <OverflowContent title={allergensNames} lineClamp={2}>
              {allergensNames}
            </OverflowContent>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default DishSizeInfo;
