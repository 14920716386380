import React, { Component, Fragment } from 'react';
import { Box, Flex } from '@rebass/grid';
import { isAfter } from 'date-fns';
import { prop } from 'ramda';

import routeUrls from 'routes/routeUrls';
import { mainTheme } from 'themes/main';

import { ContentWrapper } from 'styledComponents/blocks/ContentWrapper';
import { Heading } from 'styledComponents/elements/Typography';
import { ButtonPrimary, ButtonLink } from 'styledComponents/elements/Button';
import Spinner from 'styledComponents/elements/LoadingSpinner';
import { DisplayOnDesktop } from 'styledComponents/helpers';

import MenuPlanningLeftColumn from 'views/MenuPlanning/components/MenuPlanningLeftColumn';
import MenuPlanningRightColumn from 'views/MenuPlanning/components/MenuPlanningRightColumn';

import DeliveryDateChangeModal from 'views/MenuPlanning/components/Modal/DeliveryDateChangeModal';
import AddressChangeModal from 'views/MenuPlanning/components/Modal/AddressChangeModal';
import DietChangeModal from 'views/MenuPlanning/components/Modal/DietChangeModal';
import RateMenuModal from 'views/MenuPlanning/components/Modal/RateMenuModal';
import EcoboxActivationModal from 'views/MenuPlanning/components/Modal/EcoboxActivationModal';
import DiscountInfoModal from 'views/MenuPlanning/components/Modal/DiscountInfoModal';
import PointsReturnModal from 'views/MenuPlanning/components/Modal/PointsReturnModal';
import AddonsChangeModal from 'views/MenuPlanning/components/Modal/AddonsChangeModal';
import NewPriceConfirmModal from 'views/MenuPlanning/components/Modal/NewPriceConfirmModal/NewPriceConfirmModal';
import PreparingChangesModal from 'views/MenuPlanning/components/Modal/PreparingChangesModal';

import MENU_PLANNING_MODALS from 'views/MenuPlanning/const/modal';
import { checkDietMissingStatus } from 'views/MenuPlanning/services/dietHelpers';

import { DAY_STATUSES } from 'common/components/BigCalendar/const/dayStatuses';
import { TODAY } from 'common/shared/weekdays';

class MenuPlanningContentForm extends Component {
  render() {
    const {
      day,
      diets,
      variant,
      variants,
      meals,
      isPremium,
      isLoading,
      bag,
      selectedDay,
      history,
      allowChangeCalorific,
      allowChangeVariant,
      allowChangeDiet,
      t,
    } = this.props;
    const { additionalPrice, returnPoints, modalOpened } = this.props;

    const dayStatus = prop('state', bag);
    const hasMeals = meals.length > 0 && dayStatus !== DAY_STATUSES.DISABLED;
    const dietMissing = !isLoading && checkDietMissingStatus(dayStatus);

    const isAfterToday = isAfter(new Date(day), TODAY);

    if (isLoading > 0) {
      return (
        <ContentWrapper paddingTop="80px">
          <Flex justifyContent="center" alignItems="center">
            <Box width={1}>
              <Heading textAlign="center">
                <Spinner />
              </Heading>
            </Box>
          </Flex>
        </ContentWrapper>
      );
    }

    if (dietMissing) {
      // Display appropriate message about day without diet based on day status or availability of meals for selected day
      const isDayWithoutDietAndAfterToday =
        //dayStatus === DAY_STATUSES.ADD_DIET || (!hasMeals && isAfterToday);
        dayStatus === DAY_STATUSES.NOT_DIET_CAN_PLACE_ORDER ||
        (!hasMeals && isAfterToday);

      const message = isDayWithoutDietAndAfterToday
        ? t('$*userDiet.noDiet.noPlannedOrders', 'Brak zaplanowanych zamówień')
        : t(
            '$*userDiet.noDiet.noDietForThatDay',
            'Nie miałeś diety w tym dniu'
          );
      const buttonText = isDayWithoutDietAndAfterToday
        ? t(
            '$*userDiet.noDiet.createDietOrderNow',
            '$$Złóż zamówienie już teraz'
          )
        : t('$*userDiet.noDiet.buyNow', 'Kup teraz');

      return (
        <ContentWrapper>
          <Flex justifyContent="center">
            <Box p={mainTheme.mobileContentWrapperPadding}>
              <Flex>
                <Box
                  m="auto"
                  pt={[
                    mainTheme.mobileContentWrapperPadding,
                    mainTheme.mobileContentWrapperPadding,
                    50,
                  ]}
                >
                  <Heading textAlign="center">{message}</Heading>
                </Box>
              </Flex>
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                m="auto"
                pb={[
                  mainTheme.mobileContentWrapperPadding,
                  mainTheme.mobileContentWrapperPadding,
                  50,
                ]}
              >
                <Box>
                  <ButtonPrimary
                    type="button"
                    uppercased
                    sizeMiddle
                    weightBold
                    onClick={() => history.push(routeUrls.NEW_ORDER_FORM)}
                    fullWidthOnMobile
                    width={
                      dayStatus === DAY_STATUSES.NOT_DIET_CAN_PLACE_ORDER //@todo sprawdizc czy nie powinno byc jednak ADD_DIET
                        ? '180px'
                        : '240px'
                    }
                    padding="0 15px"
                  >
                    {buttonText}
                  </ButtonPrimary>
                </Box>
                <DisplayOnDesktop>
                  <Box>
                    <ButtonLink
                      type="button"
                      uppercased
                      sizeMiddle
                      weightBold
                      onClick={() => history.push(routeUrls.USER_DIET)}
                      fullWidthOnMobile
                    >
                      {t(
                        '$*menuPlanning.backToCalendarCaption',
                        '$$Wróć do kalendarza'
                      )}
                    </ButtonLink>
                  </Box>
                </DisplayOnDesktop>
              </Flex>
            </Box>
          </Flex>
        </ContentWrapper>
      );
    }

    const isSubscriptionSuspended =
      this.props.calendar?.days?.[this.props.selectedDay]?.newState ===
      DAY_STATUSES.SUBSCRIPTION_SUSPENDED; //@todo newState

    const isBag = entity =>
      entity?.id && entity['@id'].indexOf('bags/') != -1 ? true : false;
    const isIntent = entity =>
      entity?.id && entity['@id'].indexOf('subscription-intents/') != -1
        ? true
        : false;

    return (
      <Fragment>
        <ContentWrapper noPaddingMobile>
          <Flex flexWrap="wrap">
            {!isSubscriptionSuspended && (
              <Box width={[1, 1, 3 / 10]}>
                <MenuPlanningLeftColumn
                  getButtonAction={this.props.getButtonAction}
                  getDescription={this.props.getDescription(t)}
                  handleModalOpen={this.props.handleModalOpenWithPriceRequest}
                  selectedDay={this.props.selectedDay}
                  calendar={this.props.calendar}
                />
              </Box>
            )}
            <Box width={[1, 1, isSubscriptionSuspended ? 1 : 7 / 10]}>
              <MenuPlanningRightColumn
                bag={bag}
                isPremium={isPremium}
                handleMenuPlanningContentFormModal={this.props.handleModalOpen}
                setDietsInWhichMealExists={this.props.setDietsInWhichMealExists}
                getButtonAction={this.props.getButtonAction}
                isSubscriptionSuspended={isSubscriptionSuspended}
                onBeforeBagSave={this.props.onBeforeBagSave}
                t={t}
              />
            </Box>
          </Flex>
        </ContentWrapper>
        <DeliveryDateChangeModal
          id={MENU_PLANNING_MODALS.DELIVERY_DATE_CHANGE_MODAL}
          isOpened={modalOpened}
          toggleModal={() => this.props.handleModalOpen(null)}
          onBeforeSave={params => this.props.onBeforeBagSave(params)}
        />
        {modalOpened === MENU_PLANNING_MODALS.ADDRESS_CHANGE_MODAL && (
          <AddressChangeModal
            id={MENU_PLANNING_MODALS.ADDRESS_CHANGE_MODAL}
            isOpened={modalOpened}
            bag={bag}
            toggleModal={() => this.props.handleModalOpen(null)}
            onBeforeSave={params => this.props.onBeforeBagSave(params)}
          />
        )}
        <DiscountInfoModal
          id={MENU_PLANNING_MODALS.DISCOUNT_INFO_MODAL}
          isOpened={modalOpened}
          toggleModal={() => this.props.handleModalOpen(null)}
          additionalPrice={additionalPrice}
          onSave={additionalParams => this.props.onBagSave(additionalParams)}
        />
        <PointsReturnModal
          id={MENU_PLANNING_MODALS.POINTS_RETURN_MODAL}
          isOpened={modalOpened}
          toggleModal={() => this.props.handleModalOpen(null)}
          additionalPrice={additionalPrice}
          returnPoints={returnPoints}
          onSave={() => this.props.onBagSave()}
        />
        <EcoboxActivationModal
          id={MENU_PLANNING_MODALS.ECO_BOX_MODAL}
          isOpened={modalOpened}
          toggleModal={() => this.props.handleModalOpen(null)}
          additionalPrice={additionalPrice}
          premiumPrice={this.props.upgradePrice}
          returnPoints={returnPoints}
          onSave={() => this.props.onBagSave()}
          onBeforeBagSave={this.props.onBeforeBagSave}
          handlePremiumStatusChange={this.props.handleEcobox}
        />

        {modalOpened === MENU_PLANNING_MODALS.DIET_CHANGE_MODAL && (
          <DietChangeModal
            id={MENU_PLANNING_MODALS.DIET_CHANGE_MODAL}
            isBagView={isBag(bag)}
            isOpened={modalOpened}
            dietsInWhichMealExists={this.props.dietsInWhichMealExists}
            clearDietsInWhichMealExists={this.props.clearDietsInWhichMealExists}
            toggleModal={() => this.props.handleModalOpen(null)}
            onBeforeSave={params => this.props.onBeforeBagSave(params)}
            currentDietId={bag.diet.id}
            diets={diets}
            allowChangeDiet={allowChangeDiet}
            allowChangeCalorific={allowChangeCalorific}
            allowChangeVariant={allowChangeVariant}
            currentCalorificId={bag.calorific.id}
            variants={variants}
            currentVariantId={bag.variant.id}
            calorifics={
              variants.length
                ? variants.find(element => element.id === variant)?.calories
                : []
            }
          />
        )}
        <RateMenuModal
          id={MENU_PLANNING_MODALS.RATE_MENU_MODAL}
          isOpened={modalOpened}
          toggleModal={() => this.props.handleModalOpen(null)}
          date={selectedDay}
          bag={bag}
        />
        <AddonsChangeModal
          id={MENU_PLANNING_MODALS.ADDONS_CHANGE_MODAL}
          isOpened={modalOpened}
          toggleModal={() => this.props.handleModalOpen(null)}
          onBeforeSave={this.props.onBeforeBagSave}
          isIntent={isIntent(bag)}
        />
        <NewPriceConfirmModal
          id={MENU_PLANNING_MODALS.PRICE_CONFIRM_MODAL}
          isOpened={modalOpened}
          toggleModal={() => this.props.handleModalOpen(null)}
          newParams={this.props.newParams}
          newPriceConfirmData={this.props.newPriceConfirmData}
          newPriceRequestData={this.props.newPriceRequestData}
          onBeforeSave={this.props.onBeforeBagSave}
        />
        {modalOpened === MENU_PLANNING_MODALS.PREPARING_CHANGES && (
          <PreparingChangesModal
            id={MENU_PLANNING_MODALS.PREPARING_CHANGES}
            isOpened={modalOpened}
            toggleModal={() => this.props.handleModalOpen(null)}
          />
        )}
      </Fragment>
    );
  }
}

export default MenuPlanningContentForm;
