import {
  FETCH_USER_DATA_REQUEST,
  FETCH_USER_DATA_FAILURE,
  FETCH_USER_DATA_SUCCESS,
  UPDATE_USER_DATA_REQUEST,
  UPDATE_USER_DATA_FAILURE,
  UPDATE_USER_DATA_SUCCESS,
  CLEAR_USER_DATA,
  FETCH_USER_MONEYBOX_SUCCESS,
} from '../actions/const';

const INITIAL_STATE = {
  userData: {},
  isLoading: false,
  error: null,
};

const accountSettingsReducer = (
  state = INITIAL_STATE,
  { type, userData, moneyBox, error }
) => {
  switch (type) {
    case FETCH_USER_DATA_REQUEST:
    case UPDATE_USER_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_USER_MONEYBOX_SUCCESS:
      return {
        ...state,
        userData: {
          ...state.userData,
          moneyBox: moneyBox,
        },
      };
    case FETCH_USER_DATA_SUCCESS:
    case UPDATE_USER_DATA_SUCCESS:
      return {
        ...state,
        userData,
        isLoading: false,
      };
    case FETCH_USER_DATA_FAILURE:
    case UPDATE_USER_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error,
      };
    case CLEAR_USER_DATA:
      return {
        ...state,
        userData: {},
      };
    default:
      return state;
  }
};

export default accountSettingsReducer;
