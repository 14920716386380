import React from 'react';

import { ButtonLinkRed } from 'styledComponents/elements/Button';

const AddressDeleteButton = ({ label, isVisible = true, action }) =>
  isVisible && (
    <ButtonLinkRed
      type="button"
      uppercased
      sizeMiddle
      weightBold
      onClick={() => action()}
    >
      {label}
    </ButtonLinkRed>
  );

export default AddressDeleteButton;
