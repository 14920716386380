import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const Global = createGlobalStyle`
  ${reset}

  @keyframes tremble {
    0% { transform: translate(0px,0px) rotate(0deg) scale(1) }
    3.33333% { transform: translate(-0.18923px,1.45485px) rotate(0deg) scale(1) }
    6.66667% { transform: translate(-0.84296px,-1.32524px) rotate(0deg) scale(1) }
    10% { transform: translate(0.67971px,1.00422px) rotate(0deg) scale(1) }
    13.33333% { transform: translate(-0.5056px,0.83616px) rotate(0deg) scale(1) }
    16.66667% { transform: translate(1.31368px,-0.51401px) rotate(0deg) scale(1) }
    20% { transform: translate(-1.21184px,1.49193px) rotate(0deg) scale(1) }
    23.33333% { transform: translate(1.09065px,-0.21259px) rotate(0deg) scale(1) }
    26.66667% { transform: translate(-1.49916px,0.56159px) rotate(0deg) scale(1) }
    30% { transform: translate(1.48086px,1.21228px) rotate(0deg) scale(1) }
    33.33333% { transform: translate(-1.43889px,-1.152px) rotate(0deg) scale(1) }
    36.66667% { transform: translate(1.35914px,1.34835px) rotate(0deg) scale(1) }
    40% { transform: translate(-1.42834px,0.3091px) rotate(0deg) scale(1) }
    43.33333% { transform: translate(1.47472px,-1.49889px) rotate(0deg) scale(1) }
    46.66667% { transform: translate(-0.92402px,1.4416px) rotate(0deg) scale(1) }
    50% { transform: translate(1.0657px,-0.75306px) rotate(0deg) scale(1) }
    53.33333% { transform: translate(-1.19035px,-1.07484px) rotate(0deg) scale(1) }
    56.66667% { transform: translate(0.28828px,0.79337px) rotate(0deg) scale(1) }
    60% { transform: translate(-0.47167px,-1.42789px) rotate(0deg) scale(1) }
    63.33333% { transform: translate(0.64753px,-0.09795px) rotate(0deg) scale(1) }
    66.66667% { transform: translate(0.41006px,-0.26292px) rotate(0deg) scale(1) }
    70% { transform: translate(-0.22477px,-1.3683px) rotate(0deg) scale(1) }
    73.33333% { transform: translate(0.03588px,0.92931px) rotate(0deg) scale(1) }
    76.66667% { transform: translate(-1.01937px,-1.18398px) rotate(0deg) scale(1) }
    80% { transform: translate(0.8724px,-0.60494px) rotate(0deg) scale(1) }
    83.33333% { transform: translate(-0.71151px,1.4786px) rotate(0deg) scale(1) }
    86.66667% { transform: translate(1.40734px,-1.49607px) rotate(0deg) scale(1) }
    90% { transform: translate(-1.33062px,0.46957px) rotate(0deg) scale(1) }
    93.33333% { transform: translate(1.23264px,1.26738px) rotate(0deg) scale(1) }
    96.66667% { transform: translate(-1.48975px,-1.03867px) rotate(0deg) scale(1) }
    100% { transform: translate(0,0) rotate(0) scale(1) }
  }

  * {
    box-sizing: border-box;
  }

  html {
  overflow-x: hidden;
  }

  body {
    font-size: ${props => props.theme.baseFontSize};
    font-family: ${({ font }) => font};
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.3px;
    line-height: 1.6;
    overflow-x: hidden;
  }

  body, html {
    position: relative;
    height: 100%;
    background-color: ${props => props.theme.bodyBgColor};
    color: ${props => props.theme.bodyColor};
    font-weight: ${props => props.theme.fontWeight300};
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.baseFontColor}
  }

  button {
    font-family: inherit;
  }

  .swal2-title {
    color: #fff;
    font-size: 14px;
  }

  .swal2-popup.swal2-toast.swal2-icon-success {
    background: #4caf50
  }

  .swal2-popup.swal2-toast.swal2-icon-error {
    background: #f44336
  }

  .swal2-popup.swal2-toast .swal2-icon {
    width: 22px;
    min-width: 22px;
    height: 22px;
    border: none;

    svg {
      width: 100%;
      fill: #fff;
    }
  }


  .masonry-grid {
    display: flex;
    width: auto;
    margin: 0 -10px -10px -10px;
  }

  .masonry-grid-column {
    padding: 10px;
    background-clip: padding-box;
  }

  /* Style your items */
  .masonry-grid-column > div {
    /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 20px
  }
`;

export default Global;
