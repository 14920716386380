import React from 'react';
import styled from 'styled-components';
import { Box } from '@rebass/grid';

import { media } from 'styledComponents/helpers';
import HorizontalLine from 'styledComponents/elements/HorizontalLine';

import MENU_PLANNING_MODALS from '../../const/modal';

import {
  BackToCalendarLink,
  DateOfDelivery,
  AddressOfDelivery,
  UserDiet,
  Addons,
  Packaging,
} from './components';
import RenewDietBadge from '../RenewDietBadge';

const Wrapper = styled.div`
  border-right: 1px solid ${props => props.theme.colorIceBlue2};
  height: 100%;

  ${media.tabletDown`
    border-right: none;
  `};
`;

const BackToCalendarWrapper = styled.div`
  margin-bottom: 24px;
`;

const SuspendSubscriptionWrapper = styled.div`
  margin-bottom: 2rem;
`;

const Index = ({
  getButtonAction,
  handleModalOpen,
  isSubscriptionSuspended,
  calendar,
  selectedDay,
}) => {
  const selectedDayOnCalendar = calendar?.days?.[selectedDay];
  const isSubscriptionIntent =
    selectedDayOnCalendar?.['@type'] === 'SubscriptionIntent';

  return (
    <Wrapper>
      <Box px={[0, 40]} py={[20, 32]} width={1}>
        <BackToCalendarWrapper>
          <BackToCalendarLink />
        </BackToCalendarWrapper>
        {isSubscriptionIntent && (
          <SuspendSubscriptionWrapper>
            <RenewDietBadge
              suspend={isSubscriptionSuspended}
              onClickAction={getButtonAction(5)}
            />
          </SuspendSubscriptionWrapper>
        )}
        <DateOfDelivery action={getButtonAction} />
        <AddressOfDelivery action={getButtonAction(1)} />
        <UserDiet action={getButtonAction(3)} />
        <Addons action={getButtonAction(4)} />
        <Packaging
          action={() => handleModalOpen(MENU_PLANNING_MODALS.ECO_BOX_MODAL)}
        />
      </Box>
      <HorizontalLine />
    </Wrapper>
  );
};

export default Index;
