const MODALS = {
  ADDRESS_CHANGE_MODAL: 'Modal/MenuPlanning/AddressChangeModal',
  DISCOUNT_INFO_MODAL: 'Modal/MenuPlanning/DiscountInfoModal',
  POINTS_RETURN_MODAL: 'Modal/MenuPlanning/PointsReturnModal',
  DELIVERY_DATE_CHANGE_MODAL: 'Modal/MenuPlanning/DeliveryDateChangeModal',
  PREMIUM_ACTIVATION_MODAL: 'Modal/MenuPlanning/PremiumActivationModal',
  DIET_CHANGE_MODAL: 'Modal/MenuPlanning/DietChangeModal',
  RATE_MENU_MODAL: 'Modal/MenuPlanning/RateMenuModal',
  ECO_BOX_MODAL: 'Modal/MenuPlanning/EcoBoxModal',
  ADDONS_CHANGE_MODAL: 'Modal/MenuPlanning/AddonsChangeModal',
  PRICE_CONFIRM_MODAL: 'Modal/MenuPlanning/PriceConfirmModal',
  PREPARING_CHANGES: 'Modal/MenuPlanning/PreparingChangesModal',
};

export default MODALS;
