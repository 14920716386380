import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import HeadingSection from 'layouts/components/HeadingSection';

import ShareDiscountCode from 'views/AccountSettings/components/ShareDiscountCode';
import { useTranslation } from 'react-i18next';

const ShareProgram = ({ showRefererCode }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <HeadingSection
        title={t('$*shareProgram.title', 'Program poleceń')}
        style={{ fontSize: '50px' }}
      />
      {showRefererCode && <ShareDiscountCode />}
    </Fragment>
  );
};

export default connect(
  ({
    app: {
      brand: { showRefererCode },
    },
  }) => ({
    showRefererCode,
  }),
  null
)(ShareProgram);
