import produce from 'immer';
import { Fragment } from 'react';
import {
  ButtonLinkGrey,
  ButtonLinkRed,
} from 'styledComponents/elements/Button';
import StyledBasket from 'styledComponents/elements/NewOrder/Basket';

export const SUMMARY_ROW_LEFT_WIDTH = '70%';
export const SUMMARY_ROW_RIGHT_WIDTH = '30%';

const discountTranslations = {
  DIET_LENGTH: '$*discount.dietLength.tooltip',
  SAME_ADDRESS: '$*discount.sameAddress.tooltip',
  PICK_UP_POINT: '$*discount.pickUpPoint.tooltip',
  CODE: '$*discount.code.tooltip',
};

const renderTooltip = ({ dietCost, t, enabledCodeDescription = true }) => {
  return (
    <>
      <div>{t('$*discount.grantedDiscounts')}:</div>
      {Object.entries(dietCost.usedDiscounts).map(([key, { discount }]) => {
        return discount > 0 ? (
          <Fragment key={key}>
            <StyledBasket.ItemEntry style={{ minWidth: '200px' }}>
              <span>{t(discountTranslations[key])}</span>

              <StyledBasket.ItemEntryNewPrice>
                {`-${discount.toFixed(2)} zł`}
              </StyledBasket.ItemEntryNewPrice>
            </StyledBasket.ItemEntry>
            {dietCost.subscription && key === 'CODE' && enabledCodeDescription && (
              <>
                <div style={{ textAlign: 'left', width: '100%' }}>
                  {t('$*discount.code.subscriptionTooltip')}
                </div>
              </>
            )}
          </Fragment>
        ) : null;
      })}
    </>
  );
};

export const SUMMARY_ITEM_BUTTONS_CONFIG = t => ({
  edit: {
    component: ButtonLinkGrey,
    label: t('$*basket.labelEdit', '$$Edytuj'),
    sizeMiddle: true,
    autoHeight: true,
  },
  remove: {
    component: ButtonLinkRed,
    label: t('$*basket.labelDelete', '$$Usuń'),
    sizeMiddle: true,
    autoHeight: true,
  },
});

export const PRICES_CONFIG = ({
  t,
  paymentMode,
  dietCost,
  addons,
  deliveryType,
  dietLength,
}) => {
  const {
    addonsForAnotherDaysWithDiscount,
    addonsForAnotherDays,
    addonsForFirstDay,
    delivery,
    diet,
    subscription = null,
    subscriptionDiscountLimit = 0,
  } = dietCost;

  const getTypeAddons = addonType =>
    addons.filter(({ type }) => type === addonType);
  const bagAddons = getTypeAddons('FOR_BAG');
  const dietAddons = getTypeAddons('FOR_DIET');
  const hasBagAddons = bagAddons.length > 0;
  const hasDietAddons = dietAddons.length > 0;

  const calculatePrice = prices =>
    prices.reduce((price, acc) => (acc += price));

  const partialPrices = {
    diet: {
      label: t('$*basket.labelDiet', '$$Dieta'),
      showBeforeDiscount:
        paymentMode === '1' ? diet.discount > 0 : diet.avgDiscount > 0,
      beforeDiscount:
        paymentMode === '1' ? diet.beforeDiscount : diet.avgBeforeDiscount,
      subscriptionBeforeDiscount: subscription?.beforeDiscount,
      afterDiscount:
        paymentMode === '1' ? diet.afterDiscount : diet.avgAfterDiscount,
      subscriptionAfterDiscount: subscription?.afterDiscount,
      itemEntryProps: { isSection: true },
      tooltip: {
        isShow: false, // usedDiscounts?.DIET_LENGTH?.discount > 0,
        text: t('$*discount.dietLength', {
          price: '-0.00',
          currencySymbol: 'zł',
        }),
      },
    },
    delivery: {
      label:
        deliveryType === '1'
          ? t('$*basket.labelDelivery', '$$Dostawa')
          : t('$*basket.labelPickupPoint', '$$Odbiór w punkcie'),
      showBeforeDiscount: delivery.discount > 0,
      beforeDiscount: delivery.beforeDiscount,
      afterDiscount: delivery.afterDiscount,
      itemEntryProps: { isSection: true },
      tooltip: {
        isShow: false,
        // deliveryType === '1'
        //   ? usedDiscounts?.SAME_ADDRESS?.discount > 0
        //   : usedDiscounts?.PICK_UP_POINT?.discount > 0,
        text:
          deliveryType === '1'
            ? t('$*discount.sameAddress', {
                price: '-0.00',
                currencySymbol: 'zł',
              })
            : t('$*discount.pickUpPoint', {
                price: '-0.00',
                currencySymbol: 'zł',
              }),
      },
    },
    addonsAnotherDays: {
      label: t('$*basket.labelAddons', '$$Dodatki'),
      showBeforeDiscount: !!subscription
        ? false
        : addonsForAnotherDays.discount > 0,
      beforeDiscount: addonsForAnotherDays.beforeDiscount,
      afterDiscount: !!subscription
        ? subscription?.priceParts?.ADDON || 0
        : addonsForAnotherDays.afterDiscount,
      itemEntryProps: { isSection: true },
    },
    ...(addonsForAnotherDaysWithDiscount
      ? {
          addonsAnotherLimitDays: {
            label: t('$*basket.labelAddons', '$$Dodatki'),
            showBeforeDiscount: addonsForAnotherDaysWithDiscount.discount > 0,
            beforeDiscount: addonsForAnotherDaysWithDiscount.beforeDiscount,
            afterDiscount: addonsForAnotherDaysWithDiscount.afterDiscount,
            itemEntryProps: { isSection: true },
          },
        }
      : {}),
  };

  const addonsForAnotherDaysHasDiscount =
    addonsForAnotherDays.discount > 0 ?? false;
  const addonsForFirstDayHasDiscount = addonsForFirstDay.discount > 0 ?? false;

  const prices = produce([], draftState => {
    const hasAddons = addons.length !== 0;
    const isOneTimePayment = paymentMode === '2';

    if (isOneTimePayment) {
      draftState.push({
        key: 'totalPrice',
        label: t('$*basket.labelDietTotalPrice', '$$Cena łączna'),
        showBeforeDiscount: dietCost.priceDiscount > 0,
        beforeDiscount: dietCost.priceBeforeDiscount,
        afterDiscount: dietCost.priceAfterDiscount,
        itemEntryProps: { isGroupHeader: true },
        tooltip: {
          id: 'priceDiscountItems',
          isShow: dietCost.priceDiscount > 0,
          text: renderTooltip({ dietCost, t }),
        },
      });
    }

    const hasSubscriptionLimitWithoutDietAddons =
      subscriptionDiscountLimit > 0 && !hasDietAddons;

    const priceFirstDay = {
      key: 'priceFirstDay',
      label: hasSubscriptionLimitWithoutDietAddons
        ? t('$*basket.labelPriceFirstDay.subscriptionLimit', {
            defaultValue: '$$Cena za pierwsze dni (x{{count}})',
            replace: {
              count: subscriptionDiscountLimit + 1,
            },
          })
        : t('$*basket.labelPriceFirstDay', '$$Cena za pierwszy dzień'),
      showBeforeDiscount:
        calculatePrice([
          diet.discount,
          delivery.discount,
          addonsForFirstDay.discount,
        ]) > 0,
      beforeDiscount: calculatePrice([
        diet.beforeDiscount,
        delivery.beforeDiscount,
        addonsForFirstDay.beforeDiscount,
      ]),
      afterDiscount: calculatePrice([
        diet.afterDiscount,
        delivery.afterDiscount,
        addonsForFirstDay.afterDiscount,
      ]),
      itemEntryProps: { isGroupHeader: true },
      tooltip: {
        id: 'priceDiscountItems',
        isShow:
          calculatePrice([
            diet.discount,
            delivery.discount,
            addonsForFirstDay.discount,
          ]) > 0,
        text: renderTooltip({
          dietCost,
          t,
          enabledCodeDescription: false,
        }),
      },
      sections: [
        {
          key: 'priceFirstDayDiet',
          ...partialPrices.diet,
          tooltip: {
            ...partialPrices.diet.tooltip,
            id: 'priceFirstDayDietTooltip',
          },
        },
        {
          key: 'priceFirstDayAddons',
          label: t('$*basket.labelAddons', '$$Dodatki'),
          showBeforeDiscount: addonsForFirstDay.discount > 0,
          beforeDiscount: addonsForFirstDay.beforeDiscount,
          afterDiscount: addonsForFirstDay.afterDiscount,
          tooltip: {
            id: 'priceFirstDayAddonsTooltip',
            isShow: false, // addonsForFirstDay.discount > 0,
            text: 'addonsForFirstDay.discount > 0',
          },
          items: addons,
        },
        {
          key: 'priceFirstDayDelivery',
          ...partialPrices.delivery,
          tooltip: {
            ...partialPrices.delivery.tooltip,
            id: 'priceFirstDayDeliveryTooltip',
          },
        },
      ],
    };

    const subscriptionPriceOtherDays = {
      key: 'priceOtherDays',
      label: t('$*basket.labelPriceOtherDays', '$$Cena za każdy kolejny dzień'),
      showBeforeDiscount: subscription?.discount > 0,
      beforeDiscount: subscription?.beforeDiscount,
      afterDiscount: subscription?.afterDiscount,
      itemEntryProps: { isGroupHeader: true },
      tooltip: {
        id: 'priceFirstDayAddonsTooltip',
        isShow: subscription?.beforeDiscount > subscription?.afterDiscount, // addonsForFirstDay.discount > 0,
        text: renderTooltip({
          dietCost: {
            ...dietCost,
            usedDiscounts: dietCost?.subscription?.discountParts ?? {},
          }, //workaround to place another day discounts under usedDiscount property
          t,
        }),
      },
      sections: [
        {
          key: 'priceOtherDaysDiet',
          ...partialPrices.diet,
          showBeforeDiscount:
            subscription?.priceParts.DIET !==
            subscription?.priceParts.DIET - subscription?.discount,
          beforeDiscount: subscription?.priceParts.DIET,
          afterDiscount: subscription?.priceParts.DIET - subscription?.discount,
          tooltip: {
            ...partialPrices.diet.tooltip,
            id: 'priceOtherDaysDietTooltip',
          },
        },
        ...(hasBagAddons
          ? [
              {
                key: 'priceOtherDaysAddons',
                ...partialPrices.addonsAnotherDays,
                tooltip: {
                  id: 'priceOtherDaysAddonsTooltip',
                  isShow: false, // addonsForAnotherDays.discount > 0,
                  text: 'addonsForAnotherDays.discount > 0',
                },
                items: bagAddons,
              },
            ]
          : []),
        {
          key: 'priceOtherDaysDelivery',
          ...partialPrices.delivery,
          tooltip: {
            ...partialPrices.delivery.tooltip,
            id: 'priceOtherDaysDeliveryTooltip',
          },
        },
      ],
    };

    if (
      !hasAddons ||
      (!hasDietAddons &&
        hasBagAddons &&
        !addonsForFirstDayHasDiscount &&
        !addonsForAnotherDaysHasDiscount)
    ) {
      if (subscriptionDiscountLimit > 0) {
        draftState.push(priceFirstDay);

        if (!hasAddons) {
          draftState.push(subscriptionPriceOtherDays);
        }
      } else {
        draftState.push({
          key: 'pricePerDay',
          label: t('$*basket.labelPricePerDay', '$$Cena za dzień'),
          showBeforeDiscount:
            calculatePrice([
              paymentMode === '1' ? diet.discount : diet.avgDiscount,
              delivery.discount,
              hasBagAddons ? addonsForAnotherDays.discount : 0,
            ]) > 0,
          beforeDiscount: calculatePrice([
            diet.beforeDiscount,
            delivery.beforeDiscount,
            hasBagAddons ? addonsForAnotherDays.beforeDiscount : 0,
          ]),
          afterDiscount: calculatePrice([
            paymentMode === '1' ? diet.afterDiscount : diet.avgAfterDiscount,
            delivery.afterDiscount,
            hasBagAddons ? addonsForAnotherDays.afterDiscount : 0,
          ]),
          tooltip: {
            id: 'priceDiscountItems',
            isShow: dietCost.priceDiscount > 0 && dietCost.subscription,
            text: renderTooltip({ dietCost, t }),
          },
          itemEntryProps: { isGroupHeader: true },

          sections: [
            {
              key: 'pricePerDayDiet',
              ...partialPrices.diet,
              tooltip: {
                ...partialPrices.diet.tooltip,
                id: 'pricePerDayDietTooltip',
              },
            },
            ...(hasBagAddons
              ? [
                  {
                    key: 'pricePerDayAddons',
                    ...partialPrices.addonsAnotherDays,
                    itemEntryProps: { isSection: true },
                    tooltip: {
                      id: 'pricePerDayAddonsTooltip',
                      isShow: false, // addonsForAnotherDays.discount > 0,
                      text: 'addonsForAnotherDays.discount > 0',
                    },
                    items: bagAddons,
                  },
                ]
              : []),
            {
              key: 'pricePerDayDelivery',
              ...partialPrices.delivery,
              tooltip: {
                ...partialPrices.delivery.tooltip,
                id: 'pricePerDayDeliveryTooltip',
              },
            },
          ],
        });
      }
    }

    if (
      hasDietAddons ||
      addonsForFirstDayHasDiscount ||
      addonsForAnotherDaysHasDiscount
    ) {
      draftState.push(priceFirstDay);

      if (
        subscriptionDiscountLimit > 0 &&
        hasDietAddons &&
        addonsForAnotherDaysWithDiscount
      ) {
        draftState.push({
          key: 'priceOtherLimitDay',
          label: t('$*basket.labelPriceOtherLimitDay', {
            replace: { count: subscriptionDiscountLimit },
          }),
          showBeforeDiscount:
            calculatePrice([
              diet.discount,
              delivery.discount,
              addonsForAnotherDaysWithDiscount.discount,
            ]) > 0,
          beforeDiscount: calculatePrice([
            diet.beforeDiscount,
            delivery.beforeDiscount,
            addonsForAnotherDaysWithDiscount.beforeDiscount,
          ]),
          afterDiscount: calculatePrice([
            diet.afterDiscount,
            delivery.afterDiscount,
            addonsForAnotherDaysWithDiscount.afterDiscount,
          ]),
          itemEntryProps: { isGroupHeader: true },
          tooltip: {
            id: 'priceOtherLimitDiscountItems',
            isShow:
              calculatePrice([
                diet.discount,
                delivery.discount,
                addonsForAnotherDaysWithDiscount.discount,
              ]) > 0,
            text: renderTooltip({
              dietCost,
              t,
              enabledCodeDescription: hasSubscriptionLimitWithoutDietAddons,
            }),
          },

          sections: [
            {
              key: 'priceOtherLimitDaysDiet',
              ...partialPrices.diet,
              tooltip: {
                ...partialPrices.diet.tooltip,
                id: 'priceOtherLimitDaysDietTooltip',
              },
            },
            ...(hasBagAddons
              ? [
                  {
                    key: 'priceOtherLimitDaysAddons',
                    ...partialPrices.addonsAnotherLimitDays,
                    items: bagAddons,
                  },
                ]
              : []),
            {
              key: 'priceOtherLimitDaysDelivery',
              ...partialPrices.delivery,
              tooltip: {
                ...partialPrices.delivery.tooltip,
                id: 'priceOtherLimitDeliveryTooltip',
              },
            },
          ],
        });
      }

      if (dietLength > 1) {
        if (paymentMode === '2') {
          draftState.push({
            key: 'priceOtherDays',
            label: t(
              '$*basket.labelPriceOtherDays',
              '$$Cena za każdy kolejny dzień'
            ),
            showBeforeDiscount:
              calculatePrice([
                subscription?.discount || diet.discount,
                delivery.discount,
                hasBagAddons ? addonsForAnotherDays.discount : 0,
              ]) > 0,
            beforeDiscount: calculatePrice([
              subscription?.beforeDiscount || diet.beforeDiscount,
              delivery.beforeDiscount,
              hasBagAddons ? addonsForAnotherDays.beforeDiscount : 0,
            ]),
            afterDiscount: calculatePrice([
              subscription?.afterDiscount || diet.afterDiscount,
              delivery.afterDiscount,
              hasBagAddons ? addonsForAnotherDays.afterDiscount : 0,
            ]),
            itemEntryProps: { isGroupHeader: true },
            tooltip: null,
            sections: [
              {
                key: 'priceOtherDaysDiet',
                ...partialPrices.diet,
                tooltip: {
                  ...partialPrices.diet.tooltip,
                  id: 'priceOtherDaysDietTooltip',
                },
              },
              ...(hasBagAddons
                ? [
                    {
                      key: 'priceOtherDaysAddons',
                      ...partialPrices.addonsAnotherDays,
                      tooltip: {
                        id: 'priceOtherDaysAddonsTooltip',
                        isShow: false,
                        text: 'addonsForAnotherDays.discount > 0',
                      },
                      items: bagAddons,
                    },
                  ]
                : []),
              {
                key: 'priceOtherDaysDelivery',
                ...partialPrices.delivery,
                tooltip: {
                  ...partialPrices.delivery.tooltip,
                  id: 'priceOtherDaysDeliveryTooltip',
                },
              },
            ],
          });
        } else {
          // For subscription only
          draftState.push(subscriptionPriceOtherDays);
        }
      }
    }
  });

  return prices;
};
