import React from 'react';
import { StyledFormRadioBox } from 'styledComponents/elements/Form/FormRadio';

const FormRadioBox = ({ input, children, ...props }) => (
  <StyledFormRadioBox as="label" checked={input.checked} {...props}>
    <input type="radio" {...input} />
    {children}
  </StyledFormRadioBox>
);

export default FormRadioBox;
