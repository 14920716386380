import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  BaseWrapper,
  BaseDayNumber,
  BaseIconWrapper,
  BaseCaption,
} from 'styledComponents/elements/BigCalendarDayCell';
import { fromPxToRem, media, DisplayOnDesktop } from 'styledComponents/helpers';
import AddIcon from 'common/components/BigCalendar/icons/AddIcon.js';

const IconWrapper = styled(BaseIconWrapper)`
  top: 15px;
  right: -8px;
  ${media.tabletDown`
    width: 12px;
    height: 12px;
    top: 7px;
    right: -5px;
  `}
`;

const Caption = styled(BaseCaption)`
  color: ${props => props.theme.baseFontColor};
`;

const AddDayDayCell = ({ children, isIteratedDayToday }) => {
  const { t } = useTranslation();
  return (
    <BaseWrapper isIteratedDayToday={isIteratedDayToday}>
      <IconWrapper>
        <AddIcon />
      </IconWrapper>
      <BaseDayNumber>{children}</BaseDayNumber>

      <DisplayOnDesktop>
        <Caption>{t('$*calendarDayCell.makeOrder', '$$Zamów')}</Caption>
      </DisplayOnDesktop>
    </BaseWrapper>
  );
};

export default AddDayDayCell;
