import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import { Field } from 'react-final-form';

import { fromPxToRem } from 'styledComponents/helpers';

import ThumbRating from './ThumbRating';

import { INPUT_IDS } from '../const/ratingForm';
import { useTranslation } from 'react-i18next';

const DeliveryRatingLabel = styled(Box)`
  font-size: ${fromPxToRem('12px')};
`;

const DeliveryRating = ({ disabled }) => {
  const { t } = useTranslation();

  return (
    <Flex justifyContent="space-between" alignItems="baseline" mb={10}>
      <DeliveryRatingLabel>
        {t('$*menuPlanning.deliveryRating.label', '$$Ocena dostawy')}:
      </DeliveryRatingLabel>
      <Field
        name={INPUT_IDS.DELIVERY_RATING}
        component={ThumbRating}
        disabled={disabled}
      />
    </Flex>
  );
};

export default DeliveryRating;
