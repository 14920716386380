import { store } from './store';

import { removeBag } from 'common/components/Basket/duck/actions';

store.subscribe(() => {
  const {
    basket: { bags },
  } = store.getState();

  bags.forEach(({ bag, addons }) => {
    if (addons.length === 0) {
      store.dispatch(removeBag(bag));
    }
  });
});

export default store;
