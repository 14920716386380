import React, { useEffect, useState } from 'react';
import {
  Container,
  OptionsGroup,
  OptionsSliderWrapper,
  StandaloneWrapper,
  StyledIcon,
  SubHeading,
} from './StyledComponents';
import FormRadioGroup from '../../common/components/Form/FormRadioGroup';
import StandalonePrimaryRadio from '../../common/components/StandalonePrimaryRadio';
import Slider from 'react-slick';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

const DietsPicker = ({ diets, variants, onDietSelect, selectedDiet, t }) => {
  const sliderRef = React.useRef(null);
  const [dietSliderRef, setDietSliderRef] = React.useState(null);

  React.useEffect(() => {
    setDietSliderRef(sliderRef.current);
  }, []);

  const selectedTypeIndex = diets.findIndex(
    type => type['@id'] === selectedDiet
  );
  return (
    <>
      <SubHeading>{t('$*menu.chooseKind', 'Wybierz rodzaj')}</SubHeading>
      <OptionsGroup>
        <FormRadioGroup>
          {diets.map(diet => (
            <StandalonePrimaryRadio
              onClick={() => onDietSelect(diet['@id'])}
              key={diet.id}
              name={diet.name}
              icon={diet.image}
              checked={selectedDiet === diet['@id']}
              image={diet.icon}
              marginRight={'10px'}
            />
          ))}
        </FormRadioGroup>
      </OptionsGroup>
      <OptionsSliderWrapper>
        <StyledIcon
          className="fas fa-chevron-circle-left"
          onClick={dietSliderRef?.slickPrev}
        />
        <Slider
          arrows={false}
          style={{ width: 'calc(100vw - 100px)' }}
          ref={slider => {
            sliderRef.current = slider;
          }}
          initialSlide={selectedTypeIndex}
          afterChange={currentSlide => onDietSelect(diets[currentSlide]['@id'])}
        >
          {diets.map(diet => (
            <StandaloneWrapper key={diet.id}>
              <StandalonePrimaryRadio
                onClick={e => {
                  e.preventDefault();
                  onDietSelect(diet['@id']);
                }}
                name={diet.name}
                icon={diet.image}
                checked={selectedDiet === diet['@id']}
                image={diet.icon}
              />
            </StandaloneWrapper>
          ))}
        </Slider>
        <StyledIcon
          className="fas fa-chevron-circle-right"
          onClick={dietSliderRef?.slickNext}
        />
      </OptionsSliderWrapper>
    </>
  );
};

export default compose(
  connect(({ orderForm: { orderConfig: { variants, diets } } }) => ({
    variants,
    diets,
  })),
  withTranslation()
)(DietsPicker);
