import React from 'react';
import { Flex } from '@rebass/grid';
import { useTranslation } from 'react-i18next';

import Modal from 'common/components/Modal';
import { ButtonPrimary, ButtonLink } from 'styledComponents/elements/Button';

import { mainTheme } from 'themes/main';

const DeleteAccountConfirmModal = ({ isOpened, toggleModal, handleSubmit }) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        id={true}
        title={t(
          '$accountSettings.deleteAccountSection.confirmModal.title',
          '$$Czy na pewno chcesz usunąć konto?'
        )}
        isOpened={isOpened}
        toggleModal={toggleModal}
        widthDesktop="620px"
        widthMobile="90%"
        style={{ padding: '25px' }}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          mt="25px"
          mx="-30px"
          mb="-30px"
        >
          <ButtonLink
            type="button"
            onClick={() => toggleModal(false)}
            uppercased
            sizeMiddle
            style={{ marginTop: 0 }}
          >
            {t('$*common.cancel', 'Anuluj')}
          </ButtonLink>
          <ButtonPrimary
            onClick={handleSubmit}
            style={{ backgroundColor: mainTheme.colorRustyRed, margin: '0' }}
            withSpinner
          >
            {t('$*common.continue', '$$Kontynuuj')}
          </ButtonPrimary>
        </Flex>
      </Modal>
    </>
  );
};

export default DeleteAccountConfirmModal;
