import { SET_COUNTER_PWA_MODAL, INCREMENT_PWA_MODAL } from './const';

export const setCounterPwaModal = number => ({
  type: SET_COUNTER_PWA_MODAL,
  payload: number,
});

export const incrementCounterPwaModal = () => ({
  type: INCREMENT_PWA_MODAL,
});
