import React from 'react';
import { useTranslation } from 'react-i18next';

const DeliveryOptionLabel = ({ option: { label, description } }) => {
  return (
    <>
      <span>{label}</span>
      {description && <p style={{ fontSize: '12px' }}>{description}</p>}
    </>
  );
};

export default DeliveryOptionLabel;
