import React from 'react';

import StyledFooter from 'styledComponents/blocks/Footer';

import Logo from 'common/components/Logo';
import FooterNavigation from './FooterNavigation';
import Copyright from './Copyright';

const Footer = () => {
  return (
    <StyledFooter>
      <Logo isSmall={true} />
      <Copyright />
      <FooterNavigation isAlignedRight={true} />
    </StyledFooter>
  );
};

export default Footer;
