import {
  INIT_FORM,
  UPDATE_FORM,
  UPDATE_FORM_ADDONS,
  SET_ADDRESS_FORM_ID,
  SET_CURRENT_FORM,
  REMOVE_FORM,
  CLEAR_FORM_FIELDS,
  CLEAR_ALL_FORMS,
  SET_DEFAULTS_FROM_URL,
  SET_NAV_UPDATES,
} from './const';
import { changePaymentMode, setPaymentMethod } from './orderFormActions';

export const initForm = () => {
  return (dispatch, getState) => {
    const {
      app,
      orderForm,
      userAddresses,
      dietForm: { forms },
    } = getState();
    const {
      config: {
        modules: { ConfigClientPanel },
      },
    } = app;

    // set default if is only one diet in form else set onePaymentMethod (2)
    const isOneDiet = Object.keys(forms).length < 1;
    const defaultPaymentMode = isOneDiet
      ? ConfigClientPanel.defaultPaymentMode
      : '2';

    dispatch(changePaymentMode(defaultPaymentMode));

    dispatch(
      setPaymentMethod(
        ConfigClientPanel.defaultPaymentMode === '1' && isOneDiet
          ? ConfigClientPanel.subscriptionPaymentDefaultGateway
          : ConfigClientPanel.oneTimePaymentDefaultGateway
      )
    );

    return dispatch({
      type: INIT_FORM,
      config: {
        app,
        orderForm,
        userAddresses,
      },
    });
  };
};

export const updateForm = (formId, formState) => {
  return {
    type: UPDATE_FORM,
    formId,
    formState,
  };
};

export const updateFormAddons = (formId, addons) => ({
  type: UPDATE_FORM_ADDONS,
  formId,
  addons,
});

export const setDeliveryAddressFormId = (
  formId,
  deliveryAddressFormId
) => async dispatch => {
  return await dispatch({
    type: SET_ADDRESS_FORM_ID,
    formId,
    deliveryAddressFormId,
  });
};

export const clearFormFields = (formId, fieldNames) => ({
  type: CLEAR_FORM_FIELDS,
  formId,
  fieldNames,
});

export const setCurrentForm = formId => ({
  type: SET_CURRENT_FORM,
  formId,
});

export const removeForm = formId => ({
  type: REMOVE_FORM,
  formId,
});

export const clearAllForms = () => ({ type: CLEAR_ALL_FORMS });

export const setDefaultsFromUrl = values => {
  return {
    type: SET_DEFAULTS_FROM_URL,
    payload: values,
  };
};

export const setNavUpdates = useNavUpdates => ({
  type: SET_NAV_UPDATES,
  useNavUpdates,
});
