import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import withUserAddresses from 'common/components/UserAddresses';
import UserAddressesForm from 'views/AccountSettings/components/Form/UserAddressesForm';

import { saveUserAddressToLS } from 'common/components/UserAddresses/actions/userAddressesActions';

import { getNewAddressId } from '../../services/formService';

import UserAddressesList from './UserAddressesList';

const DeliveryTakeoutForm = ({
  token,
  addresses,
  getUserAddresses,
  values,
  canAddNewAddress,
  saveUserAddressToLS,
  deleteAddress,
  isNewOrder,
}) => {
  return addresses ? (
    addresses.length > 0 ? (
      <UserAddressesList
        token={token}
        list={addresses}
        onAddressDelete={deleteAddress}
        reloadAddresses={getUserAddresses}
        canAddNewAddress={canAddNewAddress}
        isNewOrder={isNewOrder}
      />
    ) : canAddNewAddress ? (
      <UserAddressesForm
        values={values}
        initialValues={{ isDefault: false, id: getNewAddressId(addresses) }}
        hideSaveButton={true}
        isBlank={true}
        token={token}
        useAutoSave
        saveAddressToLS={address => saveUserAddressToLS(address)}
      />
    ) : null
  ) : null;
};

export default compose(
  connect(
    ({ auth: { token } }) => ({
      token,
    }),
    { saveUserAddressToLS }
  ),
  withUserAddresses
)(DeliveryTakeoutForm);
