import styled from 'styled-components';

const StyledHeader = styled.header`
  position: sticky;
  top: 0;
  z-index: 100;
  border-bottom: ${props => `1px solid ${props.theme.colorGrey}`};
  background-color: ${props => props.theme.colorWhite};
`;

StyledHeader.AdminInfo = styled.div`
  background-color: red;
  height: 32px;
  color: white;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
`;

StyledHeader.Wrapper = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  padding: 27px 22px;
`;

export default StyledHeader;
