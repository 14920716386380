import { Styled } from './Dish.styled';
import { useDishTileContext } from './DishTile.context';

const DishInfoWrapper = ({ children }) => {
  const { isOpenMenu, showImage } = useDishTileContext();

  return (
    <Styled.DishInfoWrapper isOpenMenu={isOpenMenu} showImage={showImage}>
      {children}
    </Styled.DishInfoWrapper>
  );
};

export default DishInfoWrapper;
