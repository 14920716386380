import { DishTileProvider } from './DishTile.context';
import DishTileView from './DishTile.view';

const DishTile = ({ ...props }) => {
  return (
    <DishTileProvider>
      <DishTileView {...props} />
    </DishTileProvider>
  );
};

export default DishTile;
