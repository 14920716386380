import React from 'react';
import { connect } from 'react-redux';
import { Tabs as TabsWrapper } from 'react-tabs';

import {
  StyledTabList,
  StyledTab,
  StyledSingleTab,
  SingleTabNumber,
  SingleTabLabel,
  StyledTabPanel,
  StyledTabCounter,
  StyledTabLabel,
} from 'styledComponents/elements/Tabs';

const Tabs = ({
  tabs = [],
  panels = [],
  validStepsIndexes,
  currentTabIndex,
  premiumType,
  useImageAsBackground,
  ...props
}) => {
  const progressPercentage = ((props.selectedIndex + 1) / tabs.length) * 100;

  const isTabDisabled = tabIndex =>
    !validStepsIndexes.filter(index => index + 1 >= tabIndex).length > 0;
  return (
    <TabsWrapper selectedIndex={props.selectedIndex} onSelect={props.onSelect}>
      <StyledTabList $useImageAsBackground={useImageAsBackground}>
        {tabs.map((tabName, index) => (
          <StyledTab disabled={isTabDisabled(index)} key={index}>
            <StyledTabCounter>{index + 1}</StyledTabCounter>
            <StyledTabLabel>{tabName}</StyledTabLabel>
          </StyledTab>
        ))}
      </StyledTabList>
      {/* Single tab element for mobile view */}
      <StyledSingleTab progress={`${progressPercentage}%`}>
        <SingleTabNumber>{props.selectedIndex + 1}</SingleTabNumber>
        <SingleTabLabel>{tabs[props.selectedIndex]}</SingleTabLabel>
      </StyledSingleTab>
      {panels.map((PanelComponent, index) => (
        <StyledTabPanel key={index}>
          <PanelComponent {...props} />
        </StyledTabPanel>
      ))}
    </TabsWrapper>
  );
};

export default connect(
  ({
    orderFormTabs: { validStepsIndexes, tabIndex },
    app: {
      brand: { premiumType, useImageAsBackground },
    },
  }) => ({
    validStepsIndexes,
    premiumType,
    currentTabIndex: tabIndex,
    useImageAsBackground,
  })
)(Tabs);
