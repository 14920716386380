import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';

import 'moment/locale/en-gb';

import 'moment/locale/uk';
// import 'moment/locale/is';
// import 'moment/locale/sd';

import 'moment/locale/cs';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fi';
import 'moment/locale/it';
import 'moment/locale/pl';
import 'moment/locale/ru';

import translationCS from 'locales/cs.json';
import translationDE from 'locales/de.json';
import translationEN from 'locales/en.json';
import translationES from 'locales/es.json';
import translationFI from 'locales/fi.json';
// import translationIN from 'locales/in.json';
import translationIT from 'locales/it.json';
import translationPL from 'locales/pl.json';
import translationRU from 'locales/ru.json';
// import translationSU from 'locales/su.json';
import translationUA from 'locales/ua.json';

// the translations
const resources = {
  cs: {
    translation: translationCS,
  },
  de: {
    translation: translationDE,
  },
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
  fi: {
    translation: translationFI,
  },
  // in: {
  //   translation: translationIN,
  // },
  it: {
    translation: translationIT,
  },
  pl: {
    translation: translationPL,
  },
  ru: {
    translation: translationRU,
  },
  // su: {
  //   translation: translationSU,
  // },
  ua: {
    translation: translationUA,
  },
};

// const language = localStorage.getItem('language') ?? 'pl';
// moment.locale(language);

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    load: 'languageOnly',
    fallbackLng: 'pl',
    debug: false,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })
  .then(() => {
    const isRegionIncluded = i18n.language.length > 2;
    if (isRegionIncluded) {
      const languageShort = i18n.languages[0];
      i18n.changeLanguage(languageShort);
    }
  });

export default i18n;
