import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Flex } from '@rebass/grid';

import FormSection from 'common/components/Form/FormSection';
import PaymentCard from 'common/components/PaymentCard/PaymentCard';
import PaymentCardForm from 'common/components/PaymentCard/PaymentCardForm';
import PaymentCardFormPayU from 'common/components/PaymentCard/PaymentCardFormPayU';
import withPaymentCardsConsumer from 'common/components/HOC/withPaymentCardsConsumer';

import { Paragraph } from 'styledComponents/elements/Typography';
import { ContentWrapper } from 'styledComponents/blocks/ContentWrapper';

import PAYMENT_GATEWAYS_CODES from 'common/const/paymentGatewaysCodes';

const UserPaymentCardForm = () => {
  const { t } = useTranslation();

  const subscriptionPaymentDefaultGateway = useSelector(
    ({ app: { config } }) =>
      config.modules.ConfigClientPanel.subscriptionPaymentDefaultGateway
  );

  const { allPaymentCards, isFetchedAllPaymentCards } = useSelector(
    ({ auth }) => auth
  );

  const isStripeGateway =
    subscriptionPaymentDefaultGateway ===
    PAYMENT_GATEWAYS_CODES.STRIPE_SUBSCRIPTION;
  const isPayUGateway =
    subscriptionPaymentDefaultGateway ===
    PAYMENT_GATEWAYS_CODES.PAYU_SUBSCRIPTION;

  const getUserCards = () => {
    switch (subscriptionPaymentDefaultGateway) {
      case PAYMENT_GATEWAYS_CODES.STRIPE_SUBSCRIPTION:
        return allPaymentCards.filter(
          card => card.provider === PAYMENT_GATEWAYS_CODES.STRIPE_SUBSCRIPTION
        );
      case PAYMENT_GATEWAYS_CODES.BLUE_MEDIA_SUBSCRIPTION:
        return allPaymentCards.filter(
          card =>
            card.provider === PAYMENT_GATEWAYS_CODES.BLUE_MEDIA_SUBSCRIPTION
        );
      case PAYMENT_GATEWAYS_CODES.PAYU_SUBSCRIPTION:
        return allPaymentCards.filter(
          card => card.provider === PAYMENT_GATEWAYS_CODES.PAYU_SUBSCRIPTION
        );
      default:
        return [];
    }
  };

  const userCards = getUserCards();
  const [userCard] = userCards;

  const hasProviderCards =
    (isFetchedAllPaymentCards && userCards.length > 0) || false;

  return (
    <Flex flexWrap="wrap" justifyContent="center" flexDirection="column">
      <ContentWrapper padding="40px">
        <FormSection
          title={t('$*accountSettings.paymentCard.title', '$$Karta płatnicza')}
        >
          {hasProviderCards ? (
            <PaymentCard
              id={userCard.index}
              brand={userCard.issuer}
              last4={userCard.mask}
              name={userCard.name || ''}
              provider={userCard.provider}
              expireAt={
                new Date(userCard.validityYear, userCard.validityMonth - 1)
              }
              validatedByProvider={userCard?.validatedByProvider}
            />
          ) : isStripeGateway ? (
            <PaymentCardForm />
          ) : isPayUGateway ? (
            <PaymentCardFormPayU />
          ) : (
            <Paragraph>
              {t(
                '$*accountSettings.userPaymentCardForm.addBlueMediaCard',
                '$$Kartę możesz dodać podczas składania nowego zamówienia'
              )}
            </Paragraph>
          )}
        </FormSection>
      </ContentWrapper>
    </Flex>
  );
};

export default withPaymentCardsConsumer(UserPaymentCardForm);
