import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, useForm } from 'react-final-form';
import { compose, lifecycle } from 'recompose';
import { Flex, Box } from '@rebass/grid';
import { translatePaymentTypes } from 'utils/componentHelpers';

import FormSection from 'common/components/Form/FormSection';
import DropdownSelectButton from 'common/components/Form/DropdownSelectButton';

import {
  StyledSummaryRow,
  StyledTermsAgreementWrapper,
} from 'styledComponents/elements/NewOrder/Summary';

import {
  SummaryItem,
  SummaryDiscountCode,
  SummaryMoneyBox,
  SummaryMoneyBoxReturn,
  SummaryTotalPrice,
  SummaryInvoiceInfo,
  SummaryReceiptInfo,
  SummaryPayPoInfo,
} from '../components/summary';

import { clearSelectedDays } from 'common/components/Calendar/actions/calendarActions';
import { getDietsSummary } from '../services/orderSummaryFactory';
import { initForm } from '../actions/dietFormActions';
import {
  setTabIndex,
  setNextTabDisability,
} from '../components/OrderFormTabs/actions/orderFormTabsActions';
import { setPaymentMethod } from '../actions/orderFormActions';

import { trackFP } from 'utils/facebookPixel';
import { useTranslation } from 'react-i18next';
import FormRadio from 'common/components/Form/FormRadio';
import { useDeliveryOptions } from '../const/formConfig/deliveryOptions';
import { useCurrentTabNames } from '../components/OrderFormTabs/useCurrentTabNames';
import { INTERGRATION_TYPE } from '../const/fakturownia';
import FormCheckbox from '../../../common/components/Form/FormCheckbox';
import { selectModuleConfigClientPanel } from 'selectors/appSelectors';
import { useSelector } from 'react-redux';

const OrderFormSummary = ({
  paymentTypes,
  orderForm: {
    calculations,
    paymentMethodId,
    isInvoiceIncluded,
    testMode,
    orderConfig: { diets, packages, variants },
    pickupPoints,
  },
  dietForm,
  userAddresses,
  initForm,
  setTabIndex,
  clearSelectedDays,
  setPaymentMethod,
  setNextTabDisability,
  BlueMedia,
  discount,
  moneyBox,
  form: {
    mutators: { clear },
  },
  moduleFakturownia,
}) => {
  const { t } = useTranslation();
  const formInstance = useForm();
  const [
    isBlueMediaTermsAccepted,
    setIsBlueMediaTermsAccepted,
  ] = React.useState(true);
  const { useAddressesWithLessFields } = useSelector(
    selectModuleConfigClientPanel
  );

  useCurrentTabNames('OrderFormSummary');
  const defaultPaymentOption =
    paymentTypes.find(({ type }) => type === paymentMethodId) ||
    paymentTypes[0];

  const defaultPaymentOptionLabel = defaultPaymentOption?.label
    ? { label: translatePaymentTypes()[defaultPaymentOption.type] } || {
        label: defaultPaymentOption.label,
      }
    : '';
  const DELIVERY_OPTIONS = useDeliveryOptions();
  const dietsSummary = getDietsSummary(
    dietForm.forms,
    packages,
    diets,
    variants,
    pickupPoints,
    userAddresses.list,
    testMode,
    DELIVERY_OPTIONS,
    t,
    useAddressesWithLessFields
  );

  const { integrationType, allowInvoicesForClient } = moduleFakturownia;
  const integrationTypeIsReceipt =
    integrationType === INTERGRATION_TYPE.RECEIPT;

  const isSubscriptionWithBlueMedia =
    paymentMethodId === 'BLUE_MEDIA_CARD' && dietForm.paymentMode === '1';

  useEffect(() => {
    trackFP('AddToCart');
    formInstance.mutators.setFieldValue(
      'paymentType',
      defaultPaymentOption?.type
    );

    if (isSubscriptionWithBlueMedia) {
      setIsBlueMediaTermsAccepted(false);
      setNextTabDisability(true);
    }
  }, []);
  useEffect(() => {
    const firstPaymentId = paymentTypes[0]?.type;
    if (paymentMethodId === 'PAYPO') {
      if (
        calculations.priceLeftToPay < 10 ||
        calculations.priceLeftToPay > 1000
      ) {
        setPaymentMethod(firstPaymentId);
        formInstance.mutators.setFieldValue('paymentType', firstPaymentId);
      }
    }
  }, [calculations.priceLeftToPay]);

  return (
    <FormSection
      title={t('$*orderFormSummary.title', '$$Podsumowanie zamówienia')}
    >
      {dietsSummary.map((dietSummary, index) => (
        <SummaryItem
          key={index}
          formId={index}
          dietSummary={dietSummary}
          costs={calculations.dietCosts[index]}
        />
      ))}

      <StyledSummaryRow>
        <SummaryDiscountCode discount={discount} clearField={clear} />
      </StyledSummaryRow>
      {calculations.afterPaymentWillReturnPoints > 0 && (
        <StyledSummaryRow>
          <SummaryMoneyBoxReturn
            afterPaymentWillReturnPoints={
              calculations.afterPaymentWillReturnPoints
            }
          />
        </StyledSummaryRow>
      )}
      {moneyBox > 0 && (
        <StyledSummaryRow>
          <SummaryMoneyBox />
        </StyledSummaryRow>
      )}
      <StyledSummaryRow>
        <SummaryTotalPrice
          totalPrice={{
            beforeUsePoint:
              calculations.usedMoneyBoxPointsAsPrice > 0
                ? calculations.priceAfterDiscount
                : calculations.priceBeforeDiscount,
            afterUsePoint:
              calculations.usedMoneyBoxPointsAsPrice > 0
                ? calculations.priceAfterDiscount -
                  calculations.usedMoneyBoxPointsAsPrice
                : calculations.priceAfterDiscount,
          }}
          pointsForOrder={calculations.pointsForOrder}
        />
      </StyledSummaryRow>

      {!integrationTypeIsReceipt &&
        isInvoiceIncluded &&
        allowInvoicesForClient && (
          <StyledSummaryRow>
            <SummaryInvoiceInfo />
          </StyledSummaryRow>
        )}

      {integrationTypeIsReceipt && isInvoiceIncluded && allowInvoicesForClient && (
        <StyledSummaryRow>
          <SummaryReceiptInfo />
        </StyledSummaryRow>
      )}

      {dietForm.paymentMode === '2' ? (
        <StyledSummaryRow>
          <Flex alignItems="center" justifyContent="flex-end" flexWrap={'wrap'}>
            <Box mt={[30, 30, 0]} width={[1, 1, 'auto']}>
              <DropdownSelectButton
                label={t(
                  '$*orderFormSummary.paymentTypeLabel',
                  'Rodzaj płatności'
                )}
                paymentMethodId={paymentMethodId}
                defaultOption={defaultPaymentOptionLabel}
                onChange={type => setPaymentMethod(type.value)}
              >
                {paymentTypes
                  .filter(
                    method =>
                      !(
                        method.type === 'PAYPO' &&
                        (calculations.priceLeftToPay > 1000 ||
                          calculations.priceLeftToPay < 10)
                      )
                  )
                  .map(({ type, label }) => {
                    return (
                      <Field
                        key={type}
                        label={translatePaymentTypes()[type] || label}
                        value={type}
                        initialValue={true}
                        name="paymentType"
                        type="radio"
                        component={FormRadio}
                        componentStyle="noContainer"
                        hasAutoHeight={true}
                      />
                    );
                  })}
              </DropdownSelectButton>
            </Box>
          </Flex>
        </StyledSummaryRow>
      ) : null}

      {paymentMethodId === 'PAYPO' && (
        <StyledSummaryRow>
          <SummaryPayPoInfo />
        </StyledSummaryRow>
      )}
      {isSubscriptionWithBlueMedia && (
        <StyledTermsAgreementWrapper>
          <FormCheckbox
            componentStyle="rounded"
            input={{
              checked: isBlueMediaTermsAccepted,
              onChange: () => {
                setIsBlueMediaTermsAccepted(isAccepted => !isAccepted);
                setNextTabDisability(isBlueMediaTermsAccepted);
              },
            }}
          >
            <span dangerouslySetInnerHTML={{ __html: BlueMedia?.rulesLink }} />
          </FormCheckbox>
        </StyledTermsAgreementWrapper>
      )}
    </FormSection>
  );
};

export default compose(
  connect(
    ({
      orderForm,
      dietForm,
      userAddresses,
      accountSettings: {
        userData: { moneyBox },
      },
      app: {
        config: { modules, paymentTypes },
      },
    }) => ({
      paymentTypes,
      orderForm,
      dietForm,
      userAddresses,
      moneyBox,
      moduleFakturownia: modules.Fakturownia || {},
      BlueMedia: modules.BlueMedia,
    }),
    {
      initForm,
      setTabIndex,
      setNextTabDisability,
      clearSelectedDays,
      setPaymentMethod,
    }
  ),
  lifecycle({
    componentDidMount() {
      const {
        orderForm: { paymentMethodId },
        setNextTabDisability,
      } = this.props;

      setNextTabDisability(!paymentMethodId);
    },
    componentDidUpdate(prevProps) {
      const {
        setNextTabDisability,
        orderForm: {
          isInvoiceIncluded,
          isInvoiceDataValid,
          paymentMethodId,
          paymentMetadataIsValid,
        },
      } = this.props;
      const prevPaymentMethodId = prevProps.orderForm.paymentMethodId;
      const validate = [
        isInvoiceIncluded && !isInvoiceDataValid,
        paymentMethodId === 'PAYPO' && !paymentMetadataIsValid,
        !paymentMethodId,
      ];

      if (
        prevPaymentMethodId !== paymentMethodId &&
        prevPaymentMethodId === 'PAYPO'
      ) {
        setNextTabDisability(validate.includes(true));
      }
    },
  })
)(OrderFormSummary);
