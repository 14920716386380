import React from 'react';
import styled from 'styled-components';
import { Flex } from '@rebass/grid';

import Modal from 'common/components/Modal';
import ConfirmButtonWrapper from 'common/components/Modal/ConfirmButtonWrapper';

import { Paragraph } from 'styledComponents/elements/Typography';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

const PremiumPrice = styled.span`
  opacity: ${props => (props.premiumPrice ? 1 : 0)};
  font-weight: ${props => props.theme.fontWeight900};
  transition: 0.5s all;
  text-align: center;
`;

const PremiumActivationModal = ({
  isOpened,
  toggleModal,
  handlePremiumStatusChange,
  premiumPrice,
  needPayment,
  id,
  additionalPrices,
}) => {
  const handlePremium = ev => {
    ev.preventDefault();
    handlePremiumStatusChange();
    toggleModal();
  };
  const { t } = useTranslation();
  const { currencySymbol } = useSelector(
    ({
      app: {
        config: {
          multinational: { currencySymbol },
        },
      },
    }) => ({ currencySymbol }),
    shallowEqual
  );

  return (
    <Modal
      id={id}
      title={t('$*ecoboxActivationModal.title', 'Eko opakowania')}
      isOpened={isOpened}
      toggleModal={() => toggleModal()}
      withBackButton
      fullscreen
      widthDesktop="480px"
      padding="20px 30px 32px"
      paddingMobile="0"
    >
      <Paragraph textAlign="center">
        {t(
          '$*ecoboxActivationModal.mainLabel',
          'Zadbaj o środowisko z możliwością zamówienia posiłków w ekologicznych pudełkach!'
        )}
      </Paragraph>
      <Flex justifyContent="center" alignItems="center" flexDirection="column">
        <PremiumPrice premiumPrice={premiumPrice}>
          {t(
            '$*ecoboxActivationModal.priceForPackingSet',
            'Cena za komplet eko opakowań'
          )}
          : {premiumPrice} {currencySymbol}
        </PremiumPrice>
      </Flex>
      <ConfirmButtonWrapper
        buttonText={
          (additionalPrices && additionalPrices.CHANGE_MENU <= 0) ||
          premiumPrice <= 0
            ? t('$*common.add', 'Dodaj')
            : t('$*common.toPayment', 'Do płatności')
        }
        marginTop={28}
        disabled={!premiumPrice && needPayment}
        onClick={handlePremium}
      />
    </Modal>
  );
};

export default PremiumActivationModal;
