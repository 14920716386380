const formFieldNames = {
  // CONFIGURATION
  ADDONS: 'addons',
  DIET_TYPE: 'diet',
  VARIANT: 'variant',
  CALORIFICS: 'calorific',
  DURATION: 'dietLength',
  ADDONS_DIET: 'addonsDiet',
  ADDONS_BAG: 'addonsBag',
  DURATION_INPUT: 'dietLengthInput',
  DURATION_SLIDER: 'dietLengthSlider',
  WITH_SATURDAYS: 'saturdayInclude',
  WITH_SUNDAYS: 'sundayInclude',
  WITH_WEEKENDS: 'weekendInclude',
  TEST_MODE: 'testMode',
  WITH_ECOBOX: 'useEcoContainers',
  PAYMENT_MODE: 'paymentMode',
  PACKAGE: 'package',

  IS_SUBSCRIPTION_PAYMENT: 'isSubscriptionPayment',
  FIRST_DELIVERY_DAY: 'firstDeliveryDay',
  DELIVERY_DAYS: 'deliveryDays',

  // DELIVERY
  DELIVERY_TYPE: 'deliveryType',
  DELIVERY_ADDRESS: 'address',
  DELIVERY_PICKUP_POINT: 'pickUpPoint',

  // SUMMARY
  DISCOUNT_CODE: 'discountCode',
  USE_MONEY_BOX: 'useMoneyBox',
  IS_INVOICE_INCLUDED: 'isInvoiceIncluded',
  INVOICE_TYPE: 'invoiceType',
  PAYMENT_METHOD: 'paymentType',

  //INVOICE
  INVOICE_COMPANY_NAME: 'invoiceCompanyName',
  INVOICE_VAT_NUMBER: 'invoiceVatNumber',
  INVOICE_ADDRESS_POST_CODE: 'invoiceAddressPostCode',
  INVOICE_ADDRESS_CITY: 'invoiceAddressCity',
  INVOICE_ADDRESS_STREET: 'invoiceAddressStreet',
  INVOICE_ADDRESS_BUILD_NUMBER: 'invoiceAddressBuildNumber',
  INVOICE_ADDRESS_PLACE_NUMBER: 'invoiceAddressPlaceNumber',
  INVOICE_COMMENTS: 'invoiceComments',

  // PAYPO
  ADDRESS_POST_CODE: 'addressPostCode',
  ADDRESS_CITY: 'addressCity',
  ADDRESS_STREET: 'addressStreet',
  ADDRESS_BUILD_NUMBER: 'addressBuildNumber',
  ADDRESS_PLACE_NUMBER: 'addressPlaceNumber',

  // OTHERS
  DIET_NAME: 'name',
  IS_PREMIUM: 'optionChangeMenu',
};

export default formFieldNames;
