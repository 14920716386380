import isEmpty from 'lodash/isEmpty';
import { Styled } from './Dish.styled';

const DishTags = ({ tags = [] }) => {
  const showTags = true;

  if (isEmpty(tags) && !showTags) {
    return null;
  }

  return tags.map(tag => <Styled.Tag key={tag.id}>{tag.value}</Styled.Tag>);
};

export default DishTags;
