import FormTextInput from 'common/components/Form/FormTextInput';

import { useFormValidation } from 'common/services/formValidation';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const INPUT_IDS = {
  CURRENT_PASSWORD: 'oldPassword',
  NEW_PASSWORD: 'newPassword',
  REPEAT_NEW_PASSWORD: 'repeatNewPassword',
};

export const INITIAL_VALUES = {
  [INPUT_IDS.CURRENT_PASSWORD]: '',
  [INPUT_IDS.NEW_PASSWORD]: '',
  [INPUT_IDS.REPEAT_NEW_PASSWORD]: '',
};

export const usePasswordResetFormInputs = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const validators = useFormValidation();

  return useMemo(() => {
    return [
      {
        id: INPUT_IDS.CURRENT_PASSWORD,
        name: INPUT_IDS.CURRENT_PASSWORD,
        type: 'password',
        component: FormTextInput,
        label: t(
          '$*accountSettings.resetPassword.oldPassword',
          '$$Bieżące hasło'
        ),
        isRequired: true,
        validate: validators.required,
        width: '33%',
      },
      {
        id: INPUT_IDS.NEW_PASSWORD,
        name: INPUT_IDS.NEW_PASSWORD,
        type: 'password',
        component: FormTextInput,
        label: t('$*accountSettings.resetPassword.newPassword', '$$Nowe hasło'),
        isRequired: true,
        validate: validators.required,
        width: '33%',
      },
      {
        id: INPUT_IDS.REPEAT_NEW_PASSWORD,
        name: INPUT_IDS.REPEAT_NEW_PASSWORD,
        type: 'password',
        component: FormTextInput,
        label: t(
          '$*accountSettings.resetPassword.repeatNewPassword',
          '$$Powtórz nowe hasło'
        ),
        isRequired: true,
        validate: validators.required,
        width: '33%',
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);
};
