import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { Flex } from '@rebass/grid';
import { FORM_ERROR } from 'final-form';

import FormSection from 'common/components/Form/FormSection';
import FormFieldBox from 'common/components/Form/FormFieldBox';

import NOTIFICATIONS from 'common/shared/notifications';

import { ContentWrapper } from 'styledComponents/blocks/ContentWrapper';
import { DisplayOnDesktop, DisplayOnMobile } from 'styledComponents/helpers';
import FormSubmitError from 'common/components/Form/FormSubmitError';

import {
  INITIAL_VALUES,
  useBasicInformationFormInputs,
  useInvoiceDataFormInputs,
} from 'views/AccountSettings/const/userDataFormInputs';
import {
  getUserData,
  updateUserData,
} from 'views/AccountSettings/actions/accountSettingsActions';
import { getUserDataFormInitialValues } from 'views/AccountSettings/services/userDataFormRepresenter';

import showToast from 'utils/toast';
import { useTranslation } from 'react-i18next';
import { SubmitButton } from './SubmitButton';
import MissingUserNameModal from '../MissingUserNameModal';

const UserDataForm = ({ updateUserData, userData, getUserData }) => {
  const { t } = useTranslation();
  const [isMissingUserNameModalOpened, toggleMissingUserNameModal] = useState(
    false
  );

  useEffect(() => {
    getUserData().then(({ userData }) => {
      const hasFullName = !!userData.firstName && !!userData.lastName;
      toggleMissingUserNameModal(!hasFullName);
    });
  }, []);

  const onSubmit = (form, { getState }) => {
    // getState - internal final form state
    const { dirtyFields } = getState();
    const changedFields = Object.keys(dirtyFields);

    return updateUserData(form, changedFields)
      .then(() => {
        showToast({
          message: NOTIFICATIONS(t).DATA_UPDATED_SUCCESSFULLY,
          type: 'success',
        });
      })
      .catch(error => {
        return { [FORM_ERROR]: error };
      });
  };

  const BASIC_INFORMATION_FORM_INPUTS = useBasicInformationFormInputs();

  const INVOICE_DATA_FORM_INPUTS = useInvoiceDataFormInputs();

  const submitCaption = t(
    '$*accountSettings.basicInfoForm.updateBasicCaption',
    '$$Aktualizuj'
  );

  const initialValues =
    getUserDataFormInitialValues(userData) || INITIAL_VALUES;

  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({
          handleSubmit,
          submitting,
          pristine,
          invalid,
          submitError,
        }) => (
          <form onSubmit={handleSubmit}>
            <ContentWrapper padding="40px">
              <FormSection
                title={t(
                  '$*accountSettings.sectionBasic.title',
                  '$$Informacje podstawowe'
                )}
                action={
                  <DisplayOnDesktop>
                    <SubmitButton
                      {...{ submitting, pristine, invalid }}
                      disabledWhenPristine
                      caption={submitCaption}
                    />
                  </DisplayOnDesktop>
                }
              >
                <Flex flexWrap="wrap" justifyContent="center">
                  {BASIC_INFORMATION_FORM_INPUTS.map(
                    ({ id, width, ...input }) => (
                      <FormFieldBox key={id} width={width}>
                        <Field isBig {...input} />
                      </FormFieldBox>
                    )
                  )}
                </Flex>
              </FormSection>
              <FormSection
                title={t(
                  '$*accountSettings.sectionInvoice.title',
                  '$$Dane do faktury'
                )}
              >
                <Flex flexWrap="wrap" justifyContent="center">
                  {INVOICE_DATA_FORM_INPUTS.map(({ id, width, ...input }) => (
                    <FormFieldBox key={id} width={width}>
                      <Field isBig {...input} />
                    </FormFieldBox>
                  ))}
                </Flex>
              </FormSection>
              <FormSubmitError
                errorText={submitError}
                justifyContent="flex-start"
              />
              <DisplayOnMobile>
                <SubmitButton
                  {...{ submitting, pristine, invalid }}
                  disabledWhenPristine
                  caption={submitCaption}
                />
              </DisplayOnMobile>
            </ContentWrapper>
          </form>
        )}
      />
      {isMissingUserNameModalOpened && (
        <MissingUserNameModal
          isOpened={isMissingUserNameModalOpened}
          toggleModal={() => toggleMissingUserNameModal(false)}
        />
      )}
    </Fragment>
  );
};

export default connect(
  ({ accountSettings: { userData } }) => ({
    userData,
  }),
  {
    getUserData,
    updateUserData,
  }
)(UserDataForm);
