import styled from 'styled-components';

import { fromPxToRem, media } from 'styledComponents/helpers';

import { Button } from '../Button';

const SingleDetailSection = styled.div``;

SingleDetailSection.DescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

export const NotificationWrapper = styled.div`
  border: 1px solid ${props => props.theme.baseFontColor};
  border-radius: 8px;
  width: 100%;
  margin: 10px 0;
  padding: 20px;

  ${media.tabletDown`
    width: 100%;
  `}

  i {
    font-style: italic;
  }
  b {
    font-weight: bold;
  }
  h1 {
    font-size: 22px;
  }
  h2 {
    font-size: 20px;
  }
  ol,
  ul {
    list-style: initial;
  }
  li {
    list-style-position: inside;
  }
  ol {
    list-style: decimal;
  }
`;

SingleDetailSection.IconWrapper = styled.div`
  min-width: 43px;
  height: 43px;
  margin-right: 12px;
  border: 1px solid ${props => props.theme.colorIceBlue2};
`;

SingleDetailSection.Title = styled.h3`
  padding: 0;
  font-size: ${fromPxToRem('12px')};
  font-weight: ${props => props.theme.fontWeight900};
  color: ${props => props.theme.baseFontColor};
  text-transform: uppercase;
  margin: 0 0 12px;
`;

SingleDetailSection.Description = styled.div`
  font-size: ${props =>
    props.isSmall ? fromPxToRem('14px') : fromPxToRem('15px')};
  line-height: 1.38;
  font-weight: ${props =>
    props.isBold ? props.theme.fontWeight900 : props.theme.fontWeight500};
  white-space: pre-wrap;
  width: 100%;
`;

SingleDetailSection.Button = styled(Button)`
  font-size: ${fromPxToRem('12px')};
  margin-bottom: 40px;
  color: ${props =>
    props.disabled
      ? props.theme.colorLightBlueGrey
      : props.theme.baseFontColor};
`;

export default SingleDetailSection;
