import store from 'store.js';

export const pushPollsterEvent = event => {
  if (!window || !window.pollsterTrack || !process.browser) return;

  window.pollsterTrack.event({ ...event });
};

export const pushAuthenticationPollsterEvent = userId => {
  const { app } = store.getState() ?? {};

  const event = {
    gid: app?.config?.modules?.PollsterTrack?.GID ?? '',
    type: 'id',
    content: `user_id=${userId}`,
  };

  pushPollsterEvent(event);
};

export const pushPurchasePollsterEvent = (orderStatus, revenue) => {
  const { app, auth } = store.getState() ?? {};
  const { id: transactionId, items } = orderStatus;

  const products = (items ?? []).map(({ diet, priceAfterDiscount }) => ({
    name: diet?.diet?.name,
    option: diet?.variant?.name,
    calories: diet?.calorific?.name,
    price: priceAfterDiscount,
  }));

  const event = {
    gid: app?.config?.modules?.PollsterTrack?.GID ?? '',
    type: 'buy_product',
    content: {
      products,
      user_id: auth?.user?.id,
      total_price: revenue,
      transaction_id: transactionId,
    },
  };

  pushPollsterEvent(event);
};
