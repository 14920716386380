import styled from 'styled-components';

import { fromPxToRem, media } from 'styledComponents/helpers';

const StyledFooter = styled.footer`
  display: flex;
  align-items: center;
  min-height: 80px;
  padding: 25px 27px;
  margin-top: 35px;
  border-top: ${props => `1px solid ${props.theme.colorGrey}`};
  font-size: ${fromPxToRem('14px')};
  background: white;

  ${media.tabletDown`
    flex-flow: column wrap;
    width: 100%;
    padding: 25px 56px;
  `};
`;

export default StyledFooter;
