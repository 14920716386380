import React from 'react';
import { Field } from 'react-final-form';

import FormRadioGroup from 'common/components/Form/FormRadioGroup';

import FORM_FIELD_NAMES from '../../const/formFieldNames';

import FormRadio from 'common/components/Form/FormRadio';

const DietCalorifics = ({ calorifics }) => (
  <FormRadioGroup>
    {calorifics &&
      calorifics.map(calorific => (
        <Field
          name={FORM_FIELD_NAMES.CALORIFICS}
          key={calorific.id}
          value={calorific.id.toString()}
          label={calorific.name}
          description={calorific.description}
          type="radio"
          component={FormRadio}
          componentStyle="primary"
        />
      ))}
  </FormRadioGroup>
);

export default DietCalorifics;
