const DishImage = ({ showImage = false, imageUrl = null }) => {
  if (!showImage) {
    return null;
  }

  return (
    <div
      style={{
        background: `url(${imageUrl}) no-repeat center center / cover`,
        height: '245px',
        width: '100%',
        borderRadius: '8px 8px 0 0',
      }}
    />
  );
};

export default DishImage;
