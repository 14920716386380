import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Flex } from '@rebass/grid';

import { getSectionsToDisplay } from 'utils/componentHelpers';
import { setNextTabDisability } from 'views/NewOrder/components/OrderFormTabs/actions/orderFormTabsActions';

import FormSection from 'common/components/Form/FormSection';

import { ButtonLink } from 'styledComponents/elements/Button';

import withBasket from '../components/withBasket';

import { trackFP } from 'utils/facebookPixel';
import { useConfigurationSections } from '../const/formConfig/configurationSections';
import { useCurrentTabNames } from '../components/OrderFormTabs/useCurrentTabNames';

const OrderFormConfiguration = ({
  isLoading,
  setNextTabDisability,
  ...props
}) => {
  const CONFIGURATION_SECTIONS = useConfigurationSections();
  const filteredSections = getSectionsToDisplay(
    CONFIGURATION_SECTIONS,
    props.ecoContainer,
    props.packages
  );

  useCurrentTabNames('OrderFormConfiguration');

  useEffect(() => {
    trackFP('CustomizeProduct'); // @TODO !! bad place for it, it should be triggered by: packet/calorific/testmode/variant/diet/dietlength/calendardays change on first step
    props.validStepsIndexes.includes(props.tabIndex) &&
      setNextTabDisability(false);
  }, []);

  return (
    !isLoading &&
    filteredSections.map((section, index) => {
      return section.hideSection ? null : (
        <FormSection
          key={index}
          title={
            <div style={{ width: '100%' }}>
              <Flex
                justifyContent={
                  section.customComponentJustifyContent
                    ? section.customComponentJustifyContent
                    : 'flex-start'
                }
                alignItems={'center'}
              >
                <span>
                  {index + 1}. {section.title}
                </span>
                {props[section.titleLinkShow] && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={props[section.titleLinkPropName]}
                  >
                    <ButtonLink
                      sizeMiddle
                      weightBold
                      customMargin={'0px'}
                      fullWidthOnMobile
                    >
                      {section.titleLinkText}
                    </ButtonLink>
                  </a>
                )}
              </Flex>
            </div>
          }
        >
          <section.component
            {...section.getComponentProps(props, section.requiredProps)}
          />
        </FormSection>
      );
    })
  );
};
export default compose(
  connect(
    ({
      orderFormTabs: { tabIndex, validStepsIndexes },
      orderForm: {
        isLoading,
        dietTypeDesc,
        packageDesc,
        calorifics,
        orderConfig: {
          packages,
          diets,
          variants,
          days,
          testDays,
          testDaysEnabled,
        },
      },
      app: {
        brand: {
          menuButtonLink,
          calculatorLink,
          showCalculatorLink,
          ecoContainer,
        },
        config,
      },
      dietForm: {
        forms: {
          0: { diet, variant },
        },
      },
    }) => ({
      isLoading,
      dietTypeDesc,
      packageDesc,
      calorifics,
      packages,
      diets,
      variants,
      days,
      testDays,
      testDaysEnabled,
      menuButtonLink: menuButtonLink || `/menu/${diet}/${variant}`,
      showMenuButton: config?.modules?.MenuPage?.showMenuButton,
      calculatorLink,
      showCalculatorLink,
      ecoContainer,
      tabIndex,
      validStepsIndexes,
    }),
    {
      setNextTabDisability,
    }
  ),
  withBasket
)(OrderFormConfiguration);
