import { uniq } from 'ramda';

import {
  SET_TABS_COUNT,
  SET_TAB_INDEX,
  SET_NEXT_TAB_DISABILITY,
  CLEAR_TABS,
  SET_CURRENT_TAB_NAME,
} from '../actions/const';

const INITIAL_STATE = {
  tabsCount: 0,
  tabIndex: 0,
  isPrevDisabled: true,
  isNextDisabled: false,
  validStepsIndexes: [],
  tabName: null,
};

const orderFormTabsReducer = (
  state = INITIAL_STATE,
  { type, tabsCount, tabIndex, isNextDisabled, name: tabName }
) => {
  switch (type) {
    case SET_TABS_COUNT:
      return {
        ...state,
        tabsCount,
      };

    case SET_TAB_INDEX:
      return {
        ...state,
        tabIndex,
        isPrevDisabled: tabIndex === 0,
      };

    case SET_NEXT_TAB_DISABILITY:
      return {
        ...state,
        isNextDisabled,
        validStepsIndexes: uniq(
          getValidStepsIndexes(
            state.tabIndex,
            state.validStepsIndexes,
            !isNextDisabled
          )
        ),
      };

    case CLEAR_TABS:
      return INITIAL_STATE;

    case SET_CURRENT_TAB_NAME:
      return {
        ...state,
        tabName,
      };

    default:
      return state;
  }
};

const getValidStepsIndexes = (
  currentTabIndex,
  validStepsIndexes,
  canGoFurther
) => {
  return canGoFurther
    ? [...validStepsIndexes, currentTabIndex]
    : validStepsIndexes.reduce(
        (result, index) =>
          index < currentTabIndex ? [...result, index] : result,
        []
      );
};

export default orderFormTabsReducer;
