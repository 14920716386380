import React from 'react';
import { useTranslation } from 'react-i18next';

import URLS from 'routes/routeUrls';

import Confirmation from 'styledComponents/elements/NewOrder/Confirmation';

const ConfirmationError = () => {
  const { t } = useTranslation();
  return (
    <Confirmation>
      <Confirmation.Title>
        {t('$*confirmationError.title', 'Przepraszamy,')}
      </Confirmation.Title>
      <Confirmation.Subtitle>
        {t(
          '$*confirmationError.content',
          'wystąpił problem z płatnością. Skontaktuj się z BOK.'
        )}
      </Confirmation.Subtitle>

      <Confirmation.Link to={URLS.USER_DIET}>
        {' '}
        {t('$*confirmationError.myAccountCaption', 'Moje konto')}{' '}
      </Confirmation.Link>
    </Confirmation>
  );
};

export default ConfirmationError;
