import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';

import { Button } from 'styledComponents/elements/Button';
import { fromPxToRem } from 'styledComponents/helpers';

const DirectionButton = styled(Button)`
  [class*='fa-'] {
    color: ${props => props.color || props.theme.baseFontColor};
    font-size: ${props => props.fontSize || fromPxToRem('24px')};
  }
`;

const DaysSwitcherButton = ({ color, onClick, direction = 'right' }) => (
  <Flex alignItems="center">
    <Box>
      <DirectionButton color={color} onClick={onClick}>
        <i className={`fas fa-chevron-${direction}`} />
      </DirectionButton>
    </Box>
  </Flex>
);

export default DaysSwitcherButton;
