import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  BaseWrapper,
  BaseCaption,
  BaseDayNumber,
  BaseIconWrapper,
} from 'styledComponents/elements/BigCalendarDayCell';
import { fromPxToRem, media, DisplayOnDesktop } from 'styledComponents/helpers';
import PartiallyRatedIcon from 'common/components/BigCalendar/icons/PartiallyRatedIcon';

const Wrapper = styled(BaseWrapper)`
  background-color: ${props => props.theme.colorWhite};
  border: none;
  outline: none;
`;

const Caption = styled(BaseCaption)`
  background-color: ${props => props.theme.baseFontColor};
  color: ${props => props.theme.colorWhite};
`;

const StyledDayNumber = styled(BaseDayNumber)`
  color: ${props => props.theme.bodyColor};
  margin: auto;
`;

const DisabledDay = ({ children, isIteratedDayToday }) => {
  const { t } = useTranslation();
  return (
    <Wrapper isIteratedDayToday={isIteratedDayToday}>
      <StyledDayNumber>{children}</StyledDayNumber>
    </Wrapper>
  );
};

export default DisabledDay;
