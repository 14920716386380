import React, { Component, Fragment } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Flex, Box } from '@rebass/grid';
import { ButtonLink } from 'styledComponents/elements/Button';
import Spinner from 'styledComponents/elements/LoadingSpinner';
import { ContentWrapper } from 'styledComponents/blocks/ContentWrapper';
import { BadgeGreen as StyledBadgeGreen } from 'styledComponents/elements/Badge';
import { DisplayOnMobile, DisplayOnDesktop } from 'styledComponents/helpers';
import { setPaymentMethod } from '../../NewOrder/actions/orderFormActions';

import Table from 'common/components/Table';
import OrderDetailsModal from '../components/OrderDetailsModal';
import CancelConfirmationModal from '../components/CancelConfirmationModal';
import OrderChangePaymentTypeModal from '../components/OrderChangePaymentTypeModal';

import SingleOrderList from '../components/SingleOrderList';
import {
  fetchOrders,
  fetchAllOrders,
  updateOrders,
  cancelOrder,
} from '../actions/ordersActions';

import { dateObjectToDateTime } from 'common/services/dateHelpers';
import { withTranslation } from 'react-i18next';

const getHeaders = (t, pluralNameDenominator) => [
  { label: t('$*myOrdersContent.headers.date', 'Data'), minWidth: '120px' },
  {
    label: t('$*myOrdersContent.headers.orderNumber', 'Zamówienie'),
    minWidth: '170px',
  },
  { label: t('$*myOrdersContent.headers.value', 'Koszt'), minWidth: '100px' },
  {
    label: t('$*myOrdersContent.headers.pointsPaymentValue', {
      defaultValue: 'Użyte {{points}}',
      points: pluralNameDenominator || t('$*common.pointsLiteral', 'punkty'),
    }),
    minWidth: '100px',
  },
  {
    label: t('$*myOrdersContent.headers.status', 'Status'),
    minWidth: '100px',
  },
  {
    label: t('$*myOrdersContent.headers.actions', 'Akcje'),
    minWidth: '50px',
  },
];

export const translatePaymentStatuses = t => {
  return {
    WAITING_FOR_PAYMENT: t(
      '$*paymentStatus.WAITING_FOR_PAYMENT',
      '$$Oczekujące'
    ),
    CANCELED: t('$*paymentStatus.CANCELED', '$$Anulowane'),
    PAID: t('$*paymentStatus.PAID', '$$Opłacone'),
  };
};

const WAITING_FOR_PAYMENT_STATUS = 'WAITING_FOR_PAYMENT';
const MODAL_NAME = 'ORDER_DETAILS';
const PAYMENT_TYPE_CHANGE_MODAL_NAME = 'PAYMENT_TYPE_CHANGE_MODAL_NAME';
const CANCEL_MODAL_NAME = 'CANCEL_MODAL';

class MyOrdersContent extends Component {
  state = {
    paymentTypeModalStatus: false,
    orderDetailsModalStatus: false,
    cancelModalStatus: false,
    orderDetailsList: [],
    orderId: null,
    orderIdToCancel: null,
    orderIdToChangePaymentType: null,
    orderToChangePaymentTypeItems: [],
    priceToPay: 0,
    itemsPerPage: 30,
    page: 1,
    paymentStatusesMap: {},
    subscriptionAtDay: null,
  };

  toggleDetailsModal = (modalId, items = [], orderId) =>
    this.setState({
      orderDetailsModalStatus: modalId,
      orderDetailsList: items,
      orderId,
    });

  togglePaymentTypeChangeModal = (
    modalId,
    priceToPay,
    orderToChangePaymentTypeItems
  ) => {
    this.setState({
      paymentTypeModalStatus: modalId,
      priceToPay: priceToPay || 0,
      orderToChangePaymentTypeItems: orderToChangePaymentTypeItems || [],
    });
  };

  setOrderId = id => {
    this.setState({
      orderIdToChangePaymentType: id,
    });
  };

  toggleCancelModal = (modalId, id) =>
    this.setState({
      cancelModalStatus: modalId,
      orderIdToCancel: id,
    });

  componentDidMount() {
    this.props.fetchOrders(true, this.state.itemsPerPage, this.state.page);
    this.setState({
      paymentStatusesMap: translatePaymentStatuses(this.props.t),
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.i18n.language !== prevProps.i18n.language) {
      this.setState({
        paymentStatusesMap: translatePaymentStatuses(this.props.t),
      });
    }
  }

  updateOrders = () => {
    this.props.updateOrders(true, this.state.itemsPerPage, this.state.page + 1);

    this.setState({
      page: this.state.page + 1,
    });
  };

  render() {
    const {
      orders,
      cancelOrder,
      t,
      multinational: { currencySymbol },
    } = this.props;

    const {
      orderDetailsModalStatus,
      orderDetailsList,
      orderId,
      cancelModalStatus,
      paymentTypeModalStatus,
      orderIdToCancel,
      orderIdToChangePaymentType,
      priceToPay,
      orderToChangePaymentTypeItems,
      subscriptionAtDay,
    } = this.state;

    const rows = orders.map(
      ({
        createdAt,
        id,
        priceAfterDiscount,
        priceFromMoneyBox,
        pointsFromMoneyBox,
        status,
        items,
        invoiceKind,
        invoicePDF,
        paymentLink,
        subscriptionAtDay,
        orderItemsProcessFinished,
      }) => [
        dateObjectToDateTime(createdAt),
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            this.toggleDetailsModal(MODAL_NAME, items, id);
            this.setState({ subscriptionAtDay });
          }}
        >
          <StyledBadgeGreen>
            {t('$*common.order', 'Zamówienie')} #{id}{' '}
          </StyledBadgeGreen>
        </span>,
        <span>
          {(priceAfterDiscount - priceFromMoneyBox).toFixed(2)} {currencySymbol}
        </span>,
        pointsFromMoneyBox,
        `${this.state.paymentStatusesMap[status.systemValue] || status.value}${
          !orderItemsProcessFinished && status.systemValue === 'PAID'
            ? t('$*myOrdersContent.preparationInProgress')
            : ''
        }`,
        //render cancelOrderButton or invoiceDownloadButton
        (status.systemValue === WAITING_FOR_PAYMENT_STATUS && (
          <React.Fragment>
            <span
              style={{ cursor: 'pointer', marginRight: '5px' }}
              onClick={() => {
                this.togglePaymentTypeChangeModal(
                  PAYMENT_TYPE_CHANGE_MODAL_NAME,
                  priceAfterDiscount - priceFromMoneyBox,
                  items
                );

                this.setOrderId(id);
              }}
            >
              <StyledBadgeGreen>{t('$*common.pay', 'Opłać')}</StyledBadgeGreen>
            </span>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => this.toggleCancelModal(CANCEL_MODAL_NAME, id)}
            >
              <StyledBadgeGreen>
                {t('$*common.cancel', 'Anuluj')}
              </StyledBadgeGreen>
            </span>
          </React.Fragment>
        )) ||
          (invoicePDF && (
            <a href={invoicePDF} target="_blank" rel="noopener noreferrer">
              <StyledBadgeGreen>
                <i
                  className={`fas fa-file-alt`}
                  style={{ marginRight: '5px' }}
                ></i>
                {invoiceKind === 'vat'
                  ? t('$*common.downloadInvoice', 'Pobierz fakturę')
                  : t('$*common.downloadReceipt', 'Pobierz paragon')}
              </StyledBadgeGreen>
            </a>
          )),
      ]
    );

    const HEADERS = getHeaders(t, this.props.pluralNameDenominator);

    const emptyOrdersHistoryText = t(
      '$*myOrdersContent.emptyOrdersHistoryText',
      '$$Historia zamówień jest pusta'
    );

    return (
      <ContentWrapper padding="20px 40px 40px" marginBottom="20px">
        <Box>
          <DisplayOnMobile>
            <SingleOrderList rows={rows} noRowsText={emptyOrdersHistoryText} />
          </DisplayOnMobile>
          <DisplayOnDesktop>
            <Table
              headers={HEADERS}
              rows={rows}
              noRowsText={emptyOrdersHistoryText}
            />
          </DisplayOnDesktop>
        </Box>
        <CancelConfirmationModal
          id={CANCEL_MODAL_NAME}
          isOpened={cancelModalStatus}
          toggleModal={this.toggleCancelModal}
          cancelOrder={cancelOrder}
          orderId={orderIdToCancel}
        />

        <OrderDetailsModal
          id={MODAL_NAME}
          isOpened={orderDetailsModalStatus}
          toggleModal={this.toggleDetailsModal}
          data={orderDetailsList}
          orderId={orderId}
          subscriptionAtDay={subscriptionAtDay}
        />
        <OrderChangePaymentTypeModal
          id={PAYMENT_TYPE_CHANGE_MODAL_NAME}
          isOpened={paymentTypeModalStatus}
          toggleModal={this.togglePaymentTypeChangeModal}
          data={orderDetailsList}
          orderId={orderIdToChangePaymentType}
          priceToPay={priceToPay}
          orderToChangePaymentTypeItems={orderToChangePaymentTypeItems}
          setPaymentMethod={this.props.setPaymentMethod}
          paymentMethodId={this.props.paymentMethodId}
          fetchOrders={this.props.fetchOrders}
          ordersItemsPerPage={this.state.itemsPerPage}
          ordersPage={this.state.page}
          needStripePromise={true}
        />
        <Box textAlign={'center'}>
          {this.props.isLoading && <Spinner />}
          {!this.props.allOrdersFetched && !this.props.isLoading && (
            <Fragment>
              <Flex justifyContent={'space-around'}>
                <Box>
                  <ButtonLink
                    type="button"
                    uppercased
                    weightBold
                    onClick={this.updateOrders}
                    fullWidthOnMobile
                  >
                    {t('$*common.loadMore', 'Załaduj więcej')}
                  </ButtonLink>
                </Box>
                <Box>
                  <ButtonLink
                    type="button"
                    uppercased
                    weightBold
                    onClick={() => this.props.fetchAllOrders()}
                    fullWidthOnMobile
                  >
                    {t('$*common.loadAll', 'Załaduj wszystko')}
                  </ButtonLink>
                </Box>
              </Flex>
            </Fragment>
          )}
        </Box>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = ({
  app: {
    brand: {
      moneyBoxConfigurationNames: { pluralNameDenominator, pluralNameGenitive },
    },
    config: { multinational },
  },
  orders: { orders, isLoading, allOrdersFetched },
  orderForm: { paymentMethodId },
}) => ({
  orders,
  isLoading,
  allOrdersFetched,
  multinational,
  paymentMethodId,
  pluralNameDenominator,
  pluralNameGenitive,
});
const mapDispatchToProps = {
  fetchOrders,
  fetchAllOrders,
  updateOrders,
  cancelOrder,
  setPaymentMethod,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(MyOrdersContent);
