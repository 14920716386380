import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';

const CookiesInfo = ({ link, themeColor }) => {
  const { t } = useTranslation();

  return (
    <CookieConsent
      style={{
        background: '#fafbfc',
        maxWidth: '1310px',
        boxShadow: '0 2px 16px 0 rgba(0,0,0,0.1)',
        borderRadius: '8px',
        color: '#000',
        justifyContent: 'center',
        alignItems: 'center',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 99999999999,
      }}
      buttonStyle={{
        boxShadow: '0 2px 16px 0 rgba(0,0,0,0.1)',
        fontWeight: '500',
        fontSize: '16px',
        padding: '20px 25px',
        borderRadius: '8px',
        background: themeColor,
        color: '#fff',
      }}
      buttonText={t('$*cookies.caption', 'Akceptuję')}
    >
      {t(
        '$*cookies.consent',
        'Ta strona wykorzystuje pliki cookie w celu zapewnienia maksymalnej wygody w korzystaniu z naszego serwisu. Korzystając z tej witryny wyrażasz zgodę na zapisywanie informacji zawartej w cookies.'
      )}{' '}
      &nbsp;
      <a href={link} target="_blank" rel="noopener noreferrer">
        [{t('$*cookies.link', 'polityka prywatności')}]
      </a>
    </CookieConsent>
  );
};

export default CookiesInfo;
