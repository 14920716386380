import React from 'react';
import { media } from 'styledComponents/helpers';
import styled from 'styled-components';

const StyledNavbarItem = styled.li`
  margin: 0 20px;

  ${media.tabletDown`
    margin-top: 25px;
  `};
`;

const NavbarItem = ({ path, label, linkProps }) => (
  <StyledNavbarItem>
    <a href={path} {...linkProps}>
      {label}
    </a>
  </StyledNavbarItem>
);

export default NavbarItem;
