import { path, pathOr, prop, propOr, uniq } from 'ramda';

import { getIRI } from 'common/services/apiHelpers';
import RESOURCE_TYPE from 'common/shared/iriResourceTypes';
import { getDishItemIdFromInputId } from './menuPlanningRepresenter';
import { DELIVERY_RATING_OPTIONS, INPUT_IDS } from '../const/ratingForm';
import { sortByPosition } from '../../../utils/componentHelpers';

export const getVariantFromBag = bag => path(['variant', 'id'], bag);

export const getMealTypesFromBag = bag => {
  const meals = propOr([], 'items', bag);

  return meals.map(meal => prop('mealType', meal));
};

export const getMealTypesFromIntentBag = bag => {
  const meals = propOr([], 'menuIntents', bag);

  return meals.map(meal => prop('mealType', meal));
};

export const getMealTypesFromState = state =>
  pathOr([], ['menuPlanning', 'mealTypes'], state);

export const formatMealData = ({ options = {}, mealTypes = [] }) => {
  const mealTypeIds = Object.keys(options);

  const formattedMeals = mealTypeIds.map(id => {
    const mealType = mealTypes.find(type => type.id === +id);
    return {
      id,
      name: mealType.name,
      position: options[id].position,
      dishes: options[id],
    };
  });

  return sortByPosition(formattedMeals, true);
};

export const getSelectedMealsForPlanning = ({ meals = {}, items = [] }) =>
  meals.reduce((values, meal) => {
    const selectedItem = items.find(item => +item.mealType.id === +meal.id);

    if (selectedItem) {
      const mealTypeId = path(['mealType', 'id'], selectedItem);
      const dishId = path(['dish', 'id'], selectedItem);

      return {
        ...values,
        [getIRI({
          id: mealTypeId,
          resource: RESOURCE_TYPE.MEAL_TYPE,
        })]: dishId.toString(),
      };
    }

    return values;
  }, {});

export const prepareMenuPlanRequest = ({ currentItems = [], newItems }) => {
  const items = currentItems.map(currentItem => {
    return {
      dishItem: currentItem['@id'],
      dish: `/dishes/${newItems[currentItem.mealType['@id']]}`,
    };
  });

  return {
    items: items,
  };
};

export const getSingleItemRatingEntry = ({ itemId, ratedItems, ratingId }) => {
  const rateItemKey = getItemKey({
    inputId: INPUT_IDS.SINGLE_MEAL_RATING_PREFIX,
    ratedItems,
    itemId,
  });
  const commentItemKey = getItemKey({
    inputId: INPUT_IDS.SINGLE_MEAL_RATING_COMMENT_PREFIX,
    ratedItems,
    itemId,
  });

  const singleItemId = getIRI({
    id: itemId,
    resource: RESOURCE_TYPE.DISH_ITEMS,
  });

  return {
    ...(singleItemId ? { dishItem: singleItemId } : {}),
    rate: {
      ...(ratingId ? { '@id': ratingId } : {}),
      rate: prop(rateItemKey, ratedItems) || null,
      comment: prop(commentItemKey, ratedItems) || null,
    },
  };
};

export const getDeliveryRatingComment = ({ deliveryRating, ratedItems }) =>
  deliveryRating === DELIVERY_RATING_OPTIONS.GOOD
    ? null
    : prop(INPUT_IDS.DELIVERY_RATING_COMMENT, ratedItems);

export const getOverallRatingIdParam = ({ changedFields, bag }) => {
  return path(['rate', '@id'], bag);
};

export const getDeliveryRatingIdParam = ({ changedFields, bag }) => {
  return path(['deliveryRate', '@id'], bag);
};

// Get array of all dish item IDs by parsing input IDs and removing unnecessary fields
export const getDishItemIdsFromRatedItems = ratedItems =>
  uniq(
    Object.keys(ratedItems).map(item => getDishItemIdFromInputId(item))
  ).filter(item => !isNaN(+item));

export const getItemKey = ({ inputId, ratedItems, itemId }) =>
  Object.keys(ratedItems).find(item => {
    const dishId = +getDishItemIdFromInputId(item);

    return item.includes(inputId) && +itemId === dishId;
  });

export const getRatingIdForItemUpdate = ({ changedFields, itemId, bag }) => {
  const bagItems = prop('items', bag);
  const foundItem = bagItems.find(item => prop('id', item) === +itemId);

  return path(['rate', '@id'], foundItem);
};

export const prepareMenuRateRequest = ({ ratedItems, changedFields, bag }) => {
  // const overallRating = calculateOverallRating(ratedItems);
  const dishItemIds = getDishItemIdsFromRatedItems(ratedItems);

  const mealRatings = dishItemIds
    .reduce((ratings, itemId) => {
      // Find rating ID only if rating is being updated
      const ratingId =
        getRatingIdForItemUpdate({
          changedFields,
          itemId,
          bag,
        }) || null;

      const singleItemRatingEntry = getSingleItemRatingEntry({
        itemId,
        ratedItems,
        ratingId,
      });

      return [...ratings, singleItemRatingEntry];
    }, [])
    .filter(({ rate }) => rate.rate !== null);

  // Find rating ID only if rating is being updated
  const overallRatingId = getOverallRatingIdParam({ changedFields, bag });
  const deliveryRatingId = getDeliveryRatingIdParam({ changedFields, bag });

  const deliveryRating = prop(INPUT_IDS.DELIVERY_RATING, ratedItems);
  // If user liked the delivery, we can omit or erase a comment
  const deliveryRatingComment = getDeliveryRatingComment({
    deliveryRating,
    ratedItems,
  });

  const hasComment = !!prop(INPUT_IDS.OVERALL_RATING_COMMENT, ratedItems);

  return {
    ...(hasComment
      ? {
          rate: {
            ...(overallRatingId ? { '@id': overallRatingId } : {}),
            comment: prop(INPUT_IDS.OVERALL_RATING_COMMENT, ratedItems) || null,
          },
        }
      : {}),
    deliveryRate: {
      ...(deliveryRatingId ? { '@id': deliveryRatingId } : {}),
      rate: deliveryRating,
      comment: deliveryRatingComment || null,
    },
    dishes: mealRatings,
  };
};
