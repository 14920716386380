import React, { Fragment } from 'react';
import { Flex } from '@rebass/grid';

import { Heading } from 'styledComponents/elements/Typography';
import Spacer from 'styledComponents/elements/Spacer';
import { NotificationWrapper } from 'styledComponents/elements/MenuPlanning/SingleDetailSection';
import { isMenuRated } from 'views/MenuPlanning/services/menuPlanningRepresenter';
import DietDetails from './DietDetails';
import { useTranslation } from 'react-i18next';

const MenuDetailsSection = ({ bag }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Flex
        alignItems={['center']}
        justifyContent="space-between"
        flexDirection={['column', 'column', 'row']}
      >
        <Heading noPadding textAlignMobile="center">
          {t('$*menuPlanning.menuDetailsSection.heading', '$$Szczegóły')}
        </Heading>
        <p>
          {isMenuRated(bag)
            ? t('$*menuPlanning.menuDetailsSection.reated', '$$Dzień oceniony')
            : t(
                '$*menuPlanning.menuDetailsSection.rateTimeUp',
                '$$Czas na ocenę minął'
              )}
        </p>
      </Flex>
      {bag.notifications.map(el => (
        <NotificationWrapper dangerouslySetInnerHTML={{ __html: el.content }} />
      ))}
      <Spacer height="32px" />
      <DietDetails bag={bag} horizontalPadding={0} />
    </Fragment>
  );
};

export default MenuDetailsSection;
