const WEEKDAYS_INDEXES = {
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
  7: 'sunday',
};

const SATURDAY = 6;
const SUNDAY = 0;
const MONDAY = 1;
const WEEKEND_DAYS = [SUNDAY, SATURDAY];
const TODAY = new Date();

export { WEEKDAYS_INDEXES, WEEKEND_DAYS, SUNDAY, SATURDAY, TODAY, MONDAY };
