import { SET_NATIVE_APP_CONFIG } from '../actions/const';

const INITIAL_STATE = {
  disableThirdPartyLoginMethods: false,
  disableCookiesPopup: false,
  disableTracking: false,
  resetNativeConfig: false,
};

const nativeAppReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_NATIVE_APP_CONFIG: {
      if (payload?.resetNativeConfig) {
        return { ...INITIAL_STATE };
      }

      return { ...state, ...payload };
    }

    default:
      return state;
  }
};

export default nativeAppReducer;
