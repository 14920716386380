import OrderFormConfiguration from '../../containers/OrderFormConfiguration';
import OrderFormDelivery from '../../containers/OrderFormDelivery';
import OrderFormSummary from '../../containers/OrderFormSummary';
import OrderFormCustomer from '../../containers/OrderFormCustomer';
import OrderFormCardPayment from '../../containers/OrderFormCardPayment';
import PremiumStep from '../../containers/PremiumStep';
import { shallowEqual, useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import OrderFormAddon from '../../containers/OrderFormAddon';

export const useTabsConfig = () => {
  const {
    token,
    premiumType,
    premiumAdditionalStep,
    modules,
    isSubscriptionPayment,
    hasCreditCard,
    paymentMethodId,
  } = useSelector(
    ({
      app: {
        brand: { premiumType, premiumAdditionalStep },
        config: { modules },
      },
      auth: { token, user },
      dietForm: { paymentMode },
      orderForm: { paymentMethodId },
    }) => ({
      token,
      premiumType,
      premiumAdditionalStep,
      modules,
      isSubscriptionPayment: paymentMode === '1',
      hasCreditCard: !!user?.creditCard?.cardNumber,
      paymentMethodId,
    }),
    shallowEqual
  );
  const isAuthenticated = !!token;

  const { t } = useTranslation();

  let tabs = useMemo(
    () =>
      [
        {
          label: t('$*orderFormTabs.configuration', '$$Konfiguracja diety'),
          panel: OrderFormConfiguration,
          step: 'config',
        },
        modules.Addons.addToOrder && {
          label: t('$*orderFormTabs.addons', 'Dodatki'),
          panel: OrderFormAddon,
          step: 'addons',
        },
        premiumType === 'PREMIUM' &&
          premiumAdditionalStep && {
            label: t('$*orderFormTabs.premium', '$$Wybór menu'),
            panel: PremiumStep,
            step: 'menu',
          },
        {
          label: t('$*orderFormTabs.delivery', '$$Dane dostawy'),
          panel: OrderFormDelivery,
          step: 'delivery',
        },
        {
          label: t('$*orderFormTabs.summary', '$$Podsumowanie'),
          panel: OrderFormSummary,
          step: 'summary',
        },
        !isAuthenticated && {
          label: t('$*orderFormTabs.customer', '$$Dane klienta'),
          panel: OrderFormCustomer,
          step: 'customerData',
        },
        ['STRIPE_CARD', 'PAYU_CARD'].includes(paymentMethodId) && {
          label: t('$*orderFormTabs.payment', '$$Płatność'),
          panel: OrderFormCardPayment,
          step: 'payment',
        },
      ].filter(item => !!item),
    [
      premiumType,
      premiumAdditionalStep,
      isAuthenticated,
      isSubscriptionPayment,
      hasCreditCard,
      paymentMethodId,
      modules,
      t,
    ]
  );

  return tabs;
};
