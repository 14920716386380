import React from 'react';

import MobileMenu from 'styledComponents/elements/Menu/MobileMenu';

const NavbarHamburger = ({
  isMobileMenuOpened,
  handleNavigationChange,
  mediaMobileCss,
}) => {
  return (
    <MobileMenu.Hamburger
      showMobileMenu={isMobileMenuOpened}
      onClick={() => handleNavigationChange(!isMobileMenuOpened)}
      mediaMobileCss={mediaMobileCss}
    >
      <span />
      <span />
      <span />
    </MobileMenu.Hamburger>
  );
};

export default NavbarHamburger;
