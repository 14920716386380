import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import imagePlaceholder from 'assets/images/image_placeholder.jpg';
import {
  ContentWrapper,
  Image,
  ImageWrapper,
  InputNumber,
  LeftCol,
  Name,
  Price,
  RemoveButton,
  RemoveButtonWrapper,
  RightCol,
  Wrapper,
} from './BasketItem.styled';
import { changeQuantityAddon, removeAddonFromBag } from './duck/actions';

const BasketItem = ({
  addon,
  bag,
  clientCost,
  image,
  name,
  quantity,
  currencySymbol,
}) => {
  const dispatch = useDispatch();
  const [count, setCount] = useState(quantity);

  useEffect(() => {
    count > 0
      ? dispatch(changeQuantityAddon(bag, addon, count))
      : dispatch(removeAddonFromBag(bag, addon));
  }, [count]);

  return (
    <Wrapper>
      <LeftCol>
        <ImageWrapper>
          <Image image={image || imagePlaceholder} />
        </ImageWrapper>
        <ContentWrapper>
          <Name>{name}</Name>
        </ContentWrapper>
      </LeftCol>
      <RightCol>
        <InputNumber>
          <InputNumber.Button type="button" onClick={() => setCount(count - 1)}>
            -
          </InputNumber.Button>
          <InputNumber.Text>{quantity}</InputNumber.Text>
          <InputNumber.Button type="button" onClick={() => setCount(count + 1)}>
            +
          </InputNumber.Button>
        </InputNumber>
        <Price>{`${clientCost.toFixed(2)} ${currencySymbol}`}</Price>
        <RemoveButtonWrapper>
          <RemoveButton
            onClick={() => dispatch(removeAddonFromBag(bag, addon))}
          >
            <i class="fas fa-times" />
          </RemoveButton>
        </RemoveButtonWrapper>
      </RightCol>
    </Wrapper>
  );
};

export default BasketItem;
