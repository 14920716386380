import React from 'react';
import { Box, Flex } from '@rebass/grid';
import { mainTheme } from 'themes/main';
import URLS from 'routes/routeUrls';
import { ContentWrapper } from 'styledComponents/blocks/ContentWrapper';
import { Heading } from 'styledComponents/elements/Typography';
import { ButtonPrimary } from 'styledComponents/elements/Button';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NoDiets = ({ history }) => {
  const { t } = useTranslation();
  return (
    <ContentWrapper>
      <Flex justifyContent="center">
        <Box p={mainTheme.mobileContentWrapperPadding}>
          <Flex>
            <Box
              m="auto"
              pt={[
                mainTheme.mobileContentWrapperPadding,
                mainTheme.mobileContentWrapperPadding,
                50,
              ]}
            >
              <Heading textAlign="center">
                {t(
                  '$*userDiet.noDiet.noPlannedOrders',
                  'Brak zaplanowanych zamówień'
                )}
              </Heading>
            </Box>
          </Flex>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            pt={[
              mainTheme.mobileContentWrapperPadding,
              mainTheme.mobileContentWrapperPadding,
              50,
            ]}
            pb={[
              mainTheme.mobileContentWrapperPadding,
              mainTheme.mobileContentWrapperPadding,
              50,
            ]}
          >
            <Box>
              <ButtonPrimary
                type="button"
                uppercased
                sizeMiddle
                weightBold
                onClick={() => history.push(URLS.NEW_ORDER_FORM)}
                fullWidthOnMobile
                width={'240px'}
                padding="0 15px"
              >
                {t('$*userDiet.noDiet.createDietOrder', '$$Złóż zamówienie')}
              </ButtonPrimary>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </ContentWrapper>
  );
};

export default compose(withRouter)(NoDiets);
