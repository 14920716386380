import {
  FETCH_MONEY_BOX_HISTORY_SUCCESS,
  FETCH_MONEY_BOX_HISTORY_FAILURE,
  FETCH_MONEY_BOX_HISTORY_REQUEST,
  UPDATE_MONEY_BOX_HISTORY_SUCCESS,
} from '../actions/const';

const INITIAL_STATE = {
  history: [],
  points: 0,
  isLoading: true,
  error: null,
  page: 1,
  itemsPerPage: 5,
  allMoneyBoxHistoryLoaded: false,
};

const moneyBoxReducer = (
  state = INITIAL_STATE,
  { type, history, points, error, page, allMoneyBoxHistoryLoaded }
) => {
  switch (type) {
    case FETCH_MONEY_BOX_HISTORY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_MONEY_BOX_HISTORY_SUCCESS:
      return {
        ...state,
        history: [...state.history, ...history],
        points,
        page,
        allMoneyBoxHistoryLoaded,
        isLoading: false,
      };
    case FETCH_MONEY_BOX_HISTORY_SUCCESS:
      return {
        ...state,
        history,
        points,
        page,
        allMoneyBoxHistoryLoaded,
        isLoading: false,
      };
    case FETCH_MONEY_BOX_HISTORY_FAILURE:
      return {
        ...state,
        error,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default moneyBoxReducer;
