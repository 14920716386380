import FormTextInput from 'common/components/Form/FormTextInput';
import FormSelectBox from 'common/components/Form/FormSelectBox';

import {
  getValidators,
  useFormValidation,
} from 'common/services/formValidation';

import { sortByPosition } from 'utils/common';

import {
  INPUT_IDS as AGREEMENTS_INPUT_IDS,
  INITIAL_VALUES as AGREEMENTS_INITIAL_VALUES,
  useAgreementsFormInputs,
} from 'views/AccountSettings/const/agreementsFormInputs';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { ReactPhoneNumberAdapter } from 'common/components/ReactPhoneNumberAdapter/ReactPhoneNumberAdapter';

export const INPUT_IDS = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  GENDER: 'gender',
  EMAIL: 'email',
  PHONE_NUMBER: 'phone',
  PASSWORD: 'plainPassword',
  REPEAT_PASSWORD: 'repeatPlainPassword',
  REFERRER: 'knowAboutUs',
  REFERRER_TEXT: 'knowAboutUsText',
  ...AGREEMENTS_INPUT_IDS,
};

export const INITIAL_VALUES = {
  [INPUT_IDS.FIRST_NAME]: '',
  [INPUT_IDS.LAST_NAME]: '',
  [INPUT_IDS.GENDER]: null,
  [INPUT_IDS.EMAIL]: '',
  [INPUT_IDS.PHONE_NUMBER]: '',
  [INPUT_IDS.PASSWORD]: '',
  [INPUT_IDS.REPEAT_PASSWORD]: '',
  [INPUT_IDS.REFERRER]: '',
  [INPUT_IDS.REFERRER_TEXT]: '',
  ...AGREEMENTS_INITIAL_VALUES,
};

export const useRegisterFormInputs = ({
  sexOptions = [],
  howKnowOptions = [],
}) => {
  const { t, i18n: language } = useTranslation();
  const AGREEMENTS_FORM_INPUTS = useAgreementsFormInputs();
  const GENDER_OPTIONS = sortByPosition(
    sexOptions
  )?.map(({ '@id': iriId, value }) => ({ label: value, value: iriId }));
  const REFERRER_OPTIONS = sortByPosition([
    ...howKnowOptions,
    { value: t('$*registerForm.inputs.refererOptionsOthers'), position: 9999 },
  ])?.map(({ value }) => ({
    label: value,
    value: value,
  }));

  const {
    email,
    required,
    requiredLocalizedPhone,
    equalPasswords,
    smartPhoneInRegionValidator,
  } = useFormValidation();

  return useMemo(
    () => [
      {
        id: INPUT_IDS.FIRST_NAME,
        name: INPUT_IDS.FIRST_NAME,
        type: 'text',
        component: FormTextInput,
        label: t('$*registerForm.inputs.firstName', '$$Imię'),
        isRequired: true,
        validate: required,
        width: '35%',
      },
      {
        id: INPUT_IDS.LAST_NAME,
        name: INPUT_IDS.LAST_NAME,
        type: 'text',
        component: FormTextInput,
        label: t('$*registerForm.inputs.lastName', '$$Nazwisko'),
        isRequired: true,
        validate: required,
        width: '35%',
      },
      {
        id: INPUT_IDS.GENDER,
        name: INPUT_IDS.GENDER,
        type: 'select',
        component: FormSelectBox,
        label: t('$*registerForm.inputs.sex', '$$Płeć'),
        placeholder: t('$*registerForm.inputs.chosePlaceholder', '$$Wybierz'),
        options: GENDER_OPTIONS,
        width: '30%',
      },

      {
        id: INPUT_IDS.EMAIL,
        name: INPUT_IDS.EMAIL,
        type: 'email',
        component: FormTextInput,
        label: t('$*registerForm.inputs.email', 'E-mail'),
        isRequired: true,
        validate: getValidators([required, email]),
        width: '50%',
      },
      {
        id: INPUT_IDS.PHONE_NUMBER,
        name: INPUT_IDS.PHONE_NUMBER,
        isRequired: true,

        component: ReactPhoneNumberAdapter,
        label: t('$*registerForm.inputs.phone', '$$Telefon'),
        width: '50%',
        validate: getValidators([
          required,
          requiredLocalizedPhone,
          smartPhoneInRegionValidator,
        ]),
      },
      {
        id: INPUT_IDS.PASSWORD,
        name: INPUT_IDS.PASSWORD,
        type: 'password',
        component: FormTextInput,
        label: t('$*registerForm.inputs.password', 'Hasło'),
        isRequired: true,
        validate: required,
        width: '50%',
      },
      {
        id: INPUT_IDS.REPEAT_PASSWORD,
        name: INPUT_IDS.REPEAT_PASSWORD,
        type: 'password',
        component: FormTextInput,
        label: t('$*registerForm.inputs.repeatPassword', 'Powtórz hasło'),
        isRequired: true,
        validate: (value, values) =>
          required(value) || equalPasswords(value, values[INPUT_IDS.PASSWORD]),
        width: '50%',
      },
      {
        id: INPUT_IDS.REFERRER,
        name: INPUT_IDS.REFERRER,
        type: 'select',
        component: FormSelectBox,
        label: t(
          '$*registerForm.inputs.repeatPlainPassword',
          '$$Skąd o nas wiesz?'
        ),
        placeholder: t(
          '$*registerForm.inputs.repeatPlainPasswordPlaceholder',
          '$$Wybierz...'
        ),
        validate: required,
        isRequired: true,
        options: REFERRER_OPTIONS,
        width: '100%',
      },
      {
        id: INPUT_IDS.REFERRER_TEXT,
        name: INPUT_IDS.REFERRER_TEXT,
        type: 'text',
        component: FormTextInput,
        label: '',
        isRequired: false,
        width: '100%',
      },
      ...AGREEMENTS_FORM_INPUTS,
    ],
    [language, t]
  );
};
