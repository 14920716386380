import {
  addDays,
  eachDay,
  subDays,
  startOfMonth,
  endOfMonth,
  format,
  getDay,
} from 'date-fns';

export const generateSideDays = (
  {
    selectedDay,
    numberOfDaysOnEachSide,
    disabledDays = [],
    permanentlyDisabledDays = [],
  },
  changerFunction
) => {
  let daysToAdd = 1;
  const arr = [];

  while (arr.length < numberOfDaysOnEachSide) {
    const subsequentDay = changerFunction(selectedDay, daysToAdd);
    if (
      !disabledDays.includes(format(subsequentDay, 'YYYY-MM-DD')) &&
      !permanentlyDisabledDays.includes(getDay(subsequentDay))
    ) {
      arr.push(subsequentDay);
    }
    daysToAdd += 1;
  }

  return arr;
};

const convertWeekDaysToNumbers = weekday => {
  switch (weekday) {
    case 'sunday':
      return 0;
    case 'monday':
      return 1;
    case 'tuesday':
      return 2;
    case 'wednesday':
      return 3;
    case 'thursday':
      return 4;
    case 'friday':
      return 5;
    case 'saturday':
      return 6;
  }
};

export const getPermanentlyDisabledDays = days => {
  return Object.entries(days)
    .filter(([_, value]) => value === 0)
    .map(([key, _]) => convertWeekDaysToNumbers(key));
};

export const getVisibleDays = props => {
  const leftDays = generateSideDays(props, subDays);
  const rightDays = generateSideDays(props, addDays);

  return [...leftDays.reverse(), new Date(props.selectedDay), ...rightDays];
};

export const getCurrentCenteredDay = ({
  calendarOffset,
  direction,
  formattedDisabledDays = [],
  permanentlyDisabledDays = [],
  selectedDay,
}) => {
  let i = calendarOffset;
  const offsetToAdd = getNavigationOffset(direction);
  let iteratedDay = format(new Date(selectedDay), 'YYYY-MM-DD');

  do {
    i += offsetToAdd;
    iteratedDay = format(addDays(new Date(selectedDay), i), 'YYYY-MM-DD');
  } while (
    formattedDisabledDays.includes(iteratedDay) ||
    permanentlyDisabledDays.includes(getDay(iteratedDay))
  );

  return { calendarOffset: i, centeredVisibleDay: iteratedDay };
};

export const getNavigationOffset = direction => {
  switch (direction) {
    case 'left':
      return -1;
    case 'right':
      return 1;
    default:
      return 1;
  }
};

export const getLastDaysBeforeAndAfter = (selectedDay, numberOfDays = 3) => {
  const daysBefore = eachDay(
    subDays(selectedDay, numberOfDays),
    subDays(selectedDay, 1)
  );
  const daysAfter = eachDay(
    addDays(selectedDay, 1),
    addDays(selectedDay, numberOfDays)
  );

  return [...daysBefore, new Date(selectedDay), ...daysAfter];
};

export const getLimitDates = (range = []) => [
  range[0],
  range[range.length - 1],
];

export const getMonthRange = selectedDay =>
  eachDay(startOfMonth(selectedDay), endOfMonth(selectedDay));
